import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  injectIntl,
  FormattedMessage,
} from 'react-intl';

import _isEmpty from 'lodash/isEmpty';

import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormField from 'components/FormField';
import SummaryButtons from 'components/SummaryButtons';
import Section from 'components/Section';
import BannerContainer from 'components/BannerContainer';
import Modal from 'components/Modal';
import CardContent from 'components/CardContent';
import validator from 'utils/validator/core';
import { intlShape } from 'utils/shapes/intlShape';
import isEmptyObject from 'utils/isEmptyObject';
import config from 'config';
import filesImage from 'assets/img/files.svg';

import FileDropdown from './components/FileDropdown';
import FileInfo from './components/FileInfo';
import { fileDataShape } from './components/FileInfo/FileInfo.shapes';

import messages from './FileAddEdit.messages';
import { initialValuesShape } from './FileAddEdit.shapes';
import useStyle from './FileAddEdit.styles';

const getValidators = (isInvoice) => [{
  fieldName: 'name',
  validateIf: () => !isInvoice,
  validators: [{
    validatorName: 'notEmpty',
  },
  {
    validatorName: 'maxLength',
    parameters: [255],
  }],
}, {
  fieldName: 'note',
  validators: [{
    validatorName: 'maxLength',
    parameters: [config.maxLength.textField],
  }],
}];

const FileAddEdit = ({
  intl,
  initialValues,
  isEdit,
  onSubmit,
  onCancel,
  onDownload,
  onFileUpload,
  onFileUnload,
  type,
  isSubmitting,
  fileData,
  image,
  replaceFileNameWith,
  hideUnload,
  hideDownload,
  customTitle,
  isInvoice,
  isDisabled,
}) => {
  const [isUploading, setUploading] = useState(isEmptyObject(fileData) && !isDisabled);
  const [isFileUploaded, setFileUploaded] = useState(!isEmptyObject(fileData));
  const classes = useStyle();
  const title = isEdit ? messages.editFile : messages.addFile;

  const onUnload = () => {
    setUploading(true);
    setFileUploaded(false);
    onFileUnload();
  };

  const onUpload = (acceptedFiles, rejectedFiles) => {
    if (_isEmpty(rejectedFiles)) {
      setUploading(false);
      setFileUploaded(true);
    }
    onFileUpload(acceptedFiles, rejectedFiles);
  };

  return (
    <Modal
      isOpen={!!type}
      onRequestClose={isDisabled ? onCancel : null}
      onClickCloseIcon={onCancel}
    >
      <CardContent withoutBorder bottomMargin={false} staticWidth>
        <BannerContainer
          logo={image || filesImage}
          addPaddings
        >
          <Formik
            initialValues={{ ...initialValues }}
            validate={(values) => validator(values, getValidators(isInvoice), intl.formatMessage)}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form>
                <Section title={customTitle || <FormattedMessage {...title} />}>
                  <Grid container spacing={3}>
                    {!replaceFileNameWith && (
                      <Grid item xs={12}>
                        <FormField
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          touched={touched}
                          name="name"
                          staticLabel
                          label={intl.formatMessage(messages.name)}
                          fullWidth
                        >
                          <Input
                            disabled={isDisabled}
                            value={values.name}
                            margin="none"
                          />
                        </FormField>
                      </Grid>
                    )}
                    <Grid item xs={12} classes={{ root: classes.fileWrapper }}>
                      {replaceFileNameWith}
                      {
                        isUploading ? (
                          <FileDropdown onFileUpload={onUpload} />
                        ) : (
                          <FileInfo
                            onUnload={onUnload}
                            onDownload={onDownload}
                            fileData={fileData}
                            hideUnload={hideUnload}
                            hideDownload={hideDownload}
                          />
                        )
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.textareaTitle} variant="h5">
                        <FormattedMessage {...messages.note} />
                      </Typography>
                      <FormField
                        onBlur={setFieldTouched}
                        onChange={setFieldValue}
                        errors={errors}
                        touched={touched}
                        name="note"
                        fullWidth
                      >
                        <OutlinedInput
                          value={values.note}
                          multiline
                          disabled={isDisabled}
                          rowsMax={4}
                          rows={4}
                          placeholder={isDisabled && !values.note ? '' : intl.formatMessage(messages.textPlaceholder)}
                          classes={{
                            root: classes.textareaRoot,
                            inputMultiline: classes.inputMultiline,
                            notchedOutline: classes.notchedOutline,
                          }}
                        />
                      </FormField>
                    </Grid>
                  </Grid>
                </Section>
                <SummaryButtons
                  isSubmitDisabled={
                    !isFileUploaded
                    || !_isEmpty(errors)
                    || (!isInvoice && _isEmpty(values.name))
                    || isDisabled
                  }
                  cancelMessage={<FormattedMessage {...messages.cancel} />}
                  submitMessage={<FormattedMessage {...messages.save} />}
                  smallButtons
                  withBiggerSpacing
                  withLoaderButton
                  onCancel={onCancel}
                  loading={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        </BannerContainer>
      </CardContent>
    </Modal>
  );
};

FileAddEdit.propTypes = {
  initialValues: initialValuesShape.isRequired,
  intl: intlShape.isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onFileUnload: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  customTitle: PropTypes.node,
  fileData: fileDataShape,
  hideDownload: PropTypes.bool,
  hideUnload: PropTypes.bool,
  image: PropTypes.node,
  isDisabled: PropTypes.bool,
  isEdit: PropTypes.bool,
  isInvoice: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onlyDownload: PropTypes.bool,
  replaceFileNameWith: PropTypes.node,
};

FileAddEdit.defaultProps = {
  isSubmitting: false,
  isDisabled: false,
  isEdit: false,
  fileData: {},
  image: null,
  replaceFileNameWith: null,
  onlyDownload: false,
  hideDownload: false,
  hideUnload: false,
  customTitle: null,
  isInvoice: false,
};

export default injectIntl(FileAddEdit);
