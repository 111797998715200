import { push } from 'connected-react-router';
import _get from 'lodash/get';
import _map from 'lodash/map';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import errorCatch from 'utils/errorCatch';
import createURLWithQuery from 'utils/createURLWithQuery';
import downloadFile from 'utils/downloadFile';
import formatCodeWithName from 'utils/formatCodeWithName';
import roles from 'utils/constants/rolesOfUsers';
import { PURCHASE_PACKAGE_APPLICATION } from 'utils/constants/modalTypes';
import config from 'config';

import {
  showLoader,
  hideLoader,
  showSnackbar,
  showTransparentLoader,
  setModal,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  title: '',
  subtitle: '',
  isActive: false,
  manual: [],
  items: '',
  description: '',
};

export const actionTypes = {
  LOAD_PAGE_DATA_SUCCESS: 'TOOLS_DETAILS/LOAD_PAGE_DATA_SUCCESS',
  CLEAR_STORE: 'TOOLS_DETAILS/CLEAR_STORE',
};

const mapItems = (items) => _map(items, (el) => ({
  id: el.id,
  name: formatCodeWithName(el),
  description: el.description,
  price: el.tokens_usage_cost,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapItems(action.tools.data.items),
        title: action.title.code,
        subtitle: action.title.name,
        isActive: _get(action, 'isActive', false),
        manual: action.manual.data[0],
        description: action.description,
        batteryId: action.id,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageDataSuccess = (tools, title, isActive, manual, description, id) => ({
  type: actionTypes.LOAD_PAGE_DATA_SUCCESS,
  tools,
  title,
  isActive,
  manual,
  description,
  id,
});

const loadManualData = (id) => (dispatch) => {
  const params = {
    battery_id: id,
  };

  const url = createURLWithQuery('battery_manuals', params);

  return ApiManager.request('get', dispatch, url);
};

const loadToolsData = (id) => (dispatch) => {
  const params = {
    findByBatteryId: id,
    findByStatus: config.researchToolStatuses.active,
    sortedBy: 'asc',
    orderBy: 'order',
  };

  const url = createURLWithQuery('survey_tools', params);

  return ApiManager.request('get', dispatch, url);
};

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onActivate = () => (dispatch, getStore) => {
  const batteryId = getStore().ToolsDetails.batteryId;

  const data = {
    isActive: false,
    batteryId,
  };

  dispatch(push('/purchasePackageSummary', data));
};

export const onPurchase = (isActive) => (dispatch, getStore) => {
  const batteryId = getStore().ToolsDetails.batteryId;
  const userRole = getStore().Global.userData.role_id;
  const isClinicCoordinator = userRole === roles.clinicCoordinator;

  const data = {
    isActive,
    batteryId,
  };

  if (isClinicCoordinator) {
    dispatch(push('/purchasePackageSummary', data));
  } else {
    dispatch(setModal(PURCHASE_PACKAGE_APPLICATION));
  }
};

export const onDownload = (type, id) => async (dispatch) => {
  dispatch(showTransparentLoader());

  const url = `battery_manuals/${id}/${type}`;

  try {
    const manualData = await ApiManager.request(
      'get', dispatch, url, { responseType: 'blob' }, true,
    );
    downloadFile(manualData, 'pdf');
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch, false, false);
    dispatch(hideLoader());
  }
};

export const loadPageData = (routeState) => async (dispatch) => {
  const {
    title,
    isActive,
    description,
    id,
  } = routeState;

  dispatch(clearStore());
  dispatch(showLoader());
  try {
    const tools = await dispatch(loadToolsData(id));
    const manual = await dispatch(loadManualData(id));

    dispatch(loadPageDataSuccess(tools, title, isActive, manual, description, id));
  } catch (error) {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
  } finally {
    dispatch(hideLoader());
  }
};
