import _find from 'lodash/find';

export default (id, loads, parameters) => {
  const scaleParameter = _find(parameters, (parameter) => parameter.scale_id === id);
  const scaleParameterId = scaleParameter.id;
  const scaleLoad = _find(loads, (load) => load.child_scale_id === scaleParameterId);
  const parentScaleParameterId = scaleLoad.parent_scale_id;

  const parentScaleParameter = _find(
    parameters,
    (parameter) => parameter.id === parentScaleParameterId,
  );
  const parentScaleId = parentScaleParameter.scale_id;

  return parentScaleId;
};
