import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _values from 'lodash/values';
import Typography from '@material-ui/core/Typography';

import invoiceImage from 'assets/img/invoice.svg';
import FileAddEdit from 'components/pages/FileAddEdit/FileAddEdit';
import { fileDataShape } from 'components/pages/FileAddEdit/components/FileInfo/FileInfo.shapes';
import { initialValuesShape } from 'components/pages/FileAddEdit/FileAddEdit.shapes';
import roles from 'utils/constants/rolesOfUsers';

import useStyle from './VATInvoiceAddEdit.styles';
import messages from './VATInvoiceAddEdit.messages';

const VATInvoiceAddEdit = ({
  initialValues,
  isEdit,
  onSubmit,
  onCancel,
  onDownload,
  onFileUnload,
  onFileUpload,
  type,
  isSubmitting,
  fileData,
  hideDownload,
  isInvoiceAccepted,
  userRole,
}) => {
  const classes = useStyle();
  const isClinicCoordinator = userRole === roles.clinicCoordinator;
  let title = messages.addTitle;

  if (isEdit) {
    title = messages.editTitle;
  }

  if (isClinicCoordinator) {
    title = messages.viewTitle;
  }

  return (
    <FileAddEdit
      isInvoice
      isDisabled={isClinicCoordinator}
      image={invoiceImage}
      onFileUnload={onFileUnload}
      replaceFileNameWith={(
        <Typography className={classes.invoiceText} variant="h5">
          <FormattedMessage {...messages.invoice} />
        </Typography>
      )}
      initialValues={initialValues}
      isEdit={isEdit}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onDownload={onDownload}
      onFileUpload={onFileUpload}
      type={type}
      isSubmitting={isSubmitting}
      fileData={fileData}
      hideUnload={isClinicCoordinator || isInvoiceAccepted}
      hideDownload={hideDownload}
      customTitle={<FormattedMessage {...title} />}
    />
  );
};

VATInvoiceAddEdit.propTypes = {
  initialValues: initialValuesShape.isRequired,
  type: PropTypes.string.isRequired,
  userRole: PropTypes.oneOf(_values(roles)).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onFileUnload: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fileData: fileDataShape,
  hideDownload: PropTypes.bool,
  isEdit: PropTypes.bool,
  isInvoiceAccepted: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

VATInvoiceAddEdit.defaultProps = {
  fileData: {},
  hideDownload: false,
  isEdit: false,
  isInvoiceAccepted: false,
  isSubmitting: false,
};

export default VATInvoiceAddEdit;
