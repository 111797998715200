export default (theme) => ({
  notExpanded: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
  },
  thumb: {
    display: 'none',
  },
  thumbError: {
    '&.$Mui-disabled': {
      width: 6,
      height: 6,
      marginTop: 0,
      borderRadius: 6,
    },
  },
  thumbWarning: {
    '&.$Mui-disabled': {
      width: 12,
      height: 6,
      marginTop: 0,
      borderRadius: 6,
    },
  },
  sliderWrapper: {
    marginTop: '-3px',
    width: 87,
  },
  sliderNotCollapsedWrapper: {
    width: 52,
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 6,
    borderRadius: 4,
  },
  rail: {
    opacity: 0.5,
    height: 6,
    borderRadius: 4,
  },
  barRoot: {
    padding: 0,
  },
  accountBalance: {
    marginTop: theme.spacing(0.25),
    fontWeight: 400,
    lineHeight: '16px',
  },
  barSuccessColor: {
    '&.$Mui-disabled': {
      padding: 0,
      color: theme.palette.primary.main,
      height: 6,
      transform: 'rotate(180deg)',
    },
  },
  barWarningColor: {
    padding: 0,
    '&.$Mui-disabled': {
      color: theme.palette.warning.main,
      height: 6,
      transform: 'rotate(180deg)',
    },
  },
  barErrorColor: {
    padding: 0,
    '&.$Mui-disabled': {
      color: theme.palette.error.main,
      height: 6,
      transform: 'rotate(180deg)',
    },
  },
});
