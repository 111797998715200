export default (value, withSeconds, withFormattedMinutes) => {
  const seconds = Math.floor(value);
  const minutes = Math.floor(seconds / 60);
  const restSeconds = `0${seconds - minutes * 60}`.substr(-2);
  const formattedMinutes = !withFormattedMinutes || String(minutes).length > 1
    ? minutes
    : `0${minutes}`.substr(-2);

  return withSeconds ? `${formattedMinutes}:${restSeconds}` : minutes;
};
