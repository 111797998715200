import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { classesShape } from 'utils/shapes/classesShape';

import styles from './CardContent.styles';

const CardContentComponent = ({
  children,
  classes,
  withPadding,
  staticWidth,
  bottomMargin,
  withSidePadding,
  withWidth,
  withoutBorder,
}) => (
  <Card
    className={classNames(classes.card, {
      [classes.bottomMargin]: bottomMargin,
      [classes.maxWidth]: staticWidth,
      [classes.minWidth]: !staticWidth,
      [classes.width]: withWidth,
      [classes.sidePadding]: withSidePadding,
      [classes.withoutBorder]: withoutBorder,
    })}
  >
    <CardContent
      classes={{
        root: withPadding ? null : classes.disablePadding,
      }}
    >
      {children}
    </CardContent>
  </Card>
);

CardContentComponent.propTypes = {
  bottomMargin: PropTypes.bool,
  children: PropTypes.node,
  classes: classesShape,
  staticWidth: PropTypes.bool,
  withoutBorder: PropTypes.bool,
  withPadding: PropTypes.bool,
  withSidePadding: PropTypes.bool,
  withWidth: PropTypes.bool,
};

CardContentComponent.defaultProps = {
  bottomMargin: true,
  children: null,
  classes: {},
  withPadding: true,
  staticWidth: false,
  withSidePadding: false,
  withWidth: false,
  withoutBorder: false,
};

export default withStyles(styles)(CardContentComponent);
