import { defineMessages } from 'react-intl';
import moment from 'moment';

const messages = defineMessages({
  tooLateDate: 'Pole "od" nie może być większe od pola "do"',
});

export default (date, laterDate) => {
  const result = {};
  let isValid = true;

  if (moment.isMoment(date) && moment.isMoment(laterDate)) {
    if (moment(date).isValid() && moment(laterDate).isValid()) {
      isValid = date.isSameOrBefore(laterDate, 'day');
    }
  }

  result.isValid = isValid;
  result.message = messages.tooLateDate;

  return result;
};
