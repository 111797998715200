import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import _isNaN from 'lodash/isNaN';
import _isNumber from 'lodash/isNumber';
import _replace from 'lodash/replace';
import _startsWith from 'lodash/startsWith';
import _includes from 'lodash/includes';
import _size from 'lodash/size';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FormField from 'components/FormField';
import { intlShape } from 'utils/shapes/intlShape';
import { classesShape } from 'utils/shapes/classesShape';
import checkDecimalPlaces from 'utils/checkDecimalPlaces';
import preventNonNumericalInput from 'utils/preventNonNumericalInput';

import config from 'config';

import styles from './AmountInput.styles';
import {
  errorsShape,
  touchedShape,
} from './AmountInput.shapes';
import { formatValue } from './AmountInput.utils';

const propTypes = {
  classes: classesShape.isRequired,
  errors: errorsShape.isRequired,
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  touched: touchedShape.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  customLabel: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isBigValueDark: PropTypes.bool,
  label: PropTypes.string,
  maxDecimalPlaces: PropTypes.number,
  variant: PropTypes.string,
};

const defaultProps = {
  customLabel: '',
  isBigValueDark: false,
  disabled: false,
  label: '',
  maxDecimalPlaces: null,
  fullWidth: false,
  variant: 'standard',
};

const AmountInput = ({
  classes,
  isBigValueDark,
  customLabel,
  disabled,
  errors,
  fullWidth,
  intl,
  value,
  variant,
  label,
  name,
  touched,
  onChange,
  onBlur,
  maxDecimalPlaces,
}) => {
  const initialFormattedValue = formatValue(value, intl, config.priceFormatter);
  const [editMode, setEditMode] = useState(false);
  const [formattedValue, setFormattedValue] = useState(initialFormattedValue);

  const onInputBlur = () => {
    setEditMode(false);
    onBlur(name);
  };

  const onInputChange = (currentName, currentValue) => {
    const currentNumber = Number(currentValue);
    const isCurrentNumberNaN = _isNaN(currentNumber);
    const replacedValue = _replace(currentValue, /\./, ',');

    if (_isNumber(maxDecimalPlaces)) {
      if (checkDecimalPlaces(maxDecimalPlaces, replacedValue)
        && _size(replacedValue) <= config.maxAmountLength) {
        if (_startsWith(replacedValue, '0') && !_includes(replacedValue, ',')) {
          onChange(name, Number(replacedValue));
        } else {
          onChange(name, replacedValue);
        }
        const newFormattedValue = formatValue(replacedValue, intl, config.priceFormatter);

        setFormattedValue(newFormattedValue);
      }
    } else if (isCurrentNumberNaN) {
      onChange(currentName, value);
    } else {
      const parsedValue = parseInt(currentValue);
      const isParsedValueNaN = _isNaN(parsedValue);

      if (isParsedValueNaN) {
        onChange(name, currentValue);
        setFormattedValue(currentValue);
      } else {
        const newFormattedValue = formatValue(currentValue, intl, config.priceFormatter);
        setFormattedValue(newFormattedValue);
        onChange(name, parsedValue);
      }
    }
  };

  const inputClassNames = classNames({
    [classes.isBigValueDark]: isBigValueDark,
  });
  const InputProps = {
    classes: {
      root: inputClassNames,
    },
  };

  const hasError = !!errors[name] && !!touched[name];

  return (
    <>
      { customLabel && (
        <Typography className={classes.label} variant="h5">
          {customLabel}
        </Typography>
      )}
      <FormField
        errors={errors}
        touched={touched}
        name={name}
        onBlur={onInputBlur}
        onChange={onInputChange}
        fullWidth={fullWidth}
      >
        <TextField
          variant={variant}
          type="text"
          label={label}
          value={editMode ? value : formattedValue}
          onFocus={() => setEditMode(true)}
          InputProps={InputProps}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            className: variant === 'outlined' && classes.inputRoot,
          }}
          onKeyPress={(event) => preventNonNumericalInput(event, true)}
          disabled={disabled}
          error={hasError}
        />
      </FormField>
    </>
  );
};

AmountInput.propTypes = propTypes;

AmountInput.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(AmountInput));
