import React from 'react';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isNull from 'lodash/isNull';
import _first from 'lodash/first';
import _mean from 'lodash/mean';
import _sumBy from 'lodash/sumBy';
import _groupBy from 'lodash/groupBy';
import _flatten from 'lodash/flatten';
import _join from 'lodash/join';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _includes from 'lodash/includes';
import _values from 'lodash/values';
import _uniq from 'lodash/uniq';
import _every from 'lodash/every';
import _maxBy from 'lodash/maxBy';
import moment from 'moment';

import getDateTimeFromApi from 'utils/getDateTimeFromApi';
import formatFullName from 'utils/formatFullName';
import getAnonymousPatientName from 'utils/getAnonymousPatientName';

import UndesirableSurveyEvaluationsIcon from 'components/pages/PatientExamination/components/UndesirableSurveyEvaluationsIcon';
import EducationalNeedsName from 'components/pages/PatientList/components/EducationalNeedsName';
import TooltipCell from 'components/pages/PatientList/components/TooltipCell';
import messages from 'components/pages/GroupExaminationDetails/GroupExaminationDetails.messages';

import {
  formatDimensionCodes,
  formatGraphData,
  getTableData,
  formatExaminationSymbol,
  getAllDimensionsCodes,
  getResearchToolScales,
  formatDescriptionData,
  formatDescriptionReportData,
  getDataWithComparingResults,
} from 'containers/ExaminationDetails/utils';
import scaleNames from 'utils/constants/standarizedScales';
import groupSurveyStatuses from 'utils/constants/groupSurveyStatuses';
import config from 'config';

const { comparisonMethods } = config;

export const formatNotes = (notes) => _map(notes, (noteData) => ({
  date: getDateTimeFromApi(_get(noteData, 'created_at', '')),
  note: _get(noteData, 'note', ''),
  diagnostician: formatFullName(noteData.user),
  noteNumber: _get(noteData, 'note_order', ''),
}));

export const getIcons = (
  hasUndesirableSurveyEvaluations,
  hasSpecialEducationalNeeds,
  deletedAt,
) => {
  const icons = [];

  if (hasUndesirableSurveyEvaluations) {
    icons.push(<UndesirableSurveyEvaluationsIcon />);
  }

  if (hasSpecialEducationalNeeds) {
    icons.push(<EducationalNeedsName />);
  }

  if (deletedAt) {
    icons.push(<TooltipCell />);
  }

  return icons;
};

const getPatientClassAndSection = (classNumber, section) => (section ? `${classNumber}${section}` : classNumber);

export const getAllSurveyCodes = (examinationDetails) => {
  const surveyCodes = _map(_get(examinationDetails, 'survey_tools', []), (surveyTool) => surveyTool.code);

  return _join(surveyCodes, ', ');
};

const redirectToPatientStatuses = [groupSurveyStatuses.ENDED, groupSurveyStatuses.SUSPENDED];

const formatPatientName = (patient, intl) => {
  if (intl) {
    return (!patient.first_name && !patient.last_name)
      ? intl.formatMessage(messages.anonymous, { id: patient.id }) : formatFullName(patient);
  }

  return (!patient.first_name && !patient.last_name)
    ? getAnonymousPatientName(patient.id) : formatFullName(patient);
};

export const mapPatients = (items, intl) => _map(items, (item) => ({
  id: item.id,
  apiId: item.patient.id,
  patient: formatPatientName(item.patient, intl),
  classAndSection: getPatientClassAndSection(_get(item, 'patient.school_class'), _get(item, 'patient.school_class_section')),
  examinationCode: item.code,
  status: item.status,
  resetDisabled: item.status === groupSurveyStatuses.IN_PROGRESS,
  redirectDisabled: !_includes(redirectToPatientStatuses, item.status),
  icons: getIcons(
    item.has_undesirable_survey_evaluations,
    item.patient.has_special_educational_needs,
    item.patient.deleted_at,
  ),
  isDeleted: item.patient.deleted_at !== null,
}));

export const getChangingSurvey = (data, batteryId, surveyId) => _find(
  _get(_find(data, (battery) => battery.id === batteryId), 'surveyTools', []),
  (survey) => survey.id === surveyId,
);

const getAvarageFromArray = (arr) => _mean(arr);

const getStandardCategoricalScaleValue = (scales, ten) => {
  const scale = _find(scales, (s) => {
    if (s.upper_limit_ten === null) {
      return false;
    }
    return s.upper_limit_ten >= ten;
  }) || _last(scales);

  return scale;
};

export const formatSurveyEvaluationData = (surveyPatients, standardScaleData, scalesData) => {
  const sessionPatientId = _get(_maxBy(
    _filter(surveyPatients, (surveyPatient) => surveyPatient.patient),
    (patient) => moment(patient.started_at).unix(),
  ), 'id');
  const allEvaluations = _flatten(
    _map(_filter(surveyPatients, (surveyPatient) => surveyPatient.patient), (surveyPatient) => _map(
      surveyPatient.survey_evaluations,
      (evaluation) => ({ ...evaluation, sex: surveyPatient.patient.sex }),
    )),
  );

  const groupedEvaluations = _groupBy(
    allEvaluations, (evaluation) => evaluation.scale_parameter.scale.id,
  );
  const standardCategoricalScaleValues = standardScaleData.standard_categorical_scale_values;

  return _map(groupedEvaluations, (groupedEvaluation) => ({
    standard_categorical_scale_value_description: {
      description: _get(_find(scalesData, (s) => _first(_map(groupedEvaluation, 'scale_parameter')).scale_id === s.id), 'description', ''),
      standard_categorical_scale_value: getStandardCategoricalScaleValue(
        standardCategoricalScaleValues,
        getAvarageFromArray(_map(groupedEvaluation, 'ten')),
      ),
    },
    confidence_interval: [
      getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval[0]')),
      getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval[1]')),
    ],
    confidence_interval_standardized_value: [
      getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval_standardized_value[0]')),
      getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval_standardized_value[1]')),
    ],
    session_patient_id: sessionPatientId,
    session_patient: _maxBy(surveyPatients, (patient) => moment(patient.started_at).unix()),
    scale_parameter: _first(_map(groupedEvaluation, 'scale_parameter')),
    scale_parameter_id: _first(_map(groupedEvaluation, 'scale_parameter_id')),
    standardized_results: {
      raw: {
        sten: getAvarageFromArray(_map(groupedEvaluation, 'sten')),
        ten: getAvarageFromArray(_map(groupedEvaluation, 'ten')),
        stanin: getAvarageFromArray(_map(groupedEvaluation, 'stanin')),
      },
      rounded: {
        sten: getAvarageFromArray(_map(groupedEvaluation, 'sten_rounded')),
        ten: getAvarageFromArray(_map(groupedEvaluation, 'ten_rounded')),
        stanin: getAvarageFromArray(_map(groupedEvaluation, 'stanin_rounded')),
      },
      confidence_interval: {
        percentile: [
          getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval_percentile[0]')),
          getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval_percentile[1]')),
        ],
        stanin: [
          getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval_stanin[0]')),
          getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval_stanin[1]')),
        ],
        sten: [
          getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval_sten[0]')),
          getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval_sten[1]')),
        ],
        ten: [
          getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval_ten[0]')),
          getAvarageFromArray(_map(groupedEvaluation, 'confidence_interval_ten[1]')),
        ],
      },
    },
    standardized_value: getAvarageFromArray(_map(groupedEvaluation, 'standardized_value')),
    value: getAvarageFromArray(_map(groupedEvaluation, 'value')),
  }));
};

export const formatResultData = ({
  sessionSurveyTool,
  sessionPatients,
  standarizedScale,
  sessionsToCompare,
  isViewOnly,
  standardizedScales,
  initialState,
  expandedDimensions = [],
  currentFilterValues = {},
  resultsToCompare = [],
}) => {
  const standardScaleData = sessionSurveyTool.standardScaleData;
  const surveyPatients = _filter(
    sessionPatients,
    (sessionPatient) => sessionPatient.session.survey_tool_id === sessionSurveyTool.id,
  );

  const scaleParametersData = sessionSurveyTool.psychometricParameters.scale_parameters;
  const scaleLoadsData = sessionSurveyTool.psychometricParameters.scale_charges;
  const scalesData = getResearchToolScales(sessionSurveyTool.psychometricParameters.scales);

  const formattedSurveyEvaluations = formatSurveyEvaluationData(
    surveyPatients,
    standardScaleData,
    scalesData,
  );

  const dimensionCodesData = formatDimensionCodes(
    scalesData,
    scaleLoadsData,
    scaleParametersData,
  );

  const allDimensionsCodes = getAllDimensionsCodes(scalesData);

  const filterValues = _isEmpty(currentFilterValues) ? {
    examinationsDates: [],
    comparisonMethod: comparisonMethods.horizontal,
    qualityFilter: 'showAll',
    dimensionTypeFilter: 'primaryDimension',
    primaryDimension: true,
    secondaryDimension: true,
    tertiaryDimension: true,
    standarizedScale: _isNull(standarizedScale)
      ? 3
      : standarizedScale,
    sex: 'all',
  } : currentFilterValues;

  if (_isEmpty(formattedSurveyEvaluations)) {
    return {
      battery: {
        ...sessionSurveyTool.battery,
        key: sessionSurveyTool.battery.id,
      },
      id: sessionSurveyTool.id,
      key: sessionSurveyTool.id,
      name: sessionSurveyTool.name,
      noData: true,
      avarageSurveyTime: _get(sessionSurveyTool, 'survey.average_session_time', 0),
      isViewOnly,
      examinationName: '',
      examinationSymbol: '',
      dimensionCodesData,
      metricsTableData: null,
      metricsTableDataExpanded: null,
      standarizedScales: _filter(
        standardizedScales, (scale) => scale.name !== scaleNames.CENTYL,
      ),
      graphData: null,
      graphDataExpanded: null,
      expandedDimensions,
      filterData: {
        filterValues,
        filterOptions: {
          ...initialState.filterData.filterOptions,
        },
      },
      resultsToCompare,
      initialData: {
        allDimensionsCodes,
        standardScaleData,
        surveyEvaluationData: formattedSurveyEvaluations,
        dimensionCodesData,
        graphData: null,
        sessionPatientId: null,
        scalesData,
        batteryId: sessionSurveyTool.battery.id,
        batteryCode: sessionSurveyTool.battery.code,
        sessionsToCompare,
        sessionPatients,
        optionsToCompareNextPage: 1,
      },
    };
  }

  const dataWithComparingResults = getDataWithComparingResults(
    formattedSurveyEvaluations,
    resultsToCompare,
    filterValues,
  );

  const graphData = formatGraphData(
    standardScaleData,
    dataWithComparingResults,
    dimensionCodesData,
    expandedDimensions,
    filterValues,
    sessionsToCompare,
    '',
    scalesData,
    true,
  );

  const graphDataExpanded = formatGraphData(
    standardScaleData,
    formattedSurveyEvaluations,
    dimensionCodesData,
    allDimensionsCodes,
    filterValues,
    sessionsToCompare,
    '',
    scalesData,
    true,
  );

  const examinationName = sessionSurveyTool.name;
  const examinationSymbol = formatExaminationSymbol(
    sessionSurveyTool,
    _maxBy(_filter(surveyPatients, (session) => !_isNull(session.started_at)),
      (sessionPatient) => new Date(sessionPatient.started_at).getTime()),
  );

  const sessionPatientId = _first(formattedSurveyEvaluations).session_patient_id;

  const metricsTableData = getTableData(
    graphData,
    sessionPatientId,
    filterValues,
  );
  const metricsTableDataExpanded = getTableData(
    graphDataExpanded,
    sessionPatientId,
    filterValues,
  );

  const descriptionData = formatDescriptionData(
    scalesData,
    scaleLoadsData,
    scaleParametersData,
    formattedSurveyEvaluations,
    {},
  );

  const descriptionReportData = formatDescriptionReportData(
    descriptionData.dimensionData,
  );

  return {
    battery: {
      ...sessionSurveyTool.battery,
      key: sessionSurveyTool.battery.id,
    },
    id: sessionSurveyTool.id,
    key: sessionSurveyTool.id,
    name: sessionSurveyTool.name,
    avarageSurveyTime: _get(sessionSurveyTool, 'survey.average_session_time', 0),
    isViewOnly,
    examinationName,
    examinationSymbol,
    dimensionCodesData,
    metricsTableData,
    metricsTableDataExpanded,
    standarizedScales: _filter(
      standardizedScales, (scale) => scale.name !== scaleNames.CENTYL,
    ),
    graphData,
    graphDataExpanded,
    expandedDimensions,
    filterData: {
      filterValues,
      filterOptions: {
        ...initialState.filterData.filterOptions,
      },
    },
    descriptionData,
    descriptionReportData,
    resultsToCompare,
    initialData: {
      allDimensionsCodes,
      standardScaleData,
      surveyEvaluationData: formattedSurveyEvaluations,
      dimensionCodesData,
      graphData,
      sessionPatientId,
      scalesData,
      batteryId: sessionSurveyTool.battery.id,
      batteryCode: sessionSurveyTool.battery.code,
      sessionsToCompare,
      sessionPatients,
      optionsToCompareNextPage: 1,
    },
  };
};

export const getAvarageSurveyTime = (sessions) => {
  const patientIds = _uniq(_map(sessions, (session) => session.patient_id));

  const filteredPatients = _filter(patientIds, (patientId) => {
    const patientSessions = _filter(sessions, (session) => session.patient_id === patientId);

    return _every(
      patientSessions,
      (s) => s.session_duration !== undefined && s.session_duration !== 0,
    );
  });

  // this value groups patient sessions and maps to a sum of session_duration of every patient
  const sessionPatientTimes = _map(_values(_groupBy(
    _filter(sessions, (session) => _includes(filteredPatients, session.patient_id)),
    (session) => session.patient_id,
  )), (group) => _sumBy(group, (g) => g.session_duration));

  const result = _mean(sessionPatientTimes) || 0;

  return Math.round(result);
};

export const getIconFillColor = (isDisabled) => (isDisabled ? 'rgba(0, 0, 0, 0.26)' : 'white');
