import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import VatInvoiceAddEditView from 'components/pages/VATInvoiceAddEdit';

import {
  onDownload,
  onFileUnload,
  onFileUpload,
  onSubmit,
  onCancel,
  loadPageData,
} from './store';

class VatInvoiceAddEditContainer extends React.Component {
  static propTypes = {
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    modalProps: PropTypes.objectOf(PropTypes.any).isRequired,
    onCancel: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    onFileUnload: PropTypes.func.isRequired,
    onFileUpload: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { id, orderId } = this.props.modalProps;
    this.props.loadPageData(orderId, id);
  }

  render() {
    return this.props.isLoadedPage ? (
      <VatInvoiceAddEditView {...this.props} />
    ) : null;
  }
}

const mapDispatchToProps = {
  onDownload,
  onFileUnload,
  onFileUpload,
  onSubmit,
  onCancel,
  loadPageData,
};

const mapStateToProps = (state) => ({
  ...state.VatInvoiceAddEdit,
  userRole: state.Global.userData.role_id,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VatInvoiceAddEditContainer);
