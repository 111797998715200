import _map from 'lodash/map';
import _get from 'lodash/get';
import formatCodeWithName from 'utils/formatCodeWithName';

const ACTIVE = 'ACTIVE';

export default (batteries) => _map(
  batteries,
  (battery) => ({
    id: battery.id,
    name: formatCodeWithName(battery),
    isActive: _get(battery, 'pivot.status', null) === ACTIVE,
    isUsed: battery.has_session_patients_in_clinic,
  }),
);
