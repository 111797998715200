import { defineMessages } from 'react-intl';
import moment from 'moment';

const messages = defineMessages({
  currentDate: 'Data nie może być większa bądź równa dzisiejszej',
});

export default (date) => {
  const result = {};
  let isValid = true;

  if (moment.isMoment(date)) {
    if (moment(date).isValid()) {
      isValid = date.isBefore(moment(), 'day');
    }
  }

  result.isValid = isValid;
  result.message = messages.currentDate;

  return result;
};
