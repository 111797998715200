import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import Tooltip from 'components/MenuItemTooltip';

import useStyle from './MenuListItem.styles';

const MenuListItem = ({
  isActive,
  isScroll,
  icon,
  text,
  variant,
  isCollapsedMenu,
  onClick,
}) => {
  const classes = useStyle({ isCollapsedMenu });
  const item = variant === 'default' ? (
    <ListItem
      button
      alignItems="center"
      selected={isActive}
      className={classes.listItem}
      classes={{ selected: classes.selectedListItem }}
      onClick={onClick}
    >
      <ListItemIcon
        classes={{ root: classes.listItemIconRoot }}
        className={classNames({
          [classes.activeIcon]: isActive,
        })}
      >
        {icon}
      </ListItemIcon>
      {
        !isCollapsedMenu && (
          <ListItemText
            primary={text}
            classes={{
              primary: isActive
                ? classes.listItemTextRootSelected
                : classes.listItemText,
            }}
          />
        )
      }
    </ListItem>
  ) : (
    <div
      className={classNames(classes.userProfileItem, {
        [classes.userProfileItemActive]: isActive,
      })}
      onClick={onClick}
    >
      {icon}
      {
        !isCollapsedMenu && (
          <Typography
            className={classNames(classes.userProfileMessage, {
              [classes.userProfileItemActive]: isActive,
            })}
            variant="body2"
          >
            {text}
          </Typography>
        )
      }
    </div>
  );

  return isCollapsedMenu ? (
    <Tooltip
      title={text}
      isScroll={isScroll}
    >
      {item}
    </Tooltip>
  ) : item;
};

MenuListItem.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isCollapsedMenu: PropTypes.bool,
  isScroll: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  variant: PropTypes.oneOf(['default', 'profile']),
};

MenuListItem.defaultProps = {
  isActive: false,
  isScroll: false,
  isCollapsedMenu: false,
  text: '',
  variant: 'default',
};

export default MenuListItem;
