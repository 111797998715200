import _forEach from 'lodash/forEach';
import _get from 'lodash/get';

import apiErrorMappings from 'utils/apiErrorMappings';

export default (errors, setFieldError, fieldsList = {}) => {
  _forEach(errors, (value, key) => {
    const fieldName = _get(fieldsList, key, key);
    if (fieldName) {
      if (apiErrorMappings[value[0]]) {
        setFieldError(fieldName, apiErrorMappings[value[0]]);
      } else {
        setFieldError(fieldName, value[0]);
      }
    }
  });
};
