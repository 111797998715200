import { defineMessages } from 'react-intl';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _find from 'lodash/find';
import _findLast from 'lodash/findLast';

const messages = defineMessages({
  cannotBeEmpty: 'Pole nie może być puste',
  dimensionsMustBeUnique: 'Wymiary muszą być różne',
});

export default (value) => {
  const result = {
    fields: {},
  };

  result.isValid = true;

  _forEach(value, (el, index) => {
    if (_isEmpty(el.dimension)) {
      result.fields[`dimension-${index}`] = messages.cannotBeEmpty;
      result.isValid = false;
    }

    if (_isEmpty(el.values)) {
      result.fields[`dimensionValues-${index}`] = messages.cannotBeEmpty;
      result.isValid = false;
    }

    if (
      !_isEqual(
        _find(value, (el2) => el2.dimension === el.dimension),
        _findLast(value, (el2) => el2.dimension === el.dimension),
      )
    ) {
      result.fields[`dimension-${index}`] = messages.dimensionsMustBeUnique;
      result.isValid = false;
    }
  });

  return result;
};
