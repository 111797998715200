import PropTypes from 'prop-types';

const errorsShape = PropTypes.objectOf(PropTypes.string);

const touchedShape = PropTypes.objectOf(PropTypes.bool);

export {
  errorsShape,
  touchedShape,
};
