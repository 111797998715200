import { push } from 'connected-react-router';
import dialogTexts from 'utils/dialogTexts';

import _isEmpty from 'lodash/isEmpty';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import config from 'config';

import {
  hideLoader, showSnackbar, showLoader, showTransparentLoader,
  openDialog, setPage, setRowsPerPage, setSortingData,
} from 'containers/store';

const initialState = {
  isLoadedPage: false,
  toolId: null,
  isToolActive: false,
  isViewOnly: false,
};

const sortFieldsMapping = {
  name: 'name',
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'RESEARCH_TOOL_RESULTS_EVALUATION/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'RESEARCH_TOOL_RESULTS_EVALUATION/CLEAR_STORE',
  SET_FILTERED_RESULTS_EVALUATIONS: 'RESEARCH_TOOL_RESULTS_EVALUATION/SET_FILTERED_RESULTS_EVALUATIONS',
};

const mapEvaluations = (items, startingPosition) => items.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: el.name,
  description: el.description,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const isToolActive = action.status === config.researchToolStatuses.active;

      return {
        ...state,
        isLoadedPage: true,
        isToolActive,
        toolId: action.toolId,
        items: mapEvaluations(action.response.data.items, 0),
        totalItemsCount: action.response.data.total,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_FILTERED_RESULTS_EVALUATIONS: {
      return {
        ...state,
        items: mapEvaluations(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const setFilteredResultsEvaluations = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_RESULTS_EVALUATIONS,
  response,
  startCountFrom,
});

const getResultsEvaluations = (params, toolId) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    filterBySurveyToolId: toolId,
    sortedBy: 'asc',
    orderBy: 'name',
  };

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('scs_values_combinations_sets', data);

  return ApiManager.request('get', dispatch, url);
};

const loadResultsEvaluations = (successMessage) => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);
  const { toolId } = getStore().ResearchToolResults;

  dispatch(getResultsEvaluations(data, toolId)).then((response) => {
    dispatch(setFilteredResultsEvaluations(response, startCountFrom));
    if (!_isEmpty(successMessage)) {
      dispatch(showSnackbar(successMessage));
    }
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
  });
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(loadResultsEvaluations());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(loadResultsEvaluations());
};

const onDeleteAccept = (id) => (dispatch) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `scs_values_combinations_sets/${id}`).then(() => {
    const successMessage = snackbarMessages.evaluationDeletedSuccessfully;
    dispatch(loadResultsEvaluations(successMessage));
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
  });
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteResultsEvaluation,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const onEdit = (id) => (dispatch, getStore) => {
  const { toolId } = getStore().ResearchToolResults;
  dispatch(push('/evaluationsAddEdit', { id, toolId }));
};

export const onAdd = () => (dispatch, getStore) => {
  const { toolId } = getStore().ResearchToolResults;
  dispatch(push('/evaluationsAddEdit', { toolId }));
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(loadResultsEvaluations());
};

const loadPageSuccess = (toolId, response, status) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  toolId,
  response,
  status,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const loadPageData = (toolId) => (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(clearStore());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const toolOptions = getStore().ResearchToolOptions.initialValues;
  const { status } = toolOptions;

  dispatch(getResultsEvaluations(data, toolId)).then((response) => {
    dispatch(loadPageSuccess(toolId, response, status));
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
  });
};
