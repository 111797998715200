import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import VolunteerActivism from 'assets/img/VolunteerActivism.svg';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import useStyle from './EducationalNeedsName.styles';
import messages from './EducationalNeedsName.messages';

const EducationalNeedsName = ({ name }) => {
  const classes = useStyle();
  const intl = useIntl();

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="body1"
        className={classes.name}
        component="span"
      >
        {name}
      </Typography>
      <Tooltip
        arrow
        title={intl.formatMessage(messages.hasSpecialEducationalNeeds)}
        placement="bottom"
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      >
        <img className={classes.icon} src={VolunteerActivism} alt="volunteer activism" />
      </Tooltip>
    </div>
  );
};

EducationalNeedsName.propTypes = {
  name: PropTypes.string,
};

EducationalNeedsName.defaultProps = {
  name: '',
};

export default EducationalNeedsName;
