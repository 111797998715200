import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { classesShape } from 'utils/shapes/classesShape';
import {
  STARTED,
  SUSPENDED,
  ENDED,
  PAUSED,
  LINK,
} from 'utils/constants/examinationStatuses';

import styles from './ExaminationStatus.styles';
import messages from './ExaminationStatus.messages';

const statuses = {
  [PAUSED]: <FormattedMessage {...messages.paused} />,
  [SUSPENDED]: <FormattedMessage {...messages.suspended} />,
  [STARTED]: <FormattedMessage {...messages.started} />,
  [ENDED]: <FormattedMessage {...messages.ended} />,
  [LINK]: <FormattedMessage {...messages.link} />,
};

const ExaminationStatuses = ({
  classes,
  status,
}) => {
  const examinationStatus = statuses[status] || '';

  return examinationStatus === statuses.STARTED ? (
    <Typography
      variant="body2"
      className={classes.startedExamination}
    >
      {examinationStatus}
      <TimelapseIcon className={classes.icon} />
    </Typography>
  ) : examinationStatus;
};

ExaminationStatuses.propTypes = {
  status: PropTypes.string.isRequired,
  classes: classesShape,
};

ExaminationStatuses.defaultProps = {
  classes: {},
};

export default withStyles(styles)(ExaminationStatuses);
