import { defineMessages } from 'react-intl';

export default defineMessages({
  globalError: 'Wystąpił błąd podczas wczytywania. Zgłoś problem do Diagmatic.',
  wrongData: 'Niepoprawne dane',
  incorrectLoginData: 'Niepoprawny adres e-mail lub hasło',
  wrondClinicId: 'Użytkownik nie jest przypisany do żadnej placówki',
  logoutSuccess: 'Zostałeś pomyślnie wylogowany',
  passwordChanged: 'Hasło zostało zmienione',
  emptyDimensionName: 'Wystąpił problem podczas sortowania. Każdy wymiar musi mieć nazwę',
  wrongPassword: 'Niewłaściwe hasło',
  succesfullyResetedPassword: 'Jeśli użytkownik istnieje, link do resetu hasła został wysłany na e-mail',
  userAddedSuccessfully: 'Użytkownik dodany pomyślnie',
  userEditedSuccessfully: 'Użytkownik zmieniony pomyślnie',
  answerAddedSuccessfully: 'Zestaw dodany pomyślnie',
  answerEditedSuccessfully: 'Zestaw zmieniony pomyślnie',
  userDeleted: 'Użytkownik usunięty pomyślnie',
  replacementDeleted: 'Zastępstwo zostało odwołane',
  replacementAdded: 'Zastępstwo zostało dodane',
  organizationAddedSuccessfully: 'Placówka dodana pomyślnie',
  organizationEditedSuccessfully: 'Placówka zmieniona pomyślnie',
  organizationDeleted: 'Placówka usunięta pomyślnie',
  questionDeleted: 'Pytanie usunięte pomyślnie',
  questionAddedSuccessfully: 'Pytanie dodane pomyślnie',
  questionEditedSuccessfully: 'Pytanie zmodyfikowane pomyślnie',
  participantEditedSuccessfully: 'Dane zmodyfikowane pomyślnie',
  setAdded: 'Pomyślnie dodany nowy zestaw odpowiedzi',
  answerDeleted: 'Zestaw usunięty pomyślnie',
  surveyEdited: 'Ankieta zmodyfikowana pomyślnie',
  surveyDeleted: 'Ankieta usunięta pomyślnie',
  surveyAdded: 'Ankieta dodana pomyślnie',
  duplicateQuestions: 'Pytania nie mogą się powtarzać',
  unsaveData: 'Uwaga! Niezapisane dane formularza. Czy na pewno chcesz opuścić stronę?',
  networkError: 'Błąd połączenia z internetem',
  questionParamsWasRemoved: 'Niektóre powiązania pytań musiały zostać usunięte',
  researchToolDeleted: 'Narzędzie badawcze usunięte pomyślnie',
  scaleEditedSuccessfully: 'Opisy wymiaru zmienione pomyślnie',
  scaleDeleted: 'Wymiar usunięty pomyślnie',
  standardScaleAddedSuccessfully: 'Kategorialna skala dodana pomyślnie',
  standardScaleEditedSuccessfully: 'Kategorialna skala zmieniona pomyślnie',
  standardScaleDeleted: 'Kategorialna skala usunięta pomyślnie',
  batteryDeleted: 'Bateria usunięta pomyślnie',
  referenceGroupDeleted: 'Podział na grupy odniesienia usunięty pomyślnie',
  batteryAddedSuccessfully: 'Bateria dodana pomyślnie',
  batteryEditedSuccessfully: 'Bateria zmieniona pomyślnie',
  toolAddedSuccessfully: 'Narzędzie badawcze dodane pomyślnie',
  toolEditedSuccessfully: 'Narzędzie badawcze zmienione pomyślnie',
  psychometricModelAddedSuccessfully: 'Model psychometryczny dodany pomyślnie',
  differenceEditedSuccessfully: 'Trudność odpowiedzi zmieniona pomyślnie',
  discriminationEditedSuccessfully: 'Dyskryminacja zmieniona pomyślnie',
  questionParametersEditedSuccessfully: 'Parametry pytania zmienione pomyślnie',
  scaleParametersEditedSuccessfully: 'Parametry skali zmienione pomyślnie',
  chargeEditedSuccessfully: 'Ładunek skal zmieniony pomyślnie',
  covarianceEditedSuccessfully: 'Kowariancja skal zmieniona pomyślnie',
  refGroupSetAddedSuccessfully: 'Podział na grupy odniesienia dodany pomyślnie',
  refGroupSetDeletedSuccessfully: 'Podział na grupy odniesienia usunięty pomyślnie',
  refGroupSetEditedSuccessfully: 'Podział na grupy odniesienia zmieniony pomyślnie',
  notEmptySurvey: 'Wpisz w pole nazwę ankiety lub jej fragment, a następnie wybierz żądaną ankietę z listy podpowiedzi, które pojawią się poniżej pola',
  facilityEdit: 'Placówka została zmieniona pomyślnie',
  userProfileEdited: 'Profil użytkownika zmieniony pomyślnie',
  patientAdded: 'Klient dodany pomyślnie',
  patientEdited: 'Klient zmieniony pomyślnie',
  patientRemoved: 'Klient usunięty pomyślnie',
  patientRestored: 'Klient przywrócony pomyślnie',
  facilityAdded: 'Placówka została dodana pomyślnie',
  facilityEdited: 'Placówka została zmieniona pomyślnie',
  facilityExported: 'Zlecono eksport placówki',
  subscriptionsAdded: 'Abonament został przypisany',
  diagnosticianAdded: 'Diagnosta dodany pomyślnie',
  diagnosticianEdited: 'Diagnosta zmieniony pomyślnie',
  messageSend: 'Wiadomość została wysłana',
  wrongFileTypeOrSize: 'Niepoprawne rozszerzenie pliku lub rozmiar',
  wrongFileFormat: 'Nieprawidłowe rozszerzenie pliku',
  videoTutorialAdded: 'Film instruktażowy został dodany pomyślnie',
  videoTutorialEdited: 'Film instruktażowy został zmieniony pomyślnie',
  videoTutorialRemoved: 'Film instruktażowy został usunięty pomyślnie',
  evaluationAddedSuccessfully: 'Kombinacja wyników została dodana',
  evaluationDeletedSuccessfully: 'Kombinacja wyników została usunięta',
  evaluationEditedSuccessfully: 'Kombinacja wyników została zmieniona',
  notFoundPatient: 'Nie znaleziono klienta',
  manualTooLargeSize: 'Podręcznik powinien mieć rozmiar poniżej 8mb i być formatu pdf',
  documentTooLarge: 'Plik powinien mieć rozmiar poniżej 8mb',
  manualBadVersion: 'Podany numer wersji podręcznika już istnieje',
  manualShortcutBadVersion: 'Podany numer wersji skrótu podręcznika już istnieje',
  orderCompleted: 'Zamówienie zostało zaakceptowane',
  orderCanceled: 'Zamówienie zostało anulowane',
  orderUpdated: 'Zamówienie zostało zaktualizowane',
  notCalculated: 'Nie obliczono wyniku dla tego badania',
  surveySuspend: 'Badanie zostało przerwane',
  notAnswered: 'Nie udzielono odpowiedzi na żadne pytanie',
  sessionIsOver: 'Twoja sesja wygasła. Zaloguj się ponownie',
  fileDeletedSuccessfully: 'Plik został usunięty pomyślnie',
  batterySetToActivate: 'Aktywacja baterii została dodana do historii doładowań',
  facilityBatteriesEditedSucessfully: 'Lista baterii została zaktualizowana',
  topUpAdded: 'Zasilenie konta zostało dodane',
  facilitySettingsEditedSucessfully: 'Ustawienia placówki zostały zaktualizowane',
  tooManyFiles: 'Próbujesz załączyć więcej niż jeden plik. Taka operacja nie jest możliwa',
  packagesEditedSuccessfully: 'Dane pakietów zostały zaktualizowane',
  maxNameLength_255: 'Pole musi mieć poniżej 255 znaków',
  removeCoordinatorSecurity: 'Ze względów bezpieczeństwa nie możesz usunąć własnego konta. Poproś o usunięcie innego koordynatora w Twojej placówce.',
  cannotRemoveCollege: 'Nie można zmienić typu placówki "Uczelnia".',
  questionsIsEmpty: 'Ankieta musi zawierać przynajmniej jedno pytanie',
  examinationSuspended: 'To badanie zostało przerwane. Wróć do profilu osoby badanej.',
  emailSent: 'Wiadomość e-mail z linkiem do rozpoczęcia badania została wysłana',
  emailSentAgain: 'Link do badania został wysłany ponownie.',
  facilityDeleted: 'Placówka została usunięta',
  codeReset: 'Nowy kod dla badanego został wygenerowany',
  groupSurveyConfigured: 'Badanie grupowe skonfigurowane pomyślnie',
  groupSurveyDeleted: 'Badanie grupowe usunięte pomyślnie',
  patientsDoNotMatchCriteria: 'Co najmniej jedna z wybranych osób nie spełnia kryteriów jednego z wybranych kwestionariuszy',
  cannotResetCodeInProgress: 'Użytkownik jest w trakcie badania.',
  cannotEditStartedExamination: 'Nie można edytować badania, które się rozpoczęło',
  cannotDeleteStartedExamination: 'Nie można usunąć badania, które się rozpoczęło',
  cannotStopExaminationWithActiveSurveys: 'Nie można ręcznie zakończyć badania grupowego ponieważ co najmniej jeden badany jest w trakcie wypełniania kwestionariusza.',
  tokenExpired: 'Token autoryzacyjny wygasł',
});
