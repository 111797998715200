import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

import useStyle from './WithMatchingCriteriaIcon.styles';
import messages from './WithMatchingCriteriaIcon.messages';

const WithMatchingCriteriaIcon = ({ name }) => {
  const classes = useStyle();
  const intl = useIntl();

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="body1"
        className={classes.name}
        component="span"
      >
        {name}
      </Typography>
      <Tooltip
        arrow
        title={intl.formatMessage(messages.notMatchingCriteria)}
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
      >
        <InfoIcon className={classes.icon} />
      </Tooltip>
    </div>
  );
};

WithMatchingCriteriaIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default WithMatchingCriteriaIcon;
