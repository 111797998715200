import { defineMessages } from 'react-intl';
import _isNumber from 'lodash/isNumber';

const messages = defineMessages({
  fieldValueTooLarge: 'Wartość pola zbyt niska',
});

export default (value, minValue, parseValueToInteger) => {
  const result = {};
  result.isValid = true;

  const newValue = parseValueToInteger ? parseInt(value) : parseFloat(value);

  if (_isNumber(minValue)) {
    result.isValid = newValue > minValue;
    result.message = messages.fieldValueTooLarge;
  }

  return result;
};
