import config from 'config';
import _get from 'lodash/get';

import fetchVersionInJSON from 'utils/fetchVersionInJSON';

class VersionManager {
  #timeout = -1;

  #lastAppVersion = '';

  #forceRefresh = false;

  init = async () => {
    const version = await this.fetchVersion();
    this.setVersion(version);
  }

  fetchVersion = async () => {
    const data = await fetchVersionInJSON();

    return _get(data, 'version');
  }

  checkVersion = async () => {
    const version = await this.fetchVersion();

    this.setForceRefresh(false);
    if (version && version !== this.#lastAppVersion) {
      this.setVersion(version);

      this.setForceRefresh(true);
      window.location.reload();
    }
  }

  resetTimeout = () => {
    clearInterval(this.#timeout);
    this.#timeout = -1;
  }

  startTimeout = () => {
    this.#timeout = setTimeout(async () => {
      this.checkVersion();
      this.resetTimeout();
      this.startTimeout();
    }, config.versionCheckInterval);
  }

  setVersion = (version) => {
    this.#lastAppVersion = version;
  }

  getForceRefresh = () => this.#forceRefresh

  setForceRefresh = (forceRefresh) => {
    this.#forceRefresh = forceRefresh;
  }
}

export default new VersionManager();
