import { replace } from 'connected-react-router';

import { CHANGE_PASSWORD } from 'utils/constants/modalTypes';
import errorCatch from 'utils/errorCatch';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import getHomeRouteByRole from 'utils/getHomeRouteByRole';

import {
  hideLoaderMultipleRequests,
  setModal,
  getMeta,
  showLoaderMultipleRequests,
  hideLoader,
  showSnackbar,
  showTransparentLoader,
  loadUserData as reloadUserData,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  firstLogin: false,
  standardScale: {},
  initialValues: {
    standardScaleId: '',
  },
  userValues: {
    name: '',
    surname: '',
    specialization: '',
    email: '',
    roleId: '',
    specializationId: null,
  },
  userId: null,
};

const mapDataToValues = (data, specializations) => ({
  name: data.first_name || '',
  surname: data.last_name || '',
  specializationId: data.specialization_id,
  specialization: data.specialization_id ? specializations[data.specialization_id - 1].name : '',
  email: data.email || '',
  roleId: data.role_id || '',
  organizations: data.organizations || '',
  status: data.status || '',
  deactivationDate: data.deactivation_date || null,
});

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'USER_PROFILE/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'USER_PROFILE/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        firstLogin: action.firstLogin,
        userId: action.userId,
        userValues: mapDataToValues(
          action.responses.userData.data,
          action.responses.specializations.data,
        ),
        standardScale: action.responses.standardScale.data,
        initialValues: { standardScaleId: action.responses.userData.data.standard_scale_id || '' },
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (userId, responses, firstLogin) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  userId,
  responses,
  firstLogin,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadUserData = (id) => (dispatch) => ApiManager.request('get', dispatch, `users/${id}`);

export const onSubmit = (values) => async (dispatch, getStore) => {
  const { userId, firstLogin } = getStore().UserProfile;
  const {
    email,
    organizations,
    roleId,
    specializationId,
    status,
    name,
    surname,
    deactivationDate,
  } = getStore().UserProfile.userValues;

  const url = `users/${userId}`;
  const method = 'put';

  const data = {
    email,
    role_id: roleId,
    status,
    standard_scale_id: values.standardScaleId,
    specialization_id: specializationId,
    organizations: organizations.id,
    first_name: name,
    last_name: surname,
    deactivation_date: deactivationDate,
  };

  dispatch(showTransparentLoader());
  try {
    await ApiManager.request(method, dispatch, url, data);
    dispatch(showSnackbar(snackbarMessages.participantEditedSuccessfully));
    if (firstLogin) {
      dispatch(replace(getHomeRouteByRole(roleId)));
    }
    dispatch(reloadUserData());
  } catch (error) {
    errorCatch(error, dispatch, false, false);
  } finally {
    dispatch(hideLoader());
  }
};

export const onPasswordChange = () => (dispatch) => {
  dispatch(setModal(CHANGE_PASSWORD));
};

const loadSpecialization = () => (dispatch) => ApiManager.request('get', dispatch, 'specializations?orderBy=id&sortedBy=asc');

const loadStandardScale = () => (dispatch) => ApiManager.request('get', dispatch, 'standard_scales');

export const loadPageData = (userData, firstLogin) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoaderMultipleRequests());

  PromiseAll({
    meta: dispatch(getMeta()),
    userData: dispatch(loadUserData(userData.id)),
    standardScale: dispatch(loadStandardScale()),
    specializations: dispatch(loadSpecialization()),
  }).then((responses) => {
    dispatch(loadPageSuccess(responses.userData.data.id, responses, firstLogin));
  }).catch((error) => {
    errorCatch(error, dispatch, false, false);
  }).finally(() => dispatch(hideLoaderMultipleRequests()));
};
