const CREATED = 'CREATED';
const STARTED = 'STARTED';
const SUSPENDED = 'SUSPENDED';
const ENDED = 'ENDED';
const PAUSED = 'PAUSED';
const LINK = 'LINK';

export {
  CREATED,
  STARTED,
  SUSPENDED,
  ENDED,
  PAUSED,
  LINK,
};
