export default (theme) => ({
  title: {
    marginBottom: theme.spacing(1.75),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  largeMarginBottom: {
    marginBottom: theme.spacing(2),
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.textPrimary,
  },
});
