import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Wiadomość',
  cancel: 'Anuluj',
  save: 'Wyślij',
  topic: 'Temat',
  text: 'Treść wiadomości',
  chooseTopic: 'Wybierz temat z listy',
  footerPart1: 'Potrzebujesz pilnie pomocy w obsłudze systemu? Zadzwoń do nas pod numer',
  footerPart2: '+48 606 113 200, a my pomożemy Ci rozwiązać Twój problem. Jesteśmy dostępni od',
  footerPart3: 'poniedziałku do piątku w godzinach od 8.00 do 16.00, z wyjątkiem dni  wolnych.',
  textPlaceholder: 'Zacznij pisać',
});
