import { goBack } from 'connected-react-router';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _invert from 'lodash/invert';

import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import getFirstString from 'utils/getFirstString';
import gradientTypes from 'utils/constants/gradientTypes';
import pageTitles from 'utils/pageTitles';
import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader, setDrawerVisibility,
} from 'containers/store';

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SCALES_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'SCALES_ADD_EDIT/CLEAR_STORE',
};

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    id: '',
    name: '',
    code: '',
    description: '',
    reversedGradient: false,
    standarizedScaleValues: [],
  },
  isViewOnly: false,
};

const getInitialValues = (initialValues) => {
  const descriptions = {};
  _forEach(initialValues.standard_categorical_scale_value_descriptions, (el) => {
    descriptions[`valueDescription-${el.id}`] = el.description;
  });

  if (!_isEmpty(initialValues)) {
    return ({
      name: initialValues.name || '',
      code: initialValues.code,
      gradientType: _invert(gradientTypes)[initialValues.gradient_type] || null,
      description: initialValues.description || '',
      ...descriptions,
    });
  }

  return initialState.initialValues;
};

const getValuesLabels = (initialValues, standardScaleData) => (
  _map(initialValues.standard_categorical_scale_value_descriptions, (el) => {
    const valueId = _get(el, 'standard_categorical_scale_value_id', null);
    const value = _find(
      standardScaleData,
      (standardScaleEl) => standardScaleEl.id === valueId,
    );

    return {
      label: _get(value, 'label', ''),
      id: el.id,
    };
  }));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const initialValues = _get(action, 'responses.scaleData.data', {});
      const descriptionsData = _get(action, 'responses.scaleData.data.standard_categorical_scale_value_descriptions', {});
      const standardScaleData = _get(action, 'responses.standardScaleData.data.standard_categorical_scale_values', {});

      return {
        ...state,
        isLoadedPage: true,
        isViewOnly: action.isViewOnly,
        researchToolId: action.researchToolId,
        scaleId: action.scaleId,
        initialValues: getInitialValues(initialValues),
        descriptionsData,
        valuesLabels: getValuesLabels(initialValues, standardScaleData),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (researchToolId, scaleId, responses, isViewOnly) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  researchToolId,
  scaleId,
  responses,
  isViewOnly,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadScaleData = (id) => (dispatch) => ApiManager.request('get', dispatch, `scales/${id}`);

const getDescriptionsData = (descriptionsData, values) => _map(descriptionsData, (el) => ({
  ...el,
  description: values[`valueDescription-${el.id}`],
}));

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const store = getStore().ScaleEdit;
  const { descriptionsData } = store;
  const scaleId = store.scaleId;
  const url = `scales/${scaleId}`;
  const method = 'put';
  const message = snackbarMessages.scaleEditedSuccessfully;
  const data = {
    id: scaleId,
    name: values.name,
    code: values.code,
    gradient_type: gradientTypes[values.gradientType],
    description: values.description,
    standard_categorical_scale_value_descriptions: getDescriptionsData(descriptionsData, values),
  };

  dispatch(showTransparentLoader());

  ApiManager.request(method, dispatch, url, data).then(() => {
    dispatch(showSnackbar(message));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.scaleEdit);
      dispatch(showSnackbar(getFirstString(error.error.errors), true));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

const handleError = (error) => (dispatch) => {
  if (isBadRequest(error)) {
    dispatch(showSnackbar(snackbarMessages.wrongData));
  } else {
    dispatch(showSnackbar(snackbarMessages.globalError));
  }
  dispatch(goBack());
  dispatch(hideLoader());
};

const getStandardScaleData = (id) => (dispatch) => ApiManager.request('get', dispatch, `standard_categorical_scales/${id}`);

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const loadPageData = (researchToolId, id, isViewOnly, formatMessage) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());

  const promises = {};

  if (id) {
    promises.scaleData = dispatch(loadScaleData(id));
  }

  PromiseAll(promises).then(({ scaleData }) => {
    const standardScaleId = _get(
      scaleData,
      'data.standard_categorical_scale_value_descriptions[0].standard_categorical_scale_value.standard_categorical_scale_id',
      null,
    );
    const code = _get(scaleData, 'data.code', '');
    const pageTitle = isViewOnly ? pageTitles.scaleView : pageTitles.scaleEdit;
    dispatch(setDrawerVisibility({
      visibility: true,
      content: formatMessage(pageTitle, { code }),
    }));
    if (standardScaleId) {
      dispatch(getStandardScaleData(standardScaleId)).then((standardScaleData) => {
        dispatch(loadPageSuccess(researchToolId, id, { scaleData, standardScaleData }, isViewOnly));
      }).catch((error) => {
        dispatch(handleError(error));
      });
    } else {
      dispatch(loadPageSuccess(researchToolId, id, { scaleData }));
    }
    dispatch(hideLoader());
  }).catch((error) => {
    dispatch(handleError(error));
  });
};
