import { goBack } from 'connected-react-router';

import _map from 'lodash/map';
import _values from 'lodash/values';
import _replace from 'lodash/replace';

import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import moveLastElementToFirst from 'utils/moveLastElementToFirst';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader,
} from 'containers/store';
import config from 'config';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    packages: [],
  },
};

const mapDataToValues = (packages) => {
  const sortedPackages = moveLastElementToFirst(packages);

  return ({
    packages: _map(sortedPackages, (item, id) => ({
      id: item.id,
      type: _values(config.offerTypes)[id],
      header: item.name,
      creditsCount: item.amount_of_tokens,
      netAmount: _replace(item.net_charge_amount.toFixed(2), '.', ','),
      vatRate: item.vat_rate,
      freeCreditsCount: item.tokens_extra_credit || '',
    })),
  });
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'PACKAGES_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'PACKAGES_EDIT/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        initialValues: mapDataToValues(action.packagesData.data.items),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (packagesData) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  packagesData,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const getPackagesData = () => (dispatch) => {
  const params = {
    orderBy: 'tokens_extra_credit',
    sortedBy: 'asc',
  };

  const url = createURLWithQuery('packages', params);

  return ApiManager.request('get', dispatch, url);
};

export const onSubmit = (values) => (dispatch) => {
  const data = {
    packages: _map(values.packages, (el) => {
      const {
        id,
        header,
        creditsCount,
        netAmount,
        vatRate,
        freeCreditsCount,
      } = el;

      return ({
        id,
        name: header,
        amount_of_tokens: creditsCount,
        net_charge_amount: _replace(netAmount, ',', '.'),
        vat_rate: vatRate,
        tokens_extra_credit: freeCreditsCount,
      });
    }),
  };

  dispatch(showTransparentLoader());

  ApiManager.request('put', dispatch, 'packages', data).then(() => {
    dispatch(showSnackbar(snackbarMessages.packagesEditedSuccessfully));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const loadPageData = () => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());

  dispatch(getPackagesData()).then((response) => {
    dispatch(loadPageSuccess(response));
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
