import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    minHeight: ({ imgSrc }) => (imgSrc ? 563 : 50),
  },
  titleWithCustomImage: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(6.5),
    opacity: 1,
  },
  titleWithDefaultImage: {
    padding: 0,
    opacity: 0.6,
  },
}));
