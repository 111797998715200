export default (theme) => ({
  icon: {
    color: `${theme.palette.text.primary} !important`,
  },
  labelRoot: {
    '&:hover svg': {
      opacity: '0 !important',
    },
  },
  activeLabelRoot: {
    '&:hover svg': {
      opacity: '1 !important',
    },
  },
  customCell: {
    wordBreak: 'break-all',
  },
  defaultCell: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
  textCell: {
    lineHeight: 1.1,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.tableCell.icon,
    padding: `${theme.spacing(4.5, 1, 1.375, 0)} !important`,
    borderBottom: `1px solid ${theme.palette.tableBorderColor}`,
    verticalAlign: 'bottom',
  },
  selectCell: {
    width: 42,
    marginRight: 10,
    padding: `${theme.spacing(4.5, 2, 0, 0)} !important`,
    color: theme.palette.primary.main,
  },
});
