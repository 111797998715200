export default (theme) => ({
  label: {
    fontSize: 14,
    lineHeight: 1,
    marginTop: theme.spacing(0.5),
    color: theme.palette.deepBlue[50],
    marginBottom: theme.spacing(1),
  },
  inputRoot: {
    height: '32px',
    padding: theme.spacing(1.5, 2.5),
  },
  isBigValueDark: {
    fontSize: 32,
    '&.Mui-disabled': {
      color: theme.palette.annotationColor,
    },
  },
});
