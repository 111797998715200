import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'components/Modal';
import PolicyView from 'components/PolicyContainer';
import { setActiveSubMenuItem, setModal, setPreviousMenuItem } from 'containers/store';

class PolicyContainer extends React.Component {
  static propTypes = {
    modalProps: PropTypes.objectOf(PropTypes.any).isRequired,
    setActiveSubMenuItem: PropTypes.func.isRequired,
    setModal: PropTypes.func.isRequired,
    setPreviousMenuItem: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  }

  componentDidMount() {
    const policyType = this.props.modalProps.type;
    this.props.setActiveSubMenuItem(policyType);
  }

  render() {
    const policyType = this.props.modalProps.type;

    return (
      <Modal
        isOpen={!!this.props.type}
        onRequestClose={() => {
          this.props.setModal();
          this.props.setPreviousMenuItem();
        }}
      >
        <PolicyView
          type={policyType}
        />
      </Modal>

    );
  }
}

const mapDispatchToProps = {
  setActiveSubMenuItem,
  setModal,
  setPreviousMenuItem,
};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyContainer);
