import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const ACTIVE_ID = 'ACTIVE';
const INACTIVE_ID = 'INACTIVE';

const status = defineMessages({
  active: 'Aktywny',
  inActive: 'Nieaktywny',
});

const statusOptions = [
  {
    id: ACTIVE_ID,
    name: <FormattedMessage {...status.active} />,
  },
  {
    id: INACTIVE_ID,
    name: <FormattedMessage {...status.inActive} />,
  },
];

export { statusOptions, ACTIVE_ID, INACTIVE_ID };
