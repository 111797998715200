import React from 'react';

import { defineMessages, FormattedMessage } from 'react-intl';

export default (id) => {
  const messages = defineMessages({
    anonymousWithId: 'Klient anonimowy id {id}',
  });

  return (<FormattedMessage {...messages.anonymousWithId} values={{ id }} />);
};
