import { goBack } from 'connected-react-router';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import createURLWithQuery from 'utils/createURLWithQuery';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import {
  showLoader, hideLoader, showSnackbar, getMeta, showTransparentLoader, setDrawerVisibility,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    name: '',
    voivodeship_id: '',
    city: '',
    street: '',
    number: '',
    postalCode: '',
    consent: '',
    type: '',
    status: '',
    dateFrom: '',
    dateTo: '',
    surveys: [{
      survey: {},
    }],
  },
  organizationId: null,
  consents: [],
  organizationTypes: [],
  organizationStatuses: [],
  voivodeships: [],
  surveySuggestions: [],
};

const mapDataToValues = (data) => {
  const surveys = [];

  _forEach(data.surveys, (el) => {
    surveys.push({
      survey: {
        id: el.id,
        name: el.name,
      },
    });
  });

  if (data.surveys.length === 0) {
    surveys.push({
      survey: {},
    });
  }

  return {
    name: data.name || '',
    city: data.city || '',
    street: data.street || '',
    number: data.number || '',
    postalCode: data.postal_code || '',
    consent: data.consent,
    type: data.organization_type_id || '',
    status: data.organization_status_id || '',
    dateFrom: data.participant_year_range_from || '',
    dateTo: data.participant_year_range_to || '',
    voivodeship_id: data.voivodeship_id || '',
    surveys,
  };
};

const mapSuggestions = (items) => _filter(items, (el) => el.status === 'ACTIVE').map((el) => ({
  id: el.id,
  name: el.name,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'ORGANIZATION_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'ORGANIZATION_ADD_EDIT/CLEAR_STORE',
  SET_SURVEY_SUGGESTIONS: 'ORGANIZATION_ADD_EDIT/SET_SURVEY_SUGGESTIONS',
  CLEAR_SURVEY_SUGGESTIONS: 'ORGANIZATION_ADD_EDIT/CLEAR_SURVEY_SUGGESTIONS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        organizationId: action.organizationId,
        initialValues: action.responses.organizationData
          ? mapDataToValues(action.responses.organizationData.data) : initialState.initialValues,
        consents: action.responses.meta.data.boolList,
        voivodeships: action.responses.voivodeships.data,
        organizationTypes: action.responses.organizationTypes.data,
        organizationStatuses: action.responses.organizationStatuses.data,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_SURVEY_SUGGESTIONS: {
      return {
        ...state,
        surveySuggestions: mapSuggestions(action.response.data.items),
      };
    }

    case actionTypes.CLEAR_SURVEY_SUGGESTIONS: {
      return {
        ...state,
        surveySuggestions: [],
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (organizationId, responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  organizationId,
  responses,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setSurveySuggestions = (response) => ({
  type: actionTypes.SET_SURVEY_SUGGESTIONS,
  response,
});

const loadVoivodeships = () => (dispatch) => {
  const url = 'voivodeships';

  return ApiManager.request('get', dispatch, url);
};

const loadOrganizationTypes = () => (dispatch) => {
  const url = 'organization_types';

  return ApiManager.request('get', dispatch, url);
};

const loadOrganizationStatuses = () => (dispatch) => {
  const url = 'organization_statuses';

  return ApiManager.request('get', dispatch, url);
};

const loadOrganizationData = (id) => (dispatch) => ApiManager.request('get', dispatch, `organizations/${id}`);

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const store = getStore().OrganizationAddEdit;
  const organizationId = store.organizationId;
  let url = 'organizations';
  let method = 'post';
  let message = snackbarMessages.organizationAddedSuccessfully;

  if (organizationId) {
    url = `organizations/${organizationId}`;
    method = 'put';
    message = snackbarMessages.organizationEditedSuccessfully;
  }

  const surveys = _filter(values.surveys, (el) => !!el.survey.id);
  const consent = values.consent ? values.consent : false;

  const data = {
    name: values.name,
    city: values.city,
    voivodeship_id: values.voivodeship_id,
    street: values.street,
    number: values.number,
    postal_code: values.postalCode,
    consent,
    organization_type_id: values.type,
    organization_status_id: values.status,
    participant_year_range_from: parseInt(values.dateFrom),
    participant_year_range_to: parseInt(values.dateTo),
    surveys: surveys.map((el) => ({
      survey_id: el.survey.id,
    })),
  };

  dispatch(showTransparentLoader());

  ApiManager.request(method, dispatch, url, data).then(() => {
    dispatch(showSnackbar(message));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      if (error.error.errors.surveys) {
        dispatch(showSnackbar({
          id: 'duplicatedSurveys',
          defaultMessage: error.error.errors.surveys[0],
        }));
      } else {
        setFormErrors(error.error.errors, setFieldError, apiFieldMappings.organizationAddEdit);
        dispatch(showSnackbar(snackbarMessages.wrongData));
      }
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

const getSurveys = (findByName) => (dispatch) => {
  const params = {
    findByName,
  };

  const url = createURLWithQuery('surveys', params);

  return ApiManager.request('get', dispatch, url);
};

export const onSurveyFetch = (search) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(getSurveys(search)).then((response) => {
    dispatch(setSurveySuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const onSurveyClear = () => ({
  type: actionTypes.CLEAR_SURVEY_SUGGESTIONS,
});

export const loadPageData = (routeState, message) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  const promises = {};
  promises.meta = dispatch(getMeta());
  promises.organizationTypes = dispatch(loadOrganizationTypes());
  promises.organizationStatuses = dispatch(loadOrganizationStatuses());
  promises.voivodeships = dispatch(loadVoivodeships());

  if (routeState.id) {
    promises.organizationData = dispatch(loadOrganizationData(routeState.id));
  }

  PromiseAll(promises).then((responses) => {
    dispatch(loadPageSuccess(routeState.id, responses));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
