export default (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  wrapper: {
    width: theme.sizes.bannerContainer.content.width - theme.spacing(4),
    boxSizing: 'border-box',
    paddingRight: theme.spacing(8),
  },
  smallerPadding: {
    paddingRight: theme.spacing(4),
  },
  banner: {
    backgroundColor: theme.palette.banner.background,
    width: theme.sizes.bannerContainer.image.width,
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(-4, -4, -4, 0),
    borderRadius: '0 8px 8px 0',
  },
  alignLogo: {
    alignItems: 'center',
    paddingTop: 0,
  },
  greenBannerBackground: {
    backgroundColor: 'rgba(64, 183, 190, 0.3)',
  },
  image: {
    width: '100%',
  },
  spacers: {
    minWidth: 246,
    padding: theme.spacing(6.875, 4),
  },
  smallLogoPadding: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});
