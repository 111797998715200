import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  menuItemRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor}`,
  },
  wideMenuItem: {
    paddingRight: theme.spacing(4),
  },
  iconButtonRoot: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.75),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  primaryHoverButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  focusedRipplePrimaryBackground: {
    backgroundColor: theme.palette.primary.main,
  },
  label: {
    zIndex: 1,
  },
  focus: {
    '& .MuiTouchRipple-ripple': {
      opacity: 1,
    },
    '& .MuiTouchRipple-child': {
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.dark,
    },
  },
  popoverWrapper: {
    marginLeft: -4,
    minWidth: 134,
  },
}));
