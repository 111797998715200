import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';

import { showSnackbar, setModal } from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  isSubmitting: false,
  initialValues: {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'CHANGE_USER_PASSWORD/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'CHANGE_USER_PASSWORD/CLEAR_STORE',
  SET_IS_SUBMITTING: 'CHANGE_USER_PASSWORD/SET_IS_SUBMITTING',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_IS_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.value,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = () => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setSubmittingForm = (value) => ({
  type: actionTypes.SET_IS_SUBMITTING,
  value,
});

export const onSubmit = (values, { setFieldError }) => async (dispatch) => {
  dispatch(setSubmittingForm(true));
  const data = {
    current_password: values.oldPassword,
    password: values.newPassword,
  };

  try {
    await ApiManager.request('put', dispatch, 'users/set_password', data);
    dispatch(setModal());
    dispatch(showSnackbar(snackbarMessages.passwordChanged));
    dispatch(setSubmittingForm(false));
  } catch (error) {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.changeUserPassword);
      if (error.error.errors.current_password) {
        dispatch(showSnackbar(snackbarMessages.wrongPassword));
      } else if (error.error.errors.password) {
        dispatch(showSnackbar({
          id: 'incorrectPassword',
          defaultMessage: error.error.errors.password[0],
        }));
      } else {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      }
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(setSubmittingForm(false));
  }
};

export const onCancel = () => (dispatch) => {
  dispatch(clearStore());
  dispatch(setModal());
};

export const loadPageData = () => (dispatch) => {
  dispatch(clearStore());
  dispatch(loadPageSuccess());
};
