import { goBack } from 'connected-react-router';

import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import setFormErrors from 'utils/setFormErrors';
import getCountStartFrom from 'utils/getCountStartFrom';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import apiFieldMappings from 'utils/apiFieldMappings';
import formatMaxRemoteTime from 'utils/formatMaxRemoteTime';

import {
  showLoader,
  hideLoader,
  showSnackbar,
  setPage,
  showTransparentLoader,
  getMeta,
  mapMeta,
  setSortingData,
  setRowsPerPage,
  setDrawerVisibility,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  questionTypes: [],
  questionGroups: [],
  repositoryItems: [],
  groups: [],
  surveyStatuses: [],
  setTypes: [],
  surveyId: null,
  disableFields: false,
  addNewSetInitialValues: {
    name: '',
    range: '',
    type: '',
    answers: [],
    weights: [],
  },
  addInitialValues: {
    content: '',
    contentFemale: '',
    answerSet: {},
    type: '',
    group: '',
    questionPicture: null,
    questionImageObject: {},
  },
  initialValues: {
    name: '',
    group: '',
    minTime: '',
    maxTime: '',
    maxRemoteTime: '',
    averageTime: '',
    questions: [],
    surveyStatus: '',
    surveyType: '',
    surveyRespondent: '',
  },
  repositoryInitialValues: {
    repoFind: '',
    type: '',
    group: '',
  },
  filterValues: {},
  suggestions: [],
  totalItemsCount: 0,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEY_CREATOR/LOAD_PAGE_SUCCESS',
  SET_VALUES: 'SURVEY_CREATOR/SET_VALUES',
  SET_REPOSITORY_ITEMS: 'SURVEY_CREATOR/SET_REPOSITORY_ITEMS',
  CLEAR_SUGGESTIONS: 'SURVEY_CREATOR/CLEAR_SUGGESTIONS',
  SET_SUGGESTIONS: 'SURVEY_CREATOR/SET_SUGGESTIONS',
  CLEAR_STORE: 'SURVEY_CREATOR/CLEAR_STORE',
  CLEAR_REPOSITORY_ITEMS: 'SURVEY_CREATOR/CLEAR_REPOSITORY_ITEMS',
};

const mapRepositoryItems = (items, startingPosition, surveyQuestions) => items.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  content: el.name,
  contentFemale: el.label_female || '',
  imageUrl: el.image || null,
  answerSet: el.answer_set,
  type: el.question_type.name,
  group: el.question_group.name,
  label: _get(el, 'pivot.label', '') || el.code || '',
  progressStatus: _get(el, 'pivot.progress_status_label', ''),
  isLast: _get(el, 'pivot.is_last', false),
  answersMapping: surveyQuestions.length > 0
    ? surveyQuestions[key].survey_question_answer.map((el1) => ({
      answerId: el1.answer_id,
      questionId: el1.next_survey_question.question_id,
    })) : [],
}));

const mapSuggestions = (items) => items.map((el) => ({
  id: el.id,
  name: el.name,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const initialValues = { ...state.initialValues };
      const surveyData = action.responses.surveyData;

      if (surveyData) {
        initialValues.name = surveyData.data.name;
        initialValues.group = surveyData.data.survey_group_id;
        initialValues.maxTime = surveyData.data.max_time;
        initialValues.maxRemoteTime = formatMaxRemoteTime(
          surveyData.data.remote_examination_max_time,
          { type: 'fromApiFormat' },
        );
        initialValues.minTime = surveyData.data.min_session_time;
        initialValues.averageTime = surveyData.data.average_session_time;
        initialValues.surveyStatus = surveyData.data.status;
        initialValues.surveyType = surveyData.data.survey_type_id;
        initialValues.surveyRespondent = surveyData.data.survey_respondent_id;
        initialValues.questions = mapRepositoryItems(
          surveyData.data.questions,
          0,
          surveyData.data.survey_questions,
        );
      }

      const forCopy = action.forCopy;

      return {
        ...state,
        isLoadedPage: true,
        isForCopy: forCopy,
        questionTypes: action.responses.questionTypes.data.items,
        questionGroups: action.responses.questionGroups.data.items,
        groups: action.responses.surveyGroups.data,
        surveyStatuses: mapMeta(action.responses.meta.data.surveyStatus),
        surveyTypes: mapSuggestions(action.responses.surveyTypes.data),
        surveyRespondents: mapSuggestions(action.responses.surveyRespondents.data),
        setTypes: action.responses.setTypes.data,
        initialValues,
        surveyId: surveyData && !forCopy ? surveyData.data.id : null,
        disableFields: surveyData && !forCopy ? _includes(['ACTIVE', 'PRODUCTION'], surveyData.data.status) : false,
      };
    }

    case actionTypes.SET_VALUES: {
      return {
        ...state,
        filterValues: action.values,
      };
    }

    case actionTypes.SET_REPOSITORY_ITEMS: {
      return {
        ...state,
        repositoryItems: mapRepositoryItems(action.response.data.items, action.startCountFrom, []),
        totalItemsCount: action.response.data.total,
      };
    }

    case actionTypes.CLEAR_SUGGESTIONS: {
      return {
        ...state,
        suggestions: [],
      };
    }

    case actionTypes.SET_SUGGESTIONS: {
      return {
        ...state,
        suggestions: mapSuggestions(action.response.data.items),
      };
    }

    case actionTypes.CLEAR_REPOSITORY_ITEMS: {
      return {
        ...state,
        repositoryItems: [],
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, forCopy) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  forCopy,
});

const setValues = (values) => ({
  type: actionTypes.SET_VALUES,
  values,
});

const setRepositoryItems = (response, startCountFrom) => ({
  type: actionTypes.SET_REPOSITORY_ITEMS,
  response,
  startCountFrom,
});

const setSuggestions = (response) => ({
  type: actionTypes.SET_SUGGESTIONS,
  response,
});

const clearRepositoryItems = () => ({
  type: actionTypes.CLEAR_REPOSITORY_ITEMS,
});

const getQuestionTypes = () => (dispatch) => ApiManager.request('get', dispatch, 'question_types');

const getQuestionGroups = () => (dispatch) => ApiManager.request('get', dispatch, 'question_groups');

const getSetTypes = () => (dispatch) => ApiManager.request('get', dispatch, 'answer_set_types');

const getSurveyGroups = () => (dispatch) => ApiManager.request('get', dispatch, 'survey_groups');

const getSurveyRespondents = () => (dispatch) => ApiManager.request('get', dispatch, 'survey_respondents');

const getSurveyTypes = () => (dispatch) => ApiManager.request('get', dispatch, 'survey_types');

const saveSurvey = (body, id) => (dispatch) => ApiManager.request(id ? 'put' : 'post', dispatch, id ? `surveys/${id}` : 'surveys', body);

const saveNewSet = (body) => (dispatch) => ApiManager.request('post', dispatch, 'answer_sets', body);

const editQuestion = (id, body) => (dispatch) => ApiManager.request('post', dispatch, `questions/${id}`, body);

const getSurveyData = (id) => (dispatch) => ApiManager.request('get', dispatch, `surveys/${id}`);

const getAnswersSets = (find) => (dispatch) => {
  const params = {
    find,
  };

  const url = createURLWithQuery('answer_sets', params);

  return ApiManager.request('get', dispatch, url);
};

const getQuestionsSearch = (data) => (dispatch, getStore) => {
  const store = getStore().SurveyCreator;
  const { rowsPerPage, page } = getStore().Global.pagingData;
  const values = store.filterValues;

  const params = {
    perPage: rowsPerPage,
    page,
  };

  if (values.repoFind) {
    params.find = values.repoFind;
  }

  if (values.group) {
    params.findByQuestionGroup = values.group;
  }

  if (values.type) {
    params.findByQuestionType = values.type;
  }

  if (data.sortedBy && data.orderBy) {
    params.sortedBy = data.sortedBy;
    params.orderBy = data.orderBy;
  }

  const url = createURLWithQuery('questions', params);

  return ApiManager.request('get', dispatch, url);
};

const saveQuestion = (body) => (dispatch) => ApiManager.request('post', dispatch, 'questions', body);

const sortFieldsMapping = {
  questionContent: 'name',
};

const getQuestionRepository = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const startCountFrom = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  dispatch(getQuestionsSearch(data)).then((response) => {
    dispatch(setRepositoryItems(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getQuestionRepository());
};

export const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onRepositorySubmit = (values, isClear = false) => (dispatch) => {
  dispatch(setValues(values));
  dispatch(setPage(1));

  if (isClear) {
    dispatch(clearRepositoryItems());
  } else {
    dispatch(getQuestionRepository());
  }
};

export const onChangePage = (event, newPage) => (dispatch) => {
  dispatch(setPage(newPage + 1));
  dispatch(getQuestionRepository());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getQuestionRepository());
};

export const onAddQuestionSubmit = (
  values,
  formikMethods,
  setFieldValue,
  questions,
) => (dispatch) => {
  dispatch(showTransparentLoader());
  const newQuestions = questions;

  const formData = new FormData();

  formData.append('name', values.content);
  formData.append('label_male', values.content);
  formData.append('label_female', values.contentFemale);
  formData.append('answer_set_id', values.answerSet.id);
  formData.append('question_type_id', values.type);
  formData.append('question_group_id', values.group);

  if (_get(values.questionImageObject, 'files', false)) {
    formData.append('image', values.questionImageObject.files[0]);
  }

  dispatch(saveQuestion(formData)).then((response) => {
    const mapNewQuestion = {
      answerSet: response.data.answer_set,
      content: response.data.name,
      contentFemale: response.data.label_female,
      apiId: response.data.id,
      id: response.data.id,
      group: response.data.question_group.name,
      type: response.data.question_type.name,
      imageUrl: response.data.image || null,
    };

    newQuestions.push(mapNewQuestion);
    setFieldValue('questions', newQuestions);
    dispatch(showSnackbar(snackbarMessages.questionAddedSuccessfully));
    formikMethods.resetForm();
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSubmit = (values, { setFieldError }) => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());

  const body = {
    survey_group_id: values.group,
    survey_type_id: values.surveyType,
    survey_respondent_id: values.surveyRespondent,
    name: values.name,
    max_time: parseInt(values.maxTime),
    remote_examination_max_time: formatMaxRemoteTime(values.maxRemoteTime, { type: 'toApiFormat' }),
    min_session_time: parseInt(values.minTime),
    average_session_time: parseInt(values.averageTime),
    status: values.surveyStatus,
    questions: values.questions.map((el, key) => ({
      question_id: el.apiId,
      order: key + 1,
      label: el.label,
      progress_status_label: el.progressStatus,
      is_last: el.isLast || false,
      answers: el.answersMapping ? el.answersMapping.map((el1) => ({
        answer_id: el1.answerId,
        next_question_id: el1.questionId,
      })) : [],
    })),
  };

  const store = getStore().SurveyCreator;

  const message = store.surveyId ? snackbarMessages.surveyEdited : snackbarMessages.surveyAdded;

  try {
    await dispatch(saveSurvey(body, store.surveyId));
    dispatch(goBack());
    dispatch(showSnackbar(message));
    dispatch(hideLoader());
  } catch (error) {
    const errorResponse = error.error.errors;
    if (isBadRequest(error)) {
      if (errorResponse.status) {
        dispatch(showSnackbar({
          id: 'statusError',
          defaultMessage: errorResponse.status[0],
        }));
      } else {
        const preparedMessage = error.error.errors.questions ? 'questionsIsEmpty' : 'wrongData';
        setFormErrors(error.error.errors, setFieldError, apiFieldMappings.surveyCreator);
        dispatch(showSnackbar(snackbarMessages[preparedMessage]));
      }
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

export const onEditQuestionSubmit = (
  editValues, formikMethods, values, fromRepo,
) => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const formData = new FormData();

  formData.append('_method', 'PUT');

  formData.append('name', editValues.content);
  formData.append('label_male', editValues.content);
  formData.append('label_female', editValues.contentFemale || '');
  formData.append('answer_set_id', editValues.answerSet.id);
  formData.append('question_type_id', editValues.type);
  formData.append('question_group_id', editValues.group);

  const isQuestionImageObject = _get(editValues.questionImageObject, 'files', false);

  if (isQuestionImageObject) {
    formData.append('image', editValues.questionImageObject.files[0]);
  } else if (!isQuestionImageObject && !editValues.questionPicture) {
    formData.append('remove_image', true);
  }

  return new Promise((resolve, reject) => {
    dispatch(editQuestion(editValues.apiId, formData)).then(() => {
      dispatch(showSnackbar(snackbarMessages.questionEditedSuccessfully));
      dispatch(hideLoader());
      resolve();

      const store = getStore().SurveyCreator;

      if (fromRepo) {
        dispatch(onRepositorySubmit(store.filterValues));
      } else {
        const questions = values.questions;

        _forEach(questions, (el, key) => {
          if (el.apiId === editValues.apiId) {
            const group = _find(store.questionGroups, (el1) => el1.id === editValues.group);
            const type = _find(store.questionTypes, (el1) => el1.id === editValues.type);

            let imageUrl = null;

            if (!_isEmpty(editValues.questionImageObject) && editValues.questionPicture) {
              imageUrl = editValues.questionPicture;
            } else if (_isEmpty(editValues.questionImageObject) && editValues.questionPicture) {
              imageUrl = editValues.imageUrl;
            }

            questions[key].content = editValues.content;
            questions[key].contentFemale = editValues.contentFemale;
            questions[key].type = type.name;
            questions[key].group = group.name;
            questions[key].imageUrl = imageUrl;
          }
        });

        formikMethods.setFieldValue('questions', questions);
      }
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
      reject();
    });
  });
};

export const addNewSet = (values, { resetForm }) => (dispatch) => {
  dispatch(showTransparentLoader());

  const body = {
    answer_set_type_id: values.type,
    name: values.name,
    answers_count: parseInt(values.range),
    answers_save: values.answers.map((el, key) => ({
      label: el,
      weight: values.weights[key],
    })),
  };

  return new Promise((resolve, reject) => {
    dispatch(saveNewSet(body)).then(() => {
      dispatch(showSnackbar(snackbarMessages.setAdded));
      dispatch(hideLoader());
      resolve();
      resetForm();
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
      reject();
    });
  });
};

export const onFetch = (value) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(getAnswersSets(value)).then((response) => {
    dispatch(setSuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onClear = () => ({
  type: actionTypes.CLEAR_SUGGESTIONS,
});

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const loadPageData = (routeState, message) => (dispatch) => {
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  const promises = {
    questionTypes: dispatch(getQuestionTypes()),
    questionGroups: dispatch(getQuestionGroups()),
    surveyGroups: dispatch(getSurveyGroups()),
    surveyRespondents: dispatch(getSurveyRespondents()),
    surveyTypes: dispatch(getSurveyTypes()),
    meta: dispatch(getMeta()),
    setTypes: dispatch(getSetTypes()),
  };

  const forCopy = !!routeState.forCopy;

  if (routeState.id) {
    promises.surveyData = dispatch(getSurveyData(routeState.id));
  }

  PromiseAll(promises).then((responses) => {
    dispatch(hideLoader());
    dispatch(loadPageSuccess(responses, forCopy));
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(goBack());
    dispatch(hideLoader());
  });
};
