import moment from 'moment';

import _findIndex from 'lodash/findIndex';

const dayOptions = [1, 4, 6, 10, 14, 16, 19, 24, 26, 28];

export default () => {
  const currentDay = moment().date();
  const lastIndex = dayOptions.length - 1;
  if (currentDay >= dayOptions[lastIndex]) {
    return { firstDay: dayOptions[0], withMoveToNextMonth: true };
  }

  const closestDayIndex = _findIndex(dayOptions, (el) => el > currentDay);
  const closestValidDay = dayOptions[closestDayIndex];

  return { firstDay: closestValidDay, withMoveToNextMonth: false };
};
