import _isEmpty from 'lodash/isEmpty';

import {
  setModal,
  showSnackbar,
  showTransparentLoader,
  hideLoader,
} from 'containers/store';
import { purchaseHistoryRefresh } from 'containers/Facility/store';

import snackbarMessages from 'utils/snackbarMessages';
import formatFullName from 'utils/formatFullName';
import ApiManager from 'utils/ApiManager';
import fileDownload, { FILE_DOWNLOAD_ENDPOINTS } from 'utils/fileDownload';

import {
  formatFileData,
  formatFileFromAPI,
  getInitialValues,
} from 'utils/fileEditUtils';

export const initialState = {
  isLoadedPage: false,
  isSubmitting: false,
  initialValues: {
    name: '',
    note: '',
  },
  fileData: {
    addedBy: '',
    date: '',
    fileName: '',
  },
  file: null,
  isEdit: false,
  hideDownload: true,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'VAT_INVOICE_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'VAT_INVOICE_ADD_EDIT/CLEAR_STORE',
  LOAD_FILE_DATA: 'VAT_INVOICE_ADD_EDIT/LOAD_FILE_DATA',
  CLEAR_FILE_DATA: 'VAT_INVOICE_ADD_EDIT/CLEAR_FILE_DATA',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const newState = {
        ...state,
        isLoadedPage: true,
        orderId: action.orderId,
      };

      if (!_isEmpty(action.fileData)) {
        newState.fileData = formatFileFromAPI(action.fileData);
        newState.initialValues = getInitialValues(action.fileData);
        newState.isEdit = true;
        newState.hideDownload = false;
        newState.fileId = action.fileId;
      }

      return newState;
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.LOAD_FILE_DATA: {
      return {
        ...state,
        fileData: formatFileData(action.fileData, action.currentUserName),
        file: action.fileData,
        hideDownload: true,
      };
    }

    case actionTypes.CLEAR_FILE_DATA: {
      return {
        ...state,
        fileData: { ...initialState.fileData },
        file: null,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (orderId, fileData, fileId) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  orderId,
  fileData,
  fileId,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadFileData = (fileData, currentUserName) => ({
  type: actionTypes.LOAD_FILE_DATA,
  fileData,
  currentUserName,
});

export const onFileUpload = (acceptedFiles, rejectedFiles) => (dispatch, getStore) => {
  const currentUser = getStore().Global.userData;
  if (rejectedFiles.length === 1) {
    dispatch(showSnackbar(snackbarMessages.documentTooLarge));
  } else if (_isEmpty(acceptedFiles) && rejectedFiles.length > 1) {
    dispatch(showSnackbar(snackbarMessages.tooManyFiles));
  } else {
    dispatch(loadFileData(acceptedFiles[0], formatFullName(currentUser)));
  }
};

const clearFileData = () => ({ type: actionTypes.CLEAR_FILE_DATA });

export const onFileUnload = () => (dispatch) => dispatch(clearFileData());

export const onSubmit = (values) => async (dispatch, getStore) => {
  const {
    orderId, file, isEdit, fileId,
  } = getStore().VatInvoiceAddEdit;
  const { note } = values;

  const url = isEdit ? `order_invoices/${fileId}` : 'order_invoices';

  const formData = new FormData();
  formData.append('order_id', orderId);

  if (!_isEmpty(file)) {
    formData.append('file', file);
  }

  if (note) {
    formData.append('note', note);
  }

  dispatch(showTransparentLoader());
  try {
    await ApiManager.request('post', dispatch, url, formData);
    dispatch(setModal());
    dispatch(hideLoader());
    dispatch(purchaseHistoryRefresh());
    dispatch(clearStore());
  } catch (error) {
    const errors = error.error.errors;
    const isErrorFile = errors.file;
    const isErrorName = errors.name;

    if (isErrorFile) {
      dispatch(showSnackbar(snackbarMessages.wrongFileFormat));
    } else if (isErrorName) {
      dispatch(showSnackbar(snackbarMessages.maxNameLength_255));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

export const onCancel = () => (dispatch) => {
  dispatch(clearStore());
  dispatch(setModal());
};

const getFileData = (id) => (dispatch) => ApiManager.request('get', dispatch, `order_invoices/${id}`);

export const onDownload = () => (dispatch, getStore) => {
  const { fileId } = getStore().VatInvoiceAddEdit;
  fileDownload(dispatch, fileId, FILE_DOWNLOAD_ENDPOINTS.PATIENT_INVOICE);
};

export const loadPageData = (orderId, id) => async (dispatch) => {
  dispatch(clearStore());
  let fileData = {};
  if (id) {
    fileData = await dispatch(getFileData(id));
  }

  dispatch(loadPageSuccess(orderId, fileData.data, id));
  dispatch(hideLoader());
};
