export default (
  (theme) => ({
    startedExamination: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
      color: theme.palette.examinationInProgress,
    },
    icon: {
      marginLeft: theme.spacing(1),
      width: 16,
      height: 16,
    },
  })
);
