import PropTypes from 'prop-types';

const userDataShape = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  avatar: PropTypes.node,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  role_id: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.node,
  permissions: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  clinics: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  organizations: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  role: PropTypes.object, // eslint-disable-line react/forbid-prop-types
});

export { userDataShape };
