import React from 'react';
import { injectIntl } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import UnwantedExaminationResults from 'assets/img/unwantedExaminationResults.svg';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { intlShape } from 'utils/shapes/intlShape';
import { classesShape } from 'utils/shapes/classesShape';
import messages from 'components/pages/PatientExamination/PatientExamination.messages';
import styles from 'components/pages/PatientExamination/PatientExamination.styles';

const UndesirableSurveyEvaluationsIcon = ({ classes, intl }) => (
  <Tooltip
    arrow
    title={(
      <>
        <Typography variant="body1" component="span">
          {intl.formatMessage(messages.hasUndesirableSurveyEvaluations)}
        </Typography>
        <Divider classes={{
          root: classes.divider,
        }}
        />
        <Typography variant="body1" component="span" className={classes.tooltipText}>
          {intl.formatMessage(messages.hasUndesirableSurveyEvaluationsDescription)}
        </Typography>
      </>
      )}
    placement="bottom"
    classes={{ tooltip: classes.tooltip }}
  >
    <img className={classes.icon} src={UnwantedExaminationResults} alt="undesirable survey evaluations" />
  </Tooltip>
);

UndesirableSurveyEvaluationsIcon.propTypes = {
  classes: classesShape.isRequired,
  intl: intlShape.isRequired,
};

export default withStyles(styles)(injectIntl(UndesirableSurveyEvaluationsIcon));
