import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import _map from 'lodash/map';
import _find from 'lodash/find';
import _get from 'lodash/get';

import ApiManager from 'utils/ApiManager';
import rolesOfUsers from 'utils/constants/rolesOfUsers';
import createURLWithQuery from 'utils/createURLWithQuery';
import snackbarMessages from 'utils/snackbarMessages';
import getDateFromApi from 'utils/getDateFromApi';
import errorCatch from 'utils/errorCatch';
import formatFullName from 'utils/formatFullName';
import dialogTexts from 'utils/dialogTexts';
import { TOP_UP_ACCOUNT } from 'utils/constants/modalTypes';
import { STATUSES } from 'utils/constants/orderStatuses';
import {
  showTransparentLoader, hideLoader, setModal,
  showSnackbar, openDialog,
} from 'containers/store';
import {
  facilityDataRefresh,
  actionTypes as facilityActionTypes,
  getCreditPoints,
  refreshCreditPointsList,
} from 'containers/Facility/store';

import messages from './messages';

const initialState = {
  isLoadedPage: false,
  facilityId: null,
  startDate: moment().startOf('month'),
  endDate: moment(),
  items: [],
  paginationOptions: {
    page: 1,
    totalItemsCount: 1,
    rowsPerPage: 10,
  },
};

const actionTypes = {
  LOAD_PAGE_SUCCESS: 'FACILITY/CREDIT_POINTS_HISTORY/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'FACILITY/CREDIT_POINTS_HISTORY/CLEAR_STORE',
  CHANGE_DATE: 'FACILITY/CREDIT_POINTS_HISTORY/CHANGE_DATE',
  SET_ORDERS: facilityActionTypes.SET_CREDIT_POINTS_HISTORY_ORDERS,
  SET_PAGE: 'FACILITY/CREDIT_POINTS_HISTORY/SET_PAGE',
  SET_ROWS_PER_PAGE: 'FACILITY/CREDIT_POINTS_HISTORY/SET_ROWS_PER_PAGE',
};

const getFullName = (user, roleId) => {
  if (user) {
    return formatFullName(user);
  }

  if (roleId === rolesOfUsers.clinicCoordinator) {
    return <FormattedMessage {...messages.diagmatic} />;
  }

  return <FormattedMessage {...messages.system} />;
};

const mapItems = (items, roleId) => _map(items, (el) => ({
  id: el.id,
  addDate: getDateFromApi(el.created_at),
  status: el.status,
  addedBy: getFullName(el.user, roleId),
  pointsAmount: el.tokens,
  extraCreditPoints: el.tokens,
  balanceAfterRecharge: el.balance_after_recharge,
  substantiation: el.substantiation,
  positionName: el.position_name,
  topUpAmount: _get(el, 'order.gross_value', 0),
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapItems(action.creditPoints.data.items, action.roleId),
        paginationOptions: {
          ...state.paginationOptions,
          totalItemsCount: action.creditPoints.data.total,
        },
        facilityId: action.facilityId,
      };
    }

    case actionTypes.CHANGE_DATE: {
      return {
        ...state,
        startDate: action.startDate || state.startDate,
        endDate: action.endDate || state.endDate,
      };
    }

    case actionTypes.SET_ORDERS: {
      return {
        ...state,
        items: mapItems(action.orders.data.items, action.roleId),
        paginationOptions: {
          ...state.paginationOptions,
          totalItemsCount: action.orders.data.total,
        },
      };
    }

    case actionTypes.SET_ROWS_PER_PAGE: {
      return {
        ...state,
        paginationOptions: {
          ...state.paginationOptions,
          rowsPerPage: action.value,
        },
      };
    }

    case actionTypes.SET_PAGE: {
      return {
        ...state,
        paginationOptions: {
          ...state.paginationOptions,
          page: action.value,
        },
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (creditPoints, facilityId, roleId) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  creditPoints,
  facilityId,
  roleId,
});

const changeDate = (startDate, endDate) => ({
  type: actionTypes.CHANGE_DATE,
  startDate,
  endDate,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setPage = (value) => ({
  type: actionTypes.SET_PAGE,
  value,
});

const setRowsPerPage = (value) => ({
  type: actionTypes.SET_ROWS_PER_PAGE,
  value,
});

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(refreshCreditPointsList());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(refreshCreditPointsList());
};

export const onDatesChange = ({ startDate, endDate }) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(changeDate(startDate, endDate));
  dispatch(refreshCreditPointsList());
};

const onCancel = (id) => (dispatch) => {
  const url = createURLWithQuery(`subscriptions/${id}`, {
    status: STATUSES.CANCELED,
  });

  dispatch(showTransparentLoader());
  ApiManager.request('put', dispatch, url).then(() => {
    dispatch(showSnackbar(snackbarMessages.orderCanceled));
    dispatch(refreshCreditPointsList());
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};

const onSubmit = (id) => (dispatch) => {
  const orderUrl = createURLWithQuery(`subscriptions/${id}`, {
    status: STATUSES.CONFIRMED,
  });

  dispatch(showTransparentLoader());
  ApiManager.request('put', dispatch, orderUrl).then(() => {
    dispatch(showSnackbar(snackbarMessages.orderCompleted));
    dispatch(facilityDataRefresh());
    dispatch(refreshCreditPointsList());
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};

export const onAcceptClick = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.acceptCreditPoints,
    onAccept: () => { dispatch(onSubmit(id)); },
  }));
};

export const onPreviewClick = (id) => (dispatch, getStore) => {
  const { items } = getStore().CreditPointsHistory;
  const selectedItem = _find(items, (el) => el.id === id);
  dispatch(setModal(TOP_UP_ACCOUNT, { viewMode: true, selectedItem }));
};

export const onRefuseClick = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.refuseCreditPoints,
    onAccept: () => { dispatch(onCancel(id)); },
  }));
};

export const loadPageData = (facilityId, values) => async (dispatch, getStore) => {
  const { role_id: roleId } = getStore().Global.userData;
  dispatch(clearStore());
  dispatch(showTransparentLoader());

  if (values.creditPointsPage) {
    dispatch(setPage(Number(values.creditPointsPage)));
  }

  if (values.creditPointsPperPage) {
    dispatch(setRowsPerPage(Number(values.creditPointsPerPage)));
  }

  if (values.creditPointsDateFrom && values.creditPointsDateTo) {
    dispatch(changeDate(moment(values.creditPointsDateFrom), moment(values.creditPointsDateTo)));
  }

  try {
    const creditPoints = await dispatch(getCreditPoints(facilityId));
    dispatch(loadPageSuccess(creditPoints, facilityId, roleId));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
