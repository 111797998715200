import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  active: 'Aktywny',
  inactive: 'Nieaktywny',
});

const statuses = {
  ACTIVE: <FormattedMessage {...messages.active} />,
  INACTIVE: <FormattedMessage {...messages.inactive} />,
};

const getStatusMessage = (status) => statuses[status] || status;

export default getStatusMessage;
