import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import { withStyles } from '@material-ui/core/styles';
import { classesShape } from 'utils/shapes/classesShape';

import styles from './LabelWithIcon.styles';

const LabelWithIcon = ({
  classes,
  label,
  icon,
  onClick,
  visibleIcon,
  additionalIcons,
}) => (
  <div className={classes.wrapper}>
    {label}
    {_map(additionalIcons, (additionalIcon) => (
      <div className={classes.additionalIconWrapper}>{additionalIcon}</div>
    ))}
    {visibleIcon ? (
      <div className={classes.iconWrapper} onClick={onClick}>{icon}</div>
    ) : <div className={classes.iconWrapper} />}
  </div>
);

LabelWithIcon.propTypes = {
  classes: classesShape.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
  additionalIcons: PropTypes.arrayOf(PropTypes.element),
  visibleIcon: PropTypes.bool,
};

LabelWithIcon.defaultProps = {
  additionalIcons: [],
  visibleIcon: false,
};

export default withStyles(styles)(LabelWithIcon);
