import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _omit from 'lodash/omit';

import { withStyles } from '@material-ui/core/styles';

import { classesShape } from 'utils/shapes/classesShape';
import TableCell from '@material-ui/core/TableCell';
import cellStyles from './TableCell.styles';

const CustomTableCell = ({
  children,
  className,
  classes,
  noWrap,
  truncate,
  withMiddleVerticalAlign,
  ...props
}) => (
  <TableCell
    {..._omit(props, 'isGreyedOut')}
    className={classNames(className, {
      [classes.customCell]: !noWrap,
      [classes.truncateText]: truncate,
      [classes.defaultCell]: true,
      [classes.middleVerticalAlign]: withMiddleVerticalAlign,
    })}
  >
    {children}
  </TableCell>
);

CustomTableCell.propTypes = {
  children: PropTypes.node,
  classes: classesShape,
  className: PropTypes.string,
  noWrap: PropTypes.bool,
  truncate: PropTypes.bool,
  withMiddleVerticalAlign: PropTypes.bool,
};

CustomTableCell.defaultProps = {
  className: '',
  children: null,
  noWrap: false,
  classes: {},
  truncate: false,
  withMiddleVerticalAlign: false,
};

export default withStyles(cellStyles)(CustomTableCell);
