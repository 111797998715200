import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  injectIntl,
  FormattedMessage,
} from 'react-intl';

import Grid from '@material-ui/core/Grid';
import FormField from 'components/FormField';
import SummaryButtons from 'components/SummaryButtons';
import Section from 'components/Section';
import BannerContainer from 'components/BannerContainer';
import CardContent from 'components/CardContent';
import Modal from 'components/Modal';
import PasswordInput from 'components/PasswordInput';
import validator from 'utils/validator/core';
import { intlShape } from 'utils/shapes/intlShape';

import messages from './ChangeUserPassword.messages';
import { initialValuesShape } from './ChangeUserPassword.shapes';

const validators = [{
  fieldName: 'oldPassword',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'newPassword',
  validators: [
    {
      validatorName: 'notEmpty',
    },
    {
      validatorName: 'passwordValidator',
    },
    {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'newPasswordConfirmation',
  validateIf: (values) => values.newPassword,
  validators: [
    {
      validatorName: 'notEmpty',
    },
    {
      validatorName: 'matchOtherField',
      additionalFields: ['newPassword'],
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}];

const ChangeUserPassword = ({
  intl,
  initialValues,
  onSubmit,
  onCancel,
  type,
  isSubmitting,
}) => (
  <Modal
    isOpen={!!type}
    onRequestClose={!isSubmitting ? onCancel : undefined}
  >
    <CardContent withoutBorder bottomMargin={false}>
      <BannerContainer>
        <Formik
          initialValues={{ ...initialValues }}
          validate={(values) => validator(values, validators, intl.formatMessage)}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form>
              <Section title={<FormattedMessage {...messages.title} />}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      errors={errors}
                      touched={touched}
                      name="oldPassword"
                      label={intl.formatMessage(messages.oldPassword)}
                      fullWidth
                    >
                      <PasswordInput
                        value={values.oldPassword}
                      />
                    </FormField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      errors={errors}
                      touched={touched}
                      name="newPassword"
                      label={intl.formatMessage(messages.newPassword)}
                      fullWidth
                    >
                      <PasswordInput
                        value={values.newPassword}
                      />
                    </FormField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      errors={errors}
                      touched={touched}
                      name="newPasswordConfirmation"
                      label={intl.formatMessage(messages.newPasswordConfirmation)}
                      fullWidth
                    >
                      <PasswordInput
                        value={values.newPasswordConfirmation}
                      />
                    </FormField>
                  </Grid>
                </Grid>
              </Section>
              <SummaryButtons
                cancelMessage={<FormattedMessage {...messages.cancel} />}
                submitMessage={<FormattedMessage {...messages.save} />}
                withLoaderButton
                onCancel={onCancel}
                loading={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </BannerContainer>
    </CardContent>
  </Modal>
);

ChangeUserPassword.propTypes = {
  initialValues: initialValuesShape.isRequired,
  intl: intlShape.isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

ChangeUserPassword.defaultProps = {
  isSubmitting: false,
};

export default injectIntl(ChangeUserPassword);
