import { push, replace } from 'connected-react-router';

import ApiManager from 'utils/ApiManager';
import getStatusMessage from 'utils/getStatusMessage';
import createURLWithQuery from 'utils/createURLWithQuery';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import errorCatch from 'utils/errorCatch';

import {
  hideLoader,
  showLoader,
  showTransparentLoader,
  setPage,
  setRowsPerPage,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
};

const mapTools = (movies, startingPosition) => movies.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: el.title,
  description: el.description,
  status: getStatusMessage(el.status),
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'VIDEO_TUTORIALS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_TOOLS: 'VIDEO_TUTORIALS/SET_FILTERED_TOOLS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapTools(action.responses.data.items, action.startingPosition),
        totalItemsCount: action.responses.data.total,
      };
    }

    case actionTypes.SET_FILTERED_TOOLS: {
      return {
        ...state,
        items: mapTools(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  startingPosition,
});

const setFilteredTools = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_TOOLS,
  response,
  startCountFrom,
});

const loadVideos = ({ perPage, page }) => (dispatch) => {
  const data = { perPage, page };

  const url = createURLWithQuery('video_tutorials', data);
  const pageUrl = createURLWithQuery('videoTutorials', data);

  dispatch(replace(pageUrl));

  return ApiManager.request('get', dispatch, url);
};

const getTools = () => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const startCountFrom = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore);

  try {
    const videosData = await dispatch(loadVideos(data));
    dispatch(setFilteredTools(videosData, startCountFrom));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onChangePage = (_, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getTools());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getTools());
};

export const onActionClick = (id) => (dispatch) => {
  dispatch(push('/videoTutorialView', { id }));
};

export const onAdd = () => (dispatch) => {
  dispatch(push('/videoTutorialAddEdit'));
};

export const loadPageData = (values) => async (dispatch, getStore) => {
  dispatch(showLoader());
  if (values.page) {
    dispatch(setPage(Number(values.page)));
  }

  if (values.perPage) {
    dispatch(setRowsPerPage(Number(values.perPage)));
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore);

  try {
    const moviesData = await dispatch(loadVideos(data));
    dispatch(loadPageSuccess(moviesData, startingPosition));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
