import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';

import config from 'config';

import useStyle from './FileDropdown.styles';
import messages from './FileDropdown.messages';

const FileDropdown = ({ onFileUpload }) => {
  const classes = useStyle();
  const {
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    noKeyboard: true,
    noClick: true,
    maxSize: config.maxDocumentSize,
    multiple: false,
    onDrop: (acceptedFiles, rejectedFiles) => {
      onFileUpload(acceptedFiles, rejectedFiles);
    },
  });

  return (
    <div className={classes.wrapper} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className={classes.dropdownPlaceholder}>
        <CloudUploadIcon className={classes.cloudIcon} />
        <Typography
          variant="body1"
          className={classes.placeholderText}
          component="span"
        >
          <FormattedMessage {...messages.dropHere} />
        </Typography>
        <Typography
          variant="body1"
          className={classNames(classes.placeholderText, classes.uploadText)}
          component="span"
          onClick={open}
        >
          <FormattedMessage {...messages.upload} />
        </Typography>
        <Typography
          variant="body1"
          className={classes.placeholderText}
          component="span"
        >
          <FormattedMessage {...messages.fromHardDrive} />
        </Typography>
      </div>
    </div>
  );
};

FileDropdown.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
};

export default FileDropdown;
