import { defineMessages } from 'react-intl';

import _isObject from 'lodash/isObject';

import isEmptyObject from 'utils/isEmptyObject';

const messages = defineMessages({
  fieldCannotBeEmpty: 'To pole nie może być puste',
});

export default (value) => {
  const result = {};

  result.isValid = value !== null
  && value !== undefined
  && !(_isObject(value) && isEmptyObject(value))
  && value.toString().trim().length > 0;
  result.message = messages.fieldCannotBeEmpty;

  return result;
};
