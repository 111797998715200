import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  invoiceText: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.fadeTextPrimary,
    marginBottom: theme.spacing(1.0575),
  },
}));
