import { goBack, push } from 'connected-react-router';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _round from 'lodash/round';

import roles from 'utils/constants/rolesOfUsers';
import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import isBadRequest from 'utils/isBadRequest';
import errorCatch from 'utils/errorCatch';
import formatFullName from 'utils/formatFullName';
import snackbarMessages from 'utils/snackbarMessages';
import getDateTimeFromApi from 'utils/getDateTimeFromApi';
import formatCodeWithName from 'utils/formatCodeWithName';

import {
  showLoader, showTransparentLoader, hideLoader, setDrawerVisibility, showSnackbar,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  isViewOnly: true,
  data: [],
  patientId: null,
  sessionPatientId: null,
  maxTime: 0,
  middleTime: 0,
  minTime: 0,
  examinationName: '',
};

const dateFormat = (date) => (
  date
    ? getDateTimeFromApi(date)
    : null
);

const additionalIdKeys = ['patient_id', 'session_id', 'question_id', 'answer_id'];

const mapData = (results, currentUser) => _map(results, (result, i) => ({
  nr: i + 1,
  ..._pick(result, ['id', 'note', ...additionalIdKeys]),
  question: result.question.name,
  answer: _get(result, 'answer.label', ''),
  time: result.response_time,
  diagnostician: formatFullName(result.note ? result.note_author : currentUser),
  suspendedDate: dateFormat(result.suspended_at),
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEY_ANALYSIS/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'SURVEY_ANALYSIS/CLEAR_STORE',
  LOAD_REFETCH_DATA: 'SURVEY_ANALYSIS/LOAD_REFETCH_DATA',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const { results, sessionPatient, currentUser } = action;

      const minSessionTime = _get(results, '[0].min_session_time', 0);
      const averageSessionTime = _get(results, '[0].average_session_time', 0);
      const questionsCount = _get(action, 'toolData.data.survey.questions_count', null);

      const middleTime = _round(averageSessionTime / questionsCount);
      const minTime = _round(minSessionTime / questionsCount);

      return {
        ...state,
        isLoadedPage: true,
        minTime,
        middleTime,
        examinationName: formatCodeWithName(sessionPatient.session.survey_tool),
        patientId: sessionPatient.patient_id,
        sessionPatientId: sessionPatient.id,
        data: mapData(results, currentUser),
        isViewOnly: currentUser.role_id === roles.accountManager,
      };
    }

    case actionTypes.LOAD_REFETCH_DATA: {
      const { results } = action;

      return {
        ...state,
        data: mapData(results, action.currentUser),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (results, sessionPatient, currentUser, toolData) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  results,
  sessionPatient,
  currentUser,
  toolData,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadRefetchData = (results, currentUser) => ({
  type: actionTypes.LOAD_REFETCH_DATA,
  results,
  currentUser,
});

const getSessionPatient = (sessionPatientId) => (dispatch) => {
  const url = `session_patients/${sessionPatientId}`;

  return ApiManager.request('get', dispatch, url);
};

const getResult = (sessionPatientId) => (dispatch) => {
  const url = createURLWithQuery(
    `session_patients/${sessionPatientId}/results`,
    { appendResponseTime: true },
  );

  return ApiManager.request('get', dispatch, url);
};

const addNote = (result, values) => (dispatch) => (
  ApiManager.request('put', dispatch, `results/${result.id}`, {
    ...values,
    ..._pick(result, additionalIdKeys),
  })
);

export const onNoteSave = (result, values) => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const { sessionPatientId } = getStore().SurveyAnalysis;
  const currentUser = getStore().Global.userData;

  try {
    await dispatch(addNote(result, values));
    const results = await dispatch(getResult(sessionPatientId));
    dispatch(loadRefetchData(results.data, currentUser));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const refetchData = (sessionPatientId) => (dispatch, getStore) => {
  const currentUser = getStore().Global.userData;
  const { patientId } = getStore().SurveyAnalysis;

  const url = createURLWithQuery(
    `session_patients/${sessionPatientId}/results`,
    { appendResponseTime: true },
  );

  ApiManager.request('get', dispatch, url).then((results) => {
    dispatch(loadRefetchData(results.data, currentUser));
  }).catch((err) => {
    if (isBadRequest(err, 400)) {
      dispatch(showSnackbar(snackbarMessages.surveySuspend));
      dispatch(push('/patientView', { id: patientId }));
    }
  });
};

const getToolData = (id) => (dispatch) => ApiManager.request('get', dispatch, `survey_tools/${id}`);

export const loadPageData = (sessionPatientId, message) => async (dispatch, getStore) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  const currentUser = getStore().Global.userData;

  try {
    const results = await dispatch(getResult(sessionPatientId));
    const sessionPatient = await dispatch(getSessionPatient(sessionPatientId));
    const toolData = await dispatch(getToolData(sessionPatient.data.session.survey_tool_id));

    dispatch(loadPageSuccess(results.data, sessionPatient.data, currentUser, toolData));
    dispatch(hideLoader());
  } catch (error) {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.notAnswered));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(goBack());
    dispatch(hideLoader());
  }
};
