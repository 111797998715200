import { push, replace } from 'connected-react-router';
import _omit from 'lodash/omit';

import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';

import {
  hideLoader, showSnackbar, showLoader, showTransparentLoader,
  setPage, setRowsPerPage, setSortingData, downloadSurveyFile, setSortingAndPagination,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
  resultsId: null,
};

const sortFieldsMapping = {
  gender: 'participants|user_sex',
  birthDate: 'participants|user_birth_year',
  uniqueId: 'uniqueid',
};

const mapResults = (surveys, startingPosition) => surveys.map((survey, key) => ({
  id: key + 1 + startingPosition,
  apiId: survey.id,
  gender: survey.participant.user_sex,
  birthDate: survey.participant.user_birth_year,
  uniqueId: survey.uniqueid,
  results: survey.participant.results.map((result) => {
    const answer = result.answer ? result.answer.label : result.text_answer;

    return ({
      id: result.id,
      question: result.question.plain_name,
      answer,
    });
  }),
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEY_RESULTS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_SURVEY_RESULTS: 'SURVEY_RESULTS/SET_FILTERED_SURVEY_RESULTS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapResults(action.responses.surveyResults.data.items, action.startingPosition),
        totalItemsCount: action.responses.surveyResults.data.total,
        resultsId: action.routeState.id,
      };
    }

    case actionTypes.SET_FILTERED_SURVEY_RESULTS: {
      return {
        ...state,
        items: mapResults(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, routeState, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  routeState,
  startingPosition,
});

const setFilteredSurveysResults = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_SURVEY_RESULTS,
  response,
  startCountFrom,
});

const loadSurveyResults = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    id: params.id,
  };

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const sessionData = { ..._omit(data, 'id'), findBySession: data.id };
  const url = createURLWithQuery('session_participants', sessionData);
  const pageUrl = createURLWithQuery('surveyResults', data);

  dispatch(replace(pageUrl));

  return ApiManager.request('get', dispatch, url);
};

const getSurveyResults = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  data.id = getStore().router.location.query.id;
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadSurveyResults(data)).then((response) => {
    dispatch(setFilteredSurveysResults(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getSurveyResults());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getSurveyResults());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getSurveyResults());
};

export const onEditData = (id) => (dispatch) => {
  dispatch(push('/participantEdit', { id }));
};

export const onDownload = () => (dispatch, getStore) => {
  const { resultsId } = getStore().SurveyResults;
  const { id } = getStore().router.location.query;
  dispatch(downloadSurveyFile(resultsId || id));
};

export const loadPageData = (routeState, values) => (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(setSortingAndPagination({}, sortFieldsMapping, values));

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  data.id = values.id;

  PromiseAll({
    surveyResults: dispatch(loadSurveyResults(data)),
  }).then((responses) => {
    dispatch(loadPageSuccess(responses, routeState, startingPosition));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
