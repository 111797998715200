import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';

import redHatText from 'misc/fonts/redHatText';
import redHatTextMedium from 'misc/fonts/redHatText-medium';
import redHatTextBold from 'misc/fonts/redHatText-bold';
import { jsPDF as JsPDF } from 'jspdf';
import 'jspdf-autotable';

import {
  constants,
  getImageFromElement,
  addImage,
  getTableByData,
  addVerticalImage,
} from 'utils/pdfDocument';
import messages from 'components/pages/GroupExaminationDetails/GroupExaminationDetails.messages';

const {
  bottomMargin,
  topMargin,
  headerFontSize,
  leftMargin,
} = constants;

const getColumnsFromHeaders = (headers, intl) => _map(headers, (header) => ({
  dataKey: header,
  header: intl.formatMessage(messages[header]),
}));

export const onExportExaminationClick = async (
  intl, theme, reportName, patientData, surveyId, notesData, descriptionData,
) => {
  const patientColumns = [
    {
      header: intl.formatMessage(messages.patient),
      dataKey: 'patient',
    },
    {
      header: intl.formatMessage(messages.classAndSection),
      dataKey: 'classAndSection',
    },
  ];

  const notesColumns = [
    {
      header: intl.formatMessage(messages.number),
      dataKey: 'noteNumber',
    },
    {
      header: intl.formatMessage(messages.date),
      dataKey: 'date',
    },
    {
      header: intl.formatMessage(messages.diagnosticianName),
      dataKey: 'diagnostician',
    },
    {
      header: intl.formatMessage(messages.content),
      dataKey: 'note',
    },
  ];

  const svgElements = _filter(document.body.querySelectorAll('svg'), (item) => !item.classList.contains('MuiCircularProgress-svg'));
  _forEach(svgElements, (item) => {
    item.setAttribute('width', item.getBoundingClientRect().width);
    item.setAttribute('height', item.getBoundingClientRect().height);
  });

  const { image: detailsImage, height: detailsHeight } = await getImageFromElement('examinationInfo');
  const { image: surveyInfoImage, height: surveyInfoHeight } = await getImageFromElement(`examinationNameComponent-${surveyId}`, true);
  const { image: graphDataImage, height: graphDataHeight } = await getImageFromElement(`graphComponent-${surveyId}`, true);
  const { image: metricsTableImage, height: metricsTableHeight } = await getImageFromElement(`metricsTableComponent-${surveyId}`, true);
  const { image: legendImage, height: legendHeight } = await getImageFromElement(`legendComponent-${surveyId}`, true);

  const doc = new JsPDF();
  doc.setLanguage('pl');
  doc.addFileToVFS('RedHatText.ttf', redHatText);
  doc.addFont('RedHatText.ttf', 'RedHatText', 'normal');
  doc.addFileToVFS('RedHatTextBold.ttf', redHatTextBold);
  doc.addFont('RedHatTextBold.ttf', 'RedHatText-bold', 'bold');
  doc.addFileToVFS('RedHatTextMedium.ttf', redHatTextMedium);
  doc.addFont('RedHatTextMedium.ttf', 'RedHatText-medium', 'medium');
  doc.setFont('RedHatText-medium', 'medium');
  doc.setFontSize(headerFontSize);
  doc.setTextColor(theme.palette.text.primary);

  let position = topMargin - bottomMargin;

  addImage(doc, detailsImage, position, detailsHeight);
  position += detailsHeight + bottomMargin * 2;

  doc.text(intl.formatMessage(messages.reportTitle), leftMargin, position);
  position += bottomMargin;

  getTableByData(
    doc,
    position,
    bottomMargin,
    patientData,
    patientColumns,
    theme.palette.primary.main,
  );

  position = doc.lastAutoTable.finalY + bottomMargin * 2;

  const formattedNotesData = _map(notesData, (note, index) => ({
    ...note,
    noteNumber: index + 1,
    date: note.date || note.pauseDate || note.interruptedDate,
  }));

  if (!_isEmpty(formattedNotesData)) {
    doc.text(intl.formatMessage(messages.diagnosticianNotes), leftMargin, position);
    position += bottomMargin;

    getTableByData(
      doc,
      position,
      bottomMargin,
      formattedNotesData,
      notesColumns,
      theme.palette.secondary.main,
    );
    position = doc.lastAutoTable.finalY + bottomMargin * 2;
  }

  doc.addPage('a4', 'l');
  position = topMargin;
  addVerticalImage(doc, surveyInfoImage, position, surveyInfoHeight);

  doc.text(intl.formatMessage(messages.graph), leftMargin, position);

  position += surveyInfoHeight;
  addVerticalImage(doc, graphDataImage, position, graphDataHeight);

  position += graphDataHeight + bottomMargin;
  addVerticalImage(doc, legendImage, position, legendHeight);

  doc.addPage('a4', 'l');

  position = topMargin;
  doc.text(intl.formatMessage(messages.metricsTable), leftMargin, position);
  position += bottomMargin;
  addVerticalImage(doc, metricsTableImage, position, metricsTableHeight);

  const descriptionHeaders = [
    'name',
    'code',
    'overallResult',
    'ten',
    'sten',
    'stanin',
  ];

  const descriptionColumns = getColumnsFromHeaders(descriptionHeaders, intl);

  const mappedData = _map(descriptionData, (data) => ({
    ...data,
    sten: data.sten.toFixed(2),
    ten: data.ten.toFixed(2),
    stanin: data.stanin.toFixed(2),
  }));

  position += metricsTableHeight + bottomMargin;
  doc.text(intl.formatMessage(messages.resultDescription), leftMargin, position);
  position += bottomMargin;
  getTableByData(
    doc,
    position,
    bottomMargin,
    mappedData,
    descriptionColumns,
    theme.palette.secondary.main,
    true,
  );

  doc.save(`${reportName}.pdf`);
};
