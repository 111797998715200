import saveAs from 'file-saver';

import config from 'config';

import snackbarMessages from 'utils/snackbarMessages';
import ApiManager from 'utils/ApiManager';
import parseContentDisposition from 'utils/parseContentDisposition';

import { showSnackbar, hideLoader } from 'containers/store';

export const FILE_DOWNLOAD_ENDPOINTS = {
  PATIENT_DOCUMENT: 'PATIENT_DOCUMENT',
  PATIENT_INVOICE: 'PATIENT_INVOICE',
};

export default (dispatch, id, endpoint, isView) => {
  let endpointUrl = '';
  switch (endpoint) {
    case FILE_DOWNLOAD_ENDPOINTS.PATIENT_DOCUMENT:
      endpointUrl = `patient_documents/${id}/download`;
      break;
    case FILE_DOWNLOAD_ENDPOINTS.PATIENT_INVOICE:
      endpointUrl = `order_invoices/${id}/download`;
      break;
    default:
      break;
  }

  if (isView) {
    window.open(`${config.localApiHost}/${config.apiUrl}/${endpointUrl}?contentDispositionInline=true`);
  } else {
    ApiManager.request('get', dispatch, endpointUrl, { responseType: 'blob' }, true).then((response) => {
      const { filename } = parseContentDisposition({ header: response.headers['content-disposition'] });
      const blob = new File([response.data], filename, { type: response.headers['content-type'] });

      saveAs(blob, filename);
      dispatch(hideLoader());
    }).catch(() => {
      dispatch(showSnackbar(snackbarMessages.globalError));
      dispatch(hideLoader());
    });
  }
};
