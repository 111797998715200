import { goBack } from 'connected-react-router';

import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _replace from 'lodash/replace';

import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import PromiseAll from 'utils/PromiseAll';
import ApiManager from 'utils/ApiManager';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import createURLWithQuery from 'utils/createURLWithQuery';
import config from 'config';

import {
  hideLoader, showSnackbar, showLoader,
  showTransparentLoader,
} from 'containers/store';

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'RESEARCH_TOOL_OPTIONS/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'RESEARCH_TOOL_OPTIONS/CLEAR_STORE',
  SET_SURVEY_SUGGESTIONS: 'RESEARCH_TOOL_OPTIONS/SET_SURVEY_SUGGESTIONS',
  CLEAR_SURVEY_SUGGESTIONS: 'RESEARCH_TOOL_OPTIONS/CLEAR_SURVEY_SUGGESTIONS',
};

export const initialState = {
  isLoadedPage: false,
  isEdit: false,
  initialValues: {
    name: '',
    code: '',
    description: '',
    mean: '',
    std: '',
    status: '',
    refGroup: '',
    battery: '',
    surveyId: '',
    standardScale: '',
    survey: {},
    order: '',
  },
  dataLists: {
    refGroupsList: [],
    batteriesList: [],
    standardScalesList: [],
    surveySuggestions: [],
  },
};

const mapDataToValues = (data, onlyCommonFields) => {
  const initialValues = {
    mean: data.avg_continuous_scale || '',
    std: data.std_deviation_continuous_scale || '',
    status: data.status || null,
    battery: data.battery_id || null,
    survey: data.survey && ({
      id: data.survey.id,
      name: data.survey.name,
    }),
    testPrice: data.tokens_usage_cost || '',
    description: data.description || '',
    code: data.code || '',
    standardScale: data.standard_categorical_scale_id || '',
    order: _get(data, 'secondary_survey_tools', []).length,
    refGroup: '',
  };

  if (!onlyCommonFields) {
    initialValues.name = data.name || '';
    initialValues.order = _get(data, 'order', 0);
    initialValues.refGroup = data.reference_group_set_id || '';
  }

  return initialValues;
};

const getInitialValues = (responses, toolData, surveyId) => {
  if (!_isEmpty(toolData)) {
    return mapDataToValues(toolData);
  }

  const parentToolData = _get(responses, 'parentToolData.data', {});

  if (!_isEmpty(parentToolData)) {
    return mapDataToValues(parentToolData, true);
  }

  if (surveyId) {
    const survey = _find(responses.surveys.data.items, (el) => el.id === surveyId);

    return {
      ...initialState.initialValues,
      survey,
      status: config.researchToolStatuses.inactive,
    };
  }
  return {
    ...initialState.initialValues,
    status: config.researchToolStatuses.inactive,
  };
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        toolId: action.toolId,
        isEdit: !!action.toolId,
        initialValues: getInitialValues(action.responses, action.toolData, action.surveyId),
        dataLists: {
          ...state.dataLists,
          refGroupsList: action.responses.referenceGroupSets.data.items,
          standardScalesList: action.responses.standardScales.data.items,
          surveys: action.responses.surveys.data.items,
          batteriesList: action.responses.batteries.data.items,
        },
      };
    }

    case actionTypes.SET_SURVEY_SUGGESTIONS: {
      return {
        ...state,
        dataLists: {
          ...state.dataLists,
          surveySuggestions: _map(action.response.data.items, (el) => ({
            id: el.id,
            name: el.name,
          })),
        },
      };
    }

    case actionTypes.CLEAR_SURVEY_SUGGESTIONS: {
      return {
        ...state,
        dataLists: {
          ...state.dataLists,
          surveySuggestions: [],
        },
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (toolId, surveyId, toolData, responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  toolId,
  responses,
  surveyId,
  toolData,
});

export const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setSurveySuggestions = (response) => ({
  type: actionTypes.SET_SURVEY_SUGGESTIONS,
  response,
});

const loadBatteries = () => (dispatch) => {
  const params = {
    orderBy: 'name',
    sortedBy: 'asc',
  };

  const url = createURLWithQuery('batteries', params);

  return ApiManager.request('get', dispatch, url);
};

const loadReferenceGroupSets = () => (dispatch) => {
  const params = {
    orderBy: 'name',
    sortedBy: 'asc',
  };

  const url = createURLWithQuery('reference_group_sets', params);

  return ApiManager.request('get', dispatch, url);
};

export const loadStandardScales = () => (dispatch) => {
  const params = {
    orderBy: 'name',
    sortedBy: 'asc',
  };

  const url = createURLWithQuery('standard_categorical_scales', params);

  return ApiManager.request('get', dispatch, url);
};

const loadToolData = (id) => (dispatch) => ApiManager.request('get', dispatch, `survey_tools/${id}`);

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

const getSurveys = (findByName) => (dispatch) => {
  const params = {
    findByName,
    findByStatus: 'PRODUCTION',
    page: 1,
    perPage: config.maxSuggestions,
  };

  const url = createURLWithQuery('surveys', params);

  return ApiManager.request('get', dispatch, url);
};

export const onSurveyFetch = (search) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(getSurveys(search)).then((response) => {
    dispatch(setSurveySuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSurveyClear = () => ({
  type: actionTypes.CLEAR_SURVEY_SUGGESTIONS,
});

export const onSubmit = (values, { setFieldError }) => async (dispatch, getStore) => {
  const store = getStore().ResearchToolOptions;
  const { toolId } = store;
  const { primaryToolId } = getStore().ResearchToolAddEdit;

  let url = 'survey_tools';
  let method = 'post';
  let message = snackbarMessages.toolAddedSuccessfully;

  const data = {
    name: values.name,
    code: values.code,
    type: primaryToolId ? config.researchToolTypes.secondary : config.researchToolTypes.primary,
    order: values.order,
  };

  if (primaryToolId) {
    data.primary_survey_tool_id = primaryToolId;
    data.reference_group_set_id = values.refGroup;
  }

  if (!primaryToolId) {
    data.battery_id = values.battery;
    data.description = values.description;
    data.survey_id = values.survey.id;
    data.status = values.status;

    if (values.testPrice) {
      data.tokens_usage_cost = _replace(values.testPrice, ',', '.');
    }

    if (values.mean) {
      data.avg_continuous_scale = values.mean;
    }

    if (values.std) {
      data.std_deviation_continuous_scale = values.std;
    }

    if (values.standardScale) {
      data.standard_categorical_scale_id = values.standardScale;
    }
  }

  if (toolId) {
    url = `survey_tools/${toolId}`;
    method = 'put';
    message = snackbarMessages.toolEditedSuccessfully;
  }

  dispatch(showTransparentLoader());

  try {
    await ApiManager.request(method, dispatch, url, data);

    dispatch(showSnackbar(message));
    dispatch(hideLoader());

    dispatch(goBack());
  } catch (error) {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.toolsAddEdit);
      const isSurveyError = !_isEmpty(error.error.errors.survey_id);
      if (isSurveyError) {
        dispatch(showSnackbar(snackbarMessages.notEmptySurvey));
      } else {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      }
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

export const loadPageData = (toolId, surveyId) => async (dispatch, getStore) => {
  dispatch(showLoader());

  const { isEdit, primaryToolId, toolData } = getStore().ResearchToolAddEdit;

  const promises = {
    referenceGroupSets: dispatch(loadReferenceGroupSets()),
    batteries: dispatch(loadBatteries()),
    standardScales: dispatch(loadStandardScales()),
    surveys: dispatch(getSurveys()),
  };

  if (!isEdit && primaryToolId) {
    promises.parentToolData = dispatch(loadToolData(primaryToolId));
  }

  PromiseAll(promises).then((responses) => {
    dispatch(
      loadPageSuccess(toolId, surveyId, toolData, responses),
    );
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
