export default (theme) => ({
  wrapper: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: 16,
  },
  tooltip: {
    fontFamily: 'Red Hat Text',
    backgroundColor: theme.palette.tooltip.backgroundColor,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: 14,
    lineHeight: '20px',
  },
});
