const playerConfig = {
  file: {
    attributes: {
      controlsList: 'nodownload',
      disablePictureInPicture: true,
    },
  },
};

export { playerConfig };
