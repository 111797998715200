import _forEach from 'lodash/forEach';
import _replace from 'lodash/replace';
import _startsWith from 'lodash/startsWith';
import _endsWith from 'lodash/endsWith';

export default (errors, setFieldError, fieldsMapping) => {
  _forEach(errors, (value, apiArrayName) => {
    if (_startsWith(apiArrayName, fieldsMapping.arrayName)) {
      const errorFieldName = _replace(apiArrayName, `${fieldsMapping.arrayName}.`, '');

      _forEach(fieldsMapping.fieldsMapping, (field, apiFieldName) => {
        if (_endsWith(errorFieldName, apiFieldName)) {
          const fieldNumber = _replace(errorFieldName, `.${apiFieldName}`, '');
          const newFieldName = `${field}-${fieldNumber}`;
          setFieldError(newFieldName, value[0]);
        }
      });
    }
  });
};
