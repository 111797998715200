import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  text: {
    minWidth: 'auto',
    padding: 0,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.white,
    },
  },
  buttonMargin: {
    marginRight: 12,
  },
  label: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.placeholder,
    marginBottom: theme.spacing(1),
  },
  isBigValueDark: {
    fontSize: 32,
    '&.Mui-disabled': {
      color: theme.palette.annotationColor,
    },
  },
  inputRoot: {
    lineHeight: 1,
    height: 32,
    padding: theme.spacing(1.5, 2.5),
  },
  inputOutline: {
    borderColor: `${theme.palette.grey.outlinedInputBorder} !important`,
  },
}));
