import { push, replace } from 'connected-react-router';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import errorCatch from 'utils/errorCatch';
import formatCodeWithName from 'utils/formatCodeWithName';

import {
  hideLoader, showLoader, showTransparentLoader, setPage, setRowsPerPage,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
};

const mapBatteries = (batteries, startingPosition) => batteries.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: formatCodeWithName(el),
  plainName: el.name,
  code: el.code,
  descripiton: el.description,
  isActive: el.is_active,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'TOOLS_LIST/LOAD_PAGE_SUCCESS',
  SET_FILTERED_TOOLS: 'TOOLS_LIST/SET_FILTERED_TOOLS',
  CLEAR_STORE: 'TOOLS_LIST/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapBatteries(action.responses.data.items, action.startingPosition),
        totalItemsCount: action.responses.data.total,
      };
    }

    case actionTypes.SET_FILTERED_TOOLS: {
      return {
        ...state,
        items: mapBatteries(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  startingPosition,
});

const setFilteredBatteries = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_TOOLS,
  response,
  startCountFrom,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadBatteries = (params) => (dispatch, getStore) => {
  const clinicID = _get(getStore(), 'Global.userData.clinics[0].id', null);

  const data = {
    perPage: params.perPage,
    page: params.page,
    withSurveyToolStatuses: ['ACTIVE'],
  };

  if (clinicID) {
    data.clinicId = clinicID;
  }

  const url = createURLWithQuery('batteries', data);
  const pageUrl = createURLWithQuery('tools', _omit(data, ['withSurveyToolStatuses', 'clinicId']));

  dispatch(replace(pageUrl));

  return ApiManager.request('get', dispatch, url);
};

const getBatteries = () => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const startCountFrom = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore);

  try {
    const batteriesData = await dispatch(loadBatteries(data));
    dispatch(setFilteredBatteries(batteriesData, startCountFrom));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onChangePage = (_, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getBatteries());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getBatteries());
};

export const onActionClick = (el) => (dispatch) => {
  const data = {
    isActive: el.isActive,
    id: el.apiId,
    title: {
      name: el.plainName,
      code: el.code,
    },
    description: el.descripiton,
  };

  dispatch(push('/toolsDetails', data));
};

export const loadPageData = (values) => async (dispatch, getStore) => {
  dispatch(clearStore());
  dispatch(showLoader());
  if (values.page) {
    dispatch(setPage(Number(values.page)));
  }

  if (values.perPage) {
    dispatch(setRowsPerPage(Number(values.perPage)));
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore);

  try {
    const batteriesData = await dispatch(loadBatteries(data));

    dispatch(loadPageSuccess(batteriesData, startingPosition));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
