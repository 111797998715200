import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    name: {
      lineHeight: '22px',
    },
    wrapper: {
      position: 'relative',
    },
    maxWidth: {
      maxWidth: 'calc(100% - 36px)',
    },
    tooltip: {
      ...theme.tooltip,
      fontWeight: 500,
      backgroundColor: theme.palette.examinationTooltip,
      lineHeight: '16px',
      marginBottom: theme.spacing(1, 0),
      borderRadius: 4,
      padding: theme.spacing(0.5, 1),
      top: 6,
    },
    tooltipArrow: {
      color: theme.palette.examinationTooltip,
    },
    starIcon: {
      position: 'absolute',
      top: -1,
      right: 0,
      boxSizing: 'initial',
    },
    inactiveIcon: {
      height: 17,
      width: 17,
      marginLeft: 17,
      marginBottom: 3,
    },
  }),
);
