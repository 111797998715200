import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

const SectionTitle = ({ children }) => (
  <Typography variant="h6">
    {children}
  </Typography>
);

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionTitle;
