import { defineMessages } from 'react-intl';

export default defineMessages({
  list: 'Badania klienta',
  toolName: 'Nazwa testu',
  examinationDate: 'Data badania',
  status: 'Status',
  finished: 'Zakończone',
  notFound: 'Nie znaleziono żadnych badań',
  hasUndesirableSurveyEvaluations: 'Niepożądane wyniki badania',
  hasUndesirableSurveyEvaluationsDescription: 'Uwaga: Osoba badana uzyskała niepożądane wyniki badania w przynajmniej jednej skali. Przejdź do wyników badania indywidualnego aby zobaczyć szczegóły.',
  groupSurvey: 'Badanie grupowe',
});
