import { goBack } from 'connected-react-router';
import _map from 'lodash/map';
import _find from 'lodash/find';

import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import errorCatch from 'utils/errorCatch';
import dialogTexts from 'utils/dialogTexts';
import formatCodeWithName from 'utils/formatCodeWithName';

import {
  showLoader,
  hideLoader,
  showSnackbar,
  openDialog,
  showTransparentLoader,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    clinic: '',
    battery: '',
    subscriptionCount: 1,
  },
  batteryOptions: [],
  clinicId: null,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SUBSCRIPTION_ADD/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'SUBSCRIPTION_ADD/CLEAR_STORE',
};

const getBatteryOptions = (items) => _map(items, (item) => ({
  id: item.id,
  name: formatCodeWithName(item),
  is_active: item.is_active,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        batteryOptions: getBatteryOptions(action.responses.batteries.data.items),
        initialValues: {
          ...state.initialValues,
          clinic: action.responses.clinic.data.name,
        },
        clinicId: action.responses.clinic.data.id,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const getClinicData = (id) => (dispatch) => ApiManager.request('get', dispatch, `clinics/${id}`);

const getBatteries = (clinicId) => (dispatch) => {
  const url = createURLWithQuery('batteries', { hasActiveSurveyTool: true, clinicId });

  return ApiManager.request('get', dispatch, url);
};

const onSubmitAccept = (values, setFieldError) => async (dispatch, getStore) => {
  const { clinicId, batteryOptions } = getStore().SubscriptionAdd;

  dispatch(showTransparentLoader());

  try {
    const orderData = {
      amount_of_tokens: values.subscriptionCount,
      battery_id: values.battery,
      clinic_id: clinicId,
      with_survey_tool_manual: !_find(batteryOptions, ['id', values.battery]).is_active,
    };

    const order = await ApiManager.request('post', dispatch, 'orders', orderData);
    const orderId = order.data.id;

    await ApiManager.request('put', dispatch, `orders/${orderId}`, {
      status: 'COMPLETED',
    });

    dispatch(showSnackbar(snackbarMessages.subscriptionsAdded));
    dispatch(clearStore());
    dispatch(goBack());
    dispatch(hideLoader());
  } catch (error) {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.subscriptionAdd);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

export const onSubmit = (values, { setFieldError }) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.addSubscription,
    onAccept: () => dispatch(onSubmitAccept(values, setFieldError)),
  }));
};

export const onCancel = () => (dispatch) => {
  dispatch(showLoader());
  dispatch(goBack());
};

export const loadPageData = (routeState) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());

  try {
    const data = await PromiseAll({
      clinic: dispatch(getClinicData(routeState.clinicID)),
      batteries: dispatch(getBatteries(routeState.clinicID)),
    });

    dispatch(loadPageSuccess(data));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch, true);
  }
};
