import { goBack } from 'connected-react-router';

import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import createURLWithQuery from 'utils/createURLWithQuery';
import getCombinationName from 'utils/getCombinationName';

import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader, setDrawerVisibility,
} from 'containers/store';

import {
  getGroupStructure,
  getUsedScalesIds,
  getDimensionGroupOptions,
  getDimensionGroupInitialValues,
  getCombinationValues,
  getEvaluationData,
  formatInitialValues,
} from './utils';

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'EVALUATIONS_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'EVALUATIONS_ADD_EDIT/CLEAR_STORE',
};

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    id: '',
    name: '',
    description: '',
    dimensionGroups: [],
  },
};

const getValuesOptions = (values) => _map(values, (value) => ({ id: value.id, name: value.label }));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const evaluationData = !_isEmpty(action.responses.evaluationData) ? getEvaluationData(
        action.responses.evaluationData.data.scs_values_combinations,
        action.responses.standardScale.data.standard_categorical_scale_values,
      ) : [];

      const groupStructure = getGroupStructure(
        action.responses.scales.data.items,
        action.responses.loads.data.items,
        action.responses.parameters.data.items,
      );
      const usedScalesIds = getUsedScalesIds(evaluationData);
      const dimensionGroups = getDimensionGroupOptions(groupStructure, usedScalesIds);
      const initialDimensionGroups = getDimensionGroupInitialValues(groupStructure, usedScalesIds);

      const valuesOptions = getValuesOptions(
        action.responses.standardScale.data.standard_categorical_scale_values,
      );

      const initialValues = !_isEmpty(action.responses.evaluationData)
        ? formatInitialValues(
          action.responses.evaluationData.data,
          initialDimensionGroups,
          evaluationData,
        ) : initialState.initialValues;

      return {
        ...state,
        dimensionGroups,
        valuesOptions,
        isLoadedPage: true,
        researchToolId: action.researchToolId,
        itemId: action.itemId,
        initialValues,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (researchToolId, itemId, responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  researchToolId,
  itemId,
  responses,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadEvaluationData = (id) => (dispatch) => ApiManager.request('get', dispatch, `scs_values_combinations_sets/${id}`);

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const store = getStore().EvaluationAddEdit;
  const itemId = store.itemId;
  let url = 'scs_values_combinations_sets';
  let method = 'post';
  let message = snackbarMessages.evaluationAddedSuccessfully;

  const data = {
    name: getCombinationName(values.dimensionGroups),
    code: values.code,
    description: values.description,
    survey_tool_id: store.researchToolId,
    scs_values_combinations: getCombinationValues(values.dimensionGroups),
  };

  if (itemId) {
    data.id = itemId;
    url = `scs_values_combinations_sets/${itemId}`;
    method = 'put';
    message = snackbarMessages.evaluationEditedSuccessfully;
  }

  dispatch(showTransparentLoader());

  ApiManager.request(method, dispatch, url, data).then(() => {
    dispatch(showSnackbar(message));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.scaleEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

const loadScales = (id) => (dispatch) => {
  const url = createURLWithQuery('scales', {
    findBySurveyTool: id,
  });

  return ApiManager.request('get', dispatch, url);
};

const loadScaleParameters = (id) => (dispatch) => {
  const url = createURLWithQuery('scale_parameters', {
    findBySurveyTool: id,
  });

  return ApiManager.request('get', dispatch, url);
};

const loadScaleLoads = (id) => (dispatch) => {
  const url = createURLWithQuery('scale_charges', {
    findBySurveyTool: id,
  });

  return ApiManager.request('get', dispatch, url);
};

const loadStandardScale = (id) => (dispatch) => new Promise((resolve, reject) => {
  ApiManager.request('get', dispatch, `survey_tools/${id}`).then((response) => {
    const standardScaleId = response.data.standard_categorical_scale_id;
    const url = `standard_categorical_scales/${standardScaleId}`;

    ApiManager.request('get', dispatch, url).then((standardScaleData) => {
      resolve(standardScaleData);
    }).catch((error) => {
      reject(error);
    });
  }).catch((error) => {
    reject(error);
  });
});

export const loadPageData = (researchToolId, id, message) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  const promises = {
    standardScale: dispatch(loadStandardScale(researchToolId)),
    scales: dispatch(loadScales(researchToolId)),
    loads: dispatch(loadScaleLoads(researchToolId)),
    parameters: dispatch(loadScaleParameters(researchToolId)),
  };

  if (id) {
    promises.evaluationData = dispatch(loadEvaluationData(id));
  }

  PromiseAll(promises).then((responses) => {
    dispatch(loadPageSuccess(researchToolId, id, responses));
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
