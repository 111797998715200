const MINUTES = 60;
const SECONDS = 60;

const ratio = MINUTES * SECONDS;

const fromApiFormat = (value) => parseInt(value) / ratio;

const toApiFormat = (value) => parseInt(value) * ratio;

export default (time, { type }) => {
  if (!time) {
    return '';
  }

  if (type === 'fromApiFormat') {
    return fromApiFormat(time);
  }

  if (type === 'toApiFormat') {
    return toApiFormat(time);
  }

  return time;
};
