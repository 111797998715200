import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteUser: 'Czy na pewno chcesz usunąć użytkownika?',
  deleteOrganizations: 'Czy na pewno chcesz usunąć placówkę?',
  deleteQuestion: 'Czy na pewno chcesz usunąć pytanie?',
  deleteSurvey: 'Czy na pewno chcesz usunąć ankietę?',
  deleteAnswer: 'Czy na pewno chcesz usunąć zestaw odpowiedzi?',
  deleteResearchTool: 'Czy na pewno chcesz usunąć narzędzie badawcze?',
  deleteScale: 'Czy na pewno chcesz usunąć skalę?',
  deleteStandardScale: 'Czy na pewno chcesz usunąć kategorialną skalę standardową?',
  deleteBattery: 'Czy na pewno chcesz usunąć baterię?',
  deleteReferenceGroup: 'Czy na pewno chcesz usunąć podział na grupy odniesienia?',
  overwriteExistingModel: 'Model psychometryczny został już załadowany. Czy na pewno chcesz go nadpisać?',
  deleteReplacement: 'Czy na pewno chcesz odwołać zastępstwo?',
  deletePatient: 'Czy na pewno chcesz usunąć klienta?',
  restorePatient: 'Czy na pewno chcesz przywrócić klienta?',
  addSubscription: 'Czy na pewno chcesz przypisać abonament?',
  removeVideoTutorial: 'Czy na pewno chcesz usunąć film instruktażowy?',
  deleteResultsEvaluation: 'Czy na pewno chcesz usunąć kombinację wyników?',
  deleteFile: 'Czy na pewno chcesz usunąć plik?',
  batteryActivate: 'Czy na pewno chcesz aktywować baterię?',
  acceptCreditPoints: 'Czy na pewno chcesz zaakceptować przyznanie punktów kredytowych?',
  refuseCreditPoints: 'Czy na pewno chcesz anulować przyznanie punktów kredytowych?',
  acceptPurchase: 'Czy na pewno chcesz zaakceptować doładowanie?',
  refusePurchase: 'Czy na pewno chcesz anulować doładowanie?',
  startTryAndBuy: 'Czy na pewno chcesz rozpocząć okres Try&Buy?',
  endTryAndBuy: 'Czy na pewno chcesz zakończyć okres Try&Buy?',
  activateFacility: 'Czy na pewno chcesz aktywować placówkę?',
  inactivateFacility: 'Czy na pewno chcesz dezaktywować placówkę?',
  sendExaminationLink: 'Czy chcesz ponownie wysłać wiadomość e-mail z linkiem do rozpoczęcia badania?',
  abandonCurrentExamination: 'Uruchomienie tego testu zakończy badanie, które trwa. Czy na pewno chcesz kontynuować?',
  diagnosticianActivate: 'Czy na pewno chcesz dezaktywować użytkownika?',
  diagnosticianDeactivate: 'Czy na pewno chcesz ponownie aktywować użytkownika?',
  removeFacility: 'Czy na pewno chcesz usunąć placówkę?',
});
