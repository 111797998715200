import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import notFound from 'assets/img/notFound.svg';

import TableHead from 'components/TableHead';
import TableCell from 'components/TableCell';

import { Checkbox } from '@material-ui/core';
import CustomPagination from './components/Pagination';
import Actions from './components/Actions';

import {
  renderField,
  getLengthOfActions,
  getNewHead,
  getNoItemsComponent,
} from './CustomTable.utils';
import { headShape } from './CustomTable.shapes';
import useStyles from './CustomTable.styles';

const CustomTable = ({
  items, head, sortingDirection, sortingField, onChangeSort, onFilter,
  onView, onDelete, onEdit, rowsPerPageOptions, totalItemsCount, rowsPerPage,
  page, onChangePage, onChangeRowsPerPage, isEditable, isDeletable, isRedirect,
  isViewable, onRedirect, noItemsComponent, noItemsMessage, noItemsImage, isNumberCell,
  isCustomAction, onCustomActionClick, customActionComponent, tablePagination,
  permissionsDelete, permissionsEdit, permissionsRedirect, permissionsView, tableRowStyles,
  actionCellLength, hideAvailableCountColumn, tableId, withActions, showHead, actionsHeaderLabel,
  withMiddleVerticalAlign, centerCustomActions, customRowRenderer, redirectDisabled,
  customActionStyles, selectable, onCheck, onCheckAll, selectedRows, checkedAll,
}) => {
  const actionsLength = actionCellLength || getLengthOfActions({
    isEditable, isDeletable, isRedirect, isViewable, isCustomAction,
  });
  const classes = useStyles({ actionsLength: hideAvailableCountColumn ? 'auto' : actionsLength });
  const newHead = getNewHead(head, classes, isNumberCell, withActions, actionsHeaderLabel);

  return (
    <div className={classes.contentWrapper}>
      <Table
        className={classNames(classes.tableRoot, {
          [classes.fixedLayout]: !isNumberCell,
        })}
        id={tableId}
      >
        {(showHead || !_isEmpty(items)) && (
          <TableHead
            selectable={selectable}
            rows={newHead}
            sortingDirection={sortingDirection}
            sortingField={sortingField}
            onChangeSort={onChangeSort}
            onFilter={onFilter}
            onCheckAll={onCheckAll}
            checkedAll={checkedAll}
            checkedRows={selectedRows}
          />
        )}
        {!_isEmpty(items) && (
          <TableBody>
            {items.map((row) => (
              <TableRow
                key={row.id}
                classes={!_isNull(tableRowStyles) ? tableRowStyles(row) : null}
                className={classes.hoverableRow}
              >
                {
                  selectable && (
                    <TableCell className={classes.selectCell}>
                      <Checkbox
                        color="primary"
                        value={_includes(selectedRows, row.apiId)}
                        checked={_includes(selectedRows, row.apiId)}
                        onChange={(e) => {
                          const { checked } = e.target;
                          onCheck(row.apiId, checked);
                        }}
                      />
                    </TableCell>
                  )
                }
                {
                  customRowRenderer
                    ? customRowRenderer(row, withMiddleVerticalAlign, newHead)
                    : newHead.map((el) => (renderField(el, row, withMiddleVerticalAlign)))
                }
                {actionsLength > 0 && (
                  <TableCell
                    noWrap
                    className={
                      classNames(classes.actionsCell,
                        {
                          [classes.customActionsCell]: centerCustomActions,
                          [customActionStyles]: customActionStyles,
                        })
                    }
                    withMiddleVerticalAlign={withMiddleVerticalAlign}
                  >
                    <div
                      className={
                        classNames(
                          classes.actionsWrapper,
                          { [classes.redirectButton]: isRedirect },
                        )
                      }
                    >
                      <Actions
                        isEditable={isEditable}
                        isRedirect={isRedirect}
                        isDeletable={isDeletable}
                        isViewable={isViewable}
                        isCustomAction={isCustomAction}
                        customActionComponent={customActionComponent}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onView={onView}
                        onRedirect={onRedirect}
                        redirectDisabled={redirectDisabled}
                        onCustomActionClick={onCustomActionClick}
                        rowID={row.id}
                        row={row}
                        permissionsDelete={permissionsDelete}
                        permissionsEdit={permissionsEdit}
                        permissionsRedirect={permissionsRedirect}
                        permissionsView={permissionsView}
                        classes={classes}
                      />
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {_isEmpty(items) && (
        getNoItemsComponent(noItemsComponent, noItemsMessage, noItemsImage)
      )}
      {tablePagination && !_isEmpty(items) && (
        <CustomPagination
          rowsPerPageOptions={rowsPerPageOptions}
          totalItemsCount={totalItemsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      )}
    </div>
  );
};

CustomTable.propTypes = {
  head: PropTypes.arrayOf(headShape).isRequired,
  actionCellLength: PropTypes.number,
  actionsHeaderLabel: PropTypes.string,
  centerCustomActions: PropTypes.bool,
  checkedAll: PropTypes.bool,
  customActionComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  customActionStyles: PropTypes.string,
  customRowRenderer: PropTypes.func,
  hideAvailableCountColumn: PropTypes.bool,
  isCustomAction: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isEditable: PropTypes.bool,
  isNumberCell: PropTypes.bool,
  isRedirect: PropTypes.bool,
  isViewable: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  noItemsComponent: PropTypes.node,
  noItemsImage: PropTypes.string,
  noItemsMessage: PropTypes.node,
  page: PropTypes.number,
  permissionsDelete: PropTypes.arrayOf(PropTypes.bool),
  permissionsEdit: PropTypes.arrayOf(PropTypes.bool),
  permissionsRedirect: PropTypes.arrayOf(PropTypes.bool),
  permissionsView: PropTypes.arrayOf(PropTypes.bool),
  redirectDisabled: PropTypes.func,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  showHead: PropTypes.bool,
  sortingDirection: PropTypes.oneOf(['asc', 'desc']),
  sortingField: PropTypes.string,
  tableId: PropTypes.string,
  tablePagination: PropTypes.bool,
  tableRowStyles: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  totalItemsCount: PropTypes.number,
  withActions: PropTypes.bool,
  withMiddleVerticalAlign: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onChangeSort: PropTypes.func,
  onCheck: PropTypes.func,
  onCheckAll: PropTypes.func,
  onCustomActionClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onFilter: PropTypes.func,
  onRedirect: PropTypes.func,
  onView: PropTypes.func,
};

CustomTable.defaultProps = {
  onDelete: () => {},
  onEdit: () => {},
  onView: () => {},
  onRedirect: () => {},
  onCustomActionClick: () => {},
  onChangePage: () => {},
  onChangeRowsPerPage: () => {},
  onChangeSort: () => {},
  onFilter: () => {},
  redirectDisabled: () => false,
  onCheck: () => {},
  onCheckAll: () => {},
  checkedAll: false,
  customRowRenderer: null,
  customActionStyles: null,
  rowsPerPage: null,
  rowsPerPageOptions: null,
  totalItemsCount: null,
  isDeletable: false,
  isEditable: false,
  isRedirect: false,
  isCustomAction: false,
  isViewable: false,
  sortingField: null,
  sortingDirection: 'asc',
  items: [],
  noItemsComponent: null,
  noItemsMessage: null,
  noItemsImage: notFound,
  customActionComponent: null,
  centerCustomActions: true,
  isNumberCell: true,
  withActions: true,
  tablePagination: true,
  page: null,
  permissionsDelete: [],
  permissionsEdit: [],
  permissionsRedirect: [],
  permissionsView: [],
  tableRowStyles: null,
  tableId: '',
  actionCellLength: null,
  hideAvailableCountColumn: false,
  actionsHeaderLabel: '',
  showHead: true,
  withMiddleVerticalAlign: false,
  selectable: false,
  selectedRows: [],
};

export default CustomTable;
