import moment from 'moment';

import _toUpper from 'lodash/toUpper';
import _get from 'lodash/get';

import getDateFromApi from 'utils/getDateFromApi';
import getDateTimeFromApi from 'utils/getDateTimeFromApi';
import formatFullName from 'utils/formatFullName';
import { nationality } from 'utils/constants/nationality';

const formatBirthData = (birthDate, formatDate) => {
  if (!birthDate) {
    return null;
  }

  return formatDate ? getDateFromApi(birthDate) : moment(birthDate);
};

const formatPatientData = (data, parseToReadableFormat, formatDate) => ({
  patientID: data.id || '',
  name: data.first_name || '',
  surname: data.last_name || '',
  birthDate: formatBirthData(data.birth_date, formatDate),
  birthPlace: data.birthplace || '',
  pesel: data.pesel || '',
  nationality: parseToReadableFormat ? nationality[data.nationality] : data.nationality,
  streetAndNumber: data.address_street || '',
  city: data.address_city || '',
  postalCode: data.address_postal_code || '',
  schoolName: parseToReadableFormat ? data.school_name : { id: data.id, name: data.school_name || '' },
  schoolAddress: data.school_address || '',
  class: data.school_class || '',
  department: _toUpper(data.school_class_section),
  deletedAt: data.deleted_at || null,
  userSex: data.sex,
  voivodeship: parseToReadableFormat ? _get(data, 'school_voivodeship.name', '') : data.school_voivodeship_id || '',
  schoolCity: parseToReadableFormat ? data.school_city : { id: data.id, name: data.school_city || '' },
  diagnostician: formatFullName(data.user),
  createProfileDate: getDateTimeFromApi(data.created_at) || '',
  isBeingRemoved: !!data.deleted_at,
  email: data.email || '',
  hasSpecialEducationalNeeds: data.has_special_educational_needs || false,
});

export default formatPatientData;
