import { goBack } from 'connected-react-router';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import createURLWithQuery from 'utils/createURLWithQuery';
import errorCatch from 'utils/errorCatch';

import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader, setDrawerVisibility,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    name: '',
    surname: '',
    city: '',
    organization: '',
    birthYear: null,
    birthMonth: null,
    birthDay: null,
  },
  participantId: null,
  organizationSuggestions: [],
};

const mapDataToValues = (data) => ({
  name: data.first_name || '',
  surname: data.last_name || '',
  city: data.user_city || '',
  organization: data.organization && {
    id: data.organization.id,
    name: data.organization.name,
  },
  userSex: data.user_sex,
  birthMonth: data.user_birth_month || '',
  birthYear: data.user_birth_year || '',
  birthDay: data.user_birth_day || '',
});

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'PARTICIPANT_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'PARTICIPANT_EDIT/CLEAR_STORE',
  SET_SUGGESTIONS: 'PARTICIPANT_EDIT/SET_SUGGESTIONS',
  CLEAR_SUGGESTIONS: 'PARTICIPANT_EDIT/CLEAR_SUGGESTIONS',
};

const mapSuggestions = (items) => items.map((el) => ({
  id: el.id,
  name: el.name,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        initialValues: action.responses.participantData
          ? mapDataToValues(action.responses.participantData.data) : initialState.initialValues,
        participantId: action.responses.participantData.data.id,
      };
    }

    case actionTypes.SET_SUGGESTIONS: {
      return {
        ...state,
        organizationSuggestions: mapSuggestions(action.response.data.items),
      };
    }

    case actionTypes.CLEAR_SUGGESTIONS: {
      return {
        ...state,
        organizationSuggestions: [],
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (participantId, responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  participantId,
  responses,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setSuggestions = (response) => ({
  type: actionTypes.SET_SUGGESTIONS,
  response,
});

const loadParticipants = (id) => (dispatch) => ApiManager.request('get', dispatch, `participants/${id}`);

const getOrganizations = (find) => (dispatch) => {
  const params = {
    find,
  };

  const url = createURLWithQuery('organizations', params);

  return ApiManager.request('get', dispatch, url);
};

export const onOrganizationsFetch = (search) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(getOrganizations(search)).then((response) => {
    dispatch(setSuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};

export const onOrganizationsClear = () => ({
  type: actionTypes.CLEAR_SUGGESTIONS,
});

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const store = getStore().ParticipantEdit;
  const participantId = store.participantId;

  const url = `participants/${participantId}`;

  const data = {
    first_name: values.name,
    last_name: values.surname,
    user_city: values.city,
    organization_id: values.organization.id,
    user_sex: values.userSex,
    user_birth_month: values.birthMonth,
    user_birth_year: values.birthYear,
    user_birth_day: values.birthDay,
  };

  dispatch(showTransparentLoader());

  ApiManager.request('put', dispatch, url, data).then(() => {
    dispatch(showSnackbar(snackbarMessages.participantEditedSuccessfully));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.participantEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const loadPageData = (routeState, message) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));
  const promises = {};

  if (routeState.id) {
    promises.participantData = dispatch(loadParticipants(routeState.id));
  }

  PromiseAll(promises).then((responses) => {
    dispatch(loadPageSuccess(routeState.id, responses));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
