export default (event, allowDotsAndCommas) => {
  const charCode = (typeof event.which === 'undefined') ? event.keyCode : event.which;
  const charStr = String.fromCharCode(charCode);
  const numberType = /^\d+$/;
  const numberOrDotType = /^[.,\d]+$/;
  const regex = allowDotsAndCommas ? numberOrDotType : numberType;

  if (!charStr.match(regex)) {
    event.preventDefault();
  }
};
