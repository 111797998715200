import { defineMessages } from 'react-intl';
import moment from 'moment';

const messages = defineMessages({
  tooEarlyDate: 'Pole "do" nie może być mniejsze od pola "od"',
});

export default (date, earliestDate) => {
  const result = {};
  let isValid = true;

  if (moment.isMoment(date) && moment.isMoment(earliestDate)) {
    if (moment(date).isValid() && moment(earliestDate).isValid()) {
      isValid = date.isSameOrAfter(earliestDate, 'day');
    }
  }

  result.isValid = isValid;
  result.message = messages.tooEarlyDate;

  return result;
};
