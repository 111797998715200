import React from 'react';
import { push, goBack } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import { saveAs } from 'file-saver';
import _get from 'lodash/get';
import _values from 'lodash/values';
import _pick from 'lodash/pick';

import ApiManager from 'utils/ApiManager';
import errorCatch from 'utils/errorCatch';
import isBadRequest from 'utils/isBadRequest';
import snackbarMessages from 'utils/snackbarMessages';
import dialogTexts from 'utils/dialogTexts';
import formatPatientData from 'utils/formatPatientData';
import formatFullName from 'utils/formatFullName';
import getAnonymousPatientName from 'utils/getAnonymousPatientName';

import {
  showLoaderMultipleRequests,
  hideLoaderMultipleRequests,
  showTransparentLoader,
  setDrawerInvisibility,
  hideLoader,
  openDialog,
  showSnackbar,
} from 'containers/store';

import messages from './messages';

const formatPatientName = (data) => ((!data.first_name && !data.last_name)
  ? getAnonymousPatientName(data.id) : formatFullName(data, true));

const onRestoreAccept = (id) => async (dispatch) => {
  dispatch(showTransparentLoader());

  try {
    await ApiManager.request('put', dispatch, `patients/${id}/restore`);
    dispatch(showSnackbar(snackbarMessages.patientRestored));
    dispatch(setDrawerInvisibility());
    dispatch(hideLoader());
    dispatch(push('/patients'));
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

const onRestore = () => (dispatch, getStore) => {
  const { patientID } = getStore().PatientFile.values;

  dispatch(openDialog({
    title: dialogTexts.restorePatient,
    onAccept: () => dispatch(onRestoreAccept(patientID)),
  }));
};

const onDeleteAccept = (id) => async (dispatch) => {
  dispatch(showTransparentLoader());

  try {
    await ApiManager.request('delete', dispatch, `patients/${id}`);
    dispatch(showSnackbar(snackbarMessages.patientRemoved));
    dispatch(setDrawerInvisibility());
    dispatch(hideLoader());
    dispatch(push('/patients'));
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

const onDelete = () => (dispatch, getStore) => {
  const { patientID } = getStore().PatientFile.values;

  dispatch(openDialog({
    title: dialogTexts.deletePatient,
    onAccept: () => dispatch(onDeleteAccept(patientID)),
  }));
};

const onEdit = () => (dispatch, getStore) => {
  const id = getStore().PatientFile.values.patientID;
  dispatch(push('/patientAddEdit', { id }));
};

const onDownload = (file, fileName) => () => {
  saveAs(file, fileName);
};

const getMenuOptions = (permissions, hasDeletedAt) => {
  const menuOptions = {
    edit: {
      id: 'edit',
      name: <FormattedMessage {...messages.edit} />,
      requiredPermissions: [permissions.PAGE_PATIENT_EDIT],
      onClick: onEdit,
    },
    download: {
      id: 'download',
      name: <FormattedMessage {...messages.download} />,
      onClick: onDownload,
    },
    remove: {
      id: 'remove',
      name: <FormattedMessage {...messages.remove} />,
      onClick: onDelete,
      requiredPermissions: [
        permissions.PATIENT_REMOVE,
        permissions.PATIENT_REMOVE_OWN_CLINIC,
      ],
      permissionsOperator: 'OR',
    },
    restore: {
      id: 'restore',
      name: <FormattedMessage {...messages.restore} />,
      onClick: onRestore,
      requiredPermissions: [permissions.PATIENT_RESTORE],
    },
  };

  return _values(_pick(
    menuOptions,
    hasDeletedAt ? ['download', 'restore'] : ['edit', 'download', 'remove'],
  ));
};

export const initialState = {
  isLoadedPage: false,
  menuOptions: [],
  fullName: '',
  values: {
    name: '',
    surname: '',
    birthDate: '',
    birthPlace: '',
    pesel: '',
    nationality: '',
    streetAndNumber: '',
    email: '',
    city: '',
    schoolName: '',
    schoolAddress: '',
    department: '',
    diagnostician: '',
    createProfileDate: '',
    patientID: '',
    isBeingRemoved: false,
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'PATIENT_VIEW/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'PATIENT_VIEW/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        values: formatPatientData(action.patientData.data, true, true),
        fullName: formatPatientName(action.patientData.data),
        menuOptions: getMenuOptions(
          action.permissions,
          _get(action.patientData, 'data.deleted_at'),
        ),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (patientData, permissions) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  patientData,
  permissions,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadPatientData = (id) => (dispatch) => ApiManager.request('get', dispatch, `patients/${id}`);

export const onMenuItemChange = (onClick, ...properties) => (dispatch) => {
  dispatch(onClick(...properties));
};

export const loadPageData = (routeState) => async (dispatch, getStore) => {
  const { permissions } = getStore().Global;
  dispatch(clearStore());
  dispatch(showLoaderMultipleRequests());

  try {
    const patientData = await dispatch(loadPatientData(routeState.id));

    dispatch(loadPageSuccess(patientData, permissions));
  } catch (error) {
    if (isBadRequest(error, 404)) {
      dispatch(goBack());
      dispatch(showSnackbar(snackbarMessages.notFoundPatient));
    } else {
      errorCatch(error, dispatch, false, false);
    }
  } finally {
    dispatch(hideLoaderMultipleRequests());
  }
};
