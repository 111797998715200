import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/styles/useTheme';
import Tooltip from '@material-ui/core/Tooltip';
import useStyle from './MenuItemTooltip.styles';

const getLeftMargin = (theme, isScroll, avatarTooltip, isCollapsedMenu) => {
  if (avatarTooltip && isCollapsedMenu) {
    return theme.spacing(3.5);
  }

  if (isScroll) {
    return theme.spacing(2.25);
  }
  return theme.spacing(1);
};

const MenuItemTooltip = ({
  children, title, isScroll, avatarTooltip, isCollapsedMenu,
}) => {
  const theme = useTheme();
  const leftMargin = getLeftMargin(theme, isScroll, avatarTooltip, isCollapsedMenu);
  const classes = useStyle({ leftMargin });

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltipTooltip,
        arrow: classes.tooltipArrow,
      }}
      title={title}
      placement="right"
      arrow
    >
      {children}
    </Tooltip>
  );
};

MenuItemTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  avatarTooltip: PropTypes.bool,
  isCollapsedMenu: PropTypes.bool,
  isScroll: PropTypes.bool,
};

MenuItemTooltip.defaultProps = {
  isScroll: false,
  avatarTooltip: false,
  isCollapsedMenu: false,
};

export default MenuItemTooltip;
