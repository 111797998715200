import config from 'config';

const getMessages = (intl, messages) => ({
  title: intl.formatMessage(messages.title),
  netCost: intl.formatMessage(messages.netCost),
  numberOfTablets: intl.formatMessage(messages.numberOfTablets),
  billingCycle: intl.formatMessage(messages.billingCycle),
  transitionalPeriod: intl.formatMessage(messages.transitionalPeriod),
  startDate: intl.formatMessage(messages.startDate),
  endDate: intl.formatMessage(messages.endDate),
  firstDayOfBillingCycle: intl.formatMessage(messages.firstDayOfBillingCycle),
  save: intl.formatMessage(messages.save),
  cancel: intl.formatMessage(messages.cancel),
});

const getValidators = () => {
  const validators = {
    notEmpty: {
      validatorName: 'notEmpty',
    },
    noDecimalPlaces: {
      validatorName: 'maxDecimalPlaces',
      parameters: [0],
    },
    nonNegativeNumber: {
      validatorName: 'higherOrEqualTo',
      parameters: [0],
    },
    integer: {
      validatorName: 'higherOrEqualTo',
      parameters: [1],
    },
    isCorrectDate: {
      validatorName: 'isCorrectDate',
    },
    notEarlierThanToday: {
      validatorName: 'notEarlierThanToday',
    },
    validInteger: {
      validatorName: 'lowerOrEqualTo',
      parameters: [config.maxValue.smallInteger],
    },
    correctStartDate: {
      validatorName: 'maximumDayInTheMonth',
      parameters: [config.maxSubscriptionStartDay],
    },
  };

  return validators;
};

const disabledIfFieldValue = (fieldValue, value) => {
  if (fieldValue === value) {
    return true;
  }

  return false;
};

export {
  getMessages,
  getValidators,
  disabledIfFieldValue,
};
