import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import PageLayout from 'components/PageLayout';
import ProtectedByPermissions from 'components/ProtectedByPermissions';
import config from 'config';

const AuthorizedRoute = ({
  component: Component,
  requiredPermission,
  permissionsOperator,
  ...rest
}) => {
  const diagmaticCurrentUserId = localStorage.getItem(config.localStorageLoginKey);

  return diagmaticCurrentUserId ? (
    <Route
      {...rest}
      render={(props) => (
        <PageLayout>
          <ProtectedByPermissions
            requiredPermission={requiredPermission}
            permissionsOperator={permissionsOperator}
            withNoPermissionsPage
          >
            <Component {...props} />
          </ProtectedByPermissions>
        </PageLayout>
      )}
    />
  ) : (
    <Redirect to="/login" />
  );
};

AuthorizedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  permissionsOperator: PropTypes.oneOf(['AND', 'OR']),
  requiredPermission: PropTypes.arrayOf(PropTypes.bool),
};

AuthorizedRoute.defaultProps = {
  requiredPermission: [],
  permissionsOperator: 'AND',
};

const mapStateToProps = (state) => ({
  apiVersion: state.Global.apiVersion,
});

export default connect(mapStateToProps)(AuthorizedRoute);
