import removeHtmlTags from 'utils/removeHtmlTags';
import getDateFromApi from 'utils/getDateFromApi';
import PromiseAll from 'utils/PromiseAll';
import ApiManager from 'utils/ApiManager';
import errorCatch from 'utils/errorCatch';
import createURLWithQuery from 'utils/createURLWithQuery';
import downloadFile from 'utils/downloadFile';

import {
  hideLoaderMultipleRequests,
  showLoaderMultipleRequests,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  downloadLoading: false,
  values: {
    id: '',
    title: '',
    toolDescription: '',
    substantiveDescription: '',
    manual: '',
    version: '',
    file: '',
    createdDate: '',
  },
};

const mapDescriptionInfo = (tool, manual) => {
  const currentManual = manual.length - 1;
  let manualInfo = {};

  if (currentManual >= 0) {
    const manualData = manual[currentManual];

    manualInfo = {
      id: manualData.id || '',
      manual: manualData.name || '',
      version: manualData.version || '',
      file: `${manualData.name}-${manualData.version}.pdf`,
      createdDate: getDateFromApi(manual.created_at),
    };
  }

  return {
    ...manualInfo,
    title: tool.name,
    toolDescription: removeHtmlTags(tool.description),
    substantiveDescription: tool.substantive_description,
  };
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'TOOLS_DESCRIPTION/LOAD_PAGE_SUCCESS',
  SET_REPLACED_USER: 'TOOLS_DESCRIPTION/SET_REPLACED_USER',
  CLEAR_STORE: 'TOOLS_DESCRIPTION/CLEAR_STORE',
  SET_LOADING_VALUE: 'TOOLS_DESCRIPTION/SET_LOADING_VALUE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        values: mapDescriptionInfo(
          action.responses.surveyToolInfo.data,
          action.responses.manualInfo.data,
        ),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_LOADING_VALUE: {
      return {
        ...state,
        downloadLoading: action.value,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setLoadingValue = (value) => ({
  type: actionTypes.SET_LOADING_VALUE,
  value,
});

export const downloadManual = (id) => async (dispatch) => {
  dispatch(setLoadingValue(true));

  try {
    const manualData = await ApiManager.request(
      'get', dispatch, `survey_tool_manuals/${id}/download`, { responseType: 'blob' }, true,
    );
    downloadFile(manualData, 'pdf');
  } catch (error) {
    errorCatch(error, dispatch);
  }
  dispatch(setLoadingValue(false));
};

const loadSurveyTool = (id) => (dispatch) => ApiManager.request('get', dispatch, `survey_tools/${id}`);

const loadManualInfo = (id) => (dispatch) => {
  const params = {
    perPage: 1,
    page: 1,
    survey_tool_id: id,
  };

  const url = createURLWithQuery('survey_tool_manuals', params);

  return ApiManager.request('get', dispatch, url);
};

export const loadPageData = (routeState) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoaderMultipleRequests());

  try {
    const responses = await PromiseAll({
      surveyToolInfo: dispatch(loadSurveyTool(routeState.id)),
      manualInfo: dispatch(loadManualInfo(routeState.id)),
    });
    dispatch(loadPageSuccess(responses));
  } catch (error) {
    errorCatch(error, dispatch, false, false);
  } finally {
    dispatch(hideLoaderMultipleRequests());
  }
};
