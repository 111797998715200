export default (theme) => ({
  filtersWrapper: {
    display: 'none',
    paddingBottom: theme.spacing(2),
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    height: 48,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: 24,
    height: 48,
  },
  searchWrapper: {
    maxWidth: 420,
    width: '100%',
  },
  tableCell: {
    ...theme.tableCell,
  },
  nameCell: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1.6),
  },
  dateCell: {
    width: '15%',
  },
  statusCell: {
    width: '13%',
  },
  tooltip: {
    ...theme.tooltip,
    lineHeight: '16px',
    marginBottom: theme.spacing(1, 0),
    borderRadius: 4,
    top: 6,
  },
  icon: {
    marginBottom: 10,
    boxSizing: 'initial',
  },
  inactiveIcon: {
    height: 17,
    width: 17,
    marginLeft: 17,
    marginBottom: 3,
  },
  divider: {
    margin: theme.spacing(1, 0),
    background: theme.palette.white,
  },
  tooltipText: {
    fontSize: 12,
  },
  groupIcon: {
    color: theme.palette.icon.groupSurvey,
    fontSize: 20,
    marginBottom: 10,
  },
});
