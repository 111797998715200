import { goBack } from 'connected-react-router';
import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _isString from 'lodash/isString';
import _get from 'lodash/get';
import _without from 'lodash/without';
import _replace from 'lodash/replace';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import createURLWithQuery from 'utils/createURLWithQuery';
import apiFieldMappings from 'utils/apiFieldMappings';
import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader, setDrawerVisibility,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    battery: '',
    code: '',
    description: '',
    manualVersion: '',
    manualPreviewVersion: '',
    starterPackPrice: '',
    extraCreditPoints: '',
  },
  manual: [],
  manualPreview: [],
  hasNewManual: false,
  hasNewManualPreview: false,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'BATTERIES_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'BATTERIES_ADD_EDIT/CLEAR_STORE',
  SET_UPLOADED_FILE: 'BATTERIES_ADD_EDIT/SET_UPLOADED_FILE',
  SET_UPLOADED_FILE_MANUAL_PREVIEW: 'BATTERIES_ADD_EDIT/SET_UPLOADED_FILE_MANUAL_PREVIEW',
};

const mapDataToValues = (data, manualData) => ({
  apiId: data.id,
  battery: data.name || '',
  code: data.code || '',
  description: data.description || '',
  extraCreditPoints: _isNull(data.extra_credit_points) ? '' : data.extra_credit_points,
  starterPackPrice: _isNull(data.starter_pack_price) ? '' : data.starter_pack_price,
  manualVersion: _isEmpty(manualData) ? '' : _last(manualData).version,
  manualPreviewVersion: _isEmpty(manualData) ? '' : _last(manualData).preview_version,
});

const getManualPreview = (manual) => {
  if (manual && _isString(manual.preview)) {
    return [manual.preview];
  }

  return [];
};

const getLastVersion = (manual) => {
  const data = _get(manual, 'data');

  return _last(data);
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        batteryId: action.batteryId,
        initialValues: action.battery
          ? mapDataToValues(action.battery.data, action.manual.data)
          : initialState.initialValues,
        manual: _without([getLastVersion(action.manual)], undefined),
        manualPreview: getManualPreview(getLastVersion(action.manual)),
      };
    }

    case actionTypes.SET_UPLOADED_FILE: {
      return {
        ...state,
        manual: !_isEmpty(action.file) ? action.file : [],
        hasNewManual: !_isEmpty(action.file),
      };
    }

    case actionTypes.SET_UPLOADED_FILE_MANUAL_PREVIEW: {
      return {
        ...state,
        manualPreview: !_isEmpty(action.file) ? action.file : [],
        hasNewManualPreview: !_isEmpty(action.file),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (batteryId, battery, manual) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  batteryId,
  battery,
  manual,
});

const setUploadedFile = (file) => ({
  type: actionTypes.SET_UPLOADED_FILE,
  file,
});

const setUploadedFileManualPreview = (file) => ({
  type: actionTypes.SET_UPLOADED_FILE_MANUAL_PREVIEW,
  file,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadBatteryData = (id) => (dispatch) => ApiManager.request('get', dispatch, `batteries/${id}`);

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const store = getStore().BatteriesAddEdit;
  const batteryId = store.batteryId;

  const formData = new FormData();

  let url = 'batteries';
  let method = 'post';
  let message = snackbarMessages.batteryAddedSuccessfully;

  if (batteryId) {
    url = `batteries/${batteryId}`;
    method = 'put';
    message = snackbarMessages.batteryEditedSuccessfully;
  }

  const data = {
    id: batteryId,
    name: values.battery,
    code: values.code,
    description: values.description,
    starter_pack_price: _replace(values.starterPackPrice, ',', '.'),
    extra_credit_points: values.extraCreditPoints,
  };

  dispatch(showTransparentLoader());

  ApiManager.request(method, dispatch, url, data).then((battery) => {
    if (store.hasNewManual || store.hasNewManualPreview) {
      formData.append('battery_id', battery.data.id);

      if (store.hasNewManual) {
        formData.append('version', values.manualVersion);
        formData.append('name', store.manual[0].name);
        formData.append('file', store.manual[0]);
      }

      if (store.hasNewManualPreview) {
        formData.append('preview', store.manualPreview[0]);
        formData.append('preview_version', values.manualPreviewVersion);
        formData.append('preview_name', store.manualPreview[0].name);
      }

      ApiManager.request('post', dispatch, 'battery_manuals', formData).then(() => {
        dispatch(showSnackbar(message));
        dispatch(hideLoader());
        dispatch(goBack());
      }).catch((error) => {
        if (isBadRequest(error, 500)) {
          dispatch(showSnackbar(snackbarMessages.globalError));
        } else if (!_isEmpty(error.error.errors.file) || !_isEmpty(error.error.errors.preview)) {
          dispatch(showSnackbar(snackbarMessages.manualTooLargeSize));
        } else if (!_isEmpty(error.error.errors.version)) {
          dispatch(showSnackbar(snackbarMessages.manualBadVersion));
        } else if (!_isEmpty(error.error.errors.preview_version)) {
          dispatch(showSnackbar(snackbarMessages.manualShortcutBadVersion));
        }
        dispatch(hideLoader());
      });
    } else {
      dispatch(showSnackbar(message));
      dispatch(hideLoader());
      dispatch(goBack());
    }
  }).catch((error) => {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.batteriesAddEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(hideLoader());
  });
};

const getManual = (id) => (dispatch) => {
  const isEdit = !_isNull(id) && !_isUndefined(id);

  const params = {
    perPage: 1,
    page: 1,
    battery_id: id,
  };

  const url = createURLWithQuery('battery_manuals', params);

  return isEdit ? ApiManager.request('get', dispatch, url) : Promise.resolve();
};

export const onFileUpload = (acceptedFile, rejectedFiles, type) => (dispatch) => {
  if (!_isEmpty(rejectedFiles)) {
    return dispatch(showSnackbar(snackbarMessages.manualTooLargeSize));
  }

  if (type === 'manualPreview') {
    return dispatch(setUploadedFileManualPreview(acceptedFile));
  }
  return dispatch(setUploadedFile(acceptedFile));
};

export const onFileRemove = (type) => (dispatch) => {
  if (type === 'manualPreview') {
    dispatch(setUploadedFileManualPreview());
  } else {
    dispatch(setUploadedFile());
  }
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

const loadBattery = (id) => (dispatch) => {
  if (!_isNull(id) && !_isUndefined(id)) {
    return dispatch(loadBatteryData(id));
  }
  return Promise.resolve();
};

export const loadPageData = (routeState, message) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));
  try {
    const battery = await dispatch(loadBattery(routeState.id));
    const manual = await dispatch(getManual(routeState.id));
    dispatch(loadPageSuccess(routeState.id, battery, manual));
  } catch (error) {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
  } finally {
    dispatch(hideLoader());
  }
};
