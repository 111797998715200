const permissions = {
  PAGE_USERS_LIST: 'PAGE_USERS_LIST',
  PAGE_USERS_ADD: 'PAGE_USERS_ADD',
  PAGE_USERS_EDIT: 'PAGE_USERS_EDIT',
  PAGE_USERS_VIEW: 'PAGE_USERS_VIEW',
  USERS_DELETE: 'USERS_DELETE',
  USERS_DELETE_DIAGNOSTICIANS: 'USERS_DELETE_DIAGNOSTICIANS',
  USERS_DELETE_CLINIC_COORDINATORS: 'USERS_DELETE_CLINIC_COORDINATORS',
  USERS_DELETE_SALES_COORDINATORS: 'USERS_DELETE_SALES_COORDINATORS',
  USERS_DELETE_ACCOUNT_MANAGER: 'USERS_DELETE_ACCOUNT_MANAGER',
  USERS_DELETE_ADMIN: 'USERS_DELETE_ADMIN',
  USERS_DELETE_POLLSTER: 'USERS_DELETE_POLLSTER',
  USERS_DELETE_PRODUCT_OWNER: 'USERS_DELETE_PRODUCT_OWNER',
  PAGE_PATIENT_LIST: 'PAGE_PATIENT_LIST',
  PAGE_PATIENT_ADD: 'PAGE_PATIENT_ADD',
  PAGE_PATIENT_EDIT: 'PAGE_PATIENT_EDIT',
  PAGE_PATIENT_VIEW: 'PAGE_PATIENT_VIEW',
  PAGE_ORGANIZATIONS_LIST: 'PAGE_ORGANIZATIONS_LIST',
  PAGE_ORGANIZATIONS_ADD: 'PAGE_ORGANIZATIONS_ADD',
  PAGE_ORGANIZATIONS_EDIT: 'PAGE_ORGANIZATIONS_EDIT',
  ORGANIZATIONS_DELETE: 'ORGANIZATIONS_DELETE',
  PAGE_QUESTIONS_LIST: 'PAGE_QUESTIONS_LIST',
  PAGE_QUESTIONS_ADD: 'PAGE_QUESTIONS_ADD',
  PAGE_QUESTIONS_EDIT: 'PAGE_QUESTIONS_EDIT',
  QUESTIONS_DELETE: 'QUESTIONS_DELETE',
  PAGE_ANSWERS_LIST: 'PAGE_ANSWERS_LIST',
  PAGE_ANSWERS_ADD: 'PAGE_ANSWERS_ADD',
  PAGE_ANSWERS_EDIT: 'PAGE_ANSWERS_EDIT',
  ANSWERS_SET_DELETE: 'ANSWERS_SET_DELETE',
  PAGE_BATTERIES_LIST: 'PAGE_BATTERIES_LIST',
  PAGE_BATTERIES_ADD: 'PAGE_BATTERIES_ADD',
  PAGE_BATTERIES_EDIT: 'PAGE_BATTERIES_EDIT',
  BATTERIES_DELETE: 'BATTERIES_DELETE',
  PAGE_RESEARCH_TOOLS_LIST: 'PAGE_RESEARCH_TOOLS_LIST',
  PAGE_RESEARCH_TOOLS_ADD: 'PAGE_RESEARCH_TOOLS_ADD',
  PAGE_RESEARCH_TOOLS_EDIT: 'PAGE_RESEARCH_TOOLS_EDIT',
  PAGE_RESEARCH_TOOLS_VIEW: 'PAGE_RESEARCH_TOOLS_VIEW',
  SURVEY_TOOLS_DELETE: 'SURVEY_TOOLS_DELETE',
  PAGE_RESEARCH_TOOLS_SCALE_EDIT: 'PAGE_RESEARCH_TOOLS_SCALE_EDIT',
  PAGE_STANDARD_SCALES_LIST: 'PAGE_STANDARD_SCALES_LIST',
  PAGE_STANDARD_SCALES_ADD: 'PAGE_STANDARD_SCALES_ADD',
  PAGE_STANDARD_SCALES_EDIT: 'PAGE_STANDARD_SCALES_EDIT',
  STANDARD_CATEGORICAL_SCALE_DELETE: 'STANDARD_CATEGORICAL_SCALE_DELETE',
  PAGE_REFERENCE_GROUPS_LIST: 'PAGE_REFERENCE_GROUPS_LIST',
  PAGE_REFERENCE_GROUPS_ADD: 'PAGE_REFERENCE_GROUPS_ADD',
  PAGE_REFERENCE_GROUPS_EDIT: 'PAGE_REFERENCE_GROUPS_EDIT',
  REFERENCE_GROUP_SETS_DELETE: 'REFERENCE_GROUP_SETS_DELETE',
  PAGE_SURVEYS_LIST: 'PAGE_SURVEYS_LIST',
  PAGE_SURVEYS_ADD: 'PAGE_SURVEYS_ADD',
  PAGE_SURVEYS_EDIT: 'PAGE_SURVEYS_EDIT',
  PAGE_SURVEYS_VIEW: 'PAGE_SURVEYS_VIEW',
  SURVEYS_DELETE: 'SURVEYS_DELETE',
  PAGE_SURVEYS_RESULTS_LIST: 'PAGE_SURVEYS_RESULTS_LIST',
  PAGE_SURVEYS_RESULTS_VIEW: 'PAGE_SURVEYS_RESULTS_VIEW',
  PAGE_SURVEYS_RESULTS_PARTICIPANT_EDIT: 'PAGE_SURVEYS_RESULTS_PARTICIPANT_EDIT',
  PAGE_GROUP_SURVEYS_RESULTS_LIST: 'PAGE_GROUP_SURVEYS_RESULTS_LIST',
  PAGE_TOOLS_LIST: 'PAGE_TOOLS_LIST',
  REPLACEMENT_GET: 'REPLACEMENT_GET',
  REPLACEMENTS_GET_OWN: 'REPLACEMENTS_GET_OWN',
  REPLACEMENTS_ADD_OWN: 'REPLACEMENTS_ADD_OWN',
  REPLACEMENTS_ADD: 'REPLACEMENTS_ADD',
  REPLACEMENTS_REMOVE: 'REPLACEMENTS_REMOVE',
  REPLACEMENTS_REMOVE_OWN: 'REPLACEMENTS_REMOVE_OWN',
  PATIENT_REMOVE: 'PATIENT_REMOVE',
  PATIENT_RESTORE: 'PATIENT_RESTORE',
  PAGE_CLINICS_LIST: 'PAGE_CLINICS_LIST',
  PAGE_CLINICS_ADD: 'PAGE_CLINICS_ADD',
  PAGE_CLINICS_EDIT: 'PAGE_CLINICS_EDIT',
  PAGE_CLINICS_VIEW: 'PAGE_CLINICS_VIEW',
  PAGE_CLINIC_OWN_EDIT: 'PAGE_CLINIC_OWN_EDIT',
  CLINICS_UPDATE_NAME: 'CLINICS_UPDATE_NAME',
  PAGE_OWN_CLINIC_VIEW: 'PAGE_OWN_CLINIC_VIEW',
  PAGE_DIAGNOSTICIAN_LIST: 'PAGE_DIAGNOSTICIAN_LIST',
  PAGE_DIAGNOSTICIAN_ADD: 'PAGE_DIAGNOSTICIAN_ADD',
  PAGE_DIAGNOSTICIAN_EDIT: 'PAGE_DIAGNOSTICIAN_EDIT',
  PAGE_DIAGNOSTICIAN_VIEW: 'PAGE_DIAGNOSTICIAN_VIEW',
  MESSAGE_SEND: 'MESSAGE_SEND',
  PAGE_VIDEO_TUTORIAL_LIST: 'PAGE_VIDEO_TUTORIAL_LIST',
  PAGE_VIDEO_TUTORIAL_ADD: 'PAGE_VIDEO_TUTORIAL_ADD',
  PAGE_VIDEO_TUTORIAL_EDIT: 'PAGE_VIDEO_TUTORIAL_EDIT',
  PAGE_VIDEO_TUTORIAL_VIEW: 'PAGE_VIDEO_TUTORIAL_VIEW',
  PAGE_STATISTICS: 'PAGE_STATISTICS',
  PATIENT_REMOVE_OWN_CLINIC: 'PATIENT_REMOVE_OWN_CLINIC',
  EXAMINATION_CREATE: 'EXAMINATION_CREATE',
  EXAMINATION_CREATE_OWN_CLINIC: 'EXAMINATION_CREATE_OWN_CLINIC',
  ORDER_GET_OWN_CLINIC: 'ORDER_GET_OWN_CLINIC',
  PAGE_MENU_BOTTOM_CONTACT: 'PAGE_MENU_BOTTOM_CONTACT',
  PAGE_MENU_BOTTOM_VIDEO_TUTORIAL: 'PAGE_MENU_BOTTOM_VIDEO_TUTORIAL',
  PAGE_DIAGNOSTICIAN_CLINIC_LIST: 'PAGE_DIAGNOSTICIAN_CLINIC_LIST',
  USERS_CREATE_CLINIC_COORDINATORS: 'USERS_CREATE_CLINIC_COORDINATORS',
  USERS_SET_CLINIC_COORDINATORS: 'USERS_SET_CLINIC_COORDINATORS',
  CLINICS_UPDATE_BATTERIES: 'CLINICS_UPDATE_BATTERIES',
  SUBSCRIPTIONS_GET: 'SUBSCRIPTIONS_GET',
  SUBSCRIPTIONS_UPDATE: 'SUBSCRIPTIONS_UPDATE',
  SUBSCRIPTIONS_GET_OWN_CLINICS: 'SUBSCRIPTIONS_GET_OWN_CLINICS',
  PATIENT_DOCUMENT_GET: 'PATIENT_DOCUMENT_GET',
  CLINICS_UPDATE_SETTINGS: 'CLINICS_UPDATE_SETTINGS',
  PACKAGE_UPDATE: 'PACKAGE_UPDATE',
  ORDER_UPDATE: 'ORDER_UPDATE',
  ORDER_GET: 'ORDER_GET',
  ORDER_UPDATE_OWN_CLINIC: 'ORDER_UPDATE_OWN_CLINIC',
  CLINICS_EDIT_IS_IN_TRY_AND_BUY_MODE_ATTRIBUTE: 'CLINICS_EDIT_IS_IN_TRY_AND_BUY_MODE_ATTRIBUTE',
  CLINICS_UPDATE: 'CLINICS_UPDATE',
  PAGE_STATISTICS_ALL_CLINICS: 'PAGE_STATISTICS_ALL_CLINICS',
  CLINICS_UPDATE_ANONYMOUS_PATIENTS_CAN_BE_ASSIGNED_ATTRIBUTE: 'CLINICS_UPDATE_ANONYMOUS_PATIENTS_CAN_BE_ASSIGNED_ATTRIBUTE',
  PAGE_MENU_BOTTOM_PRIVACY_POLICY: 'PAGE_MENU_BOTTOM_PRIVACY_POLICY',
  PAGE_MENU_BOTTOM_TERMS_OF_USE: 'PAGE_MENU_BOTTOM_TERMS_OF_USE',
  CLINICS_GET_OWN: 'CLINICS_GET_OWN',
  EXAMINATION_GET_OWN_CLINIC: 'EXAMINATION_GET_OWN_CLINIC',
  USERS_UPDATE_SELF: 'USERS_UPDATE_SELF',
  ORDER_CREATE_OWN_CLINIC: 'ORDER_CREATE_OWN_CLINIC',
  CLINICS_REMOVE: 'CLINICS_REMOVE',
};

export default permissions;
