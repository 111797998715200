import _every from 'lodash/every';
import _values from 'lodash/values';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _isBoolean from 'lodash/isBoolean';

export default (filterValues) => _every(
  _values(filterValues),
  (value) => (_isEmpty(value) && !(_isNumber(value) || _isBoolean(value))),
);
