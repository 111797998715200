import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => (
  {
    wrapper: {
      textAlign: 'justify',
      fontFamily: 'Red Hat Text',
      lineHeight: 1.2,
      padding: theme.spacing(4),
      fontSize: 16,
      '& a': {
        textDecoration: 'underline',
        color: '-webkit-link',
      },
      '& b': {
        fontWeight: 500,
      },
      '& th, & td': {
        border: `1px solid ${theme.palette.text.primary}`,
      },
      '& table': {
        width: '100%',
      },
      '& h6': {
        fontWeight: 500,
      },
      '& .nestedList': {
        counterReset: 'item',
        listStyle: 'none',
        marginInlineStart: 32,
        paddingInlineStart: 0,
        '& > li:before': {
          fontWeight: 500,
          content: 'counters(item, ".") ". "',
          counterIncrement: 'item',
        },
      },
      '& ul': {
        marginInlineStart: 16,
        listStyleType: 'initial',
      },
      '& ol': {
        marginInlineStart: 12,
      },
      '& .alphabetList': {
        marginInlineStart: 0,
        listStyle: 'lower-alpha',
        '& > li:before': {
          content: 'initial',
        },
      },
      '& .romanNumbersList': {
        marginInlineStart: 16,
        listStyle: 'lower-roman',
        '& > li:before': {
          content: 'initial',
        },
      },
      '& .paragraphList': {
        counterReset: 'item',
        listStyle: 'none',
        marginInlineStart: 0,
        paddingInlineStart: 0,
        textAlign: 'center',

        '& > li > ol': {
          textAlign: 'justify',
          marginInlineStart: 32,
          paddingInlineStart: 0,
        },

        '& > li:before': {
          fontWeight: 500,
          content: '"§"counters(item, ".")" "',
          counterIncrement: 'item',
        },
      },
      '& > .browserConfigInstructions': {
        textAlign: 'left',
      },
    },
  }
));
