import makeStyles from '@material-ui/styles/makeStyles';

const getMarginRight = (fullWidth, isCollapsedMenu, theme) => {
  const drawerWidth = isCollapsedMenu
    ? theme.sizes.drawer.collapsedWidth
    : theme.sizes.drawer.width;

  return `calc(100% + ${theme.spacing(4)}px + ${fullWidth ? 0 : drawerWidth}px - 100vw)`;
};

export default makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    minWidth: ({ fullWidth }) => (fullWidth
      ? theme.sizes.versionPopup.minWidthForFullWidthPages
      : theme.sizes.versionPopup.minWidthForPagesWithMenu),
    height: theme.sizes.versionPopup.height,
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.white,
    backgroundColor: theme.palette.orange,
    paddingLeft: ({ fullWidth }) => theme.spacing(fullWidth ? 3 : 2.25),

    '@media (min-width: 1090px) and (max-width: 1096px)': {
      paddingLeft: ({ fullWidth }) => theme.spacing(fullWidth ? 3 : 2.5),
    },
  },
  container: {
    width: theme.sizes.cardContent.width - theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: ({ fullWidth, isCollapsedMenu }) => getMarginRight(
      fullWidth,
      isCollapsedMenu,
      theme,
    ),

    '@media (max-width: 1248px)': {
      marginRight: ({ fullWidth, isCollapsedMenu }) => (fullWidth || isCollapsedMenu
        ? getMarginRight(fullWidth, isCollapsedMenu, theme)
        : 0),
    },
    '@media (max-width: 1089px)': {
      marginRight: ({ fullWidth, isCollapsedMenu }) => (fullWidth
        ? getMarginRight(fullWidth, isCollapsedMenu, theme)
        : 0),
    },
    '@media (max-width: 999px)': {
      marginRight: '0 !important',
    },
  },
  content: {
    paddingLeft: theme.spacing(1),
  },
  titleWrapper: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: 500,
  },
  closeButton: {
    border: `1px solid ${theme.palette.white}`,
    color: theme.palette.white,
    backgroundColor: 'transparent',
  },
  link: {
    color: theme.palette.white,
    fontWeight: 500,
    textDecoration: 'underline',
  },
  description: {
    lineHeight: '24px',
  },
}));
