import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  CHANGE_PASSWORD,
  VIDEO_PLAYER,
  PRIVATE_MESSAGE,
  PURCHASE_PACKAGE_APPLICATION,
  FILE_ADD_EDIT,
  FACILITY_BATTERIES_EDIT,
  LACK_OF_FUND,
  TOP_UP_ACCOUNT,
  FACILITY_SETTINGS_EDIT,
  VAT_INVOICE_ADD_EDIT,
  POLICY,
} from 'utils/constants/modalTypes';

import ChangePassword from 'containers/UserPasswordChange';
import VideoPlayer from 'containers/VideoPlayer';
import PrivateMessage from 'containers/PrivateMessage';
import PurchasePackageApplication from 'containers/PurchasePackageApplication';
import FileAddEdit from 'containers/FileAddEdit';
import FacilityBatteriesEdit from 'containers/FacilityBatteriesEdit';
import LackOfFund from 'containers/LackOfFund';
import TopUpAccount from 'containers/TopUpAccount';
import FacilitySettingsEdit from 'containers/FacilitySettingsEdit';
import VatInvoiceAddEdit from 'containers/VatInvoiceAddEdit';
import Policy from 'containers/Policy';

const MODAL_COMPONENTS = {
  [CHANGE_PASSWORD]: ChangePassword,
  [PRIVATE_MESSAGE]: PrivateMessage,
  [VIDEO_PLAYER]: VideoPlayer,
  [FILE_ADD_EDIT]: FileAddEdit,
  [PURCHASE_PACKAGE_APPLICATION]: PurchasePackageApplication,
  [FACILITY_BATTERIES_EDIT]: FacilityBatteriesEdit,
  [LACK_OF_FUND]: LackOfFund,
  [TOP_UP_ACCOUNT]: TopUpAccount,
  [FACILITY_SETTINGS_EDIT]: FacilitySettingsEdit,
  [VAT_INVOICE_ADD_EDIT]: VatInvoiceAddEdit,
  [POLICY]: Policy,
};

const ModalRoot = ({ type, props }) => {
  if (!type) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[type];
  const allProps = { type, modalProps: props };

  return <SpecificModal {...allProps} />;
};

ModalRoot.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

ModalRoot.defaultProps = {
  type: '',
  props: {},
};

const mapStateToProps = (state) => ({ ...state.Global.modal });

export default connect(mapStateToProps)(ModalRoot);
