export default [
  '/surveys',
  '/surveyCreator',
  '/questions',
  '/organizations',
  '/researchTools',
  '/users',
  '/facilityList',
  '/videoTutorials',
  '/batteries',
  '/referenceGroups',
  '/standardScales',
  '/answers',
  '/surveysResults',
];
