import { goBack } from 'connected-react-router';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import config from 'config';

import _get from 'lodash/get';

import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader, setDrawerVisibility,
} from 'containers/store';

const KNOWLEDGE_QUESTION_ID = config.questionTypeIds.knowledgeQuestion;

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    content: '',
    contentFemale: '',
    set: '',
    type: '',
    code: '',
    scaleRange: '',
    scaleType: '',
    answers: [],
    weights: [],
    group: '',
    questionPicture: null,
    questionImageObject: {},
  },
  questionId: null,
  types: [],
  groups: [],
  sets: [],
};

const mapDataToValues = (data) => {
  let result = {
    content: data.questionData.data.label_male,
    contentFemale: data.questionData.data.label_female || '',
    type: data.questionData.data.question_type_id,
    group: data.questionData.data.question_group_id,
    set: data.questionData.data.answer_set_id,
    code: data.questionData.data.code || '',
    questionPicture: data.questionData.data.image || null,
    questionImageObject: {},
  };

  if (!_isEmpty(data.answerData)) {
    const answers = [];
    const weights = [];

    _forEach(data.answerData.data.answers, (el) => {
      answers.push(el.label);
      weights.push(String(el.weight) || '');
    });

    result = {
      ...result,
      scaleType: data.answerData.data.answer_set_type_id || '',
      scaleRange: data.answerData.data.answers_count || '',
      name: data.answerData.data.name || '',
      answers,
      weights,
    };
  }

  return result;
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'QUESTION_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'QUESTION_ADD_EDIT/CLEAR_STORE',
  TOGGLE_ALERT_DIALOG: 'QUESTION_ADD_EDIT/TOGGLE_ALERT_DIALOG',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        questionId: action.questionId,
        initialValues: action.responses.questionData
          ? mapDataToValues(action.responses.questionData)
          : initialState.initialValues,
        types: action.responses.questionsTypes.data.items,
        groups: action.responses.questionsGroups.data.items,
        sets: action.responses.answerSets.data.items,
        scaleTypes: action.responses.answerSetTypes.data,
      };
    }

    case actionTypes.TOGGLE_ALERT_DIALOG: {
      return {
        ...state,
        openedAlertDialog: action.status,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (questionId, responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  questionId,
  responses,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const toggleAlertDialog = (status) => ({
  type: actionTypes.TOGGLE_ALERT_DIALOG,
  status,
});

export const closeAlertDialog = () => (dispatch) => {
  dispatch(toggleAlertDialog(false));
  dispatch(hideLoader());
};

const loadQuestionsTypes = () => (dispatch) => {
  const url = 'question_types';

  return ApiManager.request('get', dispatch, url);
};

const loadQuestionsGroups = () => (dispatch) => {
  const url = 'question_groups';

  return ApiManager.request('get', dispatch, url);
};

const loadAnswerSets = () => (dispatch) => {
  const url = 'answer_sets';

  return ApiManager.request('get', dispatch, url);
};

const loadAnswerSetTypes = () => (dispatch) => {
  const url = 'answer_set_types';

  return ApiManager.request('get', dispatch, url);
};

const loadAnswerData = (id) => (dispatch) => ApiManager.request('get', dispatch, `answer_sets/${id}`);

const loadQuestionData = (id) => (dispatch) => ApiManager.request('get', dispatch, `questions/${id}`);

const questionRequest = (method, url, data, message, setFieldError) => (dispatch) => (
  ApiManager.request(method, dispatch, url, data).then(() => {
    dispatch(showSnackbar(message));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.questionAddEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  })
);

const answerSetRequest = (
  method,
  url,
  data,
  message,
  setFieldError,
  answerSetData,
) => (dispatch) => {
  const answerMethod = 'post';
  const answerUrl = 'answer_sets';

  return (
    ApiManager.request(answerMethod, dispatch, answerUrl, answerSetData).then((response) => {
      data.append('answer_set_id', response.data.id);
      return (
        dispatch(questionRequest(method, url, data, message, setFieldError))
      );
    }).catch((error) => {
      if (isBadRequest(error)) {
        setFormErrors(error.error.errors, setFieldError, apiFieldMappings.answersAddEdit);
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }
      dispatch(hideLoader());
    })
  );
};

const saveQuestion = (
  method,
  url,
  data,
  message,
  setFieldError,
  isKnowledge,
  answerSetData,
) => (dispatch) => {
  if (isKnowledge) {
    return dispatch(answerSetRequest(
      method,
      url,
      data,
      message,
      setFieldError,
      answerSetData,
    ));
  }
  return dispatch(questionRequest(method, url, data, message, setFieldError));
};

export const onSubmit = (
  values,
  { setFieldError },
  forceSubmit = false,
  isKnowledge = false,
) => (dispatch, getStore) => {
  const store = getStore().QuestionAddEdit;
  const questionId = store.questionId;
  let url = 'questions';
  let method = 'post';
  let message = snackbarMessages.questionAddedSuccessfully;
  let answerSetData = {};

  const formData = new FormData();

  if (questionId) {
    url = `questions/${questionId}`;
    method = 'post';
    message = snackbarMessages.questionEditedSuccessfully;

    // Laravel workaround for put requests when formData contains file
    // https://stackoverflow.com/questions/50691938/patch-and-put-request-does-not-working-with-form-data
    formData.append('_method', 'PUT');
  }

  formData.append('name', values.content);
  formData.append('label_male', values.content);
  formData.append('label_female', values.contentFemale);
  formData.append('answer_set_id', values.set);
  formData.append('question_type_id', values.type);
  formData.append('question_group_id', values.group);
  formData.append('code', values.code);

  const isQuestionImageObject = _get(values.questionImageObject, 'files', false);

  if (isQuestionImageObject && !questionId) {
    formData.append('image', values.questionImageObject.files[0]);
  } else if (isQuestionImageObject && questionId) {
    formData.append('image', values.questionImageObject.files[0]);
  } else if (!isQuestionImageObject && !values.questionPicture && questionId) {
    formData.append('remove_image', true);
  }

  if (isKnowledge) {
    answerSetData = {
      answer_set_type_id: values.scaleType,
      name: values.content,
      answers_count: parseInt(values.scaleRange),
      restriction: true,
      answers_save: values.answers.map((el, key) => ({
        label: el,
        weight: values.weights[key],
      })),
    };
  }

  dispatch(showTransparentLoader());

  if (!forceSubmit) {
    return ApiManager.request('post', dispatch, 'questions/name', { name: values.content }).then((response) => {
      if (!response.data) {
        dispatch(
          saveQuestion(method, url, formData, message, setFieldError, isKnowledge, answerSetData),
        );
      } else if (response.data) {
        dispatch(toggleAlertDialog(true));
      }
    });
  }

  dispatch(closeAlertDialog());
  return dispatch(
    saveQuestion(method, url, formData, message, setFieldError, isKnowledge, answerSetData),
  );
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

const getQuestionData = (id) => (dispatch) => (
  dispatch(loadQuestionData(id)).then((questionData) => {
    if (questionData.data.question_type_id === KNOWLEDGE_QUESTION_ID) {
      return dispatch(loadAnswerData(questionData.data.answer_set_id)).then((answerData) => (
        Promise.resolve({
          questionData,
          answerData,
        })
      ));
    }
    return Promise.resolve({ questionData });
  }));

export const loadPageData = (routeState, message) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  const promises = {};
  promises.questionsTypes = dispatch(loadQuestionsTypes());
  promises.questionsGroups = dispatch(loadQuestionsGroups());
  promises.answerSets = dispatch(loadAnswerSets());
  promises.answerSetTypes = dispatch(loadAnswerSetTypes());

  if (routeState.id) {
    promises.questionData = dispatch(getQuestionData(routeState.id));
  }

  PromiseAll(promises).then((responses) => {
    dispatch(loadPageSuccess(routeState.id, responses));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
