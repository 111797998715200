import { push, replace } from 'connected-react-router';

import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import getSortAndPageData from 'utils/getSortAndPageData';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getCountStartFrom from 'utils/getCountStartFrom';
import errorCatch from 'utils/errorCatch';

import {
  hideLoader, showLoader, showTransparentLoader, setSortingAndPagination,
  setPage, setRowsPerPage, setSortingData, setLastFilterValues,
} from 'containers/store';
import { getStatus } from './utils';

const sortFieldsMapping = {
  postalCode: 'postal_code',
  name: 'name',
  city: 'city',
  street: 'street',
  number: 'number',
  facilityType: 'clinic_type_name',
  status: 'status',
};

export const initialState = {
  isLoadedPage: false,
  items: [],
  facilityTypes: [],
};

const defaultSorting = {
  fieldName: 'name',
  sortDirection: 'asc',
};

const mapFacilities = (
  facilities,
  startingPosition,
) => facilities.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: el.name,
  city: el.city,
  street: el.street,
  number: el.number,
  postalCode: el.postal_code,
  facilityType: el.clinic_type_name,
  status: getStatus(el),
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'FACILITY_LIST/LOAD_PAGE_SUCCESS',
  SET_FILTERED_FACILITIES: 'FACILITY_LIST/SET_FILTERED_FACILITIES',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapFacilities(
          action.responses.data.items,
          action.startingPosition,
          state.facilityTypes,
        ),
        totalItemsCount: action.responses.data.total,
      };
    }

    case actionTypes.SET_FILTERED_FACILITIES: {
      return {
        ...state,
        items: mapFacilities(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  startingPosition,
});

const setFilteredPatients = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_FACILITIES,
  response,
  startCountFrom,
});

const loadFacilities = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: defaultSorting.fieldName,
  };

  if (params.lastFilterValues.search) {
    data.filterByName = params.lastFilterValues.search;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('clinics', data);
  const pageUrl = createURLWithQuery('facilityList', data);

  dispatch(replace(pageUrl));

  return ApiManager.request('get', dispatch, url);
};

const getFacilities = () => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  try {
    const response = await dispatch(loadFacilities(data));
    dispatch(setFilteredPatients(response, startCountFrom));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getFacilities());
};

export const onChangePage = (_, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getFacilities());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const { sortedBy, orderBy } = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, sortedBy, orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getFacilities());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getFacilities());
};

export const onAddClick = () => (dispatch) => {
  dispatch(push('/facilityAddEdit'));
};

export const onActionClick = (id) => (dispatch) => {
  dispatch(push('/facility', { idFromList: id }));
};

export const loadPageData = (values) => async (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values) {
    dispatch(setLastFilterValues({
      search: values.filterByName || '',
    }));
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  try {
    const facilities = await dispatch(loadFacilities(data));
    dispatch(loadPageSuccess(facilities, startingPosition));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
