import _forEach from 'lodash/forEach';
import _endsWith from 'lodash/endsWith';

export default (query) => {
  let parsedQuery = query.toString();
  const result = {};

  if (parsedQuery.length > 0) {
    const questionMarkPosition = parsedQuery.indexOf('?');

    if (questionMarkPosition > -1) {
      parsedQuery = parsedQuery.slice(0, questionMarkPosition)
        + parsedQuery.slice(questionMarkPosition + 1);
    }

    const parts = parsedQuery.split('&');

    _forEach(parts, (el) => {
      const [key, value] = el.split('=');

      if (_endsWith(key, '%5B%5D')) {
        const arrayKey = key.replace('%5B%5D', '');
        if (!result[arrayKey]) { result[arrayKey] = []; }
        result[arrayKey].push(decodeURIComponent(value));
      } else {
        result[key] = decodeURIComponent(value);
      }
    });
  }

  return result;
};
