import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import _isEqual from 'lodash/isEqual';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _size from 'lodash/size';

import AddBoxIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import Section from 'components/Section';
import CardContent from 'components/CardContent';
import BannerContainer from 'components/BannerContainer';
import SummaryButtons from 'components/SummaryButtons';
import CustomTable from 'components/CustomTable';
import Modal from 'components/Modal';

import batteriesEditBanner from 'assets/img/batteriesEditBanner.svg';
import { intlShape } from 'utils/shapes/intlShape';

import { itemShape as selectItemShape } from 'utils/shapes/select.shapes';
import customActionComponent from './components/Action/Action';
import messages from './FacilityBatteriesEdit.messages';
import useStyle from './FacilityBatteriesEdit.styles';
import { itemShape } from './FacilityBatteriesEdit.shapes';

const ACTION_CELL_WIDTH = 24;

const FacilityBatteriesEdit = ({
  onCancel,
  onSubmit,
  intl,
  items,
  batteryOptions,
  type,
  isTryAndBuy,
}) => {
  const classes = useStyle();
  const [isAdding, toggleAdding] = useState(_isEmpty(items));
  const [batteries, setBatteries] = useState(items);
  const [autocompleteOptions, setAutocompleteOptions] = useState(batteryOptions);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');

  const head = [
    {
      id: 'name',
      label: intl.formatMessage(messages.name),
      sortable: false,
    },
  ];

  const onBatteryDelete = (id) => {
    const selectedElement = _find(batteries, (el) => el.id === id);
    setAutocompleteOptions([...autocompleteOptions, selectedElement]);
    const newBateries = _filter(batteries, (el) => el.id !== id);
    setBatteries(newBateries);
  };

  const onInputChange = (value) => {
    setAutocompleteInputValue(value);
  };

  const onBatteryAdd = (value) => {
    setBatteries([...batteries, value]);
    const newAutocompleteOptions = _filter(autocompleteOptions, (el) => el.id !== value.id);
    setAutocompleteOptions(newAutocompleteOptions);
    setAutocompleteInputValue('');
    toggleAdding(false);
  };

  const itemsCount = _size(batteries);

  return (
    <Modal
      isOpen={!!type}
      onClickCloseIcon={onCancel}
    >
      <CardContent withoutBorder bottomMargin={false} staticWidth>
        <BannerContainer addPaddings smallLogoPadding logo={batteriesEditBanner}>
          <div className={classes.wrapper}>
            <div className={classes.content}>
              <Section title={intl.formatMessage(messages.title)} />
              <div className={classes.tableWrapper}>
                <CustomTable
                  isCustomAction
                  withActions
                  items={batteries}
                  head={head}
                  isNumberCell={false}
                  tablePagination={false}
                  showHead
                  onCustomActionClick={onBatteryDelete}
                  centerCustomActions={false}
                  customActionComponent={
                    ({ row, onCustomActionClick }) => (
                      customActionComponent({
                        row, onCustomActionClick, isTryAndBuy, itemsCount,
                      })
                    )
                  }
                  actionCellLength={ACTION_CELL_WIDTH}
                />
              </div>
              {
                isAdding ? (
                  <Autocomplete
                    name="batteryAdd"
                    rowsmax={10}
                    onChange={(event, value) => {
                      onBatteryAdd(value);
                    }}
                    classes={{ input: classes.input }}
                    options={autocompleteOptions}
                    getOptionLabel={(el) => el.name || ''}
                    disableClearable
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            onChange: (e) => {
                              params.inputProps.onChange(e);
                              onInputChange(e.target.value);
                            },
                            value: autocompleteInputValue,
                          }}
                          placeholder={intl.formatMessage(messages.add)}
                          fullWidth
                        />
                      </>
                    )}
                  />
                ) : (
                  !_isEmpty(autocompleteOptions) && (
                  <div className={classes.batteryAddButton}>
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      title={intl.formatMessage(messages.add)}
                      placement="top"
                    >
                      <span>
                        <IconButton className={classes.primaryIconButton} size="small" onClick={() => toggleAdding(true)}>
                          <AddBoxIcon color="primary" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                  )
                )
              }
            </div>
            <SummaryButtons
              smallSpacing
              cancelMessage={intl.formatMessage(messages.cancel)}
              submitMessage={intl.formatMessage(messages.save)}
              onCancel={onCancel}
              onSubmit={() => { onSubmit(batteries, setBatteries); }}
              isSubmitDisabled={_isEqual(batteries, items)}
            />
          </div>
        </BannerContainer>
      </CardContent>
    </Modal>
  );
};

FacilityBatteriesEdit.propTypes = {
  batteryOptions: PropTypes.arrayOf(selectItemShape).isRequired,
  intl: intlShape.isRequired,
  items: PropTypes.arrayOf(itemShape).isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isTryAndBuy: PropTypes.bool,
};

FacilityBatteriesEdit.defaultProps = {
  isTryAndBuy: false,
};

export default injectIntl(FacilityBatteriesEdit);
