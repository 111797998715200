import React from 'react';
import { FormattedMessage } from 'react-intl';

import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';

import messages from './messages';

const ACTIVE = 'ACTIVE';

const statuses = {
  ACTIVE: <FormattedMessage {...messages.active} />,
  INACTIVE: <FormattedMessage {...messages.inactive} />,
};

export const getStatus = (facilityData) => {
  if (facilityData.status === ACTIVE && _isEmpty(facilityData.batteries)) {
    return <FormattedMessage {...messages.lackOfTools} />;
  }

  if (facilityData.is_in_try_and_buy_mode) {
    return <FormattedMessage {...messages.trial} />;
  }
  return statuses[facilityData.status];
};

export const getFacilityType = (id, facilities) => _get(_find(facilities, (facility) => facility.id === id), 'name', '');
