import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import _isEqual from 'lodash/isEqual';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Section from 'components/Section';
import BannerContainer from 'components/BannerContainer';
import SummaryButtons from 'components/SummaryButtons';
import CardContent from 'components/CardContent';
import Modal from 'components/Modal';
import CounterInput from 'components/CounterInput';
import FormField from 'components/FormField';
import KeyboardDatePicker from 'components/KeyboardDatePicker';
import AmountInput from 'components/AmountInput';

import validator from 'utils/validator/core';
import isDateInThePast from 'utils/isDateInThePast';
import { classesShape } from 'utils/shapes/classesShape';
import { intlShape } from 'utils/shapes/intlShape';
import editInstitutionSettingsLogo from 'assets/img/editInstitutionSettingsLogo.svg';

import messages from './FacilitySettingsEdit.messages';
import {
  getMessages,
  getValidators,
  disabledIfFieldValue,
} from './FacilitySettingsEdit.utils';
import { initialValuesShape } from './FacilitySettingsEdit.shapes';
import styles from './FacilitySettingsEdit.styles';

const propTypes = {
  classes: classesShape.isRequired,
  initialValues: initialValuesShape.isRequired,
  intl: intlShape.isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isActivationMode: PropTypes.bool,
};

const defaultProps = {
  isActivationMode: false,
};
const FacilitySettingsEdit = ({
  classes,
  initialValues,
  intl,
  type,
  onSubmit,
  onCancel,
  isActivationMode,
}) => {
  const messagesGroup = getMessages(intl, messages);
  const {
    integer,
    noDecimalPlaces,
    nonNegativeNumber,
    isCorrectDate,
    validInteger,
    notEmpty,
    notEarlierThanToday,
    correctStartDate,
  } = getValidators();

  const validators = [
    {
      fieldName: 'netCost',
      validators: [notEmpty, nonNegativeNumber, noDecimalPlaces, validInteger],
    },
    {
      fieldName: 'numberOfTablets',
      validators: [nonNegativeNumber, noDecimalPlaces, validInteger],
    },
    {
      fieldName: 'startDate',
      validateIf: (values) => values.startDate !== initialValues.startDate,
      validators: [notEmpty, isCorrectDate, notEarlierThanToday, correctStartDate],
    },
    {
      fieldName: 'endDate',
      validateIf: (values) => values.endDate && values.numberOfTablets,
      validators: [isCorrectDate],
    },
    {
      fieldName: 'billingCycle',
      validateIf: (values) => values.netCost,
      validators: [notEmpty, integer, noDecimalPlaces, validInteger],
    },
    {
      fieldName: 'transitionalPeriod',
      validators: [notEmpty, validInteger],
    },
  ];

  return (
    <Modal
      isOpen={!!type}
      onClickCloseIcon={onCancel}
    >
      <CardContent withoutBorder bottomMargin={false}>
        <BannerContainer logo={editInstitutionSettingsLogo} addPaddings smallLogoPadding>
          <Section title={messagesGroup.title}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={
                (values) => validator(
                  values,
                  validators,
                  intl.formatMessage,
                )
              }
            >
              {({
                values,
                errors,
                touched,
                isValid,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form className={classes.formSpacing}>
                  <Grid container spacing={6}>
                    <Grid item md={6} xs={12} className={classes.gridItem}>
                      <AmountInput
                        fullWidth
                        onBlur={setFieldTouched}
                        onChange={setFieldValue}
                        errors={errors}
                        touched={touched}
                        name="netCost"
                        label={messagesGroup.netCost}
                        value={values.netCost}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.gridItem}>
                      <FormField
                        onBlur={setFieldTouched}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          setTimeout(() => {
                            setFieldValue('firstDayOfBillingCycle', value.date());
                          }, 0);
                        }}
                        errors={errors}
                        touched={touched}
                        name="startDate"
                        isDate
                        fullWidth
                      >
                        <KeyboardDatePicker
                          name="startDate"
                          disabled={isDateInThePast(initialValues.startDate)}
                          value={values.startDate}
                          label={messagesGroup.startDate}
                        />
                      </FormField>
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.gridItem}>
                      <CounterInput
                        onBlur={setFieldTouched}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          if (value === 0) {
                            setTimeout(() => { setFieldValue('endDate', null); }, 0);
                          }
                        }}
                        errors={errors}
                        touched={touched}
                        name="numberOfTablets"
                        label={messagesGroup.numberOfTablets}
                        value={values.numberOfTablets}
                        withoutCounterButton
                        minValue={0}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.gridItem}>
                      <FormField
                        onBlur={setFieldTouched}
                        onChange={setFieldValue}
                        errors={errors}
                        touched={touched}
                        name="endDate"
                        isDate
                        fullWidth
                      >
                        <KeyboardDatePicker
                          disabled={!values.numberOfTablets}
                          name="endDate"
                          value={values.endDate}
                          label={messagesGroup.endDate}
                        />
                      </FormField>
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.gridItem}>
                      <CounterInput
                        onBlur={setFieldTouched}
                        onChange={setFieldValue}
                        errors={errors}
                        touched={touched}
                        name="billingCycle"
                        label={messagesGroup.billingCycle}
                        value={values.billingCycle}
                        withoutCounterButton
                        disabled={disabledIfFieldValue(values.netCost, 0)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.gridItem}>
                      <FormField
                        onBlur={setFieldTouched}
                        onChange={setFieldValue}
                        errors={errors}
                        touched={touched}
                        name="firstDayOfBillingCycle"
                        label={messagesGroup.firstDayOfBillingCycle}
                        fullWidth
                      >
                        <Input
                          value={values.firstDayOfBillingCycle}
                          margin="none"
                          disabled
                        />
                      </FormField>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      <CounterInput
                        onBlur={setFieldTouched}
                        onChange={setFieldValue}
                        errors={errors}
                        touched={touched}
                        name="transitionalPeriod"
                        label={messagesGroup.transitionalPeriod}
                        value={values.transitionalPeriod}
                        withoutCounterButton
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={classes.checkboxField}
                    >
                      <FormControlLabel
                        classes={{
                          root: classes.checkbox,
                          label: classes.checkboxLabel,
                        }}
                        control={(
                          <Checkbox
                            classes={{ root: classes.checkboxRoot }}
                            checked={Boolean(values.unlimitedUsage)}
                            color="primary"
                            onChange={(event) => {
                              setFieldValue('unlimitedUsage', event.currentTarget.checked);
                            }}
                            value={values.unlimitedUsage}
                            size="small"
                            type="checkbox"
                          />
                        )}
                        label={intl.formatMessage(messages.unlimitedUsage)}
                      />
                      <FormControlLabel
                        classes={{
                          root: classes.checkbox,
                          label: classes.checkboxLabel,
                        }}
                        control={(
                          <Checkbox
                            classes={{ root: classes.checkboxRoot }}
                            checked={Boolean(values.showConfidenceInterval)}
                            color="primary"
                            onChange={(event) => {
                              setFieldValue('showConfidenceInterval', event.currentTarget.checked);
                            }}
                            value={values.showConfidenceInterval}
                            size="small"
                            type="checkbox"
                          />
                        )}
                        label={intl.formatMessage(messages.showConfidenceInterval)}
                      />
                      <FormControlLabel
                        classes={{
                          root: classes.checkbox,
                          label: classes.checkboxLabel,
                        }}
                        control={(
                          <Checkbox
                            classes={{ root: classes.checkboxRoot }}
                            checked={Boolean(values.hasGroupExaminationsEnabled)}
                            color="primary"
                            onChange={(event) => {
                              setFieldValue('hasGroupExaminationsEnabled', event.currentTarget.checked);
                            }}
                            value={values.hasGroupExaminationsEnabled}
                            size="small"
                            type="checkbox"
                          />
                        )}
                        label={intl.formatMessage(messages.hasGroupExaminationsEnabled)}
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.buttonsWrapper}>
                    <SummaryButtons
                      isSubmitDisabled={
                        (_isEqual(initialValues, values) && !isActivationMode) || !isValid
                      }
                      onCancel={onCancel}
                      cancelMessage={messagesGroup.cancel}
                      submitMessage={messagesGroup.save}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Section>
        </BannerContainer>
      </CardContent>
    </Modal>
  );
};

FacilitySettingsEdit.propTypes = propTypes;
FacilitySettingsEdit.defaultProps = defaultProps;

export default withStyles(styles)(injectIntl(FacilitySettingsEdit));
