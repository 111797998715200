import { defineMessages } from 'react-intl';

import getNumberFromValueWithComma from 'utils/getNumberFromValueWithComma';

const messages = defineMessages({
  fieldValueTooLow: 'Wartość pola zbyt niska',
});

export default (value, minValue) => {
  const result = {};
  const parsedValue = getNumberFromValueWithComma(value);

  result.isValid = parsedValue >= minValue;
  result.message = messages.fieldValueTooLow;

  return result;
};
