import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Zasilenie konta',
  topUpAmount: 'Kwota zasilenia brutto',
  addedPoints: 'Przyznane punkty',
  pointsAmount: 'Wynikowe saldo punktowe',
  substantiation: 'Uzasadnienie',
  cancel: 'Anuluj',
  save: 'Zapisz',
  textAreaPlaceholder: 'Zacznij pisać',
  positionName: 'Nazwa pozycji',
});
