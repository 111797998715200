import { goBack, replace } from 'connected-react-router';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _find from 'lodash/find';
import _reject from 'lodash/reject';
import moment from 'moment';

import config from 'config';

import userRoles, { facilityRoles } from 'utils/constants/rolesOfUsers';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import errorCatch from 'utils/errorCatch';
import createURLWithQuery from 'utils/createURLWithQuery';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import formatFullName from 'utils/formatFullName';
import isSpecializationStudent from 'utils/isSpecializationStudent';
import specializationTypes from 'utils/constants/specializationTypes';
import clinicTypes from 'utils/constants/clinicTypes';

import {
  showLoader, hideLoader, showSnackbar, getMeta, showTransparentLoader, setDrawerVisibility,
} from 'containers/store';
import { getClinicTypes } from 'containers/Facility/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    name: '',
    surname: '',
    email: '',
    status: '',
    role: '',
    specialization: '',
    deactivationDate: moment(),
    organizations: [{
      organization: {},
    }],
    clinics: [{
      clinc: {},
    }],
  },
  roles: [],
  statuses: [],
  suggestions: [],
  isViewProfile: false,
  newClinicCoordinator: false,
  userId: null,
  currentUserRole: null,
};

const getAvailableRolesForCurrentUser = (
  roles,
  loggedUserRole,
  userRole,
) => {
  const availableRoles = {
    [userRoles.superAdmin]: ['admin', 'pollster', 'sales_coordinator', 'product_owner'],
    [userRoles.admin]: ['pollster', 'sales_coordinator', 'product_owner'],
    [userRoles.salesCoordinator]: ['clinic_coordinator', 'account_manager'],
    [userRoles.accountManager]: ['clinic_coordinator'],
  };

  const isFacilityRole = _includes(facilityRoles, userRole);

  const availableRolesIds = (
    loggedUserRole === userRole || isFacilityRole
  ) ? [userRole] : availableRoles[loggedUserRole];

  return _filter(roles, ({ id }) => _includes(
    availableRolesIds,
    id,
  ));
};

const mapDataToValues = (data) => {
  const organizations = !_isEmpty(data.organizations)
    ? _map(data.organizations, (el) => ({
      organization: {
        id: el.id,
        name: el.name,
      },
    }))
    : [{ organization: {} }];

  const clinics = !_isEmpty(data.clinics)
    ? _map(data.clinics, (el) => ({
      clinic: {
        id: el.id,
        name: el.name,
      },
    }))
    : [{ clinic: {} }];

  return {
    name: data.first_name || '',
    surname: data.last_name || '',
    email: data.email || '',
    status: data.status || '',
    role: data.role_id || '',
    specialization: data.specialization_id || '',
    deactivationDate: data.deactivation_date ? moment(data.deactivation_date, 'YYYY-MM-DD') : moment(),
    organizations,
    clinics,
  };
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'USERS_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'USERS_ADD_EDIT/CLEAR_STORE',
  SET_SUGGESTIONS: 'USERS_ADD_EDIT/SET_SUGGESTIONS',
  CLEAR_SUGGESTIONS: 'USERS_ADD_EDIT/CLEAR_SUGGESTIONS',
};

const mapSuggestions = (items) => items.map((el) => ({
  id: el.id,
  name: el.name,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const initialValues = action.responses.userData
        ? mapDataToValues(action.responses.userData.data) : {
          ...initialState.initialValues,
          specialization: _find(
            action.responses.specializations.data,
            (spec) => spec.name === specializationTypes.NONE,
          ).id,
        };

      const roles = getAvailableRolesForCurrentUser(
        action.responses.meta.data.userRole,
        action.currentUserRoleID,
        _get(action, 'responses.userData.data.role_id', ''),
        action.newClinicCoordinator,
      );

      return {
        ...state,
        isLoadedPage: true,
        isViewProfile: action.isViewProfile,
        userId: action.userId,
        initialValues: {
          ...initialValues,
          role: action.newClinicCoordinator ? 'clinic_coordinator' : initialValues.role,
        },
        roles,
        newClinicCoordinator: action.newClinicCoordinator,
        specializations: _sortBy(action.responses.specializations.data, 'name'),
        statuses: action.responses.meta.data.userStatus,
        currentUserRole: action.currentUserRoleID,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_SUGGESTIONS: {
      return {
        ...state,
        suggestions: mapSuggestions(action.response.data.items),
      };
    }

    case actionTypes.CLEAR_SUGGESTIONS: {
      return {
        ...state,
        suggestions: [],
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (
  isViewProfile,
  userId,
  newClinicCoordinator,
  responses,
  currentUserRoleID,
) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  isViewProfile,
  userId,
  newClinicCoordinator,
  responses,
  currentUserRoleID,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setSuggestions = (response) => ({
  type: actionTypes.SET_SUGGESTIONS,
  response,
});

const loadUserData = (id) => (dispatch) => ApiManager.request('get', dispatch, `users/${id}`);

const goToPrevPage = (newUserData) => (dispatch, getStore) => {
  const { newClinicCoordinator } = getStore().UserAddEdit;

  if (newClinicCoordinator) {
    dispatch(
      replace(
        '/facilityAddEdit',
        {
          newCoordinatorData: newUserData,
          fromNewCoordinatorForm: true,
        },
      ),
    );
  } else {
    dispatch(goBack());
  }
};

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const { userId, specializations } = getStore().UserAddEdit;
  let url = 'users';
  let method = 'post';
  let message = snackbarMessages.userAddedSuccessfully;

  if (userId) {
    url = `users/${userId}`;
    method = 'put';
    message = snackbarMessages.userEditedSuccessfully;
  }

  const data = {
    first_name: values.name,
    last_name: values.surname,
    email: values.email,
    role_id: values.role,
    status: values.status,
    specialization_id: values.specialization,
    deactivation_date: (
      isSpecializationStudent(values.specialization, specializations) && values.deactivationDate
    ) ? values.deactivationDate.format('YYYY-MM-DD') : null,
    last_used_app_version: config.appVersion,
  };

  if (values.role === 'pollster') {
    const organizations = _filter(
      values.organizations,
      (el) => el.organization && !!el.organization.id,
    );

    data.organizations = organizations.map((el) => ({
      organization_id: el.organization.id,
    }));
  }

  if (values.role === 'account_manager') {
    data.clinics = _compact(_map(values.clinics, 'clinic.id'));
  }

  dispatch(showTransparentLoader());

  ApiManager.request(method, dispatch, url, data).then((response) => {
    dispatch(showSnackbar(message));
    dispatch(hideLoader());
    const newUserData = {
      id: response.data.id,
      name: formatFullName(response.data),
    };
    dispatch(goToPrevPage(newUserData));
  }).catch((error) => {
    if (isBadRequest(error)) {
      if (error.error.errors.organizations) {
        dispatch(showSnackbar({
          id: 'duplicatedOrganization',
          defaultMessage: error.error.errors.organizations[0],
        }));
      } else {
        setFormErrors(error.error.errors, setFieldError, apiFieldMappings.usersAddEdit);
        dispatch(showSnackbar(snackbarMessages.wrongData));
      }
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onCancel = () => (dispatch) => {
  dispatch(goToPrevPage());
};

const getSpecializations = () => (dispatch) => ApiManager.request('get', dispatch, 'specializations?orderBy=id&sortedBy=asc');

const getOrganizations = (find) => (dispatch) => {
  const params = {
    find,
  };

  const url = createURLWithQuery('organizations', params);

  return ApiManager.request('get', dispatch, url);
};

const getFacilities = (filterByName) => (dispatch) => {
  const params = {
    filterByName,
    hasAccountManager: false,
  };

  const url = createURLWithQuery('clinics', params);

  return ApiManager.request('get', dispatch, url);
};

export const onFetchOrganizations = (search) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(getOrganizations(search)).then((response) => {
    dispatch(setSuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};

export const onFetchFacilities = (search) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(getFacilities(search)).then((response) => {
    dispatch(setSuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};

export const onClear = () => ({
  type: actionTypes.CLEAR_SUGGESTIONS,
});

export const loadPageData = (routeState, message) => (dispatch, getStore) => {
  const currentUserRoleID = getStore().Global.userData.role_id;
  dispatch(clearStore());
  dispatch(showLoader());
  if (!routeState.isViewProfile) {
    dispatch(setDrawerVisibility({
      visibility: true,
      content: message,
    }));
  }
  const promises = {};
  promises.meta = dispatch(getMeta());
  promises.specializations = dispatch(getSpecializations());
  promises.clinicTypes = dispatch(getClinicTypes());

  if (routeState.id) {
    promises.userData = dispatch(loadUserData(routeState.id));
  }

  PromiseAll(promises).then((responses) => {
    const clinicTypeId = _get(responses, 'userData.data.clinics[0].clinic_type_id');
    const college = _find(
      responses.clinicTypes.data,
      (clinic) => clinic.name === clinicTypes.COLLEGE,
    );
    const isCollege = clinicTypeId && clinicTypeId === college.id;
    const isClinicCoordinator = _get(responses, 'userData.data.role_id') === userRoles.clinicCoordinator;

    let userSpecializations = responses.specializations.data;
    if ((!isCollege || isClinicCoordinator) && !routeState.isViewProfile) {
      userSpecializations = _reject(
        responses.specializations.data,
        (specialization) => specialization.name === specializationTypes.STUDENT,
      );
    }

    dispatch(loadPageSuccess(
      !!routeState.isViewProfile,
      routeState.id,
      routeState.newClinicCoordinator,
      {
        ...responses,
        specializations: {
          ...responses.specializations,
          data: userSpecializations,
        },
      },
      currentUserRoleID,
    ));
    dispatch(hideLoader());
  }).catch((error) => {
    errorCatch(error, dispatch, true);
  });
};
