import React from 'react';
import { replace, push } from 'connected-react-router';

import {
  showTransparentLoader,
  hideLoader,
  showSnackbar,
  showSnackbarAction,
  hideSnackbar,
} from 'containers/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';

import ResetPasswordTokenError from 'components/ResetPasswordTokenError';

import { isTokenError } from './utils';

export const initialState = {
  initialValues: {
    login: '',
    password: '',
    confirmPassword: '',
  },
};

export const actionTypes = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const onClick = (login) => (dispatch) => {
  dispatch(hideSnackbar());
  dispatch(push('/forgotPassword', { login }));
};

export const onSubmit = (values, token, newUser, { setFieldError }) => (dispatch) => {
  dispatch(showTransparentLoader());

  const requestBody = {
    token,
    password: values.password,
    password_confirmation: values.confirmPassword,
    email: values.login,
  };

  const url = newUser ? 'create_password' : 'reset_password';

  ApiManager.request('post', dispatch, url, requestBody).then(() => {
    dispatch(hideLoader());
    dispatch(showSnackbar(snackbarMessages.passwordChanged));
    dispatch(replace('/login'));
  }).catch((error) => {
    if (isTokenError(error)) {
      dispatch(showSnackbarAction(
        <ResetPasswordTokenError onClick={() => dispatch(onClick(values.login))} />,
      ));
    } else if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.setUserPassword);
      if (error.error.errors.password) {
        dispatch(showSnackbar({
          id: 'incorrectPassword',
          defaultMessage: error.error.errors.password[0],
        }));
      } else {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      }
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
