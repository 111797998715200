import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _find from 'lodash/find';
import _replace from 'lodash/replace';

import iconTypes from './icon_associations.json';
import useStyle from './FileIcon.styles';

const Icon = ({ iconName }) => {
  const [icon, setIcon] = useState('');
  const classes = useStyle();
  useEffect(() => {
    if (iconName) {
      const getIcon = async () => {
        const importedIcon = await import(`./iconsFiles/${iconName}`);
        setIcon(importedIcon.default);
      };
      getIcon();
    }
  });

  return (icon ? <img alt={iconName} src={icon} className={classes.fileIcon} /> : null);
};

Icon.propTypes = {
  iconName: PropTypes.string.isRequired,
};

const FileIcon = ({ fileName }) => {
  const fileType = _find(iconTypes.associations, (type) => {
    // eslint-disable-next-line no-empty-character-class
    const pattern = _replace(type.pattern, /[]/g, '\\|');

    return fileName.match(pattern);
  });

  const icon = _get(fileType, 'icon', 'blank.svg');

  return (<Icon iconName={icon} />);
};

FileIcon.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export default FileIcon;
