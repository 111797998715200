import React from 'react';
import PropTypes from 'prop-types';

import config from 'config';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormField from 'components/FormField';
import classNames from 'classnames';

import RichTextInput from './components/RichTextInput';
import useStyle from './OutlinedTextarea.styles';

const RichInput = (props) => (
  <RichTextInput
    name={props.name}
    value={props.value}
    placeholder={props.placeholder}
    onBlur={props.disabled ? () => {} : props.onBlur}
    disabled={props.disabled}
    onChange={props.onChange}
    idSuffix={props.idSuffix}
  />
);

RichInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  idSuffix: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const OutlinedTextarea = ({
  disabled,
  errors,
  touched,
  name,
  label,
  labelWidth,
  value,
  placeholder,
  isRTE,
  isBigValueDark,
  onBlur,
  onChange,
  idSuffix,
  rows,
}) => {
  const classes = useStyle();
  const inputComponent = isRTE ? RichInput : 'input';

  const inputProps = {
    name,
    value,
    placeholder,
    onBlur,
    onChange,
  };

  if (isRTE) {
    inputProps.idSuffix = idSuffix;
  } else {
    const textAreaClassName = classes.textArea;
    inputProps.className = classNames(textAreaClassName, {
      [classes.isBigValueDark]: isBigValueDark,
    });
  }
  const defaultRows = rows || config.defaultTextareaRows;

  return (
    <FormField
      variant="outlined"
      name={name}
      label={label}
      errors={errors}
      touched={touched}
      isRTE={isRTE}
      fullWidth
      onBlur={isRTE ? () => {} : onBlur}
      onChange={isRTE ? () => {} : onChange}
    >
      <OutlinedInput
        variant="outlined"
        margin="none"
        labelWidth={labelWidth}
        multiline={!isRTE}
        rows={isRTE ? null : defaultRows}
        notched={isRTE || undefined}
        placeholder={placeholder}
        inputComponent={inputComponent}
        inputProps={inputProps}
        value={value}
        disabled={disabled}
        classes={isRTE ? {} : {
          multiline: classes.multiline,
          notchedOutline: classes.inputOutline,
        }}
      />
    </FormField>
  );
};

OutlinedTextarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  idSuffix: PropTypes.string,
  isBigValueDark: PropTypes.bool,
  isRTE: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelWidth: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rows: PropTypes.number,
  touched: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  value: PropTypes.string,
  onBlur: PropTypes.func,
};

OutlinedTextarea.defaultProps = {
  disabled: false,
  errors: {},
  idSuffix: '',
  isBigValueDark: false,
  isRTE: false,
  placeholder: '',
  touched: {},
  onBlur: () => {},
  value: '',
  label: '',
  labelWidth: 0,
  name: '',
  rows: null,
};

export default OutlinedTextarea;
