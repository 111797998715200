import _reduce from 'lodash/reduce';

const units = {
  h: 'godz',
  min: 'min',
  s: 'sek.',
};

export default (time) => {
  const seconds = time % 3600 % 60;
  const minutes = (time - seconds) % 3600;
  const hours = time - seconds - minutes;

  if (time === 0) {
    return `0 ${units.s}`;
  }

  return _reduce([
    { value: hours, unit: units.h, divisor: 3600 },
    { value: minutes, unit: units.min, divisor: 60 },
    { value: seconds, unit: units.s, divisor: 1 },
  ], (acc, param) => {
    let formattedTime = acc;
    if (param.value > 0) {
      const prevValue = acc ? `${acc} ` : '';
      formattedTime = `${prevValue}${param.value / param.divisor} ${param.unit}`;
    }
    return formattedTime;
  }, '');
};
