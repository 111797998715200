export default (theme) => ({
  card: {
    overflow: 'visible',
    height: 'fit-content',
  },
  withoutBorder: {
    border: 'none',
  },
  bottomMargin: {
    marginBottom: theme.spacing(4),
    '&:last-of-type': {
      marginBottom: theme.spacing(16.5),
    },
  },
  width: {
    width: theme.sizes.cardContent.width,
  },
  maxWidth: {
    maxWidth: theme.sizes.cardContent.width,
  },
  minWidth: {
    minWidth: theme.sizes.cardContent.width,
  },
  disablePadding: {
    padding: '0 !important',
  },
  sidePadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});
