import moment from 'moment';
import _last from 'lodash/last';
import _split from 'lodash/split';
import formatDate from 'utils/formatDate';
import getDateFromApi from 'utils/getDateFromApi';
import formatFullName from 'utils/formatFullName';

const formatFileData = (fileData, currentUserName) => ({
  addedBy: currentUserName,
  date: formatDate(moment()),
  fileName: fileData.name,
});

const formatFileFromAPI = (fileData) => ({
  addedBy: formatFullName(fileData.user),
  date: getDateFromApi(fileData.created_at),
  fileName: _last(_split(fileData.original_filename || fileData.file, '/')),
});

const getInitialValues = (fileData) => ({
  name: fileData.name,
  note: fileData.note,
});

export {
  formatFileData,
  formatFileFromAPI,
  getInitialValues,
};
