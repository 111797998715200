import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  injectIntl,
  FormattedMessage,
} from 'react-intl';

import _map from 'lodash/map';

import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FormField from 'components/FormField';
import SummaryButtons from 'components/SummaryButtons';
import Section from 'components/Section';
import BannerContainer from 'components/BannerContainer';
import Modal from 'components/Modal';
import CardContent from 'components/CardContent';
import validator from 'utils/validator/core';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import { intlShape } from 'utils/shapes/intlShape';
import config from 'config';
import contactImage from 'assets/img/contactImage.svg';

import messages from './PrivateMessage.messages';
import { initialValuesShape } from './PrivateMessage.shapes';
import privateMessageStyles from './PrivateMessage.styles';

const validators = [{
  fieldName: 'topic',
  validators: [{
    validatorName: 'notEmpty',
  }],
}, {
  fieldName: 'text',
  validators: [{
    validatorName: 'notEmpty',
  }, {
    validatorName: 'maxLength',
    parameters: [config.maxLength.textField],
  }],
}];

const PrivateMessage = ({
  intl,
  initialValues,
  onSubmit,
  onCancel,
  type,
  isSubmitting,
  classes,
  topics,
}) => (
  <Modal
    isOpen={!!type}
    onRequestClose={!isSubmitting ? onCancel : undefined}
  >
    <CardContent withoutBorder bottomMargin={false} staticWidth>
      <BannerContainer
        logo={contactImage}
        alignLogo
        addPaddings
      >
        <Formik
          initialValues={{ ...initialValues }}
          validate={(values) => validator(values, validators, intl.formatMessage)}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form>
              <Section title={<FormattedMessage {...messages.title} />}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      errors={errors}
                      touched={touched}
                      name="topic"
                      staticLabel
                      label={intl.formatMessage(messages.topic)}
                      fullWidth
                    >
                      <Select
                        value={values.topic}
                        displayEmpty
                        input={(
                          <Input
                            name="topic"
                            id="topic-picker"
                          />
                        )}
                      >
                        <MenuItem style={{ display: 'none' }} />
                        <MenuItem value="" disabled>
                          {intl.formatMessage(messages.chooseTopic)}
                        </MenuItem>
                        {
                          _map(topics, (el) => (
                            <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormField>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.textareaTitle} variant="h5">
                      <FormattedMessage {...messages.text} />
                    </Typography>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      errors={errors}
                      touched={touched}
                      name="text"
                      placeholder="Test"
                      fullWidth
                    >
                      <OutlinedInput
                        value={values.text}
                        multiline
                        rowsMax={7}
                        rows={7}
                        placeholder={intl.formatMessage(messages.textPlaceholder)}
                        classes={{ root: classes.textareaRoot }}
                      />
                    </FormField>
                  </Grid>
                </Grid>
              </Section>
              <SummaryButtons
                cancelMessage={<FormattedMessage {...messages.cancel} />}
                submitMessage={<FormattedMessage {...messages.save} />}
                withLoaderButton
                onCancel={onCancel}
                loading={isSubmitting}
                smallSpacing
              />
              <Typography className={classes.footer} variant="h5">
                <FormattedMessage {...messages.footerPart1} />
                <br />
                <FormattedMessage {...messages.footerPart2} />
                <br />
                <FormattedMessage {...messages.footerPart3} />
              </Typography>
            </Form>
          )}
        </Formik>
      </BannerContainer>
    </CardContent>
  </Modal>
);

PrivateMessage.propTypes = {
  initialValues: initialValuesShape.isRequired,
  intl: intlShape.isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  isSubmitting: PropTypes.bool,
  topics: PropTypes.arrayOf(itemShape),
};

PrivateMessage.defaultProps = {
  classes: {},
  isSubmitting: false,
  topics: [],
};

export default withStyles(privateMessageStyles)(injectIntl(PrivateMessage));
