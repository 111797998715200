import React from 'react';
import { injectIntl } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Group from '@material-ui/icons/Group';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { classesShape } from 'utils/shapes/classesShape';
import { intlShape } from 'utils/shapes/intlShape';
import styles from 'components/pages/PatientExamination/PatientExamination.styles';
import messages from 'components/pages/PatientExamination/PatientExamination.messages';

const GroupExaminationIcon = ({ classes, intl }) => (
  <Tooltip
    arrow
    title={(
      <>
        <Typography variant="body1" component="span" className={classes.tooltipText}>
          {intl.formatMessage(messages.groupSurvey)}
        </Typography>
      </>
      )}
    placement="bottom"
    classes={{ tooltip: classes.tooltip }}
  >
    <Group className={classes.groupIcon} />
  </Tooltip>
);

GroupExaminationIcon.propTypes = {
  classes: classesShape.isRequired,
  intl: intlShape.isRequired,
};

export default withStyles(styles)(injectIntl(GroupExaminationIcon));
