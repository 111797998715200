import React from 'react';
import PropTypes from 'prop-types';
import { classesShape } from 'utils/shapes/classesShape';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import sectionTitleStyles from './BannerContainer.styles';

const BannerContainer = ({
  children,
  classes,
  logo,
  alignLogo,
  greenBackground,
  addPaddings,
  smallLogoPadding,
  withSmallerPadding,
}) => (
  <div className={classes.container}>
    <div className={classNames(
      classes.wrapper, {
        [classes.smallerPadding]: addPaddings && withSmallerPadding,
      },
    )}
    >
      {children}
    </div>
    <div
      className={classNames(classes.banner, {
        [classes.alignLogo]: alignLogo,
        [classes.greenBannerBackground]: greenBackground,
        [classes.spacers]: addPaddings,
        [classes.smallLogoPadding]: smallLogoPadding,
      })}
    >
      {logo && <img src={logo} alt="logo" className={classes.image} />}
    </div>
  </div>
);

BannerContainer.propTypes = {
  children: PropTypes.node.isRequired,
  addPaddings: PropTypes.bool,
  alignLogo: PropTypes.bool,
  classes: classesShape,
  greenBackground: PropTypes.bool,
  logo: PropTypes.node,
  smallLogoPadding: PropTypes.bool,
  withSmallerPadding: PropTypes.bool,
};

BannerContainer.defaultProps = {
  classes: {},
  addPaddings: false,
  logo: null,
  alignLogo: false,
  greenBackground: false,
  smallLogoPadding: false,
  withSmallerPadding: true,
};

export default withStyles(sectionTitleStyles)(BannerContainer);
