import PropTypes from 'prop-types';

const initialValuesShape = PropTypes.shape({
  topUpAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addedPoints: PropTypes.number,
  allPoints: PropTypes.number,
  substantiation: PropTypes.string,
});

export { initialValuesShape };
