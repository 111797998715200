import React from 'react';
import { FormattedMessage } from 'react-intl';
import { replace, push } from 'connected-react-router';
import _get from 'lodash/get';
import _camelCase from 'lodash/camelCase';
import moment from 'moment';

import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import ApiManager from 'utils/ApiManager';
import getSortAndPageData from 'utils/getSortAndPageData';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import formatDate from 'utils/formatDate';
import getParamsFromQuery from 'utils/getParamsFromQuery';
import groupSurveyStatuses from 'utils/constants/groupSurveyStatuses';
import formatFullName from 'utils/formatFullName';
import {
  hideLoader, showSnackbar, showLoader, showTransparentLoader, setSortingAndPagination,
  setPage, setRowsPerPage, setLastFilterValues, setSortingData,
} from 'containers/store';
import messages from 'components/pages/GroupSurveys/GroupSurveys.messages';
import config from 'config';

const initialState = {
  isLoadedPage: false,
  items: [],
  totalItemsCount: 0,
  defaultSort: true,
  startDate: moment().add(-30, 'days'),
  endDate: moment(),
  lastQueryParams: {},
  hasSurveys: false,
};

const defaultSorting = {
  fieldName: 'surveyDate',
  sortDirection: 'desc',
};

const sortFieldsMapping = {
  name: 'name',
  diagnostican: 'users|last_name',
  classAndSection: 'class_and_section',
  surveyDate: 'survey_date',
};

const statuses = {
  [groupSurveyStatuses.PREPARED]: <FormattedMessage {...messages.prepared} />,
  [groupSurveyStatuses.IN_PROGRESS]: <FormattedMessage {...messages.inProgress} />,
  [groupSurveyStatuses.ENDED]: <FormattedMessage {...messages.ended} />,
};

const mapSurveys = (surveys) => surveys.map((survey) => {
  const status = statuses[survey.status];

  return {
    id: survey.id,
    apiId: survey.id,
    name: survey.name,
    diagnostican: formatFullName(survey.user),
    classAndSection: survey.classes,
    surveyDate: formatDate(survey.last_session_patient_end_at),
    status,
    isActive: survey.status === groupSurveyStatuses.PREPARED
    || survey.status === groupSurveyStatuses.IN_PROGRESS,
  };
});

const actionTypes = {
  LOAD_PAGE_SUCCESS: 'GROUP_SURVEYS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_GROUP_SURVEYS: 'GROUP_SURVEYS/SET_FILTERED_GROUP_SURVEYS',
  CHANGE_DATES: 'GROUP_SURVEYS/CHANGE_DATES',
  SET_QUERY_PARAMS: 'PATIENTS/SET_QUERY_PARAMS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        defaultSort: true,
        items: mapSurveys(action.response.data.items),
        totalItemsCount: action.response.data.total,
        hasSurveys: action.hasSessionPationsGroups,
      };
    }

    case actionTypes.SET_FILTERED_GROUP_SURVEYS: {
      return {
        ...state,
        items: mapSurveys(action.response.data.items),
        totalItemsCount: action.response.data.total,
        defaultSort: action.defaultSort,
      };
    }

    case actionTypes.CHANGE_DATES: {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    }

    case actionTypes.SET_QUERY_PARAMS: {
      return {
        ...state,
        lastQueryParams: action.queryParams,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (response, hasSessionPationsGroups) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  response,
  hasSessionPationsGroups,
});

const setFilteredGroupSurveys = (response, defaultSort) => ({
  type: actionTypes.SET_FILTERED_GROUP_SURVEYS,
  response,
  defaultSort,
});

const changeDate = (startDate, endDate) => ({
  type: actionTypes.CHANGE_DATES,
  startDate,
  endDate,
});

const setQueryParams = (queryParams) => ({
  type: actionTypes.SET_QUERY_PARAMS,
  queryParams,
});

const loadGroupSurveys = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: sortFieldsMapping[defaultSorting.fieldName],
    orderByClasses: false,
    orderByLatestSessionPatientEndAt: false,
  };

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  if (params.lastFilterValues.startDate
    && params.lastFilterValues.endDate) {
    data.dateFrom = params.lastFilterValues.startDate.format(config.apiDateFormat);
    data.dateTo = params.lastFilterValues.endDate.format(config.apiDateFormat);
  }

  if (params.orderBy === sortFieldsMapping.classAndSection) {
    data.orderBy = undefined;
    data.orderByClasses = true;
  }

  if (params.orderBy === sortFieldsMapping.surveyDate) {
    data.orderBy = undefined;
    if (!params.defaultSort) {
      data.orderByLatestSessionPatientEndAt = true;
    }
  }

  const url = createURLWithQuery('session_patient_groups', data);

  dispatch(replace(url));

  return ApiManager.request('get', dispatch, url);
};

export const saveQueryParams = () => (dispatch) => {
  const queryParams = getParamsFromQuery(window.location.search);

  dispatch(setQueryParams(queryParams));
};

const getGroupSurveys = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const defaultSort = getStore().GroupSurveys.defaultSort;
  const isDefaultSort = defaultSort
    && _camelCase(data.orderBy) === defaultSorting.fieldName
    && data.sortedBy === defaultSorting.sortDirection;

  dispatch(loadGroupSurveys({ ...data, defaultSort: isDefaultSort })).then((response) => {
    dispatch(setFilteredGroupSurveys(response, isDefaultSort));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onDatesChange = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(changeDate(values.startDate, values.startDate));

  dispatch(setLastFilterValues({
    startDate: _get(values, 'startDate', moment().add(-30, 'days')),
    endDate: _get(values, 'endDate', moment()),
  }));
  dispatch(getGroupSurveys());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getGroupSurveys());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getGroupSurveys());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getGroupSurveys());
};

export const onAddClick = () => (dispatch) => {
  dispatch(push('/groupExaminationSelect'));
};

export const onActionClick = (id) => (dispatch) => {
  dispatch(push('/groupExaminationDetails', { id }));
};

const getClinic = (clinicID) => (dispatch) => {
  const url = createURLWithQuery(`clinics/${clinicID}`);

  return ApiManager.request('get', dispatch, url);
};

export const loadPageData = (values) => async (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values) {
    dispatch(setLastFilterValues({
      startDate: values.startDate ? moment(values.startDate) : moment().add(-30, 'days'),
      endDate: values.endDate ? moment(values.endDate) : moment(),
    }));
  }
  dispatch(setQueryParams({}));

  const data = getSortAndPageData(getStore, sortFieldsMapping);

  const clinicId = _get(getStore(), 'Global.userData.clinics[0].id', null);
  const clinicData = await dispatch(getClinic(clinicId));

  const hasSessionPationsGroups = _get(clinicData, 'data.has_session_patient_groups', false);

  dispatch(loadGroupSurveys({ ...data, defaultSort: true })).then((response) => {
    dispatch(loadPageSuccess(response, hasSessionPationsGroups));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
