import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyle from './LoaderButton.styles';

const LoaderButton = ({ children, loading, ...restProps }) => {
  const classes = useStyle();

  return (
    <Button
      disabled={loading}
      {...restProps}
    >
      {loading && (
        <div className={classes.loadingWrapper}>
          <CircularProgress size={20} />
        </div>
      )}
      {children}
    </Button>
  );
};

LoaderButton.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

LoaderButton.defaultProps = {
  loading: false,
};

export default LoaderButton;
