import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { itemShape } from 'components/pages/FacilityBatteriesEdit/FacilityBatteriesEdit.shapes';

import useStyle from './Action.styles';

import messages from './Action.messages';

const Action = ({
  row, onCustomActionClick, isTryAndBuy, itemsCount,
}) => {
  const classes = useStyle();

  const isDisabled = (
    isTryAndBuy && (row.isUsed || itemsCount === 1)
  ) || (
    !isTryAndBuy && row.isActive
  );

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={<FormattedMessage {...messages.delete} />}
      placement="top"
    >
      <span>
        <IconButton size="small" onClick={() => onCustomActionClick(row.id)} disabled={isDisabled}>
          <DeleteIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

Action.propTypes = {
  isTryAndBuy: PropTypes.bool.isRequired,
  itemsCount: PropTypes.number.isRequired,
  row: itemShape.isRequired,
  onCustomActionClick: PropTypes.func.isRequired,
};

export default Action;
