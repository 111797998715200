import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Brak środków na koncie',
  lacksOfFund: 'Saldo Twojego konta w systemie Diagmatic spadło poniżej poziomu umożliwiającego pobranie opłaty za korzystanie z systemu na kolejny okres rozliczeniowy.',
  additionalInfo: 'Przez {daysLeft} dni nadal będzie można korzystać z systemu. Po tym okresie dostęp do platformy zostanie ograniczony do czasu zasilenia konta.',
  warning: 'W celu utrzymania niezakłóconego dostępu do systemu diagnostycznego prosimy o niezwłoczne zasilenie konta.',
  submit: 'Zasil konto',
  cancel: 'Anuluj',
});
