import { goBack, push } from 'connected-react-router';
import config from 'config';

import _isUndefined from 'lodash/isUndefined';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import errorCatch from 'utils/errorCatch';
import dialogTexts from 'utils/dialogTexts';

import {
  showLoader,
  hideLoader,
  showSnackbar,
  showTransparentLoader,
  openDialog,
  setDrawerVisibility,
} from 'containers/store';

const defaultInitialValues = {
  id: '',
  name: '',
  description: '',
  status: '',
  thumbnail: null,
  video: null,
};

export const initialState = {
  isLoadedPage: false,
  videoID: null,
  initialValues: {
    ...defaultInitialValues,
  },
};

const mapDataToValues = (el) => ({
  id: el.id,
  name: el.title,
  description: el.description,
  status: el.status,
  thumbnail: [{ preview: `${config.localApiHostStorage}/${el.thumbnail}` }],
  video: [{ preview: `${config.localApiHostStorage}/${el.video}` }],
});

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'VIDEO_TUTORIAL_ADD_EDIT/LOAD_PAGE_SUCCESS',
  LOAD_PAGE_DATA_SUCCESS: 'VIDEO_TUTORIAL_ADD_EDIT/LOAD_PAGE_DATA_SUCCESS',
  CLEAR_INITIAL_VALUES: 'VIDEO_TUTORIAL_ADD_EDIT/CLEAR_INITIAL_VALUES',
  CLEAR_STORE: 'VIDEO_TUTORIAL_ADD_EDIT/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
      };
    }

    case actionTypes.LOAD_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        initialValues: mapDataToValues(action.response.data),
        videoID: action.videoID,
      };
    }

    case actionTypes.CLEAR_INITIAL_VALUES: {
      return {
        ...state,
        initialValues: {
          ...defaultInitialValues,
        },
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageDataSuccess = (response, videoID) => ({
  type: actionTypes.LOAD_PAGE_DATA_SUCCESS,
  response,
  videoID,
});

const loadPageSuccess = () => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const clearInitialValues = () => ({
  type: actionTypes.CLEAR_INITIAL_VALUES,
});

const loadVideo = (id) => (dispatch) => ApiManager.request('get', dispatch, `video_tutorials/${id}`);

const onDeleteAccept = (id) => async (dispatch) => {
  dispatch(showTransparentLoader());

  try {
    await ApiManager.request('delete', dispatch, `video_tutorials/${id}`);
    dispatch(showSnackbar(snackbarMessages.videoTutorialRemoved));
    dispatch(hideLoader());
    dispatch(clearStore());
    dispatch(push('/videoTutorials'));
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

const getValuesToRequest = (videoID, values) => {
  const formData = new FormData();

  formData.append('title', values.name);
  formData.append('description', values.description);
  formData.append('status', values.status);
  formData.append('_method', 'PUT');

  if (_isUndefined(values.thumbnail[0].path)) {
    return formData;
  }

  formData.append('thumbnail', values.thumbnail[0]);
  formData.append('video', values.video[0]);

  if (!videoID) {
    formData.delete('_method');
    return formData;
  }

  return formData;
};

export const onSubmit = (values, { setFieldError }) => async (dispatch, getStore) => {
  const { videoID } = getStore().VideoTutorialAddEdit;
  const url = videoID ? `video_tutorials/${videoID}` : 'video_tutorials';
  const method = 'post';
  const message = videoID
    ? snackbarMessages.videoTutorialEdited
    : snackbarMessages.videoTutorialAdded;

  dispatch(showTransparentLoader());

  try {
    await ApiManager.request(method, dispatch, url, getValuesToRequest(videoID, values));
    dispatch(goBack());
    dispatch(clearInitialValues());
    dispatch(showSnackbar(message));
    dispatch(hideLoader());
  } catch (error) {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.videoTutorialAddEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

export const onDeleteVideo = () => (dispatch, getStore) => {
  const { videoID } = getStore().VideoTutorialAddEdit;

  dispatch(openDialog({
    title: dialogTexts.removeVideoTutorial,
    onAccept: () => dispatch(onDeleteAccept(videoID)),
  }));
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const onRejectedFile = () => (dispatch) => {
  dispatch(showSnackbar(snackbarMessages.wrongFileTypeOrSize));
};

export const loadPageData = (routeState, message) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));
  try {
    if (routeState.id) {
      const videoData = await dispatch(loadVideo(routeState.id));
      dispatch(loadPageDataSuccess(videoData, routeState.id));
    } else {
      dispatch(loadPageSuccess());
    }
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
