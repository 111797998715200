import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import config from 'config';
import { variantShape } from 'utils/shapes/datePickerShapes';

const KeyboardDatePickerComponent = ({
  value, label, name, id, onBlur, onChange,
  onClose, error, readOnly, disabled, variant, adornmentClasses, inputClasses,
}) => (
  <MuiPickersUtilsProvider locale={moment.locale()} utils={MomentUtils}>
    <KeyboardDatePicker
      name={name}
      id={id}
      onBlur={onBlur}
      onChange={onChange}
      onClose={onClose}
      error={error}
      InputProps={{
        classes: inputClasses,
      }}
      label={label}
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      format={config.dateFormat}
      KeyboardButtonProps={{
        size: 'small',
      }}
      variant={variant}
      invalidDateMessage=""
      maxDateMessage=""
      minDateMessage=""
      autoOk
      fullWidth
      InputAdornmentProps={{
        classes: adornmentClasses,
      }}
    />
  </MuiPickersUtilsProvider>
);

KeyboardDatePickerComponent.propTypes = {
  name: PropTypes.string.isRequired,
  adornmentClasses: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputClasses: PropTypes.objectOf(PropTypes.string),
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  readOnly: PropTypes.bool,
  value: PropTypes.instanceOf(moment),
  variant: variantShape,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

KeyboardDatePickerComponent.defaultProps = {
  value: null,
  id: null,
  error: false,
  readOnly: false,
  disabled: false,
  label: '',
  adornmentClasses: {},
  variant: 'inline',
  inputClasses: {},
  onClose: () => {},
  onBlur: () => {},
  onChange: () => {},
};

export default KeyboardDatePickerComponent;
