import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import GdprClausePage from 'components/pages/GdprClause';

import { onSubmit } from './store';

const GdprClause = (props) => (
  <Route
    render={() => <GdprClausePage {...props} />}
  />
);

const mapDispatchToProps = { onSubmit };

export default connect(() => ({}), mapDispatchToProps)(GdprClause);
