import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { actionTypes as globalActionTypes, reducer as Global } from 'containers/store';

// page reducers
import { reducer as Login } from 'containers/Login/store';
import { reducer as ForgotPassword } from 'containers/ForgotPassword/store';
import { reducer as ResetPassword } from 'containers/ResetPassword/store';
import { reducer as Users } from 'containers/Users/store';
import { reducer as Organizations } from 'containers/Organizations/store';
import { reducer as Questions } from 'containers/Questions/store';
import { reducer as UserAddEdit } from 'containers/UserAddEdit/store';
import { reducer as ParticipantEdit } from 'containers/ParticipantEdit/store';
import { reducer as QuestionAddEdit } from 'containers/QuestionAddEdit/store';
import { reducer as OrganizationAddEdit } from 'containers/OrganizationAddEdit/store';
import { reducer as SurveyCreator } from 'containers/SurveyCreator/store';
import { reducer as ResearchToolScales } from 'containers/ResearchToolAddEdit/ScalesList/store';
import { reducer as Surveys } from 'containers/Surveys/store';
import { reducer as Answers } from 'containers/Answers/store';
import { reducer as AnswersAddEdit } from 'containers/AnswersAddEdit/store';
import { reducer as Batteries } from 'containers/Batteries/store';
import { reducer as BatteriesAddEdit } from 'containers/BatteriesAddEdit/store';
import { reducer as SurveysResults } from 'containers/SurveysResults/store';
import { reducer as SurveyResults } from 'containers/SurveyResults/store';
import { reducer as PreviewSurvey } from 'containers/PreviewSurvey/store';
import { reducer as SurveyAnalysis } from 'containers/SurveyAnalysis/store';
import { reducer as ResearchTools } from 'containers/ResearchTools/store';
import { reducer as ScaleEdit } from 'containers/ScaleEdit/store';
import { reducer as EvaluationAddEdit } from 'containers/EvaluationsAddEdit/store';
import { reducer as ResearchToolAddEdit } from 'containers/ResearchToolAddEdit/store';
import { reducer as ResearchToolOptions } from 'containers/ResearchToolAddEdit/ToolOptions/store';
import { reducer as ResearchToolParameters } from 'containers/ResearchToolAddEdit/Parameters/store';
import { reducer as ResearchToolResults } from 'containers/ResearchToolAddEdit/ResultsEvaluation/store';
import { reducer as StandardScales } from 'containers/StandardScales/store';
import { reducer as StandardScalesAddEdit } from 'containers/StandardScalesAddEdit/store';
import { reducer as ReferenceGroupSets } from 'containers/ReferenceGroupSets/store';
import { reducer as RefGroupsAddEdit } from 'containers/ReferenceGroupsAddEdit/store';
import { reducer as Patients } from 'containers/Patients/store';
import { reducer as Facility } from 'containers/Facility/store';
import { reducer as FacilityPurchaseHistory } from 'containers/Facility/PurchaseHistory/store';
import { reducer as FacilityAddEdit } from 'containers/FacilityAddEdit/store';
import { reducer as UserProfile } from 'containers/Profile/UserProfile/store';
import { reducer as Replacements } from 'containers/Replacements/store';
import { reducer as ReplacementAdd } from 'containers/ReplacementAdd/store';
import { reducer as UserProfileEdit } from 'containers/ProfileEdit/store';
import { reducer as PatientAddEdit } from 'containers/PatientAddEdit/store';
import { reducer as PurchasePackageSummary } from 'containers/PurchasePackageSummary/store';
import { reducer as ToolDescription } from 'containers/ToolsDetails/ToolDescription/store';
import { reducer as HistoryOfUse } from 'containers/ToolsDetails/HistoryOfUse/store';
import { reducer as ToolsDetails } from 'containers/ToolsDetails/store';
import { reducer as PatientFile } from 'containers/PatientView/PatientFile/store';
import { reducer as PatientExamination } from 'containers/PatientView/PatientExamination/store';
import { reducer as FileList } from 'containers/PatientView/FileList/store';
import { reducer as Tools } from 'containers/Tools/store';
import { reducer as ExaminationDetails } from 'containers/ExaminationDetails/store';
import { reducer as ExaminationDetailsPatientData } from 'containers/ExaminationDetails/PatientData/store';
import { reducer as Tutorials } from 'containers/Tutorials/store';
import { reducer as Diagnosticians } from 'containers/Diagnosticians/store';
import { reducer as ChangeUserPassword } from 'containers/UserPasswordChange/store';
import { reducer as PrivateMessage } from 'containers/PrivateMessage/store';
import { reducer as FacilityList } from 'containers/FacilityList/store';
import { reducer as ResearchStart } from 'containers/ResearchStart/store';
import { reducer as ResearchStartedOnTablet } from 'containers/ResearchStartedOnTablet/store';
import { reducer as ResearchSelect } from 'containers/ResearchSelect/store';
import { reducer as SubscriptionAdd } from 'containers/SubscriptionAdd/store';
import { reducer as DiagnosticianAddEdit } from 'containers/DiagnosticianAddEdit/store';
import { reducer as Diagnostician } from 'containers/Diagnostician/Profile/store';
import { reducer as VideoPlayer } from 'containers/VideoPlayer/store';
import { reducer as VideoTutorials } from 'containers/VideoTutorials/store';
import { reducer as VideoTutorialView } from 'containers/VideoTutorialView/store';
import { reducer as VideoTutorialAddEdit } from 'containers/VideoTutorialAddEdit/store';
import { reducer as Statistics } from 'containers/Statistics/store';
import { reducer as PurchasePackageApplication } from 'containers/PurchasePackageApplication/store';
import { reducer as FileAddEdit } from 'containers/FileAddEdit/store';
import { reducer as FacilityBatteriesEdit } from 'containers/FacilityBatteriesEdit/store';
import { reducer as PackagesEdit } from 'containers/PackagesEdit/store';
import { reducer as CreditPointsHistory } from 'containers/Facility/CreditPointsHistory/store';
import { reducer as TopUpAccount } from 'containers/TopUpAccount/store';
import { reducer as FacilitySettingsEdit } from 'containers/FacilitySettingsEdit/store';
import { reducer as VatInvoiceAddEdit } from 'containers/VatInvoiceAddEdit/store';
import { reducer as GroupExaminationDetails } from 'containers/GroupExaminationDetails/store';
import { reducer as GroupSurveyConfiguration } from 'containers/GroupSurveyAddEdit/store';
import { reducer as GroupSurveys } from 'containers/GroupSurveys/store';
import { reducer as LoginConfirm } from 'containers/LoginConfirm/store';
import { reducer as LoginToken } from 'containers/LoginToken/store';
import { reducer as GroupExaminationSelect } from 'containers/GroupExaminationSelect/store';

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  Global,
  Login,
  ForgotPassword,
  ResetPassword,
  Users,
  Organizations,
  Questions,
  UserAddEdit,
  ParticipantEdit,
  OrganizationAddEdit,
  QuestionAddEdit,
  SurveyCreator,
  Surveys,
  Answers,
  AnswersAddEdit,
  Batteries,
  BatteriesAddEdit,
  SurveysResults,
  SurveyResults,
  SurveyAnalysis,
  PreviewSurvey,
  ResearchTools,
  ScaleEdit,
  EvaluationAddEdit,
  ResearchToolAddEdit,
  ResearchToolOptions,
  ResearchToolParameters,
  ResearchToolScales,
  ResearchToolResults,
  StandardScales,
  StandardScalesAddEdit,
  ReferenceGroupSets,
  RefGroupsAddEdit,
  Patients,
  Facility,
  FacilityPurchaseHistory,
  FacilityAddEdit,
  UserProfile,
  Replacements,
  ReplacementAdd,
  UserProfileEdit,
  PatientAddEdit,
  PurchasePackageSummary,
  ToolDescription,
  HistoryOfUse,
  PatientFile,
  PatientExamination,
  FileList,
  Tools,
  ToolsDetails,
  Diagnosticians,
  ChangeUserPassword,
  PrivateMessage,
  FacilityList,
  ResearchStart,
  ResearchStartedOnTablet,
  ResearchSelect,
  ExaminationDetails,
  ExaminationDetailsPatientData,
  SubscriptionAdd,
  DiagnosticianAddEdit,
  Diagnostician,
  Tutorials,
  VideoPlayer,
  VideoTutorials,
  VideoTutorialView,
  VideoTutorialAddEdit,
  Statistics,
  PurchasePackageApplication,
  FileAddEdit,
  FacilityBatteriesEdit,
  PackagesEdit,
  CreditPointsHistory,
  TopUpAccount,
  FacilitySettingsEdit,
  VatInvoiceAddEdit,
  GroupExaminationDetails,
  GroupSurveyConfiguration,
  GroupSurveys,
  LoginConfirm,
  LoginToken,
  GroupExaminationSelect,
});

export const rootReducer = (history) => (state, action) => {
  let newState = { ...state };

  if (action.type === globalActionTypes.CLEAR_DATA) {
    // clear a bit of store
    newState = {};
  }

  return appReducer(history)(newState, action);
};
