import React from 'react';
import DiagnosticianName from 'components/pages/DiagnosticianList/components/DiagnosticianName';

export const getDiagnosticianName = (name, role, status) => (
  <DiagnosticianName
    name={name}
    role={role}
    status={status}
  />
);
