import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import noop from 'utils/noop';

import useStyles from './TooltipIcon.styles';

const propTypes = {
  message: PropTypes.string.isRequired,
  muiIcon: PropTypes.node.isRequired,
  customTooltipBackgroundColor: PropTypes.string,
  customTooltipTextColor: PropTypes.string,
  forWallet: PropTypes.bool,
  isCollapsedMenu: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placement: PropTypes.string,
  size: PropTypes.string,
  onIconClick: PropTypes.func,
};

const defaultProps = {
  isDisabled: false,
  placement: 'top',
  onIconClick: noop,
  size: 'small',
  customTooltipBackgroundColor: '',
  customTooltipTextColor: '#FFF',
  forWallet: false,
  isCollapsedMenu: false,
};

const TooltipIcon = ({
  placement,
  isDisabled,
  muiIcon,
  message,
  size,
  onIconClick,
  customTooltipBackgroundColor,
  customTooltipTextColor,
  forWallet,
  isCollapsedMenu,
}) => {
  const classes = useStyles({ customTooltipBackgroundColor, customTooltipTextColor });
  const tooltipClasses = {
    tooltip: classNames(classes.tooltip, {
      [classes.customTooltipBackgroundColor]: customTooltipBackgroundColor,
    }),
    popper: classes.popper,
    arrow: classes.arrow,
  };
  const customClasses = {};

  if (forWallet) {
    tooltipClasses.popper = classNames(classes.walletPopper, {
      [classes.menuCollapsedTooltip]: !isCollapsedMenu,
    });
    tooltipClasses.arrow = classNames(classes.arrow, {
      [classes.walletArrow]: isCollapsedMenu,
    });
    customClasses.root = classes.forWallet;
  }

  return (
    <>
      <Tooltip
        arrow
        classes={tooltipClasses}
        title={message}
        placement={placement}
      >
        <span>
          <IconButton
            disabled={isDisabled}
            onClick={onIconClick}
            size={size}
            classes={customClasses}
          >
            {muiIcon}
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

TooltipIcon.propTypes = propTypes;

TooltipIcon.defaultProps = defaultProps;

export default TooltipIcon;
