import { useState } from 'react';

const useAnchor = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseClick = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    isOpen: Boolean(anchorEl),
    onOpenClick,
    onCloseClick,
  };
};

export { useAnchor };
