import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import _merge from 'lodash/merge';

import { playerConfig } from 'utils/constants/playerConfig';

const CustomPlayer = ({
  url,
  controls,
  config,
  ...restProps
}) => (
  <ReactPlayer
    url={url}
    controls={controls}
    onContextMenu={(e) => e.preventDefault()}
    config={_merge(playerConfig, config)}
    {...restProps}
  />
);

CustomPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  config: PropTypes.objectOf(PropTypes.any),
  controls: PropTypes.bool,
};

CustomPlayer.defaultProps = {
  controls: true,
  config: {},
};

export default CustomPlayer;
