import { defineMessages } from 'react-intl';

const messages = defineMessages({
  isTrue: 'Pole nie może być puste',
});

export default (value) => {
  const result = {};

  result.isValid = value;
  result.message = messages.isTrue;

  return result;
};
