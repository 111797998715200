import React from 'react';
import PropTypes from 'prop-types';

import privacyPolicy from './components/privacyPolicy';
import termsOfUse from './components/termsOfUse';

import useStyle from './PolicyContainer.styles';

const PolicyContainer = ({
  type,
}) => {
  const classes = useStyle();
  const Policy = {
    privacyPolicy,
    termsOfUse,
  }[type];

  return (
    <div className={classes.wrapper}>
      <Policy />
    </div>
  );
};

PolicyContainer.propTypes = {
  type: PropTypes.oneOf(['privacyPolicy', 'termsOfUse']).isRequired,
};

export default PolicyContainer;
