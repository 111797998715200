import { defineMessages } from 'react-intl';
import _reduce from 'lodash/reduce';

const messages = defineMessages({
  isPesel: 'Niepoprawny nr pesel',
});

const INDEX_WEIGHT = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];

const isPesel = (value) => {
  const result = {};
  const checksum = parseInt(value.substring(10, 11));

  const sum = _reduce(INDEX_WEIGHT, (acc, item, i) => (
    acc + parseInt(value.substring(i, i + 1)) * item
  ), 0) % 10;

  result.isValid = (10 - sum) % 10 === checksum;
  result.message = messages.isPesel;

  return result;
};

export default isPesel;
