import React from 'react';

import europeanFoundations from 'assets/img/europeanFoundations.svg';
import europeanUnion from 'assets/img/europeanUnion.svg';
import nationalCentreResearchAndDevelopment from 'assets/img/nationalCentreResearchAndDevelopment.svg';
import republicOfPoland from 'assets/img/republicOfPoland.svg';

import useStyle from './Founders.styles';

const Founders = () => {
  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <img src={europeanFoundations} alt="europeanFoundations" />
      <img
        className={classes.republicOfPolandImage}
        src={republicOfPoland}
        alt="republicOfPoland"
      />
      <img
        className={classes.nationalCentreImage}
        src={nationalCentreResearchAndDevelopment}
        alt="nationalCentreResearchAndDevelopment"
      />
      <img
        className={classes.europeanUnionImage}
        src={europeanUnion}
        alt="europeanUnion"
      />
    </div>
  );
};

export default Founders;
