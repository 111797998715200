import { goBack, replace } from 'connected-react-router';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _filter from 'lodash/filter';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import createURLWithQuery from 'utils/createURLWithQuery';
import formatFullName from 'utils/formatFullName';
import errorCatch from 'utils/errorCatch';
import roles, { diagnosticianRoles } from 'utils/constants/rolesOfUsers';
import specializationTypes from 'utils/constants/specializationTypes';
import PromiseAll from 'utils/PromiseAll';

import {
  showLoader,
  hideLoader,
  showSnackbar,
  showTransparentLoader,
  setDrawerVisibility,
  loadUserData,
} from 'containers/store';

import { getClinicTypes } from 'containers/Facility/store';

export const initialState = {
  isLoadedPage: false,
  isCoordinatorFieldDisabled: false,
  initialValues: {
    id: '',
    facilityName: '',
    facilityType: '',
    email: '',
    city: '',
    postalCode: '',
    street: '',
    number: '',
    'coordinator-0': {},
  },
  suggestions: [],
  facilityID: null,
  currentCoordinatorID: null,
  facilityTypes: [],
};

const mapInitialValues = (data) => {
  const initialValues = {
    id: data.id || '',
    facilityName: data.name || '',
    facilityType: data.clinic_type_id || '',
    email: data.email || '',
    city: data.city || '',
    postalCode: data.postal_code || '',
    street: data.street || '',
    number: data.number || '',
    anonymousClients: data.anonymous_patients_can_be_assigned,
  };
  _forEach(data.users, (user, index) => {
    initialValues[`coordinator-${index}`] = {
      id: user.id,
      name: formatFullName(user),
    };
  });

  return initialValues;
};

const mapSuggestions = (items) => _filter(items,
  (suggestion) => suggestion.specialization.name !== specializationTypes.STUDENT)
  .map((el) => ({
    id: el.id,
    name: `${el.first_name} ${el.last_name}`,
  }));

const mapFacilityTypes = (types) => types.map((type) => ({
  id: type.id,
  name: type.name,
}));

const getUserIds = (values) => {
  const userIds = _map(
    [
      values['coordinator-0'],
      values['coordinator-1'],
      values['coordinator-2'],
    ],
    (coordinator) => {
      if (!coordinator) {
        return null;
      }
      return coordinator.id;
    },
  );

  return _compact(userIds);
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'FACILITY_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'FACILITY_EDIT/CLEAR_STORE',
  SET_SUGGESTIONS: 'FACILITY_EDIT/SET_SUGGESTIONS',
  CLEAR_SUGGESTIONS: 'FACILITY_EDIT/CLEAR_SUGGESTIONS',
  LOAD_PAGE_WITH_COORDINATOR: 'FACILITY_EDIT/LOAD_PAGE_WITH_COORDINATOR',
  LOAD_INITIAL_VALUES: 'FACILITY_EDIT/LOAD_INITIAL_VALUES',
  SET_FACILITY_TYPES: 'SET_CLINIC_TYPES',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const { admin } = roles;
      const userRole = _get(action, 'userData.role_id', null);
      const isAdmin = userRole === admin;

      const isCoordinatorFieldDisabled = !isAdmin
      && !_isNil(_get(action, 'responses.users[0].standard_scale_id', null));

      return {
        ...state,
        isLoadedPage: true,
        initialValues: action.responses
          ? mapInitialValues(action.responses)
          : initialState.initialValues,
        facilityID: action.facilityID,
        isCoordinatorFieldDisabled,
        currentCoordinatorID: _get(action, 'responses.users[0].id', null),
      };
    }

    case actionTypes.LOAD_PAGE_WITH_COORDINATOR: {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          'coordinator-0': action.newCoordinatorData,
        },
      };
    }

    case actionTypes.LOAD_INITIAL_VALUES: {
      return {
        ...state,
        initialValues: action.values,
      };
    }

    case actionTypes.SET_SUGGESTIONS: {
      return {
        ...state,
        suggestions: mapSuggestions(action.response.data.items),
      };
    }

    case actionTypes.CLEAR_SUGGESTIONS: {
      return {
        ...state,
        suggestions: [],
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_FACILITY_TYPES: {
      return {
        ...state,
        facilityTypes: mapFacilityTypes(action.values),
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, facilityID, userData) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  facilityID,
  userData,
});

const loadPageWithCoordinator = (newCoordinatorData) => ({
  type: actionTypes.LOAD_PAGE_WITH_COORDINATOR,
  newCoordinatorData,
});

const loadInitialValues = (values) => ({
  type: actionTypes.LOAD_INITIAL_VALUES,
  values,
});

export const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setSuggestions = (response) => ({
  type: actionTypes.SET_SUGGESTIONS,
  response,
});

export const onClear = () => ({
  type: actionTypes.CLEAR_SUGGESTIONS,
});

const loadFacilityTypes = (values) => ({
  type: actionTypes.SET_FACILITY_TYPES,
  values,
});

const getUsers = (findUser, getDiagnosticians) => (dispatch, getStore) => {
  const { facilityID } = getStore().FacilityAddEdit;
  const params = {
    findUser,
    findUserByRole: getDiagnosticians ? diagnosticianRoles : roles.clinicCoordinator,
    orderBy: 'first_name',
    sortedBy: 'asc',
  };

  if (getDiagnosticians) {
    params.findUserByClinic = facilityID;
  }

  const url = createURLWithQuery('users', params);

  return ApiManager.request('get', dispatch, url);
};

const getClinic = (clinicID) => (dispatch) => {
  const params = {
    with_clinic_coordinator: 1,
  };

  const url = createURLWithQuery(`clinics/${clinicID}`, params);

  return ApiManager.request('get', dispatch, url);
};

export const onCoordinatorAdd = (values) => (dispatch) => {
  dispatch(loadInitialValues(values));
  dispatch(replace('/userAddEdit', { newClinicCoordinator: true }));
};

export const onSubmit = (values, { setFieldError }) => async (dispatch) => {
  const url = values.id ? `clinics/${values.id}` : 'clinics';
  const method = values.id ? 'put' : 'post';
  const message = values.id ? snackbarMessages.facilityEdited : snackbarMessages.facilityAdded;

  const data = {
    name: values.facilityName,
    clinic_type_id: values.facilityType,
    email: values.email,
    city: values.city,
    postal_code: values.postalCode,
    street: values.street,
    number: values.number,
    user_ids: getUserIds(values),
    anonymous_patients_can_be_assigned: values.anonymousClients,
  };

  dispatch(showTransparentLoader());

  try {
    await ApiManager.request(method, dispatch, url, data);
    await dispatch(loadUserData());

    dispatch(goBack());
    dispatch(showSnackbar(message));
    dispatch(hideLoader());
  } catch (error) {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.facilityAddEdit);
      let errorMessage = error.error.errors.user_ids ? 'removeCoordinatorSecurity' : 'wrongData';
      errorMessage = error.error.errors.clinic_type_id ? 'cannotRemoveCollege' : errorMessage;
      dispatch(showSnackbar(snackbarMessages[errorMessage]));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

export const onFetch = (value, values = {}) => async (dispatch) => {
  dispatch(showTransparentLoader());

  const hasMoreCoordinators = (
    !_isEmpty(values['coordinator-0'])
    || !_isEmpty(values['coordinator-1'])
    || !_isEmpty(values['coordinator-2'])
  );

  try {
    const suggestions = await dispatch(getUsers(value, hasMoreCoordinators));
    dispatch(setSuggestions(suggestions));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

const loadFacilityData = (clinicId, userData) => async (dispatch) => {
  try {
    PromiseAll({
      clinicData: dispatch(getClinic(clinicId)),
      clinicTypes: dispatch(getClinicTypes()),
    }).then((responses) => {
      dispatch(loadFacilityTypes(responses.clinicTypes.data));
      dispatch(loadPageSuccess({
        ...responses.clinicData.data, clinicTypes: responses.clinicTypes.data,
      }, clinicId, userData));

      dispatch(hideLoader());
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

const loadAddPage = () => async (dispatch) => {
  const clinicTypes = await dispatch(getClinicTypes());

  dispatch(loadFacilityTypes(clinicTypes.data));
  dispatch(loadPageSuccess());
  dispatch(hideLoader());
};

const loadAddPageWithNewCoordinator = (newCoordinatorData) => async (dispatch) => {
  const clinicTypes = await dispatch(getClinicTypes());

  dispatch(loadFacilityTypes(clinicTypes.data));
  dispatch(loadPageWithCoordinator(newCoordinatorData));
  dispatch(hideLoader());
};

export const loadPageData = (routeState, userData, message) => async (dispatch) => {
  if (!routeState.fromNewCoordinatorForm) {
    dispatch(clearStore());
  }
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  if (routeState.id) {
    dispatch(loadFacilityData(routeState.id, userData));
  } else if (routeState.idFromList) {
    dispatch(loadFacilityData(routeState.idFromList, userData));
  } else if (routeState.fromNewCoordinatorForm) {
    dispatch(loadAddPageWithNewCoordinator(routeState.newCoordinatorData));
  } else {
    dispatch(loadAddPage());
  }
};
