import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import _get from 'lodash/get';

import FacilitySettingsEditView from 'components/pages/FacilitySettingsEdit';
import { initialValuesShape } from 'components/pages/FacilitySettingsEdit/FacilitySettingsEdit.shapes';

import {
  onSubmit,
  onCancel,
  loadPageData,
} from './store';

class FacilitySettingsEditContainer extends React.Component {
  static propTypes = {
    financialSettings: initialValuesShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    modalProps: PropTypes.objectOf(PropTypes.any).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { withActivationClinic } = this.props.modalProps;

    this.props.loadPageData(this.props.financialSettings, withActivationClinic);
  }

  render() {
    return this.props.isLoadedPage ? (
      <FacilitySettingsEditView {...this.props} />
    ) : null;
  }
}

const mapDispatchToProps = {
  onSubmit,
  onCancel,
  loadPageData,
};

const mapStateToProps = (state) => ({
  ...state.FacilitySettingsEdit,
  financialSettings: _get(state, 'Facility.financialSettings', {}),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FacilitySettingsEditContainer);
