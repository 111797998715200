export default (theme) => ({
  formSpacing: {
    marginTop: theme.spacing(6),
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1.5, 3, 1.5, 3)} !important`,
    '& > .MuiFormControl-root .MuiFormLabel-root': {
      overflow: 'hidden',
      minHeight: 20,
      textOverflow: 'ellipsis',
      '&.Mui-focused, &.MuiFormLabel-filled': {
        width: '108%',
      },
    },
    '& > .MuiFormControl-root > .MuiFormControl-root': {
      whiteSpace: 'nowrap',
      '& > .MuiFormLabel-root': {
        width: '90%',
      },
    },
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2.5),
  },
  checkboxField: {
    display: 'flex',

    '&.MuiGrid-item': {
      padding: theme.spacing(0.625),
    },
  },
  checkbox: {
    marginRight: 0,
    marginLeft: theme.spacing(1.25),
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: '20px',
  },
  checkboxRoot: {
    padding: theme.spacing(0.5),
  },
});
