import { defineMessages } from 'react-intl';

export const STATUSES = {
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  CONFIRMED: 'CONFIRMED',
};

const messages = defineMessages({
  processing: 'przetwarzane',
  purchased: 'zakupione',
  canceled: 'anulowane',
  confirmed: 'zakończone',
});

export const getStatus = (status, intl) => {
  const {
    PROCESSING,
    PROCESSED,
    COMPLETED,
    CANCELED,
    CONFIRMED,
  } = STATUSES;

  switch (status) {
    case PROCESSING:
    case PROCESSED:
      return intl.formatMessage(messages.processing);
    case COMPLETED:
      return intl.formatMessage(messages.purchased);
    case CANCELED:
      return intl.formatMessage(messages.canceled);
    case CONFIRMED:
      return intl.formatMessage(messages.confirmed);
    default: return '';
  }
};
