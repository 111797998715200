import _forEach from 'lodash/forEach';
import moment from 'moment';

const parseDatesFieldsToUrl = (values, dateFields) => {
  const parsedFields = { ...values };

  _forEach(dateFields, (el) => {
    if (parsedFields[el]) {
      const momentDate = moment(parsedFields[el], 'YYYY-MM-DD');
      const date = momentDate.isValid() ? momentDate : null;

      parsedFields[el] = date ? date.format('YYYY-MM-DD') : null;
    }
  });

  return parsedFields;
};

const parseDatesFieldsFromUrl = (values, dateFields) => {
  const parsedValues = { ...values };

  _forEach(dateFields, (el) => {
    if (parsedValues[el]) {
      parsedValues[el] = moment(parsedValues[el], 'YYYY-MM-DD').isValid()
        ? moment(parsedValues[el]) : null;
    }
  });

  return parsedValues;
};

export { parseDatesFieldsToUrl, parseDatesFieldsFromUrl };
