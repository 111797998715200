import ApiManager from 'utils/ApiManager';
import { push, replace } from 'connected-react-router';

import _isEmpty from 'lodash/isEmpty';

import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import dialogTexts from 'utils/dialogTexts';

import {
  hideLoader,
  showSnackbar,
  showLoader,
  showTransparentLoader,
  openDialog,
  setPage,
  setRowsPerPage,
  setSortingData,
  setLastFilterValues,
  setSortingAndPagination,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
  types: [],
  groups: [],
  sets: [],
};

const defaultSorting = {
  fieldName: 'contents',
  sortDirection: 'asc',
};

const sortFieldsMapping = {
  contents: 'plain_name',
  set: 'answer_sets|answer_sets.name',
  type: 'question_types|question_types.name',
  group: 'question_groups|question_groups.name',
};

const mapSurveys = (surveys) => surveys.map((el) => ({
  id: el.id,
  label: el.name,
}));

const mapQuestions = (questions, startingPosition) => questions.map((el, key) => {
  let surveys = [];

  if (el.surveys && !_isEmpty(el.surveys)) {
    surveys = {
      firstSurvey: el.surveys[0],
      items: mapSurveys(el.surveys),
    };
  }

  return {
    id: key + 1 + startingPosition,
    apiId: el.id,
    content: el.name,
    group: el.question_group_id,
    type: el.question_type_id,
    set: el.answer_set_id,
    imageUrl: el.image || null,
    surveys,
  };
});

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'QUESTIONS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_QUESTIONS: 'QUESTIONS/SET_FILTERED_QUESTIONS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapQuestions(action.responses.questions.data.items, action.startingPosition),
        types: action.responses.questionsTypes.data.items,
        groups: action.responses.questionsGroups.data.items,
        sets: action.responses.answerSets.data.items,
        totalItemsCount: action.responses.questions.data.total,
      };
    }

    case actionTypes.SET_FILTERED_QUESTIONS: {
      return {
        ...state,
        items: mapQuestions(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  startingPosition,
});

const setFilteredQuestions = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_QUESTIONS,
  response,
  startCountFrom,
});

const loadQuestions = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: sortFieldsMapping[defaultSorting.fieldName],
  };

  if (params.lastFilterValues.search) {
    data.find = params.lastFilterValues.search;
  }

  if (params.lastFilterValues.group) {
    data.findByQuestionGroup = params.lastFilterValues.group;
  }

  if (params.lastFilterValues.type) {
    data.findByQuestionType = params.lastFilterValues.type;
  }

  if (params.lastFilterValues.set) {
    data.findByAnswerSet = params.lastFilterValues.set;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('questions', data);

  dispatch(replace(url));

  return ApiManager.request('get', dispatch, url);
};

const loadQuestionsTypes = () => (dispatch) => {
  const url = createURLWithQuery('question_types', {
    sortedBy: 'asc',
    orderBy: 'name',
  });

  return ApiManager.request('get', dispatch, url);
};

const loadQuestionsGroups = () => (dispatch) => {
  const url = createURLWithQuery('question_groups', {
    sortedBy: 'asc',
    orderBy: 'name',
  });

  return ApiManager.request('get', dispatch, url);
};

const loadAnswerSets = () => (dispatch) => {
  const url = createURLWithQuery('answer_sets', {
    sortedBy: 'asc',
    orderBy: 'name',
  });

  return ApiManager.request('get', dispatch, url);
};

const getQuestions = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadQuestions(data)).then((response) => {
    dispatch(setFilteredQuestions(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getQuestions());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getQuestions());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getQuestions());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getQuestions());
};

const onDeleteAccept = (id) => (dispatch, getStore) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `questions/${id}`).then(() => {
    const data = getSortAndPageData(getStore, sortFieldsMapping);
    const startCountFrom = getCountStartFrom(getStore);

    dispatch(loadQuestions(data)).then((response) => {
      dispatch(setFilteredQuestions(response, startCountFrom));
      dispatch(showSnackbar(snackbarMessages.questionDeleted));
      dispatch(hideLoader());
    }).catch((err) => {
      if (isBadRequest(err)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteQuestion,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const onAddClick = () => (dispatch) => {
  dispatch(push('/questionAddEdit'));
};

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/questionAddEdit', { id }));
};

export const loadPageData = (values) => (dispatch, getStore) => {
  dispatch(showLoader());

  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values) {
    dispatch(setLastFilterValues({
      search: values.find || '',
      group: values.findByQuestionGroup || '',
      type: values.findByQuestionType || '',
      set: values.findByAnswerSet || '',
    }));
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  PromiseAll({
    questions: dispatch(loadQuestions(data)),
    questionsTypes: dispatch(loadQuestionsTypes()),
    questionsGroups: dispatch(loadQuestionsGroups()),
    answerSets: dispatch(loadAnswerSets()),
  }).then((responses) => {
    dispatch(loadPageSuccess(responses, startingPosition));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
