import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';

import InputToolbar from '../InputToolbar';
import useStyle from './RichTextInput.styles';
import './RichTextInput.styles.css';

const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large', 'huge'];
Quill.register(Size, true);

const formats = [
  'size',
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'align',
  'color', 'background',
];

const RichTextInput = ({
  disabled,
  name,
  placeholder,
  value,
  onBlur,
  onChange,
  idSuffix,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [outsideClickDisabled, setOutsideClickDisabled] = useState(true);
  const ref = useRef(null);
  const classes = useStyle();
  const modules = {
    toolbar: {
      container: `#quill-container-${name}-${idSuffix}`,
    },
    clipboard: {
      matchVisual: false,
    },
  };

  useEffect(() => {
    if (!value && ref) {
      ref.current.getEditor().setText('\n');
    }
  }, [value]);

  return (
    <div
      className={classes.container}
    >
      <OutsideClickHandler
        disabled={outsideClickDisabled}
        onOutsideClick={() => {
          setOutsideClickDisabled(true);
          if (!disabled) {
            onChange(name, currentValue);
            onBlur(name, true);
          }
        }}
      >
        <InputToolbar
          name={name}
          disabled={disabled}
          idSuffix={idSuffix}
        />
        <ReactQuill
          ref={ref}
          className="outlined-textarea"
          id={`quill-${name}-${idSuffix}`}
          theme="snow"
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          defaultValue={value}
          readOnly={disabled}
          onFocus={() => {
            setOutsideClickDisabled(false);
          }}
          onBlur={() => { onBlur(name, true); }}
          onChange={(textHTML, delta, source, editor) => {
            if (source === 'user') {
              const newValue = editor.getLength() > 1 ? textHTML : '';
              setCurrentValue(newValue);
              if (newValue) {
                onChange(name, newValue);
              }
            }
          }}
        />
      </OutsideClickHandler>
    </div>
  );
};

RichTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  idSuffix: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string,
};

RichTextInput.defaultProps = {
  disabled: false,
  idSuffix: '',
  value: '',
  placeholder: '',
};

export default RichTextInput;
