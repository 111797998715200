export default (theme) => ({
  customCell: {
    wordBreak: 'break-all',
  },
  truncateText: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  defaultCell: {
    fontSize: 16,
    padding: theme.spacing(1.875, 1.6),
    paddingLeft: 0,
    borderTop: 'none',
    borderBottom: `1px solid ${theme.palette.tableBorderColor}`,
    '&:last-child': {
      paddingRight: 0,
    },
    color: ({ isGreyedOut }) => (
      isGreyedOut ? theme.palette.deepBlue[50] : theme.palette.text.primary
    ),
  },
  middleVerticalAlign: {
    verticalAlign: 'middle',
  },
});
