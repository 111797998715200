import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';

import FileIcon from 'components/FileIcon';
import { ReactComponent as RestoreIcon } from 'assets/img/restore.svg';

import { fileDataShape } from './FileInfo.shapes';
import useStyle from './FileInfo.styles';
import messages from './FileInfo.messages';

const FileInfo = ({
  onUnload, fileData, onDownload, hideUnload, hideDownload,
}) => {
  const classes = useStyle();

  const {
    fileName,
    addedBy,
    date,
  } = fileData;

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.actionsWrapper}>
          {!hideUnload && (
            <IconButton
              size="small"
              onClick={onUnload}
              focusVisibleClassName={classes.focus}
            >
              <RestoreIcon />
            </IconButton>
          )}
          {!hideDownload && (
          <IconButton
            size="small"
            onClick={onDownload}
            focusVisibleClassName={classes.focus}
          >
            <GetAppIcon />
          </IconButton>
          )}
        </div>
        <div className={classes.fileNameWrapper}>
          <FileIcon fileName={fileName} />
          <Typography variant="body1" component="span" className={classes.fileName}>
            {fileName}
          </Typography>
        </div>
      </div>
      <div className={classes.additionalInfoWrapper}>
        <span>
          <Typography variant="body2" component="span" className={classes.infoLabel}>
            <FormattedMessage {...messages.addedBy} />
          </Typography>
          <Typography variant="body2" component="span">
            {addedBy}
          </Typography>
        </span>
        <span>
          <Typography variant="body2" component="span" className={classes.infoLabel}>
            <FormattedMessage {...messages.date} />
          </Typography>
          <Typography variant="body2" component="span">
            {date}
          </Typography>
        </span>
      </div>
    </>
  );
};

FileInfo.propTypes = {
  fileData: fileDataShape.isRequired,
  onDownload: PropTypes.func.isRequired,
  onUnload: PropTypes.func.isRequired,
  hideDownload: PropTypes.bool,
  hideUnload: PropTypes.bool,
};

FileInfo.defaultProps = {
  hideDownload: false,
  hideUnload: false,
};

export default FileInfo;
