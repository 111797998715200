export default {
  usersAddEdit: {
    first_name: 'name',
    last_name: 'surname',
    email: 'email',
    role_id: 'role',
    status: 'status',
  },
  questionAddEdit: {
    name: 'content',
    question_type_id: 'type',
    question_group_id: 'group',
    answer_set_id: 'set',
    code: 'code',
  },
  organizationAddEdit: {
    name: 'name',
    city: 'city',
    number: 'number',
    postal_code: 'postalCode',
    consent: 'consent',
    organization_type_id: 'type',
    organization_status_id: 'status',
  },
  answersAddEdit: {
    label: 'label',
    answer_set_id: 'answerSet',
  },
  batteriesAddEdit: {
    name: 'battery',
    survey_tool_id: 'researchTool',
  },
  participantEdit: {
    first_name: 'name',
    last_name: 'surname',
    user_city: 'city',
    organization_id: 'organization',
    user_sex: 'userSex',
    user_birth_month: 'birthMonth',
    user_birth_year: 'birthYear',
    user_birth_day: 'birthDay',
  },
  surveyCreator: {
    question_type_id: 'type',
    question_group_id: 'group',
    name: 'content',
    answer_set_id: 'answerSet',
    min_session_time: 'minTime',
    average_session_time: 'averageTime',
    max_time: 'maxTime',
    remote_examination_max_time: 'maxRemoteTime',
  },
  scaleEdit: {
    name: 'name',
    description: 'description',
    code: 'code',
  },
  toolsAddEdit: {
    reference_group_set_id: 'refGroup',
    survey_id: 'survey',
    standard_categorical_scale_id: 'standardScale',
    standardScalesAddEdit: {},
  },
  refGroupsAddEdit: {
    criteria_sex: 'genderCriteria',
    criteria_age: 'ageCriteria',
    reference_groups: 'referenceGroups',
  },
  researchToolOptions: {
    difficulty: 'difficulty',
    discrimination: 'discrimination',
    bottom_asymptote: 'bottomAsymptote',
    top_asymptote: 'topAsymptote',
    residual_variance: 'residualVariance',
    expected_value: 'expectedValue',
    empirical_variance: 'empiricalVariance',
    load: 'charge',
    covariance: 'covariance',
  },
  replacementAdd: {
    replaced_user_id: 'substitutePerson',
    replacing_user_id: 'personToSubstitute',
    from: 'from',
    to: 'to',
  },
  facilityAddEdit: {
    name: 'facilityName',
    email: 'email',
    city: 'city',
    postal_code: 'postalCode',
    street: 'street',
    number: 'number',
  },
  userProfileEdit: {
    email: 'email',
    first_name: 'name',
    last_name: 'surname',
    specialization_id: 'specialization',
  },
  patientAddEdit: {
    first_name: 'name',
    last_name: 'surname',
    birth_date: 'birthDate',
    pesel: 'nationality',
    birthplace: 'birthPlace',
    address_street: 'streetAndNumber',
    address_postal_code: 'postalCode',
    address_city: 'city',
    school_name: 'schoolName',
    school_address: 'schoolAddress',
    school_class: 'class',
    school_class_section: 'department',
  },
  changeUserPassword: {
    current_password: 'oldPassword',
    password: 'newPassword',
  },
  setUserPassword: {
    password: 'password',
  },
  subscriptionAdd: {
    tokens_count: 'subscriptionCount',
    survey_tool_id: 'tool',
    clinic_id: 'clinic',
  },
  diagnosticianAddEdit: {
    first_name: 'name',
    last_name: 'surname',
    specialization: 'specialization',
    email: 'email',
  },
  privateMessage: {
    message: 'text',
  },
  videoTutorialAddEdit: {
    title: 'name',
    description: 'description',
    status: 'status',
    thumbnail: 'thumbnail',
    video: 'video',
  },
  evaluationAddEdit: {
    description: 'description',
  },
  facilitySettingsEdit: {
    monthly_subscription_cost: 'monthlyCost',
    subscription_start_date: 'startDate',
    billing_period: 'billingCycle',
    transitional_period: 'transitionalPeriod',
    number_of_leased_tablets: 'numberOfTablets',
    end_date_of_tablet_lease: 'endDate',
    unlimited_usage: 'unlimitedUsage',
    show_confidence_interval: 'showConfidenceInterval',
  },
};
