import { defineMessages } from 'react-intl';
import moment from 'moment';

const messages = defineMessages({
  invalidDate: 'Niepoprawna data',
});

export default (date) => {
  const result = {};

  result.isValid = moment(date).isValid();
  result.message = messages.invalidDate;

  return result;
};
