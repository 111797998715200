import { defineMessages } from 'react-intl';
import regexesValidation from 'utils/validator/regexesValidation';

const messages = defineMessages({
  fieldCanContainOnlyNumber: 'To pole powinno zawierać tylko liczby',
});

export default (value) => {
  const result = {};

  const regexp = regexesValidation.isNumberNoZero;

  result.isValid = !value || !!value.toString().match(regexp);
  result.message = messages.fieldCanContainOnlyNumber;

  return result;
};
