/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import { classesShape } from 'utils/shapes/classesShape';
import { intlShape } from 'utils/shapes/intlShape';

import messages from './InputToolbar.messages';
import inputToolbarStyles from './InputToolbar.styles';

const colors = [
  'rgb(  0,   0,   0)', 'rgb(230,   0,   0)', 'rgb(255, 153,   0)',
  'rgb(255, 255,   0)', 'rgb(  0, 138,   0)', 'rgb(  0, 102, 204)',
  'rgb(153,  51, 255)', 'rgb(255, 255, 255)', 'rgb(250, 204, 204)',
  'rgb(255, 235, 204)', 'rgb(255, 255, 204)', 'rgb(204, 232, 204)',
  'rgb(204, 224, 245)', 'rgb(235, 214, 255)', 'rgb(187, 187, 187)',
  'rgb(240, 102, 102)', 'rgb(255, 194, 102)', 'rgb(255, 255, 102)',
  'rgb(102, 185, 102)', 'rgb(102, 163, 224)', 'rgb(194, 133, 255)',
  'rgb(136, 136, 136)', 'rgb(161,   0,   0)', 'rgb(178, 107,   0)',
  'rgb(178, 178,   0)', 'rgb(  0,  97,   0)', 'rgb(  0,  71, 178)',
  'rgb(107,  36, 178)', 'rgb( 68,  68,  68)', 'rgb( 92,   0,   0)',
  'rgb(102,  61,   0)', 'rgb(102, 102,   0)', 'rgb(  0,  55,   0)',
  'rgb(  0,  41, 102)', 'rgb( 61,  20,  10)',
].map((color) => (<option key={color} value={color} />));

const InputToolbar = ({
  intl,
  name,
  classes,
  disabled,
  idSuffix,
}) => (
  <div
    className={classNames(
      'input-toolbar',
      `input-toolbar-${name}`,
      classes.container,
      { [classes.disabled]: disabled },
    )}
    id={`quill-container-${name}-${idSuffix}`}
  >
    <select className="ql-size" defaultValue="extra-small">
      <option value="extra-small">
        {intl.formatMessage(messages.sizeExtraSmall)}
      </option>
      <option value="small">
        {intl.formatMessage(messages.sizeSmall)}
      </option>
      <option value="medium">
        {intl.formatMessage(messages.sizeMedium)}
      </option>
      <option value="large">
        {intl.formatMessage(messages.sizeLarge)}
      </option>
      <option value="huge">
        {intl.formatMessage(messages.sizeHuge)}
      </option>
    </select>
    <button type="button" className="ql-bold" />
    <button type="button" className="ql-italic" />
    <button type="button" className="ql-underline" />
    <select className="ql-color">
      {colors}
    </select>
    <select className="ql-background" />
    <button type="button" className="ql-list" value="ordered" />
    <button type="button" className="ql-list" value="bullet" />
    <button type="button" className="ql-clean" />
    <span className="ql-formats">
      <button type="button" className="ql-align" value="" />
      <button type="button" className="ql-align" value="center" />
      <button type="button" className="ql-align" value="right" />
      <button type="button" className="ql-align" value="justify" />
    </span>
  </div>
);

InputToolbar.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  classes: classesShape,
  disabled: PropTypes.bool,
  idSuffix: PropTypes.string,
};

InputToolbar.defaultProps = {
  classes: {},
  disabled: false,
  idSuffix: '',
};

export default withStyles(inputToolbarStyles)(injectIntl(InputToolbar));
