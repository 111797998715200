import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { injectIntl } from 'react-intl';

import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';

import OutlinedCounterInput from 'components/OutlinedCounterInput';
import OutlinedTextarea from 'components/OutlinedTextarea';
import SummaryButtons from 'components/SummaryButtons';
import Modal from 'components/Modal';
import BannerContainer from 'components/BannerContainer';
import Section from 'components/Section';
import FormField from 'components/FormField';
import AmountInput from 'components/AmountInput';

import validator from 'utils/validator/core';
import { classesShape } from 'utils/shapes/classesShape';
import { intlShape } from 'utils/shapes/intlShape';

import topUpAmountModalImage from 'assets/img/topUpAccount.svg';

import { getMessages, getValidators } from './TopUpAccountModal.utils';
import { initialValuesShape } from './TopUpAccountModal.shapes';
import styles from './TopUpAccountModal.styles';
import messages from './TopUpAccountModal.messages';

const propTypes = {
  classes: classesShape.isRequired,
  initialValues: initialValuesShape.isRequired,
  intl: intlShape.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  pointsAmount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const TopUpAccountModal = ({
  classes,
  initialValues,
  intl,
  isEditMode,
  type,
  pointsAmount,
  onCancel,
  onSubmit,
}) => {
  const messagesGroup = getMessages(intl, messages);
  const {
    nonNegativeNumber,
    noDecimalPlaces,
    notEmpty,
    maximumAddedPoints,
    nameLength,
    maxAmountInputValue,
  } = getValidators();

  const validators = [
    {
      fieldName: 'topUpAmount',
      validators: [notEmpty, nonNegativeNumber, maxAmountInputValue],
    },
    {
      fieldName: 'addedPoints',
      validators: [nonNegativeNumber, noDecimalPlaces, notEmpty, maximumAddedPoints],
    },
    {
      fieldName: 'substantiation',
      validators: [notEmpty],
    },
    {
      fieldName: 'positionName',
      validators: [nameLength],
    },
  ];
  const { allPoints } = initialValues;
  const initialPointsAmount = isEditMode
    ? parseInt(pointsAmount) + parseInt(allPoints)
    : initialValues.balanceAfterRecharge;
  const [pointsBalance, changePointsBalance] = useState(initialPointsAmount);
  const textAreaPlaceholder = isEditMode ? intl.formatMessage(messages.textAreaPlaceholder) : '';

  const onAddedPointsChange = (addedPointsAmount) => {
    const addedPointsAmountParsed = addedPointsAmount === '' ? 0 : parseInt(addedPointsAmount);

    const newBalance = pointsAmount + addedPointsAmountParsed;
    changePointsBalance(newBalance);
  };

  return (
    <Modal
      isOpen={!!type}
      onRequestClose={isEditMode ? null : onCancel}
      onClickCloseIcon={onCancel}
    >
      <CardContent>
        <BannerContainer addPaddings logo={topUpAmountModalImage}>
          <Section title={messagesGroup.title}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={
                (values) => validator(
                  values,
                  validators,
                  intl.formatMessage,
                )
              }
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form className={classes.formSpacing}>
                  <Grid container>
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <AmountInput
                          variant="outlined"
                          customLabel={messagesGroup.topUpAmount}
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          touched={touched}
                          name="topUpAmount"
                          fullWidth
                          value={values.topUpAmount}
                          disabled={!isEditMode}
                          isBigValueDark
                          maxDecimalPlaces={2}
                        />
                      </Grid>
                      <Grid item md={6} xs={12} className={classes.disableVerticalPadding}>
                        <OutlinedCounterInput
                          onBlur={setFieldTouched}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                            onAddedPointsChange(value);
                          }}
                          isBigValueDark
                          disabled={!isEditMode}
                          errors={errors}
                          touched={touched}
                          name="addedPoints"
                          label={messagesGroup.addedPoints}
                          value={values.addedPoints}
                          withoutCounterButton
                        />
                      </Grid>
                      <Grid item md={6} xs={12} className={classes.disableVerticalPadding}>
                        <OutlinedCounterInput
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          touched={touched}
                          isBigValueDark
                          name="pointsAmount"
                          label={messagesGroup.pointsAmount}
                          value={pointsBalance}
                          withoutCounterButton
                          disabled
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.name}>
                        <FormField
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          touched={touched}
                          name="positionName"
                          label={intl.formatMessage(messages.positionName)}
                          fullWidth
                        >
                          <Input
                            value={values.positionName}
                            margin="none"
                            disabled={!isEditMode}
                          />
                        </FormField>
                      </Grid>
                      <Grid item md={12} className={classes.substantiation}>
                        <Typography className={classes.label} variant="h5">
                          {messagesGroup.substantiation}
                        </Typography>
                        <OutlinedTextarea
                          rows={4}
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          isBigValueDark
                          touched={touched}
                          name="substantiation"
                          placeholder={textAreaPlaceholder}
                          disabled={!isEditMode}
                          value={values.substantiation}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    isEditMode && (
                      <SummaryButtons
                        isSubmitDisabled={!values.topUpAmount && !values.addedPoints}
                        cancelMessage={messagesGroup.cancel}
                        submitMessage={messagesGroup.save}
                        onCancel={onCancel}
                      />
                    )
                  }
                </Form>
              )}
            </Formik>
          </Section>
        </BannerContainer>
      </CardContent>
    </Modal>
  );
};

TopUpAccountModal.propTypes = propTypes;

export default withStyles(styles)(injectIntl(TopUpAccountModal));
