import _get from 'lodash/get';

import formatPatientData from 'utils/formatPatientData';
import errorCatch from 'utils/errorCatch';
import ApiManager from 'utils/ApiManager';
import formatNotes from 'utils/formatNotes';

import {
  hideLoader, showTransparentLoader, showLoader,
} from 'containers/store';

import {
  formatShortData,
  formatNewNoteData,
} from './utils';

export const initialState = {
  isLoadedPatientData: false,
  isPatientAnonymized: false,
  showConfidenceInterval: false,
  patientData: {
    id: '',
    name: '',
    surname: '',
    birthDate: '',
    birthPlace: '',
    email: '',
    pesel: '',
    nationality: '',
    streetAndNumber: '',
    city: '',
    postalCode: '',
    schoolName: '',
    schoolAddress: '',
    class: '',
    department: '',
  },
  patientFileData: {
    id: '',
    name: '',
    surname: '',
    birthDate: '',
    birthPlace: '',
    pesel: '',
    nationality: '',
    streetAndNumber: '',
    city: '',
    postalCode: '',
    schoolName: '',
    schoolAddress: '',
    class: '',
    department: '',
  },
  shortData: {
    patientName: '',
    ageNow: {
      years: '',
      months: '',
    },
    ageThen: {
      years: '',
      months: '',
    },
    diagnosticianNote: '',
  },
  newNoteData: {
    noteNumber: null,
    date: '',
    diagnostician: '',
  },
  notes: [],
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'EXAMINATION_DETAILS/PATIENT_DATA/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'EXAMINATION_DETAILS/PATIENT_DATA/CLEAR_STORE',
  UPDATE_NOTES_DATA: 'EXAMINATION_DETAILS/PATIENT_DATA/UPDATE_NOTES_DATA',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.LOAD_PAGE_SUCCESS: {
      const { userData, sessionData, clinicData } = action;

      const shortData = formatShortData(
        action.patientData.data,
        sessionData,
      );

      const patientData = formatPatientData(
        action.patientData.data,
        true,
      );

      const patientFileData = formatPatientData(
        action.patientData.data,
        true,
        true,
      );

      const notes = formatNotes(sessionData);

      const newNoteData = formatNewNoteData(
        sessionData,
        userData,
      );

      return {
        ...state,
        shortData,
        patientData,
        patientFileData,
        notes,
        newNoteData,
        isPatientAnonymized: !!_get(action, 'patientData.data.deleted_at', false),
        isLoadedPatientData: true,
        showConfidenceInterval: clinicData.data.show_confidence_interval,
      };
    }

    case actionTypes.UPDATE_NOTES_DATA: {
      const notes = formatNotes(
        action.sessionData.data,
      );

      const newNoteData = formatNewNoteData(
        action.sessionData.data,
        action.userData,
      );

      return {
        ...state,
        notes,
        newNoteData,
      };
    }

    default: {
      return state;
    }
  }
};

const loadPageSuccess = (patientData, userData, sessionData, clinicData) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  patientData,
  sessionData,
  userData,
  clinicData,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const getPatientData = (id) => (dispatch) => {
  const url = `patients/${id}`;

  return ApiManager.request('get', dispatch, url);
};

const getClinicData = (id) => (dispatch) => {
  const url = `clinics/${id}`;

  return ApiManager.request('get', dispatch, url);
};

const getSessionData = (id) => (dispatch) => {
  const url = `session_patients/${id}`;

  return ApiManager.request('get', dispatch, url);
};

const updateNotesData = (userData, sessionData) => ({
  type: actionTypes.UPDATE_NOTES_DATA,
  userData,
  sessionData,
});

const reloadNotesData = () => (dispatch, getStore) => {
  const { userData } = getStore().Global;
  const { sessionPatientId } = getStore().ExaminationDetails.initialData;

  dispatch(getSessionData(sessionPatientId)).then((sessionData) => {
    dispatch(updateNotesData(userData, sessionData));
    dispatch(hideLoader());
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};

export const onNoteSave = (values) => (dispatch, getStore) => {
  const { sessionPatientId } = getStore().ExaminationDetails.initialData;
  const { note } = values;
  const url = `session_patients/${sessionPatientId}/notes`;

  dispatch(showTransparentLoader());
  return ApiManager.request('post', dispatch, url, { note }).then(() => dispatch(reloadNotesData()))
    .catch((error) => {
      errorCatch(error, dispatch);
    });
};

export const loadPageData = (patientId) => (dispatch, getStore) => {
  dispatch(clearStore());
  dispatch(showLoader());
  const { userData } = getStore().Global;

  const { sessionData } = getStore().ExaminationDetails.initialData;

  dispatch(getPatientData(patientId)).then((patientData) => {
    dispatch(getClinicData(patientData.data.clinic_id)).then((clinicData) => {
      dispatch(loadPageSuccess(patientData, userData, sessionData, clinicData));
      dispatch(hideLoader());
    }).catch((error) => {
      errorCatch(error, dispatch);
    });
  }).catch((err) => {
    errorCatch(err, dispatch, true, true);
  });
};
