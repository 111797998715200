import { goBack } from 'connected-react-router';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import getFirstString from 'utils/getFirstString';
import config from 'config';

import {
  hideLoader,
  showSnackbar,
} from 'containers/store';

const errorCatch = (
  err,
  dispatch,
  withGoBack = false,
  withHideLoader = true,
  snackbarOptions = {},
) => {
  const {
    showMessageFromApi = false,
    snackbarType = config.snackbarTypes.info,
    snackbarAutohide = true,
  } = snackbarOptions;
  if (isBadRequest(err)) {
    dispatch(showSnackbar(
      showMessageFromApi
        ? getFirstString(err.error.errors)
        : snackbarMessages.wrongData,
      showMessageFromApi,
      snackbarType,
      snackbarAutohide,
    ));
  } else {
    dispatch(showSnackbar(snackbarMessages.globalError));
  }

  if (withGoBack) {
    dispatch(goBack());
  }

  if (withHideLoader) {
    dispatch(hideLoader());
  }
};

export default errorCatch;
