import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import _values from 'lodash/values';
import _includes from 'lodash/includes';

import { withStyles } from '@material-ui/core/styles';
import people from 'assets/img/loginimage.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import logo from 'assets/img/diagmaticLogoBlack.svg';

import config from 'config';

import { classesShape } from 'utils/shapes/classesShape';
import { intlShape } from 'utils/shapes/intlShape';
import Founders from 'components/Founders';
import { hideSnackbar as hideSnackbarCallback } from 'containers/store';
import unauthorizedLayoutStyles from './UnauthorizedLayout.styles';
import { resolveSnackbarMessage } from './UnauthorizedLayout.utils';

const UnauthorizedLayout = ({
  children, classes, loaderVisible, transparentLoaderVisible,
  hideSnackbar, snackbarVisible, snackbarMessage, intl, snackbarType,
}) => {
  const isMovedToTop = !_includes(['/login', '/forgotPassword'], window.location.pathname);

  return (
    <>
      {
      loaderVisible || transparentLoaderVisible ? (
        <div className={classNames({
          [classes.loaderWrapper]: true,
          [classes.transparentLoaderWrapper]: transparentLoaderVisible,
        })}
        >
          {
            transparentLoaderVisible ? (
              <div className={classes.transparentLoaderBg} />
            ) : null
          }
          <div className={classes.progressWrapper}>
            <CircularProgress color="primary" />
          </div>
        </div>
      ) : null
    }
      <div className={classNames({
        [classes.wrapper]: true,
        [classes.contentWrapperWithLoader]: loaderVisible,
      })}
      >
        <div className={classes.leftSide}>
          <div className={
            classNames(classes.leftSideContent,
              { [classes.movedToTop]: isMovedToTop })
            }
          >
            <div className={classes.loginForm}>
              <div className={classes.logoWrapper}>
                <img src={logo} className={classes.logo} alt="diagmatic" />
              </div>
              {children}
            </div>
            <Founders />
          </div>
        </div>
        <div className={classes.rightSite}>
          <img className={classes.image} src={people} alt="people" />
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarVisible}
        onClose={hideSnackbar}
        message={resolveSnackbarMessage(snackbarMessage, intl)}
        ContentProps={{
          classes: {
            root: classNames({
              [classes.errorSnackbar]: snackbarType === config.snackbarTypes.error,
            }),
          },
        }}
      />
    </>
  );
};

UnauthorizedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  hideSnackbar: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loaderVisible: PropTypes.bool.isRequired,
  snackbarVisible: PropTypes.bool.isRequired,
  transparentLoaderVisible: PropTypes.bool.isRequired,
  classes: classesShape,
  snackbarMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  snackbarType: PropTypes.oneOf(_values(config.snackbarTypes)),
};

UnauthorizedLayout.defaultProps = {
  classes: {},
  snackbarMessage: null,
  snackbarType: config.snackbarTypes.info,
};

const mapDispatchToProps = {
  hideSnackbar: hideSnackbarCallback,
};

const mapStateToProps = (state) => ({
  ...state.Global,
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(unauthorizedLayoutStyles)(injectIntl(UnauthorizedLayout)),
);
