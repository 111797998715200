import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  fieldCannotBeEmpty: 'Użytkownik musi być przypisany do placówki',
});

export default (organizations) => {
  const isValid = !_isEmpty(organizations)
  && _some(organizations, (el) => !_isEmpty(el.organization));

  const result = {
    isValid,
    message: messages.fieldCannotBeEmpty,
  };

  return result;
};
