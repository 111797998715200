import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import _get from 'lodash/get';

import FacilityBatteriesEditView from 'components/pages/FacilityBatteriesEdit';
import { facilityBatteryShape } from 'components/pages/FacilityBatteries/FacilityBatteries.shapes';

import {
  onSubmit,
  onCancel,
  loadPageData,
} from './store';

class FacilityBatteriesEditContainer extends React.Component {
  static propTypes = {
    isLoadedPage: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(facilityBatteryShape).isRequired,
    loadPageData: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.loadPageData(this.props.items);
  }

  render() {
    return this.props.isLoadedPage ? (
      <FacilityBatteriesEditView {...this.props} />
    ) : null;
  }
}

const mapDispatchToProps = {
  onSubmit,
  onCancel,
  loadPageData,
};

const mapStateToProps = (state) => ({
  ...state.FacilityBatteriesEdit,
  items: _get(state, 'Facility.values.batteries', []),
  isTryAndBuy: _get(state, 'Facility.values.isTryAndBuy', false),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FacilityBatteriesEditContainer);
