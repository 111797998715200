const routesToRedirect = {
  activateBattery: 'activateBattery',
  topUp: 'topUp',
};

export default (redirectTo, batteryId) => {
  switch (redirectTo) {
    case routesToRedirect.activateBattery: {
      const data = {
        isActive: false,
        batteryId,
        fromLoginPage: true,
      };

      return ['/purchasePackageSummary', data];
    }

    case routesToRedirect.topUp: {
      const data = {
        isActive: true,
        fromLoginPage: true,
      };

      return ['/purchasePackageSummary', data];
    }

    default:
      return [];
  }
};
