import _replace from 'lodash/replace';

import config from 'config';

const convertFloat = {
  toApiFormat: (value) => parseFloat(_replace(value, ',', '.')),
  toFrontFormat: (value) => _replace(value.toFixed(config.rounding), '.', ','),
};

export default convertFloat;
