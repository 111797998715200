import { replace } from 'connected-react-router';

import _includes from 'lodash/includes';
import _isNumber from 'lodash/isNumber';
import _get from 'lodash/get';

import {
  showTransparentLoader,
  hideSnackbar,
  setUserData,
  getMeta,
  loadGlobalData,
  setRowsPerPageOptions,
  setModal,
  hideLoader,
  showSnackbar,
} from 'containers/store';
import snackbarMessages from 'utils/snackbarMessages';
import errorCatch from 'utils/errorCatch';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import getHomeRouteByRole from 'utils/getHomeRouteByRole';
import getRouteByQuery from 'utils/getRouteByQuery';
import createURLWithQuery from 'utils/createURLWithQuery';
import { diagnosticianRoles } from 'utils/constants/rolesOfUsers';
import { LACK_OF_FUND } from 'utils/constants/modalTypes';

import config from 'config';

export const initialState = {};
export const actionTypes = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const loginToken = (token) => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  dispatch(hideSnackbar());

  const requestBody = {
    token,
  };

  try {
    const userData = await ApiManager.request('post', dispatch, 'login/token', requestBody);
    localStorage.setItem(config.localStorageLoginKey, JSON.stringify(userData.data.id));

    const data = await PromiseAll({
      meta: dispatch(getMeta()),
      userWithClinics: ApiManager.request('get', dispatch, createURLWithQuery('current_user', { with_clinic: 'clinics' })),
      userWithOrganizations: ApiManager.request('get', dispatch, `users/${userData.data.id}`),
    });

    const hasScaleSelected = _isNumber(userData.data.standard_scale_id);

    const userInfo = {
      ...userData.data,
      clinics: [
        ...data.userWithClinics.data.clinics,
      ],
      organizations: [
        ...data.userWithOrganizations.data.organizations,
      ],
    };

    dispatch(setUserData(userInfo, data.meta.data.userRole));
    dispatch(setRowsPerPageOptions(data.meta));
    dispatch(loadGlobalData());
    dispatch(hideLoader());
    const userRole = userInfo.role_id;
    const transitionalPeriodDaysLeft = _get(userInfo, 'clinics[0].transitional_period_days_left', null);
    const unlimitedUsage = _get(userInfo, 'clinics[0].unlimited_usage', false);
    if (_includes(diagnosticianRoles, userRole) && !userInfo.gdpr_agreed) {
      dispatch(replace('/gdprClause'));
    } else if (_includes(diagnosticianRoles, userRole) && !hasScaleSelected) {
      dispatch(replace('/userProfile', { firstLogin: true }));
    } else {
      const { redirectTo, batteryId } = _get(getStore(), 'router.location.query');
      if (redirectTo) {
        dispatch(replace(...getRouteByQuery(redirectTo, batteryId)));
      } else {
        dispatch(replace(getHomeRouteByRole(userInfo.role_id), { fromLoginPage: true }));
      }

      if (
        _includes(diagnosticianRoles, userRole) && transitionalPeriodDaysLeft && !unlimitedUsage
      ) {
        dispatch(setModal(LACK_OF_FUND));
      }
    }
  } catch (error) {
    if (_get(error, 'error.errors.token')) {
      dispatch(showSnackbar(snackbarMessages.tokenExpired, false, config.snackbarTypes.error));
      dispatch(hideLoader());
      return;
    }

    const snackbarOptions = {
      showMessageFromApi: true,
      snackbarType: config.snackbarTypes.error,
      snackbarAutohide: false,
    };
    errorCatch(error, dispatch, false, true, snackbarOptions);
  }
};
