import PropTypes from 'prop-types';

import { tableFilterValuesShape } from 'utils/shapes/tableFilterValuesShape';

const headShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  class: PropTypes.string,
  sortable: PropTypes.bool,
  filterable: PropTypes.bool,
  filterValues: tableFilterValuesShape,
});

export { headShape };
