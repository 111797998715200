import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  tooltip: {
    ...theme.tooltip,
    marginBottom: theme.spacing(1),
  },
  customTooltipBackgroundColor: {
    backgroundColor: ({ customTooltipBackgroundColor }) => customTooltipBackgroundColor,
  },
  arrow: {
    color: ({ customTooltipTextColor }) => customTooltipTextColor,
    '&:before': {
      backgroundColor: ({ customTooltipBackgroundColor }) => customTooltipBackgroundColor,
    },
  },
  tooltipArrow: {
    color: theme.palette.tooltip.backgroundColor2,
  },
  forWallet: {
    cursor: 'default',
    paddingTop: 0,
    paddingLeft: 0,
  },
  walletPopper: {
    left: '-10px !important',
  },
  walletArrow: {
    left: '10px !important',
  },
  menuCollapsedTooltip: {
    left: '31px !important',
    top: '-2px !important',
  },
}));
