import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';

import {
  showSnackbar,
  setModal,
  setPreviousMenuItem,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  isSubmitting: false,
  topics: [],
  initialValues: {
    topic: '',
    text: '',
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'PRIVATE_MESSAGE/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'PRIVATE_MESSAGE/CLEAR_STORE',
  SET_IS_SUBMITTING: 'PRIVATE_MESSAGE/SET_IS_SUBMITTING',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        topics: action.response.data,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_IS_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.value,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (response) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  response,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setSubmittingForm = (value) => ({
  type: actionTypes.SET_IS_SUBMITTING,
  value,
});

export const onSubmit = (values, { setFieldError }) => async (dispatch) => {
  dispatch(setSubmittingForm(true));
  const data = {
    message_subject_id: values.topic,
    message: values.text,
  };

  try {
    await ApiManager.request('post', dispatch, 'messages/send', data);
    dispatch(setModal());
    dispatch(showSnackbar(snackbarMessages.messageSend));
    dispatch(setSubmittingForm(false));
    dispatch(setPreviousMenuItem());
  } catch (error) {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.privateMessage);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(setSubmittingForm(false));
  }
};

export const onCancel = () => (dispatch) => {
  dispatch(setPreviousMenuItem());
  dispatch(clearStore());
  dispatch(setModal());
};

const loadTopics = () => (dispatch) => ApiManager.request('get', dispatch, 'message_subjects');

export const loadPageData = () => async (dispatch) => {
  dispatch(clearStore());

  const response = await dispatch(loadTopics());
  dispatch(loadPageSuccess(response));
};
