import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronRight from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ProtectedByPermissions from 'components/ProtectedByPermissions';
import useStyle from 'components/CustomTable/CustomTable.styles';

import messages from './Actions.messages';

const Actions = ({
  isEditable, isDeletable, isViewable, isRedirect, onDelete, row, onEdit, onView,
  onRedirect, isCustomAction, onCustomActionClick, customActionComponent,
  permissionsDelete, permissionsEdit, permissionsView, permissionsRedirect, redirectDisabled,
}) => {
  const customTableClasses = useStyle();

  return (
    <>
      {isViewable && (
        <ProtectedByPermissions requiredPermission={permissionsView}>
          <Tooltip title={<FormattedMessage {...messages.view} />} placement="top">
            <IconButton
              size="small"
              onClick={() => onView(row.id)}
              focusVisibleClassName={customTableClasses.focus}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </ProtectedByPermissions>
      )}
      {isEditable && (
        <ProtectedByPermissions requiredPermission={permissionsEdit}>
          <Tooltip title={<FormattedMessage {...messages.edit} />} placement="top">
            <IconButton
              size="small"
              onClick={() => onEdit(row.id)}
              focusVisibleClassName={customTableClasses.focus}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </ProtectedByPermissions>

      )}
      {isRedirect && (
        <ProtectedByPermissions requiredPermission={permissionsRedirect}>
          <IconButton
            size="small"
            className={customTableClasses.chevron}
            onClick={() => onRedirect(row.apiId)}
            focusVisibleClassName={customTableClasses.focus}
            disabled={redirectDisabled(row)}
          >
            <ChevronRight />
          </IconButton>
        </ProtectedByPermissions>
      )}
      {isCustomAction && customActionComponent({
        row,
        onCustomActionClick,
      })}
      {isDeletable && (
        <ProtectedByPermissions requiredPermission={permissionsDelete}>
          <Tooltip title={<FormattedMessage {...messages.delete} />} placement="top">
            <IconButton
              size="small"
              onClick={() => onDelete(row.id)}
              focusVisibleClassName={customTableClasses.focus}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ProtectedByPermissions>
      )}
    </>
  );
};

Actions.propTypes = {
  permissionsDelete: PropTypes.arrayOf(PropTypes.bool).isRequired,
  permissionsEdit: PropTypes.arrayOf(PropTypes.bool).isRequired,
  permissionsRedirect: PropTypes.arrayOf(PropTypes.bool).isRequired,
  permissionsView: PropTypes.arrayOf(PropTypes.bool).isRequired,
  row: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  customActionComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isCustomAction: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isEditable: PropTypes.bool,
  isRedirect: PropTypes.bool,
  isViewable: PropTypes.bool,
  redirectDisabled: PropTypes.func,
  onCustomActionClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onRedirect: PropTypes.func,
  onView: PropTypes.func,
};

Actions.defaultProps = {
  isDeletable: false,
  isEditable: false,
  isRedirect: false,
  isCustomAction: false,
  isViewable: false,
  redirectDisabled: () => false,
  onDelete: () => {},
  onEdit: () => {},
  onView: () => {},
  onRedirect: () => {},
  onCustomActionClick: () => {},
  customActionComponent: null,
};

export default Actions;
