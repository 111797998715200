import _forEach from 'lodash/forEach';
import _isArray from 'lodash/isArray';

const createURLWithQuery = (url, data, stringify) => {
  const ret = [];

  _forEach(data, (value, key) => {
    const finalValue = stringify ? JSON.stringify(value) : value;

    if (data[key] !== undefined) {
      if (_isArray(data[key])) {
        _forEach(data[key], (arrayValue) => {
          ret.push(`${key}%5B%5D=${arrayValue}`);
        });
      } else {
        ret.push(`${key}=${encodeURIComponent(finalValue)}`);
      }
    }
  });

  return `${url}?${ret.join('&')}`;
};

export default createURLWithQuery;
