import _slice from 'lodash/slice';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import snackbarMessages from 'utils/snackbarMessages';
import config from 'config';
import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import errorCatch from 'utils/errorCatch';
import getDateFromApi from 'utils/getDateFromApi';
import formatCodeWithName from 'utils/formatCodeWithName';
import moveLastElementToFirst from 'utils/moveLastElementToFirst';

import {
  showLoader,
  hideLoader,
  showTransparentLoader,
  showSnackbar,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
  batteryName: '',
  selectedOrders: [],
  isOpenOrdersModal: false,
  isOpenConfirmModal: false,
  isActive: false,
  manual: {},
  creditPoints: 0,
  netValue: 0,
  vatRate: 0,
  grossValue: 0,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'PURCHASE_PACKAGE_SUMMARY/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'PURCHASE_PACKAGE_SUMMARY/CLEAR_STORE',
  ADD_PRODUCT: 'PURCHASE_PACKAGE_SUMMARY/ADD_PRODUCT',
  CLOSE_MODAL: 'PURCHASE_PACKAGE_SUMMARY/CLOSE_MODAL',
  CONFIRM_PURCHASE: 'PURCHASE_PACKAGE_SUMMARY/CONFIRM_PURCHASE',
  ADD_MANUAL_TO_ORDER: 'PURCHASE_PACKAGE_SUMMARY/ADD_MANUAL_TO_ORDER',
};

const mapPackages = (items) => {
  const sortedPackages = moveLastElementToFirst(items);

  return _map(
    _slice(sortedPackages, 0, config.maxOfPackages),
    (el) => ({
      id: el.id,
      grossValue: el.gross_charge_amount,
      amount: el.amount_of_tokens,
      netValue: el.net_charge_amount,
      vatRate: el.vat_rate,
      creditPoints: el.tokens_extra_credit,
      name: el.name,
    }),
  );
};

const setManualItems = (items) => ({
  date: getDateFromApi(items.created_at),
  version: items.version,
  name: items.name,
  netAmount: items.net_value,
  vatRate: items.vat_rate,
  price: items.gross_value,
  id: items.id,
  isManual: true,
});

const getBatteryData = (battery) => battery && ({
  netValue: battery.data.starter_pack_price_net_value,
  creditPoints: battery.data.extra_credit_points,
  vatRate: battery.data.starter_pack_price_vat_rate,
  grossValue: battery.data.starter_pack_price,
  batteryName: formatCodeWithName(battery.data),
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapPackages(action.response.packages.data.items),
        manual: action.response.manual && setManualItems(action.response.manual.data[0]),
        batteryId: action.response.batteryId,
        isActive: action.response.isActive,
        clinics: action.response.currentUser.data.clinics,
        ...getBatteryData(action.response.battery),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.ADD_PRODUCT: {
      const starterPack = [{
        id: 0,
        netValue: state.netValue,
        vatRate: state.vatRate,
        grossValue: state.grossValue,
        isPackage: true,
        isActive: state.isActive,
      }, {
        id: 1,
        creditPoints: state.creditPoints,
        isActive: state.isActive,
      }];

      const creditsPack = [{ ...state.items[action.id], isActive: state.isActive }];

      return {
        ...state,
        isOpenOrdersModal: true,
        selectedOrders: state.isActive ? creditsPack : starterPack,
      };
    }

    case actionTypes.CONFIRM_PURCHASE: {
      return {
        ...state,
        isOpenOrdersModal: false,
        isOpenConfirmModal: true,
      };
    }

    case actionTypes.CLOSE_MODAL: {
      return {
        ...state,
        isOpenOrdersModal: false,
        isOpenConfirmModal: false,
        selectedOrders: [],
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (response) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  response,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onPurchase = (id) => ({
  type: actionTypes.ADD_PRODUCT,
  id: id ? id - 1 : null,
});

export const onClose = () => ({
  type: actionTypes.CLOSE_MODAL,
});

const confirmPurchase = () => ({
  type: actionTypes.CONFIRM_PURCHASE,
});

export const onSubmit = (packageId) => async (dispatch, getStore) => {
  const store = getStore().PurchasePackageSummary;
  const clinics = store.clinics;

  if (_isEmpty(clinics)) {
    dispatch(showSnackbar(snackbarMessages.wrondClinicId));
  } else {
    const data = {
      clinic_id: clinics[0].id,
      battery_id: store.batteryId,
      package_id: packageId,
      type: store.isActive ? 'AUTO_RECHARGE' : 'ACTIVATION',
    };

    dispatch(showTransparentLoader());

    try {
      await ApiManager.request('post', dispatch, 'orders', data);
      dispatch(confirmPurchase());
    } catch (error) {
      errorCatch(error, dispatch, false, false);
    } finally {
      dispatch(hideLoader());
    }
  }
};

const loadPackagesData = () => (dispatch) => {
  const params = {
    orderBy: 'tokens_extra_credit',
    sortedBy: 'asc',
  };

  const url = createURLWithQuery('packages', params);

  return ApiManager.request('get', dispatch, url);
};

const loadManual = (batteryId) => (dispatch) => {
  const params = {
    battery_id: batteryId,
  };

  const url = createURLWithQuery('battery_manuals', params);

  return ApiManager.request('get', dispatch, url);
};

const loadBatteryData = (batteryId) => (dispatch) => {
  const params = {
    appendPriceValues: true,
  };

  const url = createURLWithQuery(`batteries/${batteryId}`, params);

  return ApiManager.request('get', dispatch, url);
};

const loadCurrentUser = () => (dispatch) => {
  const data = {
    with_clinic: 'clinics',
  };

  const url = createURLWithQuery('current_user', data);

  return ApiManager.request('get', dispatch, url);
};

export const loadPageData = (routeState) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());

  const batteryId = routeState.batteryId;

  try {
    const packages = await dispatch(loadPackagesData());
    const battery = batteryId && await dispatch(loadBatteryData(batteryId));
    const manual = batteryId && await dispatch(loadManual(batteryId));
    const currentUser = await dispatch(loadCurrentUser());

    dispatch(loadPageSuccess({
      packages,
      battery,
      manual,
      batteryId,
      isActive: typeof batteryId === 'undefined' || routeState.isActive,
      currentUser,
    }));
  } catch (error) {
    errorCatch(error, dispatch, false, false);
  } finally {
    dispatch(hideLoader());
  }
};
