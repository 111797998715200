import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import getParentScaleId from 'utils/getParentScaleId';

export default (scales, loads, parameters, formatScaleData) => {
  const dimensionGroups = [];
  const secondaryDimensions = [];
  const tertiaryDimensions = [];
  _forEach(scales, (scale) => {
    if (scale.level === 0) {
      dimensionGroups.push({
        id: scale.id,
        primaryDimension: formatScaleData(scale),
      });
    } else if (scale.level === 1) {
      secondaryDimensions.push({
        ...formatScaleData(scale),
        parentId: getParentScaleId(scale.id, loads, parameters),
      });
    } else if (scale.level === 2) {
      tertiaryDimensions.push({
        ...formatScaleData(scale),
        parentId: getParentScaleId(scale.id, loads, parameters),
      });
    }
  });

  return _map(dimensionGroups, (group) => ({
    ...group,
    secondaryDimensions: _map(
      _filter(
        secondaryDimensions,
        (secondaryDimension) => secondaryDimension.parentId === group.id,
      ),
      (secondaryDimension) => ({
        ...secondaryDimension,
        tertiaryDimensions:
          _filter(tertiaryDimensions,
            (tertiaryDimension) => tertiaryDimension.parentId === secondaryDimension.id),
      }),
    ),
  }));
};
