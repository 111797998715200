import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { getIn } from 'formik';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

import { classesShape } from 'utils/shapes/classesShape';
import formFieldStyles, { LABEL_LINE_HEIGHT } from './FormField.styles';

const FormField = ({
  onChange, name, label, touched, errors, children, onBlur, submitCount,
  variant, fullWidth, passErrorStatus, setSubmitting, isRTE, runBlurWithChange, staticLabel,
  formControlClassName, isDate, classes, withAlignBottom, errorFieldName, blockShrink, nowrap,
  disabled, endAdornmentWidth, bodyWidth,
}) => {
  const labelRef = useRef(null);
  const [isTwoLines, setTwoLines] = useState(false);
  const isError = (
    touched[name] || getIn(touched, name) || submitCount > 0
  ) && (
    errors[name] || errors[errorFieldName]
  );

  useEffect(() => {
    const labelHeight = _get(labelRef, 'current.clientHeight', null);
    const isHigherThanOneLine = bodyWidth && labelHeight > LABEL_LINE_HEIGHT;
    setTwoLines(isHigherThanOneLine);
  }, [bodyWidth]);

  const newChildrenProps = {
    name,
    id: name,
    onBlur: () => onBlur(name, true),
    onChange: (event) => {
      const isEvent = isDate ? event : event.target;
      const value = isDate ? event : _get(event, 'target.value', '');

      if (isEvent || isDate) {
        onChange(name, value);
      }

      if (setSubmitting) {
        setSubmitting(false);
      }

      if (runBlurWithChange) {
        onBlur(name, true);
      }
    },
  };

  if (isDate) {
    newChildrenProps.error = !!isError;
  }

  if (isDate) {
    newChildrenProps.onClose = () => onBlur(name, true);

    if (variant) {
      newChildrenProps.inputVariant = variant;
    }
  }

  if (passErrorStatus) {
    newChildrenProps.withError = !!isError;
  }

  const labelProps = {};
  if (isRTE) {
    labelProps.shrink = true;
  }

  if (blockShrink) {
    labelProps.shrink = false;
  }

  const hasValue = _get(children, 'props.value', null);

  const formControlClass = withAlignBottom ? classNames(
    classes.alignBottom, {
      [classes.bottomWithError]: isError,
      [classes.incorrectValueLabel]: isError && hasValue,
      [classes.incorrectValueLabelTwoLines]: isError && hasValue && isTwoLines,
    },
  ) : null;

  const style = endAdornmentWidth ? { maxWidth: `calc(100% - ${endAdornmentWidth}px)` } : {};

  return (
    <FormControl
      className={formControlClassName}
      error={!!isError}
      variant={variant}
      fullWidth={fullWidth}
    >
      {
        label ? (
          <InputLabel
            htmlFor={name}
            disabled={disabled}
            ref={labelRef}
            style={style}
            {...labelProps}
            className={classNames({
              [classes.nowrap]: nowrap,
              [classes.labelStatic]: staticLabel,
            })}
            classes={{
              formControl: formControlClass,
              root: blockShrink ? classes.labelOffset : null,
            }}
          >
            {label}
          </InputLabel>
        ) : null
      }
      {
        React.cloneElement(children, newChildrenProps)
      }
      {
        isError ? (
          <FormHelperText>{errors[name] || errors[errorFieldName]}</FormHelperText>
        ) : null
      }
    </FormControl>
  );
};

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  blockShrink: PropTypes.bool,
  bodyWidth: PropTypes.number,
  classes: classesShape,
  disabled: PropTypes.bool,
  endAdornmentWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorFieldName: PropTypes.string,
  formControlClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  isDate: PropTypes.bool,
  isRTE: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  nowrap: PropTypes.bool,
  passErrorStatus: PropTypes.bool,
  runBlurWithChange: PropTypes.bool,
  setSubmitting: PropTypes.func,
  staticLabel: PropTypes.bool,
  submitCount: PropTypes.number,
  variant: PropTypes.string,
  withAlignBottom: PropTypes.bool,
};

FormField.defaultProps = {
  classes: {},
  fullWidth: false,
  passErrorStatus: false,
  runBlurWithChange: false,
  setSubmitting: () => {},
  submitCount: 0,
  variant: null,
  label: null,
  endAdornmentWidth: null,
  isRTE: false,
  staticLabel: false,
  name: '',
  isDate: false,
  formControlClassName: '',
  withAlignBottom: false,
  blockShrink: undefined,
  errorFieldName: '',
  nowrap: false,
  disabled: false,
  bodyWidth: null,
};

export default withStyles(formFieldStyles)(injectIntl(FormField));
