import { push, replace } from 'connected-react-router';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import dialogTexts from 'utils/dialogTexts';

import {
  hideLoader, showSnackbar, showLoader, showTransparentLoader, setSortingAndPagination,
  openDialog, setPage, setRowsPerPage, setLastFilterValues, setSortingData,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
};

const defaultSorting = {
  fieldName: 'name',
  sortDirection: 'asc',
};

const sortFieldsMapping = {
  name: 'name',
};

const mapScales = (scales, startingPosition) => scales.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: el.name,
  description: el.description,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'STANDARD_SCALES/LOAD_PAGE_SUCCESS',
  SET_FILTERED_STANDARD_SCALES: 'STANDARD_SCALES/SET_FILTERED_STANDARD_SCALES',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapScales(action.response.data.items, action.startingPosition),
        totalItemsCount: action.response.data.total,
      };
    }

    case actionTypes.SET_FILTERED_STANDARD_SCALES: {
      return {
        ...state,
        items: mapScales(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (response, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  response,
  startingPosition,
});

const setFilteredScales = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_STANDARD_SCALES,
  response,
  startCountFrom,
});

const loadScales = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: defaultSorting.fieldName,
  };

  if (params.lastFilterValues.name) {
    data.findByName = params.lastFilterValues.name;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('standard_categorical_scales', data);
  const pageUrl = createURLWithQuery('standardScales', data);

  dispatch(replace(pageUrl));

  return ApiManager.request('get', dispatch, url);
};

const getScales = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadScales(data)).then((response) => {
    dispatch(setFilteredScales(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(hideLoader());
  });
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getScales());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getScales());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getScales());
};

const onDeleteAccept = (id) => (dispatch, getStore) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `standard_categorical_scales/${id}`).then(() => {
    const data = getSortAndPageData(getStore, sortFieldsMapping);
    const startCountFrom = getCountStartFrom(getStore);

    dispatch(loadScales(data)).then((response) => {
      dispatch(setFilteredScales(response, startCountFrom));
      dispatch(showSnackbar(snackbarMessages.standardScaleDeleted));
      dispatch(hideLoader());
    }).catch((err) => {
      if (isBadRequest(err)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }
      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      const messageFromApi = error.error.message;
      const errorMessage = messageFromApi || snackbarMessages.wrongData;

      dispatch(showSnackbar(errorMessage, !!messageFromApi));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(hideLoader());
  });
};

export const onAddClick = () => (dispatch) => {
  dispatch(push('/standardScalesAddEdit'));
};

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/standardScalesAddEdit', { id }));
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteStandardScale,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getScales());
};

export const loadPageData = (values) => (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values) {
    dispatch(setLastFilterValues({
      name: values.findByName || '',
    }));
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  dispatch(loadScales(data)).then((response) => {
    dispatch(loadPageSuccess(response, startingPosition));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(hideLoader());
  });
};
