import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import { menuShape } from 'utils/shapes/menuOptionsShapes';
import { classesShape } from 'utils/shapes/classesShape';
import DropdownMenu from 'components/DropdownMenu';
import SectionTitle from 'components/SectionTitle';

import styles from './Section.styles';

const Section = ({
  children,
  classes,
  marginTop,
  marginBottom,
  title,
  menuOptions,
  isDropdownMenu,
  onChange,
  largeMarginBottom,
  primaryHoverButton,
}) => (
  <>
    <div
      className={
        classNames(classes.titleWrapper, {
          [classes.title]: marginBottom,
          [classes.marginTop]: marginTop,
          [classes.largeMarginBottom]: largeMarginBottom,
        })
      }
    >
      <SectionTitle>
        {title}
      </SectionTitle>
      {isDropdownMenu && (
        <DropdownMenu
          options={menuOptions}
          onChange={onChange}
          primaryHoverButton={primaryHoverButton}
        />
      )}
    </div>
    {children}
  </>
);

Section.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
  classes: classesShape,
  isDropdownMenu: PropTypes.bool,
  largeMarginBottom: PropTypes.bool,
  marginBottom: PropTypes.bool,
  marginTop: PropTypes.bool,
  menuOptions: PropTypes.arrayOf(menuShape),
  primaryHoverButton: PropTypes.bool,
  onChange: PropTypes.func,
};

Section.defaultProps = {
  classes: {},
  marginTop: false,
  marginBottom: true,
  children: null,
  menuOptions: [],
  isDropdownMenu: false,
  onChange: () => {},
  largeMarginBottom: false,
  primaryHoverButton: false,
};

export default withStyles(styles)(Section);
