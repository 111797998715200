import { push, replace } from 'connected-react-router';

import config from 'config';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import getDateFromApi from 'utils/getDateFromApi';

import {
  hideLoader, showSnackbar, showLoader, showTransparentLoader, setSortingAndPagination,
  setPage, setRowsPerPage, setSortingData, downloadSurveyFile, setLastFilterValues,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
  suggestions: [],
};

const defaultSorting = {
  fieldName: 'date',
  sortDirection: 'asc',
};

const sortFieldsMapping = {
  company: 'organizations|name',
  gradeData: 'organization_unit_code',
  surveysCount: 'number_of_surveys',
  surveyName: 'surveys|name',
  date: 'created_at',
};

const mapResults = (surveys, startingPosition) => surveys.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  company: el.organization ? el.organization.name : '',
  gradeData: el.organization_unit_code,
  surveysCount: el.number_of_surveys,
  surveyName: el.survey ? el.survey.name : '',
  date: getDateFromApi(el.created_at),
}));

const mapSuggestions = (items) => items.map((el) => ({
  id: el.id,
  name: el.name,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEYS_RESULTS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_SURVEYS_RESULTS: 'SURVEYS_RESULTS/SET_FILTERED_SURVEYS_RESULTS',
  CLEAR_SUGGESTIONS: 'SURVEYS_RESULTS/CLEAR_SUGGESTIONS',
  SET_SUGGESTIONS: 'SURVEYS_RESULTS/SET_SUGGESTIONS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapResults(action.responses.surveyResults.data.items, action.startingPosition),
        totalItemsCount: action.responses.surveyResults.data.total,
      };
    }

    case actionTypes.SET_FILTERED_SURVEYS_RESULTS: {
      return {
        ...state,
        items: mapResults(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    case actionTypes.SET_SUGGESTIONS: {
      return {
        ...state,
        suggestions: mapSuggestions(action.response.data.items),
      };
    }

    case actionTypes.CLEAR_SUGGESTIONS: {
      return {
        ...state,
        suggestions: [],
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  startingPosition,
});

const setFilteredSurveysResults = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_SURVEYS_RESULTS,
  response,
  startCountFrom,
});

const setSuggestions = (response) => ({
  type: actionTypes.SET_SUGGESTIONS,
  response,
});

const loadSurveysResults = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: sortFieldsMapping[defaultSorting.fieldName],
  };

  if (params.lastFilterValues.company) {
    data.findByOrganization = params.lastFilterValues.company.id;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('sessions', data);
  const pageUrl = createURLWithQuery('surveysResults', data);

  dispatch(replace(pageUrl));

  return ApiManager.request('get', dispatch, url);
};

const getCompanies = (find) => (dispatch) => {
  const params = {
    find,
    page: 1,
    perPage: config.maxSuggestions,
  };

  const url = createURLWithQuery('organizations', params);

  return ApiManager.request('get', dispatch, url);
};

const getCompany = (id) => (dispatch) => ApiManager.request('get', dispatch, `organizations/${id}`);

const getSurveysResults = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadSurveysResults(data)).then((response) => {
    dispatch(setFilteredSurveysResults(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getSurveysResults());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getSurveysResults());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getSurveysResults());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getSurveysResults());
};

export const onFetch = (value) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(getCompanies(value)).then((response) => {
    dispatch(setSuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onClear = () => ({
  type: actionTypes.CLEAR_SUGGESTIONS,
});

export const onView = (id) => (dispatch) => {
  const url = createURLWithQuery('/surveyResults', { id });
  dispatch(push(url));
};

export const onDownload = (id) => (dispatch) => {
  dispatch(downloadSurveyFile(id));
};

export const loadPageData = (values) => async (dispatch, getStore) => {
  dispatch(showLoader());

  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values && values.findByOrganization) {
    await dispatch(getCompany(values.findByOrganization)).then((response) => {
      const organizations = mapSuggestions([response.data]);
      dispatch(setLastFilterValues({
        company: organizations[0] || {},
      }));
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  PromiseAll({
    surveyResults: dispatch(loadSurveysResults(data)),
  }).then((responses) => {
    dispatch(loadPageSuccess(responses, startingPosition));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
