import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  logo: {
    height: 40,
  },
  wrapper: {
    width: 720,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(12)}px auto ${theme.spacing(14.5)}px`,
  },
  header: {
    margin: theme.spacing(8, 0, 5, 0),
    fontSize: 24,
    lineHeight: '32px',
  },
  clause: {
    height: 560,
    border: `1px solid ${theme.palette.text.primary}`,
    overflowY: 'scroll',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(6),
  },
  checkboxWrapper: {
    marginBottom: theme.spacing(3),
  },
  checkboxRoot: {
    padding: theme.spacing(0, 1.375),
  },
}));
