import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  userProfileItem: {
    padding: theme.spacing(0.75),
    color: theme.palette.drawer.secondaryText,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      width: 16,
      height: 16,
    },
  },
  tooltip: {
    bottom: 2,
    padding: theme.spacing(0.5, 1.5),
    lineHeight: '16px',
    margin: '0 !important',
  },
  popper: {
    left: '0.5px !important',
  },
  userProfileItemActive: {
    color: theme.palette.yellow,
  },
}));
