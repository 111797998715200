import { replace } from 'connected-react-router';
import _pick from 'lodash/pick';

import ApiManager from 'utils/ApiManager';
import errorCatch from 'utils/errorCatch';
import {
  loadUserData,
  hideLoader, showLoader,
} from 'containers/store';

export const onSubmit = (values) => (dispatch, getStore) => {
  const { userData } = getStore().Global;

  const data = {
    ..._pick(userData, [
      'email', 'first_name', 'last_name',
      'role_id', 'status', 'specialization_id',
      'deactivation_date',
    ]),
    ...values,
  };

  dispatch(showLoader());

  ApiManager.request('put', dispatch, `users/${userData.id}`, data).then(() => {
    dispatch(replace('/userProfile', { firstLogin: true }));
    dispatch(loadUserData());
  }).catch((error) => {
    errorCatch(error, dispatch);
  }).finally(() => {
    dispatch(hideLoader());
  });
};
