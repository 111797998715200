import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import PurchasePackageApplication from 'components/PurchasePackageApplication';
import { historyShape } from 'utils/shapes/router.shapes';
import { userDataShape } from 'utils/shapes/userDataShape';
import { intlShape } from 'utils/shapes/intlShape';
import {
  onSubmit,
  onCancel,
  loadPageData,
} from './store';

import messages from './messages';

class PurchasePackageApplicationContainer extends React.Component {
  static propTypes = {
    history: historyShape.isRequired,
    intl: intlShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    modalProps: PropTypes.objectOf(PropTypes.any).isRequired,
    userData: userDataShape.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { history, modalProps } = this.props;
    const { isActive: isBatteryActive } = history.location.state;
    const isActivate = !modalProps.isTopUp && !isBatteryActive;
    const subjectMessage = isActivate
      ? messages.subjectActivate
      : messages.subjectRecharge;
    const initialValues = {
      subject: this.props.intl.formatMessage(subjectMessage),
      addressee: this.props.intl.formatMessage(messages.addressee),
    };
    this.props.loadPageData(this.props.userData, initialValues, isActivate);
  }

  render() {
    return this.props.isLoadedPage ? (
      <PurchasePackageApplication {...this.props} />
    ) : null;
  }
}

const mapDispatchToProps = {
  onSubmit,
  onCancel,
  loadPageData,
};

const mapStateToProps = (state) => ({
  ...state.PurchasePackageApplication,
  userData: state.Global.userData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(PurchasePackageApplicationContainer)));
