import ApiManager from 'utils/ApiManager';
import errorCatch from 'utils/errorCatch';
import createURLWithQuery from 'utils/createURLWithQuery';
import getCountStartFrom from 'utils/getCountStartFrom';
import getSortAndPageData from 'utils/getSortAndPageData';
import getDateFromApi from 'utils/getDateFromApi';

import {
  hideLoader, showTransparentLoader,
  setPage, setRowsPerPage, hideLoaderMultipleRequests,
  showLoaderMultipleRequests, setLastFilterValues,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  toolID: null,
  initialValues: { search: '' },
  items: [],
};

const mapItems = (items, startingPosition) => items.map((el, key) => ({
  id: key + 1 + startingPosition,
  date: getDateFromApi(el.created_at),
  diagnostician: `${el.user.first_name} ${el.user.last_name}`,
  patient: `${el.patient.first_name} ${el.patient.last_name}`,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'HISTORY_OF_USE/LOAD_PAGE_SUCCESS',
  SET_FILTERED_HISTORY_OF_USE: 'HISTORY_OF_USE/SET_FILTERED_HISTORY_OF_USE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapItems(action.responses.data.items, 0),
        totalItemsCount: action.responses.data.total,
        toolID: action.toolID,
      };
    }

    case actionTypes.SET_FILTERED_HISTORY_OF_USE: {
      return {
        ...state,
        items: mapItems(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const setFilteredHistoryOfUse = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_HISTORY_OF_USE,
  response,
  startCountFrom,
});

const loadPageSuccess = (responses, toolID) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  toolID,
});

const loadHistoryOfUseData = (params, toolID) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    findByTool: toolID,
  };

  if (params.lastFilterValues.search) {
    data.find = params.lastFilterValues.search;
  }

  const url = createURLWithQuery('triggered_tools', data);

  return ApiManager.request('get', dispatch, url);
};

const getHistoryOfUse = () => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const toolID = getStore().HistoryOfUse.toolID;
  const data = getSortAndPageData(getStore);
  const startCountFrom = getCountStartFrom(getStore);

  try {
    const historyOfUseData = await dispatch(loadHistoryOfUseData(data, toolID));
    dispatch(setFilteredHistoryOfUse(historyOfUseData, startCountFrom));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getHistoryOfUse());
};

export const onChangePage = (_, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getHistoryOfUse());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getHistoryOfUse());
};

export const loadPageData = (values, routeState) => async (dispatch, getStore) => {
  dispatch(showLoaderMultipleRequests());
  const data = getSortAndPageData(getStore);

  if (values) {
    data.lastFilterValues = values;
  }

  try {
    const responses = await dispatch(loadHistoryOfUseData(data, routeState.id));
    dispatch(loadPageSuccess(responses, routeState.id));
  } catch (error) {
    errorCatch(error, dispatch, false, false);
  } finally {
    dispatch(hideLoaderMultipleRequests());
  }
};
