import _forEach from 'lodash/forEach';
import _flatMap from 'lodash/flatMap';
import _map from 'lodash/map';

import redHatTextBold from 'misc/fonts/redHatText-bold';
import { jsPDF as JsPDF } from 'jspdf';
import 'jspdf-autotable';
import redHatText from 'misc/fonts/redHatText';

import {
  constants,
  getImageFromElement,
  addImage,
  getTableByElementId,
  getTableByData,
} from 'utils/pdfDocument';

import messages from 'components/pages/Statictics/components/TestCountTable/TestCountTable.messages';

const {
  bottomMargin,
  topMargin,
  pageHeight,
} = constants;

export const onExportClick = async (theme, intl, testCountData, reportName, withHistoryTable) => {
  const formattedTestCountData = _flatMap(testCountData, (data) => ([
    {
      batteryName: data.mainDimension.name,
      testName: '',
      count: data.mainDimension.count,
    },
    ..._map(data.subDimensions, (el) => ({
      batteryName: data.mainDimension.name,
      testName: el.name,
      count: el.count,
    })),
  ]));

  const columns = [
    {
      dataKey: 'batteryName',
      header: intl.formatMessage(messages.batteryName),
    },
    {
      dataKey: 'testName',
      header: intl.formatMessage(messages.testName),
    },
    {
      dataKey: 'count',
      header: intl.formatMessage(messages.perfomedTests),
    },
  ];

  const svgElements = document.body.querySelectorAll('svg');

  _forEach(svgElements, (item) => {
    item.setAttribute('width', item.getBoundingClientRect().width);
    item.setAttribute('height', item.getBoundingClientRect().height);
  });

  const { image: headerImage, height: headerHeight } = await getImageFromElement('header');
  const { image: summaryImage, height: summaryHeight } = await getImageFromElement('summary');
  const { image: footerImage, height: footerHeight } = await getImageFromElement('documentFooter');

  const doc = new JsPDF();
  doc.setLanguage('pl');
  doc.addFileToVFS('RedHatText.ttf', redHatText);
  doc.addFont('RedHatText.ttf', 'RedHatText', 'normal');
  doc.addFileToVFS('RedHatTextBold.ttf', redHatTextBold);
  doc.addFont('RedHatTextBold.ttf', 'RedHatText-bold', 'bold');
  doc.setFont('RedHatText');

  let position = topMargin;

  addImage(doc, headerImage, position, headerHeight);
  position += headerHeight + bottomMargin;

  addImage(doc, summaryImage, position, summaryHeight);
  position = position + summaryHeight + bottomMargin;

  getTableByData(
    doc,
    position,
    footerHeight + bottomMargin * 3,
    formattedTestCountData,
    columns,
    theme.palette.secondary.main,
  );

  if (withHistoryTable) {
    getTableByElementId(
      doc,
      doc.lastAutoTable.finalY + bottomMargin * 4,
      footerHeight + bottomMargin * 3,
      '#historyOfUse',
      theme.palette.primary.main,
    );
  }

  const pageCount = doc.internal.getNumberOfPages();

  for (let i = 1; i <= pageCount; i += 1) {
    doc.setPage(i);
    const footerPosition = pageHeight - footerHeight - bottomMargin * 2;
    addImage(doc, footerImage, footerPosition, footerHeight);
  }

  doc.save(`${reportName}.pdf`);
};
