export default (theme) => ({
  wrapper: {
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    boxSizing: 'border-box',
  },
  leftSide: {
    flexGrow: 1,
    minWidth: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
  },
  leftSideContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '25%',
    marginLeft: '27vw',
    minWidth: 456,
    '@media (max-width: 1440px)': {
      marginLeft: '25vw',
    },
    '@media (max-width: 1024px)': {
      marginLeft: '20vw',
    },
    '@media (max-width: 698px)': {
      marginLeft: '17vw',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      padding: theme.spacing(0, 3),
      minWidth: 'auto',
      marginTop: '10%',
    },
  },
  movedToTop: {
    marginTop: '16%',
  },
  rightSite: {
    flexGrow: 1,
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    zIndex: 1,
  },
  image: {
    position: 'absolute',
    top: theme.spacing(9.5),
    right: theme.spacing(9.5),
    width: '27vw',
    '@media (max-width: 1440px)': {
      top: theme.spacing(8.25),
      right: theme.spacing(8.25),
      width: '29vw',
    },
    '@media (max-width: 1024px)': {
      top: theme.spacing(5.75),
      right: theme.spacing(5.75),
      width: '31vw',
    },
    '@media (max-width: 698px)': {
      width: '37vw',
    },
  },
  loaderWrapper: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transparentLoaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
  },
  transparentLoaderBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.cardBackground,
    opacity: 0.6,
    zIndex: 100,
  },
  progressWrapper: {
    position: 'relative',
    zIndex: 101,
  },
  contentWrapperWithLoader: {
    display: 'none',
  },
  logoWrapper: {
    marginBottom: 50,
  },
  logo: {
    height: 40,
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error.main,
  },
});
