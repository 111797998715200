const regexesValidation = {
  isNumber: /^-?\d+([.,]\d+)?$/g,
  isNumberNoZero: /^-?[1-9]\d*([.,]\d+)?$/,
  isEmail: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  isNumberPositiveOnly: /^\d+([.,]\d+)?$/g,
  isValidCode: /^[a-zA-Z]+[a-zA-Z0-9_]*$/,
  isOneDecimalPlace: /^\d+(\.\d{1}){0,1}$/,
  isPostalCode: /\d{2}-\d{3}/,
  hasCapitalLetter: /[A-Z,Ą,Ć,Ę,Ł,Ń,Ó,Ś,Ź,Ż]+/,
  hasSpecialCharacter: /[~`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/,
};

export default regexesValidation;
