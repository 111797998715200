const roles = {
  superAdmin: 'super_admin',
  admin: 'admin',
  pollster: 'pollster',
  diagnostician: 'diagnostician',
  clinicCoordinator: 'clinic_coordinator',
  salesCoordinator: 'sales_coordinator',
  productOwner: 'product_owner',
  accountManager: 'account_manager',
};

export const adminRoles = [roles.superAdmin, roles.admin];
export const diagnosticianRoles = [roles.diagnostician, roles.clinicCoordinator];
export const facilityRoles = [...diagnosticianRoles, roles.accountManager];

export default roles;
