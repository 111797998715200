import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TopUpAccountView from 'components/pages/TopUpAccountModal';

import {
  onSubmit,
  onCancel,
  loadPageData,
} from './store';

class TopUpAccountContainer extends React.Component {
  static propTypes = {
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    modalProps: PropTypes.objectOf(PropTypes.any).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { selectedItem } = this.props.modalProps;
    this.props.loadPageData(selectedItem);
  }

  render() {
    return this.props.isLoadedPage ? (
      <TopUpAccountView {...this.props} />
    ) : null;
  }
}

const mapDispatchToProps = {
  onSubmit,
  onCancel,
  loadPageData,
};

const mapStateToProps = (state) => ({
  ...state.TopUpAccount,
  pointsAmount: state.Facility.clinicPoints,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopUpAccountContainer);
