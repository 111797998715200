import PropTypes from 'prop-types';
import _every from 'lodash/every';
import _some from 'lodash/some';

const checkPermissions = (permissions, permissionsOperator = 'AND') => {
  if (permissionsOperator === 'AND') {
    return _every(permissions);
  }

  if (permissionsOperator === 'OR') {
    return _some(permissions);
  }

  return false;
};

checkPermissions.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.bool).isRequired,
  permissionsOperator: PropTypes.oneOf(['AND', 'OR']),
};

export default checkPermissions;
