import { push } from 'connected-react-router';
import config from 'config';

import getStatusMessage from 'utils/getStatusMessage';
import ApiManager from 'utils/ApiManager';
import errorCatch from 'utils/errorCatch';

import {
  hideLoader,
  showLoader,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  values: {
    id: '',
    name: '',
    description: '',
    status: '',
    thumbnail: null,
    videoUrl: null,
  },
};

const mapValues = (el) => ({
  id: el.id,
  name: el.title,
  description: el.description,
  status: getStatusMessage(el.status),
  thumbnail: `${config.localApiHostStorage}/${el.thumbnail}`,
  videoUrl: `${config.localApiHostStorage}/${el.video}`,
});

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'VIDEO_TUTORIAL_VIEW/LOAD_PAGE_SUCCESS',
  SET_FILTERED_TOOLS: 'VIDEO_TUTORIAL_VIEW/SET_FILTERED_TOOLS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        values: mapValues(action.responses.data),
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const loadVideos = (id) => (dispatch) => (
  ApiManager.request('get', dispatch, `video_tutorials/${id}`)
);

export const onEdit = () => (dispatch, getStore) => {
  const { id } = getStore().VideoTutorialView.values;
  dispatch(push('/videoTutorialAddEdit', { id }));
};

export const loadPageData = (routeState) => async (dispatch) => {
  dispatch(showLoader());

  try {
    const movieData = await dispatch(loadVideos(routeState.id));
    dispatch(loadPageSuccess(movieData));
  } catch (error) {
    errorCatch(error, dispatch);
  } finally {
    dispatch(hideLoader());
  }
};
