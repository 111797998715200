import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl } from 'react-intl';
import _map from 'lodash/map';

import { withStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Menu from '@material-ui/core/Menu';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import { useAnchor } from 'utils/hooks/useAnchor';
import { classesShape } from 'utils/shapes/classesShape';
import { intlShape } from 'utils/shapes/intlShape';
import { tableFilterValuesShape } from 'utils/shapes/tableFilterValuesShape';

import styles from './TableFilterLabel.styles';
import messages from './TableFilterLabel.messages';

const TableFilterLabel = ({
  classes,
  intl,
  active,
  field,
  label,
  initialValues,
  onFilter,
}) => {
  const {
    anchorEl,
    isOpen,
    onOpenClick,
    onCloseClick,
  } = useAnchor();

  const onSubmit = (values) => {
    onFilter(field, values);
    onCloseClick();
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onCloseClick}
        classes={{ paper: classes.paper }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={undefined}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <form
              onSubmit={handleSubmit}
              className={classes.form}
            >
              <div className={classes.formInnerWrapper}>
                {_map(values, (value, key) => (
                  <FormControlLabel
                    key={key}
                    label={value.name}
                    classes={{ root: classes.label }}
                    control={(
                      <Checkbox
                        value={value.id}
                        checked={value.isChecked}
                        type="checkbox"
                        color="primary"
                        onChange={(event) => {
                          setFieldValue(`${key}.isChecked`, event.currentTarget.checked);
                        }}
                      />
                    )}
                  />
                ))}
              </div>
              <div className={classes.button}>
                <Button variant="contained" color="primary" type="submit">
                  {intl.formatMessage(messages.submit)}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Menu>
      <TableSortLabel
        active={active}
        direction="desc"
        onClick={onOpenClick}
        IconComponent={FilterListIcon}
        classes={{ root: classes.sortLabel }}
      >
        {label}
      </TableSortLabel>
    </>
  );
};

TableFilterLabel.propTypes = {
  classes: classesShape.isRequired,
  field: PropTypes.string.isRequired,
  initialValues: tableFilterValuesShape.isRequired,
  intl: intlShape.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onFilter: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

TableFilterLabel.defaultProps = {
  active: false,
};

export default withStyles(styles)(injectIntl(TableFilterLabel));
