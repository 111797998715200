import { goBack } from 'connected-react-router';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _first from 'lodash/first';
import _reject from 'lodash/reject';
import moment from 'moment';

import config from 'config';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import errorCatch from 'utils/errorCatch';
import roles from 'utils/constants/rolesOfUsers';
import specializationTypes from 'utils/constants/specializationTypes';
import clinicTypes from 'utils/constants/clinicTypes';
import isSpecializationStudent from 'utils/isSpecializationStudent';

import {
  showLoader,
  hideLoader,
  showSnackbar,
  setDrawerVisibility,
  showTransparentLoader,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  diagnosticianID: null,
  specializations: [],
  isClinicCoordinator: false,
  initialValues: {
    name: '',
    surname: '',
    specialization: '',
    email: '',
    deactivationDate: moment(),
  },
};

const mapDataToValues = (data) => ({
  name: data.first_name,
  surname: data.last_name,
  specialization: data.specialization_id,
  deactivationDate: data.deactivation_date ? moment(data.deactivation_date, 'YYYY-MM-DD') : moment(),
  email: data.email,
  role: data.role_id,
});

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'DIAGNOSTICIAN_ADD_EDIT/LOAD_PAGE_SUCCESS',
  LOAD_PAGE_DATA_SUCCESS: 'DIAGNOSTICIAN_ADD_EDIT/LOAD_PAGE_DATA_SUCCESS',
  CLEAR_STORE: 'DIAGNOSTICIAN_ADD_EDIT/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        specializations: _sortBy(action.response.specializations.data, 'name'),
        isClinicCoordinator: action.response.isClinicCoordinator,
      };
    }

    case actionTypes.LOAD_PAGE_DATA_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        initialValues: mapDataToValues(action.response.data),
        diagnosticianID: action.diagnosticianID,
        specializations: _sortBy(action.specializations.data, 'name'),
        isClinicCoordinator: action.isClinicCoordinator,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageDataSuccess = (response, diagnosticianID, specializations, isClinicCoordinator) => ({
  type: actionTypes.LOAD_PAGE_DATA_SUCCESS,
  response,
  diagnosticianID,
  specializations,
  isClinicCoordinator,
});

const loadPageSuccess = (response) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  response,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onSubmit = (values, { setFieldError }) => async (dispatch, getStore) => {
  const diagnosticianID = getStore().DiagnosticianAddEdit.diagnosticianID;
  const specializations = getStore().DiagnosticianAddEdit.specializations;

  const url = diagnosticianID ? `users/${diagnosticianID}` : 'users';
  const method = diagnosticianID ? 'put' : 'post';
  const message = diagnosticianID
    ? snackbarMessages.diagnosticianEdited
    : snackbarMessages.diagnosticianAdded;

  const data = {
    first_name: values.name,
    last_name: values.surname,
    specialization_id: values.specialization,
    email: values.email,
    role_id: diagnosticianID ? values.role : roles.diagnostician,
    status: config.researchToolStatuses.active,
    deactivation_date: (
      isSpecializationStudent(values.specialization, specializations) && values.deactivationDate
    ) ? values.deactivationDate.format('YYYY-MM-DD') : null,
    last_used_app_version: config.appVersion,
  };

  dispatch(showTransparentLoader());

  try {
    await ApiManager.request(method, dispatch, url, data);
    dispatch(goBack());
    dispatch(showSnackbar(message));
    dispatch(hideLoader());
  } catch (error) {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.diagnosticianAddEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

const loadDiagnosticianData = (id) => (dispatch) => ApiManager.request('get', dispatch, `users/${id}`);

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

const loadSpecialization = () => (dispatch) => ApiManager.request('get', dispatch, 'specializations?orderBy=id&sortedBy=asc');

const loadClinicTypes = () => (dispatch) => ApiManager.request('get', dispatch, 'clinic_types');

export const loadPageData = (routeState, message) => async (dispatch, getStore) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  try {
    const specializations = await dispatch(loadSpecialization());
    const clinics = await dispatch(loadClinicTypes());

    const isClinicCoordinator = getStore().Global.userData.role_id === roles.clinicCoordinator;
    const clinicTypeId = _get(_first(getStore().Global.userData.clinics), 'clinic_type_id');
    const college = _find(clinics.data, (clinic) => clinic.name === clinicTypes.COLLEGE);
    const isCollege = clinicTypeId === college.id;

    if (!isCollege) {
      specializations.data = _reject(
        specializations.data,
        (specialization) => specialization.name === specializationTypes.STUDENT,
      );
    }

    if (routeState.id) {
      const data = await dispatch(loadDiagnosticianData(routeState.id));

      if (data.data.role_id === roles.clinicCoordinator) {
        specializations.data = _reject(
          specializations.data,
          (specialization) => specialization.name === specializationTypes.STUDENT,
        );
      }

      dispatch(loadPageDataSuccess(data, routeState.id, specializations, isClinicCoordinator));
    } else {
      dispatch(loadPageSuccess({ specializations, isClinicCoordinator }));
    }
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
