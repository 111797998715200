import PropTypes from 'prop-types';

const initialValuesShape = PropTypes.shape({
  sender: PropTypes.string,
  role: PropTypes.string,
  addressee: PropTypes.string,
  topic: PropTypes.string,
  reason: PropTypes.string,
});

export { initialValuesShape };
