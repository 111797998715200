export default (theme) => ({
  formSpacing: {
    marginTop: theme.spacing(3),
  },
  label: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.placeholder,
    marginBottom: theme.spacing(1),
  },
  disableVerticalPadding: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  substantiation: {
    color: theme.palette.text.placeholder,
    '& .Mui-disabled': {
      color: theme.palette.text.placeholder,
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
  name: {
    paddingBottom: '0 !important',
  },
});
