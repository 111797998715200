export default (theme) => ({
  row: {
    paddingBottom: theme.spacing(3.125),
  },
  disabledInput: {
    borderBottom: `1px solid ${theme.palette.grey.light}`,
  },
  form: {
    marginTop: theme.spacing(-1),
    paddingBottom: theme.spacing(1),
  },
});
