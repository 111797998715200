import _get from 'lodash/get';
import _includes from 'lodash/includes';

import { diagnosticianRoles } from 'utils/constants/rolesOfUsers';

const DEFAULT_AGENCY_NAME = 'Diagmatic';

const getAgencyName = (userData) => {
  const {
    clinics,
    organizations,
    role,
    role_id: roleId,
  } = userData;
  const userRole = _get(role, 'id', roleId);

  return _includes(diagnosticianRoles, userRole)
    ? _get(
      clinics,
      '[0].name',
      _get(organizations, '[0].name', DEFAULT_AGENCY_NAME),
    )
    : DEFAULT_AGENCY_NAME;
};

export default getAgencyName;
