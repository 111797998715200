import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  tooltipTooltip: {
    ...theme.tooltip,
    padding: theme.spacing(1, 1.5),
    lineHeight: '17px',
    marginLeft: ({ leftMargin }) => leftMargin,
  },
  tooltipArrow: {
    color: theme.palette.tooltip.backgroundColor2,
  },
}));
