import _get from 'lodash/get';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import errorCatch from 'utils/errorCatch';
import formatFullName from 'utils/formatFullName';
import {
  RECHARGE_REQUEST,
  ACTIVATION_REQUEST,
} from 'utils/constants/applicationTypes';

import {
  showSnackbar,
  setModal,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  isSubmitting: false,
  isActivate: false,
  initialValues: {
    sender: '',
    role: '',
    addressee: '',
    subject: '',
    reason: '',
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'PURCHASE_PACKAGE_APPLICATION/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'PURCHASE_PACKAGE_APPLICATION/CLEAR_STORE',
  SET_IS_SUBMITTING: 'PURCHASE_PACKAGE_APPLICATION/SET_IS_SUBMITTING',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        isActivate: action.isActivate,
        initialValues: {
          sender: formatFullName(action.userData),
          role: action.userData.role.name,
          reason: '',
          addressee: action.initialValues.addressee,
          subject: action.initialValues.subject,
        },
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_IS_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.value,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (userData, initialValues, isActivate) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  userData,
  initialValues,
  isActivate,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setSubmittingForm = (value) => ({
  type: actionTypes.SET_IS_SUBMITTING,
  value,
});

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const { id, batteryId } = _get(getStore(), 'router.location.state', {});
  const { isActivate } = getStore().PurchasePackageApplication;

  dispatch(setSubmittingForm(true));
  const data = {
    type: isActivate ? ACTIVATION_REQUEST : RECHARGE_REQUEST,
    message: values.reason || undefined,
  };

  if (isActivate) {
    data.battery_id = batteryId || id;
  }

  ApiManager.request('post', dispatch, 'messages/with-type', data).then(() => {
    dispatch(setModal());
    dispatch(showSnackbar(snackbarMessages.messageSend));
    dispatch(setSubmittingForm(false));
  }).catch((error) => {
    setFormErrors(error.error.errors, setFieldError, apiFieldMappings.privateMessage);
    errorCatch(error, dispatch, false, false);
  }).finally(() => {
    dispatch(setSubmittingForm(false));
  });
};

export const onCancel = () => (dispatch) => {
  dispatch(setModal());
};

export const loadPageData = (userData, initialValues, isActivate) => (dispatch) => {
  dispatch(clearStore());
  dispatch(loadPageSuccess(userData, initialValues, isActivate));
};
