import { push, replace } from 'connected-react-router';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _pick from 'lodash/pick';
import _keys from 'lodash/keys';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import errorCatch from 'utils/errorCatch';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import dialogTexts from 'utils/dialogTexts';
import mapSuggestions from 'utils/mapSuggestions';
import roles from 'utils/constants/rolesOfUsers';
import config from 'config';

import {
  hideLoader, showSnackbar, showLoader, getMeta, showTransparentLoader, setSortingAndPagination,
  openDialog, mapMeta, setPage, setRowsPerPage, setSortingData, setLastFilterValues,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
  types: [],
  statuses: [],
  facilitySuggestions: [],
};

const CLINIC_FIELD_NAME = 'clinic|name';

const defaultSorting = {
  fieldName: 'surname',
  sortDirection: 'asc',
};

const sortFieldsMapping = {
  surname: 'last_name',
  name: 'first_name',
  email: 'email',
  role: 'roles|name',
  status: 'status',
  facility: CLINIC_FIELD_NAME,
};

const mapUsers = (users, startingPosition) => users.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: el.first_name,
  surname: el.last_name,
  email: el.email,
  role: el.role_id,
  status: el.status,
  standardScaleId: el.standard_scale_id,
  facility: _get(el, 'clinics[0]', ''),
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'USERS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_USERS: 'USERS/SET_FILTERED_USERS',
  SET_META: 'USERS/SET_META',
  SET_FACILITY_SUGGESTIONS: 'USERS/SET_FACILITY_SUGGESTIONS',
  CLEAR_FACILITY_SUGGESTIONS: 'USERS/CLEAR_FACILITY_SUGGESTIONS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapUsers(action.responses.users.data.items, action.startingPosition),
        facilitySuggestions: mapSuggestions(action.responses.facilities.data.items),
        totalItemsCount: action.responses.users.data.total,
      };
    }

    case actionTypes.SET_META: {
      const mappedTypes = mapMeta(action.response.data.userRole);
      const availableTypes = {
        [roles.salesCoordinator]: ['clinicCoordinator', 'accountManager', 'diagnostician'],
        [roles.accountManager]: ['clinicCoordinator', 'diagnostician'],
      };
      const underSalesCoordiantor = _filter(
        mappedTypes,
        ({ id }) => _includes(_pick(roles, availableTypes[action.currentUserRole]), id),
      );

      return {
        ...state,
        types: _includes(_keys(availableTypes), action.currentUserRole)
          ? underSalesCoordiantor
          : mappedTypes,
        statuses: mapMeta(action.response.data.userStatus),
      };
    }

    case actionTypes.SET_FILTERED_USERS: {
      return {
        ...state,
        items: mapUsers(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    case actionTypes.SET_FACILITY_SUGGESTIONS: {
      return {
        ...state,
        facilitySuggestions: mapSuggestions(action.response.data.items),
      };
    }

    case actionTypes.CLEAR_FACILITY_SUGGESTIONS: {
      return {
        ...state,
        facilitySuggestions: [],
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  startingPosition,
});

const setFilteredUsers = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_USERS,
  response,
  startCountFrom,
});

const setMeta = (response, currentUserRole) => ({
  type: actionTypes.SET_META,
  response,
  currentUserRole,
});

const setFacilitySuggestions = (response) => ({
  type: actionTypes.SET_FACILITY_SUGGESTIONS,
  response,
});

const loadUsers = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: sortFieldsMapping[defaultSorting.fieldName],
    appendClinics: true,
  };

  if (params.lastFilterValues.search) {
    data.findUser = params.lastFilterValues.search;
  }

  if (params.lastFilterValues.type) {
    data.findUserByRole = params.lastFilterValues.type;
  }

  if (params.lastFilterValues.status) {
    data.findUserByStatus = params.lastFilterValues.status;
  }

  if (!_isEmpty(params.lastFilterValues.facility)) {
    data.findUserByClinic = params.lastFilterValues.facility.id;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;

    if (params.orderBy === CLINIC_FIELD_NAME) {
      delete data.orderBy;
      data.orderByClinic = 'name';
    }
  }

  const url = createURLWithQuery('users', data);

  dispatch(replace(url));

  return ApiManager.request('get', dispatch, url);
};

export const onAddClick = () => (dispatch) => {
  dispatch(push('/userAddEdit'));
};

const getUsers = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const startCountFrom = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  dispatch(loadUsers(data)).then((response) => {
    dispatch(setFilteredUsers(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};

const getFacilities = (filterByName) => (dispatch) => {
  const params = {
    filterByName,
    ...config.fullDataPagination,
  };

  const url = createURLWithQuery('clinics', params);

  return ApiManager.request('get', dispatch, url);
};

export const onFacilityClear = () => ({
  type: actionTypes.CLEAR_FACILITY_SUGGESTIONS,
});

export const onFacilityFetch = (search) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(getFacilities(search)).then((response) => {
    dispatch(setFacilitySuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getUsers());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getUsers());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getUsers());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getUsers());
};

const onDeleteAccept = (id) => (dispatch, getStore) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `users/${id}`).then(() => {
    const data = getSortAndPageData(getStore, sortFieldsMapping);
    const startCountFrom = getCountStartFrom(getStore);

    dispatch(loadUsers(data)).then((response) => {
      dispatch(setFilteredUsers(response, startCountFrom));
      dispatch(showSnackbar(snackbarMessages.userDeleted));
      dispatch(hideLoader());
    }).catch((error) => {
      errorCatch(error, dispatch);
    });
  }).catch((error) => {
    if (isBadRequest(error, 409)) {
      dispatch(showSnackbar(error.error.message, true));
    } else if (isBadRequest(error)) {
      dispatch(showSnackbar(error.error.message, true));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteUser,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/userAddEdit', { id }));
};

export const onView = (id) => (dispatch) => {
  dispatch(push('/userAddEdit', { id, isViewProfile: true }));
};

const getFacility = (id) => (dispatch) => ApiManager.request('get', dispatch, `clinics/${id}`);

export const loadPageData = (values) => async (dispatch, getStore) => {
  dispatch(showLoader());

  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values) {
    let clinics = [];
    if (values.findUserByClinic) {
      await dispatch(getFacility(values.findUserByClinic)).then((response) => {
        clinics = mapSuggestions([response.data]);
      }).catch((error) => {
        if (isBadRequest(error)) {
          dispatch(showSnackbar(snackbarMessages.wrongData));
        } else {
          dispatch(showSnackbar(snackbarMessages.globalError));
        }
      });
    }

    dispatch(setLastFilterValues({
      facility: clinics[0] || {},
      search: values.findUser || '',
      type: values.findUserByRole || '',
      status: values.findUserByStatus || '',
    }));
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const currentUserRole = getStore().Global.userData.role_id;

  dispatch(getMeta()).then((response) => {
    dispatch(setMeta(response, currentUserRole));
    PromiseAll({
      users: dispatch(loadUsers(data)),
      facilities: dispatch(getFacilities()),
    }).then((responses) => {
      dispatch(loadPageSuccess(responses, startingPosition));
      dispatch(hideLoader());
    }).catch((error) => {
      errorCatch(error, dispatch);
    });
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};
