import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    batteryAddButton: {
      marginTop: theme.spacing(0.75),
      height: 44,
    },
    input: {
      padding: `${theme.spacing(1.875, 0)} !important`,
      lineHeight: '22px',
    },
    tooltip: theme.tooltip,
    tableWrapper: {
      marginTop: theme.spacing(-2),
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      height: '100%',
    },
    primaryIconButton: {
      '&.MuiIconButton-root': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-colorPrimary': {
          '&:hover': {
            color: '#06A285',
          },
        },
      },
    },
  }),
);
