import { defineMessages } from 'react-intl';
import _forEach from 'lodash/forEach';
import _isEqual from 'lodash/isEqual';
import _find from 'lodash/find';
import _findLast from 'lodash/findLast';
import regexes from 'utils/validator/regexesValidation';
import config from 'config';

const messages = defineMessages({
  cannotBeEmpty: 'Pole nie może być puste',
  codeMustBeUnique: 'Kod grupy musi być unikalny',
  nameMustBeUnique: 'Nazwa grupy musi być unikalna',
  codeTooLong: 'Kod grupy zbyt długi',
  nameTooLong: 'Nazwa grupy zbyt długa',
  codeIsInvalid: 'Kod jest niepoprawny',
});

export default (value) => {
  const result = {
    fields: {},
  };

  result.isValid = true;
  const { isValidCode } = regexes;

  _forEach(value, (group) => {
    const { code, name } = group;
    if (code === null || code === undefined || code.toString().length === 0) {
      result.fields[`group-code-${group.id}`] = messages.cannotBeEmpty;
      result.isValid = false;
    } else if (!(isValidCode.test(code.toString()))) {
      result.fields[`group-code-${group.id}`] = messages.codeIsInvalid;
      result.isValid = false;
    } else if (code.toString().length > config.maxLength.code) {
      result.fields[`group-code-${group.id}`] = messages.codeTooLong;
      result.isValid = false;
    } else if (
      !_isEqual(
        _find(value, (el) => el.code === code),
        _findLast(value, (el) => el.code === code),
      )
    ) {
      result.fields[`group-code-${group.id}`] = messages.codeMustBeUnique;
      result.isValid = false;
    }

    if (name === null || name === undefined || name.toString().length === 0) {
      result.fields[`group-name-${group.id}`] = messages.cannotBeEmpty;
      result.isValid = false;
    } else if (
      !_isEqual(
        _find(value, (el) => el.name === name),
        _findLast(value, (el) => el.name === name),
      )
    ) {
      result.fields[`group-name-${group.id}`] = messages.nameMustBeUnique;
      result.isValid = false;
    } else if (name.toString().length >= config.maxLength.name) {
      result.fields[`group-name-${group.id}`] = messages.nameTooLong;
      result.isValid = false;
    }
  });

  return result;
};
