import messages from './ShortInfo.messages';

export const getValidators = (isAnonymous) => ([
  {
    fieldName: 'name',
    validators: [{
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    }],
  }, {
    fieldName: 'participantAmount',
    validators: [{
      validatorName: 'notEmpty',
    }, {
      validatorName: 'isNumber',
      parameters: [true],
      customErrorMessage: messages.classError,
    }, {
      validatorName: 'isNumberWithNoZero',
      customErrorMessage: messages.classError,
    }, {
      validatorName: 'higherOrEqualTo',
      parameters: [2],
    }, {
      validatorName: 'lowerOrEqualTo',
      parameters: [100, true],
    }, {
      validatorName: 'maxDecimalPlaces',
      parameters: [0],
      customErrorMessage: messages.classError,
    }],
    validateIf: () => isAnonymous,
  }, {
    fieldName: 'class',
    validators: [{
      validatorName: 'notEmpty',
    }, {
      validatorName: 'isNumber',
      parameters: [true],
      customErrorMessage: messages.classError,
    }, {
      validatorName: 'isNumberWithNoZero',
      customErrorMessage: messages.classError,
    }, {
      validatorName: 'higherOrEqualTo',
      parameters: [0],
    }, {
      validatorName: 'maxDecimalPlaces',
      parameters: [0],
      customErrorMessage: messages.classError,
    }],
    validateIf: () => isAnonymous,
  }, {
    fieldName: 'section',
    validators: [{
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    }],
    validateIf: () => isAnonymous,
  },
]);
