const config = {
  appVersion: '2.4.1',
  localApiHost: process.env.REACT_APP_API_URL || 'https://master-diagmatic-admin-api.fingoweb.it',
  localApiHostStorage: process.env.REACT_APP_STORAGE_URL
  || `${process.env.REACT_APP_API_URL || 'https://master-diagmatic-admin-api.fingoweb.it'}/storage`,
  clientAppUrl: process.env.REACT_APP_FRONT_CLIENT_URL || 'https://master-diagmatic-front-client.fingoweb.it',
  groupExaminationLoginAppUrl: process.env.REACT_APP_FRONT_GROUP_LOGIN || 'https://master-diagmatic-front-grupowe.fingoweb.it',
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  sentryEnv: process.env.REACT_APP_SENTRY_ENV || 'master',
  env: process.env.REACT_APP_ENV || 'master',
  apiUrl: 'api',
  debounceTime: 300,
  hideSnackbarTimeout: 4000,
  refetchTimeout: 5000,
  questionTypeIds: {
    knowledgeQuestion: 5,
  },
  answerTypeIds: {
    openAnswer: 5,
  },
  pin: 7586,
  rounding: 2,
  dateVisibleMonths: 1,
  minimumNights: 0,
  shortDateFormat: 'DD/MM',
  apiDateFormat: 'YYYY-MM-DD',
  dateFormat: 'DD/MM/YYYY',
  dateTimeFormat: 'DD/MM/YYYY, HH:mm',
  result: {
    lineWidth: 328,
  },
  researchToolStatuses: {
    inactive: 'INACTIVE',
    active: 'ACTIVE',
    archived: 'ARCHIVED',
  },
  dimensionRowTypes: {
    valuesPicker: 'valuesPicker',
    draggableRow: 'draggableRow',
  },
  ids: {
    scalesListMainDroppable: 'scalesListMainDroppable',
  },
  maxLength: {
    code: 8,
    name: 255,
    textField: 65535,
  },
  maxValue: {
    smallInteger: 32767,
    surveyTime: 86400,
  },
  maxSubscriptionStartDay: 28,
  passwordMinLength: 8,
  maxExaminationsToCompare: 3,
  greaterDiscount: 10,
  maxOfPackages: 3,
  fullDataPagination: {
    perPage: 9999,
    page: 1,
  },
  maxDocumentSize: 8 * (1024 ** 2),
  maxManualSize: 8 * (1024 ** 2),
  maxSubscriptions: 1000,
  maxOrder: 9999,
  maxSuggestions: 20,
  defaultTextareaRows: 5,
  maximumAddedPoints: 10000,
  maxAmountInputValue: 999999.99,
  maxAmountLength: 9,
  bigTextareaRows: 12,
  snackbarTypes: {
    error: 'error',
    info: 'info',
  },
  researchToolTypes: {
    primary: 'PRIMARY',
    secondary: 'SECONDARY',
  },
  horizontalFooterScale: 1.4,
  batteryWithCodesToShorten: 'B-KMiA',
  externalMotivationCode: 'Z',
  comparisonMethods: {
    horizontal: 'horizontal',
    vertical: 'vertical',
  },
  coordinatorAutocompleteMaxLength: 16,
  priceFormatter: {
    currency: 'zł',
    digits: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
  },
  currencyMaxDecimalPlaces: 2,
  offerTypes: {
    basic: 'basic',
    medium: 'medium',
    large: 'large',
  },
  scaleRoundingFactors: {
    sten: 0.2,
    stanin: 0.15,
  },
  localStorageLoginKey: 'diagmaticCurrentUserId',
  fullWidthPageCard: 1599,
  privacyPolicyLink: 'https://diagmatic.pl/privacy_policy',
  termsOfUseLink: 'https://diagmatic.pl/terms_of_use',
  changelogLink: 'https://diagmatic.notion.site/Rejestr-aktualizacji-systemu-Diagmatic-e819b3a593b84519b731453f32885ffb',
  versionCheckInterval: 5000,
};

export default config;
