import { goBack } from 'connected-react-router';
import _forEach from 'lodash/forEach';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrorsInArray from 'utils/setFormErrorsInArray';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader, setDrawerVisibility,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    name: '',
    genderCriteria: false,
    ageCriteria: false,
    categoriesCount: '',
    bottomLimit: '',
    topLimits: [],
    referenceGroups: [],
  },
  isViewOnly: false,
  refGroupId: null,
};

const mapDataToValues = (data) => {
  const topLimits = [];
  const referenceGroups = [];

  _forEach(data.reference_group, (el, index) => {
    const gender = (el.criteria_sex) ? 'M' : 'K';

    topLimits.push(el.criteria_max_age);
    referenceGroups.push({
      rangeId: index,
      id: el.id,
      name: el.name,
      code: el.code,
      gender: _isNull(el.criteria_sex) ? '' : gender,
      ageRange: {
        bottomLimit: el.criteria_min_age,
        topLimit: el.criteria_max_age,
      },
    });
  });

  const bottomLimit = !_isEmpty(data.reference_group) ? data.reference_group[0].criteria_min_age : '';

  return {
    name: data.name,
    genderCriteria: data.criteria_sex,
    ageCriteria: data.criteria_age,
    categoriesCount: data.reference_group.length,
    bottomLimit,
    topLimits,
    referenceGroups,
  };
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'REF_GROUP_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'REF_GROUP_ADD_EDIT/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        refGroupId: action.refGroupId,
        isEdit: !!action.refGroupId,
        isViewOnly: !!action.isViewOnly,
        initialValues: action.response
          ? mapDataToValues(action.response.data) : initialState.initialValues,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (refGroupId, response, isViewOnly) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  refGroupId,
  response,
  isViewOnly,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadReferenceGroupSetData = (id) => (dispatch) => ApiManager.request('get', dispatch, `reference_group_sets/${id}`);

const loadReferenceGroupSet = (id) => (dispatch) => {
  if (id) {
    return dispatch(loadReferenceGroupSetData(id));
  }
  return Promise.resolve();
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const store = getStore().RefGroupsAddEdit;
  const { refGroupId } = store;
  const isEdit = !!refGroupId;
  let url = 'reference_group_sets';
  let method = 'post';
  let message = snackbarMessages.refGroupSetAddedSuccessfully;

  if (isEdit) {
    url = `reference_group_sets/${refGroupId}`;
    method = 'put';
    message = snackbarMessages.refGroupSetEditedSuccessfully;
  }

  const data = {
    name: values.name,
    criteria_sex: values.genderCriteria,
    criteria_age: values.ageCriteria,
    reference_groups: values.referenceGroups.map((el) => {
      let gender = null;

      if (el.gender === 'M') {
        gender = true;
      }

      if (el.gender === 'K') {
        gender = false;
      }

      const referenceGroup = {
        name: el.name,
        code: el.code,
        criteria_sex: gender,
        criteria_min_age: Number(el.ageRange.bottomLimit),
        criteria_max_age: Number(el.ageRange.topLimit),
      };

      if (isEdit) {
        referenceGroup.id = el.id;
      }

      return referenceGroup;
    }),
  };

  dispatch(showTransparentLoader());

  ApiManager.request(method, dispatch, url, data).then(() => {
    dispatch(showSnackbar(message));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      const mapping = {
        arrayName: 'reference_groups',
        fieldsMapping: {
          criteria_max_age: 'top-limit',
          name: 'group-name',
          code: 'group-code',
        },
      };
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.refGroupsAddEdit);
      setFormErrorsInArray(error.error.errors, setFieldError, mapping);
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const loadPageData = (id, isViewOnly, message) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  if (!isViewOnly) {
    dispatch(setDrawerVisibility({
      visibility: true,
      content: message,
    }));
  }

  dispatch(loadReferenceGroupSet(id)).then((response) => {
    dispatch(loadPageSuccess(id, response, isViewOnly));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
