import { goBack } from 'connected-react-router';
import _map from 'lodash/map';
import _isNumber from 'lodash/isNumber';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader, setDrawerVisibility,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  scaleId: null,
  initialValues: {
    name: '',
    description: '',
    boundariesCount: 1,
    scaleValues: [{
      value: 1,
      topLimit: '',
      label: '1',
      resultZone: '',
    }],
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'STANDARD_SCALES_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'STANDARD_SCALES_ADD_EDIT/CLEAR_STORE',
};

const mapDataToValues = (data) => {
  const scaleValues = _map(data.standard_categorical_scale_values, (el) => ({
    label: el.label || '',
    value: el.value,
    topLimit: _isNumber(el.upper_limit) ? el.upper_limit : '',
    resultZone: el.results_zone || '',
    id: el.id,
  }));

  const boundariesCount = data.standard_categorical_scale_values.length || 0;

  return ({
    name: data.name,
    description: data.description,
    boundariesCount,
    scaleValues,
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const initialValues = action.response
        ? mapDataToValues(action.response.data) : initialState.initialValues;

      return {
        ...state,
        isLoadedPage: true,
        scaleId: action.scaleId,
        isEdit: !!action.scaleId,
        initialValues,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (scaleId, response) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  scaleId,
  response,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadScaleData = (id) => (dispatch) => {
  if (id) {
    return ApiManager.request('get', dispatch, `standard_categorical_scales/${id}`);
  }
  return Promise.resolve();
};

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const store = getStore().StandardScalesAddEdit;
  const { scaleId } = store;
  const isEdit = !!scaleId;
  let url = 'standard_categorical_scales';
  let method = 'post';
  let message = snackbarMessages.standardScaleAddedSuccessfully;

  if (isEdit) {
    url = `standard_categorical_scales/${scaleId}`;
    method = 'put';
    message = snackbarMessages.standardScaleEditedSuccessfully;
  }

  const data = {
    name: values.name,
    description: values.description,
    standard_categorical_scale_values: _map(values.scaleValues, (el, index) => {
      const scaleValue = ({
        label: el.label,
        upper_limit: index === values.scaleValues.length - 1 ? null : Number(el.topLimit),
        results_zone: el.resultZone || '',
      });

      if (isEdit) {
        scaleValue.id = el.id;
      }

      return scaleValue;
    }),
  };

  dispatch(showTransparentLoader());

  ApiManager.request(method, dispatch, url, data).then(() => {
    dispatch(showSnackbar(message));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.standardScalesAddEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(hideLoader());
  });
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const loadPageData = (id, message) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  dispatch(loadScaleData(id)).then((response) => {
    dispatch(loadPageSuccess(id, response));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
