import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    textArea: {
      padding: theme.spacing(0.875, 1.875),
      fontSize: 14,
      lineHeight: '20px',
      cursor: 'auto',
      '&::-webkit-scrollbar': {
        width: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey.scrollbarThumb,
        borderRadius: 6,
      },
      '&::placeholder': {
        color: theme.palette.deepBlue[50],
        opacity: 1,
      },
    },
    multiline: {
      padding: theme.spacing(0.625),
      paddingBottom: theme.spacing(1.625),
    },
    textarea: {
      color: theme.palette.annotationColor,
      '&:disabled': {
        color: theme.palette.annotationColor,
      },
    },
    inputOutline: {
      borderColor: theme.palette.grey.outlinedInputBorder,
    },
  }),
);
