import _map from 'lodash/map';

import ApiManager from 'utils/ApiManager';
import errorCatch from 'utils/errorCatch';
import { FILE_ADD_EDIT } from 'utils/constants/modalTypes';
import getDateFromApi from 'utils/getDateFromApi';
import convertFileSize from 'utils/convertFileSize';
import dialogTexts from 'utils/dialogTexts';
import snackbarMessages from 'utils/snackbarMessages';
import fileDownload, { FILE_DOWNLOAD_ENDPOINTS } from 'utils/fileDownload';

import {
  showLoader,
  hideLoader,
  openDialog,
  setModal,
  showTransparentLoader,
  showSnackbar,
  showLoaderMultipleRequests,
  hideLoaderMultipleRequests,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'FILE_LIST/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'FILE_LIST/CLEAR_STORE',
  LOAD_FILES: 'FILE_LIST/LOAD_FILES',
};

const mapItems = (items) => _map(items, (item) => ({
  id: item.id,
  date: getDateFromApi(item.created_at),
  documentName: item.name,
  size: convertFileSize(item.filesize),
  file: item.file,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapItems(action.files),
        patientId: action.patientId,
      };
    }

    case actionTypes.LOAD_FILES: {
      return {
        ...state,
        items: mapItems(action.files),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (files, patientId) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  files,
  patientId,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadFiles = (files) => ({
  type: actionTypes.LOAD_FILES,
  files,
});

const getFiles = (id) => (dispatch) => {
  const url = `patients/${id}/documents`;

  return ApiManager.request('get', dispatch, url, {});
};

export const refreshFileList = () => (dispatch, getStore) => {
  const { patientId } = getStore().FileList;

  dispatch(showLoader());
  dispatch(getFiles(patientId)).then((response) => {
    dispatch(hideLoader());
    dispatch(loadFiles(response.data));
  });
};

const onDeleteAccept = (id) => async (dispatch) => {
  dispatch(showTransparentLoader());

  try {
    await ApiManager.request('delete', dispatch, `patient_documents/${id}`);
    dispatch(showSnackbar(snackbarMessages.fileDeletedSuccessfully));
    dispatch(hideLoader());
    dispatch(refreshFileList());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteFile,
    onAccept: () => dispatch(onDeleteAccept(id)),
  }));
};

export const onEdit = (id) => (dispatch, getStore) => {
  const { patientId } = getStore().FileList;
  dispatch(showTransparentLoader());
  dispatch(setModal(FILE_ADD_EDIT, { patientId, id }));
};

export const onAdd = () => (dispatch, getStore) => {
  const { patientId } = getStore().FileList;
  dispatch(setModal(FILE_ADD_EDIT, { patientId }));
};

export const onDownload = (id) => (dispatch) => {
  dispatch(showTransparentLoader());
  fileDownload(dispatch, id, FILE_DOWNLOAD_ENDPOINTS.PATIENT_DOCUMENT);
};

export const onView = (id) => (dispatch) => {
  fileDownload(dispatch, id, FILE_DOWNLOAD_ENDPOINTS.PATIENT_DOCUMENT, true);
};

export const loadPageData = (routeState) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoaderMultipleRequests());

  try {
    const files = await dispatch(getFiles(routeState.id));
    dispatch(loadPageSuccess(files.data, routeState.id));
  } catch (error) {
    errorCatch(error, dispatch, false, false);
  } finally {
    dispatch(hideLoaderMultipleRequests());
  }
};
