import moment from 'moment';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';

import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import snackbarMessages from 'utils/snackbarMessages';
import { VAT_INVOICE_ADD_EDIT } from 'utils/constants/modalTypes';
import dialogTexts from 'utils/dialogTexts';
import errorCatch from 'utils/errorCatch';
import getOrderName from 'utils/getOrderName';
import convertFloat from 'utils/convertFloat';

import config from 'config';

import {
  showTransparentLoader, hideLoader, setModal,
  showSnackbar, setPage, setRowsPerPage, openDialog,
} from 'containers/store';
import {
  actionTypes as facilityActionTypes,
  facilityDataRefresh,
  purchaseHistoryRefresh,
  getPurchaseHistoryOrders,
  refreshCreditPointsList,
} from 'containers/Facility/store';

const initialState = {
  isLoadedPage: false,
  clinicId: null,
  startDate: moment().startOf('month'),
  endDate: moment(),
  items: [],
  batteries: [],
  editedRowsIds: [],
  invalidRowsIds: [],
  submittingRowId: null,
};

const actionTypes = {
  LOAD_PAGE_SUCCESS: 'FACILITY/PURCHASE_HISTORY/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'FACILITY/PURCHASE_HISTORY/CLEAR_STORE',
  CHANGE_DATE: 'FACILITY/PURCHASE_HISTORY/CHANGE_DATE',
  TOGGLE_EDIT_STATE: 'FACILITY/PURCHASE_HISTORY/TOGGLE_EDIT_STATE',
  UPDATE_INVALID_ROWS_IDS: 'FACILITY/PURCHASE_HISTORY/UPDATE_INVALID_ROWS_IDS',
  END_SUBMITTING: 'FACILITY/PURCHASE_HISTORY/END_SUBMITTING',
  RETURN_TO_EDIT_MODE: 'FACILITY/PURCHASE_HISTORY/RETURN_TO_EDIT_MODE',
  SET_ORDERS: facilityActionTypes.SET_PURCHASE_HISTORY_ORDERS,
};

const mapItems = (items) => _map(items, (el) => ({
  id: el.id,
  date: el.created_at,
  amountOfTokens: el.amount_of_tokens,
  status: el.status,
  name: el.position_name || getOrderName(el.type, {
    batteryCode: _get(el, 'battery.code', ''),
    amountOfTokens: el.amount_of_tokens || _get(el, 'package.tokens_extra_credit'),
  }),
  type: el.type,
  netValue: el.net_value,
  vatRate: el.vat_rate,
  grossValue: el.gross_value,
  disabledActions: !el.order_invoice || el.status !== 'PROCESSING',
  orderInvoiceId: _get(el, 'order_invoice.id', null),
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        clinicId: action.clinicId,
        items: mapItems(action.orders.data.items),
        totalItems: action.orders.data.total,
      };
    }

    case actionTypes.CHANGE_DATE: {
      return {
        ...state,
        startDate: action.startDate || state.startDate,
        endDate: action.endDate || state.endDate,
      };
    }

    case actionTypes.SET_ORDERS: {
      return {
        ...state,
        items: mapItems(action.orders.data.items, state.batteries),
        totalItems: action.orders.data.total,
      };
    }

    case actionTypes.TOGGLE_EDIT_STATE: {
      const { id } = action;

      const currentEditedItems = state.editedRowsIds;
      const isEditing = _includes(currentEditedItems, id);

      const editedRowsIds = isEditing
        ? _filter(currentEditedItems, (item) => item !== id)
        : [...currentEditedItems, id];

      return {
        ...state,
        submittingRowId: isEditing ? id : state.submittingRowId,
        editedRowsIds,
      };
    }

    case actionTypes.END_SUBMITTING: {
      return {
        ...state,
        submittingRowId: null,
      };
    }

    case actionTypes.RETURN_TO_EDIT_MODE: {
      const { id } = action;

      return {
        ...state,
        submittingRowId: null,
        editedRowsIds: [...state.editedRowsIds, id],
      };
    }

    case actionTypes.UPDATE_INVALID_ROWS_IDS: {
      const { id, isValid } = action;
      const currentInvalidItems = state.invalidRowsIds;

      const isMarkedAsInvalid = _includes(currentInvalidItems, id);

      let invalidRowsIds = currentInvalidItems;

      if (isMarkedAsInvalid && isValid) {
        invalidRowsIds = _filter(invalidRowsIds, (item) => item !== id);
      }

      if (!isMarkedAsInvalid && !isValid) {
        invalidRowsIds = [...invalidRowsIds, id];
      }

      return {
        ...state,
        invalidRowsIds,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (clinicId, orders) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  clinicId,
  orders,
});

const changeDate = (startDate, endDate) => ({
  type: actionTypes.CHANGE_DATE,
  startDate,
  endDate,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const toggleEditState = (id) => ({
  type: actionTypes.TOGGLE_EDIT_STATE,
  id,
});

const returnToEditMode = (id) => ({
  type: actionTypes.RETURN_TO_EDIT_MODE,
  id,
});

export const endSubmitting = (id) => ({
  type: actionTypes.END_SUBMITTING,
  id,
});

const updateInvalidRowsIds = (id, isValid) => ({
  type: actionTypes.UPDATE_INVALID_ROWS_IDS,
  id,
  isValid,
});

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(purchaseHistoryRefresh());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(purchaseHistoryRefresh());
};

export const onDatesChange = ({ startDate, endDate }) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(changeDate(startDate, endDate));
  dispatch(purchaseHistoryRefresh());
};

export const onPreviewClick = (id) => (dispatch, getStore) => {
  const { items } = getStore().FacilityPurchaseHistory;
  const selectedItem = _find(items, (el) => el.id === id);
  dispatch(setModal(VAT_INVOICE_ADD_EDIT, {
    id: selectedItem.orderInvoiceId,
    orderId: id,
  }));
};

const onAcceptConfirm = (id) => async (dispatch) => {
  const orderUrl = createURLWithQuery(`orders/${id}/status`, {
    status: 'COMPLETED',
  });

  dispatch(showTransparentLoader());
  try {
    await ApiManager.request('put', dispatch, orderUrl);
    dispatch(showSnackbar(snackbarMessages.orderCompleted));
    dispatch(refreshCreditPointsList());
    dispatch(facilityDataRefresh());
    dispatch(purchaseHistoryRefresh());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

const onRefuseConfirm = (id) => (dispatch) => {
  const url = createURLWithQuery(`orders/${id}/status`, {
    status: 'CANCELED',
  });

  dispatch(showTransparentLoader());
  ApiManager.request('put', dispatch, url).then(() => {
    dispatch(showSnackbar(snackbarMessages.orderCanceled));
    dispatch(purchaseHistoryRefresh());
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};

export const onEdit = (id) => (dispatch) => {
  dispatch(toggleEditState(id));
};

export const setInvalidRowId = (id, isValid) => (dispatch) => {
  dispatch(updateInvalidRowsIds(id, isValid));
};

export const onSubmit = (values, id) => (dispatch) => {
  const data = {
    created_at: (values.date).format(config.apiDateFormat),
    gross_value: convertFloat.toApiFormat(values.grossValue),
    net_value: convertFloat.toApiFormat(values.netValue),
    position_name: values.name,
    vat_rate: Number(values.vatRate),
  };

  dispatch(showTransparentLoader());
  ApiManager.request('put', dispatch, `orders/${id}`, data).then(() => {
    dispatch(showSnackbar(snackbarMessages.orderUpdated));
    dispatch(purchaseHistoryRefresh(false)).then(() => {
      dispatch(hideLoader());
      dispatch(endSubmitting());
    });
  }).catch((error) => {
    dispatch(returnToEditMode(id));
    errorCatch(error, dispatch);
  });
};

export const onAcceptClick = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.acceptPurchase,
    onAccept: () => { dispatch(onAcceptConfirm(id)); },
  }));
};

export const onRefuseClick = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.refusePurchase,
    onAccept: () => { dispatch(onRefuseConfirm(id)); },
  }));
};

export const loadPageData = (clinicId, values, fromList) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showTransparentLoader());

  if (values.page) {
    dispatch(setPage(Number(values.page)));
  }

  if (values.perPage) {
    dispatch(setRowsPerPage(Number(values.perPage)));
  }

  if (values.dateFrom && values.dateTo) {
    dispatch(changeDate(moment(values.dateFrom), moment(values.dateTo)));
  }

  try {
    const orders = await dispatch(getPurchaseHistoryOrders(clinicId, fromList));
    dispatch(loadPageSuccess(clinicId, orders));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
