import { defineMessages } from 'react-intl';

export default defineMessages({
  addFile: 'Dodaj dokument',
  editFile: 'Edytuj dokument',
  cancel: 'Anuluj',
  save: 'Zapisz',
  name: 'Nazwa dokumentu',
  note: 'Notatka (opcjonalnie)',
  textPlaceholder: 'Zacznij pisać',
});
