import { push } from 'connected-react-router';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _groupBy from 'lodash/groupBy';
import _isEqual from 'lodash/isEqual';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _isNull from 'lodash/isNull';
import _orderBy from 'lodash/orderBy';
import _reject from 'lodash/reject';
import _includes from 'lodash/includes';
import _values from 'lodash/values';
import _flatMap from 'lodash/flatMap';
import _uniq from 'lodash/uniq';
import PromiseAll from 'utils/PromiseAll';
import errorCatch from 'utils/errorCatch';
import createURLWithQuery from 'utils/createURLWithQuery';
import ApiManager from 'utils/ApiManager';
import getDateTimeFromApi from 'utils/getDateTimeFromApi';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import snackbarMessages from 'utils/snackbarMessages';
import roles from 'utils/constants/rolesOfUsers';
import convertSecondsToMinutes from 'utils/convertSecondsToMinutes';
import formatFullName from 'utils/formatFullName';
import standardizedScalesList from 'utils/constants/standarizedScales';
import groupSurveyStatuses from 'utils/constants/groupSurveyStatuses';
import config from 'config';

import {
  showLoader,
  hideLoader,
  showTransparentLoader,
  setPage,
  setSortingData,
  setRowsPerPage,
  setSortingAndPagination,
  showSnackbar,
} from 'containers/store';
import { formatNewNoteData } from 'containers/ExaminationDetails/PatientData/utils';
import {
  formatDimensionCodes,
  formatGraphData,
  filterGraphData,
  getDataWithComparingResults,
  getTableData,
  getResearchToolScales,
  formatComparisonOptions,
  getDefaultComparisonMethod,
  filterResultsToCompare,
} from 'containers/ExaminationDetails/utils';

import {
  formatNotes,
  mapPatients,
  getChangingSurvey,
  formatResultData,
  getAllSurveyCodes,
  getAvarageSurveyTime,
  formatSurveyEvaluationData,
} from './utils';
import { onExportClick } from './PatientsPdf';
import { onExportExaminationClick } from './SurveyPdf';

const defaultSorting = {
  fieldName: 'patient',
  sortDirection: 'asc',
};

const sortFieldsMapping = {
  patient: 'patients|patients.last_name',
  classAndSection: 'class_and_section',
  examinationCode: 'session_code',
  status: 'status',
};

const { comparisonMethods } = config;

const defaultComparisonOptionsPerPage = 20;

const sortBatteryFieldsMapping = {
  name: 'name',
  avarageSurveyTime: 'avarageSurveyTime',
};

const defaultBatterySorting = {
  sortingField: 'name',
  sortDirection: 'desc',
};

const initialState = {
  isLoadedPage: false,
  pageTitle: '',
  isDeleteDialogOpen: false,
  isStopDialogOpen: false,
  resetCodeDialogOpen: {
    isOpen: false,
    id: null,
  },
  examinationDetails: {
    name: '',
    patientAmount: 0,
    avarageSurveyTime: 0,
    selectedTests: '',
    examinationDate: '',
    diagnostician: '',
    notes: [],
    hasActiveSurveys: false,
  },
  status: '',
  newNoteData: {
    noteNumber: null,
    date: '',
    diagnostician: '',
  },
  patients: [],
  totalItemsCount: 0,
  filterData: {
    filterOptions: {
      comparableExaminations: {
        vertical: [],
        horizontal: [],
      },
      primaryDimensions: [],
      standarizedScales: [],
      disableComparison: false,
    },
    filterValues: {
      examinationsDates: [],
      comparisonMethod: comparisonMethods.horizontal,
      standarizedScale: 3,
      qualityFilter: 'showAll',
      dimensionTypeFilter: 'primaryDimension',
      primaryDimension: true,
      secondaryDimension: true,
      tertiaryDimension: true,
      sex: 'all',
    },
  },
};

const actionTypes = {
  LOAD_PAGE_SUCCESS: 'GROUP_EXAMINATION_DETAILS/LOAD_PAGE_SUCCESS',
  SET_DELETE_DIALOG: 'GROUP_EXAMINATION_DETAILS/SET_DELETE_DIALOG',
  SET_STOP_DIALOG: 'GROUP_EXAMINATION_DETAILS/SET_STOP_DIALOG',
  UPDATE_NOTES_DATA: 'GROUP_EXAMINATION_DETAILS/UPDATE_NOTES_DATA',
  SET_FILTERED_PATIENTS: 'GROUP_EXAMINATION_DETAILS/SET_FILTERED_PATIENTS',
  SET_CODE_RESET_DIALOG: 'GROUP_EXAMINATION_DETAILS/SET_RESET_CODE_DIALOG',
  UPDATE_DISPLAYED_RESULT: 'GROUP_EXAMINATION_DETAILS/UPDATE_DISPLAYED_RESULT',
  FILTER_USERS: 'GROUP_EXAMINATION_DETAILS/FILTER_USERS',
  CHANGE_BATTERY_SORT: 'GROUP_EXAMINATION_DETAILS/CHANGE_BATTERY_SORT',
  RESET_STORE: 'GROUP_EXAMINATION_DETAILS/RESET_STORE',
  LOAD_COMPARISON_OPTIONS: 'GROUP_EXAMINATION_DETAILS/LOAD_COMPARISON_OPTIONS',
  LOAD_RESULTS_TO_COMPARE: 'GROUP_EXAMINATION_DETAILS/LOAD_RESULTS_TO_COMPARE',
  RESET_COMPARISON: 'GROUP_EXAMINATION_DETAILS/RESET_COMPARISON',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const {
        groupExaminationId,
        responses,
        standardizedScales,
        standarizedScale,
        userData,
        isViewOnly,
        clinicData,
      } = action;

      const examinationDetails = _get(responses, 'examinationData.data');
      const avarageSurveyTime = getAvarageSurveyTime(examinationDetails.session_patients);

      const newNoteData = formatNewNoteData(
        { last_note_order: _get(examinationDetails, 'notes', []).length },
        userData,
      );
      const sessionPatients = _get(responses, 'examinationData.data.session_patients', []);
      const sessionSurveyTools = _get(responses, 'examinationData.data.survey_tools', []);
      const sessionsToCompare = sessionPatients;

      const resultsFormattedData = _map(sessionSurveyTools,
        (sessionSurveyTool) => formatResultData({
          sessionSurveyTool,
          sessionPatients,
          standarizedScale,
          sessionsToCompare,
          isViewOnly,
          standardizedScales,
          initialState,
        }));

      const allSurveysDimensionCodes = [];
      _forEach(sessionSurveyTools, (sessionSurveyTool) => {
        const scaleParametersData = sessionSurveyTool.psychometricParameters.scale_parameters;
        const scaleLoadsData = sessionSurveyTool.psychometricParameters.scale_charges;
        const scalesData = getResearchToolScales(sessionSurveyTool.psychometricParameters.scales);

        const dimensionCodesData = formatDimensionCodes(
          scalesData,
          scaleLoadsData,
          scaleParametersData,
        );

        allSurveysDimensionCodes.push(...dimensionCodesData);
      });

      const isExaminationStarted = _get(responses, 'examinationData.data.session_patients', []);

      return {
        ...state,
        hasActiveSurveys: _get(examinationDetails, 'has_session_patients_groups_patients_in_progress', false),
        examinationDetails: {
          name: _get(examinationDetails, 'name', ''),
          patientAmount: _get(examinationDetails, 'patients_count', 0),
          avarageSurveyTime: convertSecondsToMinutes(avarageSurveyTime, true, true),
          selectedTests: getAllSurveyCodes(examinationDetails),
          examinationDate: _get(examinationDetails, 'last_session_patient_end_at', false)
            ? getDateTimeFromApi(examinationDetails.last_session_patient_end_at) : '',
          diagnostician: formatFullName(_get(examinationDetails, 'user')),
          notes: formatNotes(_get(examinationDetails, 'notes', [])),
        },
        status: _get(examinationDetails, 'status'),
        newNoteData,
        isLoadedPage: true,
        pageTitle: _get(examinationDetails, 'name', ''),
        groupExaminationId,
        patients: mapPatients(responses.patientsData.data.items),
        totalItemsCount: responses.patientsData.data.total,
        isExaminationStarted,
        resultsFormattedData: _map(
          _groupBy(resultsFormattedData, (result) => result.battery.id),
          (batteryTools) => {
            const battery = _get(batteryTools, '[0].battery', {});

            return {
              ...battery,
              sortProps: {
                ...defaultBatterySorting,
              },
              surveyTools: _orderBy(
                batteryTools,
                [defaultBatterySorting.sortingField],
                [defaultBatterySorting.sortDirection],
              ),
            };
          },
        ),
        dimensionsCodes: allSurveysDimensionCodes,
        responses: {
          ...responses,
          standardizedScales,
          standarizedScale,
          isViewOnly,
        },
        showConfidenceInterval: clinicData.data.show_confidence_interval,
      };
    }

    case actionTypes.SET_DELETE_DIALOG: {
      return {
        ...state,
        isDeleteDialogOpen: action.responses.isOpen,
      };
    }

    case actionTypes.SET_STOP_DIALOG: {
      return {
        ...state,
        isStopDialogOpen: action.responses.isOpen,
      };
    }

    case actionTypes.UPDATE_NOTES_DATA: {
      const newNoteData = formatNewNoteData(
        { last_note_order: _get(action, 'examinationData.data.notes', []).length },
        action.userData,
      );

      return {
        ...state,
        examinationDetails: {
          ...state.examinationDetails,
          notes: formatNotes(action.examinationData.data.notes),
          newNoteData,
        },
      };
    }

    case actionTypes.SET_FILTERED_PATIENTS: {
      const totalItemsCount = action.responses.data.total;
      const patients = mapPatients(action.responses.data.items);

      return {
        ...state,
        patients,
        totalItemsCount,
      };
    }

    case actionTypes.SET_CODE_RESET_DIALOG: {
      return {
        ...state,
        resetCodeDialogOpen: {
          isOpen: action.responses.isOpen,
          id: action.responses.isOpen ? action.responses.id : null,
        },
      };
    }

    case actionTypes.UPDATE_DISPLAYED_RESULT: {
      const {
        expandedDimensions,
        filterValues,
        batteryId,
        surveyId,
      } = action;
      const sexFilterMap = {
        all: 'all',
        girls: false,
        boys: true,
      };

      const changedSurvey = getChangingSurvey(state.resultsFormattedData, batteryId, surveyId);

      const sessionPatients = _get(state.responses, 'examinationData.data.session_patients', []);
      const sessionPatientsIds = _map(sessionPatients, 'id');

      const resultsToCompareWith = _filter(
        changedSurvey.initialData.sessionsToCompare,
        (session) => session.patient && !_includes(sessionPatientsIds, session.id) && (
          session.patient.sex === sexFilterMap[filterValues.sex]
            || filterValues.sex === sexFilterMap.all
        ),
      );

      const groupedResults = _groupBy(resultsToCompareWith, 'session_patient_group_id');

      const calculated = _map(
        groupedResults,
        (results) => formatSurveyEvaluationData(
          results,
          changedSurvey.initialData.standardScaleData,
          changedSurvey.initialData.scalesData,
        ),
      );

      const dataWithComparingResults = getDataWithComparingResults(
        changedSurvey.initialData.surveyEvaluationData,
        _flatMap(calculated),
        filterValues,
      );

      const newGraphData = formatGraphData(
        changedSurvey.initialData.standardScaleData,
        dataWithComparingResults,
        changedSurvey.initialData.dimensionCodesData,
        expandedDimensions,
        filterValues,
        [...changedSurvey.initialData.sessionsToCompare, ..._flatMap(calculated)],
        changedSurvey.initialData.batteryCode,
        changedSurvey.initialData.scalesData,
        true,
      );

      const filteredGraphData = filterGraphData(
        newGraphData.data,
        newGraphData.referenceLines,
        filterValues,
      );

      const graphData = {
        ...newGraphData,
        data: filteredGraphData,
      };

      const metricsTableData = getTableData(
        graphData,
        changedSurvey.initialData.sessionPatientId,
        filterValues,
      );

      let graphDataExpanded = changedSurvey.graphDataExpanded;
      let metricsTableDataExpanded = changedSurvey.metricsTableDataExpanded;

      if (
        changedSurvey.filterData.filterValues.standarizedScale
        !== filterValues.standarizedScale
      ) {
        graphDataExpanded = formatGraphData(
          changedSurvey.initialData.standardScaleData,
          changedSurvey.initialData.surveyEvaluationData,
          changedSurvey.initialData.dimensionCodesData,
          changedSurvey.initialData.allDimensionsCodes,
          filterValues,
          changedSurvey.initialData.sessionsToCompare,
          '',
          changedSurvey.initialData.scalesData,
          true,
        );

        metricsTableDataExpanded = getTableData(
          graphDataExpanded,
          changedSurvey.initialData.sessionPatientId,
          filterValues,
        );
      }

      return {
        ...state,
        resultsFormattedData: _map(state.resultsFormattedData, (battery) => {
          if (battery.id === batteryId) {
            return {
              ...battery,
              surveyTools: _map(_get(battery, 'surveyTools', []), (survey) => {
                if (survey.id === surveyId) {
                  return {
                    ...survey,
                    graphData,
                    expandedDimensions,
                    metricsTableData,
                    graphDataExpanded,
                    metricsTableDataExpanded,
                    filterData: {
                      ...survey.filterData,
                      filterValues,
                    },
                  };
                }

                return survey;
              }),
            };
          }

          return battery;
        }),
      };
    }

    case actionTypes.FILTER_USERS: {
      const sexFilterMap = {
        all: 'all',
        girls: false,
        boys: true,
      };

      const {
        expandedDimensions,
        value,
        batteryId,
        surveyId,
      } = action;

      const {
        standardizedScales,
        standarizedScale,
        isViewOnly,
      } = state.responses;

      const sessionPatients = _filter(
        _get(state.responses, 'examinationData.data.session_patients', []),
        (sessionPatient) => sessionPatient.patient
        && (sessionPatient.patient.sex === sexFilterMap[value]
          || value === sexFilterMap.all),
      );

      const sessionSurveyTools = _get(state.responses, 'examinationData.data.survey_tools', []);

      const changedSurvey = _find(sessionSurveyTools, (surveyTool) => surveyTool.id === surveyId);

      const currentSurvey = getChangingSurvey(state.resultsFormattedData, batteryId, surveyId);
      const currentStandarizedScale = _get(
        currentSurvey, 'filterData.filterValues.standarizedScale', standarizedScale,
      );

      const sessionsToCompare = [
        ...sessionPatients,
        ...currentSurvey.initialData.sessionsToCompare,
      ];

      const sessionPatientsIds = _map(sessionPatients, 'id');

      const resultsToCompareWith = _filter(
        currentSurvey.initialData.sessionsToCompare,
        (session) => session.patient && !_includes(sessionPatientsIds, session.id) && (
          session.patient.sex === sexFilterMap[value] || value === sexFilterMap.all
        ),
      );

      const groupedResults = _groupBy(resultsToCompareWith, 'session_patient_group_id');

      const calculated = _map(
        groupedResults,
        (results) => formatSurveyEvaluationData(
          results,
          currentSurvey.initialData.standardScaleData,
          currentSurvey.initialData.scalesData,
        ),
      );

      const examinationsDates = _uniq(_map(_flatMap(_values(calculated)), 'session_patient_id'));

      const currentFilterValues = _get(
        getChangingSurvey(state.resultsFormattedData, batteryId, surveyId), 'filterData.filterValues', {},
      );

      currentFilterValues.examinationsDates = examinationsDates;

      const formattedResultData = formatResultData({
        sessionSurveyTool: changedSurvey,
        sessionPatients,
        standarizedScale: currentStandarizedScale,
        sessionsToCompare,
        isViewOnly,
        standardizedScales,
        initialState,
        expandedDimensions,
        currentFilterValues,
        resultsToCompare: _flatMap(calculated),
      });

      if (_isNull(formattedResultData.graphData) || _isNull(formattedResultData.metricsTableData)) {
        return {
          ...state,
          resultsFormattedData: _map(state.resultsFormattedData, (battery) => {
            if (battery.id === batteryId) {
              return {
                ...battery,
                surveyTools: _map(_get(battery, 'surveyTools', []), (survey) => {
                  if (survey.id === surveyId) {
                    return {
                      ...survey,
                      graphData: null,
                      metricsTableData: null,
                      filterData: {
                        ...survey.filterData,
                        filterValues: {
                          ...survey.filterData.filterValues,
                          sex: value,
                        },
                      },
                    };
                  }

                  return survey;
                }),
              };
            }

            return battery;
          }),
        };
      }

      const filteredGraphData = filterGraphData(
        formattedResultData.graphData.data,
        formattedResultData.graphData.referenceLines,
        currentFilterValues,
      );

      const graphData = {
        ...formattedResultData.graphData,
        data: filteredGraphData,
      };

      const sessionPatientId = formattedResultData.initialData.sessionPatientId;

      const metricsTableData = getTableData(
        graphData,
        sessionPatientId,
        currentFilterValues,
      );

      return {
        ...state,
        resultsFormattedData: _map(state.resultsFormattedData, (battery) => {
          if (battery.id === batteryId) {
            return {
              ...battery,
              surveyTools: _map(_get(battery, 'surveyTools', []), (survey) => {
                if (survey.id === surveyId) {
                  return {
                    ...survey,
                    graphData,
                    metricsTableData,
                    filterData: {
                      ...survey.filterData,
                      filterValues: {
                        ...survey.filterData.filterValues,
                        examinationsDates,
                        sex: value,
                      },
                    },
                    initialData: {
                      ...survey.initialData,
                      sessionPatientId,
                      surveyEvaluationData: formattedResultData.initialData.surveyEvaluationData,
                    },
                  };
                }

                return survey;
              }),
            };
          }

          return battery;
        }),
      };
    }

    case actionTypes.CHANGE_BATTERY_SORT: {
      const battery = _find(state.resultsFormattedData, (b) => b.id === action.batteryId);

      return {
        ...state,
        resultsFormattedData: _map(state.resultsFormattedData, (b) => {
          if (b.id === battery.id) {
            return {
              ...battery,
              surveyTools: _orderBy(
                battery.surveyTools,
                [action.sortData.fieldName],
                [action.sortData.sortDirection],
              ),
              sortProps: {
                sortingField: action.sortData.fieldName,
                sortDirection: action.sortData.sortDirection,
              },
            };
          }

          return b;
        }),
      };
    }

    case actionTypes.RESET_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.LOAD_COMPARISON_OPTIONS: {
      const batteryId = action.batteryId;
      const surveyId = action.surveyId;
      const changedSurvey = getChangingSurvey(state.resultsFormattedData, batteryId, surveyId);
      const sessionsToCompare = [
        ...(changedSurvey.initialData.fetchedOptions || []),
        ...action.comparisonOptions];
      const totalComparisonOptions = action.totalComparisonOptions;
      const groupExaminationId = state.groupExaminationId;

      const comparisonOptions = _reject(
        [...changedSurvey.initialData.sessionsToCompare, ...sessionsToCompare],
        (option) => _find(
          changedSurvey.initialData.sessionPatients,
          (patientSession) => patientSession.id === option.id,
        ),
      );

      const { verticalFilterOptions, horizontalFilterOptions } = formatComparisonOptions(
        comparisonOptions,
        surveyId,
        groupExaminationId,
        true,
      );

      return {
        ...state,
        resultsFormattedData: _map(state.resultsFormattedData, (battery) => {
          if (battery.id === batteryId) {
            return {
              ...battery,
              surveyTools: _map(_get(battery, 'surveyTools', []), (survey) => {
                if (survey.id === surveyId) {
                  return {
                    ...survey,
                    filterData: {
                      filterValues: {
                        ...survey.filterData.filterValues,
                        comparisonMethod: getDefaultComparisonMethod(
                          horizontalFilterOptions, verticalFilterOptions,
                        ),
                      },
                      filterOptions: {
                        ...survey.filterData.filterOptions,
                        comparableExaminations: {
                          vertical: verticalFilterOptions,
                          horizontal: horizontalFilterOptions,
                        },
                      },
                    },
                    initialData: {
                      ...survey.initialData,
                      fetchedOptions: [
                        ...(survey.initialData.fetchedOptions || []),
                        ...action.comparisonOptions,
                      ],
                      optionsToCompareNextPage: survey.initialData.optionsToCompareNextPage + 1,
                      totalComparisonOptions,
                    },
                  };
                }

                return survey;
              }),
            };
          }

          return battery;
        }),
      };
    }

    case actionTypes.LOAD_RESULTS_TO_COMPARE: {
      const {
        results,
        sessionsToCompare,
        batteryId,
        surveyId,
      } = action;
      const groupExaminationId = state.groupExaminationId;
      const currentSurvey = getChangingSurvey(state.resultsFormattedData, batteryId, surveyId);

      const resultsToCompare = filterResultsToCompare(
        results, currentSurvey.initialData.sessionPatientId,
      );

      const { verticalFilterOptions, horizontalFilterOptions } = formatComparisonOptions(
        sessionsToCompare,
        surveyId,
        groupExaminationId,
        false,
      );

      return {
        ...state,
        resultsFormattedData: _map(state.resultsFormattedData, (battery) => {
          if (battery.id === batteryId) {
            return {
              ...battery,
              surveyTools: _map(_get(battery, 'surveyTools', []), (survey) => {
                if (survey.id === surveyId) {
                  return {
                    ...survey,
                    resultsToCompare: [
                      ...survey.resultsToCompare,
                      ...resultsToCompare,
                    ],
                    filterData: {
                      ...survey.filterData,
                      filterOptions: {
                        ...survey.filterData.filterOptions,
                        comparableExaminations: {
                          vertical: verticalFilterOptions,
                          horizontal: horizontalFilterOptions,
                        },
                      },
                    },
                    initialData: {
                      ...survey.initialData,
                      sessionsToCompare: [
                        ...survey.initialData.sessionsToCompare,
                        ...sessionsToCompare,
                      ],
                    },
                    comparableResultsLoaded: true,
                  };
                }

                return survey;
              }),
            };
          }

          return battery;
        }),
      };
    }

    case actionTypes.RESET_COMPARISON: {
      const {
        batteryId,
        surveyId,
      } = action;
      const currentSurvey = getChangingSurvey(state.resultsFormattedData, batteryId, surveyId);

      const filterValues = {
        ...currentSurvey.filterData.filterValues,
        examinationsDates: [],
        comparisonMethod:
          getDefaultComparisonMethod(
            currentSurvey.filterData.filterOptions.comparableExaminations.horizontal,
            currentSurvey.filterData.filterOptions.comparableExaminations.vertical,
          ),
      };

      const graphData = formatGraphData(
        currentSurvey.initialData.standardScaleData,
        currentSurvey.initialData.surveyEvaluationData,
        currentSurvey.initialData.dimensionCodesData,
        currentSurvey.expandedDimensions,
        filterValues,
        currentSurvey.initialData.sessionsToCompare,
        '',
        currentSurvey.initialData.scalesData,
        true,
      );
      const metricsTableData = getTableData(
        graphData,
        currentSurvey.initialData.sessionPatientId,
        filterValues,
      );

      return {
        ...state,
        resultsFormattedData: _map(state.resultsFormattedData, (battery) => {
          if (battery.id === batteryId) {
            return {
              ...battery,
              surveyTools: _map(_get(battery, 'surveyTools', []), (survey) => {
                if (survey.id === surveyId) {
                  return {
                    ...survey,
                    graphData,
                    metricsTableData,
                    filterData: {
                      ...survey.filterData,
                      filterValues,
                    },
                    resultsToCompare: [],
                    initialData: {
                      ...survey.initialData,
                      sessionsToCompare: survey.initialData.sessionPatients,
                      optionsToCompareNextPage: 1,
                      fetchedOptions: [],
                    },
                  };
                }

                return survey;
              }),
            };
          }

          return battery;
        }),
      };
    }

    default: {
      return state;
    }
  }
};

const loadPageSuccess = (
  groupExaminationId,
  responses,
  standarizedScale,
  standardizedScales,
  isViewOnly,
  userData,
  clinicData,
) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  groupExaminationId,
  responses,
  standarizedScale,
  standardizedScales,
  isViewOnly,
  userData,
  clinicData,
});

const setDeleteDialog = (responses) => ({
  type: actionTypes.SET_DELETE_DIALOG,
  responses,
});

const setStopDialog = (responses) => ({
  type: actionTypes.SET_STOP_DIALOG,
  responses,
});

const setCodeResetDialog = (responses) => ({
  type: actionTypes.SET_CODE_RESET_DIALOG,
  responses,
});

const updateNotesData = (userData, examinationData) => ({
  type: actionTypes.UPDATE_NOTES_DATA,
  userData,
  examinationData,
});

const setFilteredPatients = (responses) => ({
  type: actionTypes.SET_FILTERED_PATIENTS,
  responses,
});

const updateDisplayedResult = (expandedDimensions, filterValues, batteryId, surveyId) => ({
  type: actionTypes.UPDATE_DISPLAYED_RESULT,
  expandedDimensions,
  filterValues,
  batteryId,
  surveyId,
});

const filterUsers = (expandedDimensions, value, batteryId, surveyId) => ({
  type: actionTypes.FILTER_USERS,
  batteryId,
  surveyId,
  value,
  expandedDimensions,
});

const changeBatterySort = (batteryId, sortData) => ({
  type: actionTypes.CHANGE_BATTERY_SORT,
  batteryId,
  sortData,
});

const resetStore = () => ({
  type: actionTypes.RESET_STORE,
});

const loadComparisonOptions = (comparisonOptions, totalComparisonOptions, batteryId, surveyId) => ({
  type: actionTypes.LOAD_COMPARISON_OPTIONS,
  comparisonOptions,
  totalComparisonOptions,
  batteryId,
  surveyId,
});

const loadResultsToCompare = (results, sessionsToCompare, batteryId, surveyId) => ({
  type: actionTypes.LOAD_RESULTS_TO_COMPARE,
  results,
  sessionsToCompare,
  surveyId,
  batteryId,
});

const closeComparison = (batteryId, surveyId) => ({
  type: actionTypes.RESET_COMPARISON,
  batteryId,
  surveyId,
});

const getExaminationData = (id) => (dispatch) => {
  const url = `session_patient_groups/${id}`;

  return ApiManager.request('get', dispatch, url);
};

const loadPatientsData = (id, params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: sortFieldsMapping[defaultSorting.fieldName],
  };

  if (params.sortedBy && params.orderBy) {
    data.orderBy = params.orderBy;
    data.sortedBy = params.sortedBy;
  }

  if (params.orderBy === sortFieldsMapping.classAndSection) {
    data.orderBy = undefined;
    data.orderBySchoolClassAndSection = true;
  }

  if (params.orderBy === sortFieldsMapping.status) {
    data.orderBy = undefined;
    data.orderByStatus = true;
  }

  const url = createURLWithQuery(`session_patient_groups/${id}/session_patient_groups_patients`, data);

  return ApiManager.request('get', dispatch, url);
};

const getPsychometricParameters = (id) => (dispatch) => {
  const url = createURLWithQuery('psychometric_parameters', {
    findBySurveyTool: id,
  });

  return ApiManager.request('get', dispatch, url);
};

const getStandardScaleData = (id) => (dispatch) => {
  const url = createURLWithQuery(`standard_categorical_scales/${id}`, {
    appendStandardScales: true,
  });

  return ApiManager.request('get', dispatch, url);
};

const getClinicData = (id) => (dispatch) => {
  const url = `clinics/${id}`;

  return ApiManager.request('get', dispatch, url);
};

const deleteExamination = (id) => (dispatch) => {
  const url = createURLWithQuery(`session_patient_groups/${id}`);

  return ApiManager.request('delete', dispatch, url);
};

const stopExamination = (id) => (dispatch) => {
  const url = createURLWithQuery(`session_patient_groups/${id}/status`);

  return ApiManager.request('put', dispatch, url, { status: 'ENDED' });
};

const resetCode = (id) => (dispatch) => {
  const url = createURLWithQuery(`session_patient_groups_patients/${id}/code`);

  return ApiManager.request('put', dispatch, url);
};

const getPatientsByPage = () => async (dispatch, getStore) => {
  const groupExaminationId = getStore().GroupExaminationDetails.groupExaminationId;
  const data = {
    sortedBy: sortFieldsMapping[defaultSorting.fieldName],
    orderBy: defaultSorting.orderBy,
  };

  const url = createURLWithQuery(`session_patient_groups/${groupExaminationId}/session_patient_groups_patients`, data);

  return ApiManager.request('get', dispatch, url);
};

export const goToList = () => (dispatch) => {
  dispatch(push('/session_patient_groups'));
};

const getSessionsToCompare = (examinationId, surveyId, page, perPage) => (dispatch) => {
  const data = {
    perPage,
    page,
  };

  const url = createURLWithQuery(`session_patient_groups/${examinationId}/survey_tools/${surveyId}`, data);

  return ApiManager.request('get', dispatch, url, data);
};

export const onExpandClick = (batteryId, surveyId) => (dispatch, getStore) => (id) => {
  const { resultsFormattedData } = getStore().GroupExaminationDetails;
  const {
    filterData,
    expandedDimensions,
  } = getChangingSurvey(resultsFormattedData, batteryId, surveyId);

  const { filterValues } = filterData;

  const newExpandedDimensions = [...expandedDimensions, id];
  dispatch(updateDisplayedResult(newExpandedDimensions, filterValues, batteryId, surveyId));
};

export const onShrinkClick = (batteryId, surveyId) => (dispatch, getStore) => (id) => {
  const { resultsFormattedData } = getStore().GroupExaminationDetails;
  const {
    filterData,
    expandedDimensions,
  } = getChangingSurvey(resultsFormattedData, batteryId, surveyId);

  const { filterValues } = filterData;
  const newExpandedDimensions = _filter(expandedDimensions, (dimension) => dimension !== id);
  dispatch(updateDisplayedResult(newExpandedDimensions, filterValues, batteryId, surveyId));
};

export const onStandarizedScaleChange = (
  batteryId, surveyId,
) => (dispatch, getStore) => (value) => {
  const { resultsFormattedData } = getStore().GroupExaminationDetails;
  const {
    filterData,
    expandedDimensions,
  } = getChangingSurvey(resultsFormattedData, batteryId, surveyId);
  const { filterValues } = filterData;
  const values = {
    ...filterValues,
    standarizedScale: value,
  };

  dispatch(updateDisplayedResult(expandedDimensions, values, batteryId, surveyId));
};

export const onFiltersSave = (batteryId, surveyId) => (dispatch, getStore) => (values) => {
  const { resultsFormattedData } = getStore().GroupExaminationDetails;
  const {
    filterData: { filterValues },
    expandedDimensions,
  } = getChangingSurvey(resultsFormattedData, batteryId, surveyId);

  if (!_isEqual(filterValues, values)) {
    dispatch(updateDisplayedResult(expandedDimensions, values, batteryId, surveyId));
  }
};

export const onSexFilterChange = (batteryId, surveyId) => (dispatch, getStore) => (value) => {
  const { resultsFormattedData } = getStore().GroupExaminationDetails;
  const {
    filterData: { filterValues },
    expandedDimensions,
  } = getChangingSurvey(resultsFormattedData, batteryId, surveyId);

  if (!_isEqual(filterValues.sex, value)) {
    dispatch(filterUsers(
      expandedDimensions,
      value,
      batteryId,
      surveyId,
    ));
  }
};

export const loadPageData = (groupExaminationId) => async (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(resetStore());
  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, {}));

  const data = getSortAndPageData(getStore, sortFieldsMapping);

  const { userData, globalData } = getStore().Global;
  const isViewOnly = userData.role_id === roles.accountManager;
  const standardizedScales = globalData.standardizedScales;

  const standarizedScale = _get(_find(
    standardizedScales, (scale) => scale.id === userData.standard_scale_id,
  ), 'name', standardizedScalesList.CENTYL) === standardizedScalesList.CENTYL
    ? _find(standardizedScales, (scale) => scale.name === standardizedScalesList.STANIN).id
    : userData.standard_scale_id;

  return PromiseAll({
    examinationData: dispatch(getExaminationData(groupExaminationId)),
    patientsData: dispatch(loadPatientsData(groupExaminationId, data)),
  }).then(async (responses) => {
    const surveyIds = _map(_get(responses, 'examinationData.data.survey_tools', []), (survey) => survey.id);

    let psychometricParameters = {};
    _forEach(surveyIds, (id) => {
      psychometricParameters[id] = dispatch(getPsychometricParameters(id));
    });

    psychometricParameters = await PromiseAll(psychometricParameters);

    const standardScalesIds = _map(
      _get(responses, 'examinationData.data.survey_tools', []),
      (survey) => survey.standard_categorical_scale_id,
    );

    let standardScalesData = {};
    _forEach(standardScalesIds, (id) => {
      standardScalesData[id] = dispatch(getStandardScaleData(id));
    });

    standardScalesData = await PromiseAll(standardScalesData);

    const clinicData = await dispatch(getClinicData(responses.examinationData.data.clinic_id));

    dispatch(loadPageSuccess(
      groupExaminationId,
      {
        ...responses,
        examinationData: {
          data: {
            ...responses.examinationData.data,
            survey_tools: _map(responses.examinationData.data.survey_tools, (tool) => ({
              ...tool,
              psychometricParameters: psychometricParameters[tool.id].data,
              standardScaleData: standardScalesData[tool.standard_categorical_scale_id].data,
            })),
          },
        },
      },
      standarizedScale,
      standardizedScales,
      isViewOnly,
      userData,
      clinicData,
    ));

    dispatch(hideLoader());

    return Promise.resolve();
  }).catch((err) => {
    errorCatch(err, dispatch, true, true);
  });
};

export const exportPatientList = (intl, theme, reportName) => async (dispatch) => {
  dispatch(showTransparentLoader());
  try {
    const combinedPatients = await dispatch(getPatientsByPage());
    const formattedCombinedPatients = mapPatients(combinedPatients.data.items, intl);
    await onExportClick(intl, theme, reportName, formattedCombinedPatients);
  } catch (error) {
    errorCatch(error, dispatch);
  }

  dispatch(hideLoader());
};

export const exportExaminationClick = (
  intl, theme, reportName, surveyId, descriptionData,
) => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  try {
    const combinedPatients = await dispatch(getPatientsByPage());
    const formattedCombinedPatients = mapPatients(combinedPatients.data.items, intl);

    const notes = getStore().GroupExaminationDetails.examinationDetails.notes;

    await onExportExaminationClick(
      intl,
      theme,
      reportName,
      formattedCombinedPatients,
      surveyId,
      notes,
      descriptionData,
    );
  } catch (error) {
    errorCatch(error, dispatch);
  }

  dispatch(hideLoader());
};

export const onEditGroupExamination = () => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const groupExaminationId = getStore().GroupExaminationDetails.groupExaminationId;
  const examinationData = await dispatch(getExaminationData(groupExaminationId));

  if (_get(examinationData, 'data.status') !== groupSurveyStatuses.PREPARED) {
    dispatch(showSnackbar(snackbarMessages.cannotEditStartedExamination));
    dispatch(hideLoader());
    return;
  }

  dispatch(push('/GroupSurveyAddEdit', { id: groupExaminationId }));
};

export const onSetDeleteDialog = (isOpen) => (dispatch) => {
  dispatch(setDeleteDialog({ isOpen }));
};

export const onSetStopDialog = (isOpen) => (dispatch) => {
  dispatch(setStopDialog({ isOpen }));
};

export const onSetCodeResetDialog = (isOpen, id) => (dispatch) => {
  dispatch(setCodeResetDialog({ isOpen, id }));
};

const getPatients = () => async (dispatch, getStore) => {
  const { groupExaminationId } = getStore().GroupExaminationDetails;
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  dispatch(showTransparentLoader());
  try {
    const patientsData = await dispatch(loadPatientsData(groupExaminationId, data));
    dispatch(setFilteredPatients(patientsData));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onResetCode = (id) => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  dispatch(onSetCodeResetDialog(false, null));

  try {
    const { groupExaminationId } = getStore().GroupExaminationDetails;
    await dispatch(resetCode(id));
    await dispatch(loadPageData(groupExaminationId));
    dispatch(showSnackbar(snackbarMessages.codeReset));
    dispatch(hideLoader());
  } catch (error) {
    if (_includes(error.error.message, 'in progress')) {
      dispatch(showSnackbar(snackbarMessages.cannotResetCodeInProgress));
      dispatch(hideLoader());
      return;
    }
    errorCatch(error, dispatch);
  }
};

export const onDeleteExamination = () => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const { groupExaminationId } = getStore().GroupExaminationDetails;
  const examinationData = await dispatch(getExaminationData(groupExaminationId));

  if (_get(examinationData, 'data.status') !== groupSurveyStatuses.PREPARED) {
    dispatch(showSnackbar(snackbarMessages.cannotDeleteStartedExamination));
    dispatch(onSetDeleteDialog(false));
    dispatch(hideLoader());
    return;
  }

  dispatch(deleteExamination(groupExaminationId)).then(() => {
    dispatch(push('/session_patient_groups'));
    dispatch(resetStore());
    dispatch(hideLoader());
  }).catch((err) => {
    errorCatch(err, dispatch, true, true);
  });
};

export const onStopExamination = () => async (dispatch, getStore) => {
  dispatch(showLoader());
  const { groupExaminationId } = getStore().GroupExaminationDetails;
  const examinationData = await dispatch(getExaminationData(groupExaminationId));

  dispatch(onSetStopDialog(false));
  if (_get(examinationData, 'data.has_session_patients_groups_patients_in_progress', false)) {
    dispatch(showSnackbar(snackbarMessages.cannotStopExaminationWithActiveSurveys));
    dispatch(resetStore());
    dispatch(loadPageData(groupExaminationId));
    return;
  }

  dispatch(stopExamination(groupExaminationId)).then(() => {
    dispatch(resetStore());
    dispatch(loadPageData(groupExaminationId));
  }).catch((err) => {
    errorCatch(err, dispatch, true, true);
  });
};

const reloadNotesData = () => (dispatch, getStore) => {
  const { userData } = getStore().Global;
  const { groupExaminationId } = getStore().GroupExaminationDetails;

  dispatch(getExaminationData(groupExaminationId)).then((examinationData) => {
    dispatch(updateNotesData(userData, examinationData));
    dispatch(hideLoader());
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};

export const onNoteSave = (values) => (dispatch, getStore) => {
  const { note } = values;
  const { groupExaminationId } = getStore().GroupExaminationDetails;
  dispatch(showTransparentLoader());

  const url = `session_patient_groups/${groupExaminationId}/notes`;

  return ApiManager.request('post', dispatch, url, { note }).then(() => {
    dispatch(reloadNotesData());
  });
};

export const onChangePage = (_, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getPatients());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const { sortedBy, orderBy } = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, sortedBy, orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getPatients());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getPatients());
};

export const onRedirectToPatient = (id) => (dispatch) => {
  dispatch(push('/patientView', { id }));
};

export const onChangeBatterySort = (batteryId) => (dispatch, getStore) => (fieldName) => {
  const batteries = getStore().GroupExaminationDetails.resultsFormattedData;
  const battery = _find(batteries, (b) => b.id === batteryId);

  const { sortDirection, sortingField } = battery.sortProps;

  const data = getFieldAndSortDirection(
    fieldName,
    sortDirection,
    sortingField,
    sortBatteryFieldsMapping[fieldName],
  );

  dispatch(changeBatterySort(batteryId, data));
};

export const onComparisonOpen = (
  batteryId, surveyId,
) => (dispatch, getStore) => async (openModal) => {
  dispatch(showTransparentLoader());

  const { resultsFormattedData } = getStore().GroupExaminationDetails;
  const currentSurvey = getChangingSurvey(resultsFormattedData, batteryId, surveyId);

  const page = currentSurvey.initialData.optionsToCompareNextPage;

  const { groupExaminationId } = getStore().GroupExaminationDetails;
  const response = await dispatch(
    getSessionsToCompare(groupExaminationId, surveyId, page, defaultComparisonOptionsPerPage),
  );
  dispatch(loadComparisonOptions(response.data.items, response.data.total, batteryId, surveyId));

  dispatch(hideLoader());
  openModal();
};

export const onComparisonSave = (batteryId, surveyId) => (dispatch, getStore) => async (values) => {
  dispatch(showTransparentLoader());
  const { resultsFormattedData } = getStore().GroupExaminationDetails;
  const changedSurvey = getChangingSurvey(resultsFormattedData, batteryId, surveyId);

  const standardScaleData = changedSurvey.initialData.standardScaleData;
  const scalesData = changedSurvey.initialData.scalesData;

  let comparisonSurveys = {};
  _forEach(values.examinationsDates, (id) => {
    comparisonSurveys[id] = dispatch(getExaminationData(id));
  });

  comparisonSurveys = await PromiseAll(comparisonSurveys);

  const sessionsToCompare = _flatMap(
    _map(comparisonSurveys, (survey) => survey.data.session_patients),
  );

  comparisonSurveys = _map(comparisonSurveys, (survey) => {
    const sessions = _filter(
      survey.data.session_patients,
      (sessionPatient) => sessionPatient.session.survey_tool_id === surveyId,
    );

    const result = formatSurveyEvaluationData(sessions, standardScaleData, scalesData);

    return result;
  });

  dispatch(loadResultsToCompare(
    _flatMap(_values(comparisonSurveys)),
    sessionsToCompare,
    batteryId,
    surveyId,
  ));

  const examinationsDates = _uniq(_map(_flatMap(_values(comparisonSurveys)), 'session_patient_id'));

  dispatch(onFiltersSave(batteryId, surveyId))({ ...values, examinationsDates });

  dispatch(hideLoader());
};

export const onCloseComparison = (batteryId, surveyId) => (dispatch) => () => {
  dispatch(closeComparison(batteryId, surveyId));
};
