const DEFAULT_ROUTE = '/userProfile';

const homeRouteByRole = {
  admin: '/users',
  pollster: DEFAULT_ROUTE,
  diagnostician: '/patients',
  clinic_coordinator: '/patients',
  sales_coordinator: '/users',
  product_owner: DEFAULT_ROUTE,
};

const getHomeRouteByRole = (role) => (
  homeRouteByRole[role] ? homeRouteByRole[role] : DEFAULT_ROUTE
);

export default getHomeRouteByRole;
