import { push, replace } from 'connected-react-router';
import _map from 'lodash/map';
import _omit from 'lodash/omit';

import config from 'config';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import dialogTexts from 'utils/dialogTexts';

import {
  hideLoader, showSnackbar, showLoader, showTransparentLoader, setSortingAndPagination,
  openDialog, setPage, setRowsPerPage, setLastFilterValues, setSortingData,
} from 'containers/store';

const defaultSorting = {
  fieldName: 'name',
  sortDirection: 'asc',
};

const sortFieldsMapping = {
  name: 'name',
  battery: 'batteries|batteries.name',
  description: 'description',
  status: 'status',
  survey: 'surveys|surveys.name',
};

export const initialState = {
  isLoadedPage: false,
  isViewOnly: false,
  items: [],
  batteriesList: [],
  surveysList: [],
  surveySuggestions: [],
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'RESEARCH_TOOLS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_RESEARCH_TOOLS: 'RESEARCH_TOOLS/SET_FILTERED_RESEARCH_TOOLS',
  SET_SURVEY_SUGGESTIONS: 'RESEARCH_TOOLS/SET_SURVEY_SUGGESTIONS',
  CLEAR_SURVEY_SUGGESTIONS: 'RESEARCH_TOOLS/CLEAR_SURVEY_SUGGESTIONS',
};

const mapSecondaryTools = (items) => _map(items, (item) => ({
  name: item.name,
  id: item.id,
  order: item.order,
}));

const mapTools = (researchTools, startingPosition) => _map(researchTools, (el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: el.name,
  description: el.description,
  battery: el.battery_id,
  status: el.status,
  survey: el.survey_id,
  refGroup: el.reference_group_set_id,
  secondaryTools: mapSecondaryTools(el.secondary_survey_tools),
}));

const mapSuggestions = (items) => _map(items, (el) => ({
  id: el.id,
  name: el.name,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapTools(action.responses.researchTools.data.items, action.startingPosition),
        batteriesList: action.responses.batteries.data.items,
        surveysList: action.responses.surveys.data.items,
        totalItemsCount: action.responses.researchTools.data.total,
      };
    }

    case actionTypes.SET_FILTERED_RESEARCH_TOOLS: {
      return {
        ...state,
        items: mapTools(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    case actionTypes.SET_SURVEY_SUGGESTIONS: {
      return {
        ...state,
        surveySuggestions: mapSuggestions(action.response.data.items),
      };
    }

    case actionTypes.CLEAR_SURVEY_SUGGESTIONS: {
      return {
        ...state,
        surveySuggestions: [],
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  startingPosition,
});

const setFilteredResearchTools = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_RESEARCH_TOOLS,
  response,
  startCountFrom,
});

const setSurveySuggestions = (response) => ({
  type: actionTypes.SET_SURVEY_SUGGESTIONS,
  response,
});

const loadResearchTools = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: defaultSorting.fieldName,
    withSecondarySurveyTools: true,
  };

  if (params.lastFilterValues.name) {
    data.find = params.lastFilterValues.name;
  }

  if (params.lastFilterValues.survey && params.lastFilterValues.survey.id) {
    data.findBySurvey = params.lastFilterValues.survey.id;
  }

  if (params.lastFilterValues.battery) {
    data.findByBattery = params.lastFilterValues.battery;
  }

  if (params.lastFilterValues.status) {
    data.findByStatus = params.lastFilterValues.status;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('survey_tools', data);
  const pageUrl = createURLWithQuery('researchTools', _omit(data, 'withSecondarySurveyTools'));

  dispatch(replace(pageUrl));

  return ApiManager.request('get', dispatch, url);
};

const loadBatteries = () => (dispatch) => {
  const url = 'batteries';

  return ApiManager.request('get', dispatch, url);
};

const onDeleteAccept = (id) => (dispatch, getStore) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `survey_tools/${id}`).then(() => {
    const data = getSortAndPageData(getStore, sortFieldsMapping);
    const startCountFrom = getCountStartFrom(getStore);

    dispatch(loadResearchTools(data)).then((response) => {
      dispatch(setFilteredResearchTools(response, startCountFrom));
      dispatch(showSnackbar(snackbarMessages.researchToolDeleted));
      dispatch(hideLoader());
    }).catch((err) => {
      if (isBadRequest(err)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error, 409)) {
      dispatch(showSnackbar(error.error.message, true));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

const getResearchTools = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadResearchTools(data)).then((response) => {
    dispatch(setFilteredResearchTools(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSecondaryToolsOrderSave = (rows) => (dispatch) => {
  const requestBody = _map(rows, (row) => ({ id: row.id, order: row.order }));
  ApiManager.request('put', dispatch, 'survey_tools/order', requestBody);
};

export const onAddClick = (primaryToolId = null) => (dispatch) => {
  dispatch(push('/researchToolAddEdit', { primaryToolId }));
};

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/researchToolAddEdit', { id }));
};

export const onPreview = (id) => (dispatch) => {
  dispatch(push('/researchToolAddEdit', { id, isViewOnly: true }));
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteResearchTool,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getResearchTools());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getResearchTools());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getResearchTools());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getResearchTools());
};

const getSurveys = (findByName) => (dispatch) => {
  const params = {
    findByName,
    ...config.fullDataPagination,
  };

  const url = createURLWithQuery('surveys', params);

  return ApiManager.request('get', dispatch, url);
};

const getSurvey = (id) => (dispatch) => ApiManager.request('get', dispatch, `surveys/${id}`);

export const onSurveyClear = () => ({
  type: actionTypes.CLEAR_SURVEY_SUGGESTIONS,
});

export const onSurveyFetch = (search) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(getSurveys(search)).then((response) => {
    dispatch(setSurveySuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const loadPageData = (values) => async (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values) {
    let surveys = [];
    if (values.findBySurvey) {
      await dispatch(getSurvey(values.findBySurvey)).then((response) => {
        surveys = mapSuggestions([response.data]);
      }).catch((error) => {
        if (isBadRequest(error)) {
          dispatch(showSnackbar(snackbarMessages.wrongData));
        } else {
          dispatch(showSnackbar(snackbarMessages.globalError));
        }
      });
    }

    dispatch(setLastFilterValues({
      name: values.find || '',
      survey: surveys[0] || {},
      battery: values.findByBattery || '',
      status: values.findByStatus || '',
    }));
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  PromiseAll({
    researchTools: dispatch(loadResearchTools(data)),
    batteries: dispatch(loadBatteries()),
    surveys: dispatch(getSurveys()),
  }).then((responses) => {
    dispatch(loadPageSuccess(responses, startingPosition));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
