import { push } from 'connected-react-router';

import _isEmpty from 'lodash/isEmpty';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import createURLWithQuery from 'utils/createURLWithQuery';
import PromiseAll from 'utils/PromiseAll';

import {
  hideLoader, showSnackbar, showLoader,
} from 'containers/store';
import {
  getGroupStructure,
  getReorderedDimensions,
  getUniqueDimensions,
  hasEmptyName,
} from './utils';

export const initialState = {
  isLoadedPage: false,
  isViewOnly: false,
  dimensionGroups: [],
  dimensionsToReorder: [],
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SCALES/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'SCALES/CLEAR_STORE',
  LOAD_INITIAL_DATA: 'SCALES/LOAD_INITIAL_DATA',
  UPDATE_DIMENSIONS_DATA: 'SCALES/UPDATE_DIMENSIONS_DATA',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.LOAD_INITIAL_DATA: {
      const scaleLoads = action.responses.loads.data.items;
      const scaleParameters = action.responses.parameters.data.items;
      const dimensions = action.responses.dimensions.data.items;

      const dimensionGroups = getGroupStructure(
        dimensions,
        scaleLoads,
        scaleParameters,
      );

      return {
        ...state,
        scaleLoads,
        scaleParameters,
        dimensionGroups,
        dimensions,
        toolId: action.toolId,
        isViewOnly: action.isViewOnly,
      };
    }

    case actionTypes.UPDATE_DIMENSIONS_DATA: {
      const {
        dimensionsToReorder,
        scaleLoads,
        scaleParameters,
        dimensions,
      } = state;
      const { reorderedDimensions } = action;

      const newDimensionsToReorder = getUniqueDimensions(
        dimensionsToReorder,
        reorderedDimensions,
      );

      const newDimensions = getUniqueDimensions(dimensions, reorderedDimensions);

      const newDimensionGroups = getGroupStructure(
        newDimensions,
        scaleLoads,
        scaleParameters,
      );

      return {
        ...state,
        dimensions: newDimensions,
        dimensionGroups: newDimensionGroups,
        dimensionsToReorder: newDimensionsToReorder,
      };
    }

    default:
      return state;
  }
};

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadPageSuccess = (toolId) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  toolId,
});

const loadInitialData = (responses, toolId, isViewOnly) => ({
  type: actionTypes.LOAD_INITIAL_DATA,
  toolId,
  responses,
  isViewOnly,
});

const updateDimensionsData = (reorderedDimensions) => ({
  type: actionTypes.UPDATE_DIMENSIONS_DATA,
  reorderedDimensions,
});

const loadScales = (id) => (dispatch) => {
  const url = createURLWithQuery('scales', {
    findBySurveyTool: id,
    orderBy: 'order',
  });

  return ApiManager.request('get', dispatch, url);
};

const loadScaleParameters = (id) => (dispatch) => {
  const url = createURLWithQuery('scale_parameters', {
    findBySurveyTool: id,
  });

  return ApiManager.request('get', dispatch, url);
};

const loadScaleLoads = (id) => (dispatch) => {
  const url = createURLWithQuery('scale_charges', {
    findBySurveyTool: id,
  });

  return ApiManager.request('get', dispatch, url);
};

export const onEdit = (id) => (dispatch, getStore) => {
  const { toolId, isViewOnly } = getStore().ResearchToolScales;
  dispatch(push('/scaleEdit', { id, toolId, isViewOnly }));
};

export const updateDimensions = () => (dispatch, getStore) => {
  const store = getStore().ResearchToolScales;
  const { dimensionsToReorder, toolId } = store;
  const url = `survey_tools/${toolId}/scales`;

  if (!_isEmpty(dimensionsToReorder)) {
    if (hasEmptyName(dimensionsToReorder)) {
      dispatch(showSnackbar(snackbarMessages.emptyDimensionName));
      return null;
    }

    return ApiManager.request('put', dispatch, url, dimensionsToReorder);
  }
  return null;
};

export const onDragEnd = (dragResult) => (dispatch, getStore) => {
  const store = getStore().ResearchToolScales;
  const { dimensionGroups, dimensions } = store;
  const { source, destination } = dragResult;
  const sourceIndex = source.index;
  const destinationIndex = destination.index;

  const { droppableId } = source;
  const reorderedDimensions = getReorderedDimensions(
    sourceIndex, destinationIndex, droppableId, dimensionGroups, dimensions,
  );

  dispatch(updateDimensionsData(reorderedDimensions));
};

export const loadPageData = (toolId, isViewOnly) => (dispatch) => {
  dispatch(showLoader());
  dispatch(clearStore());

  const promises = {
    loads: dispatch(loadScaleLoads(toolId)),
    parameters: dispatch(loadScaleParameters(toolId)),
    dimensions: dispatch(loadScales(toolId)),
  };
  PromiseAll(promises).then((responses) => {
    dispatch(loadInitialData(responses, toolId, isViewOnly));
    dispatch(loadPageSuccess());
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));

    dispatch(hideLoader());
  });
};
