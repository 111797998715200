const getDialogMargin = ({ drawer, isCollapsedMenu }, theme) => {
  if (drawer.isTopDrawerVisible) {
    return 0;
  }

  const menuWidth = isCollapsedMenu ? theme.sizes.drawer.collapsedWidth : theme.sizes.drawer.width;

  return menuWidth;
};

export default (theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: ({ isCollapsedMenu }) => (
      isCollapsedMenu ? theme.sizes.drawer.collapsedWidth : theme.sizes.drawer.width
    ),
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxShadow: '0px 4px 16px rgba(31, 36, 60, 0.07)',
    overflowX: 'hidden',
  },
  backgroundDrawer: {
    backgroundColor: theme.palette.text.primary,
    border: 0,
    width: ({ isCollapsedMenu }) => (
      isCollapsedMenu ? theme.sizes.drawer.collapsedWidth : theme.sizes.drawer.width
    ),
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  logo: {
    height: 32,
    cursor: 'pointer',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: ({ isCollapsedMenu }) => (
      isCollapsedMenu ? theme.spacing(2, 0.75, 3.5, 4.5) : theme.spacing(2, 2.25, 3.5, 3)
    ),
    '& svg': {
      cursor: 'pointer',
      opacity: 0.7,
      color: theme.palette.white,
      width: 16,
    },
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  drawerContent: {
    padding: ({ isCollapsedMenu }) => theme.spacing(isCollapsedMenu ? 3 : 0, 0, 1.25, 0),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    position: 'relative',
    minWidth: ({ bodyWidth }) => (
      theme.sizes.cardContent.width + theme.spacing(6)
      + theme.sizes.scrollbar.width - (window.innerWidth - bodyWidth)
    ),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  loadingPageContent: {
    padding: 0,
  },
  listRoot: {
    backgroundColor: theme.palette.text.primary,
    padding: 0,
  },
  listSection: {
    borderBottom: `1px solid ${theme.palette.drawer.divider}`,
    padding: theme.spacing(1.75, 0),
  },
  listItemIconRoot: {
    color: theme.palette.drawer.primaryText,
    minWidth: 40,
  },
  listItem: {
    height: 56,
    paddingLeft: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.drawer.hover,
    },
  },
  listItemText: {
    fontWeight: 500,
    color: theme.palette.drawer.primaryText,
    marginLeft: theme.spacing(0.75),
  },
  listItemTextRootSelected: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  selectedListItem: {
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.yellow} !important`,
  },
  userContent: ({ isCollapsedMenu }) => ({
    marginTop: (isCollapsedMenu ? theme.spacing(4.75) : theme.spacing(1.5)),
  }),
  userContentName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 56,
    height: 56,
    marginTop: theme.spacing(0.75),
    backgroundColor: theme.palette.deepBlue[60],
    borderRadius: 50,
    fontWeight: 500,
    fontSize: 24,
    color: theme.palette.white,
    cursor: 'pointer',
  },
  agencyWrapper: ({ isCollapsedMenu }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: (isCollapsedMenu ? 'auto' : 54),
    alignItems: (isCollapsedMenu ? 'center' : 'normal'),
    cursor: (isCollapsedMenu ? 'pointer' : 'default'),
  }),
  walletStatusWrapper: {
    color: theme.palette.white,
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
  wordBreak: ({ isCollapsedMenu }) => ({
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    width: (isCollapsedMenu ? 'auto' : 239),
    textAlign: (isCollapsedMenu ? 'center' : 'start'),
    padding: (isCollapsedMenu ? theme.spacing(0, 2.5) : theme.spacing(0, 3)),
  }),
  agencyName: {
    color: theme.palette.drawer.primaryText,
    fontSize: ({ isCollapsedMenu }) => (isCollapsedMenu ? 12 : 13),
    lineHeight: '17px',
  },
  agencyIcon: {
    color: theme.palette.drawer.primaryText,
  },
  hr: {
    borderColor: theme.palette.grey.border,
    margin: theme.spacing(0.25, 0),
    width: ({ isCollapsedMenu }) => (isCollapsedMenu ? 54 : 191),
  },
  divider: {
    border: `1px solid ${theme.palette.drawer.divider}`,
  },
  roleLabel: {
    color: theme.palette.white,
    opacity: 0.6,
    height: 20,
  },
  opacity: {
    opacity: 0.6,
  },
  userProfile: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3.25),
    borderBottom: `1px solid ${theme.palette.drawer.divider}`,
  },
  userProfileItemActive: {
    color: theme.palette.yellow,
  },
  drawerFooter: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  drawerFooterIcon: {
    marginRight: theme.spacing(1),
  },
  userProfileMessage: {
    marginLeft: theme.spacing(1),
  },
  activeIcon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(0.5),
  },
  loader: {
    height: '100%',
    width: `calc(100% - ${theme.sizes.drawer.width}px)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 1300,
  },
  loaderWrapper: {
    backgroundColor: theme.palette.cardBackground,
  },
  loaderWithCollapsedMenu: {
    width: `calc(100% - ${theme.sizes.drawer.collapsedWidth}px)`,
  },
  fullWidthLoader: {
    width: '100%',
  },
  transparentLoaderBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.cardBackground,
    opacity: 0.6,
    zIndex: 100,
  },
  progressWrapper: {
    position: 'relative',
    zIndex: 101,
  },
  dialogRoot: {
    minWidth: 400,
    marginLeft: (props) => getDialogMargin(props, theme),
  },
  tooltipTooltip: {
    backgroundColor: theme.palette.tooltip.backgroundColor2,
    padding: theme.spacing(1, 1.5),
    lineHeight: '17px',
    borderRadius: 2,
    fontSize: 12,
    marginLeft: theme.spacing(1),
  },
  tooltipArrow: {
    color: theme.palette.tooltip.backgroundColor2,
  },
  version: {
    fontSize: 12,
    lineHeight: '16px',
    padding: theme.spacing(0, 0.5),
    color: theme.palette.drawer.primaryText,
  },
  withDrawerVisible: {
    paddingTop: theme.spacing(17.5),
    height: 'auto',
    minHeight: '100vh',
  },
  visibleVersionPopup: {
    paddingTop: theme.spacing(17.5) + theme.sizes.versionPopup.height,
  },
  toolbarColor: {
    color: theme.palette.toolbar.color,
    backgroundColor: theme.palette.toolbar.backgroundColor,
  },
  toolbarIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  bottomLinksWrapper: {
    borderTop: `1px solid ${theme.palette.drawer.divider}`,
    padding: ({ isCollapsedMenu }) => theme.spacing(0.75, isCollapsedMenu ? 0 : 2.25),
    display: 'flex',
    justifyContent: ({
      isCollapsedMenu,
      isDiagnosticianRole,
    }) => (
      isCollapsedMenu || !isDiagnosticianRole ? 'center' : 'space-between'
    ),
    flexWrap: 'wrap',
  },
  scrollbar: {
    display: 'flex',
    flexGrow: 'inherit',
  },
  column: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});
