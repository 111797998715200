import { defineMessages } from 'react-intl';

export default defineMessages({
  logout: 'Wyloguj',
  users: 'Użytkownicy',
  organizations: 'Szkoły',
  questions: 'Pytania',
  surveys: 'Ankiety i testy',
  answers: 'Odpowiedzi',
  researchTools: 'Narzędzia diagnosty',
  surveysResults: 'Badania',
  groupSurveys: 'Badania grupowe',
  resetPassword: 'Zmień hasło',
  dialogYes: 'Tak',
  dialogNo: 'Nie',
  logged: 'Zalogowany: {date}',
  lastLogged: 'Poprzednie: {date}',
  appVersion: 'Wersja aplikacji: {version}',
  apiVersion: 'Wersja API: {version}',
  standardScales: 'Skale kategorialne',
  referenceGroups: 'Grupy odniesienia',
  batteries: 'Baterie testów',
  tools: 'Opisy testów',
  patients: 'Kartoteka klientów',
  videoTutorial: 'Filmy instruktażowe',
  sendMessage: 'Kontakt',
  facilityList: 'Placówki',
  facility: 'Placówka',
  foundationName: 'Fundacja Dom Terapii',
  diagnosticians: 'Diagności',
  videoTutorials: 'Filmy instruktażowe',
  statistics: 'Raporty',
  informations: 'Informacje o systemie',
  profile: 'Profil',
  packagesEdit: 'Pakiety zakupowe',
  privacyPolicy: 'Polityka prywatności',
  termsOfUse: 'Regulamin Systemu',
  changelog: 'Rejestr aktualizacji',
});
