import { push, replace } from 'connected-react-router';
import _isEmpty from 'lodash/isEmpty';
import _isBoolean from 'lodash/isBoolean';
import _some from 'lodash/some';

import PromiseAll from 'utils/PromiseAll';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import dialogTexts from 'utils/dialogTexts';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';

import {
  hideLoader, showSnackbar, showLoader, showTransparentLoader, setSortingData,
  openDialog, setPage, setRowsPerPage, setLastFilterValues, getMeta, setSortingAndPagination,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
  used: [],
};

const defaultSorting = {
  fieldName: 'name',
  sortDirection: 'asc',
};

const sortFieldsMapping = {
  name: 'name',
  researchToolsCount: 'survey_tools_count',
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'REFERENCE_GROUP_SETS/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'REFERENCE_GROUP_SETS/CLEAR_STORE',
  SET_FILTERED_REFERENCE_GROUPS: 'REFERENCE_GROUP_SETS/SET_FILTERED_REFERENCE_GROUPS',
};

const mapSets = (sets, startingPosition) => sets.map((el, key) => {
  const parameters = [];

  if (el.criteria_sex) {
    parameters.push(
      'GENDER_CRITERIA',
    );
  }

  if (el.criteria_age) {
    parameters.push(
      'AGE_CRITERIA',
    );
  }
  return ({
    id: key + 1 + startingPosition,
    apiId: el.id,
    name: el.name,
    researchToolsCount: el.survey_tools_count,
    parameters,
  });
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const statusesList = action.responses.meta.data.boolList;

      return {
        ...state,
        isLoadedPage: true,
        items: mapSets(action.responses.referenceGroupSets.data.items, action.startingPosition),
        totalItemsCount: action.responses.referenceGroupSets.data.total,
        statusesList,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return initialState;
    }

    case actionTypes.SET_FILTERED_REFERENCE_GROUPS: {
      return {
        ...state,
        items: mapSets(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  startingPosition,
});

export const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setFilteredReferenceGroups = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_REFERENCE_GROUPS,
  response,
  startCountFrom,
});

const loadReferenceGroupSets = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: defaultSorting.fieldName,
  };

  if (!_isEmpty(params.lastFilterValues.parameters)) {
    const criteria = params.lastFilterValues.parameters;

    if (_some(criteria, (el) => el === 'AGE_CRITERIA')) {
      data.ageCriteria = true;
    }

    if (_some(criteria, (el) => el === 'GENDER_CRITERIA')) {
      data.sexCriteria = true;
    }
  }

  if (_isBoolean(params.lastFilterValues.used)) {
    data.inUse = params.lastFilterValues.used;
  }

  if (params.lastFilterValues.name) {
    data.findByName = params.lastFilterValues.name;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('reference_group_sets', data);
  const pageUrl = createURLWithQuery('referenceGroups', data);

  dispatch(replace(pageUrl));

  return ApiManager.request('get', dispatch, url);
};

const getReferenceGroupSets = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadReferenceGroupSets(data)).then((response) => {
    dispatch(setFilteredReferenceGroups(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(hideLoader());
  });
};

export const onAddClick = () => (dispatch) => {
  dispatch(push('/referenceGroupsAddEdit'));
};

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/referenceGroupsAddEdit', { id }));
};

export const onPreview = (id) => (dispatch) => {
  dispatch(push('/referenceGroupsAddEdit', { id, isViewOnly: true }));
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getReferenceGroupSets());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getReferenceGroupSets());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getReferenceGroupSets());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getReferenceGroupSets());
};

const onDeleteAccept = (id) => (dispatch, getStore) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `reference_group_sets/${id}`).then(() => {
    const data = getSortAndPageData(getStore, sortFieldsMapping);
    const startCountFrom = getCountStartFrom(getStore);

    dispatch(loadReferenceGroupSets(data)).then((response) => {
      dispatch(setFilteredReferenceGroups(response, startCountFrom));
      dispatch(showSnackbar(snackbarMessages.referenceGroupDeleted));
      dispatch(hideLoader());
    }).catch((err) => {
      if (isBadRequest(err)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      const messageFromApi = error.error.message;
      const errorMessage = messageFromApi || snackbarMessages.wrongData;

      dispatch(showSnackbar(errorMessage, !!messageFromApi));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteReferenceGroup,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const loadPageData = (values) => (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values) {
    const parameters = [];
    if (values.ageCriteria) {
      parameters.push('AGE_CRITERIA');
    }

    if (values.sexCriteria) {
      parameters.push('GENDER_CRITERIA');
    }

    dispatch(setLastFilterValues({
      name: values.findByName || '',
      used: values.inUse ? values.inUse === 'true' : '',
      parameters,
    }));
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const promises = {
    meta: dispatch(getMeta()),
    referenceGroupSets: dispatch(loadReferenceGroupSets(data)),
  };

  PromiseAll(promises).then((responses) => {
    dispatch(loadPageSuccess(responses, startingPosition));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
