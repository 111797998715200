import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { StickyContainer } from 'react-sticky';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ApartmentIcon from '@material-ui/icons/Apartment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import PersonIcon from '@material-ui/icons/Person';
import Group from '@material-ui/icons/Group';
import ChatIcon from '@material-ui/icons/Chat';
import SchoolIcon from '@material-ui/icons/School';
import CommentIcon from '@material-ui/icons/Comment';
import InfoIcon from '@material-ui/icons/Info';
import SurveysIcon from '@material-ui/icons/List';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MovieIcon from '@material-ui/icons/Movie';
import MailIcon from '@material-ui/icons/Mail';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import ProtectedByPermissions from 'components/ProtectedByPermissions';
import Scrollbar from 'components/Scrollbar';
import MenuItemTooltip from 'components/MenuItemTooltip';
import VersionPopup from 'components/VersionPopup';

import { classesShape } from 'utils/shapes/classesShape';
import { locationShape } from 'utils/shapes/router.shapes';
import getHomeRouteByRole from 'utils/getHomeRouteByRole';
import checkPermissions from 'utils/checkPermissions';
import getAgencyName from 'utils/getAgencyName';
import getDateTimeFromApi from 'utils/getDateTimeFromApi';
import { PRIVATE_MESSAGE } from 'utils/constants/modalTypes';
import { IsCollapsedMenuContext } from 'utils/contexts/IsCollapsedMenuContext';
import roles, { diagnosticianRoles } from 'utils/constants/rolesOfUsers';

import pagesWithFullWidth from 'utils/constants/pagesWithFullWidth';
import { intlShape } from 'utils/shapes/intlShape';
import config from 'config';

import logo from 'assets/img/diagmaticLogo.svg';
import smallLogo from 'assets/img/logo_small.svg';
import { ReactComponent as LogoutIcon } from 'assets/img/logout.svg';
import { ReactComponent as Tools } from 'assets/img/tests.svg';
import { ReactComponent as Facility } from 'assets/img/placowka.svg';
import { ReactComponent as Diagnosticians } from 'assets/img/diagnosci.svg';
import { ReactComponent as Batteries } from 'assets/img/batteriesIcon.svg';
import { ReactComponent as DiagnosticTools } from 'assets/img/diagnosticTools.svg';
import { ReactComponent as ReferenceGroups } from 'assets/img/referenceGroups.svg';
import { ReactComponent as CategorialScales } from 'assets/img/categorialScales.svg';
import { ReactComponent as Research } from 'assets/img/research.svg';
import { ReactComponent as Stats } from 'assets/img/stats.svg';
import { ReactComponent as ShieldLockIcon } from 'assets/img/shieldLock.svg';
import { ReactComponent as FileStarIcon } from 'assets/img/fileStar.svg';
import { ReactComponent as ChangelogIcon } from 'assets/img/changelog.svg';
import 'components/OutlinedTextarea/components/RichTextInput/RichTextInput.styles.css';

import {
  logout,
  hideSnackbar as hideSnackbarCallback,
  closeDialog,
  toggleCollapsingMenu,
  setModal,
  setBodyWidth,
  hideVersionPopup,
} from 'containers/store';
import ModalRoot from 'misc/ModalRoot/ModalRoot';

import CardsContainer from 'components/CardsContainer';
import WalletStatus from 'components/WalletStatus';
import MenuListItem from './components/MenuListItem';
import MenuIcon from './components/MenuIcon';
import pageLayoutStyles from './PageLayout.styles';
import messages from './PageLayout.messages';

class PageLayout extends React.Component {
  static propTypes = {
    bodyWidth: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    classes: classesShape.isRequired,
    closeDialog: PropTypes.func.isRequired,
    drawer: PropTypes.shape({
      isTopDrawerVisible: PropTypes.bool,
      content: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
      ]),
    }).isRequired,
    goBack: PropTypes.func.isRequired,
    hideSnackbar: PropTypes.func.isRequired,
    hideTabs: PropTypes.bool.isRequired,
    hideVersionPopup: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    loaderVisible: PropTypes.bool.isRequired,
    location: locationShape.isRequired,
    logout: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.bool).isRequired,
    push: PropTypes.func.isRequired,
    setBodyWidth: PropTypes.func.isRequired,
    setModal: PropTypes.func.isRequired,
    snackbarVisible: PropTypes.bool.isRequired,
    toggleCollapsingMenu: PropTypes.func.isRequired,
    transparentLoaderVisible: PropTypes.bool.isRequired,
    activeMenuItem: PropTypes.string,
    activeSubMenuItem: PropTypes.string,
    apiVersion: PropTypes.string,
    dialogData: PropTypes.shape({
      title: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      description: PropTypes.string,
      onAccept: PropTypes.func,
      onDecline: PropTypes.func,
    }),
    dialogOpened: PropTypes.bool,
    isCollapsedMenu: PropTypes.bool,
    messageFromApi: PropTypes.bool,
    snackbarMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string,
    userData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    versionPopupVisible: PropTypes.bool,
  };

  static defaultProps = {
    activeMenuItem: null,
    activeSubMenuItem: null,
    dialogData: {},
    dialogOpened: false,
    snackbarMessage: null,
    title: null,
    userData: {},
    apiVersion: '',
    messageFromApi: false,
    isCollapsedMenu: false,
    versionPopupVisible: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isScroll: false,
    };

    this.items = [[{
      id: 'users',
      icon: <PersonIcon />,
      text: props.intl.formatMessage(messages.users),
      requiredPermission: [this.props.permissions.PAGE_USERS_LIST],
      onClick: () => {
        this.handleRedirect('/users');
      },
    }, {
      id: 'facilityList',
      icon: <Facility />,
      text: props.intl.formatMessage(messages.facilityList),
      requiredPermission: [this.props.permissions.PAGE_CLINICS_LIST],
      onClick: () => {
        this.handleRedirect('/facilityList');
      },
    }, {
      id: 'organizations',
      icon: <SchoolIcon />,
      text: props.intl.formatMessage(messages.organizations),
      requiredPermission: [this.props.permissions.PAGE_ORGANIZATIONS_LIST],
      onClick: () => {
        this.handleRedirect('/organizations');
      },
    }, {
      id: 'videoTutorials',
      icon: <MovieIcon />,
      text: props.intl.formatMessage(messages.videoTutorials),
      requiredPermission: [this.props.permissions.PAGE_VIDEO_TUTORIAL_LIST],
      onClick: () => {
        this.handleRedirect('/videoTutorials');
      },
    }, {
      id: 'packagesEdit',
      icon: <AccountBalanceWalletIcon />,
      text: props.intl.formatMessage(messages.packagesEdit),
      requiredPermission: [this.props.permissions.PACKAGE_UPDATE],
      onClick: () => {
        this.handleRedirect('/packagesEdit');
      },
    }], [{ // end section
      id: 'batteries',
      icon: <Batteries />,
      text: props.intl.formatMessage(messages.batteries),
      requiredPermission: [this.props.permissions.PAGE_BATTERIES_LIST],
      onClick: () => {
        this.handleRedirect('/batteries');
      },
    }, {
      id: 'researchTools',
      icon: <DiagnosticTools />,
      text: props.intl.formatMessage(messages.researchTools),
      requiredPermission: [this.props.permissions.PAGE_RESEARCH_TOOLS_LIST],
      onClick: () => {
        this.handleRedirect('/researchTools');
      },
    }, {
      id: 'referenceGroups',
      icon: <ReferenceGroups />,
      text: props.intl.formatMessage(messages.referenceGroups),
      requiredPermission: [this.props.permissions.PAGE_REFERENCE_GROUPS_LIST],
      onClick: () => {
        this.handleRedirect('/referenceGroups');
      },
    }, {
      id: 'standardScales',
      icon: <CategorialScales />,
      text: props.intl.formatMessage(messages.standardScales),
      requiredPermission: [this.props.permissions.PAGE_STANDARD_SCALES_LIST],
      onClick: () => {
        this.handleRedirect('/standardScales');
      },
    }], [{ // end section
      id: 'surveys',
      icon: <SurveysIcon />,
      text: props.intl.formatMessage(messages.surveys),
      requiredPermission: [this.props.permissions.PAGE_SURVEYS_LIST],
      onClick: () => {
        this.handleRedirect('/surveys');
      },
    }, {
      id: 'questions',
      icon: <ChatIcon />,
      text: props.intl.formatMessage(messages.questions),
      requiredPermission: [this.props.permissions.PAGE_QUESTIONS_LIST],
      onClick: () => {
        this.handleRedirect('/questions');
      },
    }, {
      id: 'answers',
      icon: <CommentIcon />,
      text: props.intl.formatMessage(messages.answers),
      requiredPermission: [this.props.permissions.PAGE_ANSWERS_LIST],
      onClick: () => {
        this.handleRedirect('/answers');
      },
    }, {
      id: 'surveysResults',
      icon: <Research />,
      text: props.intl.formatMessage(messages.surveysResults),
      requiredPermission: [this.props.permissions.PAGE_SURVEYS_RESULTS_LIST],
      onClick: () => {
        this.handleRedirect('/surveysResults');
      },
    }], [{ // end section
      id: 'patients',
      icon: <PersonIcon />,
      requiredPermission: [this.props.permissions.PAGE_PATIENT_LIST],
      text: props.intl.formatMessage(messages.patients),
      onClick: () => {
        this.handleRedirect('/patients', { fromMenu: true });
      },
    }, {
      id: 'groupSurveys',
      icon: <Group />,
      text: props.intl.formatMessage(messages.groupSurveys),
      requiredPermission: [
        this.props.permissions.PAGE_GROUP_SURVEYS_RESULTS_LIST
        && _get(this.props.userData, 'clinics[0].has_group_examinations_enabled', false)],
      onClick: () => {
        this.handleRedirect('/session_patient_groups');
      },
    }, {
      id: 'tools',
      icon: <Tools />,
      text: props.intl.formatMessage(messages.tools),
      requiredPermission: [this.props.permissions.PAGE_TOOLS_LIST],
      onClick: () => {
        this.handleRedirect('/tools');
      },
    }], [{
      id: 'facility',
      icon: <Facility />,
      requiredPermission: [this.props.permissions.PAGE_OWN_CLINIC_VIEW],
      text: props.intl.formatMessage(messages.facility),
      onClick: () => {
        this.handleRedirect('/facility');
      },
    }, {
      id: 'diagnosticians',
      icon: <Diagnosticians />,
      text: props.intl.formatMessage(messages.diagnosticians),
      requiredPermission: [this.props.permissions.PAGE_DIAGNOSTICIAN_LIST],
      onClick: () => {
        this.handleRedirect('/diagnosticians');
      },
    }, {
      id: 'statistics',
      icon: <Stats />,
      requiredPermission: [this.props.permissions.PAGE_STATISTICS],
      text: props.intl.formatMessage(messages.statistics),
      onClick: () => {
        this.handleRedirect('/statistics');
      },
    }]];

    const {
      classes, intl, userData, apiVersion,
    } = props;
    const loginDates = _map(userData.user_last_two_logins, 'created_at');

    this.bottomLinks = [{
      id: 'sendMessage',
      icon: <MailIcon fontSize="small" />,
      requiredPermission: [this.props.permissions.PAGE_MENU_BOTTOM_CONTACT],
      message: props.intl.formatMessage(messages.sendMessage),
      onClick: () => this.props.setModal(PRIVATE_MESSAGE),
    }, {
      id: 'videoTutorial',
      icon: <MovieIcon fontSize="small" />,
      requiredPermission: [this.props.permissions.PAGE_MENU_BOTTOM_VIDEO_TUTORIAL],
      message: props.intl.formatMessage(messages.videoTutorial),
      onClick: () => {
        this.handleRedirect('/tutorials');
      },
    }, {
      id: 'privacyPolicy',
      icon: <ShieldLockIcon />,
      requiredPermission: [this.props.permissions.PAGE_MENU_BOTTOM_PRIVACY_POLICY],
      message: props.intl.formatMessage(messages.privacyPolicy),
      onClick: () => {
        window.open(config.privacyPolicyLink, '_blank');
      },
    }, {
      id: 'termsOfUse',
      icon: <FileStarIcon />,
      requiredPermission: [this.props.permissions.PAGE_MENU_BOTTOM_TERMS_OF_USE],
      message: props.intl.formatMessage(messages.termsOfUse),
      onClick: () => {
        window.open(config.termsOfUseLink, '_blank');
      },
    }, {
      id: 'changelog',
      icon: (
        <ChangelogIcon
          style={{
            width: 13.33,
            height: 10.67,
          }}
        />
      ),
      message: props.intl.formatMessage(messages.changelog),
      onClick: () => {
        window.open(config.changelogLink, '_blank');
      },
    }, {
      id: 'info',
      icon: <InfoIcon />,
      message: (
        <Typography className={classes.version} component="div">
          {intl.formatMessage(messages.logged, {
            date: loginDates[0] ? getDateTimeFromApi(loginDates[0]) : '',
          })}
          <br />
          {loginDates[1] ? intl.formatMessage(messages.lastLogged, {
            date: getDateTimeFromApi(loginDates[1]),
          }) : ''}
          <br />
          {intl.formatMessage(messages.appVersion, {
            version: config.appVersion,
          })}
          <br />
          {intl.formatMessage(messages.apiVersion, {
            version: apiVersion,
          })}
        </Typography>
      ),
      tooltipClass: classes.tooltipTooltip,
    }];
  }

  componentDidMount() {
    const resizeBody = () => {
      const bodyWidth = document.body.clientWidth;
      this.props.setBodyWidth(bodyWidth);
    };

    new ResizeObserver(resizeBody).observe(document.body);
  }

  handleRedirect = (path, state) => {
    if (this.props.location.pathname !== path) {
      this.props.push(path, state);
    }
  };

  handleDialogClose = () => {
    if (this.props.dialogData.onDecline) {
      this.props.dialogData.onDecline();
    }
    this.props.closeDialog();
  }

  handleDialogAccept = () => {
    this.props.dialogData.onAccept();
    this.props.closeDialog();
  }

  onDrawerIconClick = (drawer) => {
    if (drawer.onCloseCallback) {
      drawer.onCloseCallback();
    } else {
      this.props.goBack();
    }
  }

  onLogoClick = () => {
    this.props.push(getHomeRouteByRole(this.props.userData.role_id));
  }

  getSnackbarMessage = (snackbarMessage, messageFromApi) => {
    if (snackbarMessage && !messageFromApi) {
      return this.props.intl.formatMessage(snackbarMessage);
    }

    if (snackbarMessage && messageFromApi) {
      return snackbarMessage;
    }
    return null;
  }

  checkPermissionsToSection = (section) => checkPermissions(
    _reduce(section, (acc, el) => (
      [...acc, ...(el.requiredPermission || [])]
    ), []),
    'OR',
  );

  getPageWithFullWidth = (location) => _includes(pagesWithFullWidth, location.pathname)

  render() {
    const {
      classes, children, activeMenuItem, activeSubMenuItem, userData,
      snackbarVisible, hideSnackbar, snackbarMessage, messageFromApi,
      loaderVisible, transparentLoaderVisible, dialogData, drawer, intl,
      isCollapsedMenu, versionPopupVisible,
    } = this.props;

    const withFullWidth = this.getPageWithFullWidth(this.props.location);

    const userRole = this.props.userData.role_id;
    const { clinicCoordinator, diagnostician } = roles;
    const isClinicCoordinator = userRole === clinicCoordinator;
    const isDiagnostician = userRole === diagnostician;
    const isClinicCoordinatorOrDiagnostician = isClinicCoordinator || isDiagnostician;
    const showWalletStatus = (isClinicCoordinatorOrDiagnostician);

    const showVersionPopup = isClinicCoordinatorOrDiagnostician && versionPopupVisible;

    const agency = (
      <Typography className={classes.agencyName} variant="body1">
        {getAgencyName(userData)}
      </Typography>
    );

    return (
      <>
        <IsCollapsedMenuContext.Provider value={isCollapsedMenu}>
          <StickyContainer className={classes.root}>
            <CssBaseline />
            {!drawer.isTopDrawerVisible ? (
              <Drawer
                variant="permanent"
                className={classes.drawer}
                classes={{ paper: classes.backgroundDrawer }}
                open
              >
                <div className={classes.logoWrapper}>
                  <img // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
                    className={classes.logo}
                    src={isCollapsedMenu ? smallLogo : logo}
                    alt="diagmatic"
                    onClick={this.onLogoClick}
                  />
                  <ArrowForwardIosIcon
                    onClick={this.props.toggleCollapsingMenu}
                    className={classNames({
                      [classes.rotated]: !isCollapsedMenu,
                    })}
                  />
                </div>
                <div className={classes.drawerContent}>
                  {
                    isCollapsedMenu ? (
                      <Tooltip
                        classes={{
                          tooltip: classes.tooltipTooltip,
                          arrow: classes.tooltipArrow,
                        }}
                        title={agency}
                        placement="right"
                        arrow
                      >
                        <div
                          className={classNames(
                            classes.agencyWrapper,
                            classes.wordBreak,
                          )}
                        >
                          <ApartmentIcon className={classes.agencyIcon} />
                          <hr className={classes.hr} />
                        </div>
                      </Tooltip>
                    ) : (
                      <div
                        className={classNames(
                          classes.agencyWrapper,
                          classes.wordBreak,
                        )}
                      >
                        {agency}
                        <hr className={classes.hr} />
                      </div>
                    )
                  }
                  {
                    (showWalletStatus && (
                      <div className={classNames(
                        classes.wordBreak,
                        classes.walletStatusWrapper,
                      )}
                      >
                        <WalletStatus
                          displaySlider={isDiagnostician}
                          displayAccountBalance={isClinicCoordinator}
                          accountBalance={_get(userData, 'clinics[0].balance', '')}
                          percentageAccountBalance={_get(userData, 'clinics[0].balance_percentage', '')}
                          isCollapsedMenu={!isCollapsedMenu}
                        />
                      </div>
                    ))
                  }
                  <div className={classNames(classes.userContent, classes.wordBreak)}>
                    {
                      !isCollapsedMenu ? (
                        <Typography className={classes.roleLabel} variant="body2">
                          {_get(userData, 'role.name', '')}
                        </Typography>
                      ) : null
                    }
                    <MenuItemTooltip
                      avatarTooltip
                      title={intl.formatMessage(messages.profile)}
                      isCollapsedMenu={isCollapsedMenu}
                    >
                      <Typography
                        className={classes.userContentName}
                        variant="h5"
                        onClick={() => {
                          this.handleRedirect('/userProfile');
                        }}
                      >
                        <span>
                          {_get(userData, 'first_name[0]')}
                          {_get(userData, 'last_name[0]')}
                        </span>
                      </Typography>
                    </MenuItemTooltip>
                  </div>
                </div>

                <div className={classes.userProfile}>
                  <MenuListItem
                    variant="profile"
                    icon={<LogoutIcon />}
                    text={intl.formatMessage(messages.logout)}
                    onClick={() => this.props.logout()}
                    isCollapsedMenu={isCollapsedMenu}
                  />
                </div>
                {
                  this.props.hideTabs ? null : (
                    <Scrollbar
                      variant="menu"
                      className={classes.scrollbar}
                      noScrollX
                      translateContentSizeYToHolder
                      onContentWidthChange={(width) => {
                        this.setState({ isScroll: width === 86 });
                      }}
                    >
                      <List classes={{ root: classes.listRoot }}>
                        {
                          _map(this.items, (section, key) => (
                            this.checkPermissionsToSection(section)
                          ) && (
                            <div className={classes.listSection} key={key}>
                              {
                                _map(section, (el) => (
                                  <ProtectedByPermissions
                                    key={el.id}
                                    requiredPermission={el.requiredPermission}
                                  >
                                    <MenuListItem
                                      isActive={el.id === activeMenuItem}
                                      icon={el.icon}
                                      text={el.text}
                                      onClick={el.onClick}
                                      isCollapsedMenu={isCollapsedMenu}
                                      isScroll={this.state.isScroll}
                                    />
                                  </ProtectedByPermissions>
                                ))
                              }
                            </div>
                          ))
                        }
                      </List>
                    </Scrollbar>
                  )
                }
                {/* <RecommendUs
                  isCollapsedMenu={isCollapsedMenu}
                  onClick={() => {}}
                /> */}
                {
                  this.props.hideTabs ? null : (
                    <div className={classes.bottomLinksWrapper}>
                      {
                        this.bottomLinks.map((item) => (
                          <ProtectedByPermissions
                            key={item.id}
                            requiredPermission={item.requiredPermission}
                          >
                            <MenuIcon
                              isActive={item.id === activeSubMenuItem}
                              icon={item.icon}
                              text={item.message}
                              tooltipClass={item.tooltipClass}
                              onClick={item.onClick}
                            />
                          </ProtectedByPermissions>
                        ))
                      }
                    </div>
                  )
                }
              </Drawer>
            ) : (
              <>
                {drawer.isTopDrawerVisible && !loaderVisible && showVersionPopup ? (
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      zIndex: 100,
                      overflow: 'auto hidden',
                      height: 140,
                    }}
                  >
                    <VersionPopup
                      onCloseClick={() => {
                        this.props.hideVersionPopup(config.appVersion);
                      }}
                      fullWidth={drawer.isTopDrawerVisible}
                      bodyWidth={this.props.bodyWidth}
                    />
                  </div>
                ) : null}
                <AppBar
                  position="fixed"
                  style={{ top: showVersionPopup ? 140 : 0 }}
                >
                  <Toolbar className={classes.toolbarColor}>
                    <IconButton
                      color="inherit"
                      className={classes.toolbarIcon}
                      onClick={() => this.onDrawerIconClick(drawer)}
                      edge="start"
                    >
                      <ClearIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                      {drawer.content}
                    </Typography>
                  </Toolbar>
                </AppBar>
              </>
            )}
            <div className={classes.column}>
              <main
                className={classNames({
                  [classes.content]: true,
                  [classes.loadingPageContent]: loaderVisible,
                  [classes.withDrawerVisible]: drawer.isTopDrawerVisible,
                  [classes.visibleVersionPopup]: drawer.isTopDrawerVisible && showVersionPopup,
                })}
              >
                {(loaderVisible || transparentLoaderVisible) ? (
                  <div className={classNames(classes.loader, {
                    [classes.loaderWrapper]: !transparentLoaderVisible,
                    [classes.loaderWithCollapsedMenu]: isCollapsedMenu,
                    [classes.fullWidthLoader]: drawer.isTopDrawerVisible,
                  })}
                  >
                    {
                    transparentLoaderVisible
                      ? <div className={classes.transparentLoaderBg} />
                      : null
                  }
                    <div className={classes.progressWrapper}>
                      <CircularProgress color="primary" disableShrink />
                    </div>
                  </div>
                ) : null}
                <CardsContainer
                  withFullWidth={withFullWidth}
                  centered={drawer.isTopDrawerVisible}
                  bodyWidth={this.props.bodyWidth}
                >
                  {children}
                </CardsContainer>
              </main>
            </div>
          </StickyContainer>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={snackbarVisible}
            onClose={hideSnackbar}
            message={this.getSnackbarMessage(snackbarMessage, messageFromApi)}
          />
          <Dialog
            open={this.props.dialogOpened}
            classes={{ paper: classes.dialogRoot }}
            onClose={this.handleDialogClose}
          >
            {dialogData.title && (
              <DialogTitle>{intl.formatMessage(dialogData.title)}</DialogTitle>
            )}
            {dialogData.description && (
              <DialogContent>
                <DialogContentText>
                  {dialogData.description}
                </DialogContentText>
              </DialogContent>
            )}
            <DialogActions>
              <Button color="primary" autoFocus onClick={this.handleDialogAccept}>
                {intl.formatMessage(messages.dialogYes)}
              </Button>
              <Button color="primary" onClick={this.handleDialogClose}>
                {intl.formatMessage(messages.dialogNo)}
              </Button>
            </DialogActions>
          </Dialog>
          <ModalRoot />
        </IsCollapsedMenuContext.Provider>
      </>
    );
  }
}

const mapDispatchToProps = {
  logout,
  hideSnackbar: hideSnackbarCallback,
  push,
  goBack,
  setModal,
  closeDialog,
  toggleCollapsingMenu,
  setBodyWidth,
  hideVersionPopup,
};

const mapStateToProps = (state) => ({
  ...state.Global,
  isCollapsedMenu: state.Global.isCollapsedMenu,
  bodyWidth: state.Global.bodyWidth,
  hideTabs: state.router.location.pathname === '/userProfile' && _get(state, 'router.location.state.firstLogin', false),
  isDiagnosticianRole: _includes(diagnosticianRoles, _get(state, 'Global.userData.role_id', null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(pageLayoutStyles)(
    withRouter(
      injectIntl(PageLayout),
    ),
  ),
);

// export default withStyles(pageLayoutStyles)(injectIntl(PageLayout));
