import { goBack } from 'connected-react-router';

import _get from 'lodash/get';

import createURLWithQuery from 'utils/createURLWithQuery';
import PromiseAll from 'utils/PromiseAll';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import config from 'config';

import { isAnyFilled } from 'containers/ResearchToolAddEdit/Parameters/utils';
import {
  hideLoader, showSnackbar, showLoader, setDrawerVisibility,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  isEdit: false,
  isModelImported: false,
  isMain: false,
  hasToolWithParametrers: false,
  primaryToolId: null,
  toolData: {},
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'RESEARCH_TOOL_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'RESEARCH_TOOL_ADD_EDIT/CLEAR_STORE',
  SET_LOADED: 'RESEARCH_TOOL_ADD_EDIT/SET_LOADED',
  SET_EDIT: 'RESEARCH_TOOL_ADD_EDIT/SET_EDIT',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const isModelImported = !!action.responses.surveyToolScales
      && !!action.responses.surveyToolQuestions
      && isAnyFilled([
        action.responses.surveyToolQuestions,
        action.responses.surveyToolScales,
      ]);

      return {
        ...state,
        isLoadedPage: true,
        toolData: _get(action, 'responses.toolData.data', {}),
        primaryToolId: action.primaryToolId || _get(action, 'responses.toolData.data.primary_survey_tool_id', null),
        isEdit: !!action.id,
        isMain:
         _get(action, 'responses.toolData.data.type', config.researchToolTypes.primary) === config.researchToolTypes.primary
         && !action.primaryToolId,
        hasToolWithParameters: _get(action, 'responses.toolData.data.has_at_least_one_secondary_survey_tool_with_psychometric_parameters', false),
        isViewOnly: action.isViewOnly,
        isModelImported,
      };
    }

    case actionTypes.SET_LOADED: {
      return {
        ...state,
        isModelImported: true,
      };
    }

    case actionTypes.SET_EDIT: {
      return {
        ...state,
        isEdit: true,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (isViewOnly, id, primaryToolId, responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  isViewOnly,
  id,
  primaryToolId,
  responses,
});

export const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const setEdit = () => ({
  type: actionTypes.SET_EDIT,
});

export const setModelLoaded = () => ({
  type: actionTypes.SET_LOADED,
});

const loadToolData = (id) => (dispatch) => ApiManager.request('get', dispatch, `survey_tools/${id}`);

const loadSurveyToolQuestions = (id) => (dispatch) => {
  const params = {
    findBySurveyTool: id,
  };
  const url = createURLWithQuery('survey_tool_questions', params);

  return ApiManager.request('get', dispatch, url);
};

const loadSurveyToolScales = (id) => (dispatch) => {
  const params = {
    findBySurveyTool: id,
  };
  const url = createURLWithQuery('scales', params);

  return ApiManager.request('get', dispatch, url);
};

export const loadPageData = (id, isViewOnly, primaryToolId, message) => (dispatch) => {
  dispatch(showLoader());
  if (!isViewOnly) {
    dispatch(setDrawerVisibility({
      visibility: true,
      content: message,
    }));
  }
  const promises = {};
  if (id) {
    promises.surveyToolQuestions = dispatch(loadSurveyToolQuestions(id));
    promises.surveyToolScales = dispatch(loadSurveyToolScales(id));
    promises.toolData = dispatch(loadToolData(id));
  }

  PromiseAll(promises).then((responses) => {
    dispatch(loadPageSuccess(isViewOnly, id, primaryToolId, responses));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
