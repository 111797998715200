import { defineMessages } from 'react-intl';

import _isNumber from 'lodash/isNumber';

import convertFloat from 'utils/convertFloat';
import getNumberFromValueWithComma from 'utils/getNumberFromValueWithComma';

const messages = defineMessages({
  fieldValueTooLarge: 'Wartość pola zbyt wysoka',
});

export default (value, maxValue, convertToNumber) => {
  const result = {};
  let finalValue = value;

  if (convertToNumber) {
    finalValue = getNumberFromValueWithComma(value);
  }

  result.isValid = !_isNumber(finalValue) || convertFloat.toApiFormat(finalValue) <= maxValue;
  result.message = messages.fieldValueTooLarge;

  return result;
};
