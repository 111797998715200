import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(
  () => ({
    fileIcon: {
      width: 'auto',
      height: 40,
      minHeight: 40,
    },
  }),
);
