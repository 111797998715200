import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2.25),
    marginTop: theme.spacing(4),
  },
  republicOfPolandImage: {
    marginLeft: theme.spacing(3.09375),
  },
  nationalCentreImage: {
    marginLeft: theme.spacing(3.1625),
  },
  europeanUnionImage: {
    marginLeft: theme.spacing(3.1625),
  },
}));
