import _get from 'lodash/get';
import { replace } from 'connected-react-router';
import {
  showTransparentLoader, hideLoader, showSnackbar, resetPassword,
} from 'containers/store';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    login: '',
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'FORGOT_PASSWORD/LOAD_PAGE_SUCCESS',
  SET_INITIAL_LOGIN: 'FORGOT_PASSWORD/SET_INITIAL_LOGIN',
  CLEAR_STORE: 'FORGOT_PASSWORD/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
      };
    }

    case actionTypes.SET_INITIAL_LOGIN: {
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          login: action.payload,
        },
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

const loadPageSuccess = () => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
});

export const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const setInitialLogin = (payload) => ({
  type: actionTypes.SET_INITIAL_LOGIN,
  payload,
});

export const onSendClick = (values) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(resetPassword(values.login)).then(() => {
    dispatch(showSnackbar(snackbarMessages.succesfullyResetedPassword));
    dispatch(hideLoader());
    dispatch(replace('/login'));
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const loadPageData = (routeState) => (dispatch) => {
  const login = _get(routeState, 'login');

  if (login) {
    dispatch(setInitialLogin(login));
  }

  dispatch(loadPageSuccess());
};
