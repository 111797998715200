import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import config from 'config';
import { IsCollapsedMenuContext } from 'utils/contexts/IsCollapsedMenuContext';

import useStyle from './VersionPopup.styles';
import messages from './VersionPopup.messages';

const VersionPopup = ({ onCloseClick, fullWidth, bodyWidth }) => {
  const isCollapsedMenu = useContext(IsCollapsedMenuContext);
  const classes = useStyle({ isCollapsedMenu, fullWidth, bodyWidth });

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.titleWrapper}>
            <Typography variant="h6" className={classes.title}>
              <FormattedMessage {...messages.title} />
            </Typography>
          </div>
          <Typography variant="subtitle1" className={classes.description}>
            <FormattedMessage
              values={{
                Link: (text) => (
                  <Link href={config.changelogLink} target="_blank" className={classes.link}>
                    {text}
                  </Link>
                ),
              }}
              {...messages.description}
            />
          </Typography>
        </div>
        <Button className={classes.closeButton} onClick={onCloseClick}>
          <FormattedMessage {...messages.close} />
        </Button>
      </div>
    </div>
  );
};

VersionPopup.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  bodyWidth: PropTypes.number,
  fullWidth: PropTypes.bool,
};

VersionPopup.defaultProps = {
  fullWidth: false,
  bodyWidth: 0,
};

export default VersionPopup;
