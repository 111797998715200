import React from 'react';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _mapValues from 'lodash/mapValues';
import _values from 'lodash/values';
import _flatten from 'lodash/flatten';
import _isEmpty from 'lodash/isEmpty';

import TooltipCell from 'components/pages/PatientList/components/TooltipCell';
import EducationalNeedsName from 'components/pages/PatientList/components/EducationalNeedsName';
import WithMatchingCriteriaIcon from 'components/pages/GroupSurveyAddEdit/components/WithMatchingCriteriaIcon/WithMatchingCriteriaIcon';
import validator from 'utils/validator/core';
import { getValidators } from 'components/pages/GroupSurveyAddEdit/components/ShortInfo/ShortInfo.utils';

import formatFullName from 'utils/formatFullName';
import getAnonymousPatientName from 'utils/getAnonymousPatientName';

export const mapSurveyToolIds = (survey) => {
  const surveyTools = _get(survey, 'data.survey_tools', []);

  const mappedSurveyTools = {};
  _forEach(_uniq(_map(surveyTools, 'battery_id')), (batteryId) => {
    mappedSurveyTools[batteryId] = [];
  });

  return _mapValues(
    mappedSurveyTools,
    (_, key) => _map(_filter(surveyTools, (surveyTool) => surveyTool.battery_id === parseInt(key)), 'id'),
  );
};

export const getPatientName = (data, isMatchingCriteria) => {
  let patientName = (!data.first_name && !data.last_name)
    ? getAnonymousPatientName(data.id) : formatFullName(data, true);

  if (data.deleted_at) {
    if (data.has_special_educational_needs) {
      patientName = <TooltipCell value={<EducationalNeedsName name={patientName} />} />;
    }
    patientName = <TooltipCell value={patientName} />;
  }

  if (data.has_special_educational_needs) {
    patientName = <EducationalNeedsName name={patientName} />;
  }

  if (!isMatchingCriteria) {
    patientName = <WithMatchingCriteriaIcon name={patientName} />;
  }

  return patientName;
};

export const isSaveDisabled = (
  isAnonymous, selectedPatients, selectedSurveyTools, surveyDetails, intl,
) => {
  const validators = validator(surveyDetails, getValidators(isAnonymous), intl.formatMessage);
  const detailsAndToolsValidation = !_isEmpty(validators)
    || _flatten([..._values(selectedSurveyTools)]) < 1;

  if (isAnonymous) {
    return detailsAndToolsValidation;
  }

  return selectedPatients.length < 2 || detailsAndToolsValidation;
};
