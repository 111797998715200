import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';
import notFound from 'assets/img/notFound.svg';

import useStyles from './NoItems.styles';
import messages from './NoItems.messages';

const NoItemsComponent = ({ message, imgSrc }) => {
  const classes = useStyles({ imgSrc });

  return (
    <div className={classes.notFoundContainer}>
      {
        imgSrc ? (
          <img src={imgSrc} alt="notFound" />
        ) : null
      }
      <Typography
        variant={imgSrc ? 'h5' : 'body2'}
        className={classNames({
          [classes.titleWithCustomImage]: imgSrc,
          [classes.titleWithDefaultImage]: !imgSrc,
        })}
      >
        {message}
      </Typography>
    </div>
  );
};

NoItemsComponent.propTypes = {
  imgSrc: PropTypes.string,
  message: PropTypes.node,
};

NoItemsComponent.defaultProps = {
  imgSrc: notFound,
  message: <FormattedMessage {...messages.notFound} />,
};

export default NoItemsComponent;
