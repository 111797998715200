import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  maxWidth: {
    maxWidth: theme.sizes.cardContent.width,
  },
  fullWidth: {
    width: '100%',
  },
}));
