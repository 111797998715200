import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import VisibilityIconButton from 'components/VisibilityIconButton';

const PasswordInput = ({ value, onBlur, onChange }) => {
  const [visible, setVisibility] = useState(false);

  const inputType = visible ? 'text' : 'password';

  return (
    <Input
      type={inputType}
      value={value}
      margin="none"
      onBlur={onBlur}
      onChange={onChange}
      endAdornment={(
        <InputAdornment position="end">
          <VisibilityIconButton
            visible={visible}
            setVisibility={setVisibility}
          />
        </InputAdornment>
      )}
    />
  );
};

PasswordInput.propTypes = {
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

PasswordInput.defaultProps = {
  value: '',
  onBlur: () => {},
  onChange: () => {},
};

export default PasswordInput;
