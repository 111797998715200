import { defineMessages } from 'react-intl';
import moment from 'moment';

const messages = defineMessages({
  fieldValueIsIncorrect: 'Wybrany dzień nie może być późniejszy niż {maxValue}',
});

export default (value, maxValue) => {
  const result = {};
  let isValid = true;
  if (moment.isMoment(value)) {
    if (moment(value).isValid()) {
      const selectedDay = moment(value).date();

      isValid = selectedDay <= maxValue;
    }
  }

  result.isValid = isValid;
  result.message = messages.fieldValueIsIncorrect;
  result.messageParams = { maxValue };

  return result;
};
