import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Edycja ustawień placówki',
  netCost: 'Cena netto abonamentu',
  numberOfTablets: 'Ilość dzierżawionych tabletów',
  billingCycle: 'Okres rozliczeniowy (w miesiącach)',
  transitionalPeriod: 'Okres przejściowy (w dniach)',
  startDate: 'Data startu abonamentu',
  endDate: 'Data końca dzierżawy tabletów',
  firstDayOfBillingCycle: 'Pierwszy dzień okresu rozliczeniowego',
  showConfidenceInterval: 'Wyświetlanie przedziałów ufności',
  unlimitedUsage: 'Użycia nielimitowane',
  save: 'Zapisz',
  cancel: 'Anuluj',
  hasGroupExaminationsEnabled: 'Badania grupowe',
});
