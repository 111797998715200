import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Zmiana hasła',
  cancel: 'Anuluj',
  save: 'Zatwierdź',
  oldPassword: 'Stare hasło',
  newPassword: 'Nowe hasło',
  newPasswordConfirmation: ' Powtórz nowe hasło',
});
