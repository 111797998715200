import React from 'react';
import {
  FormattedMessage,
} from 'react-intl';

import messages from './NoPermissionsPage.messages';

const NoPermissionsPage = () => (
  <div>
    <FormattedMessage {...messages.noPermissions} />
  </div>
);

export default NoPermissionsPage;
