import { defineMessages } from 'react-intl';

export default defineMessages({
  edit: 'Edytuj',
  raports: 'Raport użyć testów',
  topUp: 'Zasil konto',
  diagnosticians: 'Lista Diagnostów',
  startTryAndBuy: 'Start T&B',
  endTryAndBuy: 'Koniec T&B',
  activate: 'Aktywuj placówkę',
  inactivate: 'Dezaktywuj placówkę',
  remove: 'Usuń placówkę',
  export: 'Eksport danych',
});
