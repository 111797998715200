import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import VideoPlayerView from 'components/pages/VideoPlayer';

import {
  onCancel,
  loadPageData,
} from './store';

class VideoPlayerContainer extends React.Component {
  static propTypes = {
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    modalProps: PropTypes.objectOf(PropTypes.any).isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.loadPageData(this.props.modalProps);
  }

  render() {
    return this.props.isLoadedPage ? (
      <VideoPlayerView {...this.props} />
    ) : null;
  }
}

const mapDispatchToProps = {
  onCancel,
  loadPageData,
};

const mapStateToProps = (state) => ({
  ...state.VideoPlayer,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoPlayerContainer);
