import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { classesShape } from 'utils/shapes/classesShape';

import styles from './Truncate.styles';

const isTruncated = (el) => el.scrollWidth > el.clientWidth;

const Truncate = ({
  classes,
  children,
}) => {
  const tooltipRef = useRef(null);
  const [elementIsTruncated, setElementIsTruncated] = useState(false);

  useLayoutEffect(() => {
    setElementIsTruncated(tooltipRef && tooltipRef.current && isTruncated(tooltipRef.current));
  }, []);

  const childrenWithWrapper = (
    <div className={classes.wrapper} ref={tooltipRef}>
      {children}
    </div>
  );

  return elementIsTruncated ? (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={children}
    >
      {childrenWithWrapper}
    </Tooltip>
  ) : childrenWithWrapper;
};

Truncate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  classes: classesShape.isRequired,
};

export default withStyles(styles)(Truncate);
