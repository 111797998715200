import React from 'react';

import TooltipCell from 'components/pages/PatientList/components/TooltipCell';
import EducationalNeedsName from 'components/pages/PatientList/components/EducationalNeedsName';

import formatFullName from 'utils/formatFullName';
import getAnonymousPatientName from 'utils/getAnonymousPatientName';

const getPatientName = (data) => {
  const patientName = (!data.first_name && !data.last_name)
    ? getAnonymousPatientName(data.id) : formatFullName(data, true);

  if (data.deleted_at) {
    if (data.has_special_educational_needs) {
      return <TooltipCell value={<EducationalNeedsName name={patientName} />} />;
    }
    return <TooltipCell value={patientName} />;
  }

  if (data.has_special_educational_needs) {
    return <EducationalNeedsName name={patientName} />;
  }

  return patientName;
};

const getPatientClassAndSection = (classNumber, section) => (section ? `${classNumber}${section}` : classNumber);

export { getPatientName, getPatientClassAndSection };
