import config from 'config';

import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import errorCatch from 'utils/errorCatch';
import truncateString from 'utils/truncateString';
import { VIDEO_PLAYER } from 'utils/constants/modalTypes';

import {
  hideLoader,
  showLoader,
  setModal,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  tutorials: [],
};

const mapTools = (movies, startingPosition) => movies.map((el, key) => ({
  id: key + 1 + startingPosition,
  thumbnail: `${config.localApiHostStorage}/${el.thumbnail}`,
  link: `${config.localApiHostStorage}/${el.video}`,
  title: truncateString(el.title, 20),
  description: truncateString(el.description, 65),
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'TUTORIALS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_TOOLS: 'TUTORIALS/SET_FILTERED_TOOLS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        tutorials: mapTools(action.responses.data.items, 0),
        totalItemsCount: action.responses.data.total,
      };
    }

    case actionTypes.SET_FILTERED_TOOLS: {
      return {
        ...state,
        tutorials: mapTools(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const loadVideos = () => (dispatch) => {
  const data = {
    search: 'status:ACTIVE',
  };

  const url = createURLWithQuery('video_tutorials', data);

  return ApiManager.request('get', dispatch, url);
};

export const onMovieClick = (url) => (dispatch) => {
  dispatch(setModal(VIDEO_PLAYER, { url }));
};

export const loadPageData = () => async (dispatch) => {
  dispatch(showLoader());

  try {
    const moviesData = await dispatch(loadVideos());
    dispatch(loadPageSuccess(moviesData));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
