import { replace } from 'connected-react-router';
import saveAs from 'file-saver';

import _get from 'lodash/get';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _mapValues from 'lodash/mapValues';
import _invert from 'lodash/invert';

import ApiManager from 'utils/ApiManager'; // eslint-disable-line import/no-cycle
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import parseContentDisposition from 'utils/parseContentDisposition';
import PromiseAll from 'utils/PromiseAll';
import getPreviousMenuItem from 'utils/getPreviousMenuItem';
import createURLWithQuery from 'utils/createURLWithQuery';
import permissions from 'utils/constants/permissions';
import config from 'config';

const assignPermissions = (userPermissions) => {
  const checkForPermission = (permission) => _includes(userPermissions, permission);
  const assignedPermissions = _mapValues(permissions, checkForPermission);

  return assignedPermissions;
};

export const initialState = {
  apiVersion: null,
  versionPopupVisible: false,
  loaderVisible: false,
  loaderPendingRequests: 0,
  transparentLoaderVisible: false,
  snackbarVisible: false,
  snackbarMessage: null,
  snackbarTimeout: null,
  title: null,
  activeMenuItem: null,
  activeSubMenuItem: null,
  isCollapsedMenu: false,
  drawer: {
    isTopDrawerVisible: false,
    content: '',
    onCloseCallback: null,
  },
  userData: {
    lastUsedAppVersion: null,
  },
  dialogData: {},
  dialogOpened: false,
  globalData: {},
  pagingData: {
    sortingField: null,
    sortingDirection: 'asc',
    lastFilterValues: {},
    page: 1,
    rowsPerPageOptions: [],
    rowsPerPage: 10,
  },
  permissions: {},
  modal: {
    type: null,
    props: null,
  },
  bodyWidth: document.body.clientWidth,
};

export const actionTypes = {
  SHOW_LOADER: 'GLOBAL/SHOW_LOADER',
  SHOW_VERSION_POPUP: 'GLOBAL/SHOW_VERSION_POPUP',
  HIDE_VERSION_POPUP: 'GLOBAL/HIDE_VERSION_POPUP',
  SET_APP_VERSION: 'GLOBAL/SET_APP_VERSION',
  SHOW_LOADER_MULTIPLE_REQUESTS: 'GLOBAL/SHOW_LOADER_MULTIPLE_REQUESTS',
  HIDE_LOADER_MULTIPLE_REQUESTS: 'GLOBAL/HIDE_LOADER_MULTIPLE_REQUESTS',
  SHOW_TRANSPARENT_LOADER: 'GLOBAL/SHOW_TRANSPARENT_LOADER',
  HIDE_LOADER: 'GLOBAL/HIDE_LOADER',
  SHOW_SNACKBAR: 'GLOBAL/SHOW_SNACKBAR',
  HIDE_SNACKBAR: 'GLOBAL/HIDE_SNACKBAR',
  SET_SNACKBAR_TIMEOUT: 'GLOBAL/SET_SNACKBAR_TIMEOUT',
  SET_TITLE: 'GLOBAL/SET_TITLE',
  SET_ACTIVE_MENU_ITEM: 'GLOBAL/SET_ACTIVE_MENU_ITEM',
  SET_USER_DATA: 'GLOBAL/SET_USER_DATA',
  OPEN_DIALOG: 'GLOBAL/OPEN_DIALOG',
  CLOSE_DIALOG: 'GLOBAL/CLOSE_DIALOG',
  SET_ROWS_PER_PAGE: 'GLOBAL/SET_ROWS_PER_PAGE',
  SET_PAGE: 'GLOBAL/SET_PAGE',
  SET_ROWS_PER_PAGE_OPTIONS: 'GLOBAL/SET_ROWS_PER_PAGE_OPTIONS',
  SET_LAST_FILTER_VALUES: 'GLOBAL/SET_LAST_FILTER_VALUES',
  SET_SORTING_DATA: 'GLOBAL/SET_SORTING_DATA',
  SET_API_VERSION: 'GLOBAL/SET_API_VERSION',
  CLEAR_PAGING_DATA: 'GLOBAL/CLEAR_PAGING_DATA',
  SET_ACTIVE_SUBMENU_ITEM: 'GLOBAL/SET_ACTIVE_SUBMENU_ITEM',
  SET_DRAWER_VISIBILITY: 'GLOBAL/SET_DRAWER_VISIBILITY',
  SET_SHOW_MODAL: 'GLOBAL/SET_SHOW_MODAL',
  SET_DRAWER_INVISIBILITY: 'GLOBAL/SET_DRAWER_INVISIBILITY',
  SET_GLOBAL_DATA: 'GLOBAL/SET_GLOBAL_DATA',
  SET_BODY_WIDTH: 'GLOBAL/SET_BODY_WIDTH',
  TOGGLE_COLLAPSING_MENU: 'GLOBAL/TOGGLE_COLLAPSING_MENU',
  SET_USER_CLINICS: 'GLOBAL/SET_USER_CLINICS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_VERSION_POPUP: {
      return {
        ...state,
        versionPopupVisible: true,
      };
    }

    case actionTypes.HIDE_VERSION_POPUP: {
      return {
        ...state,
        versionPopupVisible: false,
      };
    }

    case actionTypes.SET_APP_VERSION: {
      return {
        ...state,
        userData: {
          ...state.userData,
          last_used_app_version: action.version,
        },
      };
    }

    case actionTypes.SHOW_LOADER: {
      return {
        ...state,
        loaderVisible: true,
        transparentLoaderVisible: false,
      };
    }

    case actionTypes.SHOW_LOADER_MULTIPLE_REQUESTS: {
      return {
        ...state,
        loaderPendingRequests: state.loaderPendingRequests + 1,
        loaderVisible: true,
      };
    }

    case actionTypes.HIDE_LOADER_MULTIPLE_REQUESTS: {
      const currentLoaderPendingRequests = state.loaderPendingRequests - 1;

      return {
        ...state,
        loaderPendingRequests: currentLoaderPendingRequests,
        loaderVisible: currentLoaderPendingRequests !== 0,
      };
    }

    case actionTypes.SHOW_TRANSPARENT_LOADER: {
      return {
        ...state,
        loaderVisible: false,
        transparentLoaderVisible: true,
      };
    }

    case actionTypes.HIDE_LOADER: {
      return {
        ...state,
        loaderVisible: false,
        transparentLoaderVisible: false,
      };
    }

    case actionTypes.SET_DRAWER_VISIBILITY: {
      return {
        ...state,
        drawer: {
          isTopDrawerVisible: action.drawer.visibility,
          content: action.drawer.content,
          onCloseCallback: action.drawer.onCloseCallback,
        },
      };
    }

    case actionTypes.SET_DRAWER_INVISIBILITY: {
      return {
        ...state,
        drawer: {
          isTopDrawerVisible: false,
          content: null,
          onCloseCallback: null,
        },
      };
    }

    case actionTypes.SHOW_SNACKBAR: {
      return {
        ...state,
        snackbarVisible: true,
        snackbarMessage: action.message,
        messageFromApi: action.fromApi,
        snackbarType: action.snackbarType,
      };
    }

    case actionTypes.HIDE_SNACKBAR: {
      return {
        ...state,
        snackbarVisible: false,
        snackbarMessage: null,
      };
    }

    case actionTypes.SET_SNACKBAR_TIMEOUT: {
      return {
        ...state,
        snackbarTimeout: action.timeout,
      };
    }

    case actionTypes.SET_TITLE: {
      return {
        ...state,
        title: action.title,
      };
    }

    case actionTypes.SET_ACTIVE_MENU_ITEM: {
      return {
        ...state,
        activeSubMenuItem: null,
        activeMenuItem: action.activeItem,
        previousMenuItem: getPreviousMenuItem(state),
      };
    }

    case actionTypes.SET_ACTIVE_SUBMENU_ITEM: {
      return {
        ...state,
        activeMenuItem: null,
        activeSubMenuItem: action.activeSubItem,
        previousMenuItem: getPreviousMenuItem(state),
      };
    }

    case actionTypes.SET_USER_DATA: {
      return {
        ...state,
        userData: {
          ...action.data,
          role: _find(action.role, ({ id }) => id === action.data.role_id),
        },
        permissions: {
          ...assignPermissions(action.data.permissions),
        },
      };
    }

    case actionTypes.SET_GLOBAL_DATA: {
      return {
        ...state,
        globalData: {
          standardizedScales: action.standardizedScales,
        },
      };
    }

    case actionTypes.OPEN_DIALOG: {
      return {
        ...state,
        dialogOpened: true,
        dialogData: action.dialogData,
      };
    }

    case actionTypes.CLOSE_DIALOG: {
      return {
        ...state,
        dialogOpened: false,
        dialogData: {},
      };
    }

    case actionTypes.SET_ROWS_PER_PAGE: {
      return {
        ...state,
        pagingData: {
          ...state.pagingData,
          rowsPerPage: action.value,
        },
      };
    }

    case actionTypes.SET_PAGE: {
      return {
        ...state,
        pagingData: {
          ...state.pagingData,
          page: action.value,
        },
      };
    }

    case actionTypes.SET_ROWS_PER_PAGE_OPTIONS: {
      return {
        ...state,
        pagingData: {
          ...state.pagingData,
          rowsPerPage: action.response.data.perPageNumber[0],
          rowsPerPageOptions: action.response.data.perPageNumber,
        },
      };
    }

    case actionTypes.SET_LAST_FILTER_VALUES: {
      return {
        ...state,
        pagingData: {
          ...state.pagingData,
          lastFilterValues: action.values,
        },
      };
    }

    case actionTypes.SET_SORTING_DATA: {
      return {
        ...state,
        pagingData: {
          ...state.pagingData,
          page: 1,
          sortingField: action.data.fieldName,
          sortingDirection: action.data.sortDirection,
        },
      };
    }

    case actionTypes.CLEAR_PAGING_DATA: {
      return {
        ...state,
        pagingData: {
          ...initialState.pagingData,
          rowsPerPageOptions: state.pagingData.rowsPerPageOptions,
          rowsPerPage: state.pagingData.rowsPerPage,
        },
      };
    }

    case actionTypes.SET_API_VERSION: {
      return {
        ...state,
        apiVersion: action.apiVersion,
      };
    }

    case actionTypes.SET_SHOW_MODAL: {
      return {
        ...state,
        modal: {
          type: action.modalType,
          props: action.modalProps,
        },
      };
    }

    case actionTypes.SET_BODY_WIDTH: {
      return {
        ...state,
        bodyWidth: action.bodyWidth,
      };
    }

    case actionTypes.TOGGLE_COLLAPSING_MENU: {
      return {
        ...state,
        isCollapsedMenu: !state.isCollapsedMenu,
      };
    }

    case actionTypes.SET_USER_CLINICS: {
      return {
        ...state,
        userData: {
          ...state.userData,
          clinics: action.clinics,
        },
      };
    }

    default:
      return state;
  }
};

const setSnackbarTimeout = (timeout) => ({
  type: actionTypes.SET_SNACKBAR_TIMEOUT,
  timeout,
});

export const setRowsPerPageOptions = (response) => ({
  type: actionTypes.SET_ROWS_PER_PAGE_OPTIONS,
  response,
});

export const showVersionPopup = () => ({
  type: actionTypes.SHOW_VERSION_POPUP,
});

const setAppVersion = (version) => ({
  type: actionTypes.SET_APP_VERSION,
  version,
});

export const hideVersionPopup = (version) => (dispatch, getStore) => {
  if (version) {
    const userId = getStore().Global.userData.id;

    const body = {
      last_used_app_version: version,
    };

    ApiManager.request('put', dispatch, `users/${userId}/last_used_app_version`, body);
    dispatch(setAppVersion(version));
  }

  dispatch({
    type: actionTypes.HIDE_VERSION_POPUP,
  });
};

export const showLoader = () => ({
  type: actionTypes.SHOW_LOADER,
});

export const showLoaderMultipleRequests = (pendingRequests) => ({
  type: actionTypes.SHOW_LOADER_MULTIPLE_REQUESTS,
  pendingRequests,
});

export const hideLoaderMultipleRequests = () => ({
  type: actionTypes.HIDE_LOADER_MULTIPLE_REQUESTS,
});

export const showTransparentLoader = () => ({
  type: actionTypes.SHOW_TRANSPARENT_LOADER,
});

export const hideLoader = () => ({
  type: actionTypes.HIDE_LOADER,
});

export const showSnackbarAction = (message, fromApi, snackbarType) => ({
  type: actionTypes.SHOW_SNACKBAR,
  message,
  fromApi,
  snackbarType,
});

export const setDrawerVisibility = (drawer) => ({
  type: actionTypes.SET_DRAWER_VISIBILITY,
  drawer,
});

export const setDrawerInvisibility = () => ({
  type: actionTypes.SET_DRAWER_INVISIBILITY,
});

export const hideSnackbar = () => ({
  type: actionTypes.HIDE_SNACKBAR,
});

export const showSnackbar = (
  message, fromApi, type, withAutohide = true,
) => (dispatch, getStore) => {
  dispatch(showSnackbarAction(message, fromApi, type));
  const store = getStore().Global;
  const snackbarTimeout = store.snackbarTimeout;

  if (snackbarTimeout) {
    clearTimeout(snackbarTimeout);
  }

  if (withAutohide) {
    const timeout = setTimeout(() => {
      dispatch(hideSnackbar());
    }, config.hideSnackbarTimeout);
    dispatch(setSnackbarTimeout(timeout));
  }
};

export const setApiVersion = (apiVersion) => (dispatch, getStore) => {
  if (getStore().Global.apiVersion !== apiVersion) {
    dispatch({
      type: actionTypes.SET_API_VERSION,
      apiVersion,
    });
  }
};

export const setModal = (modalType, modalProps) => ({
  type: actionTypes.SET_SHOW_MODAL,
  modalType,
  modalProps,
});

export const setBodyWidth = (bodyWidth) => ({
  type: actionTypes.SET_BODY_WIDTH,
  bodyWidth,
});

export const toggleCollapsingMenu = () => ({
  type: actionTypes.TOGGLE_COLLAPSING_MENU,
});

export const setUserClinics = (clinics) => ({
  type: actionTypes.SET_USER_CLINICS,
  clinics,
});

const defaultLogoutSuccess = (dispatch) => {
  dispatch(showSnackbar(snackbarMessages.logoutSuccess));
  dispatch(replace('/login'));
};

export const ping = () => (dispatch) => ApiManager.request('get', dispatch, 'ping');

export const logout = (logoutSuccess = defaultLogoutSuccess) => (dispatch) => {
  dispatch(showTransparentLoader());

  ApiManager.request('post', dispatch, 'logout', {}).then(() => {
    dispatch(hideLoader());
    localStorage.setItem(config.localStorageLoginKey, '');
    logoutSuccess(dispatch);
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const setTitle = (title) => ({
  type: actionTypes.SET_TITLE,
  title,
});

export const setActiveMenuItem = (activeItem) => ({
  type: actionTypes.SET_ACTIVE_MENU_ITEM,
  activeItem,
});

export const setActiveSubMenuItem = (activeSubItem) => ({
  type: actionTypes.SET_ACTIVE_SUBMENU_ITEM,
  activeSubItem,
});

export const setGlobalData = (standardizedScales) => ({
  type: actionTypes.SET_GLOBAL_DATA,
  standardizedScales,
});

export const setUserData = (data, role) => ({
  type: actionTypes.SET_USER_DATA,
  data,
  role,
});

export const openDialog = (dialogData) => ({
  type: actionTypes.OPEN_DIALOG,
  dialogData,
});

export const closeDialog = () => ({
  type: actionTypes.CLOSE_DIALOG,
});

export const fetchUsersClinics = () => (dispatch) => ApiManager.request('get', dispatch, createURLWithQuery('current_user', { with_clinic: 'clinics' }));

export const resetPassword = (email) => (dispatch) => {
  dispatch(showTransparentLoader());

  const requestBody = {
    data: {
      email,
    },
  };

  return ApiManager.request('post', dispatch, 'forgot_password', requestBody);
};

export const changePassword = (email) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(resetPassword(email)).then(() => {
    dispatch(showSnackbar(snackbarMessages.succesfullyResetedPassword));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const loadGlobalData = () => (dispatch) => {
  ApiManager.request('get', dispatch, 'standard_scales').then((response) => {
    dispatch(setGlobalData(response.data));
  });
};

export const getMeta = () => (dispatch) => ApiManager.request('get', dispatch, 'meta');

export const loadUserData = () => (dispatch) => new Promise((resolve, reject) => {
  PromiseAll({
    meta: dispatch(getMeta()),
    currentUser: ApiManager.request('get', dispatch, createURLWithQuery('current_user', { with_clinic: 'clinics' })),
  })
    .then((response) => {
      ApiManager.request('get', dispatch, `users/${response.currentUser.data.id}`)
        .then((resp) => {
          const userData = {
            ...response.currentUser.data,
            organizations: [...resp.data.organizations],
          };

          dispatch(setRowsPerPageOptions(response.meta));
          dispatch(setUserData(userData, response.meta.data.userRole));
          resolve();
        })
        .catch((error) => reject(error));
    })
    .catch((error) => reject(error));
});

export const mapMeta = (meta) => meta.map((el) => ({
  id: el.id,
  name: el.name,
}));

export const setRowsPerPage = (value) => ({
  type: actionTypes.SET_ROWS_PER_PAGE,
  value,
});

export const setPage = (value) => ({
  type: actionTypes.SET_PAGE,
  value,
});

export const setLastFilterValues = (values) => ({
  type: actionTypes.SET_LAST_FILTER_VALUES,
  values,
});

export const setSortingData = (data) => ({
  type: actionTypes.SET_SORTING_DATA,
  data,
});

export const setSortingAndPagination = (
  defaultSorting, sortFieldsMapping, values,
) => (dispatch) => {
  const invertedSortFieldsMapping = _invert(sortFieldsMapping);
  const sorting = {
    fieldName: values.orderBy
      ? invertedSortFieldsMapping[values.orderBy]
      : defaultSorting.fieldName,
    sortDirection: values.sortedBy
      ? values.sortedBy
      : defaultSorting.sortDirection,
  };
  dispatch(setSortingData(sorting));

  if (values.perPage) {
    dispatch(setRowsPerPage(Number(values.perPage)));
  }

  if (values.page) {
    dispatch(setPage(Number(values.page)));
  }
};

export const clearPagingData = () => ({
  type: actionTypes.CLEAR_PAGING_DATA,
});

export const getFromStore = (dataKey) => (_, getStore) => _get(getStore(), dataKey, '');

export const downloadSurveyFile = (id) => (dispatch) => {
  ApiManager.request('get', dispatch, `sessions/${id}/export`, {}, true).then((response) => {
    const contentType = 'text/csv;charset=UTF-8';
    const { filename } = parseContentDisposition({ header: response.headers['content-disposition'] });
    const file = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), response.data],
      { type: contentType });
    saveAs(file, filename);
  });
};

export const setPreviousMenuItem = () => (dispatch, getStore) => {
  const { previousMenuItem } = getStore().Global;

  if (previousMenuItem.type === 'mainMenu') {
    dispatch(setActiveMenuItem(previousMenuItem.item));
  } else {
    dispatch(setActiveSubMenuItem(previousMenuItem.item));
  }
};
