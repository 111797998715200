import PropTypes from 'prop-types';
import moment from 'moment';

const initialValuesShape = PropTypes.exact({
  netCost: PropTypes.number,
  numberOfTablets: PropTypes.number,
  billingCycle: PropTypes.number,
  transitionalPeriod: PropTypes.number,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  firstDayOfBillingCycle: PropTypes.number,
  showConfidenceInterval: PropTypes.bool,
  unlimitedUsage: PropTypes.bool,
});

export { initialValuesShape };
