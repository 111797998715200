/* eslint-disable max-len */
import React from 'react';

export default () => (
  <>
    <h6>POLITYKA PRYWATNOŚCI</h6>
    <br />
    W Diagmatic szanujemy Twoją prywatność i dbamy o ochronę Twoich danych osobowych. Ten dokument ma za zadanie dostarczyć Ci informacji na temat tego, w jaki sposób postępujemy z Twoimi danymi osobowymi, powiadomić o przysługujących Ci prawach oraz o tym, jak przepisy chronią Ciebie i Twoje dane osobowe. Prosimy o uważne zapoznanie się z tym dokumentem przed rozpoczęciem korzystania z naszych Usług. Dokument ten służy również realizacji obowiązku wynikającego z art. 13 oraz 14 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych – dalej też „RODO”).
    <br />
    <br />
    Definicje użyte w tym dokumencie:
    <br />
    <br />
    <b>Badany</b>
    {' – '}
    osoba poddawana badaniom z pomocą Systemu, w szczególności pacjent lub uczeń Organizacji.
    <br />
    <br />
    <b>Diagmatic</b>
    {' '}
    lub
    {' '}
    <b>My</b>
    {' – '}
    oznacza spółkę działającą w oparciu o polskie prawo pod firmą Diagmatic Spółka z ograniczoną odpowiedzialnością z siedzibą w Krakowie, ul. Bydgoska 6, 30-056 Kraków, wpisaną do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla Krakowa-Śródmieścia w Krakowie, Wydział XI Gospodarczy KRS pod numerem KRS 0000767866 oraz posługującą się następującym numerem identyfikacyjnym: NIP 676 256 07 17.
    <br />
    <br />
    <b>Diagnosta</b>
    {' – '}
    Użytkownik będący osobą, dla której działający w imieniu Podmiotu Koordynator zakłada Profil w Systemie.
    <br />
    <br />
    <b>Koordynator</b>
    {' '}
    – Użytkownik będący osobą wskazaną przez Podmiot, dla której Profil w Systemie zakłada Diagmatic.
    <br />
    <br />
    <b>Organizacja</b>
    {' '}
    – jednostka organizacyjna kierująca Badanego na badania wykonywane z pomocą Systemu, w szczególności szkoła, poradnia.
    <br />
    <br />
    <b>Podmiot</b>
    {' '}
    – organizacja, korzystająca z Systemu w celu wsparcia prowadzenia własnej działalności.
    <br />
    <br />
    <b>Polityka Prywatności</b>
    {' '}
    – ten dokument.
    <br />
    <br />
    <b>Profil</b>
    {' '}
    – profil zakładany przez Podmiot dla Diagnostów lub zakładany przez nas dla Koordynatora i przypisany do konkretnego Użytkownika po stronie Wprowadzającego lub Organizacji.
    <br />
    <br />
    <b>Regulamin</b>
    {' '}
    – regulamin świadczenia Usług wyświetlany i akceptowany przez Użytkowników w trakcie rejestracji i pierwszego logowania do Systemu.
    <br />
    <br />
    <b>System</b>
    {' '}
    – oznacza aplikację webową oraz powiązane z nią elementy niezbędne do jej prawidłowego funkcjonowania i dostępne za pomocą stron internetowych diagnozy.diagmatic.pl oraz badania.diagmatic.pl, za pośrednictwem której oferujemy Nasze Usługi.
    <br />
    <br />
    <b>Usługi</b>
    {' '}
    – oznaczają każdą usługę, treść, funkcjonalność, technologię lub funkcję, które oferujemy za pomocą Systemu. Usługi nie oznaczają usług, jakie świadczyć mogą Podmioty i Diagności na rzecz Badanych lub innych podmiotów zlecających badanie z pomocą Systemu.
    <br />
    <br />
    <b>Użytkownik</b>
    {' '}
    – oznacza każdego użytkownika Systemu. Użytkownikiem może być zarówno Koordynator, jak i Diagnosta.
    <br />
    <br />
    <ol className="nestedList">
      <li>
        <b>Administrator danych osobowych</b>
        <br />
        Administratorem danych w zakresie wskazanym w Polityce Prywatności jest:
        <br />
        <ul>
          <li>Diagmatic w odniesieniu do danych osobowych Koordynatorów i Diagnostów,</li>
          <li>Organizacje w odniesieniu do danych osobowych Badanych, rodziców i opiekunów Badanych, osób reprezentujących Organizacje kierujące Badanych na badania z pomocą Systemu, a także w odniesieniu do adresów e-mail Diagnostów.</li>
        </ul>
        <br />
        Aktualne dane kontaktowe, pod którymi możesz uzyskać informacje oraz za pomocą których możesz skorzystać z praw w zakresie objętym Polityką Prywatności:
        <br />
        <ul>
          <li>adres: Diagmatic Spółka z ograniczoną odpowiedzialnością z siedzibą w Krakowie, ul. Bydgoska 6, 30-056 Kraków</li>
          <li>e-mail: dane.osobowe@diagmatic.pl</li>
        </ul>
        <br />
      </li>

      <li>
        <b>Zakres przetwarzanych danych osobowych</b>
        <br />
        <br />
        <ol className="nestedList">
          <li>
            <b>Rejestracja i inne informacje o Użytkowniku</b>
            <br />
            <br />
            Na potrzeby rejestracji, w celu korzystania ze świadczonych przez nas Usług, możemy zbierać dotyczące Użytkowników: dane identyfikacyjne, dane o powiązaniu z konkretnym Podmiotem, a także hasła, jakie zostaną ustanowione.
            <br />
            <br />
            Dane te (poza hasłami) podawane nam są przez Podmiot, z którym jesteś powiązany.
            <br />
            <br />
          </li>
          <li>
            <b>Komunikacja telefoniczna oraz e-mailowa</b>
            <br />
            <br />
            W przypadku kontaktu telefonicznego albo za pomocą e-mail (w szczególności w przypadku zgłaszania błędów w funkcjonowaniu Systemu, czy kontaktu w sprawie wykonania Twoich uprawnień, o których mowa w tej Polityce Prywarności) zbieramy wszystkie informacje, które zdecydujesz się nam przekazać. Jeżeli rozmowa telefoniczna będzie nagrywana zostaniesz o tym poinformowany na jej początku. Jeżeli sprzeciwiasz się nagrywaniu rozmów telefonicznych, rozmowa nie będzie kontynuowana, a Ty możesz skorzystać z innych dostępnych form komunikacji z Diagmatic.
            <br />
            <br />
          </li>
          <li>
            <b>Dane, które gromadzimy automatycznie w związku z korzystaniem z Systemu</b>
            <br />
            <br />
            Kiedy korzystasz z naszego Systemu, automatycznie zbieramy o Tobie następujące informacje:
            <br />
            <br />
            <ol className="nestedList">
              <li>
                <b>Dane o urządzeniu</b>
                <br />
                <br />
                Gromadzimy informacje dotyczące Twojego urządzenia, takie jak wersja systemu operacyjnego oraz unikalne identyfikatory. Informacje związane z urządzeniem mogą być przez nas łączone z Twoim Profilem i jeżeli będzie możliwość przypisania do konkretnego Użytkownika, wówczas stanowią one również dane osobowe.
                <br />
                <br />
              </li>
              <li>
                <b>Dane związane z aktywnością w Systemie</b>
                <br />
                <br />
                <p>Zbieramy informacje dotyczące Twojej aktywności w Systemie, a wśród nich informacje:</p>
                <br />
                <ul>
                  <li>
                    dotyczące Twojego logowania (data rejestracji w Systemie, numer IP, czas wejścia na stronę Systemu, przybliżona lokalizacja wynikająca z numeru IP) oraz typu Twojej przeglądarki internetowej,
                  </li>
                  <li>
                    Twoich interakcjach z Systemem (np. wprowadzane dane, korzystanie z określonych testów, sposób korzystania z Systemu).
                  </li>
                </ul>
                <br />
              </li>
            </ol>
          </li>
          <li>
            <b>Dane pozyskane o osobach innych niż Użytkownicy - dane wprowadzane przez Koordynatorów i Diagnostów oraz pozyskane w trakcie badań</b>
            <br />
            <br />
            W trakcie korzystania z Usług mogą być zbierane i dalej przetwarzane informacje o Badanych, rodzicach i opiekunach Badanych oraz osobach reprezentujących Organizacje, które kierowały osoby na badania z pomocą Systemu. Dane te mogą wprowadzać do Systemu Użytkownicy lub mogą być wprowadzane do Systemu przez Badanych (w trakcie prowadzenia badań) lub generowane przez System w oparciu o dane wprowadzone przez Badanych. Administratorem danych osobowych wskazanych osób jest każdorazowo Podmiot, który powierzył nam te dane w ramach zawartej umowy. Podmioty te są odpowiedzialne za uzyskanie odpowiedniej podstawy prawnej dla przetwarzania danych tych osób za pomocą Systemu, jak również za realizację praw tych osób, w tym za odpowiednie ich poinformowanie o przetwarzaniu ich danych z pomocą Systemu.
          </li>
        </ol>
      </li>
      <br />
      <li>
        <b>W jakim celu przetwarzamy dane osobowe?</b>
        <br />
        <br />
        Będziemy przetwarzać Twoje dane osobowe tylko wtedy, gdy jest to zgodne z przepisami prawa i niezbędne do realizacji celów, które zostały wskazane w Polityce Prywatności. Co do zasady przetwarzamy Twoje dane osobowe, gdy są niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez nas, to jest:
        <br />
        <br />
        <ul>
          <li>
            prawidłowego wykonania umowy zawartej między nami a Podmiotem w zakresie udostępnienia Systemu do korzystania przez Użytkowników działających w imieniu i na rzecz Podmiotu,
          </li>
          <li>
            zapewnienia prawidłowego działania Systemu, jego rozwoju oraz ciągłego usprawniania jego działania, poprawiania jego bezpieczeństwa, wygody korzystania i wdrażania nowych funkcjonalności,
          </li>
          <li>
            kontaktu z Tobą, gdy będzie to niezbędne (np. powiadomienia Cię o zmianach Regulaminu czy Polityki Prywatności lub o pracach i przerwach technicznych w funkcjonowaniu Systemu),
          </li>
          <li>
            przesyłania informacji istotnych dla korzystania z Systemu oraz z zakresu diagnostyki w celu podnoszenia wiedzy, standardów i jakości pracy Użytkownika korzystającego z Systemu, w tym informacji o nowych publikacjach na prowadzonym przez nas blogu,
          </li>
          <li>
            dochodzenia i obrony przed roszczeniami, przed sądami i organami administracji oraz poza nimi (w zakresie wszystkich zebranych danych),
          </li>
          <li>
            archiwizacji i wykonywania kopii zapasowych posiadanych danych, w związku z nałożonym na nas jako na administratora danych obowiązkiem prawidłowego zabezpieczenia danych (w zakresie wszystkich zebranych danych).
          </li>
        </ul>
        <br />

        W zakresie przetwarzania w celu zapewnienia prawidłowego działania Systemu, jego rozwoju oraz ciągłego usprawniania jego działania, poprawiania jego bezpieczeństwa, wygody korzystania i wdrażania nowych funkcjonalności mieścić może się w szczególności:
        <br />
        <br />
        <ul>
          <li>
            określanie jak długo i w jaki sposób poruszasz się po naszym Systemie, w celu poznania Twoich zachowań i poprawy naszych Usług;
          </li>
          <li>
            analiza konkretnych zachowań w celu zapobiegania nadużyciom.
          </li>
        </ul>
        <br />
        Przetwarzanie danych osobowych o osobach innych niż Użytkownicy, które odbywa się na zlecenie Podmiotów, odbywa się wyłącznie w celach wskazanych w umowach zawartych przez nas z tymi Podmiotami.
        <br />
        <br />
      </li>

      <li>
        <b>Zautomatyzowane podejmowanie decyzji, w tym profilowanie</b>
        <br />
        <br />
        Informujemy, iż w ramach świadczonych Usług nie wykorzystujemy danych osobowych do zautomatyzowanego podejmowania decyzji lub profilowania Użytkowników.
        <br />
        <br />
        Dane osobowe Badanych służyć będą do profilowania („automatyzowanego przetwarzania danych osobowych, które polega na wykorzystaniu tych danych osobowych do oceny niektórych czynników osobowych osoby fizycznej, w szczególności do analizy mającej wspierać diagnozę danej osoby fizycznej).
        <br />
        <br />
      </li>

      <li>
        <b>W jaki sposób będziemy Cię informować o zmianach w naszej Polityce Prywatności?</b>
        <br />
        <br />
        Polityka Prywatności może być przez nas zmieniana lub aktualizowana. O wszelkich zmianach każdy Użytkownik zostanie powiadomiony w momencie pierwszego wejścia do Systemu po dokonaniu zmiany. Jeśli nie wyrażasz zgody na dokonane przez nas zmiany, masz w każdej chwili prawo zrezygnować z dalszego korzystania z Systemu, którą to decyzję uszanujemy.
        <br />
        <br />
      </li>

      <li>
        <b>Twoje prawa</b>
        <br />
        <br />
        W zakresie każdego z niżej wskazanych praw możesz skontaktować się z nami w szczególności za pomocą danych kontaktowych podanych w punkcie 1. Polityki Prywatności.
        <br />
        <br />
        <ul>
          <li>
            <b>Prawo do uzyskania informacji, dostępu do danych oraz do otrzymania kopii danych.</b>
            {' '}
            Masz prawo w każdym czasie zażądać podania informacji o Twoich danych osobowych, które przechowujemy, lub do których mamy dostęp. Na Twoje żądanie zostanie Ci nieodpłatnie przedstawiona kopia Twoich danych osobowych podlegających przetwarzaniu. Za przesłanie każdej kolejnych kopii danych mamy prawo do zażądania opłaty, która pokryje uzasadnione koszty obsługi takiego żądania.
          </li>
          <li>
            <b>Prawo do sprostowania danych osobowych.</b>
            {' '}
            Podejmujemy racjonalnie uzasadnione kroki w celu zapewnienia, że Twoje dane osobowe są prawidłowe, kompletne i aktualne. Jeżeli konieczne będą do wprowadzenia zmiany tych danych, prosimy byś nas o tym zawiadomił.
            <br />
          </li>
          <li>
            <b>Prawo do usunięcia danych i do ograniczenia przetwarzania.</b>
            {' '}
            We wskazanych w przepisach o ochronie danych osobowych przypadkach masz prawo, by żądać usunięcia Twoich danych osobowych. Prawo to nie jest jednakże absolutne – mogą wystąpić sytuacje, gdy będziemy nadal uprawnieni, by Twoje dane osobowe przetwarzać. Możesz również zwrócić się o ograniczenie dalszego przetwarzania Twoich danych.
            <br />
          </li>
          <li>
            <b>Prawo do wniesienia sprzeciwu wobec przetwarzania.</b>
            {' '}
            We wskazanych w przepisach przypadkach masz prawo by sprzeciwić się dalszemu przetwarzaniu Twoich danych, w sytuacji gdy podstawą przetwarzania danych osobowych jest nasz prawnie uzasadniony interes.
            <br />
          </li>
          <li>
            <b>Prawo do wniesienia skargi do organu nadzorczego.</b>
            {' '}
            Masz prawo do wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych – co do zasady będzie nim Prezes Urzędu Ochrony Danych Osobowych z siedzibą w Warszawie.
          </li>
        </ul>
        <br />
        <b>Realizacja praw jest, co do zasady, bezpłatna.</b>
        {' '}
        Co do zasady nie będziesz musiał wnosić opłaty w szczególności za realizację prawa dostępu do Twoich danych osobowych (lub za realizację jakichkolwiek innych praw). Możemy pobrać opłatę w rozsądnej wysokości jeśli Twoje żądanie jest w oczywisty sposób nieuzasadnione lub nadmierne, w szczególności ze względu na ustawiczny i powtarzalny charakter. W takich przypadkach możemy również odmówić spełnienia Twojego żądania.
        {' '}
        <br />
        <br />
        <b>Czas realizacji żądań:</b>
        {' '}
        dokładamy starań, aby odpowiedzieć na wszystkie prawnie uzasadnione żądania w ciągu jednego miesiąca. Jeśli Twoje żądanie jest szczególnie skomplikowane lub złożyłeś kilka żądań, ich rozpoznanie może zająć nam dłużej niż miesiąc. W takim wypadku w ciągu miesiąca poinformujemy Cię o wydłużeniu terminu, nie dłużej jednak niż o kolejne dwa miesiące.
        <br />
        <br />
      </li>

      <li>
        <b>Komu udostępniamy Twoje dane?</b>
        <br />
        <br />
        Dołożymy należytej staranności w doborze podmiotów, którym przekazywać będziemy Twoje dane i w przypadku takich wybranych podmiotów wymagać będziemy, by chronili Twoje dane za pomocą odpowiednich środków technicznych i organizacyjnych. Twoje dane osobowe mogą zostać ujawnione jedynie:

        <br />
        <ol className="alphabetList">
          <li>
            osobom trzecim świadczącym na naszą rzecz usługi, które są potrzebne dla realizacji celów, w związku z którymi przetwarzamy Twoje dane, np. usług z zakresu IT, komunikacji elektronicznej, hostingu,
          </li>
          <li>
            odbiorcom, na rzecz których ujawnienie wymagane jest na mocy odpowiednich przepisów lub postanowienia sądu lub innego organu władzy, np. organom administracji publicznej, organy nadzorcze, organy ścigania,
          </li>
          <li>
            innym odbiorcom, gdy przekazanie im danych jest niezbędne, by chronić Twoje żywotne interesy lub żywotne interesy innych osób fizycznych lub dla dobra ogółu.
          </li>
        </ol>
        <br />
      </li>

      <li>
        <b>Przekazywanie danych do państw trzecich</b>
        <br />
        <br />
        Twoje dane co do zasady nie będą przekazywane poza Europejski Obszar Gospodarczy (EOG). Każdorazowo, gdy powstanie jednak potrzeba przekazania Twoich danych poza EOG, w tym do państw które nie zapewniają takiego samego lub odpowiedniego poziomu ochrony danych osobowych, zadbamy o to, by odbywało się to w oparciu o ważną podstawę prawną oraz z wykorzystaniem prawem wymaganych zabezpieczeń.
        <br />
        <br />
      </li>

      <li>
        <b>Przez jaki okres przechowujemy Twoje dane?</b>
        <br />
        <br />
        Przechowujemy Twoje dane nie dłużej, niż jest to niezbędne do realizacji celów, dla których zostały zebrane.
        <br />
        <br />
        Twoje dane osobowe związane z kontem w Systemie przechowujemy przez czas jego posiadania przez Ciebie - dla celów realizacji Usług, a także ich rozliczania z Podmiotami.
        <br />
        <br />
        Po zamknięciu Twojego konta możemy przechowywać Twoje dane osobowe:
        <br />
        <br />
        <ul>
          <li>
            przez okres, który jest niezbędny do wypełnienia obowiązków wynikających z przepisów prawa (np. księgowych i podatkowych – przez okres wskazany w tych przepisach jako dopuszczalny) lub
          </li>
          <li>
            przez okres istnienia naszych prawnie uzasadnionych interesów (np. w celach zwalczania nadużyć, dochodzenia i obrony przed roszczeniami – przez okres nie dłuższy niż przedawnienie tych roszczeń, a w celach archiwizacji – przez okres ustalony w naszych wewnętrznych procedurach archiwizacji danych).
          </li>
        </ul>
        <br />
        Dane osobowe związane z technologią plików cookie są przechowywane przez czas odpowiadający cyklowi życia plików cookie lub do czasu ich usunięcia przez Użytkownika.
        <br />
        <br />
        Dane innych osób niż Użytkownicy oraz adresy email Diagnostów przechowujemy przez czas wynikający z umów powierzenia zawartych z Podmiotami.
        <br />
        <br />
      </li>

      <li>
        <b>Bezpieczeństwo przetwarzania</b>
        <br />
        <br />
        Wszystkie informacje, które otrzymujemy na Twój temat, przechowujemy na odpowiednio zabezpieczonych serwerach naszych dostawców usług hostingowych. Korzystając z usług firm zapewniających przechowywanie danych w chmurze każdorazowo dokładamy starań, by wybrać podmiot profesjonalny i zaufany, który zapewni odpowiedni poziom ochrony danych i który przechowuje Twoje dane na terenie Europejskiego Obszaru Gospodarczego.
        <br />
        <br />
        Wdrożyliśmy także odpowiednie oraz niezbędne środki techniczne i organizacyjne służące ochronie Twoich danych, które są regularnie monitorowane tak, by:
        <br />
        <br />
        <ul>
          <li>
            chronić dane przed przypadkową lub niezgodną z prawem utratą, dostępem lub ujawnieniem, w tym w szczególności w trakcie transferu danych korzystamy z połączenia zabezpieczonego SSL z potwierdzonymi certyfikatami,
          </li>
          <li>
            identyfikować możliwe do przewidzenia ryzyka dla bezpieczeństwa Systemu i danych, oraz
          </li>
          <li>
            minimalizować zagrożenia dla bezpieczeństwa, w tym przez dokonywanie oceny ryzyka i regularne testy.
          </li>
        </ul>
        <br />
        Pamiętaj jednak, że pomimo wdrożonych przez nas środków ochrony Twoich danych osobowych, udostępnianie informacji za pośrednictwem Internetu lub publicznie dostępnych sieci nigdy nie jest całkowicie bezpieczne i zawsze istnieje ryzyko, że dostęp do Twoich danych osobowych uzyskają nieuprawnione do tego osoby trzecie. Dlatego szczególny nacisk należy położyć na to, by każdy Użytkownik w sposób odpowiedni sam również dbał o bezpieczeństwo własnych danych.
        <br />
        <br />
      </li>

      <li>
        <b>Organy Nadzorcze</b>
        <br />
        <br />
        W sprawach związanych z ochroną danych osobowych możesz skontaktować się z Prezesem Urzędu Ochrony Danych Osobowych: Stawki 2, 00-193 Warszawa.
      </li>
    </ol>

    <br />
    <br />
    <b>Polityka Cookies</b>
    <br />
    <br />
    Niniejszy System i strony internetowe diagnozy.diagmatic.pl oraz badania.diagmatic.pl wykorzystują pliki cookie.
    <br />
    <br />
    Plik cookie to mały plik tekstowy umieszczany na komputerze lub innym urządzeniu Użytkownika Systemu, który m.in. umożliwia prawidłowe działanie określonych funkcji Systemu. Używamy również plików cookie do zachowania danych wprowadzonych przez Użytkownika w ramach sesji, zapisania ustawień wybranych przez Użytkownika, a także do gromadzenia danych o korzystaniu z Systemu i danych statystycznych. Dane te pozwalają nam rozpoznać podstawowe parametry urządzenia i przeglądarki Użytkownika i odpowiednio wyświetlić stronę www na Twoim urządzeniu, a także na rozwój i optymalizację Systemu oraz zapewnienie jego bezpieczeństwa i stabilności funkcjonowania.
    <br />
    <br />
    Dane zgromadzone za pośrednictwem plików cookie umieszczonych za pomocą naszego Systemu oraz za pomocą innych podobnych mechanizmów są wykorzystywanie do celów własnych.
    <br />
    <br />
    Stosowane przez nas pliki cookie mogą być usuwane automatycznie w momencie zamknięcia przeglądarki internetowej przez Użytkownika (tzw. „sesyjne pliki cookie”) lub przechowywane na komputerze lub innym urządzeniu Użytkownika w celu usprawnienia przyszłych wizyt w Systemie (tzw. „trwałe pliki cookie”). Trwałe pliki cookie są automatycznie usuwane po upływie określonego czasu lub po usunięciu ich manualnie przez Ciebie (np. za pomocą ustawień przeglądarki).
    <br />
    <br />

    <b>Jak uniknąć plików cookie?</b>
    <br />
    <br />
    Stosowane przez nas cookies mają na celu przechowywanie danych w sesji dla zalogowanego Użytkownika, co wymagane jest w szczególności w związku z zastosowanymi metodami zabezpieczania systemu przed nieautoryzowanym dostępem. Możesz zablokować wykorzystywanie plików cookie zmieniając ustawienia przeglądarki internetowej w taki sposób, aby automatycznie odrzucała umieszczanie plików cookie lub informowała o każdym przypadku, kiedy strona internetowa żąda zapisania pliku cookie. Możesz również usunąć zapisane wcześniej pliki cookie przy pomocy przeglądarki internetowej lub funkcji zapewnianych przez system operacyjny Twojego urządzenia. Pamiętaj jednak, że z uwagi na cele, w jakich stosujemy cookies, zablokowanie plików cookie może mieć negatywny wpływ na funkcjonowanie Systemu lub nawet całkowicie uniemożliwić zalogowanie się do Systemu lub korzystanie z niektórych jego funkcji.
    <br />
    <br />
    Większość przeglądarek internetowych umożliwia pewną kontrolę nad stosowaniem plików cookies poprzez ustawienia przeglądarki. Instrukcje zarządzania plikami cookies różnią się w zależności od używanej przeglądarki. Na podanych poniżej stronach można uzyskać dodatkowe informacje – opisujące możliwości konfiguracji plików cookie w przypadku najpopularniejszych przeglądarek:
    <br />
    <br />
    <div className="browserConfigInstructions">
      Google Chrome –
      {' '}
      <a href="https://support.google.com/chrome/answer/95647?Every=GENIE.Platform%3DDesktop&hl=pl" target="_blank" rel="noreferrer">https://support.google.com/chrome/answer/95647?Every=GENIE.Platform%3DDesktop&hl=pl</a>
      <br />
      <br />
      Firefox -
      {' '}
      <a href="https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop" target="_blank" rel="noreferrer">https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop</a>
      <br />
      <br />
      Microsoft Edge -
      {' '}
      <a href="https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noreferrer">https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09</a>
      <br />
      <br />
      Safari dla macOS -
      {' '}
      <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank" rel="noreferrer">https://support.apple.com/en-gb/guide/safari/sfri11471/mac</a>
    </div>
    <br />
    <br />
    Użytkownik może także ustawić swoją przeglądarkę w taki sposób, aby konieczne było każdorazowe, samodzielne akceptowanie przez niego plików cookies. W takim wypadku przeglądarka za każdym razem zwróci się do użytkownika z pytaniem o wyrażenie przez niego zgody na przyznanie dostępu plikom cookies. Daje to użytkownikowi kontrolę nad tym, co znajduje się na jego urządzeniu, jednak ma tę wadę, że spowalnia możliwość poruszania się po Stronie Internetowej i innych witrynach internetowych.
  </>
);
