import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    placeholderText: {
      fontSize: 18,
    },
    uploadText: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    cloudIcon: {
      marginRight: theme.spacing(1.375),
      marginBottom: theme.spacing(-0.75),
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 112,
      borderRadius: 8,
      border: `1px dashed ${theme.palette.grey.border}`,
    },
    dropdownPlaceholder: {
      margin: '0 auto',
      verticalAlign: 'middle',
    },
  }),
);
