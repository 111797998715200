export const LABEL_LINE_HEIGHT = 16;

export default () => ({
  nowrap: {
    whiteSpace: 'nowrap',
  },
  alignBottom: {
    position: 'absolute',
    bottom: 32,
    top: 'initial',
  },
  bottomWithError: {
    bottom: 54,
  },
  incorrectValueLabel: {
    bottom: 'initial',
  },
  incorrectValueLabelTwoLines: {
    top: `-${LABEL_LINE_HEIGHT}px`,
  },
  labelOffset: {
    transform: 'translate(32px, 24px)',
  },
  labelStatic: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
});
