import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-scrollbars-custom';
import _get from 'lodash/get';
import { useTheme } from '@material-ui/core';

const Scrollbar = ({
  children,
  noScrollY,
  noScrollX,
  translateContentSizeYToHolder,
  translateContentSizeXToHolder,
  className,
  variant,
  onContentWidthChange,
}) => {
  const theme = useTheme();
  const [contentNode, setContentNode] = useState();

  const isMenu = variant === 'menu';

  const thumbProps = {
    style: {
      background: isMenu ? theme.palette.deepBlue[60] : theme.palette.grey.divider,
    },
  };

  return (
    <Scrollbars
      noScrollY={noScrollY}
      noScrollX={noScrollX}
      translateContentSizeYToHolder={translateContentSizeYToHolder}
      translateContentSizeXToHolder={translateContentSizeXToHolder}
      elementRef={(scrollbarNode) => {
        if (scrollbarNode) {
          setContentNode(scrollbarNode.querySelector('.ScrollbarsCustom-Content'));
        }
      }}
      className={className}
      trackXProps={{
        style: {
          height: isMenu ? 6 : 8,
          width: '100%',
          borderRadius: isMenu ? 6 : 3,
          left: 0,
          bottom: 0,
          background: isMenu ? 'transparent' : theme.palette.grey.background,
        },
      }}
      thumbXProps={thumbProps}
      trackYProps={{
        style: {
          width: isMenu ? 6 : 8,
          height: '100% ',
          top: 0,
          right: isMenu ? 2 : -15,
          borderRadius: isMenu ? 6 : 3,
          background: isMenu ? 'transparent' : theme.palette.grey.background,
        },
      }}
      thumbYProps={thumbProps}
      contentProps={{
        style: {
          display: 'block',
        },
      }}
      onUpdate={() => {
        onContentWidthChange(_get(contentNode, 'offsetWidth'));
      }}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noScrollX: PropTypes.bool,
  noScrollY: PropTypes.bool,
  translateContentSizeXToHolder: PropTypes.bool,
  translateContentSizeYToHolder: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'menu']),
  onContentWidthChange: PropTypes.func,
};

Scrollbar.defaultProps = {
  noScrollY: false,
  noScrollX: false,
  translateContentSizeYToHolder: false,
  translateContentSizeXToHolder: false,
  variant: 'default',
  className: '',
  onContentWidthChange: () => {},
};

export default Scrollbar;
