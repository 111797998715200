/* eslint-disable max-len */
import React from 'react';

export default () => (
  <>
    <h6><b>Regulamin świadczenia Usług za pomocą Systemu Diagmatic</b></h6>
    <br />
    Podmiotem świadczącym Usługi za pomocą Systemu jest
    {' '}
    <b>Diagmatic Sp. z o.o.</b>
    {' '}
    z siedzibą w Krakowie, przy ul. Bydgoskiej 6, Kraków 30-056, wpisaną do Rejestru przez Sąd Rejonowy dla Krakowa-Śródmieścia w Krakowie, XI Wydział Gospodarczy pod numerem 0000767866, posiadającą NIP 6762560717 i REGON 382377817, o kapitale zakładowym w wysokości 6 000,00 złotych.
    <br />
    <br />
    We wszystkich sprawach możesz skontaktować się z Diagmatic:
    <ul>
      <li>pocztą – na wskazany powyżej adres;</li>
      <li>mailowo – biuro@diagmatic.pl</li>
    </ul>
    <ol className="paragraphList">
      <li>
        <b>Zakres i definicje</b>
        <br />
        <br />
        <ol>
          <li>
            Regulamin określa zasady korzystania przez każdego z Użytkowników z Systemu Diagmatic działającego w formule SaaS (Software as a Service). System stanowi narzędzie wsparcia diagnozy psychopedagogicznej, wykonywanej w ramach świadczenia przez Użytkowników działalności profesjonalnej. Użytkownik korzystając z Systemu akceptuje w pełni i zobowiązuje się przestrzegać wszystkich postanowień zawartych w niniejszym Regulaminie, wraz ze zmianami Regulaminu wprowadzanymi w trybie przewidzianym w niniejszym Regulaminie.
          </li>
          <li>
            Regulamin stanowi również część Umowy zawieranej z Diagmatic, a jego postanowienia, w przypadku odwołania się do Regulaminu w takiej umowie, są wiążące dla wszystkich stron tej Umowy wraz ze zmianami Regulaminu wprowadzanymi w trybie przewidzianym w niniejszym Regulaminie.
          </li>
          <li>
            Poniżej wskazane pojęcia rozumieć należy zgodnie z podanymi definicjami:
            <ol className="alphabetList">
              <li>
                <b>Diagmatic</b>
                {' '}
                lub
                {' '}
                <b>My</b>
                {' '}
                – oznacza spółkę Diagmatic Sp. z o.o. z siedzibą w Krakowie, przy ul. Bydgoskiej 6, Kraków 30-056, KRS: 0000767866.
              </li>
              <li>
                <b>Konto</b>
                {' '}
                – profil zakładany i przypisany do konkretnego Użytkownika, z którego korzystać może Użytkownik po dokonaniu Logowania, aby uzyskać dostęp do funkcjonalności Systemu.
              </li>
              <li>
                <b>Logowanie</b>
                {' '}
                – czynność polegająca na wprowadzeniu danych identyfikacyjnych, pozwalająca na identyfikację Użytkownika i udzielenie mu dostępu do funkcjonalności Systemu.
              </li>
              <li>
                <b>Materiały</b>
                {' '}
                – wszelkie materiały, w dowolnej formie, do jakich udzielony zostać może dostęp Użytkownikowi w ramach Systemu, to jest w szczególności kwestionariusze, podręczniki, opisy, skrypty i algorytmy, które w oparciu o uzupełnione kwestionariusze przygotowywać będą opis osoby diagnozowanej przy wsparciu Systemu, a także przygotowane opisy.
              </li>
              <li>
                <b>Polityka Prywatności</b>
                {' '}
                – dokument informujący o sposobie przetwarzania danych osobowych umieszczony w osobnej zakładce Systemu.
              </li>
              <li>
                <b>Regulamin</b>
                {' '}
                – niniejszy dokument.
              </li>
              <li>
                <b>Rejestracja</b>
                {' '}
                – czynność polegająca na podaniu danych Użytkownika i wyrażeniu zgody na związanie Regulaminem i Polityką Prywatności celem rozpoczęcia korzystania z Systemu, czego skutkiem jest założenie Konta Użytkownika.
              </li>
              <li>
                <b>Sprzęt</b>
                {' '}
                – urządzenia elektroniczne, jakie udostępniać może Diagmatic w ramach Umowy i na zasadach uregulowanych w Umowie i Regulaminie.
              </li>
              <li>
                <b>System</b>
                {' '}
                – oznacza aplikację webową oraz powiązane z nią elementy niezbędne do jej prawidłowego funkcjonowania i dostępne za pomocą strony internetowej diagnozy.diagmatic.pl oraz badania.diagmatic.pl, za pośrednictwem której oferujemy Nasze Usługi.
              </li>
              <li>
                <b>Umowa</b>
                {' '}
                – oznacza osobną umowę zawartą z Diagmatic, która określa warunki handlowe dostępu do Systemu dla Użytkowników. Umowa oznaczać będzie zarówno umowę na korzystanie z Systemu w okresie próbnym („Try&amp;Buy”) jak i umowę na odpłatne korzystanie z Systemu – w zależności od aktualnie obowiązującej umowy z Diagmatic.
              </li>
              <li>
                <b>Usługi</b>
                {' '}
                – oznaczają każdą usługę, treść, funkcjonalność, technologię lub funkcję, które oferujemy za pomocą Systemu.
              </li>
              <li>
                <b>Użytkownik</b>
                {' '}
                – oznacza każdego użytkownika uprawnionego do korzystania z Systemu.
              </li>
              <li>
                <b>Zasoby</b>
                {' '}
                – oznaczają udostępniane przez Diagmatic wraz z dostępem do Systemu zasoby takie jak przestrzeń dyskowa do przechowywania danych (w tym w ramach chmury obliczeniowej) oraz moc obliczeniowa do przetwarzania danych za pomocą chmury obliczeniowej.
              </li>
            </ol>
          </li>

        </ol>
      </li>
      <br />
      <li>
        <b>Postanowienia ogólne i Nasze zobowiązania</b>
        <br />
        <br />
        <ol>
          <li>Diagmatic posiada prawa do Systemu oraz zawartych w nim Materiałów w zakresie niezbędnym do świadczenia Usług zgodnie z niniejszym Regulaminem oraz innymi Umowami zawieranymi w zakresie korzystania z Systemu.</li>
          <li>Korzystanie przez Użytkowników z Systemu w zakresie zgodnym z niniejszym Regulaminem nie będzie naruszać żadnych praw osób trzecich.</li>
          <li>
            Diagmatic zobowiązuje się do dochowania należytej staranności w celu:
            <ol className="alphabetList">
              <li>zapewnienia Użytkownikom możliwości dostępu do Systemu oraz korzystania z jego funkcjonalności w wymiarze wynikającym z zawartych Umów,</li>
              <li>utrzymywania Systemu w stanie umożliwiającym korzystanie z niego w celu przewidzianym w niniejszym Regulaminie oraz Umowie.</li>
            </ol>
          </li>
          <li>
            Korzystanie Systemu wymaga posiadania urządzeń elektronicznych: komputera (mającego możliwość podłączenia do Internetu oraz jednej z wymienionych przeglądarek internetowych, w wersji aktualnej na dzień rozpoczęcia pracy w Systemie:
            <ol className="alphabetList">
              <li>Google Chrome,</li>
              <li>Mozilla Firefox,</li>
              <li>Microsoft Edge,</li>
              <li>Safari dla macOS.</li>
            </ol>
            a także tabletu z przeglądarką Google Chrome.
          </li>
        </ol>
      </li>
      <br />

      <li>
        <b>Zasady korzystania z Systemu</b>
        <br />
        <br />
        <ol>
          <li>
            Użytkownik będzie wykorzystywał System w celu wsparcia działalności diagnostycznej. Użytkownik ma świadomość, że System nie jest samodzielnym narzędziem diagnostycznym i nie może być stosowany zamiennie z diagnostyką prowadzoną przez osobę posiadającą stosowne uprawnienia w tym kierunku.
          </li>
          <li>
            Licencjobiorca uzyska dostęp do Zasobów udostępnianych wraz z Systemem przez Diagmatic.
          </li>
          <li>
            Diagmatic w celu realizacji Usług może korzystać z usług wybranych przez siebie podwykonawców (w tym w zakresie usług chmury obliczeniowej).
          </li>
          <li>
            Diagmatic może udzielać dostępu do Systemu Użytkownikom za pomocą Kont o różnym poziomie dostępu do Systemu, w tym:
            <ol className="alphabetList">
              <li>
                Konta Koordynatora – Konta, którego Użytkownik ma pełny dostęp do funkcjonalności Systemu, jak też możliwość zakładania Kont Diagnosty;
              </li>
              <li>
                Konta Diagnosty – Konta z ograniczonymi uprawnieniami do korzystania z Systemu (w ramach funkcjonalności służących do przeprowadzania testów i wglądu w ich wyniki, a także dostępu do danych osób, które są poddawane diagnozie z pomocą Systemu).
              </li>
            </ol>
          </li>
          <li>
            Konto Koordynatora może być założone w Systemie przez Diagmatic dla osoby wskazanej przez podmiot, z którym Diagmatic zawarł Umowę. Wskazanie osoby mającej posiadać Konto Koordynatora (a także zmiana tej osoby) odbyć może się jednostronnie przez ten podmiot za pomocą kanałów komunikacji uzgodnionych w Umowie.
          </li>
          <li>
            W celu zapewnienia możliwie najwyższego stopnia bezpieczeństwa podczas korzystania z Systemu obowiązuje stosowanie następujących zasad:
            <ol className="alphabetList">
              <li>
                Stworzone Konto wraz z loginem i hasłem służą wyłącznie celom pracy jednego Użytkownika. Przekazywanie tych danych osobom postronnym jest zabronione,
              </li>
              <li>
                Dostęp Użytkowników do Systemu zabezpieczony jest hasłem,
              </li>
              <li>
                Hasło musi być zbudowane w sposób zgodny z wymaganiami określonymi przez System podczas aktywacji Konta,
              </li>
              <li>
                Hasło należy zapamiętać i nie zapisywać go w miejscu niezabezpieczonym (przeglądarce internetowej, na pojedynczych kartkach lub w podręcznym notesie, czy kalendarzu, w niezaszyfrowanym pliku na komputerze/dysku). Zaleca się korzystanie z menadżera haseł,
              </li>
              <li>
                Hasło należy regularnie zmieniać – nie rzadziej niż raz na pół roku,
              </li>
              <li>
                W przypadku podejrzenia wejścia w posiadanie hasła przez osobę inną niż Użytkownik (w tym jego skradzenia), należy niezwłocznie:
                <ol className="romanNumbersList">
                  <li>
                    zmienić obecne hasło na nowe utworzone zgodnie z powyżej opisanymi zasadami,
                  </li>
                  <li>
                    sprawdzić ostatnie dane logowania (widoczne w dymku &quot;Informacje o systemie&quot; w menu głównym), to jest: datę oraz godziną poprzedniego logowania oraz podjąć próbę ustalenia kto, gdzie i kiedy mógł mieć dostęp do danych logowania danego Użytkownika, aby móc wykluczyć wystąpienie kolejnych takich sytuacji.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Użytkownik korzystający z Systemu oświadcza i zobowiązuje się:
            <ol className="alphabetList">
              <li>że jest osobą, która ma co najmniej 18 lat i posiada pełną zdolność do czynności prawnych,</li>
              <li>że poda tylko prawdziwe, dokładne i aktualne informacje, tj. w szczególności, że dane dostarczone przez Użytkownika w celu utworzenia Konta należą do niego. Używanie fałszywej tożsamości lub tożsamości innej osoby bez podstawy prawnej jest surowo zabronione,</li>
              <li>do korzystania z Systemu w sposób zgodny z obowiązującym prawem oraz niniejszym Regulaminem,</li>
              <li>do korzystania z Systemu w sposób zgodny z zasadami współżycia społecznego, w tym ogólnymi zasadami etykiety przy korzystaniu z sieci Internet (w tym do nieprzechowywania lub rozpowszechniania jakichkolwiek materiałów niezgodnych z prawem, szkodliwych, groźnych, zniesławiających, obscenicznych, naruszających prawa osób trzecich, nękających, dyskryminujących m.in. na podstawie rasy, płci, wyznania, orientacji seksualnej, niepełnosprawność, ani też destrukcyjnego, złośliwego lub w inny sposób szkodliwego oprogramowania lub kodu, w tym wirusów),</li>
              <li>do korzystania z Systemu w sposób nieuciążliwy dla innych Użytkowników oraz operatora usługi dostępu do sieci Internet oraz z poszanowaniem dóbr osobistych osób trzecich (w tym prawa do prywatności) i wszelkich innych przysługującym im praw oraz wolności,</li>
              <li>uwolnić Diagmatic od jakiejkolwiek odpowiedzialności w stosunku do osób, których prawa zostaną naruszone w związku z korzystaniem przez Użytkownika z Systemu w sposób naruszający Regulamin.</li>
            </ol>
          </li>
          <li>Użytkownik zobowiązany jest do korzystania z wszelkich Materiałów i Zasobów udostępnionych za pośrednictwem Systemu jedynie w zakresie dozwolonym przez Regulamin lub właściwe przepisy, w tym przepisy o dozwolonym użytku.</li>
          <li>Użytkownik jest zobowiązany niezwłocznie powiadomić Diagmatic o każdym przypadku naruszenia praw w związku z korzystaniem z Systemu. Diagmatic nie ma obowiązku monitorowania aktywności Użytkowników ani nie będzie próbował tego robić regularnie. W związku z tym Diagmatic nie ponosi odpowiedzialności za jakiekolwiek szkody lub straty wynikające z takiej aktywności, zobowiązuje się jednak reagować na otrzymywane informacje dotyczące naruszenia praw w związku z korzystaniem z Systemu.</li>
          <li>W przypadku stwierdzenia, że Użytkownik dopuszcza się działań zabronionych prawem lub Regulaminem, lub godzących w usprawiedliwiony interes Diagmatic lub innych Użytkowników lub podmiotów, z którymi Diagmatic zawarł Umowę, Diagmatic może podjąć stosowne działania, w tym ograniczyć możliwość korzystania przez Użytkownika z Systemu. W szczególnych sytuacjach, jeżeli mimo wezwania skierowanego do podmiotu, z którym zawarto Umowę, Użytkownik nadal będzie podejmował działania wskazane w zdaniu pierwszym, Diagmatic może skorzystać z prawa do rozwiązania Umowy w trybie natychmiastowym.</li>
          <li>Diagmatic zastrzega sobie prawo do wprowadzenia metod potwierdzenia tożsamości Użytkowników niezbędnych do dokonania rejestracji lub Logowania, w szczególności, gdy zaistnieje podejrzenie, że do Konta danego Użytkownika nastąpiło Logowanie przez osobę nieuprawnioną, a także do zawieszenia możliwości korzystania z Systemu do czasu uzyskania potwierdzenia tożsamości w sposób wybrany przez Diagmatic. </li>
        </ol>
      </li>
      <br />
      <li>
        <b>Licencja</b>
        <br />
        <br />
        <ol>
          <li>
            Diagmatic udziela podmiotowi, z którym zawarta zostaje Umowa, nieograniczonej terytorialnie licencji niewyłącznej do korzystania z Systemu przez czas trwania tej Umowy (dalej „Licencja”). Licencja zostaje udzielona na następujących polach eksploatacji:
            <ol className="alphabetList">
              <li>wprowadzanie do pamięci dowolnej liczby komputerów, urządzeń mobilnych typu telefon, tablet lub innych urządzeń posiadających pamięć elektroniczną oraz urządzeń korzystających z tzw. pamięci wirtualnej lub udostępnianych Zasobów w celu korzystania z Systemu na danym urządzeniu;</li>
              <li>wykorzystywanie fragmentów Systemu w innych utworach w postaci niestanowiącej opracowania, w tym utworach audio-wizualnych lub multimedialnych w celach wewnętrznych, w tym do informowania o korzystaniu z Systemu oraz przeprowadzania szkoleń wśród swojego personelu;</li>
              <li>korzystanie z Systemu zgodnie z jego celem lub przeznaczeniem, w tym wprowadzanie i dalsze przetwarzanie danych za pomocą Systemu, w zakresie koniecznym do realizacji Umowy.</li>
            </ol>
          </li>
          <li>Podmiot, z którym zawarto Umowę oraz każdy z Użytkowników zobowiązuje się używać Systemu wyłącznie do realizacji przedmiotu Umowy. W szczególności zobowiązują się oni nie wykorzystywać Systemu w zakresie wykraczającym poza wskazany w tej Umowie, w tym dokonywać inżynierii odwrotnej i innych czynności w celu poznania kodu części Systemu stanowiących program komputerowy lub jakichkolwiek jego części oraz nie zlecać wykonywania takich czynności osobom trzecim – poza zakresem wyraźnie dopuszczonym przez przepisy powszechnie obowiązującego prawa, w tym art. 75 ustawy o prawie autorskim i prawach pokrewnych.</li>
          <li>Okres obowiązywania Licencji określony został w treści Umowy. Zakończenie Umowy oznacza jednocześnie wygaśnięcie Licencji. W przypadku przedłużenia obowiązywania Umowy odpowiedniemu przedłużeniu ulega również Licencja.</li>
          <li>Prawo do udzielenia sublicencji do Systemu jak również udzielania jakichkolwiek dalszych praw do Systemu lub jego części jest wyłączone, za wyjątkiem prawa do udostępniania Systemu przez podmiot, który zawarł Umowę z Diagmatic swoim pracownikom i współpracownikom – stającym się Użytkownikami Systemu.</li>
          <li>
            Razem z Licencją, na zasadach opisanych powyżej, udzielane jest również prawo do korzystania z pozostałych elementów niezbędnych w celu skorzystania z Systemu, to jest określonych w Umowie Materiałów oraz Zasobów. Prawo to udzielone zostaje:
            <ol className="alphabetList">
              <li>w zakresie baterii kwestionariuszy, skryptów i algorytmów – do wykorzystywania ich przez Użytkowników wyłącznie w ramach funkcjonalności Systemu, to jest do uzupełniania kwestionariuszy z pomocą Systemu przez osoby, których diagnostyka wykonywana będzie przy wsparciu Systemu, a także do uruchamiania funkcji w Systemie, które generować będą wynik i opis w oparciu o odpowiedzi udzielane przez ww. osoby,</li>
              <li>w zakresie podręczników – do ich utrwalania i zwielokrotniania w postaci zapisu w formie elektronicznej lub w postaci wydruku wyłącznie w celu użyczenia ich pracownikom lub współpracownikom podmiotu, z którym zawarta została Umowa, którzy korzystać będą z Systemu jako Użytkownicy upoważnieni przez ten podmiot.</li>
            </ol>
          </li>
        </ol>
        <br />
      </li>
      <li>
        <b>Dostępność Systemu, wsparcie i usługi serwisowe</b>
        <br />
        <br />
        <ol>
          <li>
            W ramach Usług Diagmatic zobowiązuje się do:
            <ol className="alphabetList">
              <li>telefonicznego i mailowego wsparcia Użytkowników,</li>
              <li>świadczenia usług serwisowych,</li>
              <li>udzielania dostępu do aktualizacji funkcjonalności Systemu.</li>
            </ol>
          </li>
          <li>Świadczenie usług serwisowych oraz wsparcia odbywać będzie się wyłącznie w dni od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od pracy (dalej „Dni Robocze”) w godzinach od 9.00 do 17.00 (dalej „Godziny Robocze”). Przewidziane poniżej Czas Reakcji oraz Czas naprawy liczone będą w trakcie Godzin Roboczych.</li>
          <li>Świadczenie usług serwisowych polega na usuwaniu błędów na podstawie złożonych zleceń serwisowych, które należy zgłaszać w Systemie poprzez zakładkę „Kontakt”, lub bezpośrednio na adres serwis@diagmatic.pl. Usunięcie błędów następować będzie w ramach wynagrodzenia przewidzianego w Umowie.</li>
          <li>
            Za błąd na potrzeby niniejszego Regulaminu uznaje się:
            <ol className="alphabetList">
              <li>błąd powodujący brak możliwości zalogowania się do Systemu oraz błąd uniemożliwiający przeprowadzenie badania („Błąd Blokujący”),</li>
              <li>inne błędy, w tym nieprawidłowe wyświetlanie się Systemu nie uniemożliwiające jednak korzystania z niego („Pozostałe Błędy”).</li>
            </ol>
          </li>
          <li>
            Dla poszczególnych kategorii Błędów Diagmatic dołoży należytych starań by zachować następujący poziom realizacji zleceń serwisowych:
            <br />
            <table>
              <tbody>
                <tr>
                  <th><b>Typ błędu</b></th>
                  <th><b>Czas reakcji</b></th>
                  <th><b>Czas naprawy</b></th>
                </tr>
                <tr>
                  <td><b>Błąd blokujący</b></td>
                  <td>6 Godzin Roboczych</td>
                  <td>14 Godzin Roboczych</td>
                </tr>
                <tr>
                  <td><b>Pozostałe Błędy</b></td>
                  <td>2 Dni Robocze</td>
                  <td>5 Dni roboczych</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>W ramach świadczenia Usług Diagmatic zobowiązany jest również do zapewnienia działania usług świadczonych przez zewnętrznych dostawców umożliwiających prawidłowe działanie Systemu - na poziomie gwarantowanym przez dostawców tych usług w umowach z nimi zawartych przez Diagmatic.</li>
          <li>Wsparcie Użytkowników obejmuje możliwość zgłaszania zapytań na adres poczty elektronicznej Licencjodawcy: [serwis@diagmatic.pl] oraz na numer telefonu Licencjodawcy (+48) 505 901 560. Licencjodawca dołoży starań, by dostarczyć odpowiedź na zapytanie w przeciągu 2 Dni Roboczych od momentu zgłoszenia.</li>
          <li>Diagmatic zapewni również możliwość korzystania z aktualizacji funkcjonalności Systemu, jeżeli takie dokonane zostaną w trakcie trwania Umowy o czym decyduje Diagmatic.</li>
        </ol>
      </li>
      <br />
      <li>
        <b>Poufność</b>
        <br />
        <br />
        <ol>
          <li>Wszelkie informacje, w których posiadanie wejdzie którakolwiek ze stron w związku z wykonywaniem zobowiązań wynikających z Umowy lub korzystaniem z Systemu, niezależnie od ich rodzaju i formy, w tym fakt zawarcia oraz treść Umowy, a także know-how strony Umowy oraz Użytkownicy traktują jako poufne (dalej „Informacje Poufne”). W szczególności poufne będą informacje stanowiące tajemnicę przedsiębiorstwa w rozumieniu ustawy z dnia 16 kwietnia 1993 r. o zwalczaniu nieuczciwej konkurencji. Ponadto każda ze stron zobowiązuje się używać powyższych informacji tylko i wyłącznie do celów wykonania swoich zobowiązań wynikających z Umowy, a Użytkownicy tylko do celu korzystania z Systemu.</li>
          <li>Pojęciem Informacje Poufne objęte są także wszystkie informacje, dokumenty, dane i notyfikacje bezpośrednio i pośrednio związane z realizacją Umowy a także wszelkie informacje przekazywane chociażby wyłącznie w formie ustnej w trakcie jakichkolwiek spotkań stron Umowy oraz z osobami trzecimi współpracującymi z tym stronami przy realizacji przedmiotu Umowy.</li>
          <li>
            Nie stanowią Informacji Poufnych:
            <ol className="alphabetList">
              <li>dane powszechnie znane lub łatwo dostępne, a także inne, o których osoby trzecia mogą się łatwo w sposób legalny dowiedzieć;</li>
              <li>dane, które zostały przez strony wyraźnie i na piśmie lub w formie dokumentowej wyłączone z zakresu Informacji Poufnych;</li>
              <li>dane, które zostały niezależnie opracowane przez stronę Umowy lub uzyskane przez tą stronę niezależnie od uzyskania ich od drugiej strony i w sposób nie naruszający przepisów lub postanowień tej Umowy i Regulaminu.</li>
            </ol>
          </li>
          <li>Jakiekolwiek ujawnienie lub przekazanie osobom trzecim informacji, o których mowa w ustępach powyższych, użycie ich do innych celów, niż wynikający z ustępów powyższych oraz skopiowanie ich bez zgody przez stronę Umowy lub Użytkownika traktowane jest jako naruszenie postanowień Umowy oraz Regulaminu powodujące powstanie po stronie osoby naruszającej obowiązku naprawienia wszelkich szkód i kosztów poniesionych w związku z tym przez drugą stronę. Postanowienia zdania poprzedzającego nie stosuje się, jeżeli ujawnienie lub przekazanie informacji następuje w wykonaniu obowiązku wynikającego z powszechnie obowiązujących na terytorium Rzeczypospolitej Polskiej przepisów prawa pod warunkiem, że ujawnienie lub przekazanie nastąpi wyłącznie przed powołanym do tego organem lub sądem, na jego prawomocne lub ostateczne żądanie i w jego granicach. Strona ujawniająca Informacje Poufne w takiej sytuacji poinformuje drugą stronę Umowy (o ile nie będzie to zakazane mocą przepisów lub orzeczeniem właściwego organu lub sądu) o jej wystąpieniu w terminie 7 dni od dnia otrzymania wezwania do złożenia Informacji Poufnych.</li>
          <li>Zobowiązania wynikające z paragrafu niniejszego pozostają w mocy przez okres obowiązywania Umowy między stronami oraz przez 5 lat od dnia, w którym Umowa przestała wiązać lub Użytkownik zakończył korzystanie z Systemu.</li>
        </ol>
      </li>
      <br />
      <li>
        <b>Postanowienia Końcowe</b>
        <br />
        <br />
        <ol>
          <li>W sprawach nieuregulowanych niniejszym Regulaminem, jego wykładni oraz do wszelkich sporów zastosowanie mają odpowiednie przepisy prawa polskiego.</li>
          <li>Regulamin w aktualnym brzmieniu obowiązuje od 15 marca 2021 r.</li>
          <li>Zmiana Regulaminu nastąpić może poprzez ogłoszenie nowej treści jednolitej lub zmienionych bądź dodanych treści poprzez notyfikację w Systemie lub przez wiadomość wysłaną na adres e-mail Użytkownika podany w trakcie korzystania z Systemu i dostępny w jego Koncie, a w przypadku podmiotów, z którymi Diagmatic zawarł Umowę – na adres e-mail wskazany w Umowie. Brak rezygnacji przez podmiot, z którym Diagmatic zawarł Umowę lub przez Użytkownika z dalszego korzystania z Systemu mimo zawiadomienia o zmianie będzie oznaczać akceptację Regulaminu. Diagmatic będzie informować o zmianach z co najmniej 15-dniowym wyprzedzeniem, chyba że wcześniejsze wejście w życie zmiany będzie wymagane okolicznościami, np. zmianą obowiązujących przepisów prawa.</li>
          <li>Zmiany Regulaminu obowiązują z upływem terminu wskazanego przez Diagmatic w zawiadomieniu o zmianie i nie mają mocy wstecznej.</li>
        </ol>
      </li>
    </ol>
    <br />
    <br />
  </>
);
