import { defineMessages } from 'react-intl';

export default defineMessages({
  users: 'Użytkownicy',
  usersCatalog: 'Katalog użytkowników',
  surveyCreator: 'Kreator ankiet',
  surveys: 'Ankiety',
  organizations: 'Katalog placówek',
  questions: 'Pytania',
  profile: 'Profil',
  answers: 'Odpowiedzi',
  batteries: 'Baterie',
  surveysResults: 'Wyniki badań',
  surveyResults: 'Wyniki badania',
  surveyAnalysis: 'Analiza testu',
  previewSurvey: 'Podgląd ankiety',
  researchTools: 'Narzędzia badawcze',
  tools: 'Narzędzia',
  diagnosticianTools: 'Narzędzia diagnostyczne',
  scales: 'Skale',
  scaleView: 'Podgląd - kod wymiaru: {code}',
  scaleEdit: 'Edycja - kod wymiaru: {code}',
  standardScales: 'Skale kategorialne',
  referenceGroupSets: 'Grupy odniesienia',
  facility: 'Placówka',
  replacement: 'Zastępstwo',
  profileEdit: 'Edycja',
  patientRegister: 'Rejestracja klienta',
  patientEdit: 'Edycja',
  patientView: 'Klienci',
  researchStart: 'Uruchomienie badania',
  diagnosticianAdd: 'Rejestracja konta diagnosty',
  diagnosticianEdit: 'Edycja danych diagnosty',
  evaluationAddEdit: 'Edycja kombinacji wyników',
  examinationDetails: 'Wybrane badanie',
  clientFiles: 'Kartoteka klientów',
  systemProfile: 'Profil systemowy',
  tutorialVideos: 'Filmy instruktażowe',
  file: 'Kartoteka: {name}',
  diagnosticians: 'Diagności',
  diagnosticianProfile: 'Profil diagnosty',
  usageReport: 'Raport użyć narzędzi diagnostycznych',
  userAdd: 'Dodawanie użytkownika',
  userEdit: 'Edycja profilu użytkownika',
  userView: 'Podgląd profilu użytkownika',
  subscriptionAdd: 'Dodawanie abonamentu',
  surveyAdd: 'Dodawanie ankiety',
  surveyEdit: 'Edycja ankiety',
  surveyView: 'Podgląd ankiety',
  answerSetAdd: 'Dodawanie zestawu odpowiedzi',
  answerSetEdit: 'Edycja zestawu odpowiedzi',
  questionAdd: 'Dodawanie pytania',
  questionEdit: 'Edycja pytania',
  schools: 'Szkoły',
  schoolAdd: 'Dodawanie szkoły',
  schoolEdit: 'Edycja szkoły',
  examinations: 'Badania',
  examinationView: 'Podgląd badania',
  participantEdit: 'Edycja danych uczestnika',
  standardScaleAdd: 'Dodawanie skali kategorialnej',
  standardScaleEdit: 'Edycja skali kategorialnej',
  referenceGroupView: 'Podgląd grupy odniesienia',
  referenceGroupAdd: 'Dodawanie grupy odniesienia',
  referenceGroupEdit: 'Edycja grupy odniesienia',
  packagesEdit: 'Edycja pakietów zakupowych',
  researchToolAdd: 'Dodawanie narzędzia badawczego',
  researchToolEdit: 'Edycja narzędzia badawczego',
  researchToolView: 'Podgląd narzędzia badawczego',
  batteryAdd: 'Dodawanie baterii',
  batteryEdit: 'Edycja baterii',
  videoTutorials: 'Filmy instruktażowe',
  videoTutorialAdd: 'Dodawanie filmu instruktażowego',
  videoTutorialEdit: 'Edycja filmu instruktażowego',
  videoTutorialView: 'Podgląd filmu instruktażowego',
  evaluationAdd: 'Dodawanie kombinacji wyników',
  evaluationEdit: 'Edycja kombinacji wyników',
  groupSurveyConfiguration: 'Konfiguracja badania grupowego',
  groupSurveys: 'Badania grupowe',
});
