import React, {
  useRef, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import StarsIcon from '@material-ui/icons/Stars';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import roles from 'utils/constants/rolesOfUsers';

import { ACTIVE_ID } from 'utils/constants/statuses';
import InactiveDiangostician from 'assets/img/inactiveDiangostician.svg';
import useStyle from './DiagnosticianName.styles';
import messages from './DiagnosticianName.messages';

const DiagnosticianName = ({ name, role, status }) => {
  const classes = useStyle();
  const intl = useIntl();
  const [textWidth, setTextWidth] = useState(null);
  const textElementRef = useRef(null);
  const isClinicCoordinator = role === roles.clinicCoordinator;

  useEffect(() => {
    if (textElementRef && textElementRef.current) {
      const width = textElementRef.current.offsetWidth;
      setTextWidth(width);
    }
  }, [textElementRef, name]);

  const isActive = useMemo(
    () => status === ACTIVE_ID,
    [status],
  );

  return (
    <div className={classNames(classes.wrapper, { [classes.maxWidth]: isClinicCoordinator })}>
      <Typography
        variant="body1"
        className={classes.name}
        component="span"
        ref={textElementRef}
      >
        {name}
      </Typography>
      {
        (isActive && isClinicCoordinator) ? (
          <Tooltip
            arrow
            title={intl.formatMessage(messages.clinicCoordinator)}
            placement="top"
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            <StarsIcon color="primary" className={classes.starIcon} style={{ left: textWidth + 12 }} />
          </Tooltip>
        ) : null
      }
      {
        !isActive && <img className={classes.inactiveIcon} src={InactiveDiangostician} alt="inactive diagnostician" />
      }
    </div>
  );
};

DiagnosticianName.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default DiagnosticianName;
