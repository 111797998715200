import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ChangeUserPasswordView from 'components/pages/ChangeUserPassword';
import {
  onSubmit,
  onCancel,
  loadPageData,
} from './store';

class ChangeUserPasswordContainer extends React.Component {
  static propTypes = {
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.loadPageData();
  }

  render() {
    return this.props.isLoadedPage ? (
      <ChangeUserPasswordView {...this.props} />
    ) : null;
  }
}

const mapDispatchToProps = {
  onSubmit,
  onCancel,
  loadPageData,
};

const mapStateToProps = (state) => ({
  ...state.ChangeUserPassword,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeUserPasswordContainer);
