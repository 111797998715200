import { defineMessages } from 'react-intl';
import _forEach from 'lodash/forEach';
import _isNil from 'lodash/isNil';
import _trim from 'lodash/trim';

import config from 'config';

const maxLength = config.maxLength.name;

const messages = defineMessages({
  cannotBeEmpty: 'Pole nie może być puste',
  fieldValueTooLong: 'Wartość pola jest zbyt długa',
});

export default (value) => {
  const result = {
    fields: {},
  };

  result.isValid = true;

  _forEach(value, (el, index) => {
    if (_isNil(el) || _trim(el).length === 0) {
      result.fields[`answer-${index}`] = messages.cannotBeEmpty;
      result.isValid = false;
    } else if (_isNil(el) || _trim(el).length > maxLength) {
      result.fields[`answer-${index}`] = messages.fieldValueTooLong;
      result.isValid = false;
    }
  });

  return result;
};
