import { push, replace } from 'connected-react-router';
import _first from 'lodash/first';

import _get from 'lodash/get';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _omit from 'lodash/omit';

import {
  STARTED,
  SUSPENDED,
  ENDED,
  LINK,
} from 'utils/constants/examinationStatuses';

import ApiManager from 'utils/ApiManager';
import { PURCHASE_PACKAGE_APPLICATION } from 'utils/constants/modalTypes';
import errorCatch from 'utils/errorCatch';
import getSortAndPageData from 'utils/getSortAndPageData';
import snackbarMessages from 'utils/snackbarMessages';
import dialogTexts from 'utils/dialogTexts';

import createURLWithQuery from 'utils/createURLWithQuery';
import getDateFromApi from 'utils/getDateFromApi';
import formatNotes from 'utils/formatNotes';
import getExaminationStatus from 'utils/getExaminationStatus';
import roles, { diagnosticianRoles } from 'utils/constants/rolesOfUsers';
import formatCodeWithName from 'utils/formatCodeWithName';

import {
  showLoaderMultipleRequests,
  hideLoaderMultipleRequests,
  setModal,
  setDrawerInvisibility,
  setPage,
  setRowsPerPage,
  showTransparentLoader,
  hideLoader,
  showSnackbar,
  openDialog,
} from 'containers/store';

import messages from './messages';
import { getIcons } from './utils';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    search: '',
  },
  items: [],
  patientID: null,
  isNoSubscriptionModalOpen: false,
  orderData: null,
  totalItemsCount: null,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'PATIENT_EXAMINATION/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'PATIENT_EXAMINATION/CLEAR_STORE',
  SET_PATIENT_NOTE: 'PATIENT_EXAMINATION/SET_PATIENT_NOTE',
  TOGGLE_NO_SUBSCRIPTION_MODAL: 'PATIENT_EXAMINATION/TOGGLE_NO_SUBSCRIPTION_MODAL',
  SET_ORDER_DATA: 'PATIENT_EXAMINATION/SET_ORDER_DATA',
  SET_EXAMINATIONS: 'PATIENT_EXAMINATION/SET_EXAMINATIONS',
};

const mapItems = (items, isDiagnosticianRole, unlimitedUsage) => items.map((item, key) => ({
  id: key + 1,
  visibleIcon: item.has_notes || item.has_status_notes,
  apiId: item.id,
  toolName: formatCodeWithName(item.session.survey_tool),
  examinationDate: item.updated_at ? getDateFromApi(item.updated_at) : '',
  status: getExaminationStatus(item),
  session: item.session,
  isCalculated: item.is_calculated,
  isDiagnosticianRole,
  hasEnoughTokensToCalculate: unlimitedUsage || item.clinic_has_enough_tokens_to_calculate,
  notes: formatNotes(item),
  icons: getIcons(item.has_undesirable_survey_evaluations, item.session_patient_group_id),
  isGroupExamination: item.session_patient_group_id !== null,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapItems(
          action.responses.data.items,
          action.isDiagnosticianRole,
          action.unlimitedUsage,
        ),
        totalItemsCount: action.responses.data.total,
        patientID: action.patientID,
      };
    }

    case actionTypes.SET_PATIENT_NOTE: {
      return {
        ...state,
        notesData: action.notesData,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.TOGGLE_NO_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        isNoSubscriptionModalOpen: action.isNoSubscriptionModalOpen,
      };
    }

    case actionTypes.SET_ORDER_DATA: {
      return {
        ...state,
        orderData: action.orderData,
      };
    }

    case actionTypes.SET_EXAMINATIONS: {
      return {
        ...state,
        items: mapItems(
          action.examinationsData.data.items,
          action.isDiagnosticianRole,
          action.unlimitedUsage,
        ),
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, patientID, isDiagnosticianRole, unlimitedUsage) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  patientID,
  isDiagnosticianRole,
  unlimitedUsage,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setPatientNote = (notesData) => ({
  type: actionTypes.SET_PATIENT_NOTE,
  notesData,
});

const toggleNoSubscriptionModal = (isNoSubscriptionModalOpen) => ({
  type: actionTypes.TOGGLE_NO_SUBSCRIPTION_MODAL,
  isNoSubscriptionModalOpen,
});

const setOrderData = (orderData) => ({
  type: actionTypes.SET_ORDER_DATA,
  orderData,
});

const setExaminations = (examinationsData, isDiagnosticianRole, unlimitedUsage) => ({
  type: actionTypes.SET_EXAMINATIONS,
  examinationsData,
  isDiagnosticianRole,
  unlimitedUsage,
});

export const onNoSubscriptionClose = () => (dispatch) => dispatch(
  toggleNoSubscriptionModal(false),
);

const loadPatientExamination = (params, id) => (dispatch, getStore) => {
  const { patientID } = getStore().PatientExamination;
  const routeState = getStore().router.location.state;

  const data = {
    perPage: params.perPage,
    page: params.page,
    search: `patient_id: ${id || patientID}`,
    filterByStatus: [STARTED, SUSPENDED, ENDED],
    orderBy: 'updated_at',
    sortedBy: 'desc',
    withHasUndesirableSurveyEvaluationsFlag: true,
  };

  const url = createURLWithQuery('session_patients', data);

  const pageUrl = createURLWithQuery('patientView', _omit(data, ['orderBy', 'sortedBy', 'search', 'filterByStatus']));

  dispatch(replace(pageUrl, routeState));

  return ApiManager.request('get', dispatch, url);
};

const getPatientExaminations = () => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore);

  try {
    const examinationsData = await dispatch(loadPatientExamination(data));
    const { role_id: roleId, clinics } = getStore().Global.userData;
    const isDiagnosticianRole = _includes(diagnosticianRoles, roleId);
    const unlimitedUsage = _get(_first(clinics), 'unlimited_usage', false) || false;

    dispatch(setExaminations(examinationsData, isDiagnosticianRole, unlimitedUsage));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onChangePage = (_, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getPatientExaminations());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getPatientExaminations());
};

export const onMenuItemChange = (onClick) => (dispatch) => dispatch(onClick());

export const onNoteOpen = (id) => (dispatch, getStore) => {
  const { items } = getStore().PatientExamination;
  const selectedItem = _find(items, (item) => item.id === id);

  dispatch(setPatientNote({
    examinationName: selectedItem.toolName,
    notes: selectedItem.notes,
  }));
};

export const onNoteClose = () => (dispatch) => {
  dispatch(setPatientNote({}));
};

const loadTool = (id) => (dispatch, getStore) => {
  const userData = getStore().Global.userData;

  const data = {
    clinicIdSubscriptions: _get(userData, 'clinics[0].id', null),
  };

  const url = createURLWithQuery(`survey_tools/${id}`, data);

  return ApiManager.request('get', dispatch, url);
};

export const onNoSubscriptionActionClick = () => (dispatch, getStore) => {
  const store = getStore();
  const isOpen = !store.PatientExamination.isNoSubscriptionModalOpen;
  const userRole = store.Global.userData.role_id;

  if (userRole === roles.diagnostician) {
    dispatch(setModal(PURCHASE_PACKAGE_APPLICATION));
  }

  if (userRole === roles.clinicCoordinator) {
    const data = store.PatientExamination.orderData;

    dispatch(setDrawerInvisibility());
    dispatch(push('/toolsDetails', data));
  }

  dispatch(toggleNoSubscriptionModal(isOpen));
};

export const onActionClick = (item) => async (dispatch, getStore) => {
  switch (item.status) {
    case SUSPENDED: {
      try {
        const response = await dispatch(loadTool(item.session.survey_tool_id));
        const patientID = getStore().PatientExamination.patientID;

        dispatch(push('/researchSelect', {
          patientID,
          toolInfo: {
            apiId: item.session.survey_tool_id,
            sessionPatientsID: item.apiId,
            hasEnoughTokens: response.data.has_enough_tokens,
          },
        }));
      } catch (error) {
        errorCatch(error, dispatch, false);
      }
      break;
    }

    case ENDED: {
      if (!item.isCalculated && !item.hasEnoughTokensToCalculate) {
        const store = getStore();
        const isOpen = !store.PatientExamination.isNoSubscriptionModalOpen;
        const userRole = store.Global.userData.role_id;
        const isActive = item.session.survey_tool.status === 'ACTIVE';
        const batteryId = item.session.survey_tool.battery_id;

        if (userRole === roles.clinicCoordinator) {
          dispatch(setOrderData({
            isActive,
            id: batteryId,
            title: {
              code: item.session.survey_tool.code,
              name: item.session.survey_tool.name,
            },
            description: item.session.survey_tool.description,
          }));
        }

        const { pathname, state, query } = store.router.location;
        const pathWithQuery = createURLWithQuery(pathname, query);

        dispatch(replace(pathWithQuery, { ...state, isActive, batteryId }));
        dispatch(toggleNoSubscriptionModal(isOpen));
      } else if (!item.isCalculated && item.hasEnoughTokensToCalculate) {
        dispatch(showSnackbar(messages.resultIsCalculated));
      } else {
        dispatch(push('/examinationDetails', {
          patientId: getStore().PatientExamination.patientID,
          sessionPatientId: item.apiId,
          researchToolId: item.session.survey_tool_id,
        }));
      }
      break;
    }

    case STARTED: {
      dispatch(push('/surveyAnalysis', {
        sessionPatientId: item.apiId,
        withInterval: true,
      }));
      break;
    }

    case LINK: {
      const url = `session_patients/${item.apiId}/message`;
      dispatch(openDialog({
        title: dialogTexts.sendExaminationLink,
        onAccept: () => {
          ApiManager.request('post', dispatch, url).then(() => {
            dispatch(showSnackbar(snackbarMessages.emailSentAgain));
          }).catch(() => {
            dispatch(showSnackbar(snackbarMessages.globalError));
          });
        },
      }));

      break;
    }

    default:
      break;
  }
};

export const loadPageData = (routeState, values) => async (dispatch, getStore) => {
  dispatch(clearStore());
  dispatch(showLoaderMultipleRequests());
  if (values.page) {
    dispatch(setPage(Number(values.page)));
  }

  if (values.perPage) {
    dispatch(setRowsPerPage(Number(values.perPage)));
  }

  const data = getSortAndPageData(getStore);

  const { role_id: roleId, clinics } = getStore().Global.userData;
  const isDiagnosticianRole = _includes(diagnosticianRoles, roleId);
  const unlimitedUsage = _get(_first(clinics), 'unlimited_usage', false) || false;

  try {
    const examinations = await dispatch(loadPatientExamination(data, routeState.id));
    dispatch(loadPageSuccess(examinations, routeState.id, isDiagnosticianRole, unlimitedUsage));
  } catch (error) {
    errorCatch(error, dispatch, false, false);
  } finally {
    dispatch(hideLoaderMultipleRequests());
  }
};
