import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import _get from 'lodash/get';

import { setModal } from 'containers/store';

import LackOfFund from 'components/pages/LackOfFund';
import { historyShape } from 'utils/shapes/router.shapes';
import roles from 'utils/constants/rolesOfUsers';
import { PURCHASE_PACKAGE_APPLICATION } from 'utils/constants/modalTypes';

const LackOfFundContainer = ({
  history, daysLeft, setModalState, type, userRole,
}) => {
  const onSubmit = () => {
    if (userRole === roles.clinicCoordinator) {
      history.push('/purchasePackageSummary');
      setModalState();
    } else {
      setModalState(PURCHASE_PACKAGE_APPLICATION, { isTopUp: true });
    }
  };

  const onCancel = () => {
    setModalState();
  };

  return (
    <LackOfFund
      onSubmit={onSubmit}
      onCancel={onCancel}
      daysLeft={daysLeft}
      type={type}
    />
  );
};

LackOfFundContainer.propTypes = {
  daysLeft: PropTypes.number.isRequired,
  history: historyShape.isRequired,
  setModalState: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  setModalState: setModal,
};

const mapStateToProps = (state) => ({
  userRole: state.Global.userData.role_id,
  daysLeft: _get(state, 'Global.userData.clinics[0].transitional_period_days_left', null),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LackOfFundContainer));
