import _get from 'lodash/get';
import _trim from 'lodash/trim';

export default (data, reverse) => {
  const firstName = _get(data, 'first_name', '') || '';
  const lastName = _get(data, 'last_name', '') || '';

  return _trim(
    reverse ? `${lastName} ${firstName}` : `${firstName} ${lastName}`,
  );
};
