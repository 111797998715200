import { setModal } from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  url: null,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'VIDEO_PLAYER/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'VIDEO_PLAYER/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        url: action.url,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (url) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  url,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onCancel = () => (dispatch) => {
  dispatch(clearStore());
  dispatch(setModal());
};

export const loadPageData = (props) => (dispatch) => {
  dispatch(clearStore());
  dispatch(loadPageSuccess(props.url));
};
