import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TablePagination from '@material-ui/core/TablePagination';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from '@material-ui/core/Grid';
import TablePaginationActions from 'components/TablePaginationActions';

import messages from './Pagination.messages';

const Pagination = ({
  rowsPerPageOptions,
  totalItemsCount,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
}) => (
  <TablePagination
    ActionsComponent={TablePaginationActions}
    rowsPerPageOptions={rowsPerPageOptions}
    component="div"
    count={totalItemsCount}
    rowsPerPage={rowsPerPage}
    page={page - 1}
    onChangePage={onChangePage}
    onChangeRowsPerPage={onChangeRowsPerPage}
    labelDisplayedRows={({ from, to, count }) => (
      <FormattedMessage
        variant="body2"
        {...messages.labelNameRows}
        values={{ from, to, count }}
      />
    )}
    labelRowsPerPage={(
      <Grid
        container
        component="span"
        direction="row"
        justify="center"
        alignItems="center"
      >
        <MenuIcon fontSize="small" />
      </Grid>
    )}
  />
);

Pagination.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  totalItemsCount: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
};

Pagination.defaultProps = {
  page: null,
  rowsPerPage: null,
  rowsPerPageOptions: null,
  totalItemsCount: null,
  onChangePage: () => {},
  onChangeRowsPerPage: () => {},
};

export default Pagination;
