import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from '@material-ui/core/Tooltip';

import useTooltipStyle from 'components/MenuItemTooltip/MenuItemTooltip.styles';
import useStyle from './MenuIcon.styles';

const MenuIcon = ({
  isActive,
  icon,
  text,
  onClick,
  tooltipClass,
}) => {
  const tooltipClasses = useTooltipStyle();
  const classes = useStyle();

  return (
    <Tooltip
      classes={{
        tooltip: classNames(tooltipClasses.tooltipTooltip, tooltipClass, classes.tooltip),
        arrow: tooltipClasses.tooltipArrow,
        popper: classes.popper,
      }}
      title={text}
      placement="top"
      arrow
    >
      <div
        className={classNames(
          classes.userProfileItem,
          {
            [classes.userProfileItemActive]: isActive,
          },
        )}
        onClick={onClick}
      >
        {icon}
      </div>
    </Tooltip>
  );
};

MenuIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipClass: PropTypes.string,
  onClick: PropTypes.func,
};

MenuIcon.defaultProps = {
  tooltipClass: '',
  onClick: () => {},
};
export default MenuIcon;
