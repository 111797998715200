import { goBack } from 'connected-react-router';

import _get from 'lodash/get';
import _map from 'lodash/map';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';

import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import errorCatch from 'utils/errorCatch';
import passDateToApi from 'utils/passDateToApi';
import createURLWithQuery from 'utils/createURLWithQuery';

import formatPatientData from 'utils/formatPatientData';

import {
  showLoader, hideLoader, showSnackbar, setDrawerVisibility, showTransparentLoader,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  patientID: null,
  schools: [],
  cities: [],
  voivodeships: [],
  voivodeshipId: null,
  cityValue: '',
  schoolValue: '',
  initialValues: {
    name: '',
    surname: '',
    birthDate: null,
    birthPlace: '',
    pesel: '',
    nationality: '',
    streetAndNumber: '',
    city: '',
    postalCode: '',
    schoolAddress: '',
    schoolName: {},
    class: '',
    department: '',
    deletedAt: null,
    userSex: '',
    voivodeship: '',
    email: '',
    schoolCity: {},
    hasSpecialEducationalNeeds: false,
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'PATIENT_ADD/LOAD_PAGE_SUCCESS',
  LOAD_PAGE_DATA_SUCCESS: 'PATIENT_ADD/LOAD_PAGE_DATA_SUCCESS',
  CLEAR_STORE: 'PATIENT_ADD/CLEAR_STORE',
  SET_SCHOOL_ADDRESS: 'PATIENT_ADD/SET_SCHOOL_ADDRESS',
  CHANGE_SCHOOL_ADDRESS: 'PATIENT_ADD/CHANGE_SCHOOL_ADDRESS',
  RESET_SCHOOLS: 'PATIENT_ADD/RESET_SCHOOLS',
  RESET_CITIES: 'PATIENT_ADD/RESET_CITIES',
  SET_CITY_SUGGESTIONS: 'PATIENT_ADD/SET_CITY_SUGGESTIONS',
  CLEAR_CITY_SUGGESTIONS: 'PATIENT_ADD/CLEAR_CITY_SUGGESTIONS',
  SET_VOIVODESHIP: 'PATIENT_ADD/SET_VOIVODESHIP',
  SET_CITY_VALUE: 'PATIENT_ADD/SET_CITY_VALUE',
  SET_SCHOOL_VALUE: 'PATIENT_ADD/SET_SCHOOL_VALUE',
  SET_SCHOOL_SUGGESTIONS: 'PATIENT_ADD/SET_SCHOOL_SUGGESTIONS',
  CLEAR_SCHOOL_SUGGESTIONS: 'PATIENT_ADD/CLEAR_SCHOOL_SUGGESTIONS',
};

const mapCities = (items) => _map(items, (el, index) => ({
  id: index,
  name: el.city,
}));

const mapSchools = (items) => _map(items, (el) => ({
  id: el.id,
  name: el.name,
  street: el.street,
  number: el.number,
  postalCode: el.postal_code,
  city: el.city,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        voivodeships: action.voivodeships.data,
      };
    }

    case actionTypes.LOAD_PAGE_DATA_SUCCESS: {
      const voivodeships = action.voivodeships.data;

      return {
        ...state,
        isLoadedPage: true,
        initialValues: formatPatientData(action.patientData.data, false),
        voivodeships,
        patientID: action.patientID,
        voivodeshipId: action.patientData.data.school_voivodeship_id,
        cityValue: action.patientData.data.school_city || '',
        schoolValue: action.patientData.data.school_name || '',
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.RESET_SCHOOLS: {
      return {
        ...state,
        schoolValue: '',
      };
    }

    case actionTypes.RESET_CITIES: {
      return {
        ...state,
        cityValue: '',
      };
    }

    case actionTypes.SET_CITY_VALUE: {
      return {
        ...state,
        cityValue: action.search,
      };
    }

    case actionTypes.SET_SCHOOL_VALUE: {
      return {
        ...state,
        schoolValue: action.search,
      };
    }

    case actionTypes.CLEAR_SCHOOL_SUGGESTIONS: {
      return {
        ...state,
        schools: [],
      };
    }

    case actionTypes.CLEAR_CITY_SUGGESTIONS: {
      return {
        ...state,
        city: [],
      };
    }

    case actionTypes.SET_CITY_SUGGESTIONS: {
      return {
        ...state,
        cities: mapCities(action.response.data.items),
      };
    }

    case actionTypes.SET_SCHOOL_SUGGESTIONS: {
      return {
        ...state,
        schools: mapSchools(action.response.data.items),
      };
    }

    case actionTypes.SET_VOIVODESHIP: {
      return {
        ...state,
        voivodeshipId: action.response,
      };
    }

    default:
      return state;
  }
};

const loadPageDataSuccess = (patientData, voivodeships, patientID) => ({
  type: actionTypes.LOAD_PAGE_DATA_SUCCESS,
  patientData,
  voivodeships,
  patientID,
});

const loadPageSuccess = (voivodeships) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  voivodeships,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadVoivodeships = () => (dispatch) => (
  ApiManager.request('get', dispatch, 'voivodeships')
);

const setCitySuggestions = (response) => ({
  type: actionTypes.SET_CITY_SUGGESTIONS,
  response,
});

const setSchoolSuggestions = (response) => ({
  type: actionTypes.SET_SCHOOL_SUGGESTIONS,
  response,
});

export const setCityValue = (search) => ({
  type: actionTypes.SET_CITY_VALUE,
  search,
});

export const setSchoolValue = (search) => ({
  type: actionTypes.SET_SCHOOL_VALUE,
  search,
});

export const onSetVoivodeship = (response) => ({
  type: actionTypes.SET_VOIVODESHIP,
  response,
});

export const onCityClear = () => ({
  type: actionTypes.CLEAR_CITY_SUGGESTIONS,
});

export const onSchoolClear = () => ({
  type: actionTypes.CLEAR_SCHOOL_SUGGESTIONS,
});

const getCities = (findByVoivodeshipId, search) => (dispatch) => {
  const params = {
    orderBy: 'city',
    sortedBy: 'asc',
    findByVoivodeshipId,
    findByName: search,
  };

  const url = createURLWithQuery('organizations_cities', params);

  return ApiManager.request('get', dispatch, url);
};

const getSchools = (search, cityValue) => (dispatch) => {
  const params = {
    orderBy: 'name',
    sortedBy: 'asc',
    findByName: search,
    findByCity: cityValue,
  };

  const url = createURLWithQuery('organizations', params);

  return ApiManager.request('get', dispatch, url);
};

export const onCityFetch = (search) => (dispatch, getStore) => {
  const voivodeshipId = getStore().PatientAddEdit.voivodeshipId;

  if (voivodeshipId) {
    dispatch(showTransparentLoader());
    dispatch(getCities(voivodeshipId, search)).then((response) => {
      dispatch(setCitySuggestions(response));
      dispatch(hideLoader());
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }
};

export const onSchoolFetch = (search) => (dispatch, getStore) => {
  const cityValue = getStore().PatientAddEdit.cityValue;

  dispatch(showTransparentLoader());

  dispatch(getSchools(search, cityValue)).then((response) => {
    dispatch(setSchoolSuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const resetSchools = () => ({
  type: actionTypes.RESET_SCHOOLS,
});

export const resetCities = () => ({
  type: actionTypes.RESET_CITIES,
});

export const onSubmit = (values, { setFieldError }) => async (dispatch, getStore) => {
  const patientID = getStore().PatientAddEdit.patientID;
  const url = patientID ? `patients/${patientID}` : 'patients';
  const method = patientID ? 'put' : 'post';
  const message = patientID ? snackbarMessages.patientEdited : snackbarMessages.patientAdded;
  const data = {
    first_name: values.name,
    last_name: values.surname,
    birth_date: passDateToApi(values.birthDate),
    pesel: values.pesel,
    nationality: values.nationality,
    birthplace: values.birthPlace,
    address_street: values.streetAndNumber,
    address_postal_code: values.postalCode,
    address_city: values.city,
    school_class: values.class,
    school_class_section: values.department,
    sex: values.userSex,
    school_name: _get(values, 'schoolName.name', ''),
    school_address: values.schoolAddress,
    school_city: _get(values, 'schoolCity.name', ''),
    school_voivodeship_id: values.voivodeship,
    email: values.email,
    has_special_educational_needs: values.hasSpecialEducationalNeeds,
  };

  dispatch(showTransparentLoader());

  try {
    await ApiManager.request(method, dispatch, url, data);
    dispatch(goBack());
    dispatch(showSnackbar(message));
    dispatch(hideLoader());
  } catch (error) {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.patientAddEdit);
      if (_get(error, 'error.errors.clinic_id[0]', false)) {
        dispatch(showSnackbar(snackbarMessages.wrondClinicId));
      } else {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      }
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

const loadPatientData = (id) => (dispatch) => ApiManager.request('get', dispatch, `patients/${id}`);

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const loadPageData = (routeState, message) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  try {
    const voivodeships = await dispatch(loadVoivodeships());

    if (routeState.id) {
      const patientData = await dispatch(loadPatientData(routeState.id));
      dispatch(loadPageDataSuccess(patientData, voivodeships, routeState.id));
    } else {
      dispatch(loadPageSuccess(voivodeships));
    }
    dispatch(hideLoader());
  } catch (error) {
    if (isBadRequest(error, 404)) {
      dispatch(goBack());
      dispatch(showSnackbar(snackbarMessages.notFoundPatient));
    } else {
      errorCatch(error, dispatch);
    }
  }
};
