import _get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

import formatFullName from 'utils/formatFullName';
import getDateTimeFromApi from 'utils/getDateTimeFromApi';
import { SUSPENDED, PAUSED } from 'utils/constants/examinationStatuses';

const formatNote = (noteData) => ({
  date: getDateTimeFromApi(_get(noteData, 'updated_at', '')),
  note: _get(noteData, 'note', ''),
  diagnostician: formatFullName(noteData.user),
  noteNumber: _get(noteData, 'note_order', ''),
});

export const formatStatusNote = (noteData) => {
  const data = ({
    questionNumber: _get(noteData, 'survey_question.order', undefined),
    question: _get(noteData, 'survey_question.question.name', undefined),
    resumeDate: noteData.resumed_at
      ? getDateTimeFromApi(noteData.resumed_at)
      : undefined,
    note: _get(noteData, 'note', ''),
    diagnostician: formatFullName(noteData.user),
    noteNumber: _get(noteData, 'note_order', ''),
  });

  if (noteData.status === PAUSED) {
    data.pauseDate = getDateTimeFromApi(_get(noteData, 'paused_at', ''));
  }

  if (noteData.status === SUSPENDED) {
    data.interruptedDate = getDateTimeFromApi(_get(noteData, 'paused_at', ''));
  }

  return data;
};

const formatNotes = (sessionData) => _sortBy(_filter([
  ..._map(_get(sessionData, 'session_patient_status_notes'), formatStatusNote),
  ..._map(_get(sessionData, 'session_patient_notes'), formatNote),
], 'note'), 'noteNumber');

export default formatNotes;
