import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  listItemIconRoot: {
    color: theme.palette.drawer.primaryText,
    minWidth: 40,
  },
  listItem: {
    height: 56,
    padding: ({ isCollapsedMenu }) => (
      isCollapsedMenu ? theme.spacing(1, 3, 1, 4.375) : theme.spacing(1, 3)
    ),
    '&:hover': {
      backgroundColor: theme.palette.drawer.hover,
    },
  },
  listItemText: {
    fontWeight: 500,
    color: theme.palette.drawer.primaryText,
    marginLeft: theme.spacing(0.75),
  },
  listItemTextRootSelected: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  selectedListItem: {
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.yellow} !important`,
  },
  activeIcon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(0.5),
  },
  userProfileItem: {
    display: 'flex',
    padding: ({ isCollapsedMenu }) => theme.spacing(0.5, 0, 0.5, isCollapsedMenu ? 4.75 : 3),
    alignItems: 'center',
    color: theme.palette.drawer.secondaryText,
    cursor: 'pointer',
    height: 28,
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  userProfileItemActive: {
    color: theme.palette.yellow,
  },
  userProfileMessage: {
    marginLeft: theme.spacing(1),
  },
}));
