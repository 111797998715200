export default (theme) => ({
  paper: {
    marginTop: 5,
    border: '1px solid',
    borderColor: theme.palette.borderColor,
    borderRadius: 8,
    maxHeight: 600,
    maxWidth: ({ popoverMaxWidth }) => (popoverMaxWidth || 600),
  },
  formInnerWrapper: {
    maxHeight: 500,
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.5, 2, 2, 2),
  },
  label: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  sortLabel: {
    alignItems: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(1.5),
  },
});
