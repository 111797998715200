import { replace } from 'connected-react-router';
import _get from 'lodash/get';

import {
  showTransparentLoader, hideSnackbar, hideLoader, showSnackbar,
} from 'containers/store';
import errorCatch from 'utils/errorCatch';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';

import config from 'config';

export const initialState = {};
export const actionTypes = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const confirmLogin = (token) => async (dispatch) => {
  dispatch(showTransparentLoader());
  dispatch(hideSnackbar());

  const requestBody = {
    token,
  };

  try {
    await ApiManager.request('post', dispatch, 'login/confirmation', requestBody);
    dispatch(hideLoader());
    dispatch(replace('/login'));
  } catch (error) {
    if (_get(error, 'error.errors.token')) {
      dispatch(showSnackbar(snackbarMessages.tokenExpired, false, config.snackbarTypes.error));
      dispatch(hideLoader());
      return;
    }

    const snackbarOptions = {
      showMessageFromApi: true,
      snackbarType: config.snackbarTypes.error,
      snackbarAutohide: false,
    };
    errorCatch(error, dispatch, false, true, snackbarOptions);
  }
};
