import moment from 'moment';

import _get from 'lodash/get';

import formatFullName from 'utils/formatFullName';

const formatShortData = (patientData, sessionData) => {
  const ageNowYears = moment().diff(moment(patientData.birth_date), 'years');
  const ageNowMonths = moment().diff(moment(patientData.birth_date), 'months') % 12;
  const ageThenYears = moment(sessionData.ended_at).diff(moment(patientData.birth_date), 'years');
  const ageThenMonths = moment(sessionData.ended_at).diff(moment(patientData.birth_date), 'months') % 12;

  return ({
    patientName: (patientData.first_name && patientData.last_name)
      ? formatFullName(patientData) : null,
    diagnosticianNote: '',
    ageNow: {
      years: ageNowYears,
      months: ageNowMonths,
    },
    ageThen: {
      years: ageThenYears,
      months: ageThenMonths,
    },
  });
};

const formatNewNoteData = (sessionData, userData) => ({
  noteNumber: _get(sessionData, 'last_note_order', 0) + 1,
  diagnostician: formatFullName(userData),
});

export {
  formatShortData,
  formatNewNoteData,
};
