import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    name: {
      lineHeight: '22px',
    },
    wrapper: {
      position: 'relative',
    },
    maxWidth: {
      maxWidth: 'calc(100% - 36px)',
    },
    tooltip: {
      ...theme.tooltip,
      lineHeight: '16px',
      marginBottom: theme.spacing(1, 0),
      top: 6,
    },
    icon: {
      marginLeft: 10,
      marginBottom: 6,
      boxSizing: 'initial',
    },
    inactiveIcon: {
      height: 17,
      width: 17,
      marginLeft: 17,
      marginBottom: 3,
    },
  }),
);
