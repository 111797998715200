import { defineMessages } from 'react-intl';

import _size from 'lodash/size';

import config from 'config';
import regexes from 'utils/validator/regexesValidation';

const messages = defineMessages({
  notSafe: 'Wpisane hasło nie spełnia wymagań bezpiecznego hasła',
});

const checkPassword = (value) => {
  const isProperSize = _size(value) >= config.passwordMinLength;
  const { hasCapitalLetter, hasSpecialCharacter } = regexes;

  const passwordWithCapitalLetter = hasCapitalLetter.test(value);
  const passwordWithSpecialCharacter = hasSpecialCharacter.test(value);

  return isProperSize && passwordWithCapitalLetter && passwordWithSpecialCharacter;
};

export default (value) => {
  const isValid = checkPassword(value);

  if (!isValid) {
    return ({
      message: messages.notSafe,
      isValid: false,
    });
  }

  return { isValid };
};
