import { defineMessages } from 'react-intl';

export default defineMessages({
  groupSurveys: 'Lista badań grupowych',
  name: 'Nazwa badania grupowego',
  diagnostican: 'Diagnosta',
  classAndSection: 'Klasa i oddział',
  surveyDate: 'Data badania',
  status: 'Status',
  configureFirstGroupSurvey: 'Skonfiguruj pierwsze badanie grupowe',
  emptyState: 'Skonfiguruj pierwsze badanie grupowe',
  notFound: 'Nie znaleziono badań grupowych',
  prepared: 'Przygotowane',
  inProgress: 'W trakcie',
  ended: 'Zakończone',
});
