import React from 'react';
import PropTypes from 'prop-types';
import _endsWith from 'lodash/endsWith';
import _replace from 'lodash/replace';
import _isNumber from 'lodash/isNumber';

import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import regexes from 'utils/validator/regexesValidation';
import checkDecimalPlaces from 'utils/checkDecimalPlaces';
import FormField from 'components/FormField';

import useStyles from './CounterInput.styles';

const CounterInput = ({
  onBlur,
  onChange,
  onInputFocus,
  errors,
  touched,
  disabled,
  name,
  label,
  value,
  maxValue,
  minValue,
  withoutCounterButton,
  inputClasses,
  startAdornment,
  endAdornment,
  hasFloatValue,
  withComma,
  maxDecimalPlaces,
}) => {
  const classes = useStyles();

  return (
    <FormField
      onBlur={onBlur}
      onChange={(currentName, currentValue) => {
        const replacedValue = _replace(currentValue, /,/, '.');
        const currentNumber = Number(replacedValue);

        if (currentValue === '') {
          onChange(currentName, '');
        } else if (hasFloatValue && _endsWith(replacedValue, '.') && currentNumber >= 0) {
          onChange(currentName, withComma ? currentValue : replacedValue);
        } else if (
          !!currentValue.match(regexes.isNumber)
          && _isNumber(maxDecimalPlaces)
          && hasFloatValue) {
          if (checkDecimalPlaces(maxDecimalPlaces, currentValue)) {
            onChange(currentName, currentValue);
          }
        } else if (
          !!currentValue.match(regexes.isNumber)
          && (!maxValue || currentNumber <= maxValue)
          && (currentNumber >= minValue)
        ) {
          const newValue = withComma ? currentValue : currentNumber;
          onChange(currentName, newValue);
        }
      }}
      errors={errors}
      touched={touched}
      name={name}
      label={label}
      fullWidth
      nowrap
    >
      <Input
        startAdornment={startAdornment}
        value={value}
        disabled={disabled}
        classes={inputClasses}
        onFocus={onInputFocus}
        endAdornment={endAdornment || (!withoutCounterButton && !disabled && (
          <>
            <div className={classes.buttonMargin}>
              <Button
                classes={{ root: classes.text }}
                disabled={value >= maxValue && maxValue !== null}
                onClick={() => onChange(name, Number(value) + 1)}
              >
                <AddBoxIcon />
              </Button>
            </div>
            <Button
              disabled={value <= minValue}
              classes={{ root: classes.text }}
              onClick={() => onChange(name, Number(value) - 1)}
            >
              <RemoveBoxIcon />
            </Button>
          </>
        ))}
      />
    </FormField>
  );
};

CounterInput.propTypes = {
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  hasFloatValue: PropTypes.bool,
  inputClasses: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.node,
  maxDecimalPlaces: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  startAdornment: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withComma: PropTypes.bool,
  withoutCounterButton: PropTypes.bool,
  onInputFocus: PropTypes.func,
};

CounterInput.defaultProps = {
  value: '',
  label: '',
  disabled: false,
  maxValue: Infinity,
  minValue: 1,
  startAdornment: null,
  maxDecimalPlaces: null,
  endAdornment: null,
  withoutCounterButton: false,
  inputClasses: {},
  hasFloatValue: false,
  withComma: false,
  onInputFocus: undefined,
};

export default CounterInput;
