import theme from 'theme/theme';

const walletStatuses = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

const colorsPalette = {
  successColor: theme.palette.primary.main,
  errorColor: theme.palette.error.main,
  warningColor: theme.palette.warning.main,
  defaultColor: theme.palette.tooltip.backgroundColor2,
};

const getIconColor = (status) => {
  const { SUCCESS, WARNING, ERROR } = walletStatuses;
  const {
    errorColor, successColor, warningColor, defaultColor,
  } = colorsPalette;
  switch (status) {
    case SUCCESS:
      return successColor;
    case WARNING:
      return warningColor;
    case ERROR:
      return errorColor;
    default:
      return defaultColor;
  }
};

const getTooltipBackgroundColor = (status) => {
  const { SUCCESS, WARNING, ERROR } = walletStatuses;
  const {
    errorColor, warningColor, defaultColor,
  } = colorsPalette;
  switch (status) {
    case SUCCESS:
      return defaultColor;
    case WARNING:
      return warningColor;
    case ERROR:
      return errorColor;
    default:
      return defaultColor;
  }
};

const getMessages = (intl, messages, points) => {
  const messagesGroup = {
    successMessage: intl.formatMessage(messages.successMessage),
    warningMessage: intl.formatMessage(messages.warningMessage),
    errorMessage: intl.formatMessage(messages.errorMessage),
    notCollapsedMenuPoints: intl.formatMessage(messages.notCollapsedMenuPoints, { points }),
    collapsedMenuPoints: intl.formatMessage(messages.collapsedMenuPoints, { points }),
  };

  return messagesGroup;
};

const getTooltipMessage = (status, messages) => {
  const { SUCCESS, WARNING, ERROR } = walletStatuses;
  switch (status) {
    case SUCCESS:
      return messages.successMessage;
    case WARNING:
      return messages.warningMessage;
    case ERROR:
      return messages.errorMessage;
    default:
      return messages.successMessage;
  }
};

const mapPercentageValueToStatus = (value) => {
  const { SUCCESS, WARNING, ERROR } = walletStatuses;
  let status;

  if (value >= 15) {
    status = SUCCESS;
  } else if (value < 15 && value > 0) {
    status = WARNING;
  } else {
    status = ERROR;
  }

  return status;
};

const getSliderValue = (value) => {
  let barValue;

  if (value >= 75) {
    barValue = 100;
  } else if (value >= 50) {
    barValue = 66.66;
  } else if (value >= 25) {
    barValue = 33.33;
  } else if (value >= 15) {
    barValue = 25;
  } else if (value > 0) {
    barValue = 2;
  } else {
    barValue = 0;
  }

  return barValue;
};

const getBarColorClassNames = (status, classes) => {
  const { SUCCESS, WARNING, ERROR } = walletStatuses;
  const {
    barSuccessColor,
    barWarningColor,
    barErrorColor,
  } = classes;

  let barClass;

  if (status === SUCCESS) {
    barClass = barSuccessColor;
  }

  if (status === WARNING) {
    barClass = barWarningColor;
  }

  if (status === ERROR) {
    barClass = barErrorColor;
  }

  return barClass;
};

export {
  getIconColor,
  getTooltipBackgroundColor,
  getMessages,
  getTooltipMessage,
  mapPercentageValueToStatus,
  getSliderValue,
  getBarColorClassNames,
};
