import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';
import _includes from 'lodash/includes';

import formatGroupStructure from 'utils/formatGroupStructure';
import getParentScaleId from 'utils/getParentScaleId';

const formatScaleData = (scale, withParent = false, loads = [], parameters = []) => {
  const scaleData = {
    id: scale.id,
    name: scale.name,
    code: scale.code,
    value: [],
  };
  if (withParent) {
    scaleData.parentId = getParentScaleId(scale.id, loads, parameters);
  }

  return scaleData;
};

const isGroupBeingUsed = (group, usedScalesIds) => _includes(
  usedScalesIds,
  group.primaryDimension.id,
) || _some(
  group.secondaryDimensions,
  (secondaryDimension) => _includes(usedScalesIds, secondaryDimension.id)
    || _some(
      secondaryDimension.tertiaryDimensions,
      (tertiaryDimension) => _includes(usedScalesIds, tertiaryDimension.id),
    ),
);

export const getGroupStructure = (scales, loads, parameters) => formatGroupStructure(
  scales,
  loads,
  parameters,
  formatScaleData,
);

export const getUsedScalesIds = (evaluationData) => _map(
  evaluationData,
  (evaluation) => evaluation.scaleId,
);

export const getDimensionGroupOptions = (groupStructure, usedScalesIds) => _filter(
  groupStructure,
  (group) => !isGroupBeingUsed(group, usedScalesIds),
);

export const getDimensionGroupInitialValues = (groupStructure, usedScalesIds) => _filter(
  groupStructure,
  (group) => isGroupBeingUsed(group, usedScalesIds),
);

const getDimensionValue = (dimension) => {
  if (!_isEmpty(dimension.value)) {
    return _reduce(
      dimension.value,
      (sum, value) => ([
        ...sum,
        {
          scale_id: dimension.id,
          standard_categorical_scale_value_id: value.id,
        }]),
      [],
    );
  }
  return [];
};

export const getCombinationValues = (dimensionGroups) => {
  let combinationValue = [];

  _forEach(dimensionGroups, (group) => {
    combinationValue = [
      ...combinationValue,
      ...getDimensionValue(group.primaryDimension),
    ];

    _forEach(group.secondaryDimensions, (secondaryDimension) => {
      combinationValue = [
        ...combinationValue,
        ...getDimensionValue(secondaryDimension),
      ];

      _forEach(secondaryDimension.tertiaryDimensions, (tertiaryDimension) => {
        combinationValue = [
          ...combinationValue,
          ...getDimensionValue(tertiaryDimension),
        ];
      });
    });
  });
  return combinationValue;
};

export const getEvaluationData = (evaluations) => _map(
  evaluations,
  (evaluation) => ({
    id: evaluation.id,
    scaleId: evaluation.scale_id,
    value: {
      id: evaluation.standard_categorical_scale_value.id,
      name: evaluation.standard_categorical_scale_value.label,
    },
  }),
);

const getDimensionInitialValue = (evaluationData, dimensionId) => _map(
  _filter(evaluationData,
    (evaluation) => evaluation.scaleId === dimensionId),
  (evaluation) => evaluation.value,
);

const getInitialDimensionGroups = (groups, evaluationData) => _map(groups, (group) => ({
  ...group,
  primaryDimension: {
    ...group.primaryDimension,
    value: getDimensionInitialValue(evaluationData, group.primaryDimension.id),
  },
  secondaryDimensions: _map(group.secondaryDimensions, (secondaryDimension) => ({
    ...secondaryDimension,
    value: getDimensionInitialValue(evaluationData, secondaryDimension.id),
    tertiaryDimensions: _map(secondaryDimension.tertiaryDimensions, (tertiaryDimension) => ({
      ...tertiaryDimension,
      value: getDimensionInitialValue(evaluationData, tertiaryDimension.id),
    })),
  })),
}));

export const formatInitialValues = (values, groups, evaluationData) => ({
  id: values.id,
  name: values.name,
  description: values.description,
  dimensionGroups: getInitialDimensionGroups(groups, evaluationData),
});
