import React from 'react';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes/intlShape';
import Typography from '@material-ui/core/Typography';
import Section from 'components/Section';
import BannerContainer from 'components/BannerContainer';
import CardContent from 'components/CardContent';
import Modal from 'components/Modal';
import SummaryButtons from 'components/SummaryButtons';
import { withStyles } from '@material-ui/core';
import { classesShape } from 'utils/shapes/classesShape';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noFundsModalLogo from 'assets/img/noFundsModal.svg';

import { getMessages } from './LackOfFund.utils';
import messages from './LackOfFund.messages';
import styles from './LackOfFund.styles';

const propTypes = {
  classes: classesShape.isRequired,
  daysLeft: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const LackOfFund = ({
  classes,
  intl,
  type,
  daysLeft,
  onCancel,
  onSubmit,
}) => {
  const messagesGroup = getMessages(intl, messages, daysLeft);

  return (
    <Modal
      isOpen={!!type}
      onRequestClose={onCancel}
    >
      <CardContent withoutBorder bottomMargin={false}>
        <BannerContainer
          alignLogo
          addPaddings
          logo={noFundsModalLogo}
          withSmallerPadding={false}
        >
          <Section title={messagesGroup.title}>
            <Typography variant="body1" className={classes.typographySpacing}>
              {messagesGroup.lacksOfFund}
            </Typography>
            <Typography variant="body1" className={classes.typographySpacing}>
              {messagesGroup.additionalInfo}
            </Typography>
            <Typography variant="body1" className={classNames(classes.warning, classes.typographySpacing)}>
              {messagesGroup.warning}
            </Typography>
          </Section>
          <SummaryButtons
            cancelMessage={messagesGroup.cancel}
            submitMessage={messagesGroup.submit}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </BannerContainer>
      </CardContent>
    </Modal>
  );
};

LackOfFund.propTypes = propTypes;

export default withStyles(styles)(injectIntl(LackOfFund));
