import { push } from 'connected-react-router';

import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _isNull from 'lodash/isNull';

import errorCatch from 'utils/errorCatch';
import PromiseAll from 'utils/PromiseAll';
import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import roles from 'utils/constants/rolesOfUsers';

import config from 'config';

import { showLoader, hideLoader, showTransparentLoader } from 'containers/store';

import {
  formatDimensionCodes,
  formatDescriptionData,
  formatTimeData,
  formatGraphData,
  filterGraphData,
  filterResultsToCompare,
  formatComparisonOptions,
  getDataWithComparingResults,
  getTableData,
  formatExaminationSymbol,
  formatExaminationHistory,
  formatReportData,
  getAllDimensionsCodes,
  formatDescriptionReportData,
  getResearchToolScales,
  getDefaultComparisonMethod,
} from './utils';

const { comparisonMethods } = config;

export const initialState = {
  isLoadedPage: false,
  pageTitle: '',
  pageSubtitle: '',
  descriptionData: {
    dimensionData: [],
    summary: '',
    recomendation: '',
  },
  isViewOnly: true,
  dimensionCodesData: [],
  examinationName: '',
  isExaminationArchived: false,
  examinationNameWithDate: '',
  filterData: {
    filterOptions: {
      comparableExaminations: {
        vertical: [],
        horizontal: [],
      },
      primaryDimensions: [],
      standarizedScales: [],
      disableComparison: false,
    },
    filterValues: {
      examinationsDates: [],
      comparisonMethod: comparisonMethods.horizontal,
      standarizedScale: 3,
      qualityFilter: 'showAll',
      dimensionTypeFilter: 'primaryDimension',
      primaryDimension: true,
      secondaryDimension: true,
      tertiaryDimension: true,
    },
  },
  expandedDimensions: [],
  graphData: {
    data: [],
    dataKeys: [],
    referenceLines:
    {
      high: null,
      medium: null,
      low: null,
    },
  },
  examinationReportData: {
    date: '',
    diagnosticianName: '',
    clinicName: '',
  },
  metricsTableData: {
    referenceResults: [],
    result: {
      date: '',
      color: '',
      data: [],
    },
  },
  standarizedScales: [],
  permissions: [],
  timeData: {
    minimumTime: {
      time: '',
      scaleTime: null,
    },
    patientTime: {
      time: '',
      scaleTime: null,
    },
    avarageTime: {
      time: '',
      scaleTime: null,
    },
    isAboveMinimum: true,
  },
  examinationHistory: [],
  initialData: {},
  comparableResultsLoaded: false,
  comparisonOptionsLoaded: false,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'EXAMINATION_DETAILS/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'EXAMINATION_DETAILS/CLEAR_STORE',
  UPDATE_DISPLAYED_RESULT: 'EXAMINATION_DETAILS/UPDATE_DISPLAYED_RESULT',
  RESET_COMPARISON: 'EXAMINATION_DETAILS/RESET_COMPARISON',
  UPDATE_NOTES_DATA: 'EXAMINATION_DETAILS/UPDATE_NOTES_DATA',
  LOAD_RESULTS_TO_COMPARE: 'EXAMINATION_DETAILS/LOAD_RESULTS_TO_COMPARE',
  LOAD_COMPARISON_OPTIONS: 'EXAMINATION_DETAILS/LOAD_COMPARISON_OPTIONS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const {
        responses,
        sessionPatientId,
        patientId,
        userData,
        standardizedScales,
        standarizedScale,
        isViewOnly,
      } = action;

      const standardScaleData = responses.standardScaleData.data;
      const surveyEvaluationData = responses.surveyEvaluationData.data.items;
      const scaleParametersData = responses.psychometricParameters.data.scale_parameters;
      const scaleLoadsData = responses.psychometricParameters.data.scale_charges;
      const scalesData = getResearchToolScales(responses.psychometricParameters.data.scales);
      const sessionData = responses.sessionData.data;
      const sessionsToCompare = [sessionData];

      const timeData = formatTimeData(
        responses.researchToolData.data.survey,
        sessionData,
      );

      const dimensionCodesData = formatDimensionCodes(
        scalesData,
        scaleLoadsData,
        scaleParametersData,
      );

      const allDimensionsCodes = getAllDimensionsCodes(scalesData);

      const filterValues = {
        ...initialState.filterData.filterValues,
        standarizedScale: _isNull(standarizedScale)
          ? initialState.filterData.filterValues.standarizedScale
          : standarizedScale,
      };

      const graphData = formatGraphData(
        standardScaleData,
        surveyEvaluationData,
        dimensionCodesData,
        state.expandedDimensions,
        filterValues,
        sessionsToCompare,
        '',
        scalesData,
      );

      const graphDataExpanded = formatGraphData(
        standardScaleData,
        surveyEvaluationData,
        dimensionCodesData,
        allDimensionsCodes,
        filterValues,
        sessionsToCompare,
        '',
        scalesData,
      );

      const descriptionData = formatDescriptionData(
        scalesData,
        scaleLoadsData,
        scaleParametersData,
        surveyEvaluationData,
        sessionData,
      );

      const descriptionReportData = formatDescriptionReportData(
        descriptionData.dimensionData,
      );

      const examinationName = responses.researchToolData.data.name;
      const isExaminationArchived = responses.researchToolData.data.status
        === config.researchToolStatuses.archived;

      const examinationSymbol = formatExaminationSymbol(
        responses.researchToolData.data,
        sessionData,
      );

      const pageTitle = responses.researchToolData.data.code || '';
      const pageSubtitle = responses.researchToolData.data.name;

      const metricsTableData = getTableData(
        graphData,
        sessionPatientId,
        filterValues,
      );
      const metricsTableDataExpanded = getTableData(
        graphDataExpanded,
        sessionPatientId,
        filterValues,
      );

      const examinationHistory = formatExaminationHistory(sessionData);

      const examinationReportData = formatReportData(
        sessionData,
        userData,
      );

      const batteryId = responses.researchToolData.data.battery_id;
      const batteryCode = responses.researchToolData.data.battery.code;

      const disableComparison = !_get(sessionData, 'has_session_patients_to_compare', false);

      return {
        ...state,
        isViewOnly,
        examinationName,
        isExaminationArchived,
        examinationSymbol,
        pageTitle,
        pageSubtitle,
        dimensionCodesData,
        descriptionData,
        descriptionReportData,
        metricsTableData,
        metricsTableDataExpanded,
        examinationHistory,
        standarizedScales: standardizedScales,
        isLoadedPage: true,
        responses,
        timeData,
        graphData,
        graphDataExpanded,
        filterData: {
          filterValues,
          filterOptions: {
            ...initialState.filterData.filterOptions,
            disableComparison,
          },
        },
        examinationReportData,
        initialData: {
          allDimensionsCodes,
          standardScaleData,
          surveyEvaluationData,
          dimensionCodesData,
          graphData,
          sessionPatientId,
          sessionData,
          scalesData,
          batteryId,
          batteryCode,
          patientId,
          sessionsToCompare,
        },
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.LOAD_RESULTS_TO_COMPARE: {
      const resultsToCompare = filterResultsToCompare(
        action.results, state.initialData.sessionPatientId,
      );

      return {
        ...state,
        resultsToCompare,
        comparableResultsLoaded: true,
      };
    }

    case actionTypes.LOAD_COMPARISON_OPTIONS: {
      const sessionsToCompare = action.comparisonOptions;

      const { verticalFilterOptions, horizontalFilterOptions } = formatComparisonOptions(
        sessionsToCompare,
        state.initialData.sessionData.session.survey_tool_id,
        state.initialData.sessionPatientId,
      );

      return {
        ...state,
        filterData: {
          filterValues: {
            ...state.filterData.filterValues,
            comparisonMethod:
              getDefaultComparisonMethod(horizontalFilterOptions, verticalFilterOptions),
          },
          filterOptions: {
            ...state.filterData.filterOptions,
            comparableExaminations: {
              vertical: verticalFilterOptions,
              horizontal: horizontalFilterOptions,
            },
          },
        },
        initialData: {
          ...state.initialData,
          sessionsToCompare: [
            ...state.initialData.sessionsToCompare,
            ...sessionsToCompare,
          ],
        },
        comparisonOptionsLoaded: true,
      };
    }

    case actionTypes.UPDATE_DISPLAYED_RESULT: {
      const { expandedDimensions, filterValues } = action;

      const dataWithComparingResults = getDataWithComparingResults(
        state.initialData.surveyEvaluationData,
        state.resultsToCompare,
        filterValues,
      );

      const newGraphData = formatGraphData(
        state.initialData.standardScaleData,
        dataWithComparingResults,
        state.initialData.dimensionCodesData,
        expandedDimensions,
        filterValues,
        state.initialData.sessionsToCompare,
        state.initialData.batteryCode,
        state.initialData.scalesData,
      );

      const filteredGraphData = filterGraphData(
        newGraphData.data,
        newGraphData.referenceLines,
        filterValues,
      );

      const graphData = {
        ...newGraphData,
        data: filteredGraphData,
      };

      const metricsTableData = getTableData(
        graphData,
        state.initialData.sessionPatientId,
        filterValues,
      );

      const newState = {
        ...state,
        graphData,
        expandedDimensions,
        metricsTableData,
        filterData: {
          ...state.filterData,
          filterValues,
        },
      };

      if (
        state.filterData.filterValues.standarizedScale
        !== newState.filterData.filterValues.standarizedScale
      ) {
        const graphDataExpanded = formatGraphData(
          state.initialData.standardScaleData,
          state.initialData.surveyEvaluationData,
          state.initialData.dimensionCodesData,
          state.initialData.allDimensionsCodes,
          filterValues,
          state.initialData.sessionsToCompare,
          '',
          state.initialData.scalesData,
        );

        const metricsTableDataExpanded = getTableData(
          graphDataExpanded,
          state.initialData.sessionPatientId,
          filterValues,
        );

        newState.graphDataExpanded = graphDataExpanded;
        newState.metricsTableDataExpanded = metricsTableDataExpanded;
      }

      return newState;
    }

    case actionTypes.RESET_COMPARISON: {
      const filterValues = {
        ...state.filterData.filterValues,
        examinationsDates: [],
        comparisonMethod:
          getDefaultComparisonMethod(
            state.filterData.filterOptions.comparableExaminations.horizontal,
            state.filterData.filterOptions.comparableExaminations.vertical,
          ),
      };

      const graphData = formatGraphData(
        state.initialData.standardScaleData,
        state.initialData.surveyEvaluationData,
        state.initialData.dimensionCodesData,
        state.expandedDimensions,
        filterValues,
        state.initialData.sessionsToCompare,
        '',
        state.initialData.scalesData,
      );
      const metricsTableData = getTableData(
        graphData,
        state.initialData.sessionPatientId,
        filterValues,
      );

      return {
        ...state,
        graphData,
        metricsTableData,
        filterData: {
          ...state.filterData,
          filterValues,
        },
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (
  responses,
  userData,
  toolId,
  sessionPatientId,
  patientId,
  standarizedScale,
  standardizedScales,
  isViewOnly,
) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  userData,
  toolId,
  sessionPatientId,
  patientId,
  standarizedScale,
  standardizedScales,
  isViewOnly,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const updateDisplayedResult = (expandedDimensions, filterValues) => ({
  type: actionTypes.UPDATE_DISPLAYED_RESULT,
  expandedDimensions,
  filterValues,
});

const closeComparison = () => ({
  type: actionTypes.RESET_COMPARISON,
});

const loadResultsToCompare = (results) => ({
  type: actionTypes.LOAD_RESULTS_TO_COMPARE,
  results,
});

const loadComparisonOptions = (comparisonOptions) => ({
  type: actionTypes.LOAD_COMPARISON_OPTIONS,
  comparisonOptions,
});

export const onAnalysisClick = () => (dispatch, getStore) => {
  const { initialData: { sessionPatientId } } = getStore().ExaminationDetails;

  dispatch(push('/surveyAnalysis', { sessionPatientId }));
};

export const onStartNewExamination = () => (dispatch, getStore) => {
  const { patientId } = getStore().ExaminationDetails.initialData;
  const userWithEmail = !!_get(getStore(), 'ExaminationDetailsPatientData.patientData.email', '');

  dispatch(push('/researchStart', { patientID: patientId, userWithEmail }));
};

export const onStandarizedScaleChange = (value) => (dispatch, getStore) => {
  const { filterData, expandedDimensions } = getStore().ExaminationDetails;
  const { filterValues } = filterData;
  const values = {
    ...filterValues,
    standarizedScale: value,
  };

  dispatch(updateDisplayedResult(expandedDimensions, values));
};

export const onFiltersSave = (values) => (dispatch, getStore) => {
  const currentFilterValues = getStore().ExaminationDetails.filterData.filterValues;
  const expandedDimensions = getStore().ExaminationDetails.expandedDimensions;

  if (!_isEqual(currentFilterValues, values)) {
    dispatch(updateDisplayedResult(expandedDimensions, values));
  }
};

export const onCloseComparison = () => (dispatch) => {
  dispatch(closeComparison());
};

export const onExpandClick = (id) => (dispatch, getStore) => {
  const { filterData, expandedDimensions } = getStore().ExaminationDetails;
  const { filterValues } = filterData;

  const newExpandedDimensions = [...expandedDimensions, id];
  dispatch(updateDisplayedResult(newExpandedDimensions, filterValues));
};

export const onShrinkClick = (id) => (dispatch, getStore) => {
  const { filterData, expandedDimensions } = getStore().ExaminationDetails;
  const { filterValues } = filterData;
  const newExpandedDimensions = _filter(expandedDimensions, (dimension) => dimension !== id);
  dispatch(updateDisplayedResult(newExpandedDimensions, filterValues));
};

export const onExportReport = (callback) => async (dispatch) => {
  dispatch(showTransparentLoader());
  await callback();
  dispatch(hideLoader());
};

const getSurveyEvaluationData = (sessionPatientId) => (dispatch) => {
  const url = createURLWithQuery('survey_evaluations', {
    filterSessionPatientId: sessionPatientId,
    appendMetrics: 1,
  });

  return ApiManager.request('get', dispatch, url);
};

const getResultsByBattery = (batteryId, patientId) => (dispatch) => {
  const url = createURLWithQuery('survey_evaluations', {
    filterBatteryId: batteryId,
    appendMetrics: 1,
    filterPatientId: patientId,
  });

  return ApiManager.request('get', dispatch, url);
};

const getResearchToolData = (id, clinicId) => (dispatch) => {
  const url = createURLWithQuery(`survey_tools/${id}`, {
    clinicIdSubscriptions: clinicId,
  });

  return ApiManager.request('get', dispatch, url);
};

const getStandardScaleData = (id) => (dispatch) => {
  const url = createURLWithQuery(`standard_categorical_scales/${id}`, {
    appendStandardScales: true,
  });

  return ApiManager.request('get', dispatch, url);
};

const getSessionData = (id) => (dispatch) => {
  const url = createURLWithQuery(
    `session_patients/${id}`,
    {
      appendScsValuesCombinationsSet: true,
    },
  );

  return ApiManager.request('get', dispatch, url);
};

const getPsychometricParameters = (id) => (dispatch) => {
  const url = createURLWithQuery('psychometric_parameters', {
    findBySurveyTool: id,
  });

  return ApiManager.request('get', dispatch, url);
};

const getSessionsToCompare = (sessionPatientId) => (dispatch) => {
  const url = `session_patients/${sessionPatientId}/calculated`;

  return ApiManager.request('get', dispatch, url);
};

export const onComparisonOpen = (openModal) => async (dispatch, getStore) => {
  const { comparisonOptionsLoaded } = getStore().ExaminationDetails;
  const { sessionPatientId } = getStore().ExaminationDetails.initialData;
  if (!comparisonOptionsLoaded) {
    dispatch(showTransparentLoader());
    const response = await dispatch(getSessionsToCompare(sessionPatientId));
    dispatch(loadComparisonOptions(response.data));
  }
  dispatch(hideLoader());
  openModal();
};

export const onComparisonSave = (values) => async (dispatch, getStore) => {
  const { comparableResultsLoaded } = getStore().ExaminationDetails;
  const { batteryId, patientId } = getStore().ExaminationDetails.initialData;
  if (!comparableResultsLoaded) {
    dispatch(showTransparentLoader());
    const response = await dispatch(getResultsByBattery(batteryId, patientId));
    dispatch(loadResultsToCompare(response.data.items));
  }
  dispatch(onFiltersSave(values));
  dispatch(hideLoader());
};

export const loadPageData = (
  patientId,
  sessionPatientId,
  researchToolId,
) => (dispatch, getStore) => {
  dispatch(clearStore());
  dispatch(showLoader());
  const { userData, globalData } = getStore().Global;
  const isViewOnly = userData.role_id === roles.accountManager;
  const clinicId = _get(userData, 'clinics[0].id');

  const promises = {
    surveyEvaluationData: dispatch(getSurveyEvaluationData(sessionPatientId)),
    sessionData: dispatch(getSessionData(sessionPatientId)),
    researchToolData: dispatch(getResearchToolData(researchToolId, clinicId)),
    psychometricParameters: dispatch(getPsychometricParameters(researchToolId)),
  };

  return PromiseAll(promises).then((responses) => {
    const standardScaleId = responses.researchToolData.data.standard_categorical_scale_id;

    return dispatch(getStandardScaleData(standardScaleId))
      .then((standardScaleData) => {
        const standardizedScales = globalData.standardizedScales;
        const standarizedScale = userData.standard_scale_id;
        dispatch(loadPageSuccess(
          {
            ...responses,
            standardScaleData,
          },
          userData,
          researchToolId,
          sessionPatientId,
          patientId,
          standarizedScale,
          standardizedScales,
          isViewOnly,
        ));
        return Promise.resolve();
      }).catch((err) => {
        errorCatch(err, dispatch, true, true);
      });
  }).catch((err) => {
    errorCatch(err, dispatch, true, true);
  });
};
