import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  injectIntl,
} from 'react-intl';

import { classesShape } from 'utils/shapes/classesShape';
import { intlShape } from 'utils/shapes/intlShape';

import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import FormField from 'components/FormField';
import SummaryButtons from 'components/SummaryButtons';
import Section from 'components/Section';
import BannerContainer from 'components/BannerContainer';
import Modal from 'components/Modal';
import CardContent from 'components/CardContent';
import validator from 'utils/validator/core';
import diagnosticianTopUp from 'assets/img/diagnosticianTopUp.svg';

import messages from './PurchasePackageApplication.messages';
import { initialValuesShape } from './PurchasePackageApplication.shapes';
import PurchasePackageApplicationStyles from './PurchasePackageApplication.styles';

const validators = [{
  fieldName: 'reason',
  validators: [{
    validatorName: 'maxLength',
    parameters: [255],
  }],
}];

const PurchasePackageApplication = ({
  intl,
  classes,
  initialValues,
  onSubmit,
  onCancel,
  type,
  isSubmitting,
}) => (
  <Modal
    isOpen={!!type}
    onRequestClose={!isSubmitting ? onCancel : undefined}
  >
    <CardContent withoutBorder bottomMargin={false} staticWidth>
      <BannerContainer
        logo={diagnosticianTopUp}
        addPaddings
        smallLogoPadding
        withSmallerPadding={false}
      >
        <Formik
          initialValues={{ ...initialValues }}
          validate={(values) => validator(values, validators, intl.formatMessage)}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form className={classes.form}>
              <Section title={intl.formatMessage(messages.title)}>
                <Grid container>
                  <Grid className={classes.row} container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <FormField
                        onBlur={setFieldTouched}
                        onChange={setFieldValue}
                        errors={errors}
                        touched={touched}
                        name="sender"
                        label={intl.formatMessage(messages.sender)}
                        fullWidth
                      >
                        <Input
                          className={classes.disabledInput}
                          disabled
                          disableUnderline
                          value={values.sender}
                        />
                      </FormField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormField
                        onBlur={setFieldTouched}
                        onChange={setFieldValue}
                        errors={errors}
                        touched={touched}
                        name="role"
                        label={intl.formatMessage(messages.role)}
                        fullWidth
                      >
                        <Input
                          className={classes.disabledInput}
                          disabled
                          disableUnderline
                          value={values.role}
                        />
                      </FormField>
                    </Grid>
                  </Grid>
                  <Grid className={classes.row} container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <FormField
                        onBlur={setFieldTouched}
                        onChange={setFieldValue}
                        errors={errors}
                        touched={touched}
                        name="addressee"
                        label={intl.formatMessage(messages.addressee)}
                        fullWidth
                      >
                        <Input
                          className={classes.disabledInput}
                          disabled
                          disableUnderline
                          value={values.addressee}
                        />
                      </FormField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormField
                        onBlur={setFieldTouched}
                        onChange={setFieldValue}
                        errors={errors}
                        touched={touched}
                        name="subject"
                        label={intl.formatMessage(messages.subject)}
                        fullWidth
                      >
                        <Input
                          className={classes.disabledInput}
                          disabled
                          disableUnderline
                          value={values.subject}
                        />
                      </FormField>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      errors={errors}
                      touched={touched}
                      name="reason"
                      label={intl.formatMessage(messages.reason)}
                      fullWidth
                    >
                      <Input
                        value={values.reason}
                        multiline
                        rowsMax="4"
                      />
                    </FormField>
                  </Grid>
                </Grid>
              </Section>
              <SummaryButtons
                cancelMessage={intl.formatMessage(messages.cancel)}
                submitMessage={intl.formatMessage(messages.save)}
                withLoaderButton
                withBiggerSpacing
                onCancel={onCancel}
                loading={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </BannerContainer>
    </CardContent>
  </Modal>
);

PurchasePackageApplication.propTypes = {
  initialValues: initialValuesShape.isRequired,
  intl: intlShape.isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  isSubmitting: PropTypes.bool,
};

PurchasePackageApplication.defaultProps = {
  isSubmitting: false,
  classes: {},
};

export default withStyles(PurchasePackageApplicationStyles)(injectIntl(PurchasePackageApplication));
