import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Wniosek o doładowanie/aktywację baterii',
  cancel: 'Anuluj',
  save: 'Wyślij',
  sender: 'Nadawca',
  role: 'Rola',
  addressee: 'Adresat',
  subject: 'Temat',
  reason: 'Uzasadnienie',
});
