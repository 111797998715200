import { defineMessages } from 'react-intl';

import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _get from 'lodash/get';
import regexes from 'utils/validator/regexesValidation';

const messages = defineMessages({
  cannotBeEmpty: 'Pole nie może być puste',
  mustBeHigher: 'Wartość pola jest zbyt niska',
  tooManyDecimalPlaces: 'Za dużo miejsc dziesiętnych',
});

export default (value, bottomLimit, withoutTopLimit, withDecimalPlace) => {
  const result = {
    fields: {},
  };

  result.isValid = true;

  _forEach(value, (el, index) => {
    const topLimitValue = _get(el, 'topLimit', el);

    if (!withoutTopLimit || index < value.length - 1) {
      if (_isEmpty(topLimitValue) && !_isNumber(topLimitValue)) {
        result.fields[`top-limit-${index}`] = messages.cannotBeEmpty;
        result.isValid = false;
      } else if (
        (
          index === 0 && !!bottomLimit && topLimitValue <= Number(bottomLimit)
        ) || (
          index >= 1 && Number(topLimitValue) <= Number(_get(value[index - 1], 'topLimit', value[index - 1]))
        )
      ) {
        result.fields[`top-limit-${index}`] = messages.mustBeHigher;
        result.isValid = false;
      } else if (
        withDecimalPlace && !regexes.isOneDecimalPlace.test(topLimitValue)
            && topLimitValue.indexOf('.') !== -1
      ) {
        result.isValid = false;
        result.fields[`top-limit-${index}`] = messages.tooManyDecimalPlaces;
      }
    }
  });

  return result;
};
