import { defineMessages } from 'react-intl';

export default defineMessages({
  title: '{name}',
  deleteDialogPrompt: 'Czy na pewno chcesz usunąć to badanie grupowe?',
  stopDialogPrompt: 'Czy na pewno chcesz zakończyć badania grupowe? Nieukończone sesje zostaną porzucone.',
  cancelButtonText: 'Nie',
  saveButtonText: 'Tak',
  patient: 'Klient',
  classAndSection: 'Klasa i oddział',
  examinationCode: 'Kod badania',
  reportPatientsName: 'Lista uczestników badania - {name}',
  reportTitle: 'Lista uczestników badania grupowego',
  examinationName: 'Nazwa badania',
  number: 'Notatka nr',
  date: 'Data',
  diagnosticianName: 'Diagnosta',
  content: 'Treść',
  diagnosticianNotes: 'Notatki diagnosty',
  results: 'Wyniki',
  examinationTime: 'Czasy wykonania badania',
  graph: 'Wykres',
  metricsTable: 'Tabela metryk',
  resultDescription: 'Wyniki',
  name: 'Nazwa wymiaru',
  code: 'Kod wymiaru',
  overallResult: 'Wynik ogólny',
  ten: 'Ten',
  sten: 'Sten',
  stanin: 'Stanin',
  anonymous: 'Klient anonimowy id {id}',
  activeStopButton: 'Zakończ badanie grupowe',
  disabledStopButton: 'Nie można ręcznie zakończyć badania grupowego ponieważ co najmniej jeden badany jest w trakcie wypełniania kwestionariusza.',
});
