import _isEmpty from 'lodash/isEmpty';

import {
  setModal,
  showSnackbar,
} from 'containers/store';
import {
  purchaseHistoryRefresh,
  refreshCreditPointsList,
} from 'containers/Facility/store';

import snackbarMessages from 'utils/snackbarMessages';
import ApiManager from 'utils/ApiManager';
import getNumberFromValueWithComma from 'utils/getNumberFromValueWithComma';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    topUpAmount: 0,
    addedPoints: 0,
    allPoints: 0,
    substantiation: '',
    positionName: '',
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'TOP_UP_ACCOUNT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'TOP_UP_ACCOUNT/CLEAR_STORE',
  LOAD_FILE_DATA: 'TOP_UP_ACCOUNT/LOAD_FILE_DATA',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      if (_isEmpty(action.initialValues)) {
        return {
          ...state,
          isLoadedPage: true,
          isEditMode: true,
        };
      }
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          addedPoints: action.initialValues.extraCreditPoints,
          allPoints: action.initialValues.pointsAmount,
          topUpAmount: action.initialValues.topUpAmount,
          substantiation: action.initialValues.substantiation || '',
          balanceAfterRecharge: action.initialValues.balanceAfterRecharge,
          positionName: action.initialValues.positionName,
        },
        isLoadedPage: true,
        isEditMode: false,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (initialValues, viewMode) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  initialValues,
  viewMode,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onSubmit = (values) => async (dispatch, getStore) => {
  const facilityId = getStore().Facility.clinicID;
  const topUpAmount = getNumberFromValueWithComma(values.topUpAmount);
  const url = topUpAmount ? 'orders' : 'subscriptions';

  const data = topUpAmount ? {
    gross_value: topUpAmount,
    clinic_id: facilityId,
    amount_of_tokens: values.addedPoints,
    substantiation: values.substantiation,
    type: 'MANUAL_RECHARGE',
    position_name: values.positionName,
  } : {
    clinic_id: facilityId,
    substantiation: values.substantiation,
    tokens: values.addedPoints,
    position_name: values.positionName,
  };

  try {
    await ApiManager.request('post', dispatch, url, data);
    dispatch(setModal());
    dispatch(showSnackbar(snackbarMessages.topUpAdded));
    dispatch(purchaseHistoryRefresh());
    dispatch(refreshCreditPointsList());
    dispatch(clearStore());
  } catch (error) {
    dispatch(showSnackbar(snackbarMessages.globalError));
  }
};

export const onCancel = () => (dispatch) => {
  dispatch(clearStore());
  dispatch(setModal());
};

export const loadPageData = (initialValues) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(loadPageSuccess(initialValues));
};
