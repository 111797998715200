import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import useStyle from './VisibilityIconButton.styles';

const VisibilityIconButton = ({ visible, setVisibility }) => {
  const classes = useStyle();

  return (
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => { setVisibility(!visible); }}
      edge="end"
      size="small"
    >
      {
        visible
          ? <VisibilityOffIcon className={classes.visibilityIcon} />
          : <VisibilityIcon className={classes.visibilityIcon} />
      }
    </IconButton>
  );
};

VisibilityIconButton.propTypes = {
  setVisibility: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default VisibilityIconButton;
