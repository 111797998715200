export default (theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    color: theme.palette.tableCell.icon,
    '& > svg': {
      height: 16,
      width: 16,
      cursor: 'pointer',
    },
  },
  additionalIconWrapper: {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& > svg': {
      height: 16,
      width: 16,
    },
  },
});
