export default (theme) => ({
  cancelWrapper: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  gridPadding: {
    paddingTop: theme.spacing(5),
  },
  smallPadding: {
    paddingTop: theme.spacing(3),
  },
  bigPadding: {
    paddingTop: theme.spacing(6),
  },
  smallButton: {
    height: 36,
  },
});
