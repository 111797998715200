import _reduce from 'lodash/reduce';
import _forEach from 'lodash/forEach';
import _trimEnd from 'lodash/trimEnd';
import _isEmpty from 'lodash/isEmpty';

const getDimensionName = (dimension) => {
  if (!_isEmpty(dimension.value)) {
    return _reduce(
      dimension.value,
      (sum, value) => (`${sum}${dimension.code}/${value.name[0]}, `),
      '',
    );
  }
  return '';
};

export default (dimensionGroups) => {
  let combinationName = '';

  _forEach(dimensionGroups, (group) => {
    combinationName += getDimensionName(group.primaryDimension);

    _forEach(group.secondaryDimensions, (dimension) => {
      combinationName += getDimensionName(dimension);

      _forEach(dimension.tertiaryDimensions, (tertiaryDimension) => {
        combinationName += getDimensionName(tertiaryDimension);
      });
    });
  });
  return (_trimEnd(combinationName, ', '));
};
