import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ProtectedByPermissions from 'components/ProtectedByPermissions';

import { menuShape } from 'utils/shapes/menuOptionsShapes';

import useStyles from './DropdownMenu.styles';

const DropdownMenu = ({
  options,
  onChange,
  primaryColorButton,
  primaryHoverButton,
  wideMenuOptions,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFocused, setFocused] = useState(false);

  const handleClick = (event) => {
    setFocused(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFocused(false);
    setAnchorEl(null);
  };
  const iconButtonClasses = classNames({
    [classes.primaryHoverButton]: primaryHoverButton,
    [classes.iconButtonRoot]: primaryColorButton,
    [classes.focusedRipplePrimaryBackground]: isFocused && primaryHoverButton,
  });

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClick}
        classes={{ root: iconButtonClasses, label: classes.label }}
        focusVisibleClassName={classes.focus}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        PopoverClasses={{
          paper: classes.popoverWrapper,
        }}
      >
        {options.map((el) => (el.requiredPermissions ? (
          <div key={el.id}>
            <ProtectedByPermissions
              requiredPermission={el.requiredPermissions}
              permissionsOperator={el.permissionsOperator}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  onChange(el.onClick);
                }}
                classes={{
                  root: classNames(classes.menuItemRoot, {
                    [classes.wideMenuItem]: wideMenuOptions,
                  }),
                }}
              >
                {el.name}
              </MenuItem>
            </ProtectedByPermissions>
          </div>
        ) : (
          <MenuItem
            key={el.id}
            onClick={() => {
              handleClose();
              onChange(el.onClick);
            }}
            classes={{
              root: classNames(classes.menuItemRoot, {
                [classes.wideMenuItem]: wideMenuOptions,
              }),
            }}
          >
            {el.name}
          </MenuItem>
        )))}
      </Menu>
    </>
  );
};

DropdownMenu.propTypes = {
  options: PropTypes.arrayOf(menuShape).isRequired,
  onChange: PropTypes.func.isRequired,
  primaryColorButton: PropTypes.bool,
  primaryHoverButton: PropTypes.bool,
  wideMenuOptions: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  primaryColorButton: false,
  primaryHoverButton: false,
  wideMenuOptions: false,
};

export default DropdownMenu;
