import { defineMessages } from 'react-intl';
import _forEach from 'lodash/forEach';
import _lastIndexOf from 'lodash/lastIndexOf';

const messages = defineMessages({
  cannotBeEmpty: 'Pole nie może być puste',
  weightMustBeUnique: 'Waga musi być unikalna',
  valueTooLong: 'Waga pola zbyt długa',
});

export default (value, withoutUniqueWeights) => {
  const result = {
    fields: {},
  };

  result.isValid = true;

  _forEach(value, (el, index) => {
    if (el === null || el === undefined || el.toString().length === 0) {
      result.fields[`weight-${index}`] = messages.cannotBeEmpty;
      result.isValid = false;
    } else if (el.toString().length > 10) {
      result.fields[`weight-${index}`] = messages.valueTooLong;
      result.isValid = false;
    } else if (value.indexOf(el) !== _lastIndexOf(value, el) && !withoutUniqueWeights) {
      result.fields[`weight-${index}`] = messages.weightMustBeUnique;
      result.isValid = false;
    }
  });

  return result;
};
