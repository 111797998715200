import _isNaN from 'lodash/isNaN';
import _isNumber from 'lodash/isNumber';

import getNumberFromValueWithComma from 'utils/getNumberFromValueWithComma';

export const formatValue = (value, intl, priceFormatter) => {
  const { currency, digits } = priceFormatter;
  const isValueNaN = _isNaN(value) || (!_isNumber(value) && !value);
  const finalValue = getNumberFromValueWithComma(value);

  if (isValueNaN) {
    return '';
  }

  const formattedNumber = intl.formatNumber(finalValue, digits);
  const formattedValue = `${formattedNumber} ${currency}`;

  return formattedValue;
};
