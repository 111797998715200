import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _some from 'lodash/some';
import _values from 'lodash/values';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _isNumber from 'lodash/isNumber';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';

const mapArrayToIdObject = (data) => _keyBy(data, 'id');

const getPropertyValue = (value) => (_isNumber(value) ? value : '');

const sortingFieldNames = {
  answerDifficulties: ['question', 'weight', 'group'],
  scaleQuestions: ['scale', 'question', 'group'],
  questionParameters: ['question', 'group'],
  scaleParameters: ['scale', 'group'],
  scaleLoads: ['parentScale', 'parentScale', 'group'],
  scaleCovariances: ['scaleA', 'scaleB', 'group'],
};

const mapDifficulties = (
  difficulties, questionParameters, surveyToolQuestions, questions, referenceGroups,
) => _map(difficulties,
  (el) => {
    const questionParameterId = el.question_parameter_id;
    const referenceGroupId = questionParameters[questionParameterId].reference_group_id;
    const surveyToolQuestionId = questionParameters[questionParameterId].survey_tool_question_id;
    const questionId = surveyToolQuestions[surveyToolQuestionId].question_id;

    const groupCode = referenceGroups[referenceGroupId].code;
    const questionCode = questions[questionId].pivot.label;

    return ({
      apiId: el.id,
      cells: [
        {
          id: 'group',
          data: groupCode,
        },
        {
          id: 'question',
          data: questionCode,
        },
        {
          id: 'weight',
          data: getPropertyValue(el.weight),
        },
        {
          id: 'difficulty',
          data: getPropertyValue(el.difficulty),
          editable: true,
        },
      ],
    }
    );
  });

const mapQuestionParameters = (
  questionParameters, surveyToolQuestions, questions, referenceGroups,
) => _map(questionParameters,
  (el) => {
    const questionId = surveyToolQuestions[el.survey_tool_question_id].question_id;
    const groupCode = referenceGroups[el.reference_group_id].code;
    const questionCode = questions[questionId].pivot.label;

    return (
      {
        apiId: el.id,
        cells: [
          {
            id: 'group',
            data: groupCode,
          },
          {
            id: 'question',
            data: questionCode,
          },
          {
            id: 'residualVariance',
            data: getPropertyValue(el.residual_variance),
            editable: true,
          },
          {
            id: 'bottomAsymptote',
            data: getPropertyValue(el.bottom_asymptote),
            editable: true,
          },
          {
            id: 'topAsymptote',
            data: getPropertyValue(el.top_asymptote),
            editable: true,
          },
        ],
      }
    );
  });

const mapScaleParameters = (
  scaleParameters, scales, referenceGroups,
) => _map(scaleParameters,
  (el) => {
    const groupCode = referenceGroups[el.reference_group_id].code;
    const scaleCode = scales[el.scale_id].code;

    return ({
      apiId: el.id,
      validators: [{
        fieldName: 'empiricalVariance',
        validators: [{
          validatorName: 'greaterThan',
          parameters: [0],
        }],
      }],
      cells: [
        {
          id: 'scale',
          data: scaleCode,
        },
        {
          id: 'group',
          data: groupCode,
        },
        {
          id: 'expectedValue',
          data: getPropertyValue(el.expected_value),
          editable: true,
        },
        {
          id: 'residualVariance',
          data: getPropertyValue(el.residual_variance),
          editable: true,
        },
        {
          id: 'empiricalVariance',
          data: getPropertyValue(el.empirical_variance),
          editable: true,
        },
      ],
    }
    );
  });

const mapScaleLoads = (
  scaleLoads, scaleParameters, scales, groups,
) => _map(scaleLoads,
  (el) => {
    const parentScaleId = scaleParameters[el.parent_scale_id].scale_id;
    const childScaleId = scaleParameters[el.child_scale_id].scale_id;

    const parentScaleCode = scales[parentScaleId].code;
    const childScaleCode = scales[childScaleId].code;

    const parentScaleReferenceGroupId = scaleParameters[el.parent_scale_id].reference_group_id;
    const groupCode = groups[parentScaleReferenceGroupId].code;

    return ({
      apiId: el.id,
      cells: [
        {
          id: 'parentScale',
          data: parentScaleCode,
        },
        {
          id: 'childScale',
          data: childScaleCode,
        },
        {
          id: 'group',
          data: groupCode,
        },
        {
          id: 'load',
          data: getPropertyValue(el.charge),
          editable: true,
        },
      ],
    }
    );
  });

const mapScaleCovariances = (
  scaleCovariances, scaleParameters, scales, groups,
) => _map(scaleCovariances,
  (el) => {
    const scaleAId = scaleParameters[el.scale_a_id].scale_id;
    const scaleBId = scaleParameters[el.scale_b_id].scale_id;

    const scaleACode = scales[scaleAId].code;
    const scaleBCode = scales[scaleBId].code;

    const scaleAReferenceGroupId = scaleParameters[el.scale_a_id].reference_group_id;
    const groupCode = groups[scaleAReferenceGroupId].code;

    return ({
      apiId: el.id,
      cells: [
        {
          id: 'scaleA',
          data: scaleACode,
        },
        {
          id: 'scaleB',
          data: scaleBCode,
        },
        {
          id: 'group',
          data: groupCode,
        },
        {
          id: 'covariance',
          data: getPropertyValue(el.covariance),
          editable: true,
        },
      ],
    }
    );
  });

const mapDiscrimiations = (
  scaleQuestions,
  surveyToolQuestions,
  questions,
  referenceGroups,
  scaleParameters,
  scales,
) => _map(scaleQuestions,
  (el) => {
    const scaleParameter = scaleParameters[el.scale_parameters_id];
    const questionId = surveyToolQuestions[el.survey_tool_question_id].question_id;
    const referenceGroupId = scaleParameter.reference_group_id;
    const scaleId = scaleParameter.scale_id;

    const groupCode = referenceGroups[referenceGroupId].code;
    const questionCode = questions[questionId].pivot.label;
    const scaleCode = scales[scaleId].code;

    return ({
      apiId: el.id,
      cells: [
        {
          id: 'scale',
          data: scaleCode,
        },
        {
          id: 'group',
          data: groupCode,
        },
        {
          id: 'question',
          data: questionCode,
        },
        {
          id: 'discrimination',
          data: getPropertyValue(el.discrimination),
          editable: true,
        },
      ],
    }
    );
  });

const isAnyFilled = (items) => _some(_values(items), (el) => !_isEmpty(el.data.items));

const getMessageFromApi = (error) => {
  if (error && error.error && error.error.errors && !_isEmpty(error.error.errors.import)) {
    const errorMessages = error.error.errors.import;

    return _reduce(errorMessages, (res, value) => (res + value));
  }
  return null;
};

const sortByFieldNames = (tableData, fieldNames) => _sortBy(
  tableData,
  _map(
    fieldNames,
    (fieldName) => (table) => _find(
      table.cells,
      (el) => el.id === fieldName,
    ).data,
  ),
);

const getPsychometricModelData = (
  questionsById,
  referenceGroupsById,
  parameters,
) => {
  const questionParametersById = mapArrayToIdObject(parameters.questionParameters);
  const surveyToolQuestionsById = mapArrayToIdObject(parameters.surveyToolQuestions);
  const scaleParametersById = mapArrayToIdObject(parameters.scaleParameters);
  const scalesById = mapArrayToIdObject(parameters.scales);

  const unsortedAnswerDifficulties = mapDifficulties(
    parameters.answerDifficulties,
    questionParametersById,
    surveyToolQuestionsById,
    questionsById,
    referenceGroupsById,
  );

  const answerDifficulties = sortByFieldNames(
    unsortedAnswerDifficulties,
    sortingFieldNames.answerDifficulties,
  );

  const unsortedScaleQuestions = mapDiscrimiations(
    parameters.scaleQuestions,
    surveyToolQuestionsById,
    questionsById,
    referenceGroupsById,
    scaleParametersById,
    scalesById,
  );

  const scaleQuestions = sortByFieldNames(
    unsortedScaleQuestions,
    sortingFieldNames.scaleQuestions,
  );

  const unsortedQuestionParameters = mapQuestionParameters(
    parameters.questionParameters,
    surveyToolQuestionsById,
    questionsById,
    referenceGroupsById,
  );

  const questionParameters = sortByFieldNames(
    unsortedQuestionParameters,
    sortingFieldNames.questionParameters,
  );

  const unsortedScaleParameters = mapScaleParameters(
    parameters.scaleParameters,
    scalesById,
    referenceGroupsById,
  );

  const scaleParameters = sortByFieldNames(
    unsortedScaleParameters,
    sortingFieldNames.scaleParameters,
  );

  const unsortedScaleLoads = mapScaleLoads(
    parameters.scaleLoads,
    scaleParametersById,
    scalesById,
    referenceGroupsById,
  );

  const scaleLoads = sortByFieldNames(
    unsortedScaleLoads,
    sortingFieldNames.scaleLoads,
  );

  const unsortedScaleCovariances = mapScaleCovariances(
    parameters.scaleCovariances,
    scaleParametersById,
    scalesById,
    referenceGroupsById,
  );

  const scaleCovariances = sortByFieldNames(
    unsortedScaleCovariances,
    sortingFieldNames.scaleCovariances,
  );

  return ({
    answerDifficulties,
    scaleQuestions,
    questionParameters,
    scaleParameters,
    scaleLoads,
    scaleCovariances,
  });
};

export {
  mapArrayToIdObject,
  getPsychometricModelData,
  isAnyFilled,
  getMessageFromApi,
};
