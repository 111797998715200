import _includes from 'lodash/includes';

import makeStyles from '@material-ui/styles/makeStyles';

import pagesWithFullWidth from 'utils/constants/pagesWithFullWidth';
import config from 'config';

export const headerHeight = 64;

export const getMenuWidth = (isCollapsed, theme) => (
  isCollapsed ? theme.sizes.drawer.collapsedWidth : theme.sizes.drawer.width
);

export const getAdditionalInfoMargin = (withDropdownMenu, isCollapsedMenu, theme) => {
  if (
    window.innerWidth < (
      getMenuWidth(isCollapsedMenu, theme)
      + theme.spacing(3)
      + theme.sizes.cardContent.width
    )
  ) {
    return theme.spacing(0.5);
  }
  return withDropdownMenu ? 274 : 185;
};

export const getHeaderMaxWidth = (bodyWidth, isCollapsedMenu, theme) => (
  bodyWidth - (getMenuWidth(isCollapsedMenu, theme))
);

export const getWrapperPadding = (isCollapsedMenu, theme, isLeft = false, bodyWidth) => {
  const menuWidth = getMenuWidth(isCollapsedMenu, theme);

  const withFullWidth = _includes(pagesWithFullWidth, window.location.pathname);
  const cardContentWidth = theme.sizes.cardContent.width;

  const scrollCompensation = `(${theme.sizes.scrollbar.width}px - (100vw - 100%))`;

  const isNarrowerThanCard = (
    !withFullWidth && bodyWidth < (menuWidth + theme.sizes.cardContent.width + theme.spacing(3)))
    || (withFullWidth && bodyWidth < (menuWidth + config.fullWidthPageCard + theme.spacing(3)));

  if (isLeft && isNarrowerThanCard) {
    return theme.spacing(3);
  }

  if (!isLeft && isNarrowerThanCard) {
    return `calc(${theme.spacing(3)}px + ${scrollCompensation})`;
  }

  if (!withFullWidth) {
    return isLeft ? `calc((${bodyWidth}px - ${menuWidth}px - ${cardContentWidth}px - ${scrollCompensation}) / 2)`
      : `calc((${bodyWidth}px - ${menuWidth}px - ${cardContentWidth}px + ${scrollCompensation}) / 2 )`;
  }

  return isLeft ? theme.spacing(3) : `calc(${theme.spacing(3)}px + ${scrollCompensation})`;
};

export default makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    alignSelf: 'flex-start',
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: 20,
    color: theme.palette.text.primary,
    marginLeft: ({ titleMargin }) => titleMargin,
    height: '100%',
    lineHeight: '22px',
    margin: 'auto',
  },
  subtitle: {
    fontSize: 16,
    color: theme.palette.text.primary,
    lineHeight: '18px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    flexGrow: 1,
    alignSelf: ({ isSubtitleLowered }) => (isSubtitleLowered ? 'flex-end' : 'center'),
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    flexGrow: 1,
  },
  disableShrink: {
    flexShrink: 0,
  },
  additionalInfoWrapper: {
    marginRight: ({ withDropdownMenu, isCollapsedMenu }) => (
      getAdditionalInfoMargin(withDropdownMenu, isCollapsedMenu, theme)
    ),
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: headerHeight,
  },
  stickyWrapper: {
    position: 'fixed',
    top: 0,
    zIndex: 3,
    width: '100%',
    backgroundColor: theme.palette.grey.background,
  },
  headerShadow: {
    boxShadow: '0px 2px 5px 1px rgba(22, 17, 59, 0.05), 0px 2px 15px 2px rgba(22, 17, 59, 0.03)',
  },
  iconButton: {
    '&.MuiIconButton-root': {
      padding: 6,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '& .MuiSvgIcon-colorPrimary': {
        '&:hover': {
          color: '#06A285',
        },
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
  },
  buttonSpan: {
    width: 'fit-content',
    marginRight: theme.spacing(1.5),
  },
  tooltip: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    borderRadius: 4,
    padding: theme.spacing(0.5, 1),
    top: 6,
  },
}));
