import { push } from 'connected-react-router';
import _get from 'lodash/get';

import errorCatch from 'utils/errorCatch';
import ApiManager from 'utils/ApiManager';
import { ACTIVE_ID, INACTIVE_ID } from 'utils/constants/statuses';
import snackbarMessages from 'utils/snackbarMessages';

import {
  hideLoaderMultipleRequests,
  showLoaderMultipleRequests,
  hideLoader,
  showSnackbar,
  showTransparentLoader,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  isActive: false,
  profileValues: {
    id: null,
    name: '',
    surname: '',
    specialization: '',
    email: '',
    password: '',
    roleId: '',
    status: '',
  },
};

const mapDataToValues = (data, specializations) => ({
  id: data.id,
  name: data.first_name || '',
  surname: data.last_name || '',
  specialization: data.specialization_id ? specializations[data.specialization_id - 1].name : '',
  email: data.email || '',
  roleId: data.role_id || '',
  status: data.status || '',
});

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'DIAGNOSTICIAN_VIEW/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'DIAGNOSTICIAN_VIEW/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        isActive: _get(action, 'responses.data.status', '') === ACTIVE_ID,
        profileValues: mapDataToValues(action.responses.data, action.specializations.data),
        initialUserValues: action.responses.data,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, specializations) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  specializations,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadUserData = (id) => (dispatch) => ApiManager.request('get', dispatch, `users/${id}`);

const loadSpecialization = () => (dispatch) => ApiManager.request('get', dispatch, 'specializations?orderBy=id&sortedBy=asc');

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/diagnosticianAddEdit', { id }));
};

export const onToggleActivate = (id, isActive) => (dispatch, getStore) => {
  const { initialUserValues } = getStore().Diagnostician;
  const requiredFields = {
    first_name: initialUserValues.first_name,
    last_name: initialUserValues.last_name,
    email: initialUserValues.email,
    role_id: initialUserValues.role_id,
    specialization_id: initialUserValues.specialization_id,
  };

  const data = {
    ...requiredFields,
    status: isActive ? INACTIVE_ID : ACTIVE_ID,
  };

  dispatch(showTransparentLoader());

  ApiManager.request('put', dispatch, `users/${id}`, data).then(
    () => {
      dispatch(hideLoader());
      dispatch(showSnackbar(snackbarMessages.userEditedSuccessfully));
      dispatch(loadPageData({ id })); // eslint-disable-line no-use-before-define
    },
  ).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
  });
};

export const loadPageData = (routeState) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoaderMultipleRequests());

  try {
    const specializations = await dispatch(loadSpecialization());
    const data = await dispatch(loadUserData(routeState.id));
    dispatch(loadPageSuccess(data, specializations));
  } catch (error) {
    errorCatch(error, dispatch, false, false);
  } finally {
    dispatch(hideLoaderMultipleRequests());
  }
};
