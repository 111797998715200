import React from 'react';
import PropTypes from 'prop-types';
import checkPermissions from 'utils/checkPermissions';
import NoPermissionsPage from 'components/NoPermissionsPage';

const ProtectedByPermissions = ({
  children, requiredPermission, permissionsOperator, withNoPermissionsPage,
}) => {
  const isPermitted = checkPermissions(requiredPermission, permissionsOperator);
  const noPermissionsComponent = withNoPermissionsPage ? <NoPermissionsPage /> : null;

  return isPermitted ? children : noPermissionsComponent;
};

ProtectedByPermissions.propTypes = {
  permissionsOperator: PropTypes.oneOf(['AND', 'OR']),
  requiredPermission: PropTypes.arrayOf(PropTypes.bool),
  withNoPermissionsPage: PropTypes.bool,
};

ProtectedByPermissions.defaultProps = {
  requiredPermission: [],
  permissionsOperator: 'AND',
  withNoPermissionsPage: false,
};

export default ProtectedByPermissions;
