import { defineMessages } from 'react-intl';
import _forEach from 'lodash/forEach';

const messages = defineMessages({
  cannotBeEmpty: 'Pole nie może być puste',
});

export default (value, fieldName) => {
  const result = {
    fields: {},
  };

  result.isValid = true;

  _forEach(value, (el, index) => {
    const fieldValue = el[fieldName];
    if (fieldValue === null || fieldValue === undefined || fieldValue.toString().length === 0) {
      result.fields[`${fieldName}-${index}`] = messages.cannotBeEmpty;
      result.isValid = false;
    }
  });

  return result;
};
