import { push } from 'connected-react-router';

import errorCatch from 'utils/errorCatch';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import { SUSPENDED } from 'utils/constants/examinationStatuses';

import {
  hideLoader,
  showLoader,
  showSnackbar,
  setDrawerVisibility,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  patientID: null,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'RESEARCH_STARTED_ON_TABLET/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'RESEARCH_STARTED_ON_TABLET/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        patientID: action.routeState.patientID,
        sessionPatientID: action.routeState.sessionPatientsID,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (routeState) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  routeState,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onPreview = () => (dispatch, getStore) => {
  const sessionPatientId = getStore().ResearchStartedOnTablet.sessionPatientID;

  ApiManager.request('get', dispatch, `session_patients/${sessionPatientId}`).then((response) => {
    if (response.data.status === SUSPENDED) {
      dispatch(showSnackbar(snackbarMessages.examinationSuspended));
    } else {
      dispatch(push('/surveyAnalysis', { sessionPatientId, withInterval: true }));
    }
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
  });
};

export const onSubmit = () => (dispatch, getStore) => {
  const id = getStore().ResearchStartedOnTablet.patientID;

  dispatch(push('/patientView', { id, isFromTablet: true }));
};

export const loadPageData = (routeState) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: routeState.toolInfo.researchName,
    onCloseCallback: () => dispatch(push('/patientView', { id: routeState.patientID, isFromTablet: true })),
  }));

  try {
    dispatch(loadPageSuccess(routeState));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
