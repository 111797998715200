import React from 'react';
import PropTypes from 'prop-types';

import CustomPlayer from 'components/CustomPlayer';
import Modal from 'components/Modal';

import useStyle from './VideoPlayer.styles';

const VideoPlayer = ({
  onCancel,
  type,
  url,
  avoidScroll,
}) => {
  const classes = useStyle();

  return (
    <Modal
      isOpen={!!type}
      onRequestClose={onCancel}
      dynamicBox
      avoidScroll={avoidScroll}
    >
      <CustomPlayer url={url} className={classes.videoPlayer} />
    </Modal>
  );
};

VideoPlayer.propTypes = {
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  avoidScroll: PropTypes.bool,
};

VideoPlayer.defaultProps = {
  avoidScroll: true,
};

export default VideoPlayer;
