import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  textareaRoot: {
    padding: theme.spacing(0, 1.5),
    fontSize: 14,
    lineHeight: '20px',
    height: 112,
  },
  textareaTitle: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.fadeTextPrimary,
    marginBottom: theme.spacing(1),
  },
  inputMultiline: {
    marginTop: theme.spacing(-1.25),
  },
  notchedOutline: {
    border: `1px solid ${theme.palette.grey.outlinedInputBorder}`,
  },
  fileWrapper: {
    marginTop: theme.spacing(0.5),
  },
}));
