import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  text: {
    minWidth: 'auto',
    padding: 0,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.white,
    },
  },
  buttonMargin: {
    marginRight: 12,
  },
}));
