export default (theme) => ({
  textareaRoot: {
    padding: theme.spacing(1, 1.625),
  },
  footer: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: theme.spacing(3),
    lineHeight: '20px',
  },
  textareaTitle: {
    fontSize: 14,
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.fadeTextPrimary,
    marginBottom: theme.spacing(1),
  },
});
