import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    name: {
      lineHeight: '22px',
    },
    wrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    maxWidth: {
      maxWidth: 'calc(100% - 36px)',
    },
    tooltip: {
      ...theme.tooltip,
      lineHeight: '16px',
      marginBottom: theme.spacing(1, 0),
      top: 6,
    },
    icon: {
      boxSizing: 'initial',
      fontSize: 18,
      marginLeft: 8,
      color: theme.palette.orange,
      marginBottom: 4,
    },
  }),
);
