import { push, goBack } from 'connected-react-router';
import _get from 'lodash/get';
import _first from 'lodash/first';
import config from 'config';

import { CREATED } from 'utils/constants/examinationStatuses';
import errorCatch from 'utils/errorCatch';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import dialogTexts from 'utils/dialogTexts';

import {
  hideLoader,
  showLoader,
  setDrawerVisibility,
  showTransparentLoader,
  showSnackbar,
  logout,
  openDialog,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  areCredits: null,
  continueWithoutCredits: false,
  surveyToolID: null,
  patientID: null,
  sessionPatientsID: null,
  researchName: '',
  userWithEmail: false,
  unlimitedUsage: false,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'RESEARCH_SELECT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'RESEARCH_SELECT/CLEAR_STORE',
  CONTINUE_WITHOUT_CREDITS: 'RESEARCH_SELECT/CONTINUE_WITHOUT_CREDITS',
  SHOW_RESEARCH_LOADER: 'RESEARCH_SELECT/SHOW_RESEARCH_LOADER',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        areCredits: action.routeState.toolInfo.hasEnoughTokens,
        surveyToolID: action.routeState.toolInfo.apiId,
        researchName: action.routeState.toolInfo.researchName,
        patientID: action.routeState.patientID || null,
        sessionPatientsID: action.routeState.toolInfo.sessionPatientsID,
        userWithEmail: action.routeState.userWithEmail,
        unlimitedUsage: action.routeState.unlimitedUsage,
      };
    }

    case actionTypes.CONTINUE_WITHOUT_CREDITS: {
      return {
        ...state,
        continueWithoutCredits: true,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (routeState) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  routeState,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const continueWithoutCredits = () => ({
  type: actionTypes.CONTINUE_WITHOUT_CREDITS,
});

export const onCancel = () => (dispatch) => {
  dispatch(clearStore());
  dispatch(goBack());
};

const getToken = () => (dispatch, getStore) => {
  const { id } = getStore().Global.userData;

  return ApiManager.request('post', dispatch, 'users/token', { user_id: id });
};

const getRequestDataBySession = (
  patientID, surveyToolID, sessionPatientsID, isRemote,
) => (dispatch) => {
  if (sessionPatientsID) {
    const data = {
      status: CREATED,
      note: 'note',
    };

    return ApiManager.request('put', dispatch, `session_patients/${sessionPatientsID}`, data);
  }

  const data = {
    patient_id: patientID,
    survey_tool_id: surveyToolID,
    note: '',
    is_remote: isRemote,
  };

  return ApiManager.request('post', dispatch, 'session_patients', data);
};

const onTabletSelect = () => async (dispatch, getStore) => {
  const {
    sessionPatientsID,
    patientID,
    surveyToolID,
    researchName,
  } = getStore().ResearchSelect;

  dispatch(showTransparentLoader());

  try {
    const sessionPatient = await dispatch(getRequestDataBySession(
      patientID, surveyToolID, sessionPatientsID,
    ));
    dispatch(push('/researchStartedOnTablet', {
      patientID,
      toolInfo: { researchName },
      sessionPatientsID: sessionPatientsID || sessionPatient.data.id,
    }));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
  }
};

const onLinkSelect = () => async (dispatch, getStore) => {
  const {
    sessionPatientsID,
    patientID,
    surveyToolID,
  } = getStore().ResearchSelect;

  dispatch(showTransparentLoader());
  try {
    await dispatch(getRequestDataBySession(patientID, surveyToolID, sessionPatientsID, true));

    dispatch(showSnackbar(snackbarMessages.emailSent));
    dispatch(push('/patientView', { id: patientID }));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
  }
};

const onComputerSelect = () => async (dispatch, getStore) => {
  const { patientID, surveyToolID, sessionPatientsID } = getStore().ResearchSelect;

  dispatch(showTransparentLoader());

  try {
    await dispatch(getRequestDataBySession(patientID, surveyToolID, sessionPatientsID));
    const token = await dispatch(getToken());
    dispatch(logout(() => {
      window.location.replace(`${config.clientAppUrl}/login/${token.data.token}`);
    }));
  } catch (error) {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
  }
};

const startExamination = (version) => (dispatch) => {
  if (version === 'tablet') {
    dispatch(onTabletSelect());
  } else if (version === 'link') {
    dispatch(onLinkSelect());
  } else {
    dispatch(onComputerSelect());
  }
};

const checkIfExaminationIsInProgress = (id) => (dispatch) => (
  ApiManager.request('get', dispatch, `patients/${id}`).then((response) => Promise.resolve(
    _get(response, 'data.has_at_least_one_not_remote_session_patient_with_status_started', false),
  )).catch((error) => {
    errorCatch(error, dispatch);
  })
);

export const onResearchStart = (version) => (dispatch, getStore) => {
  const { patientID } = getStore().ResearchSelect;
  dispatch(checkIfExaminationIsInProgress(patientID)).then((isExaminationInProgress) => {
    if (isExaminationInProgress) {
      dispatch(openDialog({
        title: dialogTexts.abandonCurrentExamination,
        onAccept: () => {
          dispatch(startExamination(version));
        },
        onDecline: () => {
          dispatch(push('/patientView', { id: patientID }));
        },
      }));
    } else {
      dispatch(startExamination(version));
    }
  });
};

export const onSubmit = () => (dispatch) => {
  dispatch(continueWithoutCredits());
};

export const loadPageData = (routeState) => async (dispatch, getStore) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: routeState.toolInfo.researchName,
  }));
  const unlimitedUsage = _get(_first(getStore().Global.userData.clinics), 'unlimited_usage');

  if (!_get(routeState, 'toolInfo.apiId', null)) {
    dispatch(goBack());
  }

  try {
    dispatch(loadPageSuccess({ ...routeState, unlimitedUsage }));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
