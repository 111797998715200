import React from 'react';
import _isUndefined from 'lodash/isUndefined';
import _pickBy from 'lodash/pickBy';
import _keys from 'lodash/keys';

import getFromMeta from 'utils/getFromMeta';

import TableCell from 'components/TableCell';
import NoItems from './components/NoItems';
import Truncate from './components/Truncate';
import LabelWithIcon from './components/LabelWithIcon';
import ExaminationStatus from './components/ExaminationStatus';

const hasWhiteSpace = (text) => /\s/g.test(text);

const renderFieldInWrapper = (content, fieldConfig, withMiddleVerticalAlign, isGreyedOut) => (
  <TableCell
    truncate={!hasWhiteSpace(content)}
    noWrap={!fieldConfig.wrap}
    withMiddleVerticalAlign={withMiddleVerticalAlign}
    key={fieldConfig.id}
    className={fieldConfig.bodyCellClass || fieldConfig.class}
    isGreyedOut={isGreyedOut}
  >
    {content}
  </TableCell>
);

const renderField = (fieldConfig, row, withMiddleVerticalAlign) => {
  let content = null;
  const itemValue = row[fieldConfig.id];

  switch (fieldConfig.display) {
    case 'getFromMeta': {
      content = getFromMeta(itemValue, fieldConfig.additionalField);
      break;
    }

    case 'examinationStatus': {
      content = <ExaminationStatus status={itemValue} />;
      break;
    }

    case 'truncate': {
      content = <Truncate>{itemValue}</Truncate>;
      break;
    }

    case 'withIcon': {
      content = (
        <LabelWithIcon
          label={fieldConfig.truncate ? <Truncate>{itemValue}</Truncate> : itemValue}
          icon={fieldConfig.icon}
          onClick={() => { fieldConfig.onIconClick(row.id); }}
          visibleIcon={row.visibleIcon}
          additionalIcons={row.icons}
        />
      );
      break;
    }

    default: {
      content = itemValue;
      break;
    }
  }

  return (
    !_isUndefined(itemValue)
     && renderFieldInWrapper(content, fieldConfig, withMiddleVerticalAlign, row.isGreyedOut)
  );
};

const singleActionIconWidth = 35;
const singleCustomActionWidth = 135;

const getLengthOfActions = (actions) => (
  actions.isCustomAction
    ? singleCustomActionWidth
    : _keys(_pickBy(actions)).length * singleActionIconWidth
);

const getNewHead = (head, classes, withIdCell, withActions, actionsHeaderLabel) => {
  let newHead = head;

  if (withActions) {
    newHead = [
      ...head,
      {
        id: 'actions',
        label: actionsHeaderLabel,
        sortable: false,
        class: classes.actionsCell,
      },
    ];
  }

  if (withIdCell) {
    newHead = [
      {
        id: 'id',
        label: '#',
        class: classes.numberCell,
        sortable: false,
      },
      ...newHead,
    ];
  }

  return newHead;
};

const getNoItemsComponent = (component, message, imgSrc) => {
  const fallback = message ? <NoItems message={message} imgSrc={imgSrc} /> : null;

  return component || fallback;
};

export {
  renderField,
  renderFieldInWrapper,
  getLengthOfActions,
  getNewHead,
  getNoItemsComponent,
};
