import formatDate from 'utils/formatDate';
import messages from './messages';

export const formatReportName = (startDate, endDate, intl) => {
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const nameBeginning = intl.formatMessage(messages.report);

  return `${nameBeginning} ${formattedStartDate} - ${formattedEndDate}`;
};
