import React from 'react';
import { replace } from 'connected-react-router';
import moment from 'moment';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _pick from 'lodash/pick';
import _get from 'lodash/get';

import {
  showLoader, hideLoader,
  setPage, setRowsPerPage,
  showTransparentLoader,
} from 'containers/store';

import config from 'config';
import ApiManager from 'utils/ApiManager';
import errorCatch from 'utils/errorCatch';
import createURLWithQuery from 'utils/createURLWithQuery';
import roles from 'utils/constants/rolesOfUsers';
import getDateFromApi from 'utils/getDateFromApi';
import formatCodeWithName from 'utils/formatCodeWithName';
import formatFullName from 'utils/formatFullName';
import { FormattedMessage } from 'react-intl';
import { formatReportName } from './utils';
import { onExportClick } from './ReportPdf';
import messages from './messages';

export const initialState = {
  isLoadedPage: false,
  clinicId: null,
  startDate: moment().startOf('month'),
  endDate: moment(),
  testCount: 0,
  mostUsed: '',
  diagnostian: '',
  clinicName: '',
  testCountData: [],
  totalItemsCount: 0,
  historyOfUseFullData: [],
  filterValues: {
    batteryName: [],
    researchName: [],
    diagnostician: [],
  },
};

const mapBatteriesToSessionCount = (batteries) => _map(batteries, (battery) => ({
  mainDimension: {
    id: battery.id,
    name: formatCodeWithName(battery),
    count: battery.calculated_sessions_count_in_clinic,
  },
  subDimensions: _map(battery.survey_tools, (tool) => ({
    id: tool.id,
    name: formatCodeWithName(tool),
    count: tool.calculated_sessions_count_in_clinic,
  })),
}));

const mapPatients = (patients) => _map(patients, (patient) => ({
  id: patient.id,
  date: getDateFromApi(patient.created_at),
  batteryName: formatCodeWithName(patient.session.survey_tool.battery),
  researchName: formatCodeWithName(patient.session.survey_tool),
  diagnostician: formatFullName(patient.session.user, true),
  patient: formatFullName(patient.patient)
  || <FormattedMessage {...messages.anonymousClient} values={{ id: patient.patient.id }} />,
}));

const mapToFilterValues = (response, initialValues, nameFormatter, isReverted) => (
  _map(response.data.items, (item) => ({
    id: item.id,
    name: nameFormatter ? nameFormatter(item, isReverted) : item.name,
    isChecked: Boolean(initialValues) && _includes(initialValues, String(item.id)),
  }))
);

const mapFilters = (values) => _map(_filter(values, 'isChecked'), 'id');

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'STATISTICS/LOAD_PAGE_SUCCESS',
  SET_DATE: 'STATISTICS/SET_DATE',
  SET_ALL_FILTERS: 'STATISTICS/SET_ALL_FILTERS',
  SET_FILTERS: 'STATISTICS/SET_FILTERS',
  UPDATE_PATIENTS: 'STATISTICS/UPDATE_PATIENTS',
  CLEAR_STORE: 'STATICTCS/CLEAR_STORE',
  LOAD_WITHOUT_DATA: 'STATICTCS/LOAD_WITHOUT_DATA',
  UPDATE_ALL_PATIENTS: 'STATICTCS/UPDATE_ALL_PATIENTS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        withoutData: false,
        clinicId: action.clinicId,
        clinicName: action.clinicName,
        testCount: action.clinicReport.session_patients_count,
        mostUsed: action.clinicReport.most_frequently_used_survey_tool ? formatCodeWithName(action.clinicReport.most_frequently_used_survey_tool) : '',
        diagnostian: action.clinicReport.most_active_diagnostician,
        testCountData: mapBatteriesToSessionCount(action.batteries.data.items),
        historyOfUseData: mapPatients(action.patients.items),
        totalItemsCount: action.patients.total,
      };
    }

    case actionTypes.SET_DATE: {
      return {
        ...state,
        startDate: action.startDate || state.startDate,
        endDate: action.endDate || state.endDate,
      };
    }

    case actionTypes.LOAD_WITHOUT_DATA: {
      return {
        ...state,
        isLoadedPage: true,
        withoutData: true,
      };
    }

    case actionTypes.SET_ALL_FILTERS: {
      return {
        ...state,
        filterValues: action.filterValues,
      };
    }

    case actionTypes.SET_FILTERS: {
      return {
        ...state,
        filterValues: {
          batteryName: state.filterValues.batteryName,
          researchName: state.filterValues.researchName,
          diagnostician: state.filterValues.diagnostician,
          [action.key]: action.values,
        },
      };
    }

    case actionTypes.UPDATE_PATIENTS: {
      return {
        ...state,
        historyOfUseData: mapPatients(action.patients.items),
        historyOfUseFullData:
          !_isEmpty(action.allPatients)
            ? mapPatients(action.allPatients.items)
            : state.historyOfUseFullData,
        totalItemsCount: action.patients.total,
      };
    }

    case actionTypes.UPDATE_ALL_PATIENTS: {
      return {
        ...state,
        historyOfUseFullData: mapPatients(action.allPatients.data.items),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (
  clinicId, clinicReport, batteries, patients, clinicName,
) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  clinicId,
  clinicReport,
  batteries,
  patients,
  clinicName,
});

const loadWithoutData = () => ({
  type: actionTypes.LOAD_WITHOUT_DATA,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setDate = (startDate, endDate) => ({
  type: actionTypes.SET_DATE,
  startDate,
  endDate,
});

const setFilters = (key, values) => ({
  type: actionTypes.SET_FILTERS,
  key,
  values,
});

const setAllFilters = (filterValues) => ({
  type: actionTypes.SET_ALL_FILTERS,
  filterValues,
});

const updatePatients = (patients, allPatients) => ({
  type: actionTypes.UPDATE_PATIENTS,
  patients,
  allPatients,
});

const updateAllPatients = (allPatients) => ({
  type: actionTypes.UPDATE_ALL_PATIENTS,
  allPatients,
});

const loadClinicReport = (clinicId) => (dispatch, getStore) => {
  const { startDate, endDate } = getStore().Statistics;

  const url = createURLWithQuery(`clinics/${clinicId}/report`, {
    dateFrom: startDate.format(config.apiDateFormat),
    dateTo: endDate.format(config.apiDateFormat),
  });

  return ApiManager.request('get', dispatch, url);
};

const loadBatteries = (clinicId) => (dispatch, getStore) => {
  const { startDate, endDate } = getStore().Statistics;

  const url = createURLWithQuery('batteries', {
    clinicId,
    withSurveyToolStatuses: ['ACTIVE', 'ARCHIVED'],
    sessionsCountDateFrom: startDate.format(config.apiDateFormat),
    sessionsCountDateTo: endDate.format(config.apiDateFormat),
  });

  return ApiManager.request('get', dispatch, url);
};

const loadDiagnosticians = (findUserByClinicWithSessions) => (dispatch) => {
  const url = createURLWithQuery('users', {
    findUserByClinicWithSessions,
    findUserByRole: roles.diagnostian,
    sortedBy: 'asc',
    orderBy: 'last_name',
  });

  return ApiManager.request('get', dispatch, url);
};

const loadSurveyTools = (findByClinicId) => (dispatch, getStore) => {
  const { startDate, endDate } = getStore().Statistics;

  const url = createURLWithQuery('survey_tools', {
    findByClinicId,
    sessionsDateFrom: startDate.format(config.apiDateFormat),
    sessionsDateTo: endDate.format(config.apiDateFormat),
  });

  return ApiManager.request('get', dispatch, url);
};

const loadSessionPatients = (clinicId, fullData) => (dispatch, getStore) => {
  const { startDate, endDate, filterValues } = getStore().Statistics;
  const routeState = getStore().router.location.state;

  const { rowsPerPage: perPage, page } = getStore().Global.pagingData;
  const { batteryName, researchName, diagnostician } = filterValues;

  const filterByBatteries = mapFilters(batteryName);
  const filterBySurveyTools = mapFilters(researchName);
  const filterByUsers = mapFilters(diagnostician);

  const queryData = {
    filterByClinicId: clinicId,
    withSurveyTools: true,
    dateFrom: startDate.format(config.apiDateFormat),
    dateTo: endDate.format(config.apiDateFormat),
    perPage: fullData ? config.fullDataPagination.perPage : perPage,
    page: fullData ? config.fullDataPagination.page : page,
    hasCalculations: true,
    filterByBatteries,
    filterBySurveyTools,
    filterByUsers,
    sortedBy: 'desc',
    orderBy: 'created_at',
  };

  const url = createURLWithQuery('session_patients', queryData);
  if (!fullData) {
    const pageUrl = createURLWithQuery('statistics', _pick(
      queryData,
      ['page', 'perPage', 'dateFrom', 'dateTo', 'filterByBatteries', 'filterBySurveyTools', 'filterByUsers'],
    ));
    dispatch(replace(pageUrl, routeState));
  }

  return ApiManager.request('get', dispatch, url);
};

export const onDatesChange = ({ startDate, endDate }) => async (dispatch, getStore) => {
  const { clinicId, filterValues, clinicName } = getStore().Statistics;
  const { batteryName, researchName, diagnostician } = filterValues;
  dispatch(showTransparentLoader());
  dispatch(setDate(startDate, endDate));
  dispatch(setPage(1));

  const filterByBatteries = mapFilters(batteryName).map(String);
  const filterBySurveyTools = mapFilters(researchName).map(String);

  try {
    if (clinicId) {
      const batteriesResponse = await dispatch(loadBatteries(clinicId));
      const batteries = mapToFilterValues(batteriesResponse, filterByBatteries, formatCodeWithName);

      const surveyToolsResponse = await dispatch(loadSurveyTools(clinicId));
      const surveyTools = mapToFilterValues(
        surveyToolsResponse,
        filterBySurveyTools,
        formatCodeWithName,
      );
      dispatch(setAllFilters({
        batteryName: batteries,
        diagnostician,
        researchName: surveyTools,
      }));

      const clinicReport = await dispatch(loadClinicReport(clinicId));
      const patients = await dispatch(loadSessionPatients(clinicId));

      dispatch(loadPageSuccess(
        clinicId,
        clinicReport.data,
        batteriesResponse,
        patients.data,
        clinicName,
      ));
    } else {
      dispatch(hideLoader());
      dispatch(loadWithoutData());
    }
  } catch (error) {
    errorCatch(error, dispatch);
  } finally {
    dispatch(hideLoader());
  }
};

export const onChangePage = (_, page) => async (dispatch, getStore) => {
  dispatch(setPage(page + 1));
  const { clinicId } = getStore().Statistics;
  dispatch(showTransparentLoader());
  const patients = await dispatch(loadSessionPatients(clinicId));
  dispatch(updatePatients(patients.data));
  dispatch(hideLoader());
};

export const onChangeRowsPerPage = (event) => async (dispatch, getStore) => {
  const value = event.target.value;
  const { clinicId } = getStore().Statistics;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(showTransparentLoader());
  const patients = await dispatch(loadSessionPatients(clinicId));
  dispatch(updatePatients(patients.data));
  dispatch(hideLoader());
};

export const onFilter = (key, values) => async (dispatch, getStore) => {
  const { clinicId } = getStore().Statistics;

  dispatch(setPage(1));
  dispatch(setFilters(key, values));
  const patients = await dispatch(loadSessionPatients(clinicId));
  const allPatients = await dispatch(loadSessionPatients(clinicId, true));

  dispatch(updatePatients(patients.data, allPatients.data));
};

const getFullHistory = () => async (dispatch, getStore) => {
  const { clinicId } = getStore().Statistics;
  try {
    const allPatients = await dispatch(loadSessionPatients(clinicId, true));
    dispatch(updateAllPatients(allPatients));
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onExportReport = (theme, intl) => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  await dispatch(getFullHistory());
  dispatch(hideLoader());
  dispatch(showTransparentLoader());
  const currentStore = getStore().Statistics;
  const {
    startDate, endDate, testCountData, historyOfUseFullData,
  } = currentStore;
  const reportName = formatReportName(startDate, endDate, intl);
  const withHistoryTable = !_isEmpty(historyOfUseFullData);
  await onExportClick(theme, intl, testCountData, reportName, withHistoryTable);
  dispatch(hideLoader());
};

export const loadPageData = (routeState, values) => async (dispatch, getStore) => {
  const clinicId = routeState.clinicId || _get(getStore(), 'Global.userData.clinics[0].id', null);
  const clinicName = routeState.clinicName || _get(getStore(), 'Global.userData.clinics[0].name', null);
  dispatch(clearStore());
  dispatch(showLoader());

  if (values.page) {
    dispatch(setPage(Number(values.page)));
  }

  if (values.perPage) {
    dispatch(setRowsPerPage(Number(values.perPage)));
  }

  if (values.dateFrom && values.dateTo) {
    dispatch(setDate(moment(values.dateFrom), moment(values.dateTo)));
  }

  try {
    const clinicReport = await dispatch(loadClinicReport(clinicId));

    const batteriesResponse = await dispatch(loadBatteries(clinicId));
    const batteries = mapToFilterValues(
      batteriesResponse,
      values.filterByBatteries,
      formatCodeWithName,
    );

    const diagnosticiansResponse = await dispatch(loadDiagnosticians(clinicId));
    const diagnosticians = mapToFilterValues(
      diagnosticiansResponse,
      values.filterByUsers,
      formatFullName,
      true,
    );

    const surveyToolsResponse = await dispatch(loadSurveyTools(clinicId));
    const surveyTools = mapToFilterValues(
      surveyToolsResponse,
      values.filterBySurveyTools,
      formatCodeWithName,
    );

    dispatch(setAllFilters({
      batteryName: batteries,
      diagnostician: diagnosticians,
      researchName: surveyTools,
    }));

    const patients = await dispatch(loadSessionPatients(clinicId));
    dispatch(loadPageSuccess(
      clinicId,
      clinicReport.data,
      batteriesResponse,
      patients.data,
      clinicName,
    ));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
