import { push, replace } from 'connected-react-router';
import _get from 'lodash/get';

import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import errorCatch from 'utils/errorCatch';
import getDateTimeFromApi from 'utils/getDateTimeFromApi';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getExaminationStatus from 'utils/getExaminationStatus';
import getParamsFromQuery from 'utils/getParamsFromQuery';

import {
  hideLoader, showLoader, showTransparentLoader, setSortingAndPagination,
  setPage, setRowsPerPage, setLastFilterValues, setSortingData,
} from 'containers/store';
import { getPatientName, getPatientClassAndSection } from './utils';

const defaultSorting = {
  fieldName: 'lastResearch',
  sortDirection: 'desc',
};

const sortFieldsMapping = {
  patient: 'last_name',
  lastResearch: 'updated_at',
  status: 'status',
  classAndSection: 'class_and_section',
};

export const initialState = {
  isLoadedPage: false,
  items: [],
  anonymousPatientNamePrefix: '',
  lastQueryParams: {},
};

export const mapPatients = (patients, startingPosition) => patients.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  patient: getPatientName(el),
  classAndSection: getPatientClassAndSection(_get(el, 'school_class', null), _get(el, 'school_class_section', null)),
  lastResearch: _get(el, 'latest_session_patient.updated_at', null)
    ? getDateTimeFromApi(_get(el, 'latest_session_patient.updated_at'))
    : '',
  status: getExaminationStatus(_get(el, 'latest_session_patient', {})),
  deletedAt: el.deleted_at,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'PATIENTS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_PATIENTS: 'PATIENTS/SET_FILTERED_PATIENTS',
  SET_QUERY_PARAMS: 'PATIENTS/SET_QUERY_PARAMS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapPatients(action.responses.data.items, action.startingPosition),
        totalItemsCount: action.responses.data.total,
        anonymousPatientNamePrefix: action.anonymousPatientNamePrefix,
      };
    }

    case actionTypes.SET_FILTERED_PATIENTS: {
      return {
        ...state,
        items: mapPatients(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    case actionTypes.SET_QUERY_PARAMS: {
      return {
        ...state,
        lastQueryParams: action.queryParams,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, startingPosition, anonymousPatientNamePrefix) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  startingPosition,
  anonymousPatientNamePrefix,
});

const setFilteredPatients = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_PATIENTS,
  response,
  startCountFrom,
});

const setQueryParams = (queryParams) => ({
  type: actionTypes.SET_QUERY_PARAMS,
  queryParams,
});

export const loadPatients = (
  params, pageUrl, store = null, anonymousPatientNamePrefixInitial,
) => (dispatch, getStore) => {
  const locationState = _get(getStore(), 'router.location.state');
  const { anonymousPatientNamePrefix } = store ? getStore()[store] : getStore().Patients;
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: sortFieldsMapping[defaultSorting.fieldName],
  };

  if (params.lastFilterValues.search) {
    data.findByManyColumns = params.lastFilterValues.search;
  }

  data.onlyRelated = params.lastFilterValues.onlyRelated;

  if (store === null) {
    data.withTrashed = true;
  }

  if (params.withoutCreatedForAnonymousSessionPatientGroup) {
    data.withoutCreatedForAnonymousSessionPatientGroup = true;
  }

  if (params.sortedBy && params.orderBy) {
    if (params.orderBy === sortFieldsMapping.patient) {
      data.orderBy = params.orderBy;
    } else if (params.orderBy === sortFieldsMapping.classAndSection) {
      data.orderBySchoolClassAndSection = true;
    } else if (!store) {
      data.orderByLatestSessionPatient = params.orderBy;
    }
    data.sortedBy = params.sortedBy;
  }

  if (params.orderByLatestSessionPatient) {
    data.orderByLatestSessionPatient = params.orderBy
    || sortFieldsMapping[defaultSorting.fieldName];
  }

  if (params.orderByIdsFirst) {
    data.orderByIdsFirst = params.orderByIdsFirst;
  }

  if (params.findByManyColumns) {
    data.findByManyColumns = params.findByManyColumns;
  }

  data.anonymousPatientNamePrefix = anonymousPatientNamePrefix || anonymousPatientNamePrefixInitial;

  const url = createURLWithQuery('patients', data);
  const replacementUrl = createURLWithQuery(pageUrl, data);

  dispatch(replace(replacementUrl, locationState));

  return ApiManager.request('get', dispatch, url);
};

const getPatients = () => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  try {
    const patientData = await dispatch(loadPatients(data, 'patients'));
    dispatch(setFilteredPatients(patientData, startCountFrom));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getPatients());
};

export const onChangePage = (_, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getPatients());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const { sortedBy, orderBy } = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, sortedBy, orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getPatients());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getPatients());
};

export const saveQueryParams = () => (dispatch) => {
  const queryParams = getParamsFromQuery(window.location.search);

  dispatch(setQueryParams(queryParams));
};

export const onAdd = () => (dispatch) => {
  dispatch(saveQueryParams());
  dispatch(push('/patientAddEdit'));
};

export const onActionClick = (id) => (dispatch) => {
  dispatch(saveQueryParams());
  dispatch(push('/patientView', { id }));
};

export const loadPageData = (values, anonymousPatientNamePrefix) => async (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values) {
    dispatch(setLastFilterValues({
      search: values.findByManyColumns || '',
      onlyRelated: values.onlyRelated ? values.onlyRelated === 'true' : true,
    }));
  }
  dispatch(setQueryParams({}));

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  try {
    const patientData = await dispatch(loadPatients(data, 'patients', null, anonymousPatientNamePrefix));
    dispatch(loadPageSuccess(patientData, startingPosition, anonymousPatientNamePrefix));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};
