import redHatText from 'misc/fonts/redHatText';
import redHatTextMedium from 'misc/fonts/redHatText-medium';
import redHatTextBold from 'misc/fonts/redHatText-bold';
import { jsPDF as JsPDF } from 'jspdf';
import 'jspdf-autotable';

import {
  constants,
  getImageFromElement,
  addImage,
  getTableByData,
} from 'utils/pdfDocument';
import messages from 'components/pages/GroupExaminationDetails/GroupExaminationDetails.messages';

const {
  bottomMargin,
  topMargin,
  headerFontSize,
} = constants;

export const onExportClick = async (
  intl, theme, reportName, patientData,
) => {
  const patientColumns = [
    {
      header: intl.formatMessage(messages.patient),
      dataKey: 'patient',
    },
    {
      header: intl.formatMessage(messages.classAndSection),
      dataKey: 'classAndSection',
    },
    {
      header: intl.formatMessage(messages.examinationCode),
      dataKey: 'examinationCode',
    },
  ];

  const { image: titleImage, height: titleHeight } = await getImageFromElement('title');

  const doc = new JsPDF();
  doc.setLanguage('pl');
  doc.addFileToVFS('RedHatText.ttf', redHatText);
  doc.addFont('RedHatText.ttf', 'RedHatText', 'normal');
  doc.addFileToVFS('RedHatTextBold.ttf', redHatTextBold);
  doc.addFont('RedHatTextBold.ttf', 'RedHatText-bold', 'bold');
  doc.addFileToVFS('RedHatTextMedium.ttf', redHatTextMedium);
  doc.addFont('RedHatTextMedium.ttf', 'RedHatText-medium', 'medium');
  doc.setFont('RedHatText-medium', 'medium');
  doc.setFontSize(headerFontSize);
  doc.setTextColor(theme.palette.text.primary);

  let position = topMargin - bottomMargin;

  addImage(doc, titleImage, position, titleHeight);
  position += titleHeight;

  getTableByData(
    doc,
    position,
    bottomMargin,
    patientData,
    patientColumns,
    theme.palette.primary.main,
  );

  doc.save(`${reportName}.pdf`);
};
