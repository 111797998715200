import greaterThan from './validators/greaterThan';
import isNumber from './validators/isNumber';
import matchRegex from './validators/matchRegex';
import maxLength from './validators/maxLength';
import minLength from './validators/minLength';
import notEmpty from './validators/notEmpty';
import matchOtherField from './validators/matchOtherField';
import maxDecimalPlaces from './validators/maxDecimalPlaces';
import answersValidator from './validators/answersValidator';
import weightsValidator from './validators/weightsValidator';
import topLimitsValidator from './validators/topLimitsValidator';
import notEmptyArrayElement from './validators/notEmptyArrayElement';
import referenceGroupsValidator from './validators/referenceGroupsValidator';
import notEmptyAutocomplete from './validators/notEmptyAutocomplete';
import oneOf from './validators/oneOf';
import lowerOrEqualTo from './validators/lowerOrEqualTo';
import higherOrEqualTo from './validators/higherOrEqualTo';
import notLaterThan from './validators/notLaterThan';
import notEarlierThan from './validators/notEarlierThan';
import notLaterThanToday from './validators/notLaterThanToday';
import notEarlierThanToday from './validators/notEarlierThanToday';
import isPesel from './validators/isPesel';
import organizationsValidator from './validators/organizationsValidator';
import dimensionsValidator from './validators/dimensionsValidator';
import passwordValidator from './validators/passwordValidator';
import isCorrectDate from './validators/isCorrectDate';
import differentThan from './validators/differentThan';
import maximumDayInTheMonth from './validators/maximumDayInTheMonth';
import isTrue from './validators/isTrue';
import isNumberWithNoZero from './validators/isNumberWithNoZero';

export default {
  greaterThan,
  isNumber,
  matchRegex,
  maxLength,
  minLength,
  notEmpty,
  matchOtherField,
  maxDecimalPlaces,
  answersValidator,
  weightsValidator,
  topLimitsValidator,
  referenceGroupsValidator,
  notEmptyAutocomplete,
  oneOf,
  lowerOrEqualTo,
  higherOrEqualTo,
  notEmptyArrayElement,
  notLaterThan,
  notEarlierThan,
  notLaterThanToday,
  notEarlierThanToday,
  isPesel,
  organizationsValidator,
  dimensionsValidator,
  passwordValidator,
  isCorrectDate,
  differentThan,
  maximumDayInTheMonth,
  isTrue,
  isNumberWithNoZero,
};
