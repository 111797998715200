import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _every from 'lodash/every';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  fieldValueIsNotDifferent: 'Wartości nie mogą się powtarzać',
});

export default (value, referrenceValues) => {
  const result = {};

  result.isValid = _every(referrenceValues, (referrenceValue) => (
    _isEmpty(value) || !_isEqual(referrenceValue, value)
  ));
  result.message = messages.fieldValueIsNotDifferent;

  return result;
};
