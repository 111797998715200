import _map from 'lodash/map';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _some from 'lodash/some';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import _filter from 'lodash/filter';

import config from 'config';

import getParentScaleId from 'utils/getParentScaleId';
import formatGroupStructure from 'utils/formatGroupStructure';

const formatScaleData = (scale, withParent = false, loads = [], parameters = []) => {
  const scaleData = {
    id: scale.id,
    name: scale.name,
    code: scale.code,
    order: scale.order,
    level: scale.level,
    survey_tool_id: scale.survey_tool_id,
  };
  if (withParent) {
    scaleData.parentId = getParentScaleId(scale.id, loads, parameters);
  }

  return scaleData;
};

export const getGroupStructure = (scales, loads, parameters) => (
  formatGroupStructure(
    scales,
    loads,
    parameters,
    formatScaleData,
  )
);

const reorderDimensions = (startIndex, endIndex, dimensions) => {
  const sortedDimensions = _sortBy(dimensions, 'order');
  const [removed] = sortedDimensions.splice(startIndex, 1);
  sortedDimensions.splice(endIndex, 0, removed);

  return _map(sortedDimensions, (el, index) => ({ ...el, order: index }));
};

const getParentDimensionLevel = (dimensionId, dimensions) => {
  const convertedDimensionId = parseInt(dimensionId);
  const parentDimension = _find(dimensions, (dimension) => dimension.id === convertedDimensionId);

  return parentDimension.level;
};

export const getReorderedDimensions = (
  sourceIndex,
  destinationIndex,
  droppableId,
  dimensionGroups,
  dimensions,
) => {
  let selectedDimensions = [];
  const convertedDroppableId = parseInt(droppableId);
  if (droppableId === config.ids.scalesListMainDroppable) {
    selectedDimensions = _map(dimensionGroups, (group) => ({
      ...group.primaryDimension,
    }));
  } else {
    const parentDimensionLevel = getParentDimensionLevel(convertedDroppableId, dimensions);
    if (parentDimensionLevel === 0) {
      selectedDimensions = _get(
        _find(
          dimensionGroups,
          (group) => group.id === convertedDroppableId,
        ),
        'secondaryDimensions',
        [],
      );
    } else if (parentDimensionLevel === 1) {
      _forEach(
        dimensionGroups,
        (group) => _some(group.secondaryDimensions, (secondaryDimension) => {
          const isSelectedDimension = secondaryDimension.id === convertedDroppableId;
          if (isSelectedDimension) {
            selectedDimensions = secondaryDimension.tertiaryDimensions;
          }
          return isSelectedDimension;
        }),
      );
    }
  }

  const reorderedDimensions = reorderDimensions(sourceIndex, destinationIndex, selectedDimensions);

  return reorderedDimensions;
};

export const getUniqueDimensions = (oldDimensions, newReorderedDimensions) => {
  const newDimensions = [...newReorderedDimensions, ...oldDimensions];

  return _uniqBy(newDimensions, 'id');
};

export const hasEmptyName = (dimensions) => (
  _filter(dimensions, (item) => item.name === '').length !== 0
);
