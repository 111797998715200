import { push, replace } from 'connected-react-router';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _omit from 'lodash/omit';
import _includes from 'lodash/includes';

import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import errorCatch from 'utils/errorCatch';
import formatFullName from 'utils/formatFullName';
import { diagnosticianRoles } from 'utils/constants/rolesOfUsers';

import {
  hideLoader, showLoader, showTransparentLoader, setSortingAndPagination,
  setPage, setRowsPerPage, setLastFilterValues, getMeta, setSortingData,
} from 'containers/store';

import { getDiagnosticianName } from './utils';

const defaultSorting = {
  fieldName: 'diagnostician',
  sortDirection: 'asc',
};

const sortFieldsMapping = {
  diagnostician: 'last_name',
  specialization: 'specialization',
  account: 'status',
  tests: 'sessions_count',
};

export const initialState = {
  isLoadedPage: false,
  findUserByClinic: undefined,
  items: [],
  statuses: [],
  specializations: [],
};

const mapValues = (values, startingPosition, specializations) => values.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  diagnostician: getDiagnosticianName(formatFullName(el, true), el.role_id, el.status),
  specialization: el.specialization_id ? specializations[el.specialization_id - 1].name : '',
  tests: el.sessions_count || '',
  account: el.status,
}));

const mapToFilterValues = (data, initialValues) => _map(data, (item) => ({
  id: item.id,
  name: item.name,
  isChecked: Boolean(initialValues) && _includes(initialValues, String(item.id)),
}));

const mapFilters = (values) => _map(_filter(values, 'isChecked'), 'id');

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'DIAGNOSTICIANS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_DIAGNOSTICIANS: 'DIAGNOSTICIANS/SET_FILTERED_DIAGNOSTICIANS',
  SET_STATUSES: 'DIAGNOSTICIANS/SET_STATUSES',
  SET_SPECIALIZATION: 'DIAGNOSTICIANS/SET_SPECIALIZATION',
  CLEAR_FILTERS: 'DIAGNOSTICIANS/CLEAR_FILTERS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        findUserByClinic: action.findUserByClinic,
        items: mapValues(
          action.responses.diagnosticians.data.items,
          action.startingPosition,
          action.responses.specializations,
        ),
        totalItemsCount: action.responses.diagnosticians.data.total,
      };
    }

    case actionTypes.SET_STATUSES: {
      return {
        ...state,
        statuses: action.statuses,
      };
    }

    case actionTypes.SET_SPECIALIZATION: {
      return {
        ...state,
        specializations: action.specializations,
      };
    }

    case actionTypes.SET_FILTERED_DIAGNOSTICIANS: {
      return {
        ...state,
        items: mapValues(
          action.response.data.items,
          action.startCountFrom,
          state.specializations,
        ),
        totalItemsCount: action.response.data.total,
      };
    }

    case actionTypes.CLEAR_FILTERS: {
      return {
        ...state,
        specializations: [],
        statuses: [],
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, findUserByClinic, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  findUserByClinic,
  startingPosition,
});

const setFilteredDiagnosticians = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_DIAGNOSTICIANS,
  response,
  startCountFrom,
});

const setStatuses = (statuses) => ({
  type: actionTypes.SET_STATUSES,
  statuses,
});

const setSpecializations = (specializations) => ({
  type: actionTypes.SET_SPECIALIZATION,
  specializations,
});

const setters = {
  account: setStatuses,
  specialization: setSpecializations,
};

const loadDiagnosticians = (params, clinicId) => (dispatch, getStore) => {
  const { specializations, statuses, findUserByClinic } = getStore().Diagnosticians;
  const mappedStatuses = mapFilters(statuses);
  const filterByClinic = clinicId || findUserByClinic;

  const data = {
    perPage: params.perPage,
    page: params.page,
    findUserByRole: diagnosticianRoles,
    findUserBySpecialization: mapFilters(specializations),
    sortedBy: defaultSorting.sortDirection,
    orderBy: sortFieldsMapping[defaultSorting.fieldName],
  };

  if (params.lastFilterValues.search) {
    data.findUser = params.lastFilterValues.search;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  if (mappedStatuses.length === 1) {
    data.findUserByStatus = mappedStatuses[0];
  }

  if (filterByClinic) {
    data.findUserByClinic = filterByClinic;
  }

  const url = createURLWithQuery('users', data);
  const pageUrl = createURLWithQuery('diagnosticians', _omit(
    data, 'findUserByRole', 'findUserByClinic',
  ));

  dispatch(replace(pageUrl, { findUserByClinic: filterByClinic }));

  return ApiManager.request('get', dispatch, url);
};

const getDiagnosticians = () => async (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  try {
    const response = await dispatch(loadDiagnosticians(data));
    dispatch(setFilteredDiagnosticians(response, startCountFrom));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getDiagnosticians());
};

export const onChangePage = (_, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getDiagnosticians());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getDiagnosticians());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const { sortedBy, orderBy } = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, sortedBy, orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getDiagnosticians());
};

export const onFilter = (fieldName, values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setters[fieldName](values));
  dispatch(getDiagnosticians());
};

export const onAdd = () => (dispatch) => {
  dispatch(push('/diagnosticianAddEdit'));
};

export const onActionClick = (id) => (dispatch, getStore) => {
  const currentUserId = getStore().Global.userData.id;
  let locationState = { id };
  let url = '/diagnosticianView';

  if (id === currentUserId) {
    locationState = {};
    url = 'userProfile';
  }

  dispatch(push(url, locationState));
};

const loadSpecialization = () => (dispatch) => ApiManager.request('get', dispatch, 'specializations?orderBy=id&sortedBy=asc');

export const loadPageData = (values, { findUserByClinic }) => async (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  dispatch(setLastFilterValues({
    search: values.findUser || '',
  }));

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  try {
    const specializationsResponse = await dispatch(loadSpecialization());
    const specializations = mapToFilterValues(
      specializationsResponse.data,
      values.findUserBySpecialization,
    );
    dispatch(setSpecializations(specializations));

    const metaResponse = await dispatch(getMeta());
    const statuses = mapToFilterValues(
      metaResponse.data.diagnosticianStatus,
      [values.findUserByStatus],
    );

    dispatch(setStatuses(statuses));

    const diagnosticians = await dispatch(loadDiagnosticians(data, findUserByClinic));
    dispatch(loadPageSuccess(
      { diagnosticians, specializations },
      findUserByClinic,
      startingPosition,
    ));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const clearFilters = () => ({
  type: actionTypes.CLEAR_FILTERS,
});
