import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/styles/useTheme';

import { IsCollapsedMenuContext } from 'utils/contexts/IsCollapsedMenuContext';

import useStyle from './CardsContainer.styles';

const getRightMargin = (bodyWidth, theme) => {
  if (bodyWidth < theme.sizes.cardContent.width + theme.spacing(8)) {
    return 0;
  }
  return `calc(${theme.sizes.scrollbar.width}px - (100vw - 100%) + ${theme.spacing(6)}px)`;
};

const CardsContainer = ({
  children,
  withFullWidth,
  centered,
  bodyWidth,
}) => {
  const theme = useTheme();

  const isCollapsedMenu = useContext(IsCollapsedMenuContext);
  const classes = useStyle({ isCollapsedMenu, centered });
  const marginRight = theme.sizes.scrollbar.width - (window.innerWidth - bodyWidth);

  return !withFullWidth ? (
    <div
      style={{ marginRight: centered ? getRightMargin(bodyWidth, theme) : marginRight }}
    >
      <Grid
        container
        alignItems="center"
        direction="column"
        className={classes.maxWidth}
      >
        {children}
      </Grid>
    </div>
  ) : (
    <div
      style={{ marginRight }}
      className={classes.fullWidth}
    >
      {children}
    </div>
  );
};

CardsContainer.propTypes = {
  bodyWidth: PropTypes.number,
  centered: PropTypes.bool,
  children: PropTypes.node,
  withFullWidth: PropTypes.bool,
};

CardsContainer.defaultProps = {
  children: null,
  withFullWidth: false,
  centered: false,
  bodyWidth: 0,
};

export default CardsContainer;
