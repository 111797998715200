import { goBack } from 'connected-react-router';

import _forEach from 'lodash/forEach';
import _isEqual from 'lodash/isEqual';
import _trim from 'lodash/trim';

import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader, setDrawerVisibility,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    name: '',
    range: '',
    type: '',
    answers: [],
    weights: [],
  },
  answersSets: [],
  answerId: null,
};

const mapDataToValues = (data) => {
  const answers = [];
  const weights = [];

  _forEach(data.answers, (el) => {
    answers.push(el.label);
    weights.push(String(el.weight) || '');
  });

  return {
    type: data.answer_set_type_id || '',
    range: data.answers_count || '',
    name: data.name || '',
    answers,
    weights,
  };
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'ANSWERS_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'ANSWERS_ADD_EDIT/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        answerId: action.answerId,
        initialValues: action.responses.answerData
          ? mapDataToValues(action.responses.answerData.data) : initialState.initialValues,
        answersSets: action.responses.answersSets.data,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (answerId, responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  answerId,
  responses,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadAnswerData = (id) => (dispatch) => ApiManager.request('get', dispatch, `answer_sets/${id}`);

const loadAnswerSets = () => (dispatch) => ApiManager.request('get', dispatch, 'answer_set_types');

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const store = getStore().AnswersAddEdit;
  const { answerId, initialValues } = store;
  let url = 'answer_sets';
  let method = 'post';
  let message = snackbarMessages.answerAddedSuccessfully;

  if (answerId) {
    url = `answer_sets/${answerId}`;
    method = 'put';
    message = snackbarMessages.answerEditedSuccessfully;
  }

  const data = {
    answer_set_type_id: values.type,
    name: values.name.trim(),
    restriction: false,
  };

  if (!_isEqual(values.answers, initialValues.answers)
   || !_isEqual(values.weights, initialValues.weights)
   || !_isEqual(values.range, initialValues.range)
  ) {
    data.answers_save = values.answers.map((el, key) => ({
      label: _trim(el),
      weight: values.weights[key],
    }));
    data.answers_count = parseInt(values.range);
  }

  dispatch(showTransparentLoader());

  ApiManager.request(method, dispatch, url, data).then(() => {
    dispatch(showSnackbar(message));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error, 409)) {
      const messageFromApi = error.error.message;
      const errorMessage = messageFromApi || snackbarMessages.wrongData;

      dispatch(showSnackbar(errorMessage, !!messageFromApi));
    } else if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.answersAddEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const loadPageData = (routeState, message) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  const promises = {};
  promises.answersSets = dispatch(loadAnswerSets());

  if (routeState.id) {
    promises.answerData = dispatch(loadAnswerData(routeState.id));
  }

  PromiseAll(promises).then((responses) => {
    dispatch(loadPageSuccess(routeState.id, responses));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
