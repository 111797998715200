import { goBack } from 'connected-react-router';
import _reduce from 'lodash/reduce';
import _omit from 'lodash/omit';
import _get from 'lodash/get';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import createURLWithQuery from 'utils/createURLWithQuery';
import apiFieldMappings from 'utils/apiFieldMappings';
import { parseDatesFieldsToUrl } from 'utils/parseDates';
import errorCatch from 'utils/errorCatch';
import { diagnosticianRoles } from 'utils/constants/rolesOfUsers';

import {
  showLoader,
  hideLoader,
  showSnackbar,
  setDrawerVisibility,
  showTransparentLoader,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    substitutePerson: '',
    personToSubstitute: '',
    from: null,
    to: null,
  },
  personToSubstituteOptions: [],
  userId: null,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'REPLACEMENT_ADD/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'REPLACEMENT_ADD/CLEAR_STORE',
};

const getOptions = (data, userID) => _reduce(data, (acc, item) => (
  item.id !== userID ? acc.concat({ id: item.id, name: `${item.first_name} ${item.last_name}` }) : acc
), []);

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const userId = action.personReplacing.id;

      return {
        ...state,
        isLoadedPage: true,
        personToSubstituteOptions: getOptions(action.responses.data.items, userId),
        initialValues: {
          ...state.initialValues,
          substitutePerson: `${action.personReplacing.first_name} ${action.personReplacing.last_name}`,
        },
        userId,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, personReplacing) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  personReplacing,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const getPersonsToReplace = (personReplacing) => (dispatch) => {
  const clinicId = _get(personReplacing, 'clinics[0].id');
  const params = {
    findUserByRole: diagnosticianRoles,
    findUserByClinic: clinicId,
    orderBy: 'first_name',
    sortedBy: 'asc',
  };
  const url = createURLWithQuery('users', params);

  return clinicId ? ApiManager.request('get', dispatch, url) : { data: [] };
};

const getReplacingPerson = (id) => (dispatch) => ApiManager.request('get', dispatch, `users/${id}`);

export const onSubmit = (values, { setFieldError }) => async (dispatch, getStore) => {
  const userID = getStore().ReplacementAdd.userId;
  const omittedValues = _omit(values, ['personToSubstitute', 'substitutePerson', 'note']);
  const data = {
    ...parseDatesFieldsToUrl(omittedValues, ['from', 'to']),
    replaced_user_id: userID,
    replacing_user_id: values.personToSubstitute,
  };

  dispatch(showTransparentLoader());

  try {
    await ApiManager.request('post', dispatch, 'replacements', data);
    dispatch(showSnackbar(snackbarMessages.replacementAdded));
    dispatch(goBack());
    dispatch(hideLoader());
  } catch (error) {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.replacementAdd);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const loadPageData = (message, routeState) => async (dispatch, getStore) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  try {
    if (routeState.userID) {
      const personReplacing = await dispatch(getReplacingPerson(routeState.userID));
      const responses = await dispatch(getPersonsToReplace(personReplacing.data));
      dispatch(loadPageSuccess(responses, personReplacing.data));
    } else {
      const personReplacing = getStore().Global.userData;
      const responses = await dispatch(getPersonsToReplace(personReplacing));
      dispatch(loadPageSuccess(responses, personReplacing));
    }

    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch, true);
  }
};
