const VIDEO_PLAYER = 'VIDEO_PLAYER';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const PRIVATE_MESSAGE = 'PRIVATE_MESSAGE';
const PURCHASE_PACKAGE_APPLICATION = 'PURCHASE_PACKAGE_APPLICATION';
const FILE_ADD_EDIT = 'FILE_ADD_EDIT';
const FACILITY_BATTERIES_EDIT = 'FACILITY_BATTERIES_EDIT';
const LACK_OF_FUND = 'LACK_OF_FUND';
const TOP_UP_ACCOUNT = 'TOP_UP_ACCOUNT';
const FACILITY_SETTINGS_EDIT = 'FACILITY_SETTINGS_EDIT';
const VAT_INVOICE_ADD_EDIT = 'VAT_INVOICE_ADD_EDIT';
const POLICY = 'POLICY';

export {
  VIDEO_PLAYER,
  CHANGE_PASSWORD,
  PRIVATE_MESSAGE,
  PURCHASE_PACKAGE_APPLICATION,
  FILE_ADD_EDIT,
  FACILITY_BATTERIES_EDIT,
  LACK_OF_FUND,
  TOP_UP_ACCOUNT,
  FACILITY_SETTINGS_EDIT,
  VAT_INVOICE_ADD_EDIT,
  POLICY,
};
