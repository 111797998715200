import ApiManager from 'utils/ApiManager';
import { goBack } from 'connected-react-router';
import _forEach from 'lodash/forEach';
import _mapKeys from 'lodash/mapKeys';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _keys from 'lodash/keys';
import _invert from 'lodash/invert';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import PromiseAll from 'utils/PromiseAll';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import createURLWithQuery from 'utils/createURLWithQuery';
import dialogTexts from 'utils/dialogTexts';
import config from 'config';

import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader, openDialog,
} from 'containers/store';

import {
  downloadSPSFile,
  downloadCSVFile,
} from 'containers/Surveys/store';

import { setModelLoaded } from 'containers/ResearchToolAddEdit/store';

import {
  mapArrayToIdObject,
  getPsychometricModelData,
  isAnyFilled,
  getMessageFromApi,
} from './utils';

export const initialState = {
  isLoadedPage: false,
  isModelLoaded: false,
  tableData: {
    answerDifficulties: [],
    scaleQuestions: [],
    questionParameters: [],
    scaleParameters: [],
    scaleLoads: [],
    scaleCovariances: [],
  },
  sortingData: {
    answerDifficulties: {},
    scaleQuestions: {},
    questionParameters: {},
    scaleParameters: {},
    scaleLoads: {},
    scaleCovariances: {},
  },
  questionsById: [],
  referenceGroupsById: [],
  isActive: false,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'RESEARCH_TOOL_PARAMETERS/LOAD_PAGE_SUCCESS',
  LOAD_MODEL_DATA: 'RESEARCH_TOOL_PARAMETERS/LOAD_MODEL_DATA',
  UPDATE_MODEL_DATA: 'RESEARCH_TOOL_PARAMETERS/UPDATE_MODEL_DATA',
  SORT_TABLE: 'RESEARCH_TOOL_PARAMETERS/SORT_TABLE',
  CLEAR_STORE: 'RESEARCH_TOOL_PARAMETERS/CLEAR_STORE',
};

const directions = {
  ascending: 'asc',
  descending: 'desc',
};

const dataTableNames = {
  answer_difficulties: 'answerDifficulties',
  question_parameters: 'questionParameters',
  survey_tool_questions: 'surveyToolQuestions',
  scales_questions: 'scaleQuestions',
  scale_parameters: 'scaleParameters',
  scales: 'scales',
  scale_charges: 'scaleLoads',
  scale_covariances: 'scaleCovariances',
};

const importTableNames = {
  ...dataTableNames,
  charges: 'scaleLoads',
  covariances: 'scaleCovariances',
  scale_questions: 'scaleQuestions',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const questionsById = mapArrayToIdObject(action.responses.surveyData.data.questions);
      const referenceGroupsById = mapArrayToIdObject(
        action.responses.referenceGroupSet.data.reference_group,
      );

      const isToolActive = [
        config.researchToolStatuses.active,
        config.researchToolStatuses.archived,
      ].indexOf(action.status) > -1;
      const isModelLoaded = isAnyFilled(action.responses);

      const parameters = {};

      _forEach(dataTableNames, (value) => {
        parameters[value] = action.responses[value].data.items;
      });

      return {
        ...state,
        isLoadedPage: true,
        isModelLoaded,
        tableData: getPsychometricModelData(
          questionsById,
          referenceGroupsById,
          parameters,
        ),
        toolId: action.toolId,
        questionsById,
        referenceGroupsById,
        isToolActive,
      };
    }

    case actionTypes.LOAD_MODEL_DATA: {
      const parameters = _mapKeys(
        action.responseData,
        (value, key) => importTableNames[key] || key,
      );
      const {
        questionsById,
        referenceGroupsById,
      } = state;

      return {
        ...state,
        isModelLoaded: true,
        tableData: getPsychometricModelData(
          questionsById,
          referenceGroupsById,
          parameters,
        ),
      };
    }

    case actionTypes.SORT_TABLE: {
      const {
        tableName,
        newTableData,
        fieldName,
        direction,
      } = action;

      const { tableData, sortingData } = state;

      return {
        ...state,
        tableData: {
          ...tableData,
          [tableName]: newTableData,
        },
        sortingData: {
          ...sortingData,
          [tableName]: {
            fieldName,
            direction,
          },
        },
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.UPDATE_MODEL_DATA: {
      const {
        tableName, response, apiId,
      } = action;
      const { tableData } = state;
      const table = tableData[tableName];
      const fieldNames = _invert(apiFieldMappings.researchToolOptions);

      const newData = _map(table, (row) => {
        if (row.apiId === apiId) {
          return {
            ...row,
            cells: _map(row.cells, (cell) => {
              if (_includes(_keys(fieldNames), cell.id)) {
                return {
                  ...cell,
                  data: response.data[fieldNames[cell.id]],
                };
              }
              return cell;
            }),
          };
        }
        return row;
      });

      return {
        ...state,
        tableData: {
          ...state.tableData,
          [tableName]: newData,
        },
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (toolId, responses, status) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  toolId,
  responses,
  status,
});

const loadModelData = (responseData) => ({
  type: actionTypes.LOAD_MODEL_DATA,
  responseData,
});

const updateModelData = (response, tableName, apiId) => ({
  type: actionTypes.UPDATE_MODEL_DATA,
  tableName,
  response,
  apiId,
});

const sortTable = (tableName, newTableData, fieldName, direction) => ({
  type: actionTypes.SORT_TABLE,
  tableName,
  newTableData,
  fieldName,
  direction,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onDownloadCsv = (id) => (dispatch) => {
  const path = `survey_tools/${id}/export`;
  dispatch(downloadCSVFile(path));
};

export const onDownloadSchema = (id) => (dispatch) => {
  const path = `survey_tools/${id}/schema`;
  dispatch(downloadSPSFile(path));
};

const loadData = (name, id) => (dispatch) => {
  const params = {
    findBySurveyTool: id,
  };
  const url = createURLWithQuery(name, params);

  return ApiManager.request('get', dispatch, url);
};

export const onChangeSort = (tableName, fieldName) => (dispatch, getStore) => {
  const { sortingData, tableData } = getStore().ResearchToolParameters;
  let newTableData;

  const isSortingAsc = (fieldName === sortingData[tableName].fieldName)
    && sortingData[tableName].direction === directions.ascending;

  const direction = isSortingAsc ? directions.descending : directions.ascending;
  if (isSortingAsc) {
    newTableData = _reverse(tableData[tableName]);
  } else {
    newTableData = _sortBy(
      tableData[tableName],
      (table) => _find(table.cells, (el) => el.id === fieldName).data,
    );
  }

  dispatch(sortTable(tableName, newTableData, fieldName, direction));
};

const editElement = (paramName, id, body, message, setFieldError, onToggleEdit) => (dispatch) => {
  const method = 'put';
  const url = `${paramName}/${id}`;

  dispatch(showTransparentLoader());

  ApiManager.request(method, dispatch, url, body).then((response) => {
    dispatch(showSnackbar(message));
    onToggleEdit(null);

    const tableName = dataTableNames[paramName];
    dispatch(updateModelData(response, tableName, id));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.researchToolOptions);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(hideLoader());
  });
};

const loadToolData = (id) => (dispatch) => ApiManager.request('get', dispatch, `survey_tools/${id}`);

export const onEditDifficulty = (
  values, { setFieldError }, apiId, onToggleEdit,
) => (dispatch) => {
  const { difficulty } = values;
  const body = { difficulty };

  const paramName = 'answer_difficulties';
  const message = snackbarMessages.differenceEditedSuccessfully;

  dispatch(editElement(paramName, apiId, body, message, setFieldError, onToggleEdit));
};

export const onEditDiscrimination = (
  values, { setFieldError }, apiId, onToggleEdit,
) => (dispatch) => {
  const { discrimination } = values;
  const body = { discrimination };

  const paramName = 'scales_questions';
  const message = snackbarMessages.discriminationEditedSuccessfully;

  dispatch(editElement(paramName, apiId, body, message, setFieldError, onToggleEdit));
};

export const onEditQuestionParamters = (
  values, { setFieldError }, apiId, onToggleEdit,
) => (dispatch) => {
  const {
    bottomAsymptote,
    topAsymptote,
    residualVariance,
  } = values;

  const body = {
    bottom_asymptote: bottomAsymptote,
    top_asymptote: topAsymptote,
    residual_variance: residualVariance,
  };

  const paramName = 'question_parameters';
  const message = snackbarMessages.questionParametersEditedSuccessfully;

  dispatch(editElement(paramName, apiId, body, message, setFieldError, onToggleEdit));
};

export const onEditScaleParamters = (
  values, { setFieldError }, apiId, onToggleEdit,
) => (dispatch) => {
  const {
    expectedValue,
    residualVariance,
    empiricalVariance,
  } = values;

  const body = {
    expected_value: expectedValue,
    residual_variance: residualVariance,
    empirical_variance: empiricalVariance,
  };

  const paramName = 'scale_parameters';
  const message = snackbarMessages.scaleParametersEditedSuccessfully;

  dispatch(editElement(paramName, apiId, body, message, setFieldError, onToggleEdit));
};

export const onEditScaleLoads = (
  values, { setFieldError }, apiId, onToggleEdit,
) => (dispatch) => {
  const { load } = values;
  const body = { charge: load };

  const paramName = 'scale_charges';
  const message = snackbarMessages.chargeEditedSuccessfully;

  dispatch(editElement(paramName, apiId, body, message, setFieldError, onToggleEdit));
};

export const onEditScaleCovariances = (
  values, { setFieldError }, apiId, onToggleEdit,
) => (dispatch) => {
  const { covariance } = values;
  const body = { covariance };

  const paramName = 'scale_covariances';
  const message = snackbarMessages.covarianceEditedSuccessfully;

  dispatch(editElement(paramName, apiId, body, message, setFieldError, onToggleEdit));
};

export const onLoadParametersConfirm = (event, importAsymptotes) => (dispatch, getStore) => {
  const url = 'psychometric_parameters/import';
  const method = 'post';
  const message = snackbarMessages.psychometricModelAddedSuccessfully;
  const { toolId } = getStore().ResearchToolParameters;
  const formData = new FormData();

  dispatch(showTransparentLoader());

  formData.append('survey_tool_id', toolId);
  formData.append('import_asymptotes', importAsymptotes); // TODO check
  formData.append('psychometric_parameters', event.files[0]);

  return ApiManager.request(method, dispatch, url, formData).then((response) => {
    dispatch(loadModelData(response.data));

    if (!_isEmpty(response.data.warnings)) {
      const apiMessage = _reduce(response.data.warnings, (res, value) => (`${res}\n${value}`));
      dispatch(showSnackbar(apiMessage, true));
    } else {
      dispatch(showSnackbar(message));
    }
    dispatch(setModelLoaded());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      const messageFromApi = getMessageFromApi(error);
      const errorMessage = messageFromApi || snackbarMessages.wrongData;

      dispatch(showSnackbar(errorMessage, !!messageFromApi));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onParametersFileLoad = (event, importAsymptotes) => (dispatch, getStore) => {
  const { isModelLoaded } = getStore().ResearchToolParameters;
  if (isModelLoaded) {
    dispatch(openDialog({
      title: dialogTexts.overwriteExistingModel,
      onAccept: () => dispatch(onLoadParametersConfirm(event, importAsymptotes)),
    }));
  } else {
    dispatch(onLoadParametersConfirm(event, importAsymptotes));
  }
};

const loadReferenceGroupSet = (id) => (dispatch) => ApiManager.request('get', dispatch, `reference_group_sets/${id}`);

const loadSurveyData = (id) => (dispatch) => ApiManager.request('get', dispatch, `surveys/${id}`);

export const loadPageData = (toolId) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  const promises = {};

  _forEach(dataTableNames, (value, key) => {
    promises[value] = dispatch(loadData(key, toolId));
  });

  dispatch(loadToolData(toolId)).then((tool) => {
    const toolData = tool.data;
    const refGroupId = toolData.reference_group_set_id;
    const surveyId = toolData.survey_id;
    const { status } = toolData;

    promises.referenceGroupSet = dispatch(loadReferenceGroupSet(refGroupId));
    promises.surveyData = dispatch(loadSurveyData(surveyId));
    PromiseAll(promises).then((responses) => {
      dispatch(loadPageSuccess(toolId, responses, status));
      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
