import { push } from 'connected-react-router';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import getDateFromApi from 'utils/getDateFromApi';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import errorCatch from 'utils/errorCatch';
import createURLWithQuery from 'utils/createURLWithQuery';
import dialogTexts from 'utils/dialogTexts';

import {
  hideLoader,
  showSnackbar,
  showTransparentLoader,
  openDialog,
  hideLoaderMultipleRequests,
  showLoaderMultipleRequests,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  replacedUser: null,
  values: [],
};

const mapReplacement = (replacements, userData) => replacements.map((el) => ({
  id: el.id,
  personToSubstitute: `${userData.first_name} ${userData.last_name}`,
  dateFrom: getDateFromApi(el.from),
  dateTo: getDateFromApi(el.to),
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'REPLACEMENTS/LOAD_PAGE_SUCCESS',
  SET_REPLACED_USER: 'REPLACEMENTS/SET_REPLACED_USER',
  CLEAR_STORE: 'REPLACEMENTS/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        values: mapReplacement(action.responses.data.items, _get(action, 'user.data', {})),
      };
    }

    case actionTypes.SET_REPLACED_USER: {
      return {
        ...state,
        replacedUser: action.replacedUser,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, user) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  user,
});

const setReplacedUser = (replacedUser) => ({
  type: actionTypes.SET_REPLACED_USER,
  replacedUser,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const loadReplacements = (currentUserID) => (dispatch) => {
  const data = {
    perPage: 1,
    page: 1,
    search: `replaced_user_id: ${currentUserID}`,
  };

  const url = createURLWithQuery('replacements', data);

  return ApiManager.request('get', dispatch, url);
};

const loadUserData = (id) => (dispatch) => ApiManager.request('get', dispatch, `users/${id}`);

const onDeleteAccept = (id) => async (dispatch, getStore) => {
  const currentUserID = getStore().Replacements.replacedUser
    ? getStore().Replacements.replacedUser
    : getStore().Global.userData.id;
  dispatch(showTransparentLoader());

  try {
    await ApiManager.request('delete', dispatch, `replacements/${id}`);
    const response = await dispatch(loadReplacements(currentUserID));

    dispatch(loadPageSuccess(response));
    dispatch(showSnackbar(snackbarMessages.replacementDeleted));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

const onAddClick = () => (dispatch, getStore) => {
  const userID = getStore().Replacements.replacedUser;
  dispatch(push('/replacementAdd', { userID }));
};

export const onClick = (action) => (dispatch, getStore) => {
  if (action === 'add') {
    dispatch(onAddClick());
  } else {
    const store = getStore();
    const replacementToDelete = _get(store, 'Replacements.values[0].id', null);

    dispatch(openDialog({
      title: dialogTexts.deleteReplacement,
      onAccept: () => dispatch(onDeleteAccept(replacementToDelete)),
    }));
  }
};

const getReplacementsData = (id) => async (dispatch) => {
  const loadedReplacements = await dispatch(loadReplacements(id));
  const replacingUserID = _get(loadedReplacements, 'data.items[0].replacing_user_id', null);

  if (replacingUserID) {
    const loadUser = await dispatch(loadUserData(replacingUserID));
    dispatch(loadPageSuccess(loadedReplacements, loadUser));
  } else {
    dispatch(loadPageSuccess(loadedReplacements));
  }
};

export const loadPageData = (userData, routeState) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoaderMultipleRequests());

  try {
    if (!_isEmpty(routeState)) {
      await dispatch(getReplacementsData(routeState.id));
      dispatch(setReplacedUser(routeState.id));
    } else {
      await dispatch(getReplacementsData(userData.id));
    }
  } catch (error) {
    errorCatch(error, dispatch, false, false);
  } finally {
    dispatch(hideLoaderMultipleRequests());
  }
};
