import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';

import Tooltip from '@material-ui/core/Tooltip';
import BlockIcon from '@material-ui/icons/Block';

import messages from './TooltipCell.messages';
import useStyle from './TooltipCell.styles';

const TooltipCell = ({ value }) => {
  const classes = useStyle();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
    >
      {value}
      <Tooltip
        title={<FormattedMessage {...messages.removePatient} />}
        placement="right"
      >
        <BlockIcon className={classes.blockIcon} />
      </Tooltip>
    </Grid>
  );
};

TooltipCell.propTypes = {
  value: PropTypes.string,
};

TooltipCell.defaultProps = {
  value: '',
};

export default TooltipCell;
