const getMessages = (intl, messages, daysLeft) => {
  const messagesGroup = {
    title: intl.formatMessage(messages.title),
    lacksOfFund: intl.formatMessage(messages.lacksOfFund),
    additionalInfo: intl.formatMessage(messages.additionalInfo, { daysLeft }),
    warning: intl.formatMessage(messages.warning),
    submit: intl.formatMessage(messages.submit),
    cancel: intl.formatMessage(messages.cancel),
  };

  return messagesGroup;
};

export { getMessages };
