import html2canvas from 'html2canvas';

const constants = {
  pageWidth: 184,
  leftMargin: 12,
  bottomMargin: 8,
  topMargin: 16,
  pageHeight: 295,
  headerFontSize: 12,
};

const getImageFromElement = async (elementId, vertically) => {
  const element = document.getElementById(elementId);
  if (element) {
    const canvasOptions = {
      scrollY: (window.pageYOffset * -1),
      width: (element.getBoundingClientRect().width) + 16,
      height: (element.getBoundingClientRect().height) + 16,
    };

    const canvas = await html2canvas(element, canvasOptions);
    const image = canvas.toDataURL('image/png');

    const imageWidth = vertically
      ? constants.pageHeight - constants.leftMargin * 2 : constants.pageWidth;
    const height = (canvas.height * imageWidth) / canvas.width;

    return ({
      image,
      height,
    });
  }
  return ({});
};

const addImage = (doc, image, position, imageHeight) => {
  doc.addImage(image, 'PNG', constants.leftMargin, position, constants.pageWidth, imageHeight);
};

const addVerticalImage = (doc, image, position, imageHeight) => {
  doc.addImage(image, 'PNG', constants.leftMargin, position, constants.pageHeight - 2 * constants.leftMargin, imageHeight);
};

const getTableProps = (smallCells) => ({
  theme: 'grid',
  bodyStyles: {
    font: 'RedHatText',
  },
  styles: {
    minCellWidth: smallCells ? 10 : 25,
    minCellHeight: 10,
  },
  rowPageBreak: 'avoid',
});

const getTableByElementId = (doc, position, marginBottom, elementId, headColor, smallCells) => {
  doc.autoTable({
    startY: position,
    html: elementId,
    margin: { bottom: marginBottom },
    headStyles: {
      fillColor: headColor,
      font: 'RedHatText-bold',
    },
    ...getTableProps(smallCells),
  });
};

const getTableByData = (doc, position, marginBottom, body, columns, headColor, smallCells) => {
  doc.autoTable({
    startY: position,
    body,
    columns,
    margin: { bottom: marginBottom },
    headStyles: {
      fillColor: headColor,
      font: 'RedHatText-bold',
    },
    ...getTableProps(smallCells),
  });
};

export {
  constants,
  getImageFromElement,
  addImage,
  getTableByElementId,
  getTableByData,
  addVerticalImage,
};
