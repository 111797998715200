import React from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { classesShape } from 'utils/shapes/classesShape';

import { Checkbox } from '@material-ui/core';
import TableFilterLabel from './components/TableFilterLabel';
import { headShape } from './TableHead.shapes';
import TableHeadStyles from './TableHead.styles';

const CustomTableHead = ({
  selectable,
  classes,
  rows,
  sortingField,
  sortingDirection,
  onChangeSort,
  onFilter,
  onCheckAll,
  checkedAll,
  checkedRows,
}) => (
  <TableHead>
    <TableRow>
      {selectable && (
        <TableCell
          variant="head"
          className={classNames(classes.selectCell)}
        >
          <Checkbox
            size="small"
            color="primary"
            value={checkedAll}
            checked={checkedAll}
            indeterminate={!checkedAll && !_isEmpty(checkedRows)}
            onChange={(e) => {
              const { checked } = e.target;
              onCheckAll(checked);
            }}
          />
        </TableCell>
      )}
      {rows.map((el) => (
        <TableCell
          variant="head"
          className={classNames(classes.textCell, el.class)}
          key={el.id}
        >
          {el.sortable && !el.filterable && (
            <TableSortLabel
              active={el.id === sortingField}
              direction={sortingDirection}
              onClick={() => { onChangeSort(el.id); }}
              IconComponent={ArrowDropDownIcon}
              classes={{
                icon: classes.iconColor,
                root: classes.labelRoot,
                active: classes.activeLabelRoot,
              }}
            >
              {el.label}
            </TableSortLabel>
          )}
          {el.filterable && !el.sortable && (
            <TableFilterLabel
              active={Boolean(_find(el.filterValues, 'isChecked'))}
              field={el.id}
              label={el.label}
              onFilter={onFilter}
              initialValues={el.filterValues}
              popoverMaxWidth={el.popoverMaxWidth}
            />
          )}
          {!el.sortable && !el.filterable && el.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

CustomTableHead.propTypes = {
  classes: classesShape.isRequired,
  rows: PropTypes.arrayOf(headShape).isRequired,
  checkedAll: PropTypes.bool,
  checkedRows: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  sortingDirection: PropTypes.oneOf(['asc', 'desc']),
  sortingField: PropTypes.string,
  onChangeSort: PropTypes.func,
  onCheckAll: PropTypes.func,
  onFilter: PropTypes.func,
};

CustomTableHead.defaultProps = {
  checkedAll: false,
  checkedRows: [],
  selectable: false,
  sortingField: null,
  sortingDirection: 'asc',
  onChangeSort: () => {},
  onFilter: () => {},
  onCheckAll: () => {},
};

export default withStyles(TableHeadStyles)(CustomTableHead);
