import _filter from 'lodash/filter';
import _some from 'lodash/some';
import _map from 'lodash/map';
import _get from 'lodash/get';

import {
  setModal,
  showSnackbar,
} from 'containers/store';

import { facilityDataRefresh } from 'containers/Facility/store';

import formatFacilityBatteries from 'utils/formatFacilityBatteries';
import snackbarMessages from 'utils/snackbarMessages';
import ApiManager from 'utils/ApiManager';
import createURLWithQuery from 'utils/createURLWithQuery';

export const initialState = {
  isLoadedPage: false,
  batteryOptions: [],
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'FACILITY_BATTERIES_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'FACILITY_BATTERIES_EDIT/CLEAR_STORE',
  LOAD_FILE_DATA: 'FACILITY_BATTERIES_EDIT/LOAD_FILE_DATA',
};

const getBatteryOptions = (batteries, currentBatteries) => {
  const batteryOptions = _filter(batteries,
    (battery) => (
      !_some(
        currentBatteries,
        (currentBattery) => currentBattery.id === battery.id,
      )
    ));

  const formattedBatteryOptions = formatFacilityBatteries(batteryOptions);

  return formattedBatteryOptions;
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        batteryOptions: getBatteryOptions(action.batteries, action.currentBatteries),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (batteries, currentBatteries) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  batteries,
  currentBatteries,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onDownload = () => () => {};

export const onSubmit = (values, setBatteries) => async (dispatch, getStore) => {
  const facilityId = getStore().Facility.clinicID;
  const url = `clinics/${facilityId}/batteries`;

  const data = {
    battery_ids: _map(values, (value) => value.id),
  };
  try {
    await ApiManager.request('put', dispatch, url, data);
    dispatch(setModal());
    dispatch(showSnackbar(snackbarMessages.facilityBatteriesEditedSucessfully));
    dispatch(clearStore());
    dispatch(facilityDataRefresh());
  } catch (error) {
    if (error && error.code === 422) {
      const messageFromApi = _get(error, 'error.errors.battery_id[0]', '');
      const errorMessage = messageFromApi || snackbarMessages.wrongData;
      await dispatch(facilityDataRefresh());
      const batteries = _get(getStore(), 'Facility.values.batteries', []);

      dispatch(showSnackbar(errorMessage, !!messageFromApi));
      setBatteries(batteries);
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
  }
};

export const onCancel = () => (dispatch) => {
  dispatch(clearStore());
  dispatch(setModal());
};

const getBatteries = () => (dispatch) => {
  const url = createURLWithQuery('batteries', {
    withSurveyToolStatuses: ['ACTIVE'],
  });

  return ApiManager.request('get', dispatch, url);
};

export const loadPageData = (currentBatteries) => async (dispatch) => {
  dispatch(clearStore());
  const batteries = await dispatch(getBatteries());
  dispatch(loadPageSuccess(batteries.data.items, currentBatteries));
};
