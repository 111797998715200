import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';

import regexes from 'utils/validator/regexesValidation';
import FormField from 'components/FormField';

import useStyles from './OutlinedCounterInput.styles';

const OutlinedCounterInput = ({
  onBlur,
  onChange,
  isBigValueDark,
  errors,
  touched,
  disabled,
  name,
  label,
  value,
  maxValue,
  minValue,
  withoutCounterButton,
}) => {
  const classes = useStyles();
  const inputClassNames = {
    input: classNames(classes.inputRoot, {
      [classes.isBigValueDark]: isBigValueDark,
    }),
  };

  return (
    <>
      <Typography className={classes.label} variant="h5">
        {label}
      </Typography>
      <FormField
        onBlur={onBlur}
        onChange={(currentName, currentValue) => {
          const currentNumber = Number(currentValue);

          if (currentValue === '') {
            onChange(currentName, '');
          } else if (
            !!currentNumber.toString().match(regexes.isNumber)
            && (currentNumber <= maxValue)
          ) {
            onChange(currentName, currentNumber);
          }
        }}
        errors={errors}
        touched={touched}
        name={name}
        fullWidth
      >
        <OutlinedInput
          value={value}
          disabled={disabled}
          classes={inputClassNames}
          endAdornment={withoutCounterButton || disabled ? null : (
            <>
              <div className={classes.buttonMargin}>
                <Button
                  classes={{ root: classes.text }}
                  disabled={value >= maxValue}
                  onClick={() => onChange(name, Number(value) + 1)}
                >
                  <AddBoxIcon />
                </Button>
              </div>
              <Button
                disabled={value <= minValue}
                classes={{ root: classes.text }}
                onClick={() => onChange(name, Number(value) - 1)}
              >
                <RemoveBoxIcon />
              </Button>
            </>
          )}
        />
      </FormField>
    </>
  );
};

OutlinedCounterInput.propTypes = {
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isBigValueDark: PropTypes.bool,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withoutCounterButton: PropTypes.bool,
};

OutlinedCounterInput.defaultProps = {
  value: '',
  isBigValueDark: false,
  disabled: false,
  minValue: 1,
  maxValue: Infinity,
  withoutCounterButton: false,
};

export default OutlinedCounterInput;
