import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  topUpPlus: 'Zasilenie konta +{amountOfTokens}',
  topUp: 'Zasilenie konta',
  activation: 'Aktywacja {batteryCode}',
});

const orderTypes = {
  topUp: 'MANUAL_RECHARGE',
  autoTopUp: 'AUTO_RECHARGE',
  activation: 'ACTIVATION',
};

export default (type, { batteryCode, amountOfTokens }) => {
  switch (type) {
    case orderTypes.topUp:
    case orderTypes.autoTopUp: {
      return amountOfTokens
        ? <FormattedMessage {...messages.topUpPlus} values={{ amountOfTokens }} />
        : <FormattedMessage {...messages.topUp} />;
    }

    case orderTypes.activation: {
      return <FormattedMessage {...messages.activation} values={{ batteryCode }} />;
    }

    default:
      return '';
  }
};
