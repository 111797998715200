import { goBack } from 'connected-react-router';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _last from 'lodash/last';
import _reject from 'lodash/reject';
import _find from 'lodash/find';

import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import errorCatch from 'utils/errorCatch';
import specializationTypes from 'utils/constants/specializationTypes';

import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader,
  setDrawerVisibility, loadUserData,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  title: '',
  initialValues: {
    id: '',
    name: '',
    surname: '',
    specialization: '',
    email: '',
  },
  isStudent: false,
};

const getInitialValues = (data, specializations) => ({
  id: data.id || '',
  name: data.first_name || '',
  surname: data.last_name || '',
  specialization: data.specialization_id || _last(specializations).id,

  email: data.email || '',
});

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'USER_PROFILE_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'USER_PROFILE_EDIT/CLEAR_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      const initialValues = getInitialValues(action.responses, action.specializations.data);

      return {
        ...state,
        isLoadedPage: true,
        initialValues,
        title: _get(action, 'responses.role.name', ''),
        specializations: _sortBy(action.specializations.data, 'name'),
        isStudent: action.isStudent,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, specializations, isStudent) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  specializations,
  isStudent,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onSubmit = (values, { setFieldError }) => async (dispatch, getStore) => {
  const currentUser = getStore().Global.userData;
  const data = {
    email: values.email,
    first_name: values.name,
    last_name: values.surname,
    status: currentUser.status,
    role_id: currentUser.role_id,
    specialization_id: values.specialization,
    deactivation_date: currentUser.deactivation_date,
  };

  dispatch(showTransparentLoader());

  try {
    await ApiManager.request('put', dispatch, `users/${values.id}`, data);
    await dispatch(loadUserData());
    dispatch(goBack());
    dispatch(showSnackbar(snackbarMessages.userProfileEdited));
    dispatch(hideLoader());
  } catch (error) {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.userProfileEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const onClear = () => ({
  type: actionTypes.CLEAR_SUGGESTIONS,
});

const loadSpecializations = () => (dispatch) => ApiManager.request('get', dispatch, 'specializations?orderBy=id&sortedBy=asc');

export const loadPageData = (userData, message) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: message,
  }));

  try {
    const specializations = await dispatch(loadSpecializations());

    const specialization = _find(
      specializations.data,
      (spec) => spec.name === specializationTypes.STUDENT,
    );
    const isStudent = specialization.id === userData.specialization_id;

    if (!isStudent) {
      specializations.data = _reject(
        specializations.data,
        (spec) => spec.name === specializationTypes.STUDENT,
      );
    }

    dispatch(loadPageSuccess(userData, specializations, isStudent));
  } catch (error) {
    errorCatch(error, dispatch, false, false);
  } finally {
    dispatch(hideLoader());
  }
};
