import config from 'config';

const getMessages = (intl, messages) => {
  const formattedMessages = {
    title: intl.formatMessage(messages.title),
    topUpAmount: intl.formatMessage(messages.topUpAmount),
    addedPoints: intl.formatMessage(messages.addedPoints),
    pointsAmount: intl.formatMessage(messages.pointsAmount),
    substantiation: intl.formatMessage(messages.substantiation),
    cancel: intl.formatMessage(messages.cancel),
    save: intl.formatMessage(messages.save),
  };

  return formattedMessages;
};

const getValidators = () => {
  const validators = {
    noDecimalPlaces: {
      validatorName: 'maxDecimalPlaces',
      parameters: [0],
    },
    nonNegativeNumber: {
      validatorName: 'higherOrEqualTo',
      parameters: [0],
    },
    notEmpty: {
      validatorName: 'notEmpty',
    },
    validInteger: {
      validatorName: 'lowerOrEqualTo',
      parameters: [config.maxValue.smallInteger],
    },
    maximumAddedPoints: {
      validatorName: 'lowerOrEqualTo',
      parameters: [config.maximumAddedPoints],
    },
    maxAmountInputValue: {
      validatorName: 'lowerOrEqualTo',
      parameters: [config.maxAmountInputValue, true],
    },
    nameLength: {
      validatorName: 'maxLength',
      parameters: [config.maxLength.name],
    },
  };

  return validators;
};

export {
  getMessages,
  getValidators,
};
