import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const POLISH_NATIONALITY_ID = 'POLISH';
const UKRAINIAN_NATIONALITY_ID = 'UKRAINIAN';
const OTHER_NATIONALITY_ID = 'OTHER';

const nationality = defineMessages({
  POLISH: 'Polska',
  OTHER: 'Inna',
  UKRAINIAN: 'Ukraińska',
});

const nationalityOptions = [
  {
    id: POLISH_NATIONALITY_ID,
    name: <FormattedMessage {...nationality.POLISH} />,
  },
  {
    id: UKRAINIAN_NATIONALITY_ID,
    name: <FormattedMessage {...nationality.UKRAINIAN} />,
  },
  {
    id: OTHER_NATIONALITY_ID,
    name: <FormattedMessage {...nationality.OTHER} />,
  },
];

export {
  POLISH_NATIONALITY_ID,
  UKRAINIAN_NATIONALITY_ID,
  OTHER_NATIONALITY_ID,
  nationality,
  nationalityOptions,
};
