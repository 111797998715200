import React from 'react';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import TooltipIcon from 'components/TooltipIcon';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes/intlShape';
import { classesShape } from 'utils/shapes/classesShape';
import Slider from '@material-ui/core/Slider';
import classNames from 'classnames';

import {
  getIconColor,
  getTooltipBackgroundColor,
  getTooltipMessage,
  getMessages,
  mapPercentageValueToStatus,
  getSliderValue,
  getBarColorClassNames,
} from './WalletStatus.utils';
import styles from './WalletStatus.styles';
import messages from './WalletStatus.messages';

const propTypes = {
  accountBalance: PropTypes.number.isRequired,
  classes: classesShape.isRequired,
  displayAccountBalance: PropTypes.bool.isRequired,
  displaySlider: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  isCollapsedMenu: PropTypes.bool.isRequired,
  percentageAccountBalance: PropTypes.number.isRequired,
};

const WalletStatus = ({
  accountBalance,
  classes,
  displayAccountBalance,
  displaySlider,
  isCollapsedMenu,
  intl,
  percentageAccountBalance,
}) => {
  const preparePercentageBalance = accountBalance > 0 && percentageAccountBalance === 0
    ? 1
    : percentageAccountBalance;
  const accountStatus = mapPercentageValueToStatus(preparePercentageBalance);
  const iconColor = getIconColor(accountStatus);
  const tooltipBackgroundColor = getTooltipBackgroundColor(accountStatus);
  const iconStyle = {
    color: iconColor,
    fontSize: 20,
    cursor: 'default',
  };
  const sliderValue = getSliderValue(percentageAccountBalance);
  const barColorClass = getBarColorClassNames(accountStatus, classes);
  const messagesGroup = getMessages(intl, messages, accountBalance);
  const tooltipMessage = getTooltipMessage(accountStatus, messagesGroup);
  const sliderCheckedValue = sliderValue <= 2 ? 0 : sliderValue;

  const barClasses = {
    root: barColorClass,
    active: classes.active,
    track: classes.track,
    valueLabel: classes.valueLabel,
    rail: classes.rail,
  };

  if (sliderValue === 2) {
    barClasses.thumb = classes.thumbWarning;
  } else if (sliderValue === 0) {
    barClasses.thumb = classes.thumbError;
  } else {
    barClasses.thumb = classes.thumb;
  }
  const tooltipPlacement = isCollapsedMenu ? 'top-start' : 'right-start';

  const sliderWrapperClass = classNames({
    [classes.sliderNotCollapsedWrapper]: !isCollapsedMenu,
    [classes.sliderWrapper]: isCollapsedMenu,
  });
  const tooltipWrapperClass = classNames({
    [classes.notExpanded]: !isCollapsedMenu,
  });

  return (
    <div className={tooltipWrapperClass}>
      <TooltipIcon
        forWallet
        message={tooltipMessage}
        customTooltipBackgroundColor={tooltipBackgroundColor}
        muiIcon={<AccountBalanceWalletIcon style={iconStyle} />}
        placement={tooltipPlacement}
        isCollapsedMenu={isCollapsedMenu}
      />
      { displaySlider && (
        <div className={sliderWrapperClass}>
          <Slider
            disabled
            classes={barClasses}
            defaultValue={sliderCheckedValue}
          />
        </div>
      )}
      {displayAccountBalance && (
        <div className={classes.accountBalance}>
          {isCollapsedMenu ? (
            messagesGroup.notCollapsedMenuPoints
          ) : (
            messagesGroup.collapsedMenuPoints)}
        </div>
      )}
    </div>
  );
};

WalletStatus.propTypes = propTypes;

export default withStyles(styles)(injectIntl(WalletStatus));
