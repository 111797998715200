import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  loadingWrapper: {
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
});
