import React from 'react';
import { FormattedMessage } from 'react-intl';
import { goBack, push, replace } from 'connected-react-router';
import config from 'config';
import moment from 'moment';

import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _replace from 'lodash/replace';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import _includes from 'lodash/includes';

import { FACILITY_SETTINGS_EDIT, TOP_UP_ACCOUNT } from 'utils/constants/modalTypes';
import ApiManager from 'utils/ApiManager';
import errorCatch from 'utils/errorCatch';
import createURLWithQuery from 'utils/createURLWithQuery';
import formatFullName from 'utils/formatFullName';
import formatFacilityBatteries from 'utils/formatFacilityBatteries';
import getDateFromApi from 'utils/getDateFromApi';
import getParamsFromQuery from 'utils/getParamsFromQuery';
import snackbarMessages from 'utils/snackbarMessages';
import dialogTexts from 'utils/dialogTexts';
import { adminRoles } from 'utils/constants/rolesOfUsers';

import {
  showLoader,
  hideLoader,
  showSnackbar,
  setModal,
  showTransparentLoader,
  openDialog,
} from 'containers/store';
import messages from './messages';

export const initialState = {
  isLoadedPage: false,
  clinicID: null,
  menuOptions: [],
  clinicPoints: null,
  values: {
    id: '',
    name: '',
    address: '',
    email: '',
    accountBalance: '',
  },
};

const formatClinicBalance = (value) => `${_replace(value, /(\d)(?=(\d{3})+$)/g, '$1 ')} pkt`;

const getClinicData = (clinic) => {
  const street = clinic.street || '';
  const number = clinic.number || '';
  const postalCode = clinic.postal_code || '';
  const city = clinic.city || '';
  const batteries = clinic.batteries ? formatFacilityBatteries(clinic.batteries) : [];
  const coordinators = _reduce(clinic.users, (result, user) => (result ? `${result}, ${formatFullName(user)}` : formatFullName(user)), '');

  return {
    id: clinic.id || '',
    name: clinic.name || '',
    address: `${street} ${number}, ${postalCode} ${city}`,
    email: clinic.email || '',
    coordinators,
    accountBalance: formatClinicBalance(clinic.balance),
    batteries,
    isTryAndBuy: clinic.is_in_try_and_buy_mode,
    facilityType: clinic.clinic_type_name,
  };
};

const getDate = (date) => (date ? getDateFromApi(date) : '');

const getFirstDayOfBillingCycle = (date) => (date ? moment(date).date() : '');

const getNumberOrNull = (value) => (_isNumber(value) ? value : null);

const getFinancialSettings = (values) => ({
  netCost: getNumberOrNull(values.monthly_subscription_cost),
  startDate: getDate(values.subscription_start_date),
  numberOfTablets: getNumberOrNull(values.number_of_leased_tablets),
  endDate: getDate(values.end_date_of_tablet_lease),
  billingCycle: getNumberOrNull(values.billing_period),
  firstDayOfBillingCycle: getFirstDayOfBillingCycle(values.subscription_start_date),
  transitionalPeriod: getNumberOrNull(values.transitional_period),
  showConfidenceInterval: values.show_confidence_interval || false,
  unlimitedUsage: values.unlimited_usage || false,
  hasGroupExaminationsEnabled: values.has_group_examinations_enabled || false,
});

export const onEdit = () => (dispatch, getStore) => {
  const clinicID = getStore().Facility.clinicID;
  const id = getStore().Facility.values.id;
  const keyValue = clinicID ? 'idFromList' : 'id';

  dispatch(push('/facilityAddEdit', { [keyValue]: id }));
};

const goToStatistics = () => (dispatch, getStore) => {
  const clinicId = getStore().Facility.values.id;
  const clinicName = getStore().Facility.values.name;

  dispatch(push('/statistics', { clinicId, clinicName }));
};

const onTopUp = () => (dispatch) => {
  dispatch(setModal(TOP_UP_ACCOUNT));
};

const goToDiagnosticiansList = () => (dispatch, getStore) => {
  const findUserByClinic = getStore().Facility.values.id;

  dispatch(push('/diagnosticians', { findUserByClinic }));
};

const setClinicTryAndBuy = (isTryAndBuy) => (dispatch, getStore) => {
  const id = getStore().Facility.values.id;
  const data = {
    is_in_try_and_buy_mode: isTryAndBuy,
  };

  return ApiManager.request('put', dispatch, `clinics/${id}/try_and_buy`, data);
};

const onStartTryAndBuy = () => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.startTryAndBuy,
    onAccept: async () => {
      try {
        dispatch(showLoader());
        await dispatch(setClinicTryAndBuy(true));
        // eslint-disable-next-line no-use-before-define
        dispatch(refreshAllFacility());
      } catch (error) {
        errorCatch(error);
      }
    },
  }));
};

const onEndTryAndBuy = () => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.endTryAndBuy,
    onAccept: async () => {
      try {
        dispatch(showLoader());
        await dispatch(setClinicTryAndBuy(false));
        // eslint-disable-next-line no-use-before-define
        dispatch(refreshAllFacility());
      } catch (error) {
        errorCatch(error);
      }
    },
  }));
};

export const setClinicStatus = (isActive) => (dispatch, getStore) => {
  const id = getStore().Facility.values.id;
  const data = {
    status: isActive ? 'ACTIVE' : 'INACTIVE',
  };

  return ApiManager.request('put', dispatch, `clinics/${id}/status`, data);
};

const removeFacility = () => (dispatch, getStore) => {
  const id = getStore().Facility.values.id;

  return ApiManager.request('delete', dispatch, `clinics/${id}`);
};

const exportClinicData = () => (dispatch, getStore) => {
  const id = getStore().Facility.values.id;

  return ApiManager.request('post', dispatch, `clinics/${id}/export`);
};

const onActivate = () => (dispatch) => {
  dispatch(setModal(FACILITY_SETTINGS_EDIT, { withActivationClinic: true }));
};

const onInactivate = () => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.inactivateFacility,
    onAccept: async () => {
      try {
        await dispatch(setClinicStatus(false));
        // eslint-disable-next-line no-use-before-define
        dispatch(facilityDataRefresh());
      } catch (error) {
        errorCatch(error);
      }
    },
  }));
};

const onRemove = () => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.removeFacility,
    onAccept: () => {
      dispatch(removeFacility()).then(() => {
        dispatch(showSnackbar(snackbarMessages.facilityDeleted));
        dispatch(goBack());
      }).catch((error) => {
        errorCatch(error);
      });
    },
  }));
};

const onExport = () => (dispatch) => {
  dispatch(showTransparentLoader());
  dispatch(exportClinicData()).then(() => {
    dispatch(showSnackbar(snackbarMessages.facilityExported));
    dispatch(hideLoader());
  }).catch((error) => {
    errorCatch(error, dispatch);
  });
};

const getMenuOptions = (permissions, clinicData, roleId) => {
  const menuOptions = [
    {
      id: 1,
      name: <FormattedMessage {...messages.topUp} />,
      onClick: onTopUp,
    }, {
      id: 2,
      name: <FormattedMessage {...messages.raports} />,
      requiredPermissions: [permissions.PAGE_STATISTICS_ALL_CLINICS],
      onClick: goToStatistics,
    }, {
      id: 3,
      name: <FormattedMessage {...messages.diagnosticians} />,
      requiredPermissions: [permissions.PAGE_DIAGNOSTICIAN_CLINIC_LIST],
      onClick: goToDiagnosticiansList,
    }, {
      id: 4,
      name: <FormattedMessage {...messages.startTryAndBuy} />,
      requiredPermissions: [
        permissions.CLINICS_EDIT_IS_IN_TRY_AND_BUY_MODE_ATTRIBUTE,
        !clinicData.is_in_try_and_buy_mode,
        clinicData.status === 'INACTIVE',
        !clinicData.try_and_buy_mode_ended_at,
        !_isEmpty(clinicData.batteries),
      ],
      onClick: onStartTryAndBuy,
    }, {
      id: 5,
      name: <FormattedMessage {...messages.endTryAndBuy} />,
      requiredPermissions: [
        permissions.CLINICS_EDIT_IS_IN_TRY_AND_BUY_MODE_ATTRIBUTE,
        clinicData.is_in_try_and_buy_mode,
      ],
      onClick: onEndTryAndBuy,
    }, {
      id: 6,
      name: <FormattedMessage {...messages.activate} />,
      requiredPermissions: [
        permissions.CLINICS_UPDATE_SETTINGS,
        clinicData.status === 'INACTIVE',
        !clinicData.is_in_try_and_buy_mode,
      ],
      onClick: onActivate,
    }, {
      id: 7,
      name: <FormattedMessage {...messages.inactivate} />,
      requiredPermissions: [
        permissions.CLINICS_UPDATE_SETTINGS,
        clinicData.status === 'ACTIVE',
      ],
      onClick: onInactivate,
    }, {
      id: 8,
      name: <FormattedMessage {...messages.remove} />,
      requiredPermissions: [permissions.CLINICS_REMOVE],
      onClick: onRemove,
    }, {
      id: 9,
      name: <FormattedMessage {...messages.export} />,
      requiredPermissions: [_includes(adminRoles, roleId)],
      onClick: onExport,
    },
  ];

  return menuOptions;
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'FACILITY/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'FACILITY/CLEAR_STORE',
  SET_MENU_OPTIONS: 'FACILITY/SET_MENU_OPTIONS',
  SET_PURCHASE_HISTORY_ORDERS: 'FACILITY/PURCHASE_HISTORY/SET_ORDERS',
  SET_CREDIT_POINTS_HISTORY_ORDERS: 'FACILITY/CREDIT_POINTS_HISTORY/SET_ORDERS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        values: getClinicData(action.responses.data),
        financialSettings: getFinancialSettings(action.responses.data),
        clinicPoints: _get(action, 'responses.data.balance', null),
        clinicID: action.clinicID,
      };
    }

    case actionTypes.SET_MENU_OPTIONS: {
      const clinicData = action.clinicData.data;

      return {
        ...state,
        menuOptions: getMenuOptions(action.permissions, clinicData, action.roleId),
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, clinicID) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  clinicID,
});

const setMenuOptions = (permissions, clinicData, roleId) => ({
  type: actionTypes.SET_MENU_OPTIONS,
  permissions,
  clinicData,
  roleId,
});

const setPurchaseHistoryOrders = (orders) => ({
  type: actionTypes.SET_PURCHASE_HISTORY_ORDERS,
  orders,
});

const setCreditPointsHistoryOrders = (orders, roleId) => ({
  type: actionTypes.SET_CREDIT_POINTS_HISTORY_ORDERS,
  orders,
  roleId,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onTopUpClick = () => (dispatch) => {
  dispatch(push('/purchasePackageSummary', { fromFacilityPage: true }));
};

const getClinic = (clinicID) => (dispatch) => {
  const params = {
    with_clinic_coordinator: 1,
    append_balance: true,
    append_has_session_patients_in_clinic: true,
    append_clinic_type_name: true,
  };

  const url = createURLWithQuery(`clinics/${clinicID}`, params);

  return ApiManager.request('get', dispatch, url);
};

export const getClinicTypes = () => (dispatch) => {
  const url = createURLWithQuery('clinic_types');

  return ApiManager.request('get', dispatch, url);
};

const loadFacilityData = (clinicId) => async (dispatch, getStore) => {
  const roleId = getStore().Global.userData.role_id;
  const permissions = getStore().Global.permissions;
  const { idFromList } = _get(getStore(), 'router.location.state', {});

  try {
    const clinicData = await dispatch(getClinic(clinicId));
    if (idFromList) {
      dispatch(setMenuOptions(permissions, clinicData, roleId));
    }
    dispatch(loadPageSuccess(clinicData, clinicId));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const onMenuItemChange = (onClick) => (dispatch) => dispatch(onClick());

export const facilityDataRefresh = () => (dispatch, getStore) => {
  const facilityId = getStore().Facility.clinicID;

  dispatch(showLoader());
  return dispatch(loadFacilityData(facilityId));
};

export const getPurchaseHistoryOrders = (clinicId) => (dispatch, getStore) => {
  const { idFromList } = _get(getStore(), 'router.location.state', {});
  const { rowsPerPage, page } = getStore().Global.pagingData;
  const { startDate, endDate } = getStore().FacilityPurchaseHistory;
  const findByClinicId = clinicId || getStore().FacilityPurchaseHistory.clinicId;

  const data = {
    perPage: rowsPerPage,
    page,
    findByClinicId,
    dateFrom: startDate.format(config.apiDateFormat),
    dateTo: endDate.format(config.apiDateFormat),
    sortedBy: 'desc',
    orderBy: 'created_at',
  };

  const url = createURLWithQuery('orders', data);
  const currentParams = getParamsFromQuery(window.location.search);

  const pageUrl = createURLWithQuery('facility',
    {
      ...currentParams,
      ..._pick(
        data,
        ['perPage', 'page', 'dateFrom', 'dateTo'],
      ),

    });
  const locationState = idFromList ? { idFromList: findByClinicId } : {};
  dispatch(replace(pageUrl, locationState));

  return ApiManager.request('get', dispatch, url);
};

export const purchaseHistoryRefresh = (withLoader = true) => async (dispatch) => {
  if (withLoader) {
    dispatch(showTransparentLoader());
  }

  try {
    const orders = await dispatch(getPurchaseHistoryOrders());
    dispatch(setPurchaseHistoryOrders(orders));
    if (withLoader) {
      dispatch(hideLoader());
    }
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

export const activateBattery = (id) => async (dispatch, getStore) => {
  const { clinicID: facilityId } = getStore().Facility;
  const url = 'orders';

  const data = {
    clinic_id: facilityId,
    battery_id: id,
    type: 'ACTIVATION',
  };
  dispatch(showLoader());
  try {
    await ApiManager.request('post', dispatch, url, data);
    dispatch(hideLoader());
    dispatch(showSnackbar(snackbarMessages.batterySetToActivate));
    dispatch(facilityDataRefresh());
    dispatch(purchaseHistoryRefresh());
  } catch {
    dispatch(hideLoader());
    dispatch(showSnackbar(snackbarMessages.globalError));
  }
};

export const loadPageData = (routeState, clinicId) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());

  try {
    const facilityId = routeState.idFromList || clinicId;
    dispatch(loadFacilityData(facilityId));
  } catch (error) {
    errorCatch(error, dispatch, true);
  }
};

export const getCreditPoints = (facilityId) => (dispatch, getStore) => {
  const { idFromList } = getStore().router.location.state;
  const { rowsPerPage, page } = getStore().CreditPointsHistory.paginationOptions;
  const { startDate, endDate } = getStore().CreditPointsHistory;
  const findByClinicId = facilityId || getStore().CreditPointsHistory.facilityId;

  const data = {
    perPage: rowsPerPage,
    page,
    findByClinicId,
    dateFrom: startDate.format(config.apiDateFormat),
    dateTo: endDate.format(config.apiDateFormat),
    sortedBy: 'desc',
    orderBy: 'created_at',
  };

  const url = createURLWithQuery('subscriptions', data);
  const currentParams = getParamsFromQuery(window.location.search);
  const pageUrl = createURLWithQuery('facility',
    {
      ...currentParams,
      creditPointsPage: data.page,
      creditPointsPerPage: data.perPage,
      creditPointsDateFrom: data.dateFrom,
      creditPointsDateTo: data.dateTo,
    });
  const locationState = idFromList ? { idFromList: findByClinicId } : {};

  dispatch(replace(pageUrl, locationState));

  return ApiManager.request('get', dispatch, url);
};

export const refreshCreditPointsList = () => async (dispatch, getStore) => {
  const { idFromList } = _get(getStore(), 'router.location.state', {});
  const { role_id: roleId } = getStore().Global.userData;

  dispatch(showTransparentLoader());
  try {
    const orders = await dispatch(getCreditPoints(idFromList));
    dispatch(setCreditPointsHistoryOrders(orders, roleId));
    dispatch(hideLoader());
  } catch (error) {
    errorCatch(error, dispatch);
  }
};

const refreshAllFacility = () => (dispatch) => {
  dispatch(facilityDataRefresh());
  dispatch(purchaseHistoryRefresh());
  dispatch(refreshCreditPointsList());
};
