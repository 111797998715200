import moment from 'moment';
import _get from 'lodash/get';
import _every from 'lodash/every';
import _isEmpty from 'lodash/isEmpty';

import {
  setModal,
  showSnackbar,
  showLoader,
  hideLoader,
} from 'containers/store';

import { facilityDataRefresh, setClinicStatus } from 'containers/Facility/store';
import apiFieldMappings from 'utils/apiFieldMappings';

import setFormErrors from 'utils/setFormErrors';
import snackbarMessages from 'utils/snackbarMessages';
import getFirstDayOfBillingCycle from 'utils/getFirstDayOfBillingCycle';
import ApiManager from 'utils/ApiManager';
import config from 'config';

export const initialState = {
  isLoadedPage: false,
  initialValues: {},
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'FACILITY_SETTINGS_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'FACILITY_SETTINGS_EDIT/CLEAR_STORE',
  LOAD_FILE_DATA: 'FACILITY_SETTINGS_EDIT/LOAD_FILE_DATA',
};

const activationDefaultValues = {
  netCost: 30,
  numberOfTablets: 0,
  endDate: null,
  billingCycle: 1,
  transitionalPeriod: 60,
  unlimitedUsage: false,
  showConfidenceInterval: false,
  hasGroupExaminationsEnabled: false,
};

const getPaymentStartDate = (date) => {
  if (!date || moment(date).isSameOrBefore(moment(), 'day')) {
    return moment(date, config.dateFormat, true);
  }
  return moment(date, config.dateFormat, true);
};

const prepareStartDateBaseOnFirstDay = () => {
  const { firstDay, withMoveToNextMonth } = getFirstDayOfBillingCycle();
  const now = moment();
  const currentMonth = now.month() + 1;
  const currentYear = now.year();
  let month = currentMonth;
  let year = currentYear;
  if (withMoveToNextMonth) {
    const moveToNextYear = currentMonth === 12;
    month = moveToNextYear ? 1 : currentMonth + 1;
    year = moveToNextYear ? currentYear + 1 : currentYear;
  }
  const newDate = moment(`${year}-${month}-${firstDay}`, 'YYYY-MM-DD');

  return getPaymentStartDate(newDate);
};

const formatInitialValues = (values, isActivationMode) => {
  const everyValuesAreEmpty = _every(values, (value) => _isEmpty(value));
  if (isActivationMode || everyValuesAreEmpty) {
    return ({
      ...activationDefaultValues,
      startDate: prepareStartDateBaseOnFirstDay(),
      firstDayOfBillingCycle: getFirstDayOfBillingCycle().firstDay,
    });
  }

  return ({
    ...values,
    endDate: values.endDate ? moment(values.endDate, config.dateFormat, true) : null,
    startDate: moment(values.startDate, config.dateFormat, true),
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        initialValues: formatInitialValues(
          action.financialSettings,
          action.isActivationMode,
        ),
        isActivationMode: action.isActivationMode,
        isLoadedPage: true,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (financialSettings, isActivationMode) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  financialSettings,
  isActivationMode,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

export const onSubmit = (values, { setFieldError }) => async (dispatch, getStore) => {
  const facilityId = getStore().Facility.clinicID;
  const { withActivationClinic } = _get(getStore(), 'Global.modal.props', false);
  const url = `clinics/${facilityId}/settings`;

  const data = {
    monthly_subscription_cost: values.netCost,
    subscription_start_date: values.startDate.format('YYYY-MM-DD'),
    billing_period: values.billingCycle,
    transitional_period: values.transitionalPeriod,
    number_of_leased_tablets: values.numberOfTablets,
    end_date_of_tablet_lease: (values.numberOfTablets && values.endDate) ? values.endDate.format('YYYY-MM-DD') : null,
    unlimited_usage: values.unlimitedUsage,
    show_confidence_interval: values.showConfidenceInterval,
    has_group_examinations_enabled: values.hasGroupExaminationsEnabled,
  };

  dispatch(showLoader());
  try {
    await ApiManager.request('put', dispatch, url, data);
    dispatch(showSnackbar(snackbarMessages.facilitySettingsEditedSucessfully));
    dispatch(clearStore());
    if (withActivationClinic) {
      await dispatch(setClinicStatus(true));
    }
    dispatch(facilityDataRefresh());
    dispatch(setModal());
  } catch (error) {
    if (error.error.errors) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.facilitySettingsEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  }
};

export const onCancel = () => (dispatch) => {
  dispatch(clearStore());
  dispatch(setModal());
};

export const loadPageData = (financialSettings, isActivationMode) => async (dispatch) => {
  dispatch(clearStore());
  dispatch(loadPageSuccess(financialSettings, isActivationMode));
};
