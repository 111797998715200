import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  tableRoot: {
    width: '100%',
  },
  fixedLayout: {
    tableLayout: 'fixed',
  },
  numberCell: theme.numberCell,
  actionsCell: {
    ...theme.actionsCell,
    width: ({ actionsLength }) => actionsLength,
  },
  customActionsCell: {
    paddingTop: theme.spacing(1),
  },
  defaultCell: {
    width: '20%',
    wordWrap: 'break-word',
  },
  actionsWrapper: {
    display: 'flex',
    minHeight: 30,
    justifyContent: 'flex-end',
  },
  hoverableRow: {
    '&:hover $chevron': {
      background: theme.palette.icon.hover,
    },
  },
  chevron: {
    '&:hover ': {
      background: `${theme.palette.primary.main} !important`,
    },
  },
  redirectButton: {
    paddingTop: theme.spacing(0.375),
  },
  focus: {
    ...theme.focus,
  },
  selectCell: {
    padding: theme.spacing(0.5, 2, 0.5, 0),
  },
}));
