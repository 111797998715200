import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IsCollapsedMenuContext } from 'utils/contexts/IsCollapsedMenuContext';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import useStyle from './Modal.styles';

const Modal = ({
  children,
  centered,
  fullWidth,
  dynamicBox,
  avoidScroll,
  onRequestClose,
  onClickCloseIcon,
  ...restProps
}) => {
  const isCollapsedMenu = useContext(IsCollapsedMenuContext);
  const classes = useStyle({ centered, isCollapsedMenu });
  let onClose;

  if (!onRequestClose) {
    onClose = onClickCloseIcon;
  } else {
    onClose = onRequestClose;
  }

  const boxClasses = classNames({
    [classes.box]: !dynamicBox,
    dynamicBox,
  });

  const innerWrapper = classNames({
    [classes.modalBoxInnerWrapper]: !avoidScroll,
    [classes.modalBoxInnerWrapperAvoidScroll]: avoidScroll,
  });

  return (
    <ReactModal
      className={classNames(classes.content, {
        [classes.fullWidth]: fullWidth,
        [classes.shiftedContent]: !centered,
      })}
      overlayClassName={classes.overlay}
      bodyOpenClassName={classes.body}
      onRequestClose={onRequestClose}
      {...restProps}
    >
      <IconButton
        size="small"
        onClick={onClose}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>
      <div className={classes.modalBoxWrapper}>
        <div className={innerWrapper}>
          <div className={boxClasses}>
            {children}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  ariaHideApp: PropTypes.bool,
  avoidScroll: PropTypes.bool,
  centered: PropTypes.bool,
  dynamicBox: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClickCloseIcon: PropTypes.func,
  onClose: PropTypes.func,
  onRequestClose: PropTypes.func,
};

Modal.defaultProps = {
  centered: false,
  fullWidth: false,
  ariaHideApp: false,
  dynamicBox: false,
  avoidScroll: false,
  onClose: null,
  onRequestClose: null,
  onClickCloseIcon: null,
};

export default Modal;
