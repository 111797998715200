import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Formik } from 'formik';

import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import { intlShape } from 'utils/shapes/intlShape';
import logo from 'assets/img/diagmaticLogoBlack.svg';
import PolicyContainer from 'components/PolicyContainer';

import useStyle from './GdprClause.styles';
import messages from './GdprClause.messages';

const stages = {
  privacyPolicy: 'privacyPolicy',
  termsOfUse: 'termsOfUse',
};

const GdprClause = ({ intl, onSubmit }) => {
  const classes = useStyle();
  const clauseRef = useRef();
  const [stage, setStage] = useState(stages.privacyPolicy);
  const isPrivacyPolicy = stage === stages.privacyPolicy;

  const buttonMessage = {
    [stages.privacyPolicy]: messages.goOn,
    [stages.termsOfUse]: messages.goToDiagmatic,
  }[stage];

  const headerMessage = {
    [stages.privacyPolicy]: messages.privacyPolicy,
    [stages.termsOfUse]: messages.termsOfUse,
  }[stage];

  return (
    <div className={classes.wrapper}>
      <div>
        <img
          src={logo}
          alt="diagmatic"
          className={classes.logo}
        />
      </div>
      <Typography className={classes.header}>
        {intl.formatMessage(headerMessage)}
      </Typography>
      <div className={classes.clause} ref={clauseRef}>
        <PolicyContainer
          type={stage}
        />
      </div>
      <Formik
        initialValues={{ gdpr_agreed: false }}
        onSubmit={(values) => {
          if (isPrivacyPolicy) {
            setStage(stages.termsOfUse);
            clauseRef.current.scrollTo({ top: 0 });
          } else {
            onSubmit(values);
          }
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form
            className={classes.form}
            onSubmit={handleSubmit}
          >
            {
              isPrivacyPolicy
                ? null : (
                  <div className={classes.checkboxWrapper}>
                    <FormControlLabel
                      key="gdpr_agreed"
                      label={intl.formatMessage(messages.checkbox)}
                      control={(
                        <Checkbox
                          value="gdpr_agreed"
                          checked={values.gdpr_agreed}
                          type="checkbox"
                          color="primary"
                          classes={{ root: classes.checkboxRoot }}
                          onChange={(event) => setFieldValue('gdpr_agreed', event.currentTarget.checked)}
                        />
                    )}
                    />
                  </div>
                )
              }
            <div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!values.gdpr_agreed && !isPrivacyPolicy}
              >
                {intl.formatMessage(buttonMessage)}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

GdprClause.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default injectIntl(GdprClause);
