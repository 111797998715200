import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import GdprClause from 'containers/GdprClause';
import { historyShape } from 'utils/shapes/router.shapes';
import { userDataShape } from 'utils/shapes/userDataShape';
import AsyncComponent from 'misc/AsyncComponent';
import UnauthorizedRoute from './UnauthorizedRoute';
import AuthorizedRoute from './AuthorizedRoute';
import Global from '../global';

// pages

const asyncLoadedPages = {
  LoginPage: AsyncComponent(() => import('containers/Login')),
  ForgotPasswordPage: AsyncComponent(() => import('containers/ForgotPassword')),
  ResetPassword: AsyncComponent(() => import('containers/ResetPassword')),
  UsersPage: AsyncComponent(() => import('containers/Users')),
  OrganizationsPage: AsyncComponent(() => import('containers/Organizations')),
  QuestionsPage: AsyncComponent(() => import('containers/Questions')),
  UserAddEditPage: AsyncComponent(() => import('containers/UserAddEdit')),
  ParticipantEditPage: AsyncComponent(() => import('containers/ParticipantEdit')),
  QuestionAddEditPage: AsyncComponent(() => import('containers/QuestionAddEdit')),
  OrganizationAddEditPage: AsyncComponent(() => import('containers/OrganizationAddEdit')),
  SurveyCreatorPage: AsyncComponent(() => import('containers/SurveyCreator')),
  SurveysPage: AsyncComponent(() => import('containers/Surveys')),
  AnswersPage: AsyncComponent(() => import('containers/Answers')),
  PatientsPage: AsyncComponent(() => import('containers/Patients')),
  AnswersAddEditPage: AsyncComponent(() => import('containers/AnswersAddEdit')),
  BatteriesPage: AsyncComponent(() => import('containers/Batteries')),
  BatteriesAddEditPage: AsyncComponent(() => import('containers/BatteriesAddEdit')),
  SurveyAnalysisPage: AsyncComponent(() => import('containers/SurveyAnalysis')),
  SurveysResultsPage: AsyncComponent(() => import('containers/SurveysResults')),
  SurveyResultsPage: AsyncComponent(() => import('containers/SurveyResults')),
  PreviewSurveyPage: AsyncComponent(() => import('containers/PreviewSurvey')),
  ResearchToolsPage: AsyncComponent(() => import('containers/ResearchTools')),
  ScaleEditPage: AsyncComponent(() => import('containers/ScaleEdit')),
  EvaluationsAddEditPage: AsyncComponent(() => import('containers/EvaluationsAddEdit')),
  StandardScalesPage: AsyncComponent(() => import('containers/StandardScales')),
  StandardScalesAddEditPage: AsyncComponent(() => import('containers/StandardScalesAddEdit')),
  ReferenceGroupSetsPage: AsyncComponent(() => import('containers/ReferenceGroupSets')),
  ReferenceGroupsAddEditPage: AsyncComponent(() => import('containers/ReferenceGroupsAddEdit')),
  ExaminationDetailsPage: AsyncComponent(() => import('containers/ExaminationDetails')),
  Facility: AsyncComponent(() => import('containers/Facility')),
  FacilityList: AsyncComponent(() => import('containers/FacilityList')),
  FacilityAddEdit: AsyncComponent(() => import('containers/FacilityAddEdit')),
  Profile: AsyncComponent(() => import('containers/Profile')),
  ReplacementAdd: AsyncComponent(() => import('containers/ReplacementAdd')),
  UserProfileEdit: AsyncComponent(() => import('containers/ProfileEdit')),
  PatientAddEdit: AsyncComponent(() => import('containers/PatientAddEdit')),
  PurchasePackageSummary: AsyncComponent(() => import('containers/PurchasePackageSummary')),
  ToolsDetails: AsyncComponent(() => import('containers/ToolsDetails')),
  PatientView: AsyncComponent(() => import('containers/PatientView')),
  Tools: AsyncComponent(() => import('containers/Tools')),
  Tutorials: AsyncComponent(() => import('containers/Tutorials')),
  DiagnosticiansPage: AsyncComponent(() => import('containers/Diagnosticians')),
  DiagnosticianAddEdit: AsyncComponent(() => import('containers/DiagnosticianAddEdit')),
  Diagnostician: AsyncComponent(() => import('containers/Diagnostician')),
  ResearchStart: AsyncComponent(() => import('containers/ResearchStart')),
  ResearchStartedOnTablet: AsyncComponent(() => import('containers/ResearchStartedOnTablet')),
  ResearchSelect: AsyncComponent(() => import('containers/ResearchSelect')),
  VideoTutorials: AsyncComponent(() => import('containers/VideoTutorials')),
  VideoTutorialView: AsyncComponent(() => import('containers/VideoTutorialView')),
  VideoTutorialAddEdit: AsyncComponent(() => import('containers/VideoTutorialAddEdit')),
  Statistics: AsyncComponent(() => import('containers/Statistics')),
  ResearchToolAddEditPage: AsyncComponent(() => import('containers/ResearchToolAddEdit')),
  PackagesEditPage: AsyncComponent(() => import('containers/PackagesEdit')),
  GroupExaminationDetails: AsyncComponent(() => import('containers/GroupExaminationDetails')),
  GroupSurveyAddEdit: AsyncComponent(() => import('containers/GroupSurveyAddEdit')),
  GroupSurveys: AsyncComponent(() => import('containers/GroupSurveys')),
  LoginConfirm: AsyncComponent(() => import('containers/LoginConfirm')),
  LoginToken: AsyncComponent(() => import('containers/LoginToken')),
  GroupExaminationSelect: AsyncComponent(() => import('containers/GroupExaminationSelect')),
};

const Routes = ({ history, permissions, userData }) => {
  const {
    PAGE_USERS_LIST,
    PAGE_ORGANIZATIONS_LIST,
    PAGE_QUESTIONS_LIST,
    PAGE_ANSWERS_LIST,
    PAGE_BATTERIES_LIST,
    PAGE_RESEARCH_TOOLS_LIST,
    PAGE_STANDARD_SCALES_LIST,
    PAGE_REFERENCE_GROUPS_LIST,
    PAGE_SURVEYS_LIST,
    PAGE_SURVEYS_RESULTS_LIST,
    REPLACEMENTS_ADD_OWN,
    PAGE_SURVEYS_RESULTS_VIEW,
    PAGE_SURVEYS_ADD,
    PAGE_RESEARCH_TOOLS_SCALE_EDIT,
    PAGE_SURVEYS_VIEW,
    PAGE_SURVEYS_RESULTS_PARTICIPANT_EDIT,
    PAGE_TOOLS_LIST,
    PAGE_PATIENT_LIST,
    PAGE_CLINICS_LIST,
    PAGE_CLINICS_EDIT,
    PAGE_CLINICS_ADD,
    PAGE_CLINIC_OWN_EDIT,
    PAGE_DIAGNOSTICIAN_LIST,
    PAGE_DIAGNOSTICIAN_VIEW,
    REPLACEMENTS_ADD,
    PAGE_VIDEO_TUTORIAL_LIST,
    PAGE_VIDEO_TUTORIAL_VIEW,
    PAGE_VIDEO_TUTORIAL_ADD,
    PAGE_VIDEO_TUTORIAL_EDIT,
    PAGE_DIAGNOSTICIAN_CLINIC_LIST,
    PAGE_MENU_BOTTOM_VIDEO_TUTORIAL,
    EXAMINATION_CREATE,
    EXAMINATION_CREATE_OWN_CLINIC,
    PAGE_STATISTICS,
    PAGE_STATISTICS_ALL_CLINICS,
    PACKAGE_UPDATE,
    ORDER_CREATE_OWN_CLINIC,
    PAGE_PATIENT_EDIT,
    PAGE_PATIENT_ADD,
    PAGE_PATIENT_VIEW,
    PAGE_DIAGNOSTICIAN_EDIT,
    PAGE_DIAGNOSTICIAN_ADD,
    USERS_UPDATE_SELF,
    PAGE_USERS_ADD,
    PAGE_USERS_EDIT,
    PAGE_QUESTIONS_ADD,
    PAGE_QUESTIONS_EDIT,
    PAGE_ORGANIZATIONS_ADD,
    PAGE_ORGANIZATIONS_EDIT,
    PAGE_ANSWERS_ADD,
    PAGE_ANSWERS_EDIT,
    PAGE_BATTERIES_ADD,
    PAGE_BATTERIES_EDIT,
    EXAMINATION_GET_OWN_CLINIC,
    PAGE_RESEARCH_TOOLS_ADD,
    PAGE_RESEARCH_TOOLS_EDIT,
    PAGE_STANDARD_SCALES_ADD,
    PAGE_STANDARD_SCALES_EDIT,
    PAGE_REFERENCE_GROUPS_ADD,
    PAGE_REFERENCE_GROUPS_EDIT,
    CLINICS_GET_OWN,
    PAGE_CLINICS_VIEW,
    PAGE_GROUP_SURVEYS_RESULTS_LIST,
  } = permissions;

  return (
    <>
      <Global>
        <Switch>
          <UnauthorizedRoute
            component={asyncLoadedPages.LoginPage}
            path="/login"
          />
          <UnauthorizedRoute
            component={asyncLoadedPages.ForgotPasswordPage}
            path="/forgotPassword"
          />
          <UnauthorizedRoute
            component={asyncLoadedPages.ResetPassword}
            path="/resetPassword/:token"
          />
          <UnauthorizedRoute
            component={asyncLoadedPages.ResetPassword}
            path="/createPassword/:token"
          />
          <UnauthorizedRoute
            component={asyncLoadedPages.LoginConfirm}
            path="/confirmLogin/:token"
          />
          <UnauthorizedRoute
            component={asyncLoadedPages.LoginToken}
            path="/tokenLogin/:token"
          />
          <GdprClause path="/gdprClause" />
          <AuthorizedRoute
            requiredPermission={[PAGE_USERS_LIST]}
            component={asyncLoadedPages.UsersPage}
            path="/users"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_ORGANIZATIONS_LIST]}
            component={asyncLoadedPages.OrganizationsPage}
            path="/organizations"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_QUESTIONS_LIST]}
            component={asyncLoadedPages.QuestionsPage}
            path="/questions"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_USERS_ADD, PAGE_USERS_EDIT]}
            permissionsOperator="OR"
            component={asyncLoadedPages.UserAddEditPage}
            path="/userAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_SURVEYS_RESULTS_PARTICIPANT_EDIT]}
            component={asyncLoadedPages.ParticipantEditPage}
            path="/participantEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_QUESTIONS_ADD, PAGE_QUESTIONS_EDIT]}
            permissionsOperator="OR"
            component={asyncLoadedPages.QuestionAddEditPage}
            path="/questionAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_ORGANIZATIONS_ADD, PAGE_ORGANIZATIONS_EDIT]}
            permissionsOperator="OR"
            component={asyncLoadedPages.OrganizationAddEditPage}
            path="/organizationAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_SURVEYS_ADD]}
            component={asyncLoadedPages.SurveyCreatorPage}
            path="/surveyCreator"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_SURVEYS_LIST]}
            component={asyncLoadedPages.SurveysPage}
            path="/surveys"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_ANSWERS_LIST]}
            component={asyncLoadedPages.AnswersPage}
            path="/answers"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_ANSWERS_ADD, PAGE_ANSWERS_EDIT]}
            permissionsOperator="OR"
            component={asyncLoadedPages.AnswersAddEditPage}
            path="/answersAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_BATTERIES_LIST]}
            component={asyncLoadedPages.BatteriesPage}
            path="/batteries"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_BATTERIES_ADD, PAGE_BATTERIES_EDIT]}
            permissionsOperator="OR"
            component={asyncLoadedPages.BatteriesAddEditPage}
            path="/batteriesAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_SURVEYS_RESULTS_LIST]}
            component={asyncLoadedPages.SurveysResultsPage}
            path="/surveysResults"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_SURVEYS_RESULTS_VIEW]}
            component={asyncLoadedPages.SurveyResultsPage}
            path="/surveyResults"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_SURVEYS_VIEW]}
            component={asyncLoadedPages.PreviewSurveyPage}
            path="/previewSurvey"
          />
          <AuthorizedRoute
            requiredPermission={[EXAMINATION_GET_OWN_CLINIC]}
            component={asyncLoadedPages.SurveyAnalysisPage}
            path="/surveyAnalysis"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_RESEARCH_TOOLS_LIST]}
            component={asyncLoadedPages.ResearchToolsPage}
            path="/researchTools"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_RESEARCH_TOOLS_ADD, PAGE_RESEARCH_TOOLS_EDIT]}
            permissionsOperator="OR"
            component={asyncLoadedPages.ResearchToolAddEditPage}
            path="/researchToolAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_RESEARCH_TOOLS_SCALE_EDIT]}
            component={asyncLoadedPages.ScaleEditPage}
            path="/scaleEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_RESEARCH_TOOLS_EDIT]}
            component={asyncLoadedPages.EvaluationsAddEditPage}
            path="/evaluationsAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_STANDARD_SCALES_LIST]}
            component={asyncLoadedPages.StandardScalesPage}
            path="/standardScales"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_STANDARD_SCALES_ADD, PAGE_STANDARD_SCALES_EDIT]}
            permissionsOperator="OR"
            component={asyncLoadedPages.StandardScalesAddEditPage}
            path="/standardScalesAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_REFERENCE_GROUPS_LIST]}
            component={asyncLoadedPages.ReferenceGroupSetsPage}
            path="/referenceGroups"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_REFERENCE_GROUPS_ADD, PAGE_REFERENCE_GROUPS_EDIT]}
            permissionsOperator="OR"
            component={asyncLoadedPages.ReferenceGroupsAddEditPage}
            path="/referenceGroupsAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[EXAMINATION_GET_OWN_CLINIC]}
            component={asyncLoadedPages.ExaminationDetailsPage}
            path="/examinationDetails"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_CLINICS_LIST]}
            component={asyncLoadedPages.FacilityList}
            path="/facilityList"
          />
          <AuthorizedRoute
            requiredPermission={[CLINICS_GET_OWN, PAGE_CLINICS_VIEW]}
            permissionsOperator="OR"
            component={asyncLoadedPages.Facility}
            path="/facility"
          />
          <AuthorizedRoute
            requiredPermission={[
              PAGE_CLINICS_EDIT,
              PAGE_CLINICS_ADD,
              PAGE_CLINIC_OWN_EDIT,
            ]}
            permissionsOperator="OR"
            component={asyncLoadedPages.FacilityAddEdit}
            path="/facilityAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[USERS_UPDATE_SELF]}
            component={asyncLoadedPages.Profile}
            path="/userProfile"
          />
          <AuthorizedRoute
            requiredPermission={[USERS_UPDATE_SELF]}
            component={asyncLoadedPages.UserProfileEdit}
            path="/userProfileEdit"
          />
          <AuthorizedRoute
            requiredPermission={[ORDER_CREATE_OWN_CLINIC]}
            component={asyncLoadedPages.PurchasePackageSummary}
            path="/purchasePackageSummary"
          />
          <AuthorizedRoute
            requiredPermission={[REPLACEMENTS_ADD_OWN, REPLACEMENTS_ADD]}
            permissionsOperator="OR"
            component={asyncLoadedPages.ReplacementAdd}
            path="/replacementAdd"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_PATIENT_EDIT, PAGE_PATIENT_ADD]}
            permissionsOperator="OR"
            component={asyncLoadedPages.PatientAddEdit}
            path="/patientAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_TOOLS_LIST]}
            component={asyncLoadedPages.ToolsDetails}
            path="/toolsDetails"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_PATIENT_VIEW]}
            component={asyncLoadedPages.PatientView}
            path="/patientView"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_TOOLS_LIST]}
            component={asyncLoadedPages.Tools}
            path="/tools"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_PATIENT_LIST]}
            component={asyncLoadedPages.PatientsPage}
            path="/patients"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_DIAGNOSTICIAN_LIST, PAGE_DIAGNOSTICIAN_CLINIC_LIST]}
            permissionsOperator="OR"
            component={asyncLoadedPages.DiagnosticiansPage}
            path="/diagnosticians"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_DIAGNOSTICIAN_EDIT, PAGE_DIAGNOSTICIAN_ADD]}
            permissionsOperator="OR"
            component={asyncLoadedPages.DiagnosticianAddEdit}
            path="/diagnosticianAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_DIAGNOSTICIAN_VIEW]}
            component={asyncLoadedPages.Diagnostician}
            path="/diagnosticianView"
          />
          <AuthorizedRoute
            requiredPermission={[EXAMINATION_CREATE, EXAMINATION_CREATE_OWN_CLINIC]}
            permissionsOperator="OR"
            component={asyncLoadedPages.ResearchStart}
            path="/researchStart"
          />
          <AuthorizedRoute
            requiredPermission={[EXAMINATION_CREATE, EXAMINATION_CREATE_OWN_CLINIC]}
            permissionsOperator="OR"
            component={asyncLoadedPages.ResearchStartedOnTablet}
            path="/researchStartedOnTablet"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_MENU_BOTTOM_VIDEO_TUTORIAL]}
            component={asyncLoadedPages.Tutorials}
            path="/tutorials"
          />
          <AuthorizedRoute
            requiredPermission={[EXAMINATION_CREATE, EXAMINATION_CREATE_OWN_CLINIC]}
            permissionsOperator="OR"
            component={asyncLoadedPages.ResearchSelect}
            path="/researchSelect"
          />
          <AuthorizedRoute
            component={asyncLoadedPages.VideoTutorials}
            path="/videoTutorials"
            requiredPermission={[PAGE_VIDEO_TUTORIAL_LIST]}
          />
          <AuthorizedRoute
            component={asyncLoadedPages.VideoTutorialView}
            requiredPermission={[PAGE_VIDEO_TUTORIAL_VIEW]}
            path="/videoTutorialView"
          />
          <AuthorizedRoute
            component={asyncLoadedPages.VideoTutorialAddEdit}
            requiredPermission={[PAGE_VIDEO_TUTORIAL_ADD, PAGE_VIDEO_TUTORIAL_EDIT]}
            permissionsOperator="OR"
            path="/videoTutorialAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_STATISTICS, PAGE_STATISTICS_ALL_CLINICS]}
            permissionsOperator="OR"
            component={asyncLoadedPages.Statistics}
            path="/statistics"
          />
          <AuthorizedRoute
            requiredPermission={[PACKAGE_UPDATE]}
            component={asyncLoadedPages.PackagesEditPage}
            path="/packagesEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_GROUP_SURVEYS_RESULTS_LIST, _get(userData, 'clinics[0].has_group_examinations_enabled', false)]}
            component={asyncLoadedPages.GroupExaminationSelect}
            path="/groupExaminationSelect"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_GROUP_SURVEYS_RESULTS_LIST, _get(userData, 'clinics[0].has_group_examinations_enabled', false)]}
            component={asyncLoadedPages.GroupExaminationDetails}
            path="/groupExaminationDetails"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_GROUP_SURVEYS_RESULTS_LIST, _get(userData, 'clinics[0].has_group_examinations_enabled', false)]}
            component={asyncLoadedPages.GroupSurveyAddEdit}
            path="/groupSurveyAddEdit"
          />
          <AuthorizedRoute
            requiredPermission={[PAGE_GROUP_SURVEYS_RESULTS_LIST, _get(userData, 'clinics[0].has_group_examinations_enabled', false)]}
            component={asyncLoadedPages.GroupSurveys}
            path="/session_patient_groups"
          />
          <Redirect
            to={{
              pathname: '/login',
              search: history.location.search,
            }}
          />
        </Switch>
      </Global>
    </>
  );
};

Routes.propTypes = {
  history: historyShape.isRequired,
  permissions: PropTypes.objectOf(PropTypes.bool).isRequired,
  userData: userDataShape,
};

Routes.defaultProps = {
  userData: {},
};

const mapStateToProps = (state) => ({
  permissions: state.Global.permissions,
  userData: state.Global.userData,
});

export default connect(mapStateToProps)(Routes);
