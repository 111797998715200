import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { classesShape } from 'utils/shapes/classesShape';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import LoaderButton from 'components/LoaderButton';

import sectionTitleStyles from './SummaryButtons.styles';

const SummaryButtons = ({
  classes,
  onCancel,
  onSubmit,
  isSubmitDisabled,
  cancelMessage,
  submitMessage,
  withLoaderButton,
  loading,
  smallSpacing,
  withBiggerSpacing,
  smallButtons,
}) => {
  const buttonProps = {
    variant: 'contained',
    color: 'primary',
    type: 'submit',
    disabled: isSubmitDisabled,
    onClick: onSubmit,
    ...(withLoaderButton) && { loading },
  };

  const ButtonComponent = withLoaderButton ? LoaderButton : Button;

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      className={classnames(classes.gridPadding, {
        [classes.smallPadding]: smallSpacing,
        [classes.bigPadding]: withBiggerSpacing,
      })}
    >
      <ButtonComponent {...buttonProps} classes={{ root: smallButtons && classes.smallButton }}>
        {submitMessage}
      </ButtonComponent>
      <div className={classes.cancelWrapper}>
        <Button
          color="primary"
          classes={{ root: smallButtons && classes.smallButton }}
          disabled={loading}
          onClick={onCancel}
        >
          {cancelMessage}
        </Button>
      </div>
    </Grid>
  );
};

SummaryButtons.propTypes = {
  cancelMessage: PropTypes.node.isRequired,
  submitMessage: PropTypes.node.isRequired,
  classes: classesShape,
  isSubmitDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  smallButtons: PropTypes.bool,
  smallSpacing: PropTypes.bool,
  withBiggerSpacing: PropTypes.bool,
  withLoaderButton: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

SummaryButtons.defaultProps = {
  classes: {},
  onCancel: () => {},
  onSubmit: () => {},
  isSubmitDisabled: false,
  loading: false,
  smallButtons: false,
  smallSpacing: false,
  withLoaderButton: false,
  withBiggerSpacing: false,
};

export default withStyles(sectionTitleStyles)(SummaryButtons);
