import { push } from 'connected-react-router';

import { setDrawerVisibility } from 'containers/store';

export const initialState = {
  isLoadedPage: false,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'GROUP_EXAMINATION_SELECT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'GROUP_EXAMINATION_SELECT/CLEAR_STORE',
  SHOW_RESEARCH_LOADER: 'GROUP_EXAMINATION_SELECT/SHOW_RESEARCH_LOADER',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = () => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const startExamination = (version) => (dispatch) => {
  if (version === 'anonymous') {
    dispatch(push('/GroupSurveyAddEdit', { anonymous: true }));
    return;
  }

  dispatch(push('/GroupSurveyAddEdit', { anonymous: false }));
};

export const onResearchStart = (version) => (dispatch) => {
  dispatch(startExamination(version));
};

export const loadPageData = (title) => (dispatch) => {
  dispatch(clearStore());
  dispatch(loadPageSuccess());
  dispatch(setDrawerVisibility({
    visibility: true,
    content: title,
    onCloseCallback: () => {
      dispatch(push('/session_patient_groups'));
    },
  }));
};
