import { push, replace } from 'connected-react-router';
import _map from 'lodash/map';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getSortAndPageData from 'utils/getSortAndPageData';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getCountStartFrom from 'utils/getCountStartFrom';
import dialogTexts from 'utils/dialogTexts';

import {
  hideLoader, showSnackbar, showLoader, showTransparentLoader, setSortingAndPagination,
  openDialog, setPage, setRowsPerPage, setLastFilterValues, setSortingData,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  batteries: [],
  totalItemsCount: 0,
};

const defaultSorting = {
  fieldName: 'battery',
  sortDirection: 'asc',
};

const sortFieldsMapping = {
  battery: 'name',
};

const mapBatteries = (items, offset) => _map(items, (el, key) => ({
  id: key + 1 + offset,
  apiId: el.id,
  battery: el.name,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'BATTERIES/LOAD_PAGE_SUCCESS',
  SET_FILTERED_BATTERIES: 'BATTERIES/SET_FILTERED_BATTERIES',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        batteries: mapBatteries(action.response.data.items, action.startingPosition),
        totalItemsCount: action.response.data.total,
      };
    }

    case actionTypes.SET_FILTERED_BATTERIES: {
      return {
        ...state,
        batteries: mapBatteries(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (response, startingPosition) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  response,
  startingPosition,
});

const setFilteredBatteries = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_BATTERIES,
  response,
  startCountFrom,
});

const loadBatteries = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    sortedBy: defaultSorting.sortDirection,
    orderBy: sortFieldsMapping[defaultSorting.fieldName],
  };

  if (params.lastFilterValues.battery) {
    data.find = params.lastFilterValues.battery;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('batteries', data);

  dispatch(replace(url));

  return ApiManager.request('get', dispatch, url);
};

export const onAddClick = () => (dispatch) => {
  dispatch(push('/batteriesAddEdit'));
};

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/batteriesAddEdit', { id }));
};

const getBatteries = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadBatteries(data)).then((response) => {
    dispatch(setFilteredBatteries(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getBatteries());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getBatteries());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getBatteries());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getBatteries());
};

const onDeleteAccept = (id) => (dispatch, getStore) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `batteries/${id}`).then(() => {
    const data = getSortAndPageData(getStore, sortFieldsMapping);
    const startCountFrom = getCountStartFrom(getStore);

    dispatch(loadBatteries(data)).then((response) => {
      dispatch(setFilteredBatteries(response, startCountFrom));
      dispatch(showSnackbar(snackbarMessages.batteryDeleted));
      dispatch(hideLoader());
    }).catch((err) => {
      if (isBadRequest(err)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error, 409)) {
      const messageFromApi = error.error.message;
      const errorMessage = messageFromApi || snackbarMessages.wrongData;

      dispatch(showSnackbar(errorMessage, !!messageFromApi));
    } else if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteBattery,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const loadPageData = (values) => (dispatch, getStore) => {
  dispatch(showLoader());
  dispatch(setSortingAndPagination(defaultSorting, sortFieldsMapping, values));
  if (values) {
    dispatch(setLastFilterValues({
      battery: values.find || '',
    }));
  }

  const startingPosition = getCountStartFrom(getStore);
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  dispatch(loadBatteries(data)).then((response) => {
    dispatch(loadPageSuccess(response, startingPosition));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
