import React from 'react';

import UndesirableSurveyEvaluationsIcon from 'components/pages/PatientExamination/components/UndesirableSurveyEvaluationsIcon';
import GroupExaminationIcon from 'components/pages/PatientExamination/components/GroupExaminationIcon';

export const getIcons = (hasUndesirableSurveyEvaluations, sessionPatientGroupId) => {
  const icons = [];
  if (hasUndesirableSurveyEvaluations) {
    icons.push(<UndesirableSurveyEvaluationsIcon />);
  }

  if (sessionPatientGroupId !== null && sessionPatientGroupId !== undefined) {
    icons.push(<GroupExaminationIcon />);
  }

  return icons;
};
