import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    wrapper: {
      borderRadius: '8px 8px 0px 0px',
      border: `1px solid ${theme.palette.grey.border}`,
      position: 'relative',
      height: 112,
    },
    actionsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'absolute',
      top: 0,
      right: 0,
      margin: theme.spacing(0.5, 0.25, 0, 0),
    },
    additionalInfoWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '0px 0px 8px 8px',
      border: `1px solid ${theme.palette.grey.border}`,
      backgroundColor: theme.palette.grey.background,
      borderTop: 'none',
      padding: theme.spacing(1.25, 1.5),
    },
    fileNameWrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      alignItems: 'center',
    },
    fileName: {
      marginLeft: theme.spacing(1),
      verticalAlign: 'middle',
      wordBreak: 'break-all',
    },
    infoLabel: {
      color: theme.palette.tableCell.textColor,
    },
    focus: {
      ...theme.focus,
    },
  }),
);
